import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';

import { PlatformService } from '../ssr/platform.service';
import { WindowService } from '../ssr/window.service';

class BrowserClipboardProvider extends Clipboard {
  constructor(private windowService: WindowService) {
    super();
  }

  public copy(text: string): Promise<unknown> {
    if (!this.windowService.isWindowDefined) {
      return Promise.resolve();
    }

    return this.windowService.window.navigator.clipboard.writeText(text);
  }
}

class MobileClipboardProvider extends Clipboard {}

function clipboardFactory(windowService: WindowService, platformService: PlatformService): Clipboard {
  return platformService.areCordovaPluginsAvailable ? new MobileClipboardProvider() : new BrowserClipboardProvider(windowService);
}

export const clipboardProvider = { provide: Clipboard, useFactory: clipboardFactory, deps: [WindowService, PlatformService] };
