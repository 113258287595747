import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { AddressModalPage } from './address-modal.page';

import { AddressFormComponentModule } from '../../shared/components/address-form/address-form.module';
import { GoogleMapsComponentModule } from '../../shared/components/google-maps/google-maps.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule.forChild(), GoogleMapsComponentModule, AddressFormComponentModule],
  declarations: [AddressModalPage],
})
export class AddressModalPageModule {}
