import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { PreviousOrderModalPage } from './previous-order-modal.page';

import { PipesModule } from '../../shared/pipes/pipes.module';

import { AnimatedImageComponentModule } from '../../shared/components/animated-image/animated-image.module';
import { RatingComponentModule } from '../../shared/components/rating/rating.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild(),
    RatingComponentModule,
    PipesModule,
    AnimatedImageComponentModule,
  ],
  declarations: [PreviousOrderModalPage],
})
export class PreviousOrderModalPageModule {}
