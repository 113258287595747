import { Injector } from '@angular/core';

import { TranslateLoader } from '@ngx-translate/core';

import { Observable } from 'rxjs';

import { AppService } from '../app.service';
import { SettingsService } from '../settings.service';

import * as fs from 'fs';
import { join } from 'path';

export class TranslateServerLoader implements TranslateLoader {
  constructor(private injector: Injector) {}

  public getTranslation(lang: string): Observable<Record<string, string>> {
    return new Observable((observer) => {
      const appService = this.injector.get(AppService);
      const countryCode = this.injector.get(SettingsService).getCountry().code;
      const translateFilesFolder = join(process.cwd(), 'dist', 'browser', 'assets', 'i18n');
      const translateFile = fs.readFileSync(`${translateFilesFolder}/${countryCode}/${lang}.json`, 'utf8');
      const jsonData = JSON.parse(translateFile) as Record<string, string>;

      appService.updateTranslationsLoadedStatus(true);

      observer.next(jsonData);
      observer.complete();
    });
  }
}
