import { Injectable } from '@angular/core';

import { BehaviorSubject, distinctUntilChanged, Observable, Subject } from 'rxjs';

import { AppState } from '../models/app-state.model';

import { Helpers } from '../helpers';

export const INITIAL_STATE = {} as AppState;

interface AppStateLogDetails {
  payload: Partial<AppState>;
  nextState: AppState;
}

@Injectable({ providedIn: 'root' })
export class StateService {
  private appState$ = new BehaviorSubject<AppState>(INITIAL_STATE);
  private appStateChangesLog$ = new Subject<AppStateLogDetails>();

  public get state$(): Observable<AppState> {
    return this.appState$.pipe(distinctUntilChanged());
  }

  public get stateChangesLog$(): Observable<AppStateLogDetails> {
    return this.appStateChangesLog$.asObservable();
  }

  public get state(): AppState {
    return Helpers.clone(this.appState$.value);
  }

  public initialize(prevState?: Partial<AppState>): void {
    if (prevState) {
      this.update(prevState);
    }
  }

  public update(newValue: Partial<AppState>): void {
    if (!newValue) {
      return;
    }

    const nextState = { ...this.state, ...newValue };

    this.appState$.next(nextState);
    this.appStateChangesLog$.next({ payload: newValue, nextState });
  }
}
