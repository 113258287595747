<div class="modal-container">
  <div class="modal-content">
    <div class="main-section">
      <div class="modal-header">
        <div class="modal-title">{{ "DIMENSIONS" | translate }}</div>
        <div class="modal-subtitle">{{ description }}</div>
      </div>

      <div class="modal-body" [class.rotated]="!!shouldRotateImage">
        <div class="dimension-illustration-container">
          <div class="dimension-illustration" [style.backgroundImage]="'url(/assets/img/dimension-requirements/' + imageUrl + ')'"></div>
          <div *ngIf="width" class="dimension-details width">
            {{ width }}<br />{{ (+width === 1 ? "METER" : "METERS") | translate | lowercase }}
          </div>
          <div *ngIf="height" class="dimension-details height">
            {{ height }}<br />{{ (+height === 1 ? "METER" : "METERS") | translate | lowercase }}
          </div>
          <div *ngIf="depth" class="dimension-details depth">
            {{ depth }}<br />{{ (+depth === 1 ? "METER" : "METERS") | translate | lowercase }}
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <ion-button color="grey" expand="block" class="custom-button" (click)="onDismiss()">
          <span>{{ "OKAY" | translate }}</span>
        </ion-button>
      </div>
    </div>
  </div>
</div>
