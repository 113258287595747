// https://day.js.org/docs/en/display/format            //
export const LTR_TO_RTL_FORMAT: Record<string, string> = {
  'MMM DD': 'DD MMM',
  'ddd MMM D': 'ddd MMM D ',
  'MMMM D YYYY': 'D MMMM YYYY',
  'MMMM D, YYYY': 'D MMMM, YYYY',
  'MMMM DD YYYY': 'DD MMMM YYYY',
  'MMMM DD, YYYY': 'DD MMMM, YYYY',
  'DD MMM YYYY': 'MMM DD YYYY',
  'dddd, MMMM DD': 'dddd, DD MMMM',
  'dddd, MMMM DD, YYYY': 'dddd, DD MMMM, YYYY',
  'dddd, DD MMMM, YYYY': 'dddd, MMMM DD, YYYY',
} as const;

export const URL_DATE_FORMAT = 'YYYYMMDD';
export const URL_TIME_FORMAT = 'HHmm';
export const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const TIME_FORMAT_REGEX = /(h{1,2}|H{1,2}):?m{1,2}(:?s{1,2}|\s?(a|A))?/;
export const DATE_FORMAT_REGEX = /Y{2}|Y{4}|M{1,4}|D{1,2}|d{1,4}/;
