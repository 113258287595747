import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { AddressDetailsComponent } from './address-details.component';

@NgModule({
  declarations: [AddressDetailsComponent],
  imports: [CommonModule, IonicModule],
  exports: [AddressDetailsComponent],
})
export class AddressDetailsComponentModule {}
