import { Injectable } from '@angular/core';

import { AppState } from '../models/app-state.model';

import { PushNotificationsEffects } from './push-notifications.effects';
import { StorageEffects } from './storage.effects';
import { UniversalLinksAttributionEffects } from './universal-links-attribution.effects';
import { UniversalLinksEffects } from './universal-links.effects';

export interface Effect {
  initialize(): void;
}

@Injectable({ providedIn: 'root' })
export class AppEffects {
  private effects: Array<Effect> = [];

  constructor(
    private storageEffects: StorageEffects,
    private universalLinksEffects: UniversalLinksEffects,
    private pushNotificationsEffects: PushNotificationsEffects,
    private universalLinksAttributionEffects: UniversalLinksAttributionEffects,
  ) {
    this.effects.push(
      this.storageEffects,
      this.universalLinksEffects,
      this.pushNotificationsEffects,
      this.universalLinksAttributionEffects,
    );
  }

  public getPreviousSessionState(): Promise<Partial<AppState>> {
    return this.storageEffects.getPreviousSessionState();
  }

  public initialize(): void {
    this.effects.forEach((effect) => effect.initialize());
  }
}
