import { Injectable } from '@angular/core';

import { File } from '@awesome-cordova-plugins/file/ngx';

@Injectable({ providedIn: 'root' })
export class FilePlugin {
  constructor(private file: File) {}

  public get applicationDirectory(): string {
    return this.file.applicationDirectory;
  }

  public readAsText(path: string, file: string): Promise<string> {
    return this.file.readAsText(path, file);
  }
}
