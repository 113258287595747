/* istanbul ignore file */

import { Injectable } from '@angular/core';

import { MixpanelPlugin } from './native-plugins/mixpanel.plugin';

import { AccountService } from './account.service';
import { PlatformService } from './ssr/platform.service';
import { WindowService } from './ssr/window.service';

export enum PerformanceMark {
  InitializeApp = 'InitializeApp',
  PlatformReady = 'PlatformReady',
  LoadDataFromPreviousSessions = 'LoadDataFromPreviousSessions',
  InitializeAnalytics = 'InitializeAnalytics',
  LoadTestGroupsFromStorage = 'LoadTestGroupsFromStorage',
  InitializeSettingsFromStorage = 'InitializeSettingsFromStorage',
  InitializePushNotificationsIfHasPermission = 'InitializePushNotificationsIfHasPermission',
  MaybeReportPushNotificationStatusToApi = 'MaybeReportPushNotificationStatusToApi',
  InitializeUniversalLinks = 'InitializeUniversalLinks',
  LoadDataFromApi = 'LoadDataFromApi',
  LoadInitialSettingsFromApi = 'LoadInitialSettingsFromApi',
  RefreshUserDetailsFromApi = 'RefreshUserDetailsFromApi',
  LoadLoyaltyProgramDetailsFromApi = 'LoadLoyaltyProgramDetailsFromApi',
  LoadVendorsIdSLugMapFromCdn = 'LoadVendorsIdSLugMapFromCdn',
  LoadModalAdsFromApi = 'LoadModalAdsFromApi',
  LoadTranslationsFromCdn = 'LoadTranslationsFromCdn',
  LoadLastUnreviewedOrderFromApi = 'LoadLastUnreviewedOrderFromApi',
}

enum PerformanceExperimentVersion {
  AppOpeningLocalInitial = 4,
  AppOpeningLocalInitialSettingsFromCdn = 5,
  AppOpeningLocalFullyOptimized = 6,
  AppOpeningRealUsersUnitial = 7,
  AppOpeningRealUsersOptimizedV1 = 12,
}

@Injectable({ providedIn: 'root' })
export class AppPerformanceService {
  private enabled: boolean;
  private marks: Array<PerformanceMark> = [];

  constructor(
    private windowService: WindowService,
    private accountService: AccountService,
    private mixpanelPlugin: MixpanelPlugin,
    private platformService: PlatformService,
  ) {
    this.enabled = !this.platformService.isBrowser && this.windowService.isWindowDefined;
  }

  public markStart(name: PerformanceMark): void {
    if (!this.enabled) {
      return;
    }

    this.windowService.window.performance.mark(`will${name}`);
  }

  public markEnd(name: PerformanceMark): void {
    if (!this.enabled) {
      return;
    }

    this.windowService.window.performance.mark(`did${name}`);
    this.marks.push(name);
  }

  public sendPerformanceDataToMixpanel(): void {
    if (!this.enabled) {
      return;
    }

    this.marks.forEach((mark) => {
      this.windowService.window.performance.measure(`App:${mark}`, `will${mark}`, `did${mark}`);
    });

    const measures: Record<string, number> = {};

    this.windowService.window.performance.getEntriesByType('measure').forEach((measureItem) => {
      if (measureItem.name.includes('App:')) {
        const formattedName = measureItem.name.replace('App:', '');
        measures[`b_${formattedName.charAt(0).toLowerCase() + formattedName.slice(1)}`] = measureItem.duration;
      }
    });

    void this.mixpanelPlugin
      .logEvent('AppDidLoad', {
        ...measures,
        b_isLoggedIn: !!this.accountService.isLoggedIn(),
        b_platform: this.platformService.applicationPlatformName,
        b_optimizationExperimentVersion: PerformanceExperimentVersion.AppOpeningRealUsersOptimizedV1,
      })
      .then(() => {
        this.marks = [];
        this.windowService.window.performance.clearMarks();
        this.windowService.window.performance.clearMeasures();
      });
  }
}
