import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';

import { isCypressTestEnv } from '../environment.service';
import { PlatformService } from '../ssr/platform.service';
import { WindowService } from '../ssr/window.service';

class BrowserDiagnosticProvider extends Diagnostic {
  constructor(private windowService: WindowService) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public get permissionStatus() {
    return {
      GRANTED: 'GRANTED',
      DENIED: 'DENIED',
      DENIED_ONCE: 'DENIED_ONCE',
      NOT_REQUESTED: 'NOT_REQUESTED',
      DENIED_ALWAYS: 'DENIED_ALWAYS',
      RESTRICTED: 'RESTRICTED',
      GRANTED_WHEN_IN_USE: 'GRANTED_WHEN_IN_USE',
    };
  }

  public getLocationAuthorizationStatus(): Promise<string> {
    if (!this.windowService.isWindowDefined) {
      return Promise.resolve('RESTRICTED');
    }

    return Promise.resolve('geolocation' in this.windowService.window.navigator ? 'GRANTED' : 'RESTRICTED');
  }

  public getRemoteNotificationsAuthorizationStatus(): Promise<string> {
    if (isCypressTestEnv() && this.windowService.isWindowDefined) {
      const permissionStatus = this.windowService.window.localStorage.getItem('cy-test-notification-permission-status');

      if (permissionStatus) {
        return Promise.resolve(permissionStatus);
      }
    }

    return Promise.resolve('GRANTED');
  }

  public requestLocationAuthorization(_mode?: string): Promise<unknown> {
    if (!this.windowService.isWindowDefined) {
      return Promise.resolve('RESTRICTED');
    }

    return Promise.resolve('geolocation' in this.windowService.window.navigator ? 'GRANTED' : 'RESTRICTED');
  }

  public switchToSettings(): Promise<unknown> {
    return Promise.resolve();
  }
}

class MobileDiagnosticProvider extends Diagnostic {}

function diagnosticFactory(windowService: WindowService, platformService: PlatformService): Diagnostic {
  return platformService.areCordovaPluginsAvailable ? new MobileDiagnosticProvider() : new BrowserDiagnosticProvider(windowService);
}

export const diagnosticProvider = { provide: Diagnostic, useFactory: diagnosticFactory, deps: [WindowService, PlatformService] };
