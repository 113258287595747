import { Injector } from '@angular/core';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import nativePlugins from './services/native-plugins/index';

import { PlatformServerService } from './services/ssr/platform.server.service';
import { PlatformService } from './services/ssr/platform.service';
import { WindowServerService } from './services/ssr/window.server.service';
import { WindowService } from './services/ssr/window.service';

import config from './config/index';
import interceptors from './interceptors/index';

import { LOCAL_VENDOR_DETAILS_LOADER, LocalVendorDetailsBrowserLoader } from './services/ssr/local-vendor-details-loader';
import { LocalVendorDetailsServerLoader } from './services/ssr/local-vendor-details-loader.server';
import { SETTINGS_LOADER, SettingsBrowserLoader } from './services/ssr/settings-loader';
import { SettingsServerLoader } from './services/ssr/settings-loader.server';
import { TranslateBrowserLoader } from './services/ssr/translate-loader';
import { TranslateServerLoader } from './services/ssr/translate-loader.server';

import { appInitializerProvider } from './app-initializer';
import { errorHandlerProvider } from './error-handler';
import { DevtoolsModule } from './plugins/devtools.plugin';

export default {
  providers: [appInitializerProvider, errorHandlerProvider, ...config, ...nativePlugins, ...interceptors],
  ssrBrowserProviders: [
    { provide: SETTINGS_LOADER, useClass: SettingsBrowserLoader },
    { provide: LOCAL_VENDOR_DETAILS_LOADER, useClass: LocalVendorDetailsBrowserLoader },
    { provide: WindowService, useClass: WindowService },
    { provide: PlatformService, useClass: PlatformService },
  ],
  ssrBrowserModules: [
    DevtoolsModule.forRoot(),
    TranslateModule.forRoot({ loader: { provide: TranslateLoader, useClass: TranslateBrowserLoader, deps: [Injector] } }),
  ],
  ssrServerProviders: [
    { provide: SETTINGS_LOADER, useClass: SettingsServerLoader },
    { provide: LOCAL_VENDOR_DETAILS_LOADER, useClass: LocalVendorDetailsServerLoader },
    { provide: WindowService, useClass: WindowServerService },
    { provide: PlatformService, useClass: PlatformServerService },
  ],
  ssrServerModules: [TranslateModule.forRoot({ loader: { provide: TranslateLoader, useClass: TranslateServerLoader, deps: [Injector] } })],
};
