import { Inject, Injectable } from '@angular/core';

import { Market } from '@awesome-cordova-plugins/market/ngx';

import { PlatformService } from '../ssr/platform.service';

import { AppConfig, TOKEN_CONFIG } from '../../config/app.config';

@Injectable({ providedIn: 'root' })
export class MarketPlugin {
  constructor(private market: Market, private platformService: PlatformService, @Inject(TOKEN_CONFIG) private config: AppConfig) {}

  public openAppStore(): void {
    const appId = this.platformService.isIos ? this.config.appIdIos : this.config.appIdAndroid;
    void this.market.open(appId);
  }
}
