import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { IonContent, ViewDidEnter, ViewWillEnter, ViewWillLeave } from '@ionic/angular';

import { KEYBOARD_DID_SHOW_EVENT } from '../../core/services/native-plugins/keyboard.plugin';
import { SplashScreenPlugin } from '../../core/services/native-plugins/splash-screen.plugin';

import { Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { OrderReview } from '../../core/models/order-review.model';

import { AnalyticsService } from '../../core/services/analytics.service';
import { LoggerService } from '../../core/services/logger.service';
import { ModalService } from '../../core/services/modal.service';
import { OrdersService } from '../../core/services/orders.service';
import { OverlayService } from '../../core/services/overlay.service';
import { SettingsService } from '../../core/services/settings.service';
import { PlatformService } from '../../core/services/ssr/platform.service';
import { WindowService } from '../../core/services/ssr/window.service';
import { ValidationService } from '../../core/services/validation.service';

import { AnalyticsConfig, TOKEN_ANALYTICS_CONFIG } from '../../core/config/analytics.config';
import { BACK_BUTTON_HANDLER_PRIORITY } from '../../core/config/app.config';

import { SwiperComponent } from 'swiper/angular';

export const OrderReviewModalPageIdentifier = 'order-review-modal';

export interface OrderReviewModalPageResult {
  reviewed: boolean;
}

interface VendorRating {
  vendorId: number;
  vendorName: string;
  service: number;
  products: number;
  timeliness: number;
  presentation: number;
  overall: number;
  review: string;
  submitted?: boolean;
}

@Component({
  selector: 'app-order-review-modal',
  templateUrl: './order-review-modal.page.html',
  styleUrls: ['./order-review-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderReviewModalPage implements ViewWillEnter, ViewDidEnter, ViewWillLeave, OnDestroy {
  @ViewChild(IonContent)
  public content: IonContent;

  @ViewChild('swiper')
  public swiper: SwiperComponent;

  @Input()
  public orderId: number;

  public vendorRatings: Array<VendorRating> = [];

  public canLeave: boolean;
  public sliderActiveIndex: number;
  public isLastVendor: boolean;
  public sliderScrollingDirection: string;

  private unregisterBackButtonSubscription: Subscription;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private windowService: WindowService,
    private overlayService: OverlayService,
    private modalService: ModalService,
    private validationService: ValidationService,
    private translateService: TranslateService,
    private ordersService: OrdersService,
    private settingsService: SettingsService,
    private platformService: PlatformService,
    private loggerService: LoggerService,
    private analyticsService: AnalyticsService,
    private splashScreenPlugin: SplashScreenPlugin,
    @Inject(TOKEN_ANALYTICS_CONFIG) private analyticsConfig: AnalyticsConfig,
  ) {}

  ionViewWillEnter() {
    this.sliderScrollingDirection = this.settingsService.getLanguageDirection();
    this.getOrderDetails();
  }

  ionViewDidEnter() {
    this.trackPageView();
    this.splashScreenPlugin.hide();
    this.listenToKeyboardEvents();
    this.unregisterBackButtonSubscription = this.platformService.backButton.subscribeWithPriority(BACK_BUTTON_HANDLER_PRIORITY, () =>
      this.onGoBack(),
    );
  }

  ionViewWillLeave() {
    this.removeKeyboardEventsListener();
    this.unregisterBackButtonSubscription?.unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  public onGoBack({ reviewed }: OrderReviewModalPageResult = { reviewed: false }): void {
    if (!this.canLeave) {
      this.showPreviousVendor();
      return;
    }

    this.onDismiss({ reviewed });
  }

  public onDismiss({ reviewed }: OrderReviewModalPageResult = { reviewed: false }): void {
    void this.modalService.dismissModal({ id: OrderReviewModalPageIdentifier, data: { reviewed } });
  }

  public onContinue(): void {
    const selectedVendorRating = this.vendorRatings[this.sliderActiveIndex];

    if (selectedVendorRating) {
      selectedVendorRating.submitted = true;

      if (
        !selectedVendorRating.service ||
        !selectedVendorRating.products ||
        !selectedVendorRating.timeliness ||
        !selectedVendorRating.presentation ||
        !selectedVendorRating.overall
      ) {
        this.overlayService.showToast({
          message: this.translateService.instant('ORDER_REVIEW_MODAL_PAGE.MISSING_RATING_ERROR') as string,
          showCloseButton: true,
          type: 'error',
        });
        return;
      }

      if (this.isLastVendor) {
        this.submitReview();
        return;
      }

      this.showNextVendor();
    }
  }

  private submitReview(): void {
    const ratingsParam: Array<OrderReview> = [];

    this.vendorRatings.forEach((vendor) =>
      ratingsParam.push({
        catererId: vendor.vendorId,
        products: vendor.products,
        service: vendor.service,
        presentation: vendor.presentation,
        timeliness: vendor.timeliness,
        overall: vendor.overall,
        feedback: vendor.review,
      }),
    );

    void this.overlayService.showLoading().then(() => {
      this.ordersService
        .submitOrderReview(this.orderId, ratingsParam)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.trackSubmitReviewEvent();

            void this.overlayService.hideLoading().then(() => {
              void this.modalService
                .showAlert({
                  title: this.translateService.instant('ORDER_REVIEW_SUCCESS.TITLE') as string,
                  message: this.translateService.instant('ORDER_REVIEW_SUCCESS.MESSAGE') as string,
                  buttons: [{ text: this.translateService.instant('OK') as string, handler: () => {} }],
                })
                .then(() => {
                  this.canLeave = true;
                  void this.onGoBack({ reviewed: true });
                });
            });
          },
          error: (error: HttpErrorResponse) => {
            void this.overlayService.hideLoading().then(() => {
              const errorMessage = this.validationService.getErrorMessage(error);
              this.loggerService.info({
                component: 'OrderReviewModalPage',
                message: "couldn't submit review",
                details: { error, messageShownToUser: errorMessage },
              });

              void this.modalService.showAlert({ title: this.translateService.instant('ERROR') as string, message: errorMessage });
            });
          },
        });
    });
  }

  private showNextVendor(): void {
    this.sliderActiveIndex++;
    this.isLastVendor = this.sliderActiveIndex === this.vendorRatings.length - 1;
    this.canLeave = false;

    this.swiper.swiperRef.slideNext();
  }

  private showPreviousVendor(): void {
    this.sliderActiveIndex--;
    this.canLeave = this.sliderActiveIndex === 0;
    this.isLastVendor = false;

    this.swiper.swiperRef.slidePrev();
  }

  private getOrderDetails(): void {
    void this.overlayService.showLoading().then(() => {
      this.ordersService
        .getOrderDetails(this.orderId)
        .pipe(
          map((orderDetails) => orderDetails.caterers.map((vendor) => ({ vendorId: vendor.catererId, name: vendor.name }))),
          takeUntil(this.unsubscribe$),
        )
        .subscribe({
          next: (vendors) => {
            for (const vendor of vendors) {
              this.vendorRatings.push({
                vendorId: vendor.vendorId,
                vendorName: vendor.name,
                presentation: 0,
                products: 0,
                service: 0,
                timeliness: 0,
                overall: 0,
                review: '',
              });
            }

            this.canLeave = true;
            this.sliderActiveIndex = 0;
            this.isLastVendor = this.vendorRatings.length === 1;

            void this.platformService.wait(200).then(() => {
              this.swiper?.swiperRef?.update();
              void this.overlayService.hideLoading();
            });
          },
          error: (error: HttpErrorResponse) => {
            const errorMessage = this.validationService.getErrorMessage(error);
            this.loggerService.info({
              component: 'OrderReviewModalPage',
              message: "couldn't get order details",
              details: { error, messageShownToUser: errorMessage },
            });

            this.canLeave = true;

            void this.overlayService.hideLoading().then(() => {
              this.overlayService.showToast({ message: errorMessage, type: 'error', showCloseButton: true });
              this.onGoBack();
            });
          },
        });
    });
  }

  private trackPageView(): void {
    void this.analyticsService.trackView({
      name: this.analyticsConfig.pageName.orderReviewModalPage,
      data: {
        [this.analyticsConfig.propertyName.orderId]: this.orderId,
      },
    });
  }

  private trackSubmitReviewEvent(): void {
    void this.analyticsService.trackEvent({
      name: this.analyticsConfig.eventName.orderReviewModalPageSubmit,
      data: { [this.analyticsConfig.propertyName.orderId]: this.orderId },
    });
  }

  private scrollToBottom = (): void => {
    void this.content.scrollToBottom(300);
  };

  private listenToKeyboardEvents(): void {
    if (this.windowService.isWindowDefined) {
      this.windowService.window.addEventListener(KEYBOARD_DID_SHOW_EVENT, this.scrollToBottom);
    }
  }

  private removeKeyboardEventsListener(): void {
    if (this.windowService.isWindowDefined) {
      this.windowService.window.removeEventListener(KEYBOARD_DID_SHOW_EVENT, this.scrollToBottom);
    }
  }
}
