<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="onClosePage()">
        <ion-icon slot="icon-only" color="white" src="/assets/custom-icons/b-times.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <swiper #swiper class="image-preview-slider" [dir]="sliderScrollingDirection" [zoom]="true" (activeIndexChange)="onActiveIndexChange()">
    <ng-template swiperSlide *ngFor="let imageUrl of imageUrls">
      <div class="swiper-zoom-container">
        <app-animated-image @animatedImage class="slide-image swiper-zoom-target" [imageUrl]="imageUrl"></app-animated-image>
      </div>
    </ng-template>
  </swiper>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <p class="footer-text ion-text-center">
      {{ "IMAGES_PREVIEW_MODAL_PAGE.CURRENT_IMAGE" | translate: { index: currentIndex + 1, total: imageUrls?.length } }}
    </p>
  </ion-toolbar>
</ion-footer>
