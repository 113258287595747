import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { catchError, forkJoin, map, Observable, of } from 'rxjs';

import { DeepLinkClick } from '../models/deep-link-click.model';
import { PushNotification } from '../models/push-notification.model';
import { ReferralProgramLinkClick } from '../models/referral-program-link-click.model';
import { UniversalLink } from '../models/universal-link.model';

import { DateTimeService } from './date-time.service';
import { LoggerService } from './logger.service';
import { SettingsService } from './settings.service';
import { PlatformService } from './ssr/platform.service';
import { StateService } from './state.service';

import { AnalyticsConfig, TOKEN_ANALYTICS_CONFIG } from '../config/analytics.config';
import { AppConfig, TOKEN_CONFIG } from '../config/app.config';

import { toPromise } from '../operators/to-promise';

const SEND_CLICKS_TO_API_INTERVAL_IN_MS = 30_000;
@Injectable({ providedIn: 'root' })
export class UniversalLinksAttributionService {
  constructor(
    private http: HttpClient,
    private loggerService: LoggerService,
    private stateService: StateService,
    private settingsService: SettingsService,
    private platformService: PlatformService,
    private dateTimeService: DateTimeService,
    @Inject(TOKEN_CONFIG) private config: AppConfig,
    @Inject(TOKEN_ANALYTICS_CONFIG) private analyticsConfig: AnalyticsConfig,
  ) {}

  public get sendClicksToApiIntervalInMs(): number {
    return SEND_CLICKS_TO_API_INTERVAL_IN_MS;
  }

  public saveUniversalLinkClick(universalLink: UniversalLink): void {
    const linkData = !universalLink.affiliateUserId
      ? this.getDeepLinkClickFromUniversalLink(universalLink)
      : this.getReferralProgramLinkClickFromUniversalLink(universalLink);

    this.saveDeepLinkClick(linkData);
  }

  public savePushNotificationClick(pushNotification: PushNotification): void {
    this.saveDeepLinkClick(this.getDeepLinkClickFromPushNotification(pushNotification));
  }

  public sendDeepLinkClicksAndReferralProgramLinkClicksToApi(): Promise<void> {
    return toPromise(forkJoin([this.sendDeepLinkClicksToApi(), this.sendReferralProgramLinkClicksToApi()]).pipe(map(() => {})));
  }

  public shouldBeAttributed(linkClick: DeepLinkClick | ReferralProgramLinkClick): boolean {
    return this.isReferralProgramLink(linkClick)
      ? this.settingsService.isReferralProgramFeatureEnabled()
      : this.shouldDeepLinkClickBeAttributed(linkClick);
  }

  public shouldDeepLinkClickBeAttributed(deepLinkClick: DeepLinkClick): boolean {
    return deepLinkClick && !!deepLinkClick.campaignId;
  }

  public sendDeepLinkClicksToApi(): Observable<boolean> {
    const url = this.config.routes.postDeepLinkClick.url;
    const linkClicks = this.stateService.state?.pendingDeepLinkClicks || [];
    const platformName = this.platformService.applicationPlatformName;

    // Filter the data just in case if links related to
    // whatsapp share were saved by a prev version of the app
    const linkClicksToBeAttributed = linkClicks
      .filter((deepLinkClick) => this.shouldBeAttributed(deepLinkClick))
      .map((linkClick) => this.getDeepLinkApiDataModel(linkClick, platformName));

    if (!linkClicksToBeAttributed || !linkClicksToBeAttributed.length) {
      return of(false);
    }

    this.stateService.update({ sendingDeepLinkClicksToApi: true });

    return this.http.post<void>(url, { clicks: linkClicksToBeAttributed }, { responseType: 'text' as 'json' }).pipe(
      map(() => {
        this.stateService.update({
          pendingDeepLinkClicks: [],
          sendingDeepLinkClicksToApi: false,
        });

        return true;
      }),
      catchError((error: unknown) => {
        this.stateService.update({ sendingDeepLinkClicksToApi: false });
        this.loggerService.info({
          component: 'UniversalLinksAttributionService',
          message: "couldn't process deep link clicks",
          details: { error },
        });
        return of(false);
      }),
    );
  }

  public sendReferralProgramLinkClicksToApi(): Observable<boolean> {
    if (!this.settingsService.isReferralProgramFeatureEnabled()) {
      return of(false);
    }

    const url = this.config.routes.postReferralProgramLinkClick.url;
    const linkClicks = this.stateService.state?.pendingReferralProgramLinkClicks || [];
    const linkClicksToBeSent = linkClicks.map((linkClick) => this.getReferralProgramApiDataModel(linkClick));

    if (!linkClicksToBeSent || !linkClicksToBeSent.length) {
      return of(false);
    }

    this.stateService.update({ sendingReferralProgramLinkClicksToApi: true });

    return this.http.post<void>(url, { affiliateUsers: linkClicksToBeSent }, { responseType: 'text' as 'json' }).pipe(
      map(() => {
        this.stateService.update({
          pendingReferralProgramLinkClicks: [],
          sendingReferralProgramLinkClicksToApi: false,
        });
        return true;
      }),
      catchError((error: unknown) => {
        this.stateService.update({ sendingReferralProgramLinkClicksToApi: false });
        this.loggerService.info({
          component: 'UniversalLinksAttributionService',
          message: "couldn't process affiliate link clicks",
          details: { error },
        });
        return of(false);
      }),
    );
  }

  private saveDeepLinkClick(linkData: DeepLinkClick | ReferralProgramLinkClick): void {
    const isReferralProgramLink = this.isReferralProgramLink(linkData);
    const isReferralProgramEnabled = this.settingsService.isReferralProgramFeatureEnabled();

    if (isReferralProgramLink && !isReferralProgramEnabled) {
      return;
    }

    const referralProgramLinkClicks = this.stateService.state?.pendingReferralProgramLinkClicks || [];
    const deepLinkClicks = this.stateService.state?.pendingDeepLinkClicks || [];

    isReferralProgramLink
      ? this.stateService.update({ pendingReferralProgramLinkClicks: [...referralProgramLinkClicks, linkData] })
      : this.stateService.update({ pendingDeepLinkClicks: [...deepLinkClicks, linkData] });
  }

  private getDeepLinkClickFromUniversalLink(linkData: UniversalLink): DeepLinkClick {
    const newDeepLinkClick = {} as DeepLinkClick;

    newDeepLinkClick.clickedAt = this.dateTimeService.getUtcDateTimeIsoString();

    newDeepLinkClick.isUniversalLink = true;
    newDeepLinkClick.isPushNotification = false;

    newDeepLinkClick.isNewInstall = linkData.isNewInstall;

    newDeepLinkClick.fromModalAds = linkData.fromModalAds;
    newDeepLinkClick.fromBannerAds = linkData.fromBannerAds;

    newDeepLinkClick.orderId = linkData.orderId;
    newDeepLinkClick.catererId = linkData.catererId;
    newDeepLinkClick.menuItemId = linkData.menuItemId;
    newDeepLinkClick.serviceTypes = linkData.serviceTypes;
    newDeepLinkClick.marketingCollectionId = linkData.marketingCollectionId;
    newDeepLinkClick.wishlistId = linkData.wishlistId;

    newDeepLinkClick.campaignId = linkData.campaignId;
    newDeepLinkClick.source = linkData.campaignSource;
    newDeepLinkClick.couponCode = linkData.couponCode;

    return newDeepLinkClick;
  }

  private getDeepLinkClickFromPushNotification(linkData: PushNotification): DeepLinkClick {
    const newDeepLinkClick = {} as DeepLinkClick;

    newDeepLinkClick.clickedAt = this.dateTimeService.getUtcDateTimeIsoString();

    newDeepLinkClick.isUniversalLink = false;
    newDeepLinkClick.isPushNotification = true;

    newDeepLinkClick.isNewInstall = false;

    newDeepLinkClick.fromModalAds = false;
    newDeepLinkClick.fromBannerAds = false;

    newDeepLinkClick.orderId = linkData.orderId;
    newDeepLinkClick.catererId = linkData.catererId;
    newDeepLinkClick.menuItemId = linkData.menuItemId;
    newDeepLinkClick.serviceTypes = linkData.serviceTypes;
    newDeepLinkClick.marketingCollectionId = linkData.marketingCollectionId;
    newDeepLinkClick.wishlistId = linkData.wishlistId;

    newDeepLinkClick.campaignId = linkData.campaignId;
    newDeepLinkClick.source = this.analyticsConfig.sourceName.pushNotification;

    return newDeepLinkClick;
  }

  private getReferralProgramLinkClickFromUniversalLink(linkData: UniversalLink): ReferralProgramLinkClick {
    return {
      clickedAt: this.dateTimeService.getUtcDateTimeIsoString(),
      isNewInstall: linkData.isNewInstall,
      orderId: linkData.orderId,
      catererId: linkData.catererId,
      menuItemId: linkData.menuItemId,
      marketingCollectionId: linkData.marketingCollectionId,
      wishlistId: linkData.wishlistId,
      affiliateUserId: linkData.affiliateUserId,
      couponCode: linkData.couponCode,
    } as ReferralProgramLinkClick;
  }

  private getReferralProgramApiDataModel(referralProgramLink: ReferralProgramLinkClick): Record<string, unknown> {
    return {
      clickedAt: referralProgramLink.clickedAt,
      affiliateUserId: referralProgramLink.affiliateUserId,
    };
  }

  private isReferralProgramLink(link: DeepLinkClick | ReferralProgramLinkClick): link is ReferralProgramLinkClick {
    return !!(link as ReferralProgramLinkClick).affiliateUserId;
  }

  private getDeepLinkApiDataModel(deepLinkClick: DeepLinkClick, platform: string): Record<string, unknown> {
    return {
      clickedAt: deepLinkClick.clickedAt,
      platform,
      campaignId: deepLinkClick.campaignId ? deepLinkClick.campaignId : null,
      source: deepLinkClick.source ? deepLinkClick.source : null,
    };
  }
}
