import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { DeliveryProviders } from '../../core/enums/delivery-providers.enum';

@Pipe({ name: 'deliveredBy' })
export class DeliveredByPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(deliveryProvider: DeliveryProviders): string {
    const deliveredBy = deliveryProvider === DeliveryProviders.Bilbayt ? 'DELIVERED_BY_BILBAYT' : 'DELIVERED_BY_VENDOR';
    return this.translateService.instant(deliveredBy) as string;
  }
}
