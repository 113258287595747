import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { Area } from '../../../core/models/area.model';
import { Province } from '../../../core/models/province.model';

import { AnimationState } from '../../../core/animations';
import { AreasByProvinceComponentAnimations } from './areas-by-province.animations';

@Component({
  selector: 'app-areas-by-province',
  templateUrl: './areas-by-province.component.html',
  styleUrls: ['./areas-by-province.component.scss'],
  animations: [...AreasByProvinceComponentAnimations],
  encapsulation: ViewEncapsulation.None,
})
export class AreasByProvinceComponent {
  @Output()
  public areaSelect = new EventEmitter<Area>();

  public selectedArea: Area;
  public provincesToShow: Array<Province>;

  public AnimationState: typeof AnimationState = AnimationState;

  private enteredText = '';
  private allProvinces: Array<Province> = [];

  @Input()
  public set query(query: string) {
    this.enteredText = query ? query.toLowerCase() : '';

    if (!this.allProvinces || !this.allProvinces.length) {
      return;
    }

    this.updateVisibleAreas();
  }

  @Input()
  public set provinces(provinces: Array<Province>) {
    this.allProvinces = provinces;
    this.updateVisibleAreas();
  }

  @Input()
  public set selected(area: Area) {
    if (area && this.selectedArea && this.selectedArea.areaId === area.areaId) {
      return;
    }

    if (!this.allProvinces || !this.allProvinces.length) {
      return;
    }

    const shouldExpandProvinces = !this.selectedArea && area;
    const shouldCollapseProvinces = !area;

    this.selectedArea = area;

    if (shouldExpandProvinces) {
      this.updateProvinceThatIncludesArea(this.selectedArea, AnimationState.Expanded);
    } else if (!shouldCollapseProvinces) {
      this.collapseAllUnselectedProvinces();
    }
  }

  public onToggleProvince(province: Province): void {
    province.animationState = province.animationState === AnimationState.Collapsed ? AnimationState.Expanded : AnimationState.Collapsed;
  }

  public onSelect(area: Area): void {
    this.areaSelect.emit(area);
  }

  private updateVisibleAreas(): void {
    if (this.allProvinces?.length) {
      if (this.enteredText) {
        const matchingProvinces = JSON.parse(JSON.stringify(this.allProvinces)) as Array<Province>;

        matchingProvinces.forEach((province) => {
          const matchingAreas = province.areas.filter((area) => area.name.toLowerCase().indexOf(this.enteredText) > -1);

          // Keep only the matching areas of each province
          province.areas = matchingAreas;
        });

        this.provincesToShow = matchingProvinces;
        this.expandAllProvinces();
        return;
      }

      this.provincesToShow = JSON.parse(JSON.stringify(this.allProvinces)) as Array<Province>;

      this.allProvinces.length === 1 ? this.expandAllProvinces() : this.collapseAllUnselectedProvinces();
    }
  }

  private expandAllProvinces(): void {
    this.provincesToShow.forEach((province) => {
      province.animationState = AnimationState.Expanded;
    });
  }

  private collapseAllUnselectedProvinces(): void {
    this.provincesToShow.forEach((province) => {
      province.animationState = AnimationState.Collapsed;
    });

    if (this.selectedArea) {
      this.updateProvinceThatIncludesArea(this.selectedArea, AnimationState.Expanded);
    }
  }

  private updateProvinceThatIncludesArea(area: Area, newState: AnimationState): void {
    if (area) {
      const selectedProvince = this.provincesToShow.find((province) => province.name.toLowerCase() === area.province.toLowerCase());

      if (selectedProvince) {
        selectedProvince.animationState = newState;
      }
    }
  }
}
