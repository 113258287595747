import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { PlatformService } from '../ssr/platform.service';

class BrowserStatusBarProvider extends StatusBar {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public get isVisible(): boolean {
    return true;
  }

  public overlaysWebView(_doesOverlay: boolean): void {}

  public styleDefault(): void {}

  public styleLightContent(): void {}

  public styleBlackTranslucent(): void {}

  public styleBlackOpaque(): void {}

  public backgroundColorByName(): void {}

  public backgroundColorByHexString(): void {}

  public hide(): void {}

  public show(): void {}
}

class MobileStatusBarProvider extends StatusBar {}

function statusBarFactory(platformService: PlatformService): StatusBar {
  return platformService.areCordovaPluginsAvailable ? new MobileStatusBarProvider() : new BrowserStatusBarProvider();
}

export const statusBarProvider = { provide: StatusBar, useFactory: statusBarFactory, deps: [PlatformService] };
