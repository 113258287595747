<ion-content>
  <div class="modal-container">
    <div class="modal-content">
      <div class="form-container">
        <form class="custom-form" #form="ngForm" #formElement (ngSubmit)="onSubmit(formElement)" [formGroup]="userDetailsForm">
          <ion-list class="custom-form-wrapper">
            <!--
              Accoring to the XD file we should handle errors in the following way:
                - If the field is empty, the background should be red but we shouldn't show any messages
                - If the value is invalid, we should show a message without the red background
            -->

            <ng-template #emailFormField>
              <ion-item
                class="custom-form-item"
                [class.with-error-background]="
                  !!userDetailsForm.controls.email.errors?.required && (userDetailsForm.controls.email.touched || submitAttempt)
                "
              >
                <ion-label
                  @animatedInputField
                  position="stacked"
                  class="custom-form-item-label"
                  *ngIf="currentStep === 'initial' || currentStep === 'newUser'"
                  >{{ "FIELDS.EMAIL" | translate }}
                </ion-label>

                <ion-input
                  #emailInputElement
                  type="email"
                  formControlName="email"
                  class="custom-form-item-input"
                  data-cy="email-input-field"
                ></ion-input>

                <p
                  @animatedErrorMessage
                  class="custom-form-item-error"
                  *ngIf="
                    !!userDetailsForm.controls.email.errors &&
                    !userDetailsForm.controls.email.errors?.required &&
                    !!userDetailsForm.controls.email.errors?.invalidEmailAddress &&
                    (userDetailsForm.controls.email.touched || submitAttempt)
                  "
                >
                  {{ "LOG_IN_MODAL_PAGE.INVALID_EMAIL" | translate }}
                </p>
              </ion-item>
            </ng-template>

            <ng-template #passwordFormField>
              <ion-item
                class="custom-form-item"
                [class.with-error-background]="
                  !!userDetailsForm.controls.password.errors?.required && (userDetailsForm.controls.password.touched || submitAttempt)
                "
              >
                <ion-label @animatedInputField position="stacked" class="custom-form-item-label" *ngIf="currentStep === 'newUser'"
                  >{{ "FIELDS.PASSWORD" | translate }}
                </ion-label>

                <ion-input
                  #passwordInputElement
                  type="password"
                  formControlName="password"
                  class="custom-form-item-input"
                  [placeholder]="'FIELDS.PASSWORD' | translate"
                  data-cy="password-input-field"
                ></ion-input>

                <p
                  @animatedErrorMessage
                  class="custom-form-item-error"
                  *ngIf="
                    !!userDetailsForm.controls.password.errors &&
                    !userDetailsForm.controls.password.errors?.required &&
                    (userDetailsForm.controls.password.touched || submitAttempt)
                  "
                >
                  {{
                    (!!userDetailsForm.controls.password.errors?.invalidPassword
                      ? "LOG_IN_MODAL_PAGE.INCORRECT_PASSWORD"
                      : "LOG_IN_MODAL_PAGE.TOO_SHORT"
                    ) | translate
                  }}
                </p>
              </ion-item>
            </ng-template>

            <!--
              The initial form only shows the email because the idea is to use it
              to check if the user already has an account or not
            -->
            <div class="initial-form-fields" @animatedInputField *ngIf="currentStep === 'initial'">
              <ng-template [ngTemplateOutlet]="emailFormField"></ng-template>
            </div>

            <!--
              If the user already has an account we should show a sign in form that
              is slightly different than the initial or the new user forms
            -->
            <div class="existing-user-form-fields" @animatedInputField *ngIf="currentStep === 'existingUser'">
              <p class="section-title">{{ "LOG_IN" | translate }}</p>

              <ng-template [ngTemplateOutlet]="emailFormField"></ng-template>
              <ng-template [ngTemplateOutlet]="passwordFormField"></ng-template>

              <p class="section-callback" (click)="onShowForgotPasswordForm()" data-cy="forgot-password-button">
                {{ "LOG_IN_MODAL_PAGE.FORGOT_PASSWORD" | translate }}
              </p>
            </div>

            <div class="existing-user-form-fields" @animatedInputField *ngIf="currentStep === 'forgotPassword'">
              <p class="section-title">{{ "LOG_IN_MODAL_PAGE.FORGOT_PASSWORD" | translate }}</p>
              <ng-template [ngTemplateOutlet]="emailFormField"></ng-template>
            </div>

            <div class="new-user-form-fields" @animatedInputField *ngIf="currentStep === 'newUser'">
              <ng-template [ngTemplateOutlet]="emailFormField"></ng-template>

              <ion-item
                class="custom-form-item first-name"
                [class.with-error-background]="
                  !!userDetailsForm.controls.firstName.errors && (userDetailsForm.controls.firstName.touched || submitAttempt)
                "
              >
                <ion-label class="custom-form-item-label" position="stacked">{{ "FIELDS.FIRST_NAME" | translate }} </ion-label>
                <ion-input
                  type="text"
                  #firstNameInputElement
                  formControlName="firstName"
                  class="custom-form-item-input"
                  data-cy="first-name-input-field"
                ></ion-input>
              </ion-item>

              <ion-item
                class="custom-form-item last-name"
                [class.with-error-background]="
                  !!userDetailsForm.controls.lastName.errors && (userDetailsForm.controls.lastName.touched || submitAttempt)
                "
              >
                <ion-label class="custom-form-item-label" position="stacked">{{ "FIELDS.LAST_NAME" | translate }} </ion-label>
                <ion-input
                  type="text"
                  formControlName="lastName"
                  class="custom-form-item-input"
                  data-cy="last-name-input-field"
                ></ion-input>
              </ion-item>

              <ion-item
                class="custom-form-item phone-number"
                [class.with-error-background]="
                  !!userDetailsForm.controls.phoneCountryCode.errors?.required &&
                  (userDetailsForm.controls.phoneCountryCode.touched || submitAttempt) &&
                  !!userDetailsForm.controls.phoneLocalNumber.errors?.required &&
                  (userDetailsForm.controls.phoneLocalNumber.touched || submitAttempt)
                "
              >
                <ion-label class="custom-form-item-label" position="stacked">{{ "FIELDS.PHONE_NUMBER" | translate }} </ion-label>
                <div class="phone-input-container">
                  <ion-input
                    type="tel"
                    formControlName="phoneCountryCode"
                    class="custom-form-item-input"
                    data-cy="country-code-input-field"
                  ></ion-input>
                  <div class="separator"></div>
                  <ion-input
                    type="tel"
                    formControlName="phoneLocalNumber"
                    class="custom-form-item-input"
                    data-cy="phone-number-input-field"
                  ></ion-input>
                </div>

                <!--
                  Show an error message if any of the fields is invalid but not if both of them are empty
                -->
                <p
                  @animatedErrorMessage
                  class="custom-form-item-error"
                  *ngIf="
                    ((!!userDetailsForm.controls.phoneCountryCode.errors &&
                      (userDetailsForm.controls.phoneCountryCode.touched || submitAttempt)) ||
                      (!!userDetailsForm.controls.phoneLocalNumber.errors &&
                        (userDetailsForm.controls.phoneLocalNumber.touched || submitAttempt))) &&
                    (!userDetailsForm.controls.phoneCountryCode.errors?.required ||
                      !userDetailsForm.controls.phoneLocalNumber.errors?.required)
                  "
                >
                  {{ "LOG_IN_MODAL_PAGE.INCORRECT_NUMBER" | translate }}
                </p>
              </ion-item>

              <ng-template [ngTemplateOutlet]="passwordFormField"></ng-template>
            </div>
          </ion-list>

          <!--
            Hidden button to make the GO button submit the form,
            since the button is in the footer (outside the form tag)
          -->
          <button
            type="submit"
            style="visibility: hidden; width: 1px; height: 1px; padding: 0; margin: 0; position: absolute; top: 0"
          ></button>
        </form>
      </div>

      <div class="footer">
        <ion-row>
          <ion-col class="ion-no-padding">
            <ion-button [disabled]="isLoading" expand="block" (click)="onGoBack()" color="grey" class="custom-button">
              <ng-container *ngIf="currentStep !== 'forgotPassword'">{{ "CANCEL" | translate }}</ng-container>
              <ng-container *ngIf="currentStep === 'forgotPassword'">{{ "BACK" | translate }}</ng-container>
            </ion-button>
          </ion-col>
          <ion-col class="ion-no-padding">
            <ion-button
              color="green"
              expand="block"
              class="custom-button"
              [disabled]="isLoading"
              (mousedown)="$event.preventDefault(); form.ngSubmit.emit($event)"
              data-cy="continue-button"
            >
              <ng-container *ngIf="!isLoading">
                <ng-container *ngIf="currentStep === 'initial'">{{ "CONTINUE" | translate }}</ng-container>
                <ng-container *ngIf="currentStep === 'newUser'">{{ "SIGN_UP" | translate }}</ng-container>
                <ng-container *ngIf="currentStep === 'existingUser'">{{ "LOG_IN" | translate }}</ng-container>
                <ng-container *ngIf="currentStep === 'forgotPassword'">{{
                  "LOG_IN_MODAL_PAGE.FORGOT_PASSWORD_BUTTON" | translate
                }}</ng-container>
              </ng-container>
              <ion-icon *ngIf="isLoading" class="spinning-icon" src="/assets/custom-icons/b-spinner.svg"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </div>
</ion-content>
