import { Injectable } from '@angular/core';

import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';

import { LoggerService } from '../logger.service';
import { PlatformService } from '../ssr/platform.service';

// There was a MAJOR change in the Diagnostic plugin but Ionic
// Native is still using the old code so we need to define the
// permission status enums locally
// https://github.com/dpa99c/cordova-diagnostic-plugin/issues/230

// The following permission states are defined for Android:
// NOT_REQUESTED - App has not yet requested access to this permission. App can request permission and user will be prompted to allow/deny.
// DENIED_ONCE - User denied access to this permission (without checking "Never Ask Again" box). App can request permission again and user will be prompted again to allow/deny again.
// DENIED_ALWAYS - User denied access to this permission and checked "Never Ask Again" box. App can never ask for permission again. The only way around this is to instruct the user to manually change the permission on the app permissions page in Settings.
// GRANTED - User granted access to this permission, the device is running Android 5.x or below, or the app is built with API 22 or below.

// The following permission states are defined for iOS:
// NOT_REQUESTED - App has not yet requested access to this permission. App can request permission and user will be prompted to allow/deny.
// DENIED_ALWAYS - User denied access to this permission. App can never ask for permission again. The only way around this is to instruct the user to manually change the permission in Settings.
// RESTRICTED - Permission is unavailable and user cannot enable it. For example, when parental controls are in effect for the current user.
// GRANTED - User granted access to this permission. For location permission, this indicates the user has granted access to the permission "always" (when app is both in foreground and background).
// GRANTED_WHEN_IN_USE - Used only for location permission. Indicates the user has granted access to the permission "when in use" (only when the app is in the foreground).

export const enum PermissionAuthorizationStatus {
  CanRequest,
  RequestedAndAccepted,
  RequestedAndRejected,
}

@Injectable({ providedIn: 'root' })
export class DiagnosticPlugin {
  constructor(private platformService: PlatformService, private diagnostic: Diagnostic, private loggerService: LoggerService) {}

  public switchToSettings(): Promise<void> {
    return this.diagnostic
      .switchToSettings()
      .then(() => {})
      .catch((error: unknown) => {
        this.loggerService.info({
          component: 'DiagnosticPlugin',
          message: "couldn't switch to settings",
          details: { error },
          fromPlugin: true,
        });
      });
  }

  public getRemoteNotificationsAuthorizationStatus(): Promise<PermissionAuthorizationStatus> {
    const getStatus = this.platformService.isIos
      ? this.getRemoteNotificationsAuthorizationStatusIos()
      : this.getRemoteNotificationsAuthorizationStatusAndroid();

    return getStatus
      .then((status) => this.mapStatusToAuthorizationStatus(status))
      .catch((error: unknown) => {
        this.loggerService.info({
          component: 'DiagnosticPlugin',
          message: "couldn't get remote notifications auth status",
          details: { error },
          fromPlugin: true,
        });
        return PermissionAuthorizationStatus.RequestedAndRejected;
      });
  }

  public requestLocationAuthorization(): Promise<PermissionAuthorizationStatus> {
    return this.diagnostic
      .requestLocationAuthorization()
      .then((status: string) => this.mapStatusToAuthorizationStatus(status))
      .catch((error: unknown) => {
        this.loggerService.info({
          component: 'DiagnosticPlugin',
          message: "couldn't request location authorization",
          details: { error },
          fromPlugin: true,
        });
        return PermissionAuthorizationStatus.RequestedAndRejected;
      });
  }

  public getLocationAuthorizationStatus(): Promise<PermissionAuthorizationStatus> {
    return this.diagnostic
      .getLocationAuthorizationStatus()
      .then((status: string) => this.mapStatusToAuthorizationStatus(status))
      .catch((error: unknown) => {
        this.loggerService.info({
          component: 'DiagnosticPlugin',
          message: "couldn't get location auth status",
          details: { error },
          fromPlugin: true,
        });
        return PermissionAuthorizationStatus.RequestedAndRejected;
      });
  }

  private getRemoteNotificationsAuthorizationStatusIos(): Promise<string> {
    return this.diagnostic.getRemoteNotificationsAuthorizationStatus();
  }

  private getRemoteNotificationsAuthorizationStatusAndroid(): Promise<string> {
    // The POST_NOTIFICATIONS permission has not been added to Ionic native yet
    return this.diagnostic.getPermissionAuthorizationStatus('POST_NOTIFICATIONS') as Promise<string>;
  }

  private mapStatusToAuthorizationStatus(status: string): PermissionAuthorizationStatus {
    switch (status) {
      case this.diagnostic.permissionStatus.NOT_REQUESTED:
      case this.diagnostic.permissionStatus.DENIED_ONCE:
        return PermissionAuthorizationStatus.CanRequest;

      case this.diagnostic.permissionStatus.GRANTED:
      case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
        return PermissionAuthorizationStatus.RequestedAndAccepted;

      default:
        return PermissionAuthorizationStatus.RequestedAndRejected;
    }
  }
}
