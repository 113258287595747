import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { TrackingTransparencyModalPage } from './tracking-transparency-modal.page';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule.forChild()],
  declarations: [TrackingTransparencyModalPage],
})
export class TrackingTransparencyModalPageModule {}
