import { PreloadingStrategy, Route } from '@angular/router';

import { Observable, of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { PlatformService } from './core/services/ssr/platform.service';

import { AppModule } from './app.module';

export class AppPreloadingStrategy implements PreloadingStrategy {
  public preload(route: Route, load: () => Observable<unknown>): Observable<unknown> {
    const injector = AppModule.injector;
    const platformService = injector.get(PlatformService);
    const loadRoute = (delay: number) => (!!delay && !platformService.isServer ? timer(delay).pipe(mergeMap(() => load())) : load());
    return route.data && route.data.preload ? loadRoute(route.data.delay as number) : of(null);
  }
}
