import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { SelectedTimeSlot } from '../../../core/models/selected-time-slots-per-vendor.model';
import { TimeSlot } from '../../../core/models/time-slot.model';
import { TimeSlotsByDate } from '../../../core/models/time-slots-by-date.model';

import { Helpers } from '../../../core/helpers';

import { AnimationState } from '../../../core/animations';
import { TimeSlotsByDateComponentAnimations } from './time-slots-by-date.animations';

@Component({
  selector: 'app-time-slots-by-date',
  templateUrl: './time-slots-by-date.component.html',
  styleUrls: ['./time-slots-by-date.component.scss'],
  animations: [...TimeSlotsByDateComponentAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TimeSlotsByDateComponent implements OnInit {
  @Input()
  public selectedTimeSlot: TimeSlot;

  @Output()
  public timeSlotSelect = new EventEmitter<SelectedTimeSlot>();

  public allTimeSlotsByDate: Array<TimeSlotsByDate> = [];

  public AnimationState: typeof AnimationState = AnimationState;

  @Input()
  public set timeSlots(timeSlots: Array<TimeSlotsByDate>) {
    if (timeSlots?.length) {
      this.allTimeSlotsByDate = Helpers.clone(timeSlots);
    }
  }

  ngOnInit() {
    this.expandFirstOrSelectedTimeSlot();
  }

  public onToggleDate(timeSlotsByDate: TimeSlotsByDate): void {
    timeSlotsByDate.animationState =
      timeSlotsByDate.animationState === AnimationState.Collapsed ? AnimationState.Expanded : AnimationState.Collapsed;
  }

  public onSelectTimeSlot(dateText: string, timeSlot: TimeSlot): void {
    this.timeSlotSelect.emit({ dateText, timeSlot });
  }

  private expandFirstOrSelectedTimeSlot(): void {
    this.allTimeSlotsByDate.forEach((timeSlotsByDate, index) => {
      const shouldExpand = this.selectedTimeSlot
        ? timeSlotsByDate.timeSlots.findIndex(
            (timeSlot) => timeSlot.timeSlotId === this.selectedTimeSlot.timeSlotId && timeSlot.date === this.selectedTimeSlot.date,
          ) >= 0
        : index === 0;

      timeSlotsByDate.animationState = shouldExpand ? AnimationState.Expanded : AnimationState.Collapsed;
    });
  }
}
