import { transition, trigger } from '@angular/animations';

import {
  AnimationTransition,
  applyFadeInAnimation,
  applyFadeInTranslateAnimation,
  applyFadeOutAnimation,
  applyFadeOutTranslateAnimation,
} from '../../core/animations';

export const GroceryItemDetailsModalComponentAnimations = [
  trigger('animatedImageButton', [
    transition(AnimationTransition.Enter, applyFadeInAnimation({ duration: 300 })),
    transition(AnimationTransition.Leave, applyFadeOutAnimation({ duration: 300 })),
  ]),
  trigger('animatedWishlistName', [
    transition(AnimationTransition.Enter, applyFadeInAnimation({ duration: 300 })),
    transition(AnimationTransition.Leave, applyFadeOutAnimation({ duration: 300 })),
  ]),
  trigger('animatedWishlistLoadingIcon', [
    transition(AnimationTransition.Enter, applyFadeInTranslateAnimation({ duration: 300, distance: '50px' })),
    transition(AnimationTransition.Leave, applyFadeOutTranslateAnimation({ duration: 300, distance: '-50px' })),
  ]),
  trigger('animatedWishlistSuccessIcon', [
    transition(AnimationTransition.Enter, applyFadeInTranslateAnimation({ duration: 300, distance: '50px' })),
    transition(AnimationTransition.Leave, applyFadeOutTranslateAnimation({ duration: 300, distance: '-50px' })),
  ]),
];
