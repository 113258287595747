import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { filter, map } from 'rxjs/operators';

import { NavigationService } from '../services/navigation.service';
import { SettingsService } from '../services/settings.service';

import { AppReadyGuard } from './app-ready.guard';

@Injectable({
  providedIn: 'root',
})
export class AppNotUpdatedGuard implements CanActivate {
  constructor(
    private appReadyGuard: AppReadyGuard,
    private settingsService: SettingsService,
    private navigationService: NavigationService,
  ) {}

  canActivate() {
    return this.appReadyGuard.canActivate().pipe(
      map(() => this.checkAppNotUpdated()),
      filter((canContinue) => !!canContinue),
    );
  }

  private checkAppNotUpdated(): boolean {
    if (!this.settingsService.shouldUpdateApp()) {
      void this.navigationService.navigateBackToHomePage();
      return false;
    }

    return true;
  }
}
