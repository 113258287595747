import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { EMPTY, from, merge, Observable, of, Subject } from 'rxjs';
import { catchError, filter, find, map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';

import { OrderMenuItemSourceType } from '../enums/order-menu-item-source-type.enum';
import { OrderType } from '../enums/order-type.enum';
import { PaymentClientId } from '../enums/payment-client-id.enum';
import { PaymentMethod } from '../enums/payment-method.enum';

import { Address } from '../models/address.model';
import { Area } from '../models/area.model';
import { CartRequest } from '../models/cart-request.model';
import { CartResponse } from '../models/cart-response.model';
import { Cart, CartItem } from '../models/cart.model';
import { Eta } from '../models/eta.model';
import { NewCard } from '../models/new-card.model';
import { NewCartItem } from '../models/new-cart-item.model';
import { NewOrderResponse } from '../models/new-order-response.model';
import { NewOrder, NewOrderAccountDetails, TapPaymentInfo } from '../models/new-order.model';
import { OrderGift } from '../models/order-gift.model';
import { OrderInProgress, SelectedDateTimePerVendor } from '../models/order-in-progress.model';
import { OrderMetadata } from '../models/order-metadata.model';
import { OrderPaymentOptions } from '../models/order-payment-options.model';
import { PayOrderResult } from '../models/pay-order-result.model';
import { PaymentClient } from '../models/payment-client.model';
import { PromotionValidationResult } from '../models/promotion-validation-result.model';
import { SavedCard } from '../models/saved-card.model';
import { SearchMetadata } from '../models/search-metadata.model';
import { SelectedTimeSlot, SelectedTimeSlotsPerVendor } from '../models/selected-time-slots-per-vendor.model';

import { AddressService } from './address.service';
import { AppEventsService } from './app-events.service';
import { AppService, MAX_HOURS_TO_KEEP_DATA } from './app.service';
import { DateTimeService } from './date-time.service';
import { ModalService } from './modal.service';
import { SettingsService } from './settings.service';
import { PlatformService } from './ssr/platform.service';
import { WindowService } from './ssr/window.service';
import { StateService } from './state.service';

import { AppConfig, TOKEN_CONFIG } from '../config/app.config';

import { Helpers } from '../helpers';

import { toPromise } from '../operators/to-promise';

export const ADD_TO_CART_BUFFER_TIME_IN_MINUTES = 30;

interface NewCartItemParams {
  item: NewCartItem;
  address: Address;
  dateTime?: string;
  isForNow?: boolean;
}

type TapPaymentEvent = 'success' | 'error' | 'other';

interface ValidatedPromotion {
  // This property tells that the promotion is related to a specific card ID so
  // if the selected payment is changed then the promotion needs to be removed
  promotionCardId?: string;
  promotionValidationResult: PromotionValidationResult;
}

@Injectable({ providedIn: 'root' })
export class CartService {
  public cart$: Observable<void>;

  private cartSource$ = new Subject<void>();
  private saveCartState$ = new Subject<void>();

  private cart: Cart;
  private cartDetails: CartResponse;
  private orderId: number;
  private selectedDateTimePerVendor: SelectedDateTimePerVendor = {};
  private selectedTimeSlotsPerVendor: SelectedTimeSlotsPerVendor = {};
  private dateTimeUpdatedAutomaticallyPerVendor: Record<number, boolean> = {};

  private orderGiftDetails: OrderGift;
  private searchId: string;
  private sorterTypeText: string;

  private orderAccountDetails: NewOrderAccountDetails;

  private alreadyShownCartTimeSlotPromptInCurrentSession = false;

  private cartPromotions: Array<ValidatedPromotion> = [];
  private otherPromotions: Array<ValidatedPromotion> = [];
  private selectedPromotion: ValidatedPromotion = null;

  private selectedCard: SavedCard | NewCard;
  private paymentClient: PaymentClient;
  private walletCredits = 0;
  private totalAfterDiscounts = 0;

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private appService: AppService,
    private settingsService: SettingsService,
    private dateTimeService: DateTimeService,
    private translateService: TranslateService,
    private addressService: AddressService,
    private stateService: StateService,
    private platformService: PlatformService,
    private appEventsService: AppEventsService,
    private windowService: WindowService,
    private inAppBrowser: InAppBrowser,
    @Inject(TOKEN_CONFIG) private config: AppConfig,
  ) {
    this.cart$ = this.cartSource$.pipe(
      tap(() => this.updateCartTotals()),
      shareReplay(1),
    );
  }

  public getCart(): Cart {
    return Helpers.clone(this.cart);
  }

  public getCartDetails(): CartResponse {
    return Helpers.clone(this.cartDetails);
  }

  public removeVendorFromCart(vendorId: number): void {
    const vendor = this.cart.caterers.find((v) => v.catererId === vendorId);

    if (!vendor) {
      return;
    }

    this.removeItemsFromCart(
      vendorId,
      vendor.items.map((item) => item.menuItemId),
    );
  }

  public removeItemsFromCart(vendorId: number, menuItemIds: Array<number>): void {
    const vendorIndex = this.cart.caterers.findIndex((v) => v.catererId === vendorId);

    if (vendorIndex < 0) {
      return;
    }

    const vendor = this.cart.caterers[vendorIndex];

    if (!vendor.items.some((i) => menuItemIds.some((id) => i.menuItemId === id))) {
      return;
    }

    for (const id of menuItemIds) {
      const itemIndex = vendor.items.findIndex((i) => i.menuItemId === id);
      if (itemIndex >= 0) {
        vendor.items.splice(itemIndex, 1);
      }
    }

    const removedAllItemsFromVendor = vendor.items.length === 0;

    if (removedAllItemsFromVendor) {
      this.cart.caterers.splice(vendorIndex, 1);

      // The selected date/time kept in this service may be used as a default date/time
      // in the search pages, so it's important to remove date/time values for vendors
      // that are not in the cart anymore
      delete this.selectedDateTimePerVendor[vendorId];
      delete this.selectedTimeSlotsPerVendor[vendorId];
      delete this.dateTimeUpdatedAutomaticallyPerVendor[vendorId];
    }

    this.cartDetails = null;
    this.updateCartTotals();

    this.cartSource$.next();
  }

  public clearCart(): void {
    this.cart.caterers = [];
    this.updateCartTotals();
    this.cartDetails = null;
    this.cartSource$.next();
  }

  public validateAndUpdateCart(params: { validateLoyaltyWalletRewards?: boolean } = {}): Observable<void> {
    const cartRequest = this.getCartRequest({ validateLoyaltyWalletRewards: params?.validateLoyaltyWalletRewards });
    const url = this.config.routes.putCartValidate.url;

    return this.http
      .put<CartResponse>(url, cartRequest)
      .pipe(switchMap((validatedCart) => from(this.updateCartAndCartDetails(validatedCart))));
  }

  public isCartBasedOnTimeSlots(): boolean {
    if (!this.cart?.itemsCount) {
      return false;
    }

    return this.getServiceTypesAddedToCart().every((serviceType) => this.settingsService.isServiceTypeBasedOnTimeSlots(serviceType));
  }

  public isCartBasedDateTime(): boolean {
    if (!this.cart?.itemsCount) {
      return false;
    }

    return this.getServiceTypesAddedToCart().every((serviceType) => this.settingsService.isServiceTypeBasedOnDateTime(serviceType));
  }

  public isCartBasedOnCategories(): boolean {
    if (!this.cart?.itemsCount) {
      return false;
    }

    return this.getServiceTypesAddedToCart().some((serviceType) => this.settingsService.isServiceTypeBasedOnCategories(serviceType));
  }

  public updateItemSpecialRequest({ vendorId, itemId, request }: { vendorId: number; itemId: number; request: string }): void {
    const vendor = this.cart.caterers.find((v) => v.catererId === vendorId);

    if (!vendor) {
      return;
    }

    const cartItemIndex = vendor.items.findIndex((i) => i.menuItemId === itemId);

    if (cartItemIndex < 0) {
      return;
    }

    vendor.items[cartItemIndex].specialRequests = request;
    this.cartDetails = null;

    this.cartSource$.next();
  }

  public updateIsBilbaytNowCart(isBilbaytNow: boolean): void {
    this.cart.isBilbaytNow = !!isBilbaytNow;
    this.cartDetails = null;
  }

  public isCartEmpty(): boolean {
    return !this.cart || this.cart.itemsCount === 0;
  }

  public getFirstVendorName(): string {
    return this.cart.caterers[0].catererName;
  }

  public getVendorsCount(): number {
    return this.cart.caterers.length;
  }

  public isVendorInCart(vendorId: number): boolean {
    return this.cart.caterers.some((c) => c.catererId === vendorId);
  }

  public getVendorMinimumOrderValue(vendorId: number): number {
    if (this.isCartEmpty()) {
      return null;
    }

    const vendor = this.cart.caterers.find((c) => c.catererId === vendorId);

    if (!vendor) {
      return null;
    }

    return vendor.items.reduce((pv, cv) => (cv.minimumOrderValue > pv ? cv.minimumOrderValue : pv), 0);
  }

  public getItemsCount(): number {
    return this.cart.itemsCount;
  }

  public getItemsTotalPrice(): number {
    return this.cart.totalPrice;
  }

  public getVendorItemsTotalPrice(vendorId: number): number {
    return this.cart.caterers.find((c) => c.catererId === vendorId)?.total || 0;
  }

  public getCartTotalPrice(): number {
    return this.cartDetails?.totalPrice ?? 0;
  }

  public getCartTotalDeliveryCharges(): number {
    return Helpers.subtractWithPrecision(this.getCartTotalPrice(), this.getItemsTotalPrice());
  }

  public getServiceTypesAddedToCart(): Array<number> {
    if (!this.cart?.itemsCount) {
      return [];
    }

    const serviceTypes = this.cart.caterers.reduce((pv, cv) => [...pv, ...cv.items.map((i) => i.serviceType)], [] as Array<number>);
    return [...new Set(serviceTypes)];
  }

  public getAvailablePaymentClients(): Array<PaymentClient> {
    return this.cartDetails?.paymentOptions ?? [];
  }

  public getOrderPaymentOptions(orderId: number): Observable<OrderPaymentOptions> {
    const url = this.config.routes.getOrderPaymentOptions.url.replace('#orderId#', `${orderId}`);
    return this.http.get<OrderPaymentOptions>(url);
  }

  public getSavedCards(): Observable<Array<SavedCard>> {
    const url = this.config.routes.getUserCards.url;
    return this.http.get<Array<SavedCard>>(url).pipe(map((cards) => (cards?.length ? cards : [])));
  }

  public saveCard(cardToken: string): Observable<SavedCard> {
    const url = this.config.routes.getUserCards.url;
    return this.http.post<SavedCard>(url, { tokenId: cardToken });
  }

  public deleteSavedCard(cardId: string): Observable<void> {
    const url = this.config.routes.deleteUserCard.url.replace('#cardId#', cardId);
    return this.http.delete<void>(url);
  }

  public getItemsIdsFromVendor(vendorId: number): Array<number> {
    const itemsIds: Array<number> = [];

    for (const vendorFromCart of this.cart.caterers) {
      if (vendorFromCart.catererId === vendorId) {
        for (const itemFromCart of vendorFromCart.items) {
          itemsIds.push(itemFromCart.menuItemId);
        }
      }
    }

    return itemsIds;
  }

  public getItemsServiceTypesFromVendor(vendorId: number): Array<number> {
    const itemsServiceTypes: Array<number> = [];

    for (const vendorFromCart of this.cart.caterers) {
      if (vendorFromCart.catererId === vendorId) {
        for (const itemFromCart of vendorFromCart.items) {
          itemsServiceTypes.push(itemFromCart.serviceType);
        }
      }
    }

    return itemsServiceTypes;
  }

  public getItemQuantityByVendor(vendorId: number, itemId: number): number {
    return this.cart.caterers.find((c) => c.catererId === vendorId)?.items?.find((i) => i.menuItemId === itemId)?.quantity ?? 0;
  }

  public getCartRequest(params?: {
    orderId?: number;
    promotionId?: number;
    includeItemEta?: boolean;
    validateLoyaltyWalletRewards?: boolean;
  }): CartRequest {
    const orderId = params?.orderId ?? null;
    const promotionId = params?.promotionId ?? null;

    const isOrderForNow = this.isCartBasedDateTime() && this.isBilbaytNowCart();
    const isNotBasedOnDateTimeOrTimeSlots = !this.isCartBasedDateTime() && !this.isCartBasedOnTimeSlots();
    const includeItemEta = (params?.includeItemEta && (isOrderForNow || isNotBasedOnDateTimeOrTimeSlots)) ?? false;

    const orderType = this.cart.isBilbaytNow ? OrderType.BilbaytNow : OrderType.Traditional;
    const areaId = this.stateService.state?.address?.area?.areaId ?? null;

    const selectedTimeSlotsPerVendor = this.isCartBasedOnTimeSlots() ? this.getSelectedTimeSlots() ?? null : null;
    const selectedDateTimesPerVendor = this.isCartBasedDateTime() && !this.isBilbaytNowCart() ? this.getSelectedDateTimes() ?? null : null;

    return {
      orderId,
      orderType,
      areaId,
      promotionId,
      setPrices: true,
      validateLoyaltyWalletRewards: params?.validateLoyaltyWalletRewards || undefined,
      caterers: this.cart.caterers.map((caterer) => {
        const selectedTimeSlotForVendor =
          selectedTimeSlotsPerVendor && selectedTimeSlotsPerVendor[caterer.catererId]
            ? selectedTimeSlotsPerVendor[caterer.catererId].timeSlot
            : null;

        const selectedDateTimeForVendor =
          selectedDateTimesPerVendor && selectedDateTimesPerVendor[caterer.catererId]
            ? selectedDateTimesPerVendor[caterer.catererId]
            : null;

        return {
          catererId: caterer.catererId,
          timeSlot: selectedTimeSlotForVendor,
          deliveryDate: selectedDateTimeForVendor,
          items: caterer.items.map((item) => ({
            catererId: item.catererId,
            menuItemId: item.menuItemId,
            quantity: item.quantity,
            femaleServers: item.femaleServers,
            specialRequests: item.specialRequests,
            sourceId: item.sourceId,
            sourceType: item.sourceType,
            sourceNotes: item.sourceNotes,

            // If the order is not based on date/time and not based
            // on time slots OR if it's for now we need to send the
            // item ETA to the API
            // https://bilbayt.atlassian.net/browse/CA-1765
            // https://bilbayt.atlassian.net/browse/CA-2455
            eta: includeItemEta ? this.getItemEta(item.catererId, item.menuItemId) : null,
            options: item.options || [],
            addOns: item.addOns || [],
          })),
        };
      }),
    };
  }

  public isCartInvalid(): boolean {
    return !this.cart || !this.cartDetails || this.isCartEmpty();
  }

  public initializeCart(): void {
    this.initializeListeners();

    const emptyCart: Cart = { caterers: [], isBilbaytNow: false, itemsCount: 0, totalPrice: 0 };

    if (!this.stateService.state?.orderInProgress) {
      this.cart = emptyCart;
      this.cartDetails = null;
      this.cartSource$.next();
      return;
    }

    this.orderId = this.stateService.state.orderInProgress.orderId || null;
    const elapsedTime = this.dateTimeService.getElapsedTimeFromCurrentUtcTime(this.stateService.state.orderInProgress.timestamp, 'hour');

    // Only load a saved cart if the user added at least one menu item to it
    // and the cart is no older than MAX_HOURS_TO_KEEP_DATA hours
    this.cart =
      !this.stateService.state.orderInProgress.cart?.itemsCount || elapsedTime > MAX_HOURS_TO_KEEP_DATA
        ? emptyCart
        : this.restoreCartFromPreviousSession(this.stateService.state.orderInProgress);

    this.cartDetails = null;
    this.cartSource$.next();
  }

  public maybeAddToCart({ item, address, dateTime, isForNow }: NewCartItemParams): Promise<{ added: boolean; failureReason?: string }> {
    const sourceData =
      this.maybeGetSourceDataFromVendorChatEvent({ vendorId: item.catererId }) ||
      this.maybeGetSourceDataFromGlobalSearchEvent({ vendorId: item.catererId, itemId: item.menuItemId });

    const cartItem = !sourceData
      ? item
      : {
          ...item,
          sourceId: sourceData.sourceId,
          sourceType: sourceData.sourceType,
          sourceNotes: sourceData.sourceNotes,
        };

    if (this.isCartEmpty()) {
      this.addToCart({ item: cartItem, address, dateTime, isForNow });
      return Promise.resolve({ added: true });
    }

    const satifiesOrderForNowRequirement = this.satifiesOrderForNowRequirement(isForNow);
    const satifiesDateTimeOrTimeSlotRequirement = this.satifiesDateTimeOrTimeSlotRequirement(cartItem);

    const shouldAskToClearCart = !satifiesDateTimeOrTimeSlotRequirement || !satifiesOrderForNowRequirement;

    if (shouldAskToClearCart) {
      return this.showClearCartConfirmationModal().then((shouldAdd) => {
        if (shouldAdd) {
          this.clearCart();
          this.addToCart({ item, address, dateTime, isForNow });
        }

        return {
          added: shouldAdd,
          failureReason: shouldAdd
            ? undefined
            : !satifiesOrderForNowRequirement
            ? 'Item is not compatible with current cart (now - later)'
            : !satifiesDateTimeOrTimeSlotRequirement
            ? 'Item is not compatible with current cart (date/time - time slot)'
            : undefined,
        };
      });
    }

    this.addToCart({ item: cartItem, address, dateTime, isForNow });
    return Promise.resolve({ added: true });
  }

  public updateItemQuantity(itemQuantitiesUpdates: { [vendorId: number]: { [itemId: number]: number } }): Observable<boolean> {
    // NOTE: We don't need to calculate the total because we'll get
    // that data from the API. If the request fails we'll undo the
    // change to keep the cart consistent
    const currentCart = Helpers.clone(this.cart);
    const currenCartDetails = Helpers.clone(this.cartDetails);

    for (const vendorId of Object.keys(itemQuantitiesUpdates).map((id) => +id)) {
      const targetVendor = this.cart.caterers.find((vendor) => vendor.catererId === vendorId);
      const itemsToRemove: Array<number> = [];

      if (targetVendor) {
        for (const itemId of Object.keys(itemQuantitiesUpdates[vendorId]).map((id) => +id)) {
          const targetItem = targetVendor.items.find((item) => item.menuItemId === itemId);

          if (targetItem) {
            if (itemQuantitiesUpdates[vendorId][itemId] === 0) {
              itemsToRemove.push(itemId);
            } else {
              targetItem.quantity = itemQuantitiesUpdates[vendorId][itemId];
            }
          }
        }
      }

      if (itemsToRemove.length) {
        this.removeItemsFromCart(vendorId, itemsToRemove);
      }
    }

    this.cartDetails = null;
    this.updateCartTotals();

    if (this.isCartEmpty()) {
      return of(true);
    }

    return this.validateAndUpdateCart().pipe(
      map(() => true),
      catchError(() => {
        this.cart = currentCart;
        this.cartDetails = currenCartDetails;

        return of(false);
      }),
    );
  }

  public makeOrderPayment({
    orderId,
    payment,
    applePayTokenBase64,
  }: {
    orderId: number;
    payment: PaymentClient;
    applePayTokenBase64?: string;
  }): Observable<PayOrderResult> {
    const url = this.config.routes.putOrderPay.url.replace('#orderId#', `${orderId}`);

    const { paymentMethod, client: paymentClient } = payment;

    const selectedCard = this.getSelectedCard();
    const newCard = selectedCard ? ('isNew' in selectedCard ? selectedCard : undefined) : undefined;
    const savedCardId = selectedCard ? ('isNew' in selectedCard ? undefined : selectedCard.cardId) : undefined;

    const payOrderInput = {
      paymentClient,
      paymentMethod: this.getPaymentMethod(paymentClient, paymentMethod),
      country: this.settingsService.getCountry().value,
      tap: this.getTapInfo(newCard, savedCardId, applePayTokenBase64),
    };

    return this.http.put<PayOrderResult>(url, payOrderInput);
  }

  public placeOrder({
    paymentClient,
    paymentMethod,
    promotionId,
    applePayTokenBase64,
    savedCardId,
    newCard,
  }: {
    paymentClient: PaymentClientId;
    paymentMethod?: PaymentMethod;
    promotionId?: number;
    applePayTokenBase64?: string;
    savedCardId?: string;
    newCard?: NewCard;
  }): Observable<NewOrderResponse> {
    const url = this.config.routes.putNewOrder.url;

    const orderId = this.orderId ?? null;
    const address = this.stateService.state?.address;
    const saveAddress = this.addressService.isNew(address) && address?.shouldBeSaved;
    const savedAddressId = !this.addressService.isNew(address) ? address?.savedAddressId : null;

    const cartRequest = this.getCartRequest({ orderId, promotionId, includeItemEta: true });

    const newOrder: NewOrder = {
      orderType: this.isBilbaytNowCart() ? OrderType.BilbaytNow : OrderType.Traditional,
      areaId: cartRequest.areaId,
      metadata: this.getNewOrderMetadata(),

      phone: address?.phoneNumber,
      address: this.addressService.getNewOrderAddressFromSavedAddressModel(address, this.settingsService.getCountry()),
      geography: address?.geography,
      deliveryInstructions: address?.deliveryInstructions,
      saveAddress,
      savedAddressId,
      cart: cartRequest,
      promotionId,
      paymentClient,
      paymentMethod: this.getPaymentMethod(paymentClient, paymentMethod),
      tap: this.getTapInfo(newCard, savedCardId, applePayTokenBase64),
      gift: this.orderGiftDetails,
      accountDetails: this.orderAccountDetails,
    };

    return this.http.put<NewOrderResponse>(url, newOrder).pipe(
      tap((response: NewOrderResponse) => {
        this.appEventsService.dispatch('OrderPlaced');

        if (response.order?.orderId) {
          this.orderId = response.order.orderId;
          this.saveOrderInProgressInStorage();
        }

        if (response.savedAddressId) {
          this.updateSelectedAddressAfterPlacingOrder(response.savedAddressId);
        }
      }),
    );
  }

  public handleTapPayment(paymentUrl: string): Observable<TapPaymentEvent> {
    if (!this.platformService.areCordovaPluginsAvailable) {
      // We need to get out of the app and load the payment URL — the user will
      // be redirected once the payment is made
      if (this.windowService.isWindowDefined) {
        this.windowService.window.location.href = paymentUrl;
      }

      return EMPTY;
    }

    // The server returned a paymentUrl which is a link to TAP services. We
    // need to open that URL using InAppBrowser, and listen to redirect events
    // to check if the user was able to make the payment or if there was an error
    const inAppBrowserInstance = this.inAppBrowser.create(paymentUrl, '_blank', Helpers.inAppBrowserConfig);

    return inAppBrowserInstance.on('loadstop').pipe(
      map((result) => {
        // TAP will redirect the user a few times before the payment
        // is ready so we need to listen specifically for the success/error
        // URLs and ignore any other redirections (CA-2792)

        if (result.url.indexOf('/success') > 0) {
          inAppBrowserInstance.close();
          return 'success';
        }

        if (result.url.indexOf('/error') > 0) {
          inAppBrowserInstance.close();
          return 'error';
        }

        return 'other';
      }),
    );
  }

  public getCurrentOrderInProgressId(): number {
    return this.orderId ? this.orderId : null;
  }

  public setGiftDetails(orderGift: OrderGift): void {
    this.orderGiftDetails = orderGift;
  }

  public clearGiftDetails(): void {
    this.orderGiftDetails = null;
  }

  public setOrderAccountDetails(orderAccountDetails: NewOrderAccountDetails): void {
    this.orderAccountDetails = orderAccountDetails;
  }

  public clearOrderAccountDetails(): void {
    this.orderAccountDetails = null;
  }

  public getArea(): Area {
    return this.stateService.state?.address?.area;
  }

  public getCartTimeSlotPromptAlreadyShown(): boolean {
    return this.alreadyShownCartTimeSlotPromptInCurrentSession;
  }

  public markCartTimeSlotPromptAsAlreadyShown(): void {
    this.alreadyShownCartTimeSlotPromptInCurrentSession = true;
  }

  public clearOrderInProgressData(): void {
    this.orderId = null;
    this.clearCartAndSelectedPromotion();
  }

  public clearCartAndSelectedPromotion(): void {
    this.selectedPromotion = null;
    this.cartPromotions = [];
    this.otherPromotions = [];
    this.clearCart();
  }

  public clearCountryLanguageRelatedData(): void {
    this.selectedTimeSlotsPerVendor = {};
    this.paymentClient = null;
    this.clearCartAndSelectedPromotion();
    this.stateService.update({ address: undefined });

    this.saveCartState$.next();
  }

  public clearAccountRelatedData(): void {
    this.paymentClient = null;
    this.selectedCard = null;
    this.selectedPromotion = null;
    this.cartPromotions = [];
    this.otherPromotions = [];

    if (!this.addressService.isNew(this.stateService.state?.address)) {
      const address = this.addressService.createNewAddressFromArea(this.stateService.state?.address?.area);
      this.stateService.update({ address });
      this.saveCartState$.next();
    }
  }

  public getSelectedTimeSlots(): SelectedTimeSlotsPerVendor {
    return Helpers.clone(this.selectedTimeSlotsPerVendor);
  }

  public getSelectedTimeSlotForVendor(vendorId: number): SelectedTimeSlot {
    return Helpers.clone(this.selectedTimeSlotsPerVendor[vendorId]);
  }

  public setSelectedTimeSlotForVendor(vendorId: number, timeSlotParams: SelectedTimeSlot): void {
    this.selectedTimeSlotsPerVendor[vendorId] = timeSlotParams;
  }

  public getSelectedDateTimeForLastVendorInCart(): string {
    if (this.isCartEmpty() || Object.keys(this.selectedDateTimePerVendor).length === 0) {
      return null;
    }

    const vendorsIds = Object.keys(this.selectedDateTimePerVendor);
    const vendorDateTime = this.selectedDateTimePerVendor[+vendorsIds[vendorsIds.length - 1]];

    return this.isDateTimeStillValid(vendorDateTime) ? vendorDateTime : null;
  }

  public getSelectedDateTimes(): SelectedDateTimePerVendor {
    const validDateTimes: SelectedDateTimePerVendor = {};

    for (const vendorId of Object.keys(this.selectedDateTimePerVendor)) {
      const vendorDateTime = this.selectedDateTimePerVendor[+vendorId];

      if (this.isDateTimeStillValid(vendorDateTime)) {
        validDateTimes[+vendorId] = vendorDateTime;
      }
    }

    return validDateTimes;
  }

  public getSelectedDateTimeForVendor(vendorId: number): string | null {
    const vendorDateTime = this.selectedDateTimePerVendor[vendorId];
    return vendorDateTime && this.isDateTimeStillValid(vendorDateTime) ? vendorDateTime : null;
  }

  public setSelectedDateTimeForVendor(vendorId: number, dateTime: string): void {
    if (dateTime) {
      this.selectedDateTimePerVendor[vendorId] = dateTime;
    } else if (this.selectedDateTimePerVendor[vendorId]) {
      delete this.selectedDateTimePerVendor[vendorId];
    }

    this.saveCartState$.next();
  }

  // This method is called when the user edits an existing saved address
  // so that we can keep the local copy updated as well
  public maybeUpdateEditedAddress(editedAddress: Address): void {
    if (this.stateService.state?.address?.savedAddressId === editedAddress.savedAddressId) {
      this.updateOrderAddress(editedAddress);
    }
  }

  public updateOrderAddress(address: Address): void {
    if (!address) {
      return;
    }

    if (!this.addressService.hasLocationChanged(this.stateService.state?.address, address)) {
      return;
    }

    this.stateService.update({ address });
    this.saveCartState$.next();
  }

  public getAddress(): Address {
    return this.stateService.state?.address;
  }

  public isBilbaytNowCart(): boolean {
    return !!this.cart.isBilbaytNow;
  }

  public saveOrderInProgressInStorage(): void {
    const orderInProgress: OrderInProgress = {
      orderId: this.orderId ?? null,
      selectedAddress: this.stateService.state?.address,
      cart: !this.isCartEmpty() ? this.getCart() : null,
      timestamp: this.dateTimeService.getUtcDateTimeIsoString(),
      selectedDateTimePerVendor: this.selectedDateTimePerVendor ?? null,
      dateTimeUpdatedAutomaticallyPerVendor: this.dateTimeUpdatedAutomaticallyPerVendor ?? {},
    };

    this.stateService.update({ orderInProgress });
  }

  public getOrderType(): OrderType {
    return this.isBilbaytNowCart() ? OrderType.BilbaytNow : OrderType.Traditional;
  }

  public updateCurrentOrderSearchMetadata(medatada: SearchMetadata): void {
    if (!medatada) {
      return;
    }

    this.searchId = medatada.searchId || null;
    this.sorterTypeText = medatada.sorterTypeText || null;
  }

  public getCurrentOrderSearchMetadata(): SearchMetadata {
    return {
      searchId: this.searchId,
      sorterTypeText: this.sorterTypeText,
    };
  }

  public isOrderForNowAvailable(): boolean {
    const isCartAvailableForNow = this.cart.caterers.every((vendor) => vendor.items.every((menuItem) => menuItem.isBilbaytNow));
    return this.isCartEmpty() || isCartAvailableForNow;
  }

  public getAddToCartBufferTimeInMinutes(): number {
    return ADD_TO_CART_BUFFER_TIME_IN_MINUTES;
  }

  public addVendorToListOfVendorIdsDateTimeUpdatedAutomatically(vendorId: number): void {
    this.dateTimeUpdatedAutomaticallyPerVendor[vendorId] = true;
    this.saveOrderInProgressInStorage();
  }

  public removeVendorFromListOfVendorIdsDateTimeUpdatedAutomatically(vendorId: number): void {
    if (this.dateTimeUpdatedAutomaticallyPerVendor[vendorId]) {
      delete this.dateTimeUpdatedAutomaticallyPerVendor[vendorId];
      this.saveOrderInProgressInStorage();
    }
  }

  public getListOfVendorIdsDateTimeUpdatedAutomatically(): { [key: number]: boolean } {
    return Helpers.clone(this.dateTimeUpdatedAutomaticallyPerVendor);
  }

  public getEarliestDeliveryDateTime(): string | null {
    if (this.isBilbaytNowCart()) {
      return null;
    }

    if (this.isCartBasedOnTimeSlots()) {
      return null;
    }

    const dateTimes = Object.keys(this.selectedDateTimePerVendor).map((id) => this.getSelectedDateTimeForVendor(+id));
    return this.dateTimeService.getEarliestDateTime(dateTimes);
  }

  public getItemFromCart({ vendorId, itemId }: { vendorId: number; itemId: number }): CartItem {
    let itemFromCart: CartItem = null;

    vendorsLoop: for (const vendor of this.cart.caterers) {
      if (vendor?.catererId === vendorId) {
        if (vendor.items?.length) {
          for (const item of vendor.items) {
            if (item?.menuItemId === itemId) {
              itemFromCart = item;

              break vendorsLoop;
            }
          }
        }
      }
    }

    return itemFromCart;
  }

  public getSelectedPaymentClient(): PaymentClient {
    return this.paymentClient ? this.paymentClient : null;
  }

  public setSelectedPaymentClient(client: PaymentClient, details: { card?: SavedCard | NewCard } = null): void {
    this.paymentClient = client;
    this.selectedCard = details && details.card ? details.card : null;

    // Remove any promotions related to previously selected payment methods
    this.otherPromotions = (this.otherPromotions || []).filter(
      (promotion) => !promotion.promotionCardId || (this.selectedCard && promotion.promotionCardId === this.selectedCard.cardId),
    );

    if (!this.canApplyDiscounts()) {
      this.selectedPromotion = null;
      this.updateTotal();
      return;
    }

    // The user applied a bank promotion but then changed the selected
    // payment method so we need to remove that promotion
    if (
      this.selectedPromotion &&
      this.selectedPromotion.promotionCardId &&
      (!this.selectedCard || this.selectedPromotion.promotionCardId !== this.selectedCard.cardId)
    ) {
      this.selectedPromotion = null;
    }

    if (!this.selectedPromotion) {
      const autoApplyPromotion = this.cartDetails?.discounts?.autoApplyPromotion?.isValid
        ? this.cartDetails.discounts.autoApplyPromotion
        : null;

      this.selectedPromotion = autoApplyPromotion ? { promotionValidationResult: autoApplyPromotion } : null;
    }

    this.updateTotal();
  }

  public getSelectedCard(): SavedCard | NewCard {
    return this.selectedCard ? this.selectedCard : null;
  }

  public getAvailablePromotions(): Array<PromotionValidationResult> {
    return [...(this.cartPromotions || []), ...(this.otherPromotions || [])].map((promotion) => promotion.promotionValidationResult);
  }

  public getSelectedPromotion(): PromotionValidationResult {
    return this.selectedPromotion ? this.selectedPromotion.promotionValidationResult : null;
  }

  public addManuallyEnteredPromotion(promotion: PromotionValidationResult): void {
    if (!this.canApplyDiscounts() || !promotion) {
      return;
    }

    // Manually entered promotions are already validated so we just
    // need to apply them and maybe add them to the list
    this.selectedPromotion = { promotionValidationResult: promotion };
    this.maybeAddOrUpdatePromotion(this.selectedPromotion);
    this.updateTotal();
  }

  public addBankPromotion(
    promotion: PromotionValidationResult,
    bankPromotionDetails: { addWithoutApplying?: boolean; promotionCardId: string },
  ): void {
    if (!this.canApplyDiscounts() || !promotion) {
      return;
    }

    // Bank promotions are handled in a slightly different way because these promotions
    // are added automatically by the app without the user manually entering the code
    // so we need to validate a few more things

    const { addWithoutApplying, promotionCardId } = bankPromotionDetails;

    if (!promotionCardId || !this.selectedCard || this.selectedCard.cardId !== promotionCardId) {
      return;
    }

    const bankPromotion: ValidatedPromotion = { promotionValidationResult: promotion, promotionCardId };

    if (!addWithoutApplying) {
      this.selectedPromotion = bankPromotion;
    }

    this.maybeAddOrUpdatePromotion(bankPromotion);
    this.updateTotal();
  }

  public selectPromotionFromAvailablePromotions(promotionId: number): void {
    if (!this.canApplyDiscounts() || !promotionId) {
      return;
    }

    if (this.selectedPromotion && this.selectedPromotion.promotionValidationResult.promotionId === promotionId) {
      return;
    }

    const existingPromotion = [...this.cartPromotions, ...this.otherPromotions].find(
      (promotion) => promotion.promotionValidationResult.promotionId === promotionId,
    );

    if (!existingPromotion) {
      return;
    }

    this.selectedPromotion = existingPromotion;
    this.updateTotal();
  }

  public clearSelectedPromotion(): void {
    this.selectedPromotion = null;
    this.updateTotal();
  }

  public canApplyDiscounts(): boolean {
    return this.paymentClient?.client !== PaymentClientId.Cash;
  }

  public getWalletCreditsUsed(): number {
    return this.walletCredits;
  }

  public getCartTotalAfterDiscounts(): number {
    return this.totalAfterDiscounts;
  }

  public hasSelectedDateTimeForAllVendorsInCart(): boolean {
    let hasSelectedDateTimeForAllVendors = true;

    if (!this.isCartBasedOnTimeSlots()) {
      for (const caterer of this.cart.caterers) {
        const selectedDateTimesForVendor = this.getSelectedDateTimeForVendor(caterer.catererId);

        if (!selectedDateTimesForVendor) {
          hasSelectedDateTimeForAllVendors = false;
          break;
        }
      }
    }

    return hasSelectedDateTimeForAllVendors;
  }

  public hasSelectedTimeSlotsForAllVendorsInCart(): boolean {
    let hasSelectedTimeSlotForAllVendors = true;

    if (this.isCartBasedOnTimeSlots()) {
      for (const caterer of this.cart.caterers) {
        const vendorId = caterer.catererId;
        const selectedTimeSlotForVendor = this.selectedTimeSlotsPerVendor[vendorId];

        if (!selectedTimeSlotForVendor) {
          hasSelectedTimeSlotForAllVendors = false;
          break;
        }
      }
    }

    return hasSelectedTimeSlotForAllVendors;
  }

  public validatePromotionCode(code: string): Observable<PromotionValidationResult> {
    const cartRequest = this.getCartRequest();
    const url = this.config.routes.postPromotionValidate.url.replace('#promotionCode#', code);
    return this.http.post<PromotionValidationResult>(url, cartRequest);
  }

  public checkBankPromotion(cardBin: string): Observable<PromotionValidationResult> {
    const cart = this.getCartRequest();
    const url = this.config.routes.postBankPromotion.url;
    return this.http.post<PromotionValidationResult>(url, { cardBin, cart });
  }

  public maybeApplyDeepLinkPromotion(): Observable<void> {
    const promotions = this.stateService.state.deeplinkPromotions || [];

    if (!promotions.length) {
      return;
    }

    return from(promotions).pipe(
      mergeMap((p) => this.validatePromotionCode(p)),
      filter((vr) => vr?.isValid),
      tap((vr) => this.stateService.update({ deeplinkPromotions: promotions.filter((p) => p === vr.promotionCode) })),
      find((vr) => vr.isValid),
      switchMap((vr) => of(this.addManuallyEnteredPromotion(vr))),
    );
  }

  public maybeRemoveDeeplinkPromotion(promoCode: string): void {
    const deeplinkPromotions = this.stateService.state.deeplinkPromotions || [];
    this.stateService.update({ deeplinkPromotions: deeplinkPromotions.filter((p) => p !== promoCode) });
  }

  private getPaymentMethod(paymentClient: PaymentClientId, paymentMethod: PaymentMethod): PaymentMethod {
    return paymentClient === PaymentClientId.Cash
      ? PaymentMethod.Cash
      : paymentMethod && paymentMethod === PaymentMethod.ApplePay
      ? PaymentMethod.ApplePay
      : PaymentMethod.Online;
  }

  private getTapInfo(newCard: NewCard, savedCardId: string, applePayTokenBase64: string): TapPaymentInfo {
    return newCard
      ? { tokenId: newCard.cardToken, saveCard: newCard.shouldSave }
      : savedCardId
      ? { savedCardId }
      : applePayTokenBase64
      ? { applePayTokenBase64 }
      : null;
  }

  private updateTotal(): void {
    this.walletCredits = 0;
    this.totalAfterDiscounts = 0;

    if (!this.canApplyDiscounts()) {
      this.totalAfterDiscounts = this.getCartTotalPrice();
    } else {
      const subtotal = !this.selectedPromotion
        ? this.getCartTotalPrice()
        : Helpers.subtractWithPrecision(this.getCartTotalPrice(), this.selectedPromotion.promotionValidationResult.cartDiscount || 0);

      const availableWalletCredits = this.cartDetails ? this.cartDetails?.wallet?.balance || 0 : 0;

      this.walletCredits = subtotal > availableWalletCredits ? availableWalletCredits : subtotal;
      this.totalAfterDiscounts = Helpers.subtractWithPrecision(subtotal, this.walletCredits);
    }
  }

  private addToCart({
    item,
    address,
    dateTime,
    isForNow,
  }: {
    item: NewCartItem;
    address: Address;
    dateTime?: string;
    isForNow?: boolean;
  }): void {
    const newCartItem: CartItem = {
      catererId: item.catererId,
      menuItemId: item.menuItemId,
      minimumOrderValue: item.minimumOrderValue,
      name: item.menuItemName,
      quantity: item.quantity,
      isBilbaytNow: item.isBilbaytNow,
      total: item.total,
      serviceType: item.serviceType,
      femaleServers: item.femaleService,
      specialRequests: item.specialRequests,
      imageUrl: item.imageUrl,
      options: item.selectedOptions,
      addOns: item.selectedAddOns,
      sourceId: item.sourceId,
      sourceType: item.sourceType,
      sourceNotes: item.sourceNotes,
      noticePeriod: item.noticePeriod,
    };

    const cartVendorIndex = this.cart.caterers.findIndex((vendor) => vendor.catererId === item.catererId);

    if (cartVendorIndex >= 0) {
      const cartVendor = this.cart.caterers[cartVendorIndex];
      const cartItemIndex = cartVendor.items.findIndex((cartItem) => cartItem.menuItemId === item.menuItemId);

      if (cartItemIndex >= 0) {
        cartVendor.items[cartItemIndex] = newCartItem;
      } else {
        cartVendor.items.push(newCartItem);
      }
    } else {
      this.cart.caterers.push({
        catererId: item.catererId,
        catererName: item.catererName,
        total: newCartItem.total,
        items: [newCartItem],
      });
    }

    this.cartDetails = null;
    this.updateCartTotals();

    this.cartSource$.next();

    this.updateIsBilbaytNowCart(!!isForNow);
    this.setSelectedDateTimeForVendor(item.catererId, dateTime);

    this.stateService.update({ address });
    this.saveCartState$.next();
  }

  private updateCartAndCartDetails(cartDetails: CartResponse): Promise<void> {
    this.cartDetails = cartDetails;

    // Do not replace the current cart by the cart returned by
    // the API because there are some properties that were added
    // after and are not returned by the validation API endpoint
    this.updateCartBasedOnCartDetails(cartDetails);

    this.updateSelectedTimeSlots();
    this.updateSelectedPaymentClient();

    return this.updateSelectedAndAvailablePromotion().then(() => {
      this.updateTotal();
      this.cartSource$.next();
    });
  }

  private updateCartBasedOnCartDetails(cartDetails: CartResponse): void {
    for (const vendor of this.cart.caterers) {
      const apiVendor = cartDetails.caterers.find((c) => c.catererId === vendor.catererId);

      if (apiVendor) {
        for (const item of vendor.items) {
          const apiItem = apiVendor.items.find((i) => i.menuItemId === item.menuItemId);

          if (apiItem) {
            item.quantity = apiItem.quantity;
            item.total = apiItem.total;
            item.serviceType = apiItem.serviceType;
            item.femaleServers = apiItem.femaleServers;

            const serviceTypeRequirement = apiVendor?.serviceTypeRequirements?.find((s) => s.serviceType === item.serviceType);
            item.minimumOrderValue = serviceTypeRequirement ? serviceTypeRequirement.minimumOrderAmount ?? 0 : item.minimumOrderValue;

            for (const option of item.options || []) {
              const apiCartOption = apiItem.options?.find((o) => o.menuItemOptionId === option.menuItemOptionId);

              if (apiCartOption) {
                option.quantity = apiCartOption.quantity;
              }
            }

            for (const addon of item.addOns || []) {
              const apiCartAddOn = apiItem.addOns?.find((a) => a.menuItemAddOnId === addon.menuItemAddOnId);

              if (apiCartAddOn) {
                addon.quantity = apiCartAddOn.quantity;
              }
            }
          }
        }
      }
    }
  }

  private updateCartTotals(): void {
    let cartTotalPrice = 0;
    let cartItemsCount = 0;

    // We may have updated the quanity/price of the items
    // so we need to calculate the total of the vendor again
    for (const vendor of this.cart.caterers) {
      vendor.total = vendor.items.reduce((pv, cv) => Helpers.sumWithPrecision(pv, cv.total), 0);
      cartTotalPrice = Helpers.sumWithPrecision(cartTotalPrice, vendor.total);
      cartItemsCount = Helpers.sumWithPrecision(cartItemsCount, vendor.items.length);
    }

    this.cart.totalPrice = cartTotalPrice;
    this.cart.itemsCount = cartItemsCount;

    if (this.isCartEmpty()) {
      this.updateIsBilbaytNowCart(false);
    }
  }

  private updateSelectedTimeSlots(): void {
    // If the order is based on timeslots, we need to make sure that
    // the selected time slots are still valid — otherwise just select
    // the first time slot for each vendor

    if (this.isCartBasedOnTimeSlots()) {
      this.cartDetails.caterers.forEach((vendor) => {
        const alreadySelectedTimeSlot = this.getSelectedTimeSlotForVendor(vendor.catererId);

        // Make sure the time slot selected is still
        // valid for the selected vendor (CA-1223)
        const isSelectedTimeSlotValid =
          !!alreadySelectedTimeSlot &&
          vendor.availableTimeSlots.some((timeSlotByDate) =>
            timeSlotByDate.timeSlots.some(
              (timeSlot) =>
                timeSlot.date === alreadySelectedTimeSlot.timeSlot.date &&
                timeSlot.timeSlotId === alreadySelectedTimeSlot.timeSlot.timeSlotId,
            ),
          );

        if (!isSelectedTimeSlotValid) {
          const isFirstTimeSlotAvailable =
            vendor.availableTimeSlots &&
            vendor.availableTimeSlots.length > 0 &&
            vendor.availableTimeSlots[0].timeSlots &&
            vendor.availableTimeSlots[0].timeSlots.length > 0;

          if (isFirstTimeSlotAvailable) {
            this.setSelectedTimeSlotForVendor(vendor.catererId, {
              dateText: vendor.availableTimeSlots[0].dateText,
              timeSlot: vendor.availableTimeSlots[0].timeSlots[0],
            });
          }
        }
      });
    }
  }

  private maybeGetSourceDataFromGlobalSearchEvent({ vendorId, itemId }: { vendorId: number; itemId: number }): {
    sourceType: OrderMenuItemSourceType;
    sourceId: number;
    sourceNotes: string;
  } {
    if (!this.stateService.state?.searchEvent) {
      return null;
    }

    const isItemOpenedFromGlobalSearch = this.stateService.state.searchEvent.itemsOpened.some((item) => item === itemId);
    const isVendorOpenedFromGlobalSearch = this.stateService.state.searchEvent.vendorsOpened.some((vendor) => vendor === vendorId);

    if (!isItemOpenedFromGlobalSearch && !isVendorOpenedFromGlobalSearch) {
      return null;
    }

    return {
      sourceType: isItemOpenedFromGlobalSearch ? OrderMenuItemSourceType.GlobalSearchItem : OrderMenuItemSourceType.GlobalSearchVendor,
      sourceId: isItemOpenedFromGlobalSearch ? itemId : vendorId,
      sourceNotes: this.stateService.state.searchEvent.searchTerm,
    };
  }

  private maybeGetSourceDataFromVendorChatEvent({ vendorId }: { vendorId: number }): {
    sourceType: OrderMenuItemSourceType;
    sourceId: number;
    sourceNotes: string;
  } {
    const latestVendorChatEvent = this.stateService.state?.latestVendorChatEvent;

    if (!latestVendorChatEvent || latestVendorChatEvent.vendorId !== vendorId) {
      return null;
    }

    return {
      sourceType: OrderMenuItemSourceType.VendorDetailsChat,
      sourceId: vendorId,
      sourceNotes: latestVendorChatEvent.chatId,
    };
  }

  private initializeListeners(): void {
    // Listen to changes in the area/date/time and the cart to save the
    // order in progress in the storage
    merge(this.saveCartState$, this.cart$).subscribe(() => this.saveOrderInProgressInStorage());
  }

  private updateSelectedAddressAfterPlacingOrder(newSavedAddressId: number): void {
    // Update the savedAddressId if the user placed the order with a new address
    const address = this.stateService.state?.address;

    if (this.addressService.isNew(address)) {
      address.savedAddressId = newSavedAddressId;
      this.stateService.update({ address });
    }

    this.saveCartState$.next();
  }

  private getNewOrderMetadata(): OrderMetadata {
    const metadata: OrderMetadata = {
      appVersion: this.appService.getAppVersion(),
    };

    if (this.searchId) {
      metadata.searchId = this.searchId;
    }

    if (this.sorterTypeText) {
      metadata.vendorSorter = this.sorterTypeText;
    }

    const testGroups = this.settingsService.getTestGroups();

    if (testGroups?.length > 0) {
      metadata.group = testGroups.reduce((pv, cv) => ({ ...pv, [cv.id]: cv.value }), {});
    }

    return metadata;
  }

  private restoreCartFromPreviousSession(savedOrderInProgress: OrderInProgress): Cart {
    this.dateTimeUpdatedAutomaticallyPerVendor = savedOrderInProgress.dateTimeUpdatedAutomaticallyPerVendor ?? {};

    if (savedOrderInProgress.selectedDateTimePerVendor) {
      const vendorIds = Object.keys(savedOrderInProgress.selectedDateTimePerVendor).map((id) => +id);

      if (vendorIds?.length) {
        for (const vendorId of vendorIds) {
          const vendorDateTime = savedOrderInProgress.selectedDateTimePerVendor[vendorId];

          if (vendorDateTime) {
            const isStillValid = this.dateTimeService.isLocalTimeWithBufferSameOrBefore({
              dateTime: vendorDateTime,
              bufferMinutes: ADD_TO_CART_BUFFER_TIME_IN_MINUTES,
            });

            if (isStillValid) {
              this.setSelectedDateTimeForVendor(vendorId, vendorDateTime);
            }
          }
        }
      }
    }

    // Backwards compatibility check related to CA-1046
    // for users that already have a cart saved in the
    // storage from a previous version of the app
    for (const vendor of savedOrderInProgress.cart.caterers) {
      for (const item of vendor.items.filter((i) => i.sourceId == null || i.sourceType == null)) {
        item.sourceId = item.catererId;
        item.sourceType = OrderMenuItemSourceType.Vendor;
      }
    }

    return Helpers.clone(savedOrderInProgress.cart);
  }

  private satifiesDateTimeOrTimeSlotRequirement(newItem: NewCartItem): boolean {
    const dateTimeServiceTypes = this.settingsService.getServiceTypesBasedOnDateTime();
    const timeSlotServiceTypes = this.settingsService.getServiceTypesBasedOnTimeSlots();

    const serviceTypesFromCart = this.getServiceTypesAddedToCart();
    const itemsInCartSupportDateTime = serviceTypesFromCart.some((serviceType) => dateTimeServiceTypes.indexOf(serviceType) >= 0);
    const itemsInCartSupportTimeSlots = serviceTypesFromCart.some((serviceType) => timeSlotServiceTypes.indexOf(serviceType) >= 0);

    const newItemSupportsDateTime = dateTimeServiceTypes.indexOf(newItem.serviceType) >= 0;
    const newItemSupportsTimeSlots = timeSlotServiceTypes.indexOf(newItem.serviceType) >= 0;

    return newItemSupportsTimeSlots === itemsInCartSupportTimeSlots && newItemSupportsDateTime === itemsInCartSupportDateTime;
  }

  private satifiesOrderForNowRequirement(isForNow: boolean): boolean {
    return !!isForNow === this.isBilbaytNowCart();
  }

  private isDateTimeStillValid(dateTime: string): boolean {
    return dateTime && !this.dateTimeService.isBeforeCurrentLocalTime(dateTime);
  }

  private showClearCartConfirmationModal(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      void this.injector.get(ModalService).showAlert({
        title: this.translateService.instant('CLEAR_CART_CONFIRMATION_TITLE') as string,
        message: this.translateService.instant('CLEAR_CART_CONFIRMATION_MESSAGE') as string,
        buttons: [
          {
            isPrimary: true,
            text: this.translateService.instant('OK') as string,
            handler: () => resolve(true),
          },
          {
            text: this.translateService.instant('CANCEL') as string,
            handler: () => resolve(false),
          },
        ],
      });
    });
  }

  private updateSelectedPaymentClient(): void {
    const defaultPaymentMethod = this.getAvailablePaymentClients()[0];

    if (!this.paymentClient) {
      this.paymentClient = defaultPaymentMethod;
      return;
    }

    // Credit card and Apple Pay have the same PaymentClientId but different
    // payment methods so we must check both
    const isPaymentClientValid = this.getAvailablePaymentClients().some(
      (availablePaymentOption) =>
        availablePaymentOption.client === this.paymentClient.client &&
        availablePaymentOption.paymentMethod === this.paymentClient.paymentMethod,
    );

    if (!isPaymentClientValid) {
      this.paymentClient = defaultPaymentMethod;
      this.selectedCard = null;
    }
  }

  private updateSelectedAndAvailablePromotion(): Promise<void> {
    this.cartPromotions = (this.cartDetails?.discounts?.promotions || []).map((promotion) => ({ promotionValidationResult: promotion }));

    // If there was a bank promotion in the list of available promotions, we need
    // to check if the selected payment method was not changed
    this.otherPromotions = (this.otherPromotions || []).filter(
      (promotion) => !promotion.promotionCardId || (this.selectedCard && promotion.promotionCardId === this.selectedCard.cardId),
    );

    if (!this.canApplyDiscounts()) {
      this.selectedPromotion = null;
      return Promise.resolve();
    }

    const autoApplyPromotion = this.cartDetails?.discounts?.autoApplyPromotion?.isValid
      ? this.cartDetails.discounts.autoApplyPromotion
      : null;

    if (!this.selectedPromotion) {
      // The user didn't select a promotion before so we just need
      // to check if the API sent us a promotion to be auto applied
      this.selectedPromotion = autoApplyPromotion ? { promotionValidationResult: autoApplyPromotion } : null;
      return Promise.resolve();
    }

    // The user already selected a promotion before and then went back a few
    // pages. So we need to check if that promotion is one of the user promotions
    // or a new promotion entered manually.
    const promotionFromCart = this.cartPromotions.find(
      (promotion) => promotion.promotionValidationResult.promotionId === this.selectedPromotion.promotionValidationResult.promotionId,
    );

    if (promotionFromCart) {
      // The promotion was selected from the list of user promotions so
      // we don't need to validate anything since the API just validated
      // the user promotions. But we need to update the local promotion
      // since the 'cartDiscount' property may have changed if an item
      // was added/removed from the cart
      this.selectedPromotion = { promotionValidationResult: promotionFromCart.promotionValidationResult };
      return Promise.resolve();
    }

    if (
      this.selectedPromotion.promotionCardId &&
      (!this.selectedCard || this.selectedPromotion.promotionCardId !== this.selectedCard.cardId)
    ) {
      // The user applied a bank promotion but then changed the selected
      // payment method so we need to remove that promotion
      this.selectedPromotion = autoApplyPromotion ? { promotionValidationResult: autoApplyPromotion } : null;
      return Promise.resolve();
    }

    // We need to validate the promotion just in case if it's not valid
    // anymore or if the 'cartDiscount' property have changed
    // IMPORTANT: bank promotions cannot be validated using the validatePromotionCode()
    // method because they cannot be entered directly so we must use the checkBankPromotion()
    // method instead
    return toPromise(
      this.selectedPromotion.promotionCardId
        ? this.checkBankPromotion(this.selectedCard.firstSixDigits)
        : this.validatePromotionCode(this.selectedPromotion.promotionValidationResult.promotionCode),
    )
      .then((promotionValidationResult) => {
        if (promotionValidationResult?.isValid) {
          this.selectedPromotion = { ...this.selectedPromotion, promotionValidationResult };
          this.maybeAddOrUpdatePromotion(this.selectedPromotion);
        } else {
          this.selectedPromotion = autoApplyPromotion ? { promotionValidationResult: autoApplyPromotion } : null;
          this.maybeRemovePromotion(promotionValidationResult.promotionId, promotionValidationResult.promotionCode);
        }
      })
      .catch(() => {
        // We don't know if the promotion is valid or not because there was
        // an error when trying to validate it. So the best thing we can do
        // is to use the auto apply promotion if any.
        this.selectedPromotion = autoApplyPromotion ? { promotionValidationResult: autoApplyPromotion } : null;
      });
  }

  private getItemEta(vendorId: number, itemId: number): Eta {
    if (!this.cartDetails) {
      return null;
    }

    const cartVendor = this.cartDetails.caterers.find((vendor) => vendor.catererId === vendorId);

    if (!cartVendor) {
      return null;
    }

    const cartItem = cartVendor.items.find((item) => item.menuItemId === itemId);

    if (!cartItem || !cartItem.eta) {
      return null;
    }

    return cartItem.eta;
  }

  private maybeAddOrUpdatePromotion(newPromotion: ValidatedPromotion): void {
    // IMPORTANT: if the promotion already exists in the cart promotions then we don't
    // need to add/update anything as the cart validation API already validated and
    // updated the promotion amount BUT if it's not from the cart, we need to add it
    // or updated it because the total may have been changed if the cart was changed

    const existsInCartPromotions = this.cartPromotions.find(
      (promotion) => promotion.promotionValidationResult.promotionId === newPromotion.promotionValidationResult.promotionId,
    );

    if (existsInCartPromotions) {
      return;
    }

    const existingPromotionIndex = this.otherPromotions.findIndex(
      (promotion) => promotion.promotionValidationResult.promotionId === newPromotion.promotionValidationResult.promotionId,
    );

    if (existingPromotionIndex > -1) {
      this.otherPromotions[existingPromotionIndex] = {
        promotionValidationResult: newPromotion.promotionValidationResult,
        promotionCardId: newPromotion.promotionCardId || null,
      };
    } else {
      this.otherPromotions.push({ ...newPromotion });
    }
  }

  private maybeRemovePromotion(promotionId: number, promotionCode: string): void {
    this.cartPromotions = this.cartPromotions.filter((promotion) => promotion.promotionValidationResult.promotionId !== promotionId);
    this.otherPromotions = this.otherPromotions.filter((promotion) => promotion.promotionValidationResult.promotionId !== promotionId);
    this.maybeRemoveDeeplinkPromotion(promotionCode);
  }
}
