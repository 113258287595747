import { Injectable, OnDestroy } from '@angular/core';

import { combineLatest, filter, Subject, takeUntil } from 'rxjs';

import { AnalyticsService } from '../services/analytics.service';
import { AppService } from '../services/app.service';
import { StateService } from '../services/state.service';
import { UniversalLinksService } from '../services/universal-links.service';

import { selectNotNil } from '../operators/select-not-nil';
import { Effect } from './';

@Injectable({ providedIn: 'root' })
export class UniversalLinksEffects implements Effect, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private appService: AppService,
    private stateService: StateService,
    private analyticsService: AnalyticsService,
    private universalLinksService: UniversalLinksService,
  ) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  public initialize(): void {
    this.trackAppInstallEvent();
    this.trackAppOpenEvents();
    this.handleUniversalLinkRedirection();
  }

  private trackAppInstallEvent(): void {
    // Wait for the country to be set so that we track the right country in the
    // event — otherwise it would always track KW
    combineLatest([
      this.stateService.state$.pipe(selectNotNil((state) => state.pendingUniversalLink)),
      this.stateService.state$.pipe(selectNotNil((state) => state.country)),
    ])
      .pipe(
        filter(([link]) => link.isNewInstall),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(([link]) => {
        if (!this.universalLinksService.shouldBeAttributed(link)) {
          this.analyticsService.trackOrganicAppInstallEvent();
          return;
        }

        if (this.universalLinksService.isReferralProgramLink(link)) {
          this.analyticsService.trackAttributedReferralProgramAppInstallEvent(link);
          return;
        }

        this.analyticsService.trackAttributedAppInstallEvent(link);
      });
  }

  private trackAppOpenEvents(): void {
    this.stateService.state$
      .pipe(
        selectNotNil((state) => state.pendingUniversalLink),
        filter((link) => !link.isNewInstall),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((link) => {
        if (!this.universalLinksService.shouldBeAttributed(link)) {
          this.analyticsService.trackOrganicAppOpenEvent();
          return;
        }

        if (this.universalLinksService.isReferralProgramLink(link)) {
          this.analyticsService.trackAttributedReferralProgramAppOpenEvent(link);
          return;
        }

        if (this.universalLinksService.isModalAdLink(link)) {
          this.analyticsService.trackModalAdOpenEvent(link.campaignId, link.campaignSource);
          return;
        }

        if (this.universalLinksService.isBannerAdLink(link)) {
          this.analyticsService.trackBannerAdOpenEvent(link.campaignId, link.campaignSource);
          return;
        }

        this.analyticsService.trackAttributedAppOpenFromUniversalLinkEvent(link);
      });
  }

  private handleUniversalLinkRedirection(): void {
    this.stateService.state$
      .pipe(
        selectNotNil((state) => state.pendingUniversalLink),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((link) => {
        if (!this.universalLinksService.shouldRedirect(link)) {
          this.universalLinksService.clearPendingUniversalLink();
          return;
        }

        if (this.appService.isHomePageLoaded()) {
          // Links need to be handled in the AppComponent because otherwise
          // we don't have access to the router
          this.universalLinksService.processPendingUniversalLink$.next(link);
        }
      });
  }
}
