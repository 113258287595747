// import 'hammerjs';

import { Component, ElementRef, Inject, NgZone, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { PickerColumnOption, PickerOptions } from '@ionic/core';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AreaSelectionModalPage, AreaSelectionModalPageIdentifier } from '../../../modals/area-selection-modal/area-selection-modal.page';

import { Address } from '../../../core/models/address.model';

import { AddressService } from '../../../core/services/address.service';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { DateTimeService } from '../../../core/services/date-time.service';
import { ModalService } from '../../../core/services/modal.service';
import { OverlayService } from '../../../core/services/overlay.service';
import { PlatformService } from '../../../core/services/ssr/platform.service';

import { AnalyticsConfig, TOKEN_ANALYTICS_CONFIG } from '../../../core/config/analytics.config';

import { PickerComponent, PickerComponentSelectedColumnsDetails } from '../picker/picker.component';

import { Helpers } from '../../../core/helpers';

import { AreaDateTimePickerAnimations, ENTER_ANIMATION_TOTAL_DURATION } from './area-date-time-picker.animations';

const CUTTOF_TIME_IN_MINUTES = 60;
const DEFAULT_SELECTED_TIME = '12:00';

const DATE_TEXT_DEFAULT_FORMAT = 'ddd MMM D';
const DATE_VALUE_DEFAULT_FORMAT = 'DD/MM/YYYY';

const TIME_VALUE_ORDER_FOR_NOW = 'NOW';
const TIME_VALUE_DEFAULT_FORMAT = 'HH:mm';

const PICKER_DATE_FORMAT = 'MMMM D YYYY';
const PICKER_TIME_FORMAT = 'hh:mm A';

const BUTTON_DEFAULT_TRANSLATION_KEY = 'CONFIRM';

enum ColumnName {
  Date = 'date',
  Time = 'time',
}

export interface AreaDateTimePickerComponentParams {
  enableBackdropDismiss?: boolean;
  selectedAddress?: Address;
  selectedDateTime?: string;
  isOrderForNowSelected?: boolean;
  isOrderForNowAvailable?: boolean;
  selectedServiceType?: number;
  availableDateTimesAsync?: Observable<Array<DateTimeValues>>;
  hideAddressPicker?: boolean;
  buttonTranslationKey?: string;
}

export interface AreaDateTimePickerComponentResult {
  hasChangedData?: boolean;
  selectedAddress?: Address;
  selectedDateTime?: string;
  isOrderForNowSelected?: boolean;
  selectedSubmitButton?: boolean;
}

interface TimeValue {
  hour: number;
  minute: number;
}

interface DateTimeValues {
  date: string;
  times: Array<TimeValue>;
}

@Component({
  selector: 'app-area-date-time-picker',
  templateUrl: 'area-date-time-picker.component.html',
  styleUrls: ['area-date-time-picker.component.scss'],
  animations: [...AreaDateTimePickerAnimations],
  encapsulation: ViewEncapsulation.None,
})
export class AreaDateTimePickerComponent implements OnDestroy {
  @ViewChild(PickerComponent)
  public picker: PickerComponent;

  public newSelectedAddress: Address;
  public newSelectedDateTime: string;
  public newIsOrderForNowSelected: boolean;

  public areaText: string;
  public dateTimeText: string;
  public dateTimePickerOptions: PickerOptions;

  public isReady: boolean;
  public isVisible = false;
  public isOrderForNowAvailable = false;
  public isDatePickerLoading = false;
  public isDatePickerVisible = false;
  public isGettingAsyncValues = false;
  public hideAddressPicker = false;
  public buttonTranslationKey = BUTTON_DEFAULT_TRANSLATION_KEY;

  private selectedAddress: Address;
  private selectedDateTime: string;
  private isOrderForNowSelected: boolean;
  private enableBackdropDismiss = true;

  private availableDateTimeValuessFromAPI: Array<DateTimeValues>;

  private dateOptions: Array<PickerColumnOption>;
  private timeOptions: Array<PickerColumnOption> = [];

  private dateSelectedIndex: number;
  private timeSelectedIndex: number;

  // Property used so that the user cannot open the area selection
  // modal more than once because that could cause some issues
  // related to Google Maps being loaded multiple times
  // https://bilbayt.atlassian.net/browse/CA-1728
  private isAreaSelectionModalOpen: boolean;

  private resolveFunction: (params?: AreaDateTimePickerComponentResult) => void;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private ngZone: NgZone,
    private modalService: ModalService,
    private overlayService: OverlayService,
    private dateTimeService: DateTimeService,
    private translateService: TranslateService,
    private addressService: AddressService,
    private analyticsService: AnalyticsService,
    private platformService: PlatformService,
    @Inject(TOKEN_ANALYTICS_CONFIG) private analyticsConfig: AnalyticsConfig,
  ) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  public show(params?: AreaDateTimePickerComponentParams): Promise<AreaDateTimePickerComponentResult> {
    this.isReady = false;

    if (params) {
      this.selectedAddress = params.selectedAddress;
      this.selectedDateTime = params.selectedDateTime;
      this.isOrderForNowSelected = !!params.isOrderForNowSelected;
      this.isOrderForNowAvailable = !!params.isOrderForNowAvailable;
      this.enableBackdropDismiss = !!params.enableBackdropDismiss;
      this.hideAddressPicker = params.hideAddressPicker ?? false;
      this.buttonTranslationKey = params.buttonTranslationKey ?? BUTTON_DEFAULT_TRANSLATION_KEY;

      if (!this.isOrderForNowAvailable) {
        this.isOrderForNowSelected = false;
      }
    }

    this.isVisible = true;

    if (params?.availableDateTimesAsync) {
      this.isDatePickerVisible = true;
      this.isDatePickerLoading = true;

      params.availableDateTimesAsync.pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (availableDateTimeValuessFromAPI) => {
          this.availableDateTimeValuessFromAPI = availableDateTimeValuessFromAPI ?? [];
          this.initializePicker();
        },
        error: () => {
          this.availableDateTimeValuessFromAPI = [];
          this.initializePicker();
        },
      });
    } else {
      this.isDatePickerVisible = false;
      this.isDatePickerLoading = false;
      this.availableDateTimeValuessFromAPI = [];

      this.initializePicker();
    }

    return new Promise((resolve) => {
      this.resolveFunction = resolve;
    });
  }

  public dismiss(results?: AreaDateTimePickerComponentResult): void {
    // Do not allow the picker to be closed before
    // it's ready to avoid some js errors
    // https://bilbayt.atlassian.net/browse/CA-1645
    if (!this.isReady) {
      return;
    }

    this.isVisible = false;

    if (results) {
      results.selectedSubmitButton = true;
      this.trackChangeEvent(results);
    }

    if (this.resolveFunction) {
      this.resolveFunction(results || null);
      this.resolveFunction = null;
    }
  }

  public onHandleBackdropClick(): void {
    if (this.enableBackdropDismiss) {
      this.dismiss();
    }
  }

  public onHandleSubmitClick(): void {
    const results = this.getSelectedData();

    if (this.areResultsValid(results)) {
      this.dismiss(results);
    }
  }

  public onToggleDateTimePicker(): void {
    this.isDatePickerVisible = !this.isDatePickerVisible;

    if (this.isDatePickerVisible) {
      this.initializeDateTimePicker();
    }
  }

  public onOpenAreasModal(): void {
    if (this.isAreaSelectionModalOpen) {
      return;
    }

    this.isAreaSelectionModalOpen = true;

    const maybeToggleDateTimePicker = this.isDatePickerVisible ? this.platformService.wait(300) : Promise.resolve();

    if (this.isDatePickerVisible) {
      this.onToggleDateTimePicker();
    }

    void maybeToggleDateTimePicker.then(() => {
      void this.modalService
        .showCard<Address>({
          component: AreaSelectionModalPage,
          id: AreaSelectionModalPageIdentifier,
          canDismiss: true,
          presentingElement: this.elementRef.nativeElement,
          componentProps: {
            selectedAddress: this.newSelectedAddress,
          },
        })
        .then((address) => {
          this.isAreaSelectionModalOpen = false;

          if (address) {
            this.newSelectedAddress = address;
            this.updateAreaText();
          }
        });
    });
  }

  public onUpdateSelectedDateAndTime(pickerValues: PickerComponentSelectedColumnsDetails): void {
    const hasChangedDate = pickerValues[ColumnName.Date].changed;
    const selectedDateIndex = pickerValues[ColumnName.Date].index;
    const selectedTimeIndex = pickerValues[ColumnName.Time].index;

    const isToday = this.isToday({ datePickerIndex: selectedDateIndex });
    const wasToday = this.isToday({ datePickerIndex: this.dateSelectedIndex });
    const isTomorrow = this.isTomorrow({ datePickerIndex: selectedDateIndex });

    // We only need to update the picker if:
    // a) the user changed from today <-> another day
    // b) the user changed the date and we are getting the date and times from the API
    const shouldUpdateTimePicker = hasChangedDate && (isToday !== wasToday || this.availableDateTimeValuessFromAPI?.length);

    this.ngZone.run(() => {
      if (!shouldUpdateTimePicker) {
        this.dateSelectedIndex = selectedDateIndex;
        this.timeSelectedIndex = selectedTimeIndex;
      } else {
        // Keep the selected time so we can select it again after
        // updating the picker options
        const timeValueBeforeChange = this.timeOptions[this.timeSelectedIndex].value as string;

        this.timeOptions = this.getTimeOptions({
          forToday: isToday,
          forTomorrow: isTomorrow,
          selectedDateIndexFromApi: selectedDateIndex,
        });

        // If the user already selected a given time before changing the date
        // and we added/removed the "Now" option, we need to select the
        // same time since the index will be different now. Otherwise select
        // the default time or just the first available option from the picker

        let newSelectedTimeIndex: number;

        const newIndexOfPrevTimeValue = this.timeOptions.findIndex((timeOption) => timeOption.value === timeValueBeforeChange);

        if (newIndexOfPrevTimeValue !== -1) {
          newSelectedTimeIndex = newIndexOfPrevTimeValue;
        } else {
          const defaultSelectedTimeIndex = this.timeOptions.findIndex((timeOption) => timeOption.value === DEFAULT_SELECTED_TIME);

          newSelectedTimeIndex = defaultSelectedTimeIndex !== -1 ? defaultSelectedTimeIndex : 0;
        }

        const timeColumn = {
          name: ColumnName.Time,
          cssClass: 'time-column',
          selectedIndex: newSelectedTimeIndex,
          options: this.timeOptions.map((option) => ({
            text: option.text,
            value: option.value as string,
          })),
        };

        // Update the picker
        this.dateTimePickerOptions = {
          columns: [this.dateTimePickerOptions.columns[0], timeColumn],
        };

        this.dateSelectedIndex = selectedDateIndex;
        this.timeSelectedIndex = newSelectedTimeIndex;
      }

      this.newIsOrderForNowSelected = this.isNow({ timePickerIndex: this.timeSelectedIndex });

      this.updateNewSelectedDateTime();
      this.updateDateTimeText();
    });
  }

  private getSelectedData(): AreaDateTimePickerComponentResult {
    return {
      hasChangedData: this.hasChangedData(),
      selectedAddress: this.newSelectedAddress,
      selectedDateTime: this.newIsOrderForNowSelected ? null : this.newSelectedDateTime,
      isOrderForNowSelected: this.newIsOrderForNowSelected,
    };
  }

  private getTimeOptions({
    forToday,
    forTomorrow,
    selectedDateIndexFromApi,
  }: {
    forToday: boolean;
    forTomorrow: boolean;
    selectedDateIndexFromApi?: number;
  }): Array<PickerColumnOption> {
    const timeOptions: Array<PickerColumnOption> = [];

    if (forToday && this.isOrderForNowAvailable) {
      timeOptions.push({
        text: this.translateService.instant('NOW') as string,
        value: TIME_VALUE_ORDER_FOR_NOW,
      });
    }

    let availableValues: Array<TimeValue>;

    if (selectedDateIndexFromApi != null && this.availableDateTimeValuessFromAPI?.length > selectedDateIndexFromApi) {
      availableValues = this.availableDateTimeValuessFromAPI[selectedDateIndexFromApi].times;
    } else {
      availableValues = this.getAllAvailableTimes();
    }

    availableValues.forEach(({ hour, minute }) => {
      // We need to check the hour and minutes against the current time today
      // AND tomorrow to handle properly what happens when opening the picker
      // near midnight (before and after)
      const isValid =
        forToday || forTomorrow
          ? this.dateTimeService.isTimeSameOrAfterCurrentLocalTime(hour, minute, CUTTOF_TIME_IN_MINUTES, forTomorrow)
          : true;

      if (isValid) {
        timeOptions.push({
          text: `${hour}:${Helpers.padNumber(minute)}`,
          value: `${Helpers.padNumber(hour)}:${Helpers.padNumber(minute)}`,
        });
      }
    });

    return timeOptions;
  }

  private getDateOptions(): Array<PickerColumnOption> {
    const dateOptions: Array<PickerColumnOption> = [];

    const availableValues = this.availableDateTimeValuessFromAPI?.length
      ? this.availableDateTimeValuessFromAPI.map((dateTimes) => dateTimes.date)
      : this.dateTimeService.getCalendarDays();

    availableValues.forEach((date, index) => {
      const isToday = index === 0 && this.dateTimeService.isToday(date);

      // 23:30 is the last possible time for today so we need to check if
      // the current time + cutoff time is still sooner than that
      const isValidDate = isToday ? this.dateTimeService.isTimeSameOrAfterCurrentLocalTime(23, 30, CUTTOF_TIME_IN_MINUTES) : true;

      if (isValidDate) {
        const dateValue = this.dateTimeService.format({ dateTimeIso: date, format: DATE_VALUE_DEFAULT_FORMAT });
        const dateText = this.dateTimeService.format({ dateTimeIso: date, format: DATE_TEXT_DEFAULT_FORMAT, useRelativeDate: true });

        dateOptions.push({ text: dateText, value: dateValue });
      }
    });

    return dateOptions;
  }

  private initializeAreaDateTimeOptions(): void {
    this.dateOptions = this.getDateOptions();

    // First decide the available options for the date column
    if (this.selectedDateTime) {
      const dateSelectedIndex = this.dateOptions.findIndex(
        (dateOption) =>
          (dateOption.value as string) ===
          this.dateTimeService.format({ dateTimeIso: this.selectedDateTime, format: DATE_VALUE_DEFAULT_FORMAT }),
      );

      this.dateSelectedIndex = dateSelectedIndex !== -1 ? dateSelectedIndex : 0;
    } else {
      this.dateSelectedIndex = 0;
    }

    this.timeOptions = this.getTimeOptions({
      forToday: this.isToday({ datePickerIndex: this.dateSelectedIndex }),
      forTomorrow: this.isTomorrow({ datePickerIndex: this.dateSelectedIndex }),
      selectedDateIndexFromApi: this.dateSelectedIndex,
    });

    // Then decide the available options for the date column
    if (this.selectedDateTime) {
      const timeSelectedIndex = this.timeOptions.findIndex(
        (timeOption) =>
          timeOption.value === this.dateTimeService.format({ dateTimeIso: this.selectedDateTime, format: TIME_VALUE_DEFAULT_FORMAT }),
      );

      this.timeSelectedIndex = timeSelectedIndex !== -1 ? timeSelectedIndex : 0;
    } else {
      if (this.isNowAvailable()) {
        this.timeSelectedIndex = 0;
      } else {
        const defaultSelectedTimeIndex = this.timeOptions.findIndex((timeOption) => timeOption.value === DEFAULT_SELECTED_TIME);
        this.timeSelectedIndex = defaultSelectedTimeIndex !== -1 ? defaultSelectedTimeIndex : 0;
      }
    }

    // It may happen that the user already selected the date/time but after some time
    // the selected values may not be avialible anymore. Since the code above validates
    // that we always need to use the dateSelectedIndex and timeSelectedIndex properties
    // instead of the selectedDate and selectedTime properties sent
    // https://bilbayt.atlassian.net/browse/CA-1903
    // https://bilbayt.atlassian.net/browse/CA-1907

    this.newSelectedAddress = Helpers.clone(this.selectedAddress);

    // We don't need to check this.isOrderForNowSelected because order for now will be
    // selected by default if it's available
    this.newIsOrderForNowSelected = this.isNow({ timePickerIndex: this.timeSelectedIndex });

    this.updateNewSelectedDateTime();
    this.updateAreaText();
    this.updateDateTimeText();
  }

  private updateNewSelectedDateTime() {
    const selectedDateValue = this.dateOptions[this.dateSelectedIndex].value as string;
    const selectedTimeValue = this.timeOptions[this.timeSelectedIndex].value as string;

    this.newSelectedDateTime = this.newIsOrderForNowSelected
      ? null
      : this.dateTimeService.convertToDateTimeIsoString(
          `${selectedDateValue} ${selectedTimeValue}`,
          `${DATE_VALUE_DEFAULT_FORMAT} ${TIME_VALUE_DEFAULT_FORMAT}`,
        );
  }

  private initializeDateTimePicker(): void {
    this.isDatePickerLoading = true;

    const dateColumn = {
      name: ColumnName.Date,
      cssClass: 'date-column',
      selectedIndex: this.dateSelectedIndex,
      options: this.dateOptions.map((option) => ({
        text: option.text,
        value: option.value as string,
      })),
    };

    const timeColumn = {
      name: ColumnName.Time,
      cssClass: 'time-column',
      selectedIndex: this.timeSelectedIndex,
      options: this.timeOptions.map((option) => ({
        text: option.text,
        value: option.value as string,
      })),
    };

    this.dateTimePickerOptions = {
      columns: [dateColumn, timeColumn],
    };

    // Rendering ~90 picker colums for the date section could
    // create some lag so it's better to initialize the colums
    // while they are hidden
    void this.platformService.wait(500).then(() => (this.isDatePickerLoading = false));
  }

  private updateDateTimeText(): void {
    const selectedNow = this.isNow({ timePickerIndex: this.timeSelectedIndex });
    const selectedToday = this.isToday({ datePickerIndex: this.dateSelectedIndex });

    const dateText = this.newSelectedDateTime
      ? this.dateTimeService.format({ dateTimeIso: this.newSelectedDateTime, format: PICKER_DATE_FORMAT, useRelativeDate: true })
      : null;

    const timeText = selectedNow
      ? (this.translateService.instant('NOW') as string)
      : this.dateTimeService.format({ dateTimeIso: this.newSelectedDateTime, format: PICKER_TIME_FORMAT });

    this.ngZone.run(() => (this.dateTimeText = selectedToday && selectedNow ? timeText : `${dateText} @ ${timeText}`));
  }

  private updateAreaText(): void {
    const areaText = this.newSelectedAddress?.area ? this.newSelectedAddress?.area.fullName : null;
    const addressAreaText = this.newSelectedAddress ? this.newSelectedAddress.area.fullName : null;

    this.areaText = areaText || addressAreaText;
  }

  private hasChangedData(): boolean {
    return (
      this.addressService.hasLocationChanged(this.selectedAddress, this.newSelectedAddress) ||
      this.dateTimeService.hasChangedDateOrTime(this.newSelectedDateTime, this.selectedDateTime) ||
      this.newIsOrderForNowSelected !== this.isOrderForNowSelected
    );
  }

  private isNow({ timePickerIndex }: { timePickerIndex: number }): boolean {
    return this.timeOptions[timePickerIndex].value === TIME_VALUE_ORDER_FOR_NOW;
  }

  private isNowAvailable(): boolean {
    return this.timeOptions[0].value === TIME_VALUE_ORDER_FOR_NOW;
  }

  private isToday({ datePickerIndex }: { datePickerIndex: number }): boolean {
    return this.dateTimeService.isToday(
      this.dateTimeService.convertToDateTimeIsoString(this.dateOptions[datePickerIndex].value as string, DATE_VALUE_DEFAULT_FORMAT),
    );
  }

  private isTomorrow({ datePickerIndex }: { datePickerIndex: number }): boolean {
    return this.dateTimeService.isTomorrow(
      this.dateTimeService.convertToDateTimeIsoString(this.dateOptions[datePickerIndex].value as string, DATE_VALUE_DEFAULT_FORMAT),
    );
  }

  private areResultsValid(data: AreaDateTimePickerComponentResult): boolean {
    const address = data.selectedAddress;
    const dateTime = data.selectedDateTime;
    const isOrderForNowSelected = data.isOrderForNowSelected;

    const showToastMessage = (message: string) => this.overlayService.showToast({ message, showCloseButton: true, type: 'error' });

    if (isOrderForNowSelected) {
      if (!address && !this.hideAddressPicker) {
        const errorMessage = this.translateService.instant('AREA_DATE_TIME_PICKER.ERROR_EMPTY_AREA') as string;
        showToastMessage(errorMessage);
        return false;
      }
    }

    if (!isOrderForNowSelected) {
      if (!dateTime) {
        const errorMessage = this.translateService.instant('AREA_DATE_TIME_PICKER.ERROR_EMPTY_DATE_TIME') as string;
        showToastMessage(errorMessage);
        return false;
      }

      if (this.dateTimeService.isBeforeCurrentLocalTime(dateTime)) {
        const errorMessage = this.translateService.instant('AREA_DATE_TIME_PICKER.ERROR_INVALID_DATE_TIME') as string;
        showToastMessage(errorMessage);
        return false;
      }

      if (!address && !this.hideAddressPicker) {
        const errorMessage = this.translateService.instant('AREA_DATE_TIME_PICKER.ERROR_EMPTY_AREA') as string;
        showToastMessage(errorMessage);
        return false;
      }
    }

    return true;
  }

  private trackChangeEvent(results: AreaDateTimePickerComponentResult): void {
    void this.analyticsService.trackEvent({
      name: this.analyticsConfig.eventName.areaDateTimePickerChange,
      data: {
        [this.analyticsConfig.propertyName.areaId]: results.selectedAddress.areaId,
        [this.analyticsConfig.propertyName.dateTime]: results.selectedDateTime ?? null,
        [this.analyticsConfig.propertyName.bilbaytNow]: results.isOrderForNowSelected ?? false,
      },
      includeAreaDateTime: true,
    });
  }

  private getAllAvailableTimes(): Array<TimeValue> {
    const timeValues: Array<TimeValue> = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute <= 30; minute += 30) {
        timeValues.push({ hour, minute });
      }
    }

    return timeValues;
  }

  private initializePicker(): void {
    this.initializeAreaDateTimeOptions();

    void this.platformService.wait(this.hideAddressPicker ? 100 : ENTER_ANIMATION_TOTAL_DURATION).then(() => {
      // Expand the date/time section automatically when showing the picker
      // https://bilbayt.atlassian.net/browse/CA-1680
      this.isDatePickerVisible = true;
      this.initializeDateTimePicker();

      this.isReady = true;
    });
  }
}
