import { EmailComposer, EmailComposerOptions } from '@awesome-cordova-plugins/email-composer/ngx';

import { PlatformService } from '../ssr/platform.service';
import { WindowService } from '../ssr/window.service';

class BrowserEmailComposerProvider extends EmailComposer {
  constructor(private windowService: WindowService) {
    super();
  }

  public hasAccount(): Promise<unknown> {
    return Promise.resolve(true);
  }

  public hasClient(_app?: string): Promise<unknown> {
    return Promise.resolve(true);
  }

  public open(options: EmailComposerOptions): Promise<unknown> {
    if (this.windowService.isWindowDefined) {
      location.href = `mailto:${options.to}?subject=${options.subject}`;
    }

    return Promise.resolve(true);
  }
}

class MobileEmailComposerProvider extends EmailComposer {}

function emailComposerFactory(windowService: WindowService, platformService: PlatformService): EmailComposer {
  return platformService.areCordovaPluginsAvailable ? new MobileEmailComposerProvider() : new BrowserEmailComposerProvider(windowService);
}

export const emailComposerProvider = { provide: EmailComposer, useFactory: emailComposerFactory, deps: [WindowService, PlatformService] };
