import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { PipesModule } from '../../shared/pipes/pipes.module';

import { AnimatedImageComponentModule } from '../../shared/components/animated-image/animated-image.module';
import { AreaDateTimePickerComponentModule } from '../../shared/components/area-date-time-picker/area-date-time-picker.module';
import { GroceryItemDetailsModalComponent } from './grocery-item-details-modal.component';

import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [GroceryItemDetailsModalComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule.forChild(),
    AnimatedImageComponentModule,
    AreaDateTimePickerComponentModule,
    PipesModule,
    SwiperModule,
  ],
  exports: [GroceryItemDetailsModalComponent],
})
export class GroceryItemDetailsModalComponentModule {}
