<ng-template #groceryItemImage>
  <div class="item-image-container">
    <div class="item-image">
      <swiper
        #swiper
        class="item-image-slider"
        (swiper)="onSwiperLoaded($event)"
        [pagination]="itemDetails.imageUrls.length > 1 ? { clickable: true } : false"
        [dir]="sliderScrollingDirection"
      >
        <ng-template swiperSlide *ngFor="let imageUrl of itemDetails.imageUrls">
          <app-animated-image tappable class="image" [imageUrl]="imageUrl" (click)="onOpenImagesPreviewModalPage()"></app-animated-image>
        </ng-template>
      </swiper>
    </div>

    <div class="header-buttons-end">
      <ion-button @animatedImageButton tappable class="share-button" (click)="onShare()">
        <ion-icon src="/assets/custom-icons/shared/share.svg"></ion-icon>
      </ion-button>
      <ion-button
        tappable
        @animatedImageButton
        class="wishlist-button"
        *ngIf="!isUpdatingWishlistItem && isLoggedIn"
        (click)="onShowWishlists($event)"
      >
        <ion-icon src="/assets/custom-icons/shared/heart.svg"></ion-icon>
      </ion-button>
    </div>

    <ion-button @animatedImageButton tappable class="dismiss-button" (click)="onDismiss()">
      <ion-icon src="/assets/custom-icons/b-times.svg"></ion-icon>
    </ion-button>
  </div>
</ng-template>

<ng-template #groceryItemFooter>
  <div class="item-footer" *ngIf="itemDetails">
    <div class="item-footer-row">
      <p class="name">{{ itemDetails.name }}</p>
      <p data-cy="item-total" class="total">{{ itemDetails.selectedQuantity * itemDetails.price | localCurrency }}</p>
    </div>
    <div class="item-footer-row">
      <div class="quantity-selector" *ngIf="itemDetails.inStock">
        <ion-button
          class="quantity-selector-button"
          [class.disabled]="isUpdatingCartItem ? itemDetails.selectedQuantity === 0 : itemDetails.selectedQuantity === 1"
          (click)="onDecrementItemQuantity()"
        >
          <ion-icon
            slot="icon-only"
            class="quantity-selector-button-icon"
            src="/assets/custom-icons/item-details-modal/decrement.svg"
          ></ion-icon>
        </ion-button>

        <p class="quantity-selector-text" data-cy="item-quantity">{{ itemDetails.selectedQuantity }}</p>

        <ion-button
          class="quantity-selector-button"
          [class.disabled]="itemDetails.selectedQuantity === itemDetails.quantity"
          data-cy="increment-quantity-button"
          (click)="onIncrementItemQuantity()"
        >
          <ion-icon
            slot="icon-only"
            class="quantity-selector-button-icon"
            src="/assets/custom-icons/item-details-modal/increment.svg"
          ></ion-icon>
        </ion-button>
      </div>

      <ng-container *ngIf="!isUpdatingWishlistItem">
        <ion-button
          color="green"
          expand="block"
          class="add-to-cart-button"
          data-cy="add-to-cart-button"
          *ngIf="itemDetails.inStock && (!isUpdatingCartItem || itemDetails.selectedQuantity !== 0)"
          (click)="onAddToCart()"
        >
          <div class="add-to-cart-button-content">
            {{ (isUpdatingCartItem ? "MENU_ITEM_DETAILS_PAGE.UPDATE_CART" : "MENU_ITEM_DETAILS_PAGE.ADD_TO_CART") | translate }}
          </div>
        </ion-button>

        <ion-button
          disabled
          color="red"
          expand="block"
          class="add-to-cart-button"
          *ngIf="!itemDetails.inStock && (!isUpdatingCartItem || itemDetails.selectedQuantity !== 0)"
        >
          <span>{{ "OUT_OF_STOCK" | translate }}</span>
        </ion-button>

        <ion-button
          color="red"
          expand="block"
          class="remove-from-cart-button"
          data-cy="remove-from-cart-button"
          *ngIf="isUpdatingCartItem && itemDetails.selectedQuantity === 0"
          (click)="onRemoveFromCart()"
        >
          <span>{{ "MENU_ITEM_DETAILS_PAGE.REMOVE_FROM_CART" | translate }}</span>
        </ion-button>
      </ng-container>

      <ion-button
        *ngIf="isUpdatingWishlistItem"
        color="green"
        expand="block"
        class="add-to-cart-button"
        (click)="onUpdateExistingWishlistItem()"
      >
        <div class="add-to-cart-button-content">
          <span>{{ "MENU_ITEM_DETAILS_PAGE.UPDATE_WISHLIST" | translate }}</span>
        </div>
      </ion-button>
    </div>
  </div>
</ng-template>

<ng-template #groceryItemDescription>
  <div class="item-description" data-cy="item-description">
    <span *ngIf="fullDescriptionFormatted; else noDescription" [innerHtml]="fullDescriptionFormatted"></span>

    <ng-template #noDescription>
      <span class="no-description">{{ "MENU_ITEM_DETAILS_PAGE.NO_DESCRIPTION" | translate }}</span>
    </ng-template>
  </div>
</ng-template>

<ng-container *ngIf="!isBrowser">
  <ion-content>
    <div class="item-details-container">
      <div *ngIf="itemDetails" class="item-details grocery-item-details">
        <ng-template [ngTemplateOutlet]="groceryItemImage"></ng-template>
        <ng-template [ngTemplateOutlet]="groceryItemDescription"></ng-template>
      </div>
    </div>
  </ion-content>

  <ion-footer *ngIf="!shouldHideFooter && itemDetails">
    <ion-toolbar>
      <ng-template [ngTemplateOutlet]="groceryItemFooter"></ng-template>
    </ion-toolbar>
  </ion-footer>
</ng-container>

<ng-container *ngIf="isBrowser">
  <div class="inner-content">
    <div class="item-details-container">
      <div *ngIf="itemDetails" class="item-details grocery-item-details">
        <ng-template [ngTemplateOutlet]="groceryItemImage"></ng-template>
        <ng-template [ngTemplateOutlet]="groceryItemDescription"></ng-template>
        <ng-template [ngTemplateOutlet]="groceryItemFooter"></ng-template>
      </div>
    </div>
  </div>
</ng-container>

<ion-modal
  class="wishlists multi-platform"
  *ngIf="!!itemDetails"
  [class.no-wishlists]="!wishlists || wishlists.length === 0"
  [isOpen]="isWishlistsModalOpen"
  (didDismiss)="isWishlistsModalOpen = false"
>
  <ng-template>
    <div class="inner-content">
      <div class="empty-lists-content" *ngIf="!wishlists || wishlists.length === 0">
        <img class="empty-lists-image" src="/assets/img/wishlists/illustration.svg" />
        <p class="empty-lists-title" [innerHTML]="'WISHLISTS_PAGE.EMPTY_LISTS_TITLE' | translate"></p>
        <p class="empty-lists-message" [innerHTML]="'WISHLISTS_PAGE.EMPTY_LISTS_MESSAGE' | translate"></p>
      </div>

      <ng-container *ngIf="wishlists?.length">
        <p class="wishlists-title">{{ "WISHLISTS_PAGE.ADD_TO_LIST" | translate }}</p>

        <ion-button
          expand="block"
          color="grey"
          class="custom-button wishlist"
          *ngFor="let wishlist of wishlists"
          (click)="onAddItemToWishlist(wishlist)"
        >
          <div class="wishlist-details">
            <div
              @animatedWishlistName
              class="wishlist-status"
              *ngIf="wishlist.wishListId !== updatingWishlistId && wishlist.wishListId !== updatedWishlistId"
            >
              <p class="wishlist-name">{{ wishlist.title }}</p>
            </div>

            <div @animatedWishlistLoadingIcon class="wishlist-status" *ngIf="wishlist.wishListId === updatingWishlistId">
              <ion-spinner duration="1000" name="lines-sharp-small" class="wishlist-loading-icon"></ion-spinner>
            </div>

            <div @animatedWishlistSuccessIcon class="wishlist-status" *ngIf="wishlist.wishListId === updatedWishlistId">
              <ion-icon class="wishlist-success-icon" src="/assets/custom-icons/item-details-modal/add-to-wishlists-check.svg"></ion-icon>
            </div>
          </div>
        </ion-button>
      </ng-container>

      <ion-button (click)="onAddItemToNewWishlist()" expand="block" class="custom-button" color="green">
        {{ "WISHLISTS_PAGE.NEW_LIST" | translate }}
      </ion-button>
    </div>
  </ng-template>
</ion-modal>
