import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { VendorCartModalPage } from './vendor-cart-modal.page';

import { CartDetailsComponentModule } from '../../shared/components/cart-details/cart-details.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule.forChild(), CartDetailsComponentModule],
  declarations: [VendorCartModalPage],
})
export class VendorCartModalPageModule {}
