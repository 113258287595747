import { transition, trigger } from '@angular/animations';

import {
  AnimationTransition,
  applyFadeInAnimation,
  applyFadeInExpandHeightPaddingMarginAnimation,
  applyFadeOutAnimation,
  applyFadeOutCollapseHeightPaddingMarginAnimation,
} from '../../core/animations';

export const LogInModalPageAnimations = [
  trigger('animatedInputField', [
    transition(AnimationTransition.VoidToAny, applyFadeInExpandHeightPaddingMarginAnimation({ duration: 400 })),
    transition(AnimationTransition.AnyToVoid, applyFadeOutCollapseHeightPaddingMarginAnimation({ duration: 400 })),
  ]),
  trigger('animatedErrorMessage', [
    transition(AnimationTransition.VoidToAny, applyFadeInAnimation({ duration: 400 })),
    transition(AnimationTransition.AnyToVoid, applyFadeOutAnimation({ duration: 400 })),
  ]),
];
