import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { StateService } from './state.service';

export const MAX_HOURS_TO_KEEP_DATA = 96;

@Injectable({ providedIn: 'root' })
export class AppService {
  // Do not change the appVersion declaration since there is
  // an Ionic hook that keeps this value synced with the
  // version attribute of the config.xml file
  // https://ionicframework.com/docs/cli/configuration#hooks
  private appVersion = '2.47.4';

  private appReady = new BehaviorSubject<boolean>(false);
  private appInitError = new BehaviorSubject<boolean>(false);
  private translationsLoaded = new BehaviorSubject<boolean>(false);

  constructor(private stateService: StateService) {}

  public get isAppReady(): boolean {
    return this.appReady.getValue();
  }

  public get appReady$(): Observable<boolean> {
    return this.appReady.asObservable();
  }

  public get appInitError$(): Observable<boolean> {
    return this.appInitError.asObservable();
  }

  public get areTranslationsLoaded(): boolean {
    return this.translationsLoaded.getValue();
  }

  public get loadTranslations$(): Observable<boolean> {
    return this.translationsLoaded.asObservable();
  }

  public isHomePageLoaded(): boolean {
    return !!this.stateService.state?.homePageLoaded;
  }

  public getAppVersion(): string {
    return this.appVersion;
  }

  public updateAppReadyStatus(initializedSuccessfully: boolean): void {
    this.appReady.next(initializedSuccessfully);
    this.appInitError.next(!initializedSuccessfully);
  }

  public updateTranslationsLoadedStatus(loaded: boolean): void {
    this.translationsLoaded.next(loaded);
  }

  public setHomePageLoaded(): void {
    this.stateService.update({ homePageLoaded: true });
  }
}
