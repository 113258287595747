import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, merge, Observable, of, timer } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { ActiveOrderVendorDetails } from '../models/active-order-vendor-details.model';
import { ActiveOrderVendor } from '../models/active-order-vendor.model';
import { CartResponse } from '../models/cart-response.model';
import { HttpQueryStringParams } from '../models/http-query-string-param.model';
import { OrderReview } from '../models/order-review.model';
import { Order } from '../models/order.model';
import { PaginatedResponse } from '../models/paginated-response.model';
import { UnreviewedOrderDetails } from '../models/unreviewed-order-details.model';

import { AccountService } from './account.service';
import { AppEventsService } from './app-events.service';
import { AppService } from './app.service';
import { LoggerService } from './logger.service';
import { PlatformService } from './ssr/platform.service';

import { AppConfig, TOKEN_CONFIG } from '../config/app.config';

const ACTIVE_ORDERS_REFRESH_INTERVAL_IN_MS = 30_000;

declare type ActiveOrderVendorSourceDetails = { count: number; vendors: Array<ActiveOrderVendor> };

@Injectable({ providedIn: 'root' })
export class OrdersService {
  public activeOrders$: Observable<ActiveOrderVendorSourceDetails>;

  private activeOrdersSource$: BehaviorSubject<ActiveOrderVendorSourceDetails>;

  private lastUnreviewedOrderDetails: UnreviewedOrderDetails;
  private alreadyShownLastUnreviewedOrderPromptInCurrentSession = false;

  constructor(
    private http: HttpClient,
    private appService: AppService,
    private accountService: AccountService,
    private loggerService: LoggerService,
    private appEventsService: AppEventsService,
    private platformService: PlatformService,
    @Inject(TOKEN_CONFIG) private config: AppConfig,
  ) {
    this.activeOrdersSource$ = new BehaviorSubject<ActiveOrderVendorSourceDetails>({ count: 0, vendors: [] });
    this.activeOrders$ = this.activeOrdersSource$.asObservable();

    this.appService.appReady$
      .pipe(
        filter((isReady) => !!isReady),
        take(1),
      )
      .subscribe(() => this.initializeActiveOrdersListener());
  }

  public getOrders(pageNumber: number = 1, pageSize: number = 20, includeCancelledOrders = true): Observable<PaginatedResponse<Order>> {
    const url = this.config.routes.getOrders.url;
    const params: HttpQueryStringParams = {
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      includeCancelledOrders: includeCancelledOrders.toString(),
    };

    return this.http.get<PaginatedResponse<Order>>(url, { params });
  }

  public getOrderDetails(orderId: number): Observable<Order> {
    const url = this.config.routes.getOrderDetails.url.replace('#orderId#', orderId.toString());
    return this.http.get<Order>(url);
  }

  public getVendorOrderDetails(orderId: number, vendorId: number): Observable<CartResponse> {
    const url = this.config.routes.getReOrderDetails.url
      .replace('#orderId#', orderId.toString())
      .replace('#vendorId#', vendorId.toString());

    return this.http.get<CartResponse>(url);
  }

  public loadLastUnreviewedOrder(): Observable<void> {
    if (!this.accountService.isLoggedIn()) {
      return of(null);
    }

    const url = this.config.routes.getLastUnreviewedOrder.url;
    return this.http.get<UnreviewedOrderDetails>(url).pipe(
      tap((orderDetails) => (this.lastUnreviewedOrderDetails = orderDetails)),
      catchError(() => of(null)),
      map(() => {}),
    );
  }

  public resetLastUnreviewedOrder(): void {
    this.lastUnreviewedOrderDetails = null;
  }

  public getLastUnreviewedOrderDetailsModel(): UnreviewedOrderDetails {
    return this.lastUnreviewedOrderDetails;
  }

  public shouldShowLastUnreviewedOrderPrompt(): boolean {
    return !this.alreadyShownLastUnreviewedOrderPromptInCurrentSession && !!this.lastUnreviewedOrderDetails;
  }

  public markLastUnreviewedOrderPromptAsAlreadyShown(): void {
    this.alreadyShownLastUnreviewedOrderPromptInCurrentSession = true;
  }

  public getActiveOrders(): Observable<Array<ActiveOrderVendor>> {
    const url = this.config.routes.getUserActiveOrders.url;
    return this.http.get<Array<ActiveOrderVendor>>(url);
  }

  public getOrderTrackDetails(orderId: number, vendorId: number): Observable<ActiveOrderVendorDetails> {
    const url = this.config.routes.getOrderTrackDetails.url
      .replace('#orderId#', orderId.toString())
      .replace('#vendorId#', vendorId.toString());

    return this.http.get<ActiveOrderVendorDetails>(url);
  }

  public submitOrderReview(orderId: number, reviews: Array<OrderReview>): Observable<void> {
    const url = this.config.routes.putOrderReview.url.replace('#orderId#', orderId.toString());
    return this.http.put<void>(url, reviews);
  }

  public submitSpecialRequest(orderId: number, catererId: number, request: string): Observable<void> {
    const url = this.config.routes.putOrderSpecialRequest.url.replace('#orderId#', orderId.toString());
    const data = { orderId, catererId, specialRequest: request };

    // Horrible workaround for non json responses
    // GITHUB ISSUE: https://github.com/angular/angular/issues/18586
    return this.http.put<void>(url, data, { responseType: 'text' as 'json' });
  }

  public cancelOrder(orderId: number): Observable<void> {
    const url = this.config.routes.putOrderCancellation.url.replace('#orderId#', orderId.toString());

    // Horrible workaround for non json responses
    // GITHUB ISSUE: https://github.com/angular/angular/issues/18586
    return this.http.put<void>(url, null, { responseType: 'text' as 'json' });
  }

  private initializeActiveOrdersListener(): void {
    // Interval and timer operators doesn't work in SSR
    // https://stackoverflow.com/a/57456495/3915438
    if (!this.platformService.isServer) {
      merge(
        this.appEventsService.onEvent('AppResumed'),
        this.appEventsService.onEvent('OrderPlaced'),
        this.appEventsService.onEvent('OrderPaid'),
        this.appEventsService.onEvent('UserLoginStatusChanged'),
        timer(0, ACTIVE_ORDERS_REFRESH_INTERVAL_IN_MS),
      )
        .pipe(switchMap(() => (this.accountService.isLoggedIn() ? this.getActiveOrders() : of([] as Array<ActiveOrderVendor>))))
        .subscribe({
          next: (activeOrders) => {
            const currentValue = this.activeOrdersSource$.value;
            const currentActiveOrdersVendors = currentValue?.vendors ?? [];

            let hasChangedDetails = false;
            const hasChangedActiveOrderCount = currentActiveOrdersVendors?.length !== activeOrders?.length;

            if (!hasChangedActiveOrderCount) {
              for (let i = 0; i < currentActiveOrdersVendors.length; i++) {
                const changedDate = currentActiveOrdersVendors[i].deliveryDateText !== activeOrders[i].deliveryDateText;
                const changedTime = currentActiveOrdersVendors[i].deliveryTimeText !== activeOrders[i].deliveryTimeText;
                const changedStatus = currentActiveOrdersVendors[i].deliveryStatusText !== activeOrders[i].deliveryStatusText;
                const changedPaymentStatus = currentActiveOrdersVendors[i].canPayOnline !== activeOrders[i].canPayOnline;

                if (changedDate || changedTime || changedStatus || changedPaymentStatus) {
                  hasChangedDetails = true;
                  break;
                }
              }
            }

            if (hasChangedActiveOrderCount || hasChangedDetails) {
              this.appEventsService.dispatch('ActiveOrdersChanged');

              this.activeOrdersSource$.next({
                vendors: activeOrders,
                count: activeOrders?.length === 0 ? 0 : new Set<number>(activeOrders.map((activeOrder) => activeOrder.orderId)).size,
              });
            }
          },
          error: (error: HttpErrorResponse) =>
            this.loggerService.info({ component: 'OrdersService', message: "couldn't get active orders stream", details: { error } }),
        });
    }
  }
}
