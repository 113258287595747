<div class="modal-container">
  <div class="modal-content">
    <div class="modal-body">
      <div class="title" data-cy="alert-title">{{ "TIMEOUT_RETRY_MODAL_PAGE.TITLE" | translate }}</div>
      <div class="message" *ngIf="(countdown$ | async) > 0" data-cy="alert-message">
        {{ "TIMEOUT_RETRY_MODAL_PAGE.MESSAGE_COUNTDOWN" | translate : { countdown: countdown$ | async } }}
      </div>
      <div class="message" *ngIf="waitDurationInSeconds === 0" data-cy="alert-message">
        {{ "TIMEOUT_RETRY_MODAL_PAGE.MESSAGE_STATIC" | translate }}
      </div>
    </div>

    <div class="modal-footer">
      <ion-button (click)="onDismiss('manualRetry')" data-cy="alert-retry-button" expand="block" class="custom-button" color="grey">
        {{ "TRY_AGAIN_NOW" | translate }}
      </ion-button>
      <ion-button (click)="onDismiss('cancel')" data-cy="alert-cancel-button" expand="block" class="custom-button" color="grey">
        {{ "CANCEL" | translate }}
      </ion-button>
    </div>
  </div>
</div>
