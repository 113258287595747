import { Animation, createAnimation } from '@ionic/core';

export const alertModalIosEnterAnimation = (baseEl: HTMLElement): Animation => {
  if (!baseEl) {
    return createAnimation();
  }

  const backdropAnimation = createAnimation();
  const wrapperAnimation = createAnimation();
  const modalContentAnimation = createAnimation();

  const backdropEl = baseEl.querySelector('ion-backdrop');
  if (backdropEl) {
    backdropAnimation
      .addElement(backdropEl)
      .fromTo('opacity', 0.01, 'var(--backdrop-opacity)')
      .beforeStyles({ 'pointer-events': 'none' })
      .afterClearStyles(['pointer-events']);
  }

  const wrapperEl = baseEl.querySelectorAll('.modal-wrapper, .modal-show');
  if (wrapperEl) {
    wrapperAnimation.addElement(wrapperEl).beforeStyles({
      opacity: 1,
      transform: 'translateY(0vh)',
    });
  }

  const contentEl = baseEl.querySelector('.modal-content');
  if (contentEl) {
    modalContentAnimation.addElement(contentEl).keyframes([
      { offset: 0, opacity: '0.01', transform: 'scale(0.9)' },
      { offset: 1, opacity: '1', transform: 'scale(1)' },
    ]);
  }

  const baseAnimation = createAnimation()
    .addElement(baseEl)
    .easing('ease-in-out')
    .duration(200)
    .addAnimation([backdropAnimation, wrapperAnimation, modalContentAnimation]);

  return baseAnimation;
};
