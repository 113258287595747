import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { BrowserCartModalPage } from './browser-cart-modal.page';

import { AnimatedImageComponentModule } from '../../shared/components/animated-image/animated-image.module';
import { CartDetailsComponentModule } from '../../shared/components/cart-details/cart-details.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, AnimatedImageComponentModule, TranslateModule.forChild(), CartDetailsComponentModule],
  declarations: [BrowserCartModalPage],
})
export class BrowserCartModalPageModule {}
