import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { Address } from '../../../core/models/address.model';
import { OrderCustomerDetails } from '../../../core/models/order-customer-details.model';

import { AddressService } from '../../../core/services/address.service';

@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AddressDetailsComponent {
  @Input()
  public hidePhoneNumber = false;

  @Input()
  public hideName = false;

  @Input()
  public plainText = false;

  @Input()
  public singleLine = false;

  public name: string;
  public firstLine: string;
  public secondLine: string;
  public thirdLine: string;

  public phoneNumber: string;
  public oneLineAddress: string;

  constructor(private addressService: AddressService) {}

  @Input()
  public set address(address: Address | OrderCustomerDetails) {
    this.initializeAddress(address);
  }

  private initializeAddress(currentAddress: Address | OrderCustomerDetails): void {
    if (!currentAddress) {
      return;
    }

    const address: Address = this.isSavedAddressModel(currentAddress)
      ? currentAddress
      : this.addressService.createNewAddressFromOrderCustomerDetailsAddress(currentAddress);

    const { oneLiner, addressLines } = this.addressService.getAddressDescriptionText(address);

    this.oneLineAddress = oneLiner;
    this.firstLine = addressLines[0];
    this.secondLine = addressLines[1];
    this.thirdLine = addressLines[2];

    this.name = address.name ?? null;
    this.phoneNumber = address.phoneNumber ? `${address.phoneNumber.countryCode} ${address.phoneNumber.localNumber}` : null;
  }

  private isSavedAddressModel(address: Address | OrderCustomerDetails): address is Address {
    return !!(address as Address).area?.areaId;
  }
}
