import { Injectable } from '@angular/core';

import { PlatformService } from './ssr/platform.service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConsoleToggleService {
  constructor(private platformService: PlatformService) {}

  public disableConsoleInProduction(): void {
    if (environment.production && this.platformService.isBrowser) {
      console.log = function (): void {};
      console.debug = function (): void {};
      console.warn = function (): void {};
      console.info = function (): void {};
      console.table = function (): void {};
    }
  }
}
