import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { filter, map } from 'rxjs';

import { NavigationService } from '../services/navigation.service';
import { SettingsService } from '../services/settings.service';
import { PlatformService } from '../services/ssr/platform.service';

import { AppInitializedGuard } from './app-initialized.guard';
import { CountryLanguageGuard } from './country-language.guard';

@Injectable({ providedIn: 'root' })
export class BrowserCountrySelectionPageGuard implements CanActivate {
  constructor(
    private router: Router,
    private settingsService: SettingsService,
    private platformService: PlatformService,
    private navigationService: NavigationService,
    private appInitializedGuard: AppInitializedGuard,
    private countryLanguageGuard: CountryLanguageGuard,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.platformService.isBrowser) {
      return this.countryLanguageGuard.canActivate(route, state);
    }

    return this.appInitializedGuard.canActivate().pipe(
      filter((canContinue) => !!canContinue),
      map(() => this.checkCountryLanguage()),
    );
  }

  private checkCountryLanguage() {
    if (this.settingsService.alreadySelectedCountryLanguage()) {
      return this.router.createUrlTree([this.navigationService.getCountryLanguageUrlSegment()]);
    }

    return true;
  }
}
