import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { OrderDetailsModalPage } from './order-details-modal.page';

import { PipesModule } from '../../shared/pipes/pipes.module';

import { AddressDetailsComponentModule } from '../../shared/components/address-details/address-details.module';
import { AnimatedImageComponentModule } from '../../shared/components/animated-image/animated-image.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild(),
    PipesModule,
    AddressDetailsComponentModule,
    AnimatedImageComponentModule,
  ],
  declarations: [OrderDetailsModalPage],
})
export class OrderDetailsModalPageModule {}
