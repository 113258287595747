import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, ParamMap, Params, Router } from '@angular/router';

import { AnimationBuilder, NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { mdTransitionAnimation } from '@ionic/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { OrderMenuItemSourceType } from '../enums/order-menu-item-source-type.enum';

import { URL_DATE_FORMAT, URL_TIME_FORMAT } from '../models/date-time-format.model';
import { LanguageCode } from '../models/language.model';

import { DateTimeService } from './date-time.service';
import { SettingsService } from './settings.service';
import { PlatformService } from './ssr/platform.service';
import { WindowService } from './ssr/window.service';

import { COUNTRY_LANGUAGE_REGEX } from '../config/app.config';

import { browserEmptyTransitionAnimation } from '../transitions/browser-page-transition';

import assert from 'assert';

type NavigationDirection = 'forward' | 'back' | 'root' | 'auto';

interface UrlDetails {
  origin: string;
  protocol: string;
  hostname: string;
  pathname: string;
  search: string;
  searchMap: Record<string, string | number>;
  hash: string;
}

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private firstNavigationEnded = new BehaviorSubject<boolean>(false);

  private alreadyNavigated = false;
  private currentUrlParams: { fragment: string; queryParams: Params } = { fragment: undefined, queryParams: {} };

  constructor(
    private router: Router,
    private navCtrl: NavController,
    private windowService: WindowService,
    private dateTimeService: DateTimeService,
    private settingsService: SettingsService,
    private platformService: PlatformService,
  ) {
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.currentUrlParams = {
        fragment: this.router?.routerState?.snapshot?.root?.fragment ?? undefined,
        queryParams: this.router?.routerState?.snapshot?.root?.queryParams ?? {},
      };

      if (!this.alreadyNavigated) {
        this.alreadyNavigated = true;
        this.firstNavigationEnded.next(true);

        if (this.windowService.isWindowDefined) {
          void this.platformService.wait(300).then(() => {
            // IMPORTANT: we use the class added here to avoid a weird flickering that happens when
            // rendering pages in the server — if this needs to be changed please take a look at the
            // index.html file as well since it's used there.
            // https://bilbayt.atlassian.net/browse/CA-2693
            this.windowService.window.document.querySelector('html').className += ' initial-navigation-end';
          });
        }
      }
    });
  }

  public get firstNavigationEnded$(): Observable<boolean> {
    return this.firstNavigationEnded.asObservable();
  }

  public getUrl(params?: { queryStringParamsToAdd?: Record<string, unknown>; fragmentToAdd?: string }): string {
    const currentUrlTree = this.router.parseUrl(this.router.url);

    if (params) {
      if (params.queryStringParamsToAdd) {
        currentUrlTree.queryParams = {
          ...(currentUrlTree.queryParams || {}),
          ...params.queryStringParamsToAdd,
        };
      }

      if (params.fragmentToAdd) {
        currentUrlTree.fragment = params.fragmentToAdd;
      }
    }

    return currentUrlTree.toString();
  }

  // Angular built-in methods only work for the current URL but they don't allow
  // us to parse or format external URLs
  public getUrlDetails(url: string): UrlDetails {
    const emptyDetails = { origin: '', protocol: '', hostname: '', pathname: '', search: '', searchMap: {}, hash: '' };

    if (!this.windowService.isWindowDefined) {
      return emptyDetails;
    }

    try {
      const parser = this.windowService.window.document.createElement('a');
      parser.href = url;

      const searchMap: Record<string, string | number> = {};
      const searchParams = new URLSearchParams(parser.search);

      for (const [key, value] of searchParams) {
        searchMap[key] = value;
      }

      return {
        origin: parser.origin || '',
        protocol: parser.protocol || '',
        hostname: parser.hostname || '',
        pathname: parser.pathname || '',
        search: parser.search || '',
        searchMap,
        hash: parser.hash ? parser.hash.substring(1) : '',
      };
    } catch (e) {
      return emptyDetails;
    }
  }

  public getUrlWithoutParams(): string {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {};
    urlTree.fragment = null;
    return urlTree.toString();
  }

  public navigatePreservingFragmentAndQueryParamsTo(targetUrl: string, openAsRoot?: boolean, options?: NavigationOptions): Promise<void> {
    if (!this.windowService.isWindowDefined) {
      return this.navigateTo(targetUrl, openAsRoot, options);
    }

    // For some reason preserveFragment: true and queryParamsHandling: 'preserve' don't
    // work as expected here so we need to send the params manually
    const fragment = options?.fragment ?? this.currentUrlParams?.fragment ?? undefined;
    const queryParams = {
      ...(this.currentUrlParams?.queryParams ?? {}),
      ...(options?.queryParams ?? {}),
    };

    return this.navigateTo(targetUrl, openAsRoot, { ...(options || {}), fragment, queryParams });
  }

  public navigateTo(targetUrl: string, openAsRoot?: boolean, options?: NavigationOptions): Promise<void> {
    targetUrl = this.maybePrefixCountryLanguageToURL(targetUrl);

    const updatedOptions: NavigationOptions = this.platformService.isBrowser
      ? { ...(options || {}), animation: browserEmptyTransitionAnimation }
      : options;

    return openAsRoot
      ? this.navCtrl.navigateRoot(targetUrl, updatedOptions).then(() => {})
      : this.navCtrl.navigateForward(targetUrl, updatedOptions).then(() => {});
  }

  public navigateBackTo(targetUrl: string, animation?: AnimationBuilder): Promise<boolean> {
    targetUrl = this.maybePrefixCountryLanguageToURL(targetUrl);
    const updatedAnimation: AnimationBuilder = this.platformService.isBrowser ? browserEmptyTransitionAnimation : animation;
    return this.navCtrl.navigateBack(targetUrl, { animation: updatedAnimation });
  }

  public navigateBack(): Promise<void> {
    if (!this.windowService.isWindowDefined) {
      return this.navCtrl.pop();
    }

    const currentUrl = this.windowService.window.location.href;

    return this.navCtrl.pop().then(() => {
      // If after navigating we're still in the same URL we need to redirect
      // the user to the home page — we probably loaded the current page using
      // deep links and there's no navigation history to go back to
      if (currentUrl === this.windowService.window.location.href) {
        return this.navigateBackToHomePage();
      }
    });
  }

  public isNavigatingForward(): boolean {
    return this.getCurrentNavigationDirection() === 'forward';
  }

  public isNavigatingBack(): boolean {
    return this.getCurrentNavigationDirection() === 'back';
  }

  public getCurrentNavigationStateParams<T>(): T {
    return (this.router.getCurrentNavigation().extras.state || {}) as T;
  }

  public isEntryPageNavigation(): boolean {
    return !this.alreadyNavigated;
  }

  public navigateBackToHomePage(): Promise<void> {
    return this.navigateBackToTab('/vendors/home');
  }

  public navigateBackToTab(tabRoute: string): Promise<void> {
    tabRoute = this.maybePrefixCountryLanguageToURL(tabRoute);
    return this.navigateTo(tabRoute, true, { animation: mdTransitionAnimation, animationDirection: 'back' });
  }

  public switchLanguage(language: LanguageCode): void {
    const country = this.settingsService.getCountry();
    const parts = this.router.url.split('/').filter(Boolean);
    const newUrl = parts.length > 2 ? `/${country.code}/${language}/${parts.slice(2).join('/')}` : `/${country.code}/${language}`;

    this.windowService.window.location.href = newUrl;
  }

  public updateServiceTypeFromUrl(route: ActivatedRoute, serviceType: number, dateTime?: string, orderForNow?: boolean): void {
    const newDateValue = dateTime
      ? this.dateTimeService.format({ dateTimeIso: dateTime, format: URL_DATE_FORMAT })
      : orderForNow
      ? this.dateTimeService.format({ dateTimeIso: this.dateTimeService.getLocalDateTimeIsoString(), format: URL_DATE_FORMAT })
      : undefined;

    const newTimeValue = dateTime
      ? this.dateTimeService.format({ dateTimeIso: dateTime, format: URL_TIME_FORMAT })
      : orderForNow
      ? 'now'
      : undefined;

    void this.router.navigate([], {
      relativeTo: route,
      fragment: serviceType ? serviceType.toString() : undefined,
      queryParams: {
        date: newDateValue,
        time: newTimeValue,
      },
      queryParamsHandling: 'merge',
    });
  }

  public updateAreaIdFromUrl(route: ActivatedRoute, areaId: number): void {
    void this.router.navigate([], {
      relativeTo: route,
      preserveFragment: true,
      queryParams: { areaId },
      queryParamsHandling: 'merge',
    });
  }

  public updateDateTimeFromUrl(route: ActivatedRoute, dateTime: string, orderForNow: boolean): void {
    const { date, time } = this.getDateAndTimeQueryParamsFromIsoString(dateTime, orderForNow);

    void this.router.navigate([], {
      relativeTo: route,
      preserveFragment: true,
      queryParams: { date, time },
      queryParamsHandling: 'merge',
    });
  }

  public updateServiceTypeAreaDateTimeFromUrl(
    route: ActivatedRoute,
    serviceType: number,
    areaId: number,
    dateTime: string,
    orderForNow: boolean,
  ): void {
    const { date, time } = this.getDateAndTimeQueryParamsFromIsoString(dateTime, orderForNow);

    void this.router.navigate([], {
      relativeTo: route,
      fragment: serviceType ? serviceType.toString() : undefined,
      queryParams: { areaId, date, time },
      queryParamsHandling: 'merge',
    });
  }

  public getServiceTypeFromUrl(route: ActivatedRouteSnapshot): number {
    const serviceTypeStr = route.fragment || route.queryParamMap.get('serviceType');

    if (!serviceTypeStr) {
      return undefined;
    }

    return this.settingsService.isServiceTypeValid(+serviceTypeStr) ? +serviceTypeStr : undefined;
  }

  public getVendorIdFromUrl(route: ActivatedRouteSnapshot): number {
    const vendorId = route.paramMap.get('vendorId') || route.queryParamMap.get('vendorId');
    return vendorId ? +vendorId : undefined;
  }

  public getVendorSlugFromUrl(route: ActivatedRouteSnapshot): string {
    return route.paramMap.get('vendorSlug') || undefined;
  }

  public getTagsIdsFromUrl(route: ActivatedRouteSnapshot): Array<number> {
    const tagsIds = route.queryParamMap.getAll('tagsIds');
    return tagsIds ? tagsIds.map((tagId) => +tagId) : [];
  }

  public getItemIdFromUrl(route: ActivatedRouteSnapshot): number {
    const itemId = route.paramMap.get('itemId');
    return itemId ? +itemId : undefined;
  }

  public getChatIdFromUrl(route: ActivatedRouteSnapshot): string {
    const chatId = route.paramMap.get('chatId') || route.queryParamMap.get('chatId');
    return chatId ? chatId : undefined;
  }

  public getChatRequestedByUserIdFromUrl(route: ActivatedRouteSnapshot): string {
    const chatRequestedByUserId = route.paramMap.get('chatRequestedByUserId') || route.queryParamMap.get('chatRequestedByUserId');
    return chatRequestedByUserId ? chatRequestedByUserId : undefined;
  }

  public getChatRequestedByUserEmailFromUrl(route: ActivatedRouteSnapshot): string {
    const chatRequestedByUserEmail = route.paramMap.get('chatRequestedByUserEmail') || route.queryParamMap.get('chatRequestedByUserEmail');
    return chatRequestedByUserEmail ? chatRequestedByUserEmail : undefined;
  }

  public getCategoryIdFromUrl(route: ActivatedRouteSnapshot): number {
    const categoryId = route.paramMap.get('categoryId');
    return categoryId ? +categoryId : undefined;
  }

  public getOrderIdIdFromUrl(route: ActivatedRouteSnapshot): number {
    const orderId = route.paramMap.get('orderId') || route.queryParamMap.get('orderId');
    return orderId ? +orderId : undefined;
  }

  public getCollectionIdFromUrl(route: ActivatedRouteSnapshot): number {
    const collectionId = route.paramMap.get('collectionId');
    return collectionId ? +collectionId : undefined;
  }

  public getAreaIdFromUrl(route: ActivatedRouteSnapshot): number {
    const areaIdStr = route.queryParamMap.get('areaId');
    return areaIdStr ? +areaIdStr : undefined;
  }

  public getAreaIdFromParamMap(paramMap: ParamMap): number {
    return paramMap && paramMap.has('areaId') ? +paramMap.get('areaId') : undefined;
  }

  public getDateFromUrl(route: ActivatedRouteSnapshot): string {
    const dateStr = route.queryParamMap.get('date');
    return dateStr ? dateStr : undefined;
  }

  public getTimeFromUrl(route: ActivatedRouteSnapshot): string {
    const timeStr = route.queryParamMap.get('time');
    return timeStr ? timeStr : undefined;
  }

  public getDateTimeAndOrderForNowFromParamMap(paramMap: ParamMap): { dateTime: string; orderForNow: boolean } {
    const date = paramMap && paramMap.has('date') ? paramMap.get('date') : undefined;
    const time = paramMap && paramMap.has('time') ? paramMap.get('time') : undefined;
    const orderForNow = date && time ? time === 'now' : undefined;
    const dateTime =
      date && time
        ? time === 'now'
          ? undefined
          : this.dateTimeService.convertToDateTimeIsoString(`${date}${time}`, `${URL_DATE_FORMAT}${URL_TIME_FORMAT}`)
        : undefined;

    return { dateTime, orderForNow };
  }

  public getDateTimeAndOrderForNowFromUrl(route: ActivatedRouteSnapshot): { dateTime: string; orderForNow: boolean } {
    const dateFromUrl = this.getDateFromUrl(route);
    const timeFromUrl = this.getTimeFromUrl(route);

    const orderForNow = timeFromUrl === 'now';
    const dateTime = orderForNow
      ? undefined
      : dateFromUrl &&
        timeFromUrl &&
        this.dateTimeService.isValid(dateFromUrl, URL_DATE_FORMAT) &&
        this.dateTimeService.isValid(timeFromUrl, URL_TIME_FORMAT)
      ? this.dateTimeService.convertToDateTimeIsoString(`${dateFromUrl}${timeFromUrl}`, `${URL_DATE_FORMAT}${URL_TIME_FORMAT}`)
      : undefined;

    return { orderForNow, dateTime };
  }

  public getSourceIdAndTypeFromUrl(route: ActivatedRouteSnapshot): { sourceId: number; sourceType: OrderMenuItemSourceType } {
    const sourceId = route.queryParamMap.get('sourceId');
    const sourceType = route.queryParamMap.get('sourceType');

    return {
      sourceId: sourceId ? +sourceId : undefined,
      sourceType: sourceType ? +sourceType : undefined,
    };
  }

  public getCountryLanguageUrlSegment(): string {
    const currentOrDefaultCountryCode = this.settingsService.getCountry().code;
    const currentOrDefaultLanguageCode = this.settingsService.getLanguage().value;
    return `/${currentOrDefaultCountryCode}/${currentOrDefaultLanguageCode}`;
  }

  public getDateAndTimeQueryParamsFromIsoString(dateTime: string, orderForNow: boolean): { date: string; time: string } {
    return {
      date: dateTime
        ? this.dateTimeService.format({ dateTimeIso: dateTime, format: URL_DATE_FORMAT })
        : orderForNow
        ? this.dateTimeService.format({ dateTimeIso: this.dateTimeService.getLocalDateTimeIsoString(), format: URL_DATE_FORMAT })
        : undefined,
      time: dateTime ? this.dateTimeService.format({ dateTimeIso: dateTime, format: URL_TIME_FORMAT }) : orderForNow ? 'now' : undefined,
    };
  }

  private maybePrefixCountryLanguageToURL(targetUrl: string): string {
    if (targetUrl === '/') {
      return targetUrl;
    }

    if (targetUrl.endsWith('error') || targetUrl.endsWith('update') || targetUrl.endsWith('incident') || targetUrl.endsWith('not-found')) {
      return targetUrl;
    }

    // COUNTRY_LANGUAGE_REGEX.lastIndex = 0 allows us to reset the search index
    // to perform a second search starting from the beginning. Without it, the search
    // begins at the index of the last match, which would yield null in this case
    // https://stackoverflow.com/a/23299037/3915438
    COUNTRY_LANGUAGE_REGEX.lastIndex = 0;

    if (COUNTRY_LANGUAGE_REGEX.exec(targetUrl.startsWith('/') ? targetUrl.slice(1) : targetUrl)) {
      return targetUrl;
    }

    const targetUrlSegments = targetUrl.split('/').filter((segment) => segment);
    const countryCode = this.settingsService.getCountry().code;
    const languageCode = this.settingsService.getLanguage().value;

    const targetUrlWithDefaultPrefixes = this.router.serializeUrl(
      this.router.createUrlTree([countryCode, languageCode, ...targetUrlSegments]),
    );

    return targetUrlWithDefaultPrefixes;
  }

  private getCurrentNavigationDirection(): NavigationDirection {
    const direction: NavigationDirection = (this.navCtrl as unknown as { direction: NavigationDirection }).direction;

    // The direction is a PRIVATE property from the NavController
    // so we should keep this assert to know if they remove it
    // in any future version of Ionic
    assert(!!direction, 'NavigationServiceError: Direction property is not available anymore!');

    return direction;
  }
}
