import { Injectable } from '@angular/core';

import { CleverTapPlugin } from './native-plugins/clever-tap.plugin';
import { DiagnosticPlugin, PermissionAuthorizationStatus } from './native-plugins/diagnostic.plugin';
import { FirebasePlugin } from './native-plugins/firebase.plugin';

import { Subject } from 'rxjs';

import {
  PushNotificationsDisabledModalPage,
  PushNotificationsDisabledModalPageIdentifier,
} from '../../modals/push-notifications-disabled-modal/push-notifications-disabled-modal.page';
import {
  PushNotificationsModalPage,
  PushNotificationsModalPageIdentifier,
} from '../../modals/push-notifications-modal/push-notifications-modal.page';

import { TargetPage } from '../enums/target-page.enum';

import { PushNotification } from '../models/push-notification.model';

import { AccountService } from './account.service';
import { ModalService } from './modal.service';
import { SettingsService } from './settings.service';
import { PlatformService } from './ssr/platform.service';
import { StateService } from './state.service';
import { UniversalLinksService } from './universal-links.service';

@Injectable({ providedIn: 'root' })
export class PushNotificationsService {
  public processPendingPushNotification$ = new Subject<PushNotification>();
  public handlePushNotificationOnAccountPage$ = new Subject<PushNotification>();
  public handlePushNotificationOnSearchResultsPage$ = new Subject<PushNotification>();

  constructor(
    private cleverTapPlugin: CleverTapPlugin,
    private diagnosticPlugin: DiagnosticPlugin,
    private firebasePlugin: FirebasePlugin,
    private stateService: StateService,
    private accountService: AccountService,
    private settingsService: SettingsService,
    private platformService: PlatformService,
    private modalService: ModalService,
    private universalLinksService: UniversalLinksService,
  ) {}

  public getDeviceId(): Promise<string> {
    return this.cleverTapPlugin.getDeviceId();
  }

  public shouldShowPushNotificationsPrompt(): Promise<boolean> {
    if (this.platformService.isBrowser) {
      return Promise.resolve(false);
    }

    return this.stateService.state?.pushNotificationsPromptShown
      ? Promise.resolve(false)
      : this.getAuthorizationStatus().then((status) => status === PermissionAuthorizationStatus.CanRequest);
  }

  public markPushNotificationsPromptAsAlreadyShown(): void {
    this.stateService.update({ pushNotificationsPromptShown: true });
  }

  public initializePushNotifications(): void {
    this.unregisterOldProviders();
    this.subscribeToPushNotificationEvents();

    void this.hasPermission().then((hasPermission) => {
      if (hasPermission) {
        this.cleverTapPlugin.enablePushNotifications();
      }
    });
  }

  public grantPermissionAndInitialize(): Promise<boolean> {
    return this.firebasePlugin.grantPermission().then((hasPermission) => {
      if (hasPermission) {
        this.cleverTapPlugin.enablePushNotifications();
      }

      return hasPermission;
    });
  }

  public hasPermission(): Promise<boolean> {
    return this.firebasePlugin.hasRemoteNotificationsPermission();
  }

  public getAuthorizationStatus(): Promise<PermissionAuthorizationStatus> {
    return this.diagnosticPlugin.getRemoteNotificationsAuthorizationStatus();
  }

  public getValidTargetPage(pushNotification: PushNotification): TargetPage {
    switch (pushNotification.type) {
      case TargetPage.MarketingCollectionPage:
        return pushNotification.marketingCollectionId ? TargetPage.MarketingCollectionPage : null;

      case TargetPage.WishlistDetails:
        return pushNotification.wishlistId ? TargetPage.WishlistDetails : null;

      case TargetPage.OrderReview:
        return pushNotification.orderId ? TargetPage.OrderReview : null;

      case TargetPage.OrderDetails:
        return pushNotification.orderId ? TargetPage.OrderDetails : null;

      case TargetPage.CatererDetails:
        return pushNotification.catererId ? TargetPage.CatererDetails : null;

      case TargetPage.MenuItemDetails:
        return pushNotification.catererId && pushNotification.menuItemId ? TargetPage.MenuItemDetails : null;

      case TargetPage.ServiceType:
        return pushNotification.serviceTypes ? TargetPage.ServiceType : null;

      case TargetPage.Cart:
      case TargetPage.SignUp:
      case TargetPage.AppUpdate:
      case TargetPage.UserRewards:
      case TargetPage.ReferralProgram:
      case TargetPage.TopSearchedServiceType:
      case TargetPage.TopSearchedVendor:
      case TargetPage.PushNotificationsPermission:
        return pushNotification.type;

      default:
        return null;
    }
  }

  public getHomePageUrl(pushNotification: PushNotification): string {
    if (this.shouldBeHandledInSearchResultsPage(pushNotification)) {
      return this.platformService.isCordova ? '/vendors/home' : '/vendors/search';
    }

    if (this.shouldBeHandledInAccountPage(pushNotification)) {
      return '/account';
    }

    return null;
  }

  public handleNotificationOnTabPage(pushNotification: PushNotification): void {
    if (this.shouldBeHandledInSearchResultsPage(pushNotification)) {
      this.handlePushNotificationOnSearchResultsPage$.next(pushNotification);
      return;
    }

    if (this.shouldBeHandledInAccountPage(pushNotification)) {
      this.handlePushNotificationOnAccountPage$.next(pushNotification);
      return;
    }
  }

  public getPendingNotificationToBeHandledInSearchResultsPage(): PushNotification {
    const pendingPushNotification = this.stateService.state?.pendingPushNotification;
    return pendingPushNotification && this.shouldBeHandledInSearchResultsPage(pendingPushNotification) ? pendingPushNotification : null;
  }

  public getPendingNotificationToBeHandledInAccountPage(): PushNotification {
    const pendingPushNotification = this.stateService.state?.pendingPushNotification;
    return pendingPushNotification && this.shouldBeHandledInAccountPage(pendingPushNotification) ? pendingPushNotification : null;
  }

  public clearPendingPushNotification(): void {
    this.stateService.update({ pendingPushNotification: null });
  }

  public askForPushNotificationsPermissionEvenIfRejected(): void {
    void this.getAuthorizationStatus().then((status) => {
      if (status === PermissionAuthorizationStatus.CanRequest) {
        void this.modalService.showModal({
          component: PushNotificationsModalPage,
          id: PushNotificationsModalPageIdentifier,
          backdropDismiss: false,
        });
      } else if (status === PermissionAuthorizationStatus.RequestedAndRejected) {
        void this.modalService.showModal({
          component: PushNotificationsDisabledModalPage,
          id: PushNotificationsDisabledModalPageIdentifier,
        });
      }
    });
  }

  public shouldBeAttributed(pushNotification: PushNotification): boolean {
    return !!pushNotification.campaignId;
  }

  public shouldRedirect(pushNotification: PushNotification): boolean {
    return !!pushNotification.type;
  }

  private shouldBeHandledInSearchResultsPage(pushNotification: PushNotification): boolean {
    switch (this.getValidTargetPage(pushNotification)) {
      case TargetPage.Cart:
      case TargetPage.ServiceType:
      case TargetPage.CatererDetails:
      case TargetPage.MenuItemDetails:
      case TargetPage.MarketingCollectionPage:
      case TargetPage.TopSearchedServiceType:
      case TargetPage.TopSearchedVendor:
      case TargetPage.WishlistDetails:
      case TargetPage.PushNotificationsPermission:
        return true;

      default:
        return false;
    }
  }

  private shouldBeHandledInAccountPage(pushNotification: PushNotification): boolean {
    switch (this.getValidTargetPage(pushNotification)) {
      case TargetPage.AppUpdate:
      case TargetPage.SignUp:
      case TargetPage.UserRewards:
      case TargetPage.ReferralProgram:
      case TargetPage.OrderReview:
      case TargetPage.OrderDetails:
        return true;

      default:
        return false;
    }
  }

  private subscribeToPushNotificationEvents(): void {
    this.cleverTapPlugin.onMessageReceived().subscribe((pushNotification) => {
      if (!pushNotification) {
        return;
      }

      if (pushNotification.deeplink) {
        this.universalLinksService.handleDeepLinkUrl(pushNotification.deeplink);
        return;
      }

      // Testing only
      // if (clickedLinkData) alert(`${JSON.stringify(clickedLinkData, null, 4)}`);

      this.stateService.update({ pendingPushNotification: pushNotification });
    });

    this.cleverTapPlugin.subscribeToPushNotificationEvents();
  }

  private unregisterOldProviders(): void {
    this.firebasePlugin.unregisterFromPushNotifications(
      this.settingsService.getCountry().code,
      this.settingsService.getLanguage().value,
      this.accountService.isLoggedIn() ? this.accountService.userDetails?.userId : null,
    );
  }
}
