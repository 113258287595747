import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AppService } from '../services/app.service';
import { NavigationService } from '../services/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class AppReadyGuard implements CanActivate {
  constructor(private appService: AppService, private navigationService: NavigationService) {}

  canActivate() {
    return combineLatest([this.appService.appReady$, this.appService.appInitError$]).pipe(
      filter(([isReady, errorOccurred]) => !!isReady || !!errorOccurred),
      map(([isReady, errorOccurred]) => {
        if (errorOccurred) {
          void this.navigationService.navigateTo('/error');
          return false;
        }

        return isReady;
      }),
    );
  }
}
