export interface Language {
  value: LanguageCode;
  rtl: boolean;
}

export const LANGUAGE = {
  english: { code: 'en' },
  arabic: { code: 'ar' },
} as const;

export const LANGUAGE_CODES = [LANGUAGE.english.code, LANGUAGE.arabic.code] as const;

export type LanguageCode = typeof LANGUAGE_CODES[number];
