import { InjectionToken } from '@angular/core';

export const TOKEN_UNIVERSAL_LINKS_CONFIG = new InjectionToken<UniversalLinksConfig>('universal-links.config');

interface UniversalLinkConfigParamName {
  targetPage: string;
  orderId: string;
  catererId: string;
  menuItemId: string;
  marketingCollectionId: string;
  serviceTypes: string;
  campaignId: string;
  campaignSource: string;
  affiliateUserId: string;
  wishlistId: string;
  couponCode: string;
}

export interface UniversalLinksConfig {
  storageName: string;

  paramName: UniversalLinkConfigParamName;
}

export const UNIVERSAL_LINKS_CONFIG: UniversalLinksConfig = {
  storageName: 'BILBAYT_CUSTOMER_APP:universalLink',

  paramName: {
    targetPage: 'targetPage',
    orderId: 'orderId',
    catererId: 'catererId',
    menuItemId: 'menuItemId',
    marketingCollectionId: 'marketingCollectionId',
    serviceTypes: 'serviceTypes',
    campaignId: 'campaignId',
    campaignSource: 'campaignSource',
    affiliateUserId: 'affiliateUserId',
    wishlistId: 'wishlistId',
    couponCode: 'couponCode',
  },
};
