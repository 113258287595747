import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { NavigationService } from '../services/navigation.service';
import { PlatformService } from '../services/ssr/platform.service';

@Injectable({
  providedIn: 'root',
})
export class NoEntryPageCordovaGuard implements CanActivate {
  constructor(private navigationService: NavigationService, private platformService: PlatformService) {}

  canActivate() {
    if (this.platformService.isBrowser) {
      return true;
    }

    if (this.navigationService.isEntryPageNavigation()) {
      void this.navigationService.navigateBackToHomePage();
      return false;
    }

    return true;
  }
}
