import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, Injector, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicConfig } from '@ionic/core';
import { IonicStorageModule, StorageConfig } from '@ionic/storage';

import { isCypressTestEnv } from './core/services/environment.service';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';

import { environment } from '../environments/environment';

import core from './core';
import modals from './modals';
import shared from './shared';

export const APP_SSR_BASE_URL = new InjectionToken<string>('APP_SSR_BASE_URL');
export const APP_SSR_FULL_URL = new InjectionToken<string>('APP_SSR_FULL_URL');

// Properties that allow us to have control over the
// data saved by the app when running e2e tests
const storageConfig: StorageConfig = isCypressTestEnv() ? { driverOrder: ['localstorage'] } : {};
const ionicConfig: IonicConfig = {
  mode: 'ios',
  backButtonText: '',
  swipeBackEnabled: false,
  scrollPadding: false,
  scrollAssist: false,
  animated: isCypressTestEnv() ? false : true,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    IonicStorageModule.forRoot(storageConfig),
    IonicModule.forRoot(ionicConfig),
    ...core.ssrBrowserModules,
    ...shared,
    ...modals,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [...core.providers, ...core.ssrBrowserProviders, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {
  public static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
