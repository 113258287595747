export enum Availability {
  Available = 0,

  // Message: Catering service not available
  UnavailableServiceTypeCaterering = 51,

  // Message: Drop-Off service not available
  UnavailableServiceTypeDropOff = 52,

  UnavailableDeliveryArea = 100,
  UnavailableInsufficientNotice = 200,
  UnavailableServiceTime = 300,
  UnavailableFullyBooked = 400,
  UnavailableUnspecified = 999,
}
