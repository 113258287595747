import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, NgZone, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

import { DomController, IonContent, ViewWillEnter } from '@ionic/angular';

import {
  KEYBOARD_DID_SHOW_EVENT,
  KEYBOARD_WILL_HIDE_EVENT,
  KEYBOARD_WILL_SHOW_EVENT,
} from '../../core/services/native-plugins/keyboard.plugin';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AreaSelectionModalPage, AreaSelectionModalPageIdentifier } from '../area-selection-modal/area-selection-modal.page';
import { EditWishlistModalPage, EditWishlistModalPageIdentifier } from '../edit-wishlist-modal/edit-wishlist-modal.page';
import { ImagesPreviewModalPage, ImagesPreviewModalPageIdentifier } from '../images-preview-modal/images-preview-modal.page';

import { OrderMenuItemSourceType } from '../../core/enums/order-menu-item-source-type.enum';

import { Address } from '../../core/models/address.model';
import { CartValidatedItem } from '../../core/models/cart-response.model';
import { GroceryMenuItem } from '../../core/models/grocery-menu-item.model';
import { GroceryVendorDetails } from '../../core/models/grocery-vendor-details.model';
import { MultiLanguageValue } from '../../core/models/multi-language-value.model';
import { NewCartItem } from '../../core/models/new-cart-item.model';
import { WishlistDetails } from '../../core/models/wishlist-details.model';
import { WishlistItem } from '../../core/models/wishlist-item.model';
import { Wishlist } from '../../core/models/wishlist.model';

import { AccountService } from '../../core/services/account.service';
import { AnalyticsService } from '../../core/services/analytics.service';
import { CartService } from '../../core/services/cart.service';
import { DeepLinkService } from '../../core/services/deep-link.service';
import { LoggerService } from '../../core/services/logger.service';
import { ModalService } from '../../core/services/modal.service';
import { OverlayService } from '../../core/services/overlay.service';
import { ReferralProgramService } from '../../core/services/referral-program.service';
import { SettingsService } from '../../core/services/settings.service';
import { PlatformService } from '../../core/services/ssr/platform.service';
import { WindowService } from '../../core/services/ssr/window.service';
import { UniversalLinksService } from '../../core/services/universal-links.service';
import { ValidationService } from '../../core/services/validation.service';
import { VendorsService } from '../../core/services/vendors.service';

import { AnalyticsConfig, TOKEN_ANALYTICS_CONFIG } from '../../core/config/analytics.config';

import { Helpers } from '../../core/helpers';

import { GroceryItemDetailsModalComponentAnimations } from './grocery-item-details-modal.animations';

import SwiperCore, { Pagination, Swiper } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Pagination]);

export const GroceryItemDetailsModalPageIdentifier = 'grocery-item-details-modal';

const ITEM_IMAGE_MAX_HEIGHT = 360;

export interface GroceryItemDetailsModalParams {
  vendorDetails: GroceryVendorDetails;
  itemDetails: GroceryMenuItem;
  selectedAddress?: Address;

  sourceId?: number;
  sourceType?: OrderMenuItemSourceType;

  shouldTrackCleverTapEvents?: boolean;
  currentWishlist?: WishlistDetails;
  shouldUpdateCurrentWishlist?: boolean;
}

export interface GroceryItemDetailsModalResult {
  addedToCart: boolean;
  updatedWishlist: boolean;
}

@Component({
  selector: 'app-grocery-item-details-modal',
  templateUrl: 'grocery-item-details-modal.component.html',
  styleUrls: ['grocery-item-details-modal.component.scss'],
  animations: [...GroceryItemDetailsModalComponentAnimations],
  encapsulation: ViewEncapsulation.None,
})
export class GroceryItemDetailsModalComponent implements OnInit, ViewWillEnter, OnDestroy {
  @Input()
  public itemDetailsParams: GroceryItemDetailsModalParams;

  @ViewChild(IonContent)
  public content: IonContent;

  @ViewChild('swiper')
  public swiper?: SwiperComponent;

  public vendorDetails: GroceryVendorDetails;
  public itemDetails: GroceryMenuItem;

  public isUpdatingCartItem: boolean;
  public isUpdatingWishlistItem: boolean;

  public sourceId: number;
  public sourceType: OrderMenuItemSourceType;

  public sliderScrollingDirection: string;
  public discountPercentage: number;
  public imageHeight: number;
  public fullDescriptionFormatted: SafeHtml;

  public isBrowser: boolean;
  public isLoggedIn: boolean;
  public shouldHideFooter = false;

  public currentWishlist: WishlistDetails;

  public wishlists: Array<Wishlist>;
  public updatedWishlistId: number;
  public updatingWishlistId: number;
  public isWishlistsModalOpen: boolean;
  public shouldIgnoreKeyboardEvent: boolean;

  private selectedAddress: Address;
  private shouldTrackCleverTapEvents: boolean;
  private shouldUpdateCurrentWishlist: boolean;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private ngZone: NgZone,
    public renderer: Renderer2,
    public domCtrl: DomController,
    private domSanitizer: DomSanitizer,
    private windowService: WindowService,
    private modalService: ModalService,
    private vendorsService: VendorsService,
    private settingsService: SettingsService,
    private platformService: PlatformService,
    private overlayService: OverlayService,
    private loggerService: LoggerService,
    private cartService: CartService,
    private analyticsService: AnalyticsService,
    private validationService: ValidationService,
    private referralProgramService: ReferralProgramService,
    private universalLinksService: UniversalLinksService,
    private translateService: TranslateService,
    private deepLinkService: DeepLinkService,
    private accountService: AccountService,
    @Inject(TOKEN_ANALYTICS_CONFIG) private analyticsConfig: AnalyticsConfig,
  ) {}

  ngOnInit() {
    this.isBrowser = this.platformService.isBrowser;
    this.isLoggedIn = this.accountService.isLoggedIn();
    this.initializeKeyboardEventsListener();
  }

  ionViewWillEnter(): void {
    this.sliderScrollingDirection = this.settingsService.getLanguageDirection();

    this.vendorDetails = Helpers.clone(this.itemDetailsParams.vendorDetails);
    this.itemDetails = Helpers.clone(this.itemDetailsParams.itemDetails);

    this.sourceId = this.itemDetailsParams.sourceId;
    this.sourceType = this.itemDetailsParams.sourceType;
    this.shouldTrackCleverTapEvents = this.itemDetailsParams.shouldTrackCleverTapEvents;

    // Note: we need to send both the currentWishlist and shouldUpdateCurrentWishlist properties because:
    // - currentWishlist allows us to load the quantity/option/add-ons from the wishlist item
    // - shouldUpdateCurrentWishlist tells us if changes should be applied to the wishlist or not
    this.currentWishlist = this.itemDetailsParams.currentWishlist;
    this.shouldUpdateCurrentWishlist = this.itemDetailsParams.shouldUpdateCurrentWishlist;

    this.selectedAddress = this.itemDetailsParams.selectedAddress;
    this.imageHeight =
      this.platformService.width() >= this.platformService.tabletMinScreenWidth ? ITEM_IMAGE_MAX_HEIGHT : this.platformService.width();

    this.initializeLocalPropertiesBasedOnVendorDetailsAndCart();
    this.trackPageView();
    this.trackItemViewedCleverTapEvent();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
    this.unsubscribeFromKeyboardEvents();
  }

  public onShare(): void {
    if (!this.vendorDetails || !this.itemDetails) {
      return;
    }

    this.trackShareEvent();

    void this.referralProgramService.maybeShowReferralProgramModal().then((shouldShare) => {
      if (shouldShare) {
        void this.universalLinksService
          .shareMenuItemDetails({
            vendorId: this.vendorDetails.vendorId,
            vendorName: this.vendorDetails.name,
            itemId: this.itemDetails.menuItemId,
            itemName: this.itemDetails.name,
            itemDescription: this.itemDetails.description,
            itemImageUrl: this.itemDetails.imageUrl,
            itemServiceTypes: this.itemDetails.serviceType,
          })
          .catch((error: HttpErrorResponse) => {
            const errorMessage = this.translateService.instant('ERROR_MESSAGE.SHARE') as string;
            this.loggerService.info({
              component: 'ItemDetailsModalComponent',
              message: "couldn't share menu item",
              details: { error, messageShownToUser: errorMessage },
            });

            this.overlayService.showToast({ type: 'error', message: errorMessage });
          });
      }
    });
  }

  public onDismiss(): void {
    this.dismiss({ addedToCart: false });
  }

  public onUpdateExistingWishlistItem(): void {
    this.accountService
      .addItemToWishlist(this.currentWishlist.wishListId, this.getWishlistItem())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.dismiss({ addedToCart: false, updatedWishlist: true });
        },
        error: (error: HttpErrorResponse) => {
          const errorMessage = this.validationService.getErrorMessage(error);
          this.loggerService.info({
            component: 'ItemDetailsModal',
            message: "couldn't update user wishlists",
            details: { error, messageShownToUser: errorMessage },
          });

          this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });
          this.dismiss({ addedToCart: false, updatedWishlist: false });
        },
      });
  }

  public onRemoveFromExistingWishlistItem(): void {
    if (!this.currentWishlist) {
      return;
    }

    this.currentWishlist.details.caterers.forEach((vendor) => {
      vendor.items.forEach((item) => {
        if (item.menuItemId === this.itemDetails.menuItemId) {
          item.quantity = 0;
        }
      });
    });

    this.accountService
      .updateWishlistItems(this.currentWishlist)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.dismiss({ addedToCart: false, updatedWishlist: true });
        },
        error: (error: HttpErrorResponse) => {
          const errorMessage = this.validationService.getErrorMessage(error);
          this.loggerService.info({
            component: 'ItemDetailsModal',
            message: "couldn't update user wishlists",
            details: { error, messageShownToUser: errorMessage },
          });

          this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });
          this.dismiss({ addedToCart: false, updatedWishlist: false });
        },
      });
  }

  public onShowWishlists(event: MouseEvent): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.wishlists = [];
    this.updatedWishlistId = undefined;
    this.updatingWishlistId = undefined;

    void this.overlayService.showLoading().then(() => {
      this.accountService
        .getUserWishlists()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (wishlists) => {
            void this.overlayService.hideLoading().then(() => {
              this.wishlists = wishlists;
              this.isWishlistsModalOpen = true;
            });
          },
          error: (error: HttpErrorResponse) => {
            const errorMessage = this.validationService.getErrorMessage(error);
            this.loggerService.info({
              component: 'ItemDetailsModal',
              message: "couldn't get user wishlists",
              details: { error, messageShownToUser: errorMessage },
            });

            void this.overlayService.hideLoading();
            this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });
          },
        });
    });
  }

  public onAddItemToWishlist(wishlist: Wishlist): void {
    if (this.updatingWishlistId) {
      return;
    }

    this.updatingWishlistId = wishlist.wishListId;

    this.accountService
      .addItemToWishlist(wishlist.wishListId, this.getWishlistItem())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.updatingWishlistId = undefined;
          this.updatedWishlistId = wishlist.wishListId;

          void this.platformService.wait(1000).then(() => {
            this.updatedWishlistId = undefined;
          });
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 403) {
            const errorMessage = this.translateService.instant('MENU_ITEM_DETAILS_PAGE.DUPLICATE_WISHLIST_ITEM') as string;
            this.loggerService.info({
              component: 'ItemDetailsModal',
              message: "couldn't update user wishlists",
              details: { error, messageShownToUser: errorMessage },
            });

            this.updatedWishlistId = undefined;
            this.updatingWishlistId = undefined;
            this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'info' });
            return;
          }

          const errorMessage = this.validationService.getErrorMessage(error);
          this.loggerService.info({
            component: 'ItemDetailsModal',
            message: "couldn't update user wishlists",
            details: { error, messageShownToUser: errorMessage },
          });

          this.updatedWishlistId = undefined;
          this.updatingWishlistId = undefined;
          this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });
        },
      });
  }

  public onAddItemToNewWishlist(): void {
    if (this.updatingWishlistId) {
      return;
    }

    this.isWishlistsModalOpen = false;

    // This page listen to the keyboard show event and scrolls downs thinking
    // that the event is related to the special request textarea, so we need
    // to ignore it if it's related to the wishlist modal instead
    this.shouldIgnoreKeyboardEvent = true;

    void this.platformService.wait(300).then(() => {
      void this.modalService
        .showModal<string>({ component: EditWishlistModalPage, id: EditWishlistModalPageIdentifier })
        .then((newName) => {
          if (newName) {
            void this.overlayService.showLoading().then(() => {
              this.accountService
                .createWishlist(newName, this.getWishlistItem())
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                  next: () => {
                    this.shouldIgnoreKeyboardEvent = false;
                    void this.overlayService.hideLoading();
                  },
                  error: (error: HttpErrorResponse) => {
                    this.shouldIgnoreKeyboardEvent = false;

                    const errorMessage = this.validationService.getErrorMessage(error);
                    this.loggerService.info({
                      component: 'ItemDetailsModal',
                      message: "couldn't create new wishlist",
                      details: { error, messageShownToUser: errorMessage },
                    });

                    void this.overlayService.hideLoading().then(() => {
                      this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });
                    });
                  },
                });
            });
          }
        });
    });
  }

  public onRemoveFromCart(): void {
    this.cartService.removeItemsFromCart(this.itemDetails.vendorId, [this.itemDetails.menuItemId]);
    this.dismiss({ addedToCart: true });
  }

  public onIncrementItemQuantity(): void {
    this.itemDetails.selectedQuantity = (this.itemDetails.selectedQuantity || 0) + 1;
  }

  public onDecrementItemQuantity(): void {
    if (this.itemDetails.selectedQuantity > 0) {
      this.itemDetails.selectedQuantity = this.itemDetails.selectedQuantity - 1;
    }
  }

  public onAddToCart(): void {
    const canContinue = this.validateItemBeforeBeingAddedToCart();

    if (!canContinue) {
      return;
    }

    const total = Helpers.multiplyWithPrecision(this.itemDetails.price, this.itemDetails.selectedQuantity);
    const minimumOrderValue =
      this.vendorDetails.serviceTypeRequirements?.find((requirement) => requirement.serviceType === this.itemDetails.serviceType)
        ?.minimumOrderAmount || 0;

    const newCartItem: NewCartItem = {
      catererId: this.vendorDetails.vendorId,
      catererName: this.vendorDetails.name,
      menuItemId: this.itemDetails.menuItemId,
      menuItemName: this.itemDetails.name,
      serviceType: this.itemDetails.serviceType,
      imageUrl: this.itemDetails.imageUrl,
      sourceId: this.itemDetails.vendorId,
      sourceType: OrderMenuItemSourceType.Vendor,
      quantity: this.itemDetails.selectedQuantity,
      total,
      minimumOrderValue,
      noticePeriod: this.itemDetails.noticePeriodHours,
    };

    void this.cartService
      .maybeAddToCart({
        item: newCartItem,
        address: this.selectedAddress,
      })
      .then(({ added }) => {
        if (added) {
          this.trackAddtoCartEvent();
          this.trackItemAddedCleverTapEvent(total);

          this.dismiss({ addedToCart: true });
        }
      });
  }

  public onSwiperLoaded(swiper: Swiper): void {
    if (swiper) {
      void this.platformService.wait(50).then(() => swiper.update());
    }
  }

  public onOpenImagesPreviewModalPage(): void {
    if (this.isBrowser) {
      return;
    }

    void this.modalService
      .showModal<number>({
        component: ImagesPreviewModalPage,
        id: ImagesPreviewModalPageIdentifier,
        componentProps: {
          imageUrls: this.itemDetails.largeImageUrls?.length > 0 ? this.itemDetails.largeImageUrls : this.itemDetails.imageUrls,
          selectedImageIndex: this.swiper.swiperRef.activeIndex,
        },
      })
      .then((currentIndex) => {
        if (!currentIndex) {
          currentIndex = 0;
        }

        if (this.swiper.swiperRef.activeIndex !== currentIndex) {
          this.swiper.swiperRef.slideTo(currentIndex, 0);
        }
      });
  }

  private getWishlistItem(): WishlistItem {
    return {
      itemId: this.itemDetails.menuItemId,
      quantity: this.itemDetails.selectedQuantity,
      femaleService: undefined,
      specialRequests: undefined,
      options: undefined,
      addons: undefined,
    };
  }

  private validateItemBeforeBeingAddedToCart(): boolean {
    if (!this.selectedAddress) {
      // In the webapp, selecting the address in the home page is optional so it
      // may happen that we reach this page without an area/address yet
      const alreadySelectedAddress = this.vendorsService.getAddressSearchFilters();
      if (alreadySelectedAddress) {
        this.selectedAddress = alreadySelectedAddress;
      } else {
        void this.modalService
          .showCard<Address>({
            component: AreaSelectionModalPage,
            id: AreaSelectionModalPageIdentifier,
            canDismiss: true,
            backdropDismiss: true,
            presentingElement: undefined,
            componentProps: {
              showCloseButton: true,
            },
          })
          .then((selectedAddress) => {
            if (!selectedAddress) {
              const message = this.translateService.instant('MENU_ITEM_DETAILS_PAGE.MISSING_AREA') as string;
              this.overlayService.showToast({ message, showCloseButton: true, type: 'error' });
              return;
            }

            this.selectedAddress = selectedAddress;
            this.onAddToCart();
          });

        return false;
      }
    }
    return true;
  }

  private trackShareEvent(): void {
    const isReferralProgramEnabled = this.settingsService.isReferralProgramFeatureEnabled();

    const eventDetails = {
      data: {
        ...this.getEventsSharedProperties(),
        [this.analyticsConfig.propertyName.referralProgram]: !!isReferralProgramEnabled,
      },
    };

    void this.analyticsService.trackEvent({ name: this.analyticsConfig.eventName.share, ...eventDetails });
    void this.analyticsService.trackEvent({ name: this.analyticsConfig.eventName.menuItemDetailsPageShare, ...eventDetails });
  }

  private initializeLocalPropertiesBasedOnVendorDetailsAndCart(): boolean {
    const itemFromCart = this.cartService.getItemFromCart({ vendorId: this.itemDetails.vendorId, itemId: this.itemDetails.menuItemId });
    const itemFromWishlist = this.getItemFromWishlist({ vendorId: this.itemDetails.vendorId, itemId: this.itemDetails.menuItemId });

    this.isUpdatingCartItem = !!itemFromCart;
    this.isUpdatingWishlistItem = this.shouldUpdateCurrentWishlist && !!itemFromWishlist;

    this.itemDetails.selectedQuantity = itemFromWishlist ? itemFromWishlist.quantity : itemFromCart ? itemFromCart.quantity : 1;
    this.fullDescriptionFormatted = this.itemDetails.description
      ? this.domSanitizer.bypassSecurityTrustHtml(this.itemDetails.description)
      : null;

    if (this.itemDetails.onSale) {
      this.discountPercentage = Math.floor((this.itemDetails.price * 100) / this.itemDetails.retailPrice);
    }

    return true;
  }

  private getItemFromWishlist({ vendorId, itemId }: { vendorId: number; itemId: number }): CartValidatedItem {
    let itemFromWishlist: CartValidatedItem = null;

    if (!this.currentWishlist?.details?.caterers?.length) {
      return itemFromWishlist;
    }

    vendorsLoop: for (const vendor of this.currentWishlist?.details.caterers) {
      if (vendor?.catererId === vendorId) {
        if (vendor.items?.length) {
          for (const item of vendor.items) {
            if (item?.menuItemId === itemId) {
              itemFromWishlist = item;

              break vendorsLoop;
            }
          }
        }
      }
    }

    return itemFromWishlist;
  }

  private trackItemViewedCleverTapEvent(): void {
    if (!this.shouldTrackCleverTapEvents) {
      return;
    }

    let serviceType: number = null;
    let itemName: MultiLanguageValue = null;

    if (this.itemDetails?.nameJson) {
      serviceType = this.itemDetails.serviceType;
      itemName = JSON.parse(this.itemDetails.nameJson) as MultiLanguageValue;
    }

    this.analyticsService.trackItemViewedCleverTapEvent({
      id: this.itemDetails.menuItemId,
      nameEn: itemName ? Helpers.getLocalizedMultiLanguageValue(itemName, 'en') : null,
      nameAr: itemName ? Helpers.getLocalizedMultiLanguageValue(itemName, 'ar') : null,
      vendorId: this.itemDetails.vendorId,
      serviceType,
    });
  }

  private trackItemAddedCleverTapEvent(itemTotal: number): void {
    if (!this.shouldTrackCleverTapEvents) {
      return;
    }

    let serviceType: number;
    let itemName: MultiLanguageValue;

    if (this.itemDetails) {
      serviceType = this.itemDetails.serviceType;
      itemName = this.itemDetails.nameJson ? (JSON.parse(this.itemDetails.nameJson) as MultiLanguageValue) : undefined;
    }

    this.analyticsService.trackItemAddedCleverTapEvent({
      id: this.itemDetails.menuItemId,
      nameEn: itemName ? Helpers.getLocalizedMultiLanguageValue(itemName, 'en') : null,
      nameAr: itemName ? Helpers.getLocalizedMultiLanguageValue(itemName, 'ar') : null,
      currency: this.settingsService.currencySymbolEN,
      amount: itemTotal,
      vendorId: this.itemDetails.vendorId,
      serviceType,
    });
  }

  private trackPageView(): void {
    void this.analyticsService.trackView({
      name: this.analyticsConfig.pageName.groceryItemDetailsPage,
      data: {
        ...this.getEventsSharedProperties(),
        [this.analyticsConfig.propertyName.dateTime]: null,
        [this.analyticsConfig.propertyName.bilbaytNow]: false,
      },
      includeAreaDateTime: true,
    });
  }

  private trackAddtoCartEvent(): void {
    void this.analyticsService.trackEvent({
      name: this.analyticsConfig.eventName.menuItemDetailsPageAddToCart,
      data: {
        [this.analyticsConfig.propertyName.catererId]: this.itemDetails.vendorId,
        [this.analyticsConfig.propertyName.menuItemId]: this.itemDetails.menuItemId,
        [this.analyticsConfig.propertyName.amount]: this.itemDetails.price,
        [this.analyticsConfig.propertyName.currency]: this.settingsService.currencySymbolEN,
        [this.analyticsConfig.propertyName.isGroceryItem]: true,
      },
      includeAreaDateTime: true,
    });
  }

  private getEventsSharedProperties(): Record<string, number | boolean> {
    return {
      [this.analyticsConfig.propertyName.catererId]: this.itemDetails.vendorId,
      [this.analyticsConfig.propertyName.menuItemId]: this.itemDetails.menuItemId,
      [this.analyticsConfig.propertyName.fromFeaturedMenuItems]: this.sourceType === OrderMenuItemSourceType.FeaturedMenuItem,
      [this.analyticsConfig.propertyName.fromFeaturedVendors]: this.sourceType === OrderMenuItemSourceType.SponseredVendor,
    };
  }

  private initializeKeyboardEventsListener(): void {
    if (this.windowService.isWindowDefined) {
      this.windowService.window.addEventListener(KEYBOARD_WILL_SHOW_EVENT, this.willOpenKeyboardEventHandler, false);
      this.windowService.window.addEventListener(KEYBOARD_DID_SHOW_EVENT, this.didOpenKeyboardEventHandler, false);
      this.windowService.window.addEventListener(KEYBOARD_WILL_HIDE_EVENT, this.willCloseKeyboardEventHandler, false);
    }
  }

  private unsubscribeFromKeyboardEvents(): void {
    if (this.windowService.isWindowDefined) {
      this.windowService.window.removeEventListener(KEYBOARD_WILL_SHOW_EVENT, this.willOpenKeyboardEventHandler);
      this.windowService.window.removeEventListener(KEYBOARD_DID_SHOW_EVENT, this.didOpenKeyboardEventHandler);
      this.windowService.window.removeEventListener(KEYBOARD_WILL_HIDE_EVENT, this.willCloseKeyboardEventHandler);
    }
  }

  private willOpenKeyboardEventHandler = () => {
    if (this.shouldIgnoreKeyboardEvent) {
      return;
    }

    this.ngZone.run(() => {
      this.shouldHideFooter = true;
    });
  };

  private didOpenKeyboardEventHandler = () => {
    if (this.shouldIgnoreKeyboardEvent) {
      return;
    }

    void this.platformService.wait(50).then(() => {
      this.content.scrollToBottom(300).catch(() => {});
    });
  };

  private willCloseKeyboardEventHandler = () => {
    if (this.shouldIgnoreKeyboardEvent) {
      return;
    }

    this.ngZone.run(() => {
      void this.platformService.wait(100).then(() => {
        this.shouldHideFooter = false;
      });
    });
  };

  private dismiss({ addedToCart, updatedWishlist }: { addedToCart: boolean; updatedWishlist?: boolean }): void {
    void this.modalService.dismissModal({
      id: GroceryItemDetailsModalPageIdentifier,
      data: { addedToCart, updatedWishlist: !!updatedWishlist },
    });

    if (addedToCart && this.sourceType === OrderMenuItemSourceType.FeaturedMenuItem) {
      this.deepLinkService.openCartPage();
    }
  }
}
