import { Geolocation, Geoposition } from '@awesome-cordova-plugins/geolocation/ngx';

import { PlatformService } from '../ssr/platform.service';
import { WindowService } from '../ssr/window.service';

class BrowserGeolocationProvider extends Geolocation {
  constructor(private windowService: WindowService) {
    super();
  }

  public getCurrentPosition(): Promise<Geoposition> {
    if (this.windowService.isWindowDefined && 'geolocation' in this.windowService.window.navigator) {
      return new Promise((resolve, reject) => {
        this.windowService.window.navigator.geolocation.getCurrentPosition(
          (position) => resolve(position),
          (error) => reject(error),
        );
      });
    }

    return Promise.resolve(null as Geoposition);
  }
}

class MobileGeolocationProvider extends Geolocation {}

function geolocationFactory(windowService: WindowService, platformService: PlatformService): Geolocation {
  return platformService.areCordovaPluginsAvailable ? new MobileGeolocationProvider() : new BrowserGeolocationProvider(windowService);
}

export const geolocationProvider = { provide: Geolocation, useFactory: geolocationFactory, deps: [WindowService, PlatformService] };
