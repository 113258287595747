import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SentryPlugin } from './native-plugins/sentry.plugin';

import { User } from '../models/user.model';

export interface InfoLogParams {
  component: string;
  message: string;
  fromPlugin?: boolean;
  details?: {
    error: unknown;
    messageShownToUser?: unknown;
  };
}

export interface ErrorLogParams extends InfoLogParams {
  error: Error | HttpErrorResponse | unknown;
}

@Injectable({ providedIn: 'root' })
export class LoggerService {
  constructor(private sentryPlugin: SentryPlugin) {}

  public initialize(): void {
    this.sentryPlugin.initialize();
  }

  public info({ message, component, details, fromPlugin }: InfoLogParams): void {
    this.sentryPlugin.info(`[${component}] ${message}`, { ...(details || {}), fromPlugin: !!fromPlugin });
  }

  public error({ message, component, error, details, fromPlugin }: ErrorLogParams): void {
    const { error: errorInstance, extraDetails } = this.normalizeError(error, message, component);

    this.sentryPlugin.error(errorInstance, {
      origin: `[${component}] ${message}`,
      fromPlugin: !!fromPlugin,
      details: { ...(details || {}), additionalDetails: extraDetails || undefined },
    });
  }

  public identifyUser(user: User): void {
    this.sentryPlugin.identifyUser(user);
  }

  public resetUser(): void {
    this.sentryPlugin.resetUser();
  }

  private normalizeError(error: unknown, message: string, component: string): { error: Error; extraDetails?: string } {
    if (error instanceof Error) {
      return { error };
    }

    if (error instanceof HttpErrorResponse) {
      const { status, statusText, message } = error;
      const errorMessage = `HTTP Error: ${status} ${statusText} - ${message}`;
      return {
        error: new Error(errorMessage),
        extraDetails: JSON.stringify(error),
      };
    }

    if (error && typeof error === 'object') {
      const errorObj = error as Record<string, unknown>;
      const errorMessage =
        'message' in errorObj
          ? String(errorObj.message)
          : `[${component}] ${message}: ${JSON.stringify(errorObj, null, 2).substring(0, 100)}`;

      return {
        error: new Error(errorMessage),
        extraDetails: JSON.stringify(error),
      };
    }

    return {
      error: new Error(String(error) || 'An unknown error occurred'),
      extraDetails: JSON.stringify(error),
    };
  }
}
