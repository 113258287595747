import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { VendorsService } from '../services/vendors.service';

@Injectable({
  providedIn: 'root',
})
export class AddressNotSelectedGuard implements CanActivate {
  constructor(private vendorsService: VendorsService) {}

  canActivate() {
    return !this.vendorsService.getAddressSearchFilters();
  }
}
