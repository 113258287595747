import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { NavigationService } from '../services/navigation.service';
import { PlatformService } from '../services/ssr/platform.service';

import { CheckoutPageParams } from '../../pages/checkout/checkout-routing.module';

@Injectable({
  providedIn: 'root',
})
export class OnlinePaymentErrorGuard implements CanActivate {
  constructor(private navigationService: NavigationService, private platformService: PlatformService) {}

  canActivate() {
    if (!this.platformService.isBrowser) {
      void this.navigationService.navigateBackToHomePage();
      return false;
    }

    const pageParams: CheckoutPageParams = { shouldShowPaymentError: true };
    void this.navigationService.navigateTo('/checkout', false, { state: pageParams });
    return false;
  }
}
