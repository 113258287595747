import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { AreaSelectionModalPage } from './area-selection-modal.page';

import { AreasByProvinceComponentModule } from '../../shared/components/areas-by-province/areas-by-province.module';
import { GoogleMapsComponentModule } from '../../shared/components/google-maps/google-maps.module';
import { SavedAddressesComponentModule } from '../../shared/components/saved-addresses/saved-addresses.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild(),
    GoogleMapsComponentModule,
    AreasByProvinceComponentModule,
    SavedAddressesComponentModule,
  ],
  declarations: [AreaSelectionModalPage],
})
export class AreaSelectionModalPageModule {}
