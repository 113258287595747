import {
  BranchIo,
  BranchIoAnalytics,
  BranchIoPromise,
  BranchIoProperties,
  BranchUniversalObject,
} from '@awesome-cordova-plugins/branch-io/ngx';

import { EnvironmentService, isCypressTestEnv } from '../environment.service';
import { PlatformService } from '../ssr/platform.service';
import { WindowService } from '../ssr/window.service';

interface BranchWebSdk {
  init: (key: string, callback: (error: Error, branchLink: { data: string }) => void) => void;
  data: (callback: (error: Error, branchLink: { data: string }) => void) => void;
  setIdentity: (identity: string, callback: (error: Error, identityDetails: unknown) => void) => void;
  logout: (callback: (error: Error, identityDetails: unknown) => void) => void;
  logEvent: (event: string, metaData: Record<string, unknown>, callback: (error: Error) => void) => void;
  link: (data: Record<string, unknown>, callback: (error: Error, link: string) => void) => void;
}

let branch: BranchWebSdk;

class BrowserBranchProvider extends BranchIo {
  constructor(private windowService: WindowService, private environmentService: EnvironmentService) {
    super();

    if (!this.windowService.isWindowDefined) {
      return;
    }

    if (isCypressTestEnv()) {
      return;
    }

    import('branch-sdk')
      .then((branchLib: { default: BranchWebSdk }) => {
        if (branchLib && branchLib.default) {
          branch = branchLib.default;
        }
      })
      .catch(() => {});
  }

  public initSession(): Promise<BranchIoPromise> {
    if (!branch) {
      return Promise.resolve(undefined as BranchIoPromise);
    }

    return new Promise<BranchIoPromise>((resolve, reject) => {
      branch.init(this.environmentService.branchKey, (error, branchLink) => {
        if (error) {
          reject(error);
        } else {
          resolve(!branchLink.data ? undefined : (JSON.parse(branchLink.data) as unknown as BranchIoPromise));
        }
      });
    });
  }

  public getLatestReferringParams(): Promise<BranchIoPromise> {
    if (!branch) {
      return Promise.resolve(undefined as BranchIoPromise);
    }

    return new Promise<BranchIoPromise>((resolve, reject) => {
      branch.data((error, branchLink) => {
        if (error) {
          reject(error);
        } else {
          resolve(!branchLink.data ? undefined : (JSON.parse(branchLink.data) as unknown as BranchIoPromise));
        }
      });
    });
  }

  public createBranchUniversalObject(_properties: BranchIoProperties): Promise<BranchUniversalObject> {
    return Promise.resolve({
      generateShortUrl: (branchAnalytics: BranchIoAnalytics, branchProperties: BranchIoProperties) => {
        if (!branch) {
          return Promise.resolve(undefined);
        }

        return new Promise((resolve, reject) => {
          branch.link({ ...branchAnalytics, data: { ...branchProperties } }, (error, link) => {
            if (error) {
              reject(error);
            } else {
              resolve({ url: link });
            }
          });
        });
      },
    } as BranchUniversalObject);
  }

  public setIdentity(userId: string): Promise<unknown> {
    if (!branch) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      branch.setIdentity(userId, (error, data) => {
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    });
  }

  public logout(): Promise<unknown> {
    if (!branch) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      branch.logout((error, data) => {
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    });
  }

  public sendBranchEvent(event: string, metaData: Record<string, unknown>): Promise<unknown> {
    if (!branch) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      branch.logEvent(event, metaData, (error) => {
        if (error) {
          reject(error);
        } else {
          resolve(undefined);
        }
      });
    });
  }
}

class MobileBranchProvider extends BranchIo {
  constructor(private windowService: WindowService, private platformService: PlatformService) {
    super();
  }

  public sendBranchEvent(event: string, metaData: Record<string, unknown>): Promise<unknown> {
    if (this.platformService.areCordovaPluginsAvailable && this.windowService.isWindowDefined) {
      const branchWindowRef = (this.windowService.window as unknown as { Branch: BranchIo }).Branch;
      if (branchWindowRef) {
        // We don't use the callbacks since there's an issue in the Branch
        // cordova plugin and they are not being invoked at all
        // https://github.com/BranchMetrics/cordova-ionic-phonegap-branch-deep-linking-attribution/issues/565
        void branchWindowRef.sendBranchEvent(event, metaData);
      }
    }

    return Promise.resolve();
  }
}

function branchFactory(windowService: WindowService, platformService: PlatformService, environmentService: EnvironmentService): BranchIo {
  return platformService.areCordovaPluginsAvailable
    ? new MobileBranchProvider(windowService, platformService)
    : new BrowserBranchProvider(windowService, environmentService);
}

export const branchProvider = {
  provide: BranchIo,
  useFactory: branchFactory,
  deps: [WindowService, PlatformService, EnvironmentService],
};
