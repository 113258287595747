<ion-content>
  <div data-cy="backdrop" class="modal-container" (click)="onDismiss()">
    <div class="modal-content" (click)="$event.preventDefault(); $event.stopPropagation()">
      <div class="date-time-picker">
        <div class="calendar">
          <div class="calendar-header">
            <div
              tappable
              class="calendar-header-prev"
              [class.disabled]="showCalendar ? !calendar.isPrevAvailable : !canCheckPrevDate"
              (click)="showCalendar ? onShowPrevMonth() : onShowPrevDate()"
            >
              <ion-icon class="calendar-header-icon" src="/assets/custom-icons/vendors-page/chevron.svg"></ion-icon>
            </div>
            <p tappable data-cy="calendar-header-title" class="calendar-header-title" (click)="onShowCalendarForSelectedDateTime()">
              <ng-container *ngIf="showCalendar && calendar">{{ calendar.monthName }} {{ calendar.year }}</ng-container>
              <ng-container *ngIf="!showCalendar">
                {{ nextAvailableDate | formatDateTime : { format: "ddd DD MMMM YYYY", useRelativeDate: false } }}
                <span class="calendar-header-subtitle">
                  {{ "ITEM_AVAILABILITY_DATE_TIME_PICKER_MODAL.CHANGE_THE_DATE" | translate | lowercase }}
                </span>
              </ng-container>
            </p>
            <div
              tappable
              data-cy="calendar-header-next-button"
              class="calendar-header-next"
              [class.disabled]="showCalendar ? !calendar.isNextAvailable : false"
              (click)="showCalendar ? onShowNextMonth() : onShowNextDate()"
            >
              <ion-icon class="calendar-header-icon" src="/assets/custom-icons/vendors-page/chevron.svg"></ion-icon>
            </div>
          </div>

          <div
            class="calendar-details"
            [class.with-error]="shouldShowLoadingErrorMessage || shouldShowItemNotAvailableSoonMessage"
            [class.with-warning]="shouldShowItemNotAvailableBeforeDateMessage"
            [style.height.px]="calendarContainerHeight"
          >
            <div *ngIf="isLoading" class="loading-spinner">
              <ion-spinner duration="1000" name="dots"></ion-spinner>
            </div>

            <ng-container *ngIf="!isLoading">
              <p
                @animatedCalendarMessage
                class="calendar-warning"
                *ngIf="shouldShowItemNotAvailableBeforeDateMessage"
                [innerHtml]="
                  'ITEM_AVAILABILITY_DATE_TIME_PICKER_MODAL.ITEM_NOT_AVAILABLE_BEFORE_DATE'
                    | translate
                      : {
                          startDate: (requestedDate | formatDateTime : { format: 'ddd DD MMMM', useRelativeDate: false }),
                          endDate: (nextAvailableDate | formatDateTime : { format: 'ddd DD MMMM', useRelativeDate: false })
                        }
                "
              ></p>

              <ng-container *ngIf="shouldShowLoadingErrorMessage || shouldShowItemNotAvailableSoonMessage">
                <p
                  @animatedCalendarMessage
                  class="calendar-message"
                  [innerHTML]="
                    shouldShowLoadingErrorMessage
                      ? ('ERROR_MESSAGE.DEFAULT_MESSAGE' | translate)
                      : ('ITEM_AVAILABILITY_DATE_TIME_PICKER_MODAL.ITEM_NOT_AVAILABLE_SOON'
                        | translate
                          : {
                              startDate: (requestedDate | formatDateTime : { format: 'ddd DD MMMM', useRelativeDate: false }),
                              endDate: (nextAvailableDate | formatDateTime : { format: 'ddd DD MMMM', useRelativeDate: false })
                            })
                  "
                ></p>

                <div @animatedCalendarFooter class="calendar-footer" [style.marginTop.px]="footerMarginTop">
                  <ion-button
                    color="green"
                    expand="block"
                    class="select-button custom-button"
                    [style.height.px]="footerHeight"
                    (click)="onHandleTryAgain()"
                  >
                    {{
                      (shouldShowLoadingErrorMessage ? "TRY_AGAIN" : "ITEM_AVAILABILITY_DATE_TIME_PICKER_MODAL.TRY_A_LATER_DATE")
                        | translate
                    }}
                  </ion-button>
                </div>
              </ng-container>

              <ng-container *ngIf="!!showCalendar || (!!nextAvailableTimes && !!nextAvailableTimes.length)">
                <div @animatedCalendarContent class="calendar-body" [class.month-view]="showCalendar">
                  <ng-container *ngIf="showCalendar">
                    <p *ngFor="let weekDayName of calendar.weekDayNames" class="calendar-body-cell week-name">{{ weekDayName }}</p>
                    <div *ngFor="let emptyCell of calendar.daysBeforeStartDay" class="calendar-body-cell"></div>
                    <div
                      *ngFor="let day of calendar.days; let i = index"
                      data-cy="calendar-date-option"
                      class="calendar-body-cell"
                      [class.today]="day.isToday"
                      [class.disabled]="day.isBeforeToday"
                      [class.selected]="day.valueIsoString === newSelectedDate"
                      (click)="onUpdateSelectedDate(day.valueIsoString)"
                    >
                      <p tappable class="calendar-body-cell-content" [style.height.px]="calendarItemHeight">{{ day.value }}</p>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="!showCalendar">
                    <div
                      data-cy="calendar-time-option"
                      class="calendar-body-cell"
                      [class.disabled]="!hourMinuteValue.available"
                      [class.selected]="
                        newIsOrderForNowSelected
                          ? hourMinuteValue.isOrderForNow === true
                          : hourMinuteValue.dateTimeValue === newSelectedDateTime
                      "
                      *ngFor="let hourMinuteValue of nextAvailableTimes"
                    >
                      <p
                        tappable
                        class="calendar-body-cell-content"
                        [style.height.px]="calendarItemHeight"
                        (click)="onUpdateSelectedDateTime(hourMinuteValue)"
                      >
                        {{ hourMinuteValue.text }}
                      </p>
                    </div>
                  </ng-container>
                </div>

                <div @animatedCalendarFooter class="calendar-footer" [style.marginTop.px]="footerMarginTop">
                  <ion-button
                    color="green"
                    expand="block"
                    data-cy="calendar-submit-button"
                    class="select-button custom-button"
                    [class.disabled]="showCalendar ? !newSelectedDate : !newSelectedDateTime && !newIsOrderForNowSelected"
                    [style.height.px]="footerHeight"
                    (click)="onHandleSubmit()"
                  >
                    <ng-container *ngIf="!showCalendar">
                      <span *ngIf="newSelectedDateTime || newIsOrderForNowSelected">
                        {{ "ITEM_AVAILABILITY_DATE_TIME_PICKER_MODAL.SELECT" | translate }}

                        <span class="selected-date-time">
                          {{
                            newIsOrderForNowSelected
                              ? ("ORDER_FOR_NOW" | translate)
                              : (newSelectedDateTime | formatDateTime : { format: "ddd DD MMMM h:mm A", useRelativeDate: false })
                          }}
                        </span>
                      </span>

                      <span *ngIf="!newSelectedDateTime && !newIsOrderForNowSelected">
                        {{ "ITEM_AVAILABILITY_DATE_TIME_PICKER_MODAL.EMPTY_DATE_TIME" | translate }}
                      </span>
                    </ng-container>

                    <ng-container *ngIf="showCalendar">
                      <span *ngIf="newSelectedDate">
                        {{ "ITEM_AVAILABILITY_DATE_TIME_PICKER_MODAL.CHECK_AVAILABILITY_FOR" | translate }}

                        <span class="selected-date-time">
                          {{ newSelectedDate | formatDateTime : { format: "ddd DD MMM", useRelativeDate: false } }}
                        </span>
                      </span>

                      <span *ngIf="!newSelectedDate">
                        {{ "ITEM_AVAILABILITY_DATE_TIME_PICKER_MODAL.EMPTY_DATE" | translate }}
                      </span>
                    </ng-container>
                  </ion-button>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
