import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { CartService } from '../services/cart.service';
import { NavigationService } from '../services/navigation.service';
import { OverlayService } from '../services/overlay.service';

@Injectable({
  providedIn: 'root',
})
export class InvalidCartGuard implements CanActivate {
  constructor(
    private cartService: CartService,
    private overlayService: OverlayService,
    private translateService: TranslateService,
    private navigationService: NavigationService,
  ) {}

  canActivate() {
    const isCartEmpty = this.cartService.isCartEmpty();

    if (isCartEmpty) {
      void this.navigationService.navigateBackToHomePage().then(() =>
        this.overlayService.showToast({
          type: 'error',
          message: this.translateService.instant('ERROR_MESSAGE.EXPIRED_OR_EMPTY_CART') as string,
          showCloseButton: true,
        }),
      );
      return false;
    }

    return true;
  }
}
