import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { LoyaltyProgramModalPage } from './loyalty-program-modal.page';

import { PipesModule } from '../../shared/pipes/pipes.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, PipesModule, TranslateModule.forChild()],
  declarations: [LoyaltyProgramModalPage],
})
export class LoyaltyProgramModalPageModule {}
