import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { IonContent } from '@ionic/angular';

import { NetworkPlugin } from './native-plugins/network.plugin';

import { ScrollService } from './scroll.service';
import { WindowService } from './ssr/window.service';

import { ApiError } from '../models/api-error';

@Injectable({ providedIn: 'root' })
export class ValidationService {
  constructor(
    private windowService: WindowService,
    private translateService: TranslateService,
    private networkPlugin: NetworkPlugin,
    private scrollService: ScrollService,
  ) {}

  // Method that tries to scroll and set the focus on the first invalid field from the form
  // passed as parameter. If the field is readonly this method will assume it is a custom
  // dropdown and will trigger a click on it
  public focusFirstInvalidField(content: IonContent, formElement: HTMLElement): void {
    // Because of this Ionic issue it may happen that the ion-item container has the
    // 'ng-invalid' class but the input/textarea within that item is valid. So we need
    // to get the first element with ng-invalid that is not an ion-item or ion-row
    // https://github.com/ionic-team/ionic-v3/issues/306
    const formControlInvalid: HTMLElement = formElement.querySelector(':not(ion-item):not(ion-row).ng-invalid');

    if (!formControlInvalid) {
      return;
    }

    const parentItem: HTMLElement =
      formControlInvalid.closest('ion-row') || formControlInvalid.closest('ion-item,[ion-item]') || formControlInvalid;

    void this.scrollService.scrollToFormField(content, parentItem).then(() => {
      this.setFocusOnFormField(formControlInvalid);
    });
  }

  // Method that tries to obtain the error message from an error result object
  public getErrorMessage(httpErrorResponse?: HttpErrorResponse): string {
    if (this.networkPlugin.isOffline()) {
      return this.translateService.instant('ERROR_MESSAGE.NO_INTERNET_MESSAGE') as string;
    }

    if (!httpErrorResponse || !httpErrorResponse.error) {
      return this.getDefaultErrorMessage();
    }

    if (httpErrorResponse.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      return this.getDefaultErrorMessage();
    }

    let error: ErrorEvent | string | Record<string, unknown>;

    // If the error is coming from the API, it
    // could be stringified

    try {
      error = JSON.parse(httpErrorResponse.error as string) as Record<string, unknown>;
    } catch (e) {
      error = httpErrorResponse.error as ErrorEvent;
    }

    if (typeof error === 'object') {
      const apiErrorObject = error as ApiError;

      // 1) Identity Server errors
      if (apiErrorObject.error) {
        return this.getIdentityServerErrorMessage(apiErrorObject);
      }

      // 2) ModelStateError or IdentityResultError errors
      if (apiErrorObject.type) {
        switch (apiErrorObject.type) {
          case 'ModelStateError':
            return this.getModelStateErrorMessage(apiErrorObject);
          case 'IdentityResultError':
            return this.getIdentityResultErrorMessage(apiErrorObject);
        }
      }

      // 3) Other errors returned by our API
      if (apiErrorObject.messages?.length) {
        return apiErrorObject.messages.map((errorMessage) => this.formatErrorMessage(errorMessage)).join(' ');
      }
    }

    // Is not a recognized error, so just return a default message
    return this.getDefaultErrorMessage();
  }

  // Method that returns the default error message
  public getDefaultErrorMessage(): string {
    return this.translateService.instant('ERROR_MESSAGE.DEFAULT_MESSAGE') as string;
  }

  public emailValidator(control: UntypedFormControl): Record<string, boolean> {
    // RFC 2822 compliant regex
    if (control.value === '') {
      return { required: true };
    }

    const isValid =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.exec(
        control.value as string,
      );

    if (isValid) {
      return null;
    }

    return { invalidEmailAddress: true };
  }

  // Method that tries to set the focus in the element sent as parameter. If the element is
  // readonly the app assumes that is a dropdown so it triggers a click on the element
  private setFocusOnFormField(element: HTMLElement): void {
    if (this.windowService.isWindowDefined) {
      if (!element.hasAttribute('readonly')) {
        const targetField = element.querySelector('input') || element.querySelector('textarea') || element;
        targetField.focus();
      } else {
        const fakeMouseEvent = new MouseEvent('click', { bubbles: true, cancelable: true, view: this.windowService.window });
        element.dispatchEvent(fakeMouseEvent);
      }
    }
  }

  private getIdentityServerErrorMessage(apiError: ApiError): string {
    if (apiError.error === 'invalid_grant') {
      return apiError.error_description
        ? this.formatErrorMessage(apiError.error_description)
        : (this.translateService.instant('ERROR_MESSAGE.INVALID_GRANT') as string);
    }

    return apiError.error_description ? this.formatErrorMessage(apiError.error_description) : this.getDefaultErrorMessage();
  }

  private getModelStateErrorMessage(apiError: ApiError): string {
    let message = '';

    if (!apiError.metadata || !apiError.metadata.fields || !apiError.metadata.fields.length) {
      return this.getDefaultErrorMessage();
    }

    message = apiError.metadata.fields
      .filter((field) => field.message)
      .map((field) => this.formatErrorMessage(field.message))
      .join(' ');

    return message;
  }

  private getIdentityResultErrorMessage(apiError: ApiError): string {
    let message = '';

    if (!apiError.metadata || !apiError.metadata.errors || !apiError.metadata.errors.length) {
      return this.getDefaultErrorMessage();
    }

    message = apiError.metadata.errors.map((errorMessage) => this.formatErrorMessage(errorMessage)).join(' ');

    return message ? message : this.getDefaultErrorMessage();
  }

  private formatErrorMessage(message: string): string {
    if (!message) {
      return message;
    }

    let formattedMessage = message;

    // Add ending dot
    if (formattedMessage[formattedMessage.length - 1] !== '.') {
      formattedMessage += '.';
    }

    // Make first letter to be uppercase
    formattedMessage = formattedMessage.charAt(0).toUpperCase() + formattedMessage.slice(1);

    return formattedMessage;
  }
}
