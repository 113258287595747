<ion-header class="white-header ion-no-border">
  <ion-toolbar class="header-toolbar" fixed>
    <ion-buttons class="header-buttons start" slot="start">
      <ion-button class="header-button" fill="clear" (click)="onDismiss()">
        <ion-icon class="header-button-icon" slot="icon-only" src="/assets/custom-icons/b-times.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ "CART_PAGE.CART" | translate }}</ion-title>
    <ion-buttons class="header-buttons end" slot="end">
      <ion-button class="header-button" (click)="inEditMode = !inEditMode" [class.disabled]="isCartEmpty">
        {{ (inEditMode ? "DONE" : "EDIT") | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [class.with-loyalty-program-reward]="!!loyaltyProgramNextRewardDetails">
  <app-cart-details
    [inEditMode]="inEditMode"
    (checkoutStarted)="onCheckoutStarted()"
    (editModeChanged)="onEditModeChanged($event)"
    (cartTotalUpdated)="onCartTotalUpdated()"
  ></app-cart-details>
</ion-content>

<ion-footer class="animated-footer" *ngIf="!isCartEmpty">
  <ion-toolbar fixed>
    <div *ngIf="loyaltyProgramNextRewardDetails" class="loyalty-program-reward">
      <app-animated-image class="loyalty-program-reward-icon" [imageUrl]="loyaltyProgramNextRewardDetails.imageUrl"></app-animated-image>
      <p class="loyalty-program-reward-text" [innerHTML]="loyaltyProgramNextRewardDetails.description"></p>
    </div>

    <ion-button *ngIf="!inEditMode" expand="block" color="green" class="custom-button" data-cy="checkout-button" (click)="onCheckout()">
      <span>{{ "CHECKOUT" | translate }}</span>
    </ion-button>

    <ion-button *ngIf="inEditMode" (click)="onClearCart()" class="custom-button" expand="block" color="red">
      <span>{{ "CART_PAGE.CLEAR_CART" | translate }}</span>
    </ion-button>
  </ion-toolbar>
</ion-footer>
