<div class="modal-container">
  <div class="modal-content">
    <div class="main-section">
      <div class="title">
        {{ (isGiftCard ? "CART_PAGE.GIFT_CARD_MODAL_TITLE" : "CART_PAGE.SPECIAL_REQUEST_MODAL_TITLE") | translate }}
      </div>
      <div class="description">
        {{
          (isGiftCard ? "CART_PAGE.GIFT_CARD_MODAL_DESCRIPTION" : "CART_PAGE.SPECIAL_REQUEST_MODAL_DESCRIPTION")
            | translate: { vendor: name }
        }}
      </div>
      <textarea
        #textArea
        [(ngModel)]="comment"
        [placeholder]="(isGiftCard ? 'CART_PAGE.GIFT_CARD_MODAL_PLACEHOLDER' : 'CART_PAGE.SPECIAL_REQUEST_MODAL_PLACEHOLDER') | translate"
      ></textarea>
    </div>

    <div class="footer-section">
      <ion-button class="custom-button" expand="block" color="grey" (click)="onDismiss()">{{ "CANCEL" | translate }}</ion-button>
      <ion-button class="custom-button" expand="block" color="green" (click)="onSubmit()">{{ "SAVE" | translate }}</ion-button>
    </div>
  </div>
</div>
