import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { interval, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map } from 'rxjs/operators';

import { SettingsService } from './settings.service';
import { PlatformService } from './ssr/platform.service';

import { RESPONSE_TIMING_URL } from '../config/app.config';

export const SUBMIT_INTERVAL_IN_MS = 30_000;

interface ResponseTimingData {
  userId: string;
  httpRequest: string;
  statusCode: number;
  responseTime: number;
  createdAt: string;
}

@Injectable({ providedIn: 'root' })
export class ResponseTimingService {
  private responseTimingDataQueue: Array<ResponseTimingData> = [];

  constructor(private http: HttpClient, private settingsService: SettingsService, private platformService: PlatformService) {
    // Interval and timer operators doesn't work in SSR
    // https://stackoverflow.com/a/57456495/3915438
    if (!this.platformService.isServer) {
      interval(SUBMIT_INTERVAL_IN_MS)
        .pipe(
          filter(
            () =>
              this.platformService.areCordovaPluginsAvailable &&
              this.settingsService.isInitialized() &&
              this.settingsService.shouldSendApiTimingData(),
          ),
          concatMap(() => this.submitResponseTimingData()),
        )
        .subscribe();
    }
  }

  public logResponseTimingData(data: ResponseTimingData): void {
    this.responseTimingDataQueue.push(data);
  }

  private submitResponseTimingData(): Observable<boolean> {
    const logsToSend = this.responseTimingDataQueue.splice(0);

    if (!logsToSend?.length) {
      return of(true);
    }

    return this.http.post<void>(RESPONSE_TIMING_URL, logsToSend).pipe(
      map(() => true),
      catchError(() => {
        this.responseTimingDataQueue.unshift(...logsToSend);
        return of(false);
      }),
    );
  }
}
