import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';

import { PlatformService } from '../ssr/platform.service';
import { WindowService } from '../ssr/window.service';

class BrowserCallNumberProvider extends CallNumber {
  constructor(private windowService: WindowService) {
    super();
  }

  public callNumber(numberToCall: string, _bypassAppChooser: boolean): Promise<unknown> {
    if (this.windowService.isWindowDefined) {
      this.windowService.window.location.href = `tel:${numberToCall}`;
    }

    return Promise.resolve();
  }
}

class MobileCallNumberProvider extends CallNumber {}

function callNumberFactory(windowService: WindowService, platformService: PlatformService): CallNumber {
  return platformService.areCordovaPluginsAvailable ? new MobileCallNumberProvider() : new BrowserCallNumberProvider(windowService);
}

export const callNumberProvider = { provide: CallNumber, useFactory: callNumberFactory, deps: [WindowService, PlatformService] };
