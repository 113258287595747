import { Component, ViewEncapsulation } from '@angular/core';

import { ModalService } from '../../core/services/modal.service';
import { PushNotificationsService } from '../../core/services/push-notifications.service';

export const PushNotificationsModalPageIdentifier = 'push-notifications-modal';

@Component({
  selector: 'app-push-notifications-modal',
  templateUrl: './push-notifications-modal.page.html',
  styleUrls: ['./push-notifications-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PushNotificationsModalPage {
  constructor(private modalService: ModalService, private pushNotificationsService: PushNotificationsService) {}

  public onContinue(): void {
    void this.pushNotificationsService.grantPermissionAndInitialize().then(() => {
      this.dismiss();
    });
  }

  public onSkip(): void {
    this.dismiss();
  }

  private dismiss(): void {
    void this.modalService.dismissModal({ id: PushNotificationsModalPageIdentifier });
  }
}
