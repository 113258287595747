import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalSearchEvent } from '../models/global-search-event.model';
import { GlobalSearchResults } from '../models/global-search-results.model';
import { MultiLanguageValue } from '../models/multi-language-value.model';
import { SearchTrackingData } from '../models/search-tracking-data.model';

import { AnalyticsService } from './analytics.service';
import { LoggerService } from './logger.service';
import { SettingsService } from './settings.service';
import { StateService } from './state.service';

import { AnalyticsConfig, TOKEN_ANALYTICS_CONFIG } from '../config/analytics.config';
import { AppConfig, TOKEN_CONFIG } from '../config/app.config';

import { Helpers } from '../helpers';

export interface GlobalSearchTopSearchedResults {
  serviceTypes: { [id: number]: { id: number; nameEn: string; nameAr: string; frequency: number } };
  vendors: { [id: number]: { id: number; nameEn: string; nameAr: string; frequency: number } };
}

@Injectable({ providedIn: 'root' })
export class SearchService {
  constructor(
    private http: HttpClient,
    private analyticsService: AnalyticsService,
    private loggerService: LoggerService,
    private stateService: StateService,
    private settingsService: SettingsService,
    @Inject(TOKEN_CONFIG) private config: AppConfig,
    @Inject(TOKEN_ANALYTICS_CONFIG) private analyticsConfig: AnalyticsConfig,
  ) {}

  public getSearchResults(searchTerm: string, selectedServiceTypes: number = null): Observable<Array<GlobalSearchResults>> {
    const url = this.config.routes.getGlobalSearchResults.url;
    const params: { search: string; serviceTypes?: number; excludeServiceTypes?: number } = { search: searchTerm };

    if (selectedServiceTypes) {
      params.serviceTypes = selectedServiceTypes;
    }

    if (!this.settingsService.isFoodServiceTypeEnabled()) {
      params.excludeServiceTypes = this.settingsService.getFoodServiceType();
    }

    return this.http.get<Array<GlobalSearchResults>>(url, { params });
  }

  public maybeInitializeTracking(newSearchTerm?: string): Promise<void> {
    const newSearchEvent = { searchTerm: newSearchTerm, vendorsOpened: [], itemsOpened: [] };

    if (!this.stateService.state?.searchEvent) {
      this.setTrackingData(newSearchEvent);
      return Promise.resolve();
    }

    if (this.stateService.state.searchEvent.searchTerm !== newSearchTerm) {
      return this.maybeSendTrackingData().then(() => this.setTrackingData(newSearchEvent));
    }

    return Promise.resolve();
  }

  public maybeSendTrackingData(): Promise<unknown> {
    if (this.stateService.state?.searchEvent?.vendorsOpened?.length || this.stateService.state?.searchEvent?.itemsOpened?.length) {
      const { searchTerm, vendorsOpened, itemsOpened } = this.stateService.state.searchEvent;

      return this.analyticsService
        .trackEvent({
          name: this.analyticsConfig.eventName.globalSearchResultsPageSearch,
          data: {
            searchTerm,
            vendorsOpened,
            itemsOpened,
            vendorsOpenedCount: vendorsOpened.length,
            itemsOpenedCount: itemsOpened.length,
          },
        })
        .then(() => this.stateService.update({ searchEvent: null }))
        .catch((error: unknown) =>
          this.loggerService.info({ component: 'SearchService', message: 'Unable to track global search event', details: { error } }),
        );
    }

    return Promise.resolve();
  }

  public updateTrackingData({ vendorId, itemId }: { vendorId?: number; itemId?: number }): Promise<void> {
    if (this.stateService.state?.searchEvent) {
      const { searchTerm, vendorsOpened, itemsOpened } = this.stateService.state.searchEvent;

      if (vendorId && !vendorsOpened.some((vendor) => vendor === vendorId)) {
        vendorsOpened.push(vendorId);
      }

      if (itemId && !itemsOpened.some((item) => item === itemId)) {
        itemsOpened.push(itemId);
      }

      this.setTrackingData({ searchTerm, vendorsOpened, itemsOpened });
    }

    return Promise.resolve();
  }

  public trackCleverTapEvents({ searchTerm, results }: { searchTerm: string; results: Array<GlobalSearchResults> }): void {
    if (!results?.length) {
      return;
    }

    const topThreeVendors: Array<{ id: number; nameEn: string; nameAr: string }> = [];
    const topThreeServiceTypes: Array<{ id: number; nameEn: string; nameAr: string }> = [];

    let vendorsCount = 0;
    let serviceTypesCount = 0;

    for (const result of results) {
      if (topThreeServiceTypes.length < 3) {
        const serviceTypeNames = JSON.parse(result.nameJson) as MultiLanguageValue;
        topThreeServiceTypes.push({
          id: result.serviceType,
          nameEn: serviceTypeNames ? Helpers.getLocalizedMultiLanguageValue(serviceTypeNames, 'en') : undefined,
          nameAr: serviceTypeNames ? Helpers.getLocalizedMultiLanguageValue(serviceTypeNames, 'ar') : undefined,
        });
      }

      for (const vendor of result.vendors) {
        if (topThreeVendors.length < 3) {
          const vendorNames = JSON.parse(vendor.nameJson) as MultiLanguageValue;
          topThreeVendors.push({
            id: vendor.vendorId,
            nameEn: Helpers.getLocalizedMultiLanguageValue(vendorNames, 'en'),
            nameAr: Helpers.getLocalizedMultiLanguageValue(vendorNames, 'ar'),
          });
        }

        vendorsCount++;
      }

      serviceTypesCount++;
    }

    this.analyticsService.trackSearchPerformedCleverTapEvent(searchTerm, {
      vendorsCount,
      serviceTypesCount,
      topVendors: topThreeVendors,
      topServiceTypes: topThreeServiceTypes,
    });

    // Save the ids in the storage so that we
    // can use them later for deep linking too
    this.stateService.update({
      topSearchedVendor: topThreeVendors.length > 0 ? topThreeVendors[0].id : null,
      topSearchedServiceType: topThreeServiceTypes?.length > 0 ? topThreeServiceTypes[0].id : null,
    });
  }

  private setTrackingData(globalSearchEvent: GlobalSearchEvent): void {
    this.stateService.update({ searchEvent: this.getTrackingData(globalSearchEvent) });
  }

  private getTrackingData({ searchTerm, vendorsOpened, itemsOpened }: GlobalSearchEvent): SearchTrackingData {
    return {
      searchTerm,
      vendorsOpened,
      itemsOpened,
      vendorsOpenedCount: vendorsOpened.length,
      itemsOpenedCount: itemsOpened.length,
    };
  }
}
