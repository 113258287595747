import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { LoadingController } from '@ionic/angular';

import { Observable, Subject } from 'rxjs';

import { Toast } from '../models/toast.model';

import { PlatformService } from './ssr/platform.service';

import {
  AreaDateTimePickerComponentParams,
  AreaDateTimePickerComponentResult,
} from '../../shared/components/area-date-time-picker/area-date-time-picker.component';

type LoadingAction = 'show' | 'hide';

interface LoadingActionTask {
  action: LoadingAction;
  resolveFn: (value: boolean | PromiseLike<boolean>) => void;
}

@Injectable({ providedIn: 'root' })
export class OverlayService {
  private preserveToastOnRouteChange = false;
  private toastQueue = new Subject<Toast>();

  private currentAlertInstance: HTMLIonAlertElement;

  private isShowingLoading = false;
  private isProcessingLoading = false;
  private loadingQueue: Array<LoadingActionTask> = [];
  private currentLoadingInstance: HTMLIonLoadingElement;

  constructor(private router: Router, private loadingCtrl: LoadingController, private platformService: PlatformService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.preserveToastOnRouteChange) {
          this.preserveToastOnRouteChange = false;
        } else {
          this.dismissToast();
        }
      }
    });
  }

  public async showLoading(): Promise<boolean> {
    return this.enqueueLoadingTask('show');
  }

  public hideLoading(): Promise<boolean> {
    return this.enqueueLoadingTask('hide');
  }

  public hideAlertMessage(): Promise<boolean> {
    return this.currentAlertInstance ? this.currentAlertInstance.dismiss() : Promise.resolve(true);
  }

  public getToast(): Observable<Toast> {
    return this.toastQueue.asObservable();
  }

  public showToast(toast: Toast): void {
    this.preserveToastOnRouteChange = toast.preserveOnRouteChange ?? false;
    this.toastQueue.next(toast);
  }

  public dismissToast(): void {
    this.toastQueue.next(null);
  }

  // This method is just an empty definition so that it can be called through
  // this service but the implementation is handled in the AppComponent
  public showAreaDateTimePicker(_params?: AreaDateTimePickerComponentParams): Promise<AreaDateTimePickerComponentResult> {
    return Promise.resolve(null as AreaDateTimePickerComponentResult);
  }

  // This method is just an empty definition so that it can be called through
  // this service but the implementation is handled in the AppComponent
  public hideAreaDateTimePicker(): void {}

  private enqueueLoadingTask(action: LoadingAction): Promise<boolean> {
    return new Promise((resolveFn) => {
      this.loadingQueue.push({ action, resolveFn });
      this.dequeueLoadingTask();
    });
  }

  private dequeueLoadingTask(): void {
    if (this.isProcessingLoading) {
      return;
    }

    const task = this.loadingQueue.shift();

    if (!task) {
      return;
    }

    this.isProcessingLoading = true;

    void this.platformService.wait(100).then(() => {
      const performTask = task.action === 'show' ? this.presentLoading() : this.dismissLoading();

      performTask
        .then(() => {
          task.resolveFn(true);
        })
        .catch(() => {
          task.resolveFn(false);
        })
        .finally(() => {
          this.isProcessingLoading = false;
          this.dequeueLoadingTask();
        });
    });
  }

  private presentLoading(): Promise<void> {
    if (this.isShowingLoading) {
      return Promise.resolve();
    }

    return this.loadingCtrl.create({ spinner: 'dots', backdropDismiss: false }).then((loadingInstance) =>
      loadingInstance.present().then(() => {
        this.isShowingLoading = true;
        this.currentLoadingInstance = loadingInstance;
      }),
    );
  }

  private dismissLoading(): Promise<void> {
    if (!this.isShowingLoading) {
      return Promise.resolve();
    }

    return this.currentLoadingInstance.dismiss().then(() => {
      this.isShowingLoading = false;
      this.currentLoadingInstance = null;
    });
  }
}
