import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { InitialSettings } from '../../models/initial-settings.model';

import { SettingsLoader } from './settings-loader';

import * as fs from 'fs';
import { join } from 'path';

@Injectable({ providedIn: 'root' })
export class SettingsServerLoader implements SettingsLoader {
  public getSettings(): Observable<InitialSettings> {
    return new Observable((observer) => {
      const settingsFilesFolder = join(process.cwd(), 'dist', 'browser', 'assets', 'config');
      const settingsFile = fs.readFileSync(`${settingsFilesFolder}/settings.json`, 'utf8');
      const jsonData = JSON.parse(settingsFile) as InitialSettings;
      observer.next(jsonData);
      observer.complete();
    });
  }
}
