import { Injectable } from '@angular/core';

import { AppEventsService } from './app-events.service';
import { DateTimeService } from './date-time.service';
import { PlatformService } from './ssr/platform.service';

@Injectable({ providedIn: 'root' })
export class BackgroundService {
  private inBackground: boolean;
  private minutesInBackground: number;
  private wentToBackgroundTimeStamp: string;

  constructor(
    private platformService: PlatformService,
    private appEventsService: AppEventsService,
    private dateTimeService: DateTimeService,
  ) {}

  public initializeBackgroundHandlers(): void {
    this.platformService.pause.subscribe(() => {
      this.appEventsService.dispatch('AppPaused');
      this.wentToBackgroundTimeStamp = this.dateTimeService.getUtcDateTimeIsoString();
      this.updateBackgroundStatus(true);
    });

    this.platformService.resume.subscribe(() => {
      this.appEventsService.dispatch('AppResumed');

      const minutesInBackground = this.dateTimeService.getElapsedTimeFromCurrentUtcTime(this.wentToBackgroundTimeStamp, 'minutes');
      console.warn(`The device was ${minutesInBackground} minutes in the background`);

      if (!this.minutesInBackground || minutesInBackground > this.minutesInBackground) {
        this.minutesInBackground = minutesInBackground;
      }

      // Wait a few seconds before updating the background status of the
      // app so that we can check if the app was opened because of a push
      // notification was received while being in the background
      void this.platformService.wait(3000).then(() => this.updateBackgroundStatus(false));
    });
  }

  public isInBackground(): boolean {
    return this.inBackground;
  }

  public getMinutesSpentInBackground(): number {
    return this.minutesInBackground ?? 0;
  }

  public resetMinutesSpentInBackground(): void {
    this.minutesInBackground = 0;
  }

  private updateBackgroundStatus(isInBackground: boolean): void {
    this.inBackground = isInBackground;
  }
}
