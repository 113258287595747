import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { LogInModalPage } from './log-in-modal.page';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, IonicModule, TranslateModule.forChild()],
  declarations: [LogInModalPage],
})
export class LogInModalPageModule {}
