import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';

import { PlatformService } from '../ssr/platform.service';

class BrowserSplashScreenProvider extends SplashScreen {
  public show(): void {}
  public hide(): void {}
}

class MobileSplashScreenProvider extends SplashScreen {}

function splashScreenFactory(platformService: PlatformService): SplashScreen {
  return platformService.areCordovaPluginsAvailable ? new MobileSplashScreenProvider() : new BrowserSplashScreenProvider();
}

export const splashScreenProvider = { provide: SplashScreen, useFactory: splashScreenFactory, deps: [PlatformService] };
