import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { merge, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { DiscountType } from '../enums/discount-type.enum';
import { LoyaltyProgramRewardType } from '../enums/loyalty-program-reward-type.enum';

import { LoyaltyProgramDetails } from '../models/loyalty-program-details.model';
import { LoyaltyProgramNextRewardDetails } from '../models/loyalty-program-next-reward-details.model';

import { AppEventsService } from './app-events.service';
import { CartService } from './cart.service';
import { LoggerService } from './logger.service';
import { SettingsService } from './settings.service';

import { AppConfig, TOKEN_CONFIG } from '../config/app.config';

import { LocalCurrencyPipe } from '../../shared/pipes/local-currency.pipe';

import { Helpers } from '../helpers';

@Injectable({ providedIn: 'root' })
export class LoyaltyProgramService {
  private localCurrencyPipe: LocalCurrencyPipe;
  private loyaltyProgramDetails: LoyaltyProgramDetails;

  constructor(
    private http: HttpClient,
    private cartService: CartService,
    private loggerService: LoggerService,
    private settingsService: SettingsService,
    private appEventsService: AppEventsService,
    private translateService: TranslateService,
    @Inject(TOKEN_CONFIG) private config: AppConfig,
  ) {
    this.localCurrencyPipe = new LocalCurrencyPipe(this.settingsService, this.config);

    merge(
      this.appEventsService.onEvent('AppResumed'),
      this.appEventsService.onEvent('ActiveOrdersChanged'),
      this.appEventsService.onEvent('UserLoginStatusChanged'),
    )
      .pipe(switchMap(() => this.loadLoyaltyProgramDetails()))
      .subscribe({
        error: (error: HttpErrorResponse) => {
          this.loggerService.info({
            component: 'LoyaltyProgramService',
            message: "couldn't update loyalty program details",
            details: { error },
          });
        },
      });
  }

  public getLoyaltyProgramDetails(): LoyaltyProgramDetails | null {
    return this.loyaltyProgramDetails || null;
  }

  public loadLoyaltyProgramDetails(): Observable<LoyaltyProgramDetails | null> {
    const url = this.config.routes.getUserLoyaltyProgramDetails.url;
    return this.http.get<LoyaltyProgramDetails>(url).pipe(
      tap((loyaltyProgramDetails) => {
        this.loyaltyProgramDetails = loyaltyProgramDetails;
      }),
      catchError(() => {
        this.loyaltyProgramDetails = null;
        return of(this.loyaltyProgramDetails);
      }),
    );
  }

  public getNextRewardDetailsForItem({ itemTotalPrice }: { itemTotalPrice: number }): LoyaltyProgramNextRewardDetails | null {
    if (!this.isNextRewardAvailable() || !this.settingsService.shouldShowLoyaltyProgramNextRewardItemFeatureEnabled()) {
      return null;
    }

    try {
      const nextReward = this.loyaltyProgramDetails.nextReward;
      const rewardType = nextReward.rewardType;
      const discountType = nextReward.discountType;

      const imageUrl = this.loyaltyProgramDetails.steps[nextReward.stepIndex].imageUrl;
      let description: string;

      // Some items may not have a price yet as it depends on the selected
      // options or add-ons
      if (discountType === DiscountType.Fixed || !!itemTotalPrice) {
        const rewardAmount =
          discountType === DiscountType.Fixed ? nextReward.amount : Helpers.multiplyWithPrecision(itemTotalPrice, nextReward.amount);

        description = this.translateService.instant(
          rewardType === LoyaltyProgramRewardType.WalletCredit
            ? 'LOYALTY_PROGRAM_NEXT_REWARD.ITEM_WALLET_CREDITS_AMOUNT'
            : 'LOYALTY_PROGRAM_NEXT_REWARD.ITEM_DISCOUNT_AMOUNT',
          { total: this.localCurrencyPipe.transform(rewardAmount) },
        ) as string;
      } else {
        description = this.translateService.instant(
          rewardType === LoyaltyProgramRewardType.WalletCredit
            ? 'LOYALTY_PROGRAM_NEXT_REWARD.ITEM_WALLET_CREDITS_PERCENTAGE'
            : 'LOYALTY_PROGRAM_NEXT_REWARD.ITEM_DISCOUNT_PERCENTAGE',
          { percentage: Helpers.multiplyWithPrecision(nextReward.amount, 100) },
        ) as string;
      }

      return imageUrl && description ? { imageUrl, description } : null;
    } catch (error) {
      this.loggerService.error({
        component: 'LoyaltyProgramService',
        message: "couldn't calculate loyalty program next reward details",
        error,
      });

      return null;
    }
  }

  public getNextRewardDetailsForCart(): LoyaltyProgramNextRewardDetails | null {
    if (!this.isNextRewardAvailable() || !this.settingsService.shouldShowLoyaltyProgramNextRewardCartFeatureEnabled()) {
      return null;
    }

    const nextReward = this.loyaltyProgramDetails.nextReward;
    const rewardType = nextReward.rewardType;
    const rewardAmount = this.getNextRewardCartAmount();

    if (!rewardAmount) {
      return null;
    }

    const imageUrl = this.loyaltyProgramDetails.steps[nextReward.stepIndex].imageUrl;
    let description: string;

    if (rewardType === LoyaltyProgramRewardType.WalletCredit) {
      description = this.translateService.instant('LOYALTY_PROGRAM_NEXT_REWARD.CART_WALLET_CREDITS', {
        total: this.localCurrencyPipe.transform(rewardAmount),
      });
    } else if (rewardType === LoyaltyProgramRewardType.Promotion) {
      description = this.translateService.instant('LOYALTY_PROGRAM_NEXT_REWARD.CART_DISCOUNT', {
        total: this.localCurrencyPipe.transform(rewardAmount),
      });
    }

    return imageUrl && description ? { imageUrl, description } : null;
  }

  public getNextRewardDetailsForCheckout(): LoyaltyProgramNextRewardDetails | null {
    if (!this.isNextRewardAvailable() || !this.settingsService.shouldShowLoyaltyProgramNextRewardCheckoutFeatureEnabled()) {
      return null;
    }

    const nextReward = this.loyaltyProgramDetails.nextReward;
    const rewardType = nextReward.rewardType;
    const rewardAmount = this.getNextRewardCartAmount();

    if (!rewardAmount) {
      return null;
    }

    const imageUrl = this.loyaltyProgramDetails.steps[nextReward.stepIndex].imageUrl;
    let description: string;

    if (rewardType === LoyaltyProgramRewardType.WalletCredit) {
      description = this.translateService.instant('LOYALTY_PROGRAM_NEXT_REWARD.CHECKOUT_WALLET_CREDITS', {
        total: this.localCurrencyPipe.transform(rewardAmount),
      });
    } else if (rewardType === LoyaltyProgramRewardType.Promotion) {
      description = this.translateService.instant('LOYALTY_PROGRAM_NEXT_REWARD.CHECKOUT_DISCOUNT', {
        total: this.localCurrencyPipe.transform(rewardAmount),
      });
    }

    return imageUrl && description ? { imageUrl, description } : null;
  }

  public getNextRewardCartAmount(): number {
    if (!this.isNextRewardAvailable()) {
      return 0;
    }

    const nextReward = this.loyaltyProgramDetails.nextReward;
    const rewardType = nextReward.rewardType;
    const discountType = nextReward.discountType;

    if (discountType === DiscountType.Fixed) {
      return nextReward.amount;
    }

    // Assuming CartTotal includes DeliveryCharges:
    // Wallet credit reward -> (CartTotal - CouponCodeAmount) * Loyalty Reward %
    // Coupon code reward   -> (CartTotal - CouponCodeAmount - DeliveryCharges) * loyalty Reward %

    const cartTotal = this.cartService.getCartTotalPrice();
    const promoCodeAmount = this.cartService.getSelectedPromotion()?.cartDiscount || 0;
    const deliveryChargesAmount = this.cartService.getCartTotalDeliveryCharges() || 0;

    if (rewardType === LoyaltyProgramRewardType.WalletCredit) {
      const subtotal = Helpers.subtractWithPrecision(cartTotal, promoCodeAmount);
      return Helpers.multiplyWithPrecision(subtotal, nextReward.amount);
    }

    if (rewardType === LoyaltyProgramRewardType.Promotion) {
      const subtotal = Helpers.subtractWithPrecision(cartTotal, promoCodeAmount, deliveryChargesAmount);
      return Helpers.multiplyWithPrecision(subtotal, nextReward.amount);
    }

    return 0;
  }

  public isNextRewardAvailable(): boolean {
    return !!(
      this.loyaltyProgramDetails &&
      this.loyaltyProgramDetails.nextReward &&
      this.loyaltyProgramDetails.nextReward.rewardType !== LoyaltyProgramRewardType.None
    );
  }
}
