import { Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { mdTransitionAnimation } from '@ionic/angular';

import { Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { Area } from '../../../core/models/area.model';
import { Country } from '../../../core/models/country.model';
import { GooglePlacesGeocodedResult } from '../../../core/models/google-places-geocoded-result.model';
import { LatLng } from '../../../core/models/latlng.model';

import { AppEventsService } from '../../../core/services/app-events.service';
import { AreasService } from '../../../core/services/areas.service';
import { CartService } from '../../../core/services/cart.service';
import { GeolocationService } from '../../../core/services/geolocation.service';
import { LoggerService } from '../../../core/services/logger.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { OverlayService } from '../../../core/services/overlay.service';
import { SettingsService } from '../../../core/services/settings.service';
import { PlatformService } from '../../../core/services/ssr/platform.service';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GoogleMapsComponent implements OnInit, OnDestroy {
  @ViewChild('map', { read: ElementRef })
  public mapElement: ElementRef;

  @ViewChild('resetmap', { read: ElementRef })
  public resetMapElement: ElementRef<HTMLElement>;

  @Input() public lat: number;
  @Input() public lng: number;
  @Input() public area: Area;
  @Input() public showMapTypeControl = true;
  @Input() public showUserLocationInMap = true;
  @Input() public showHomeIcon = false;

  @Output() public mapError = new EventEmitter<void>();
  @Output() public locationUpdated = new EventEmitter<{ latitude: number; longitude: number }>();

  // Default location in case we can't reverse geocode
  // the selected address and if we can't get the user
  // location either
  private defaulLocation: LatLng;

  private startPosition: google.maps.LatLng;
  private userPosition: google.maps.LatLng;
  private currentPosition: google.maps.LatLng;
  private map: google.maps.Map;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private ngZone: NgZone,
    private navigationService: NavigationService,
    private overlayService: OverlayService,
    private areasService: AreasService,
    private settingsService: SettingsService,
    private platformService: PlatformService,
    private geolocationService: GeolocationService,
    private cartService: CartService,
    private translateService: TranslateService,
    private loggerService: LoggerService,
    private appEventsService: AppEventsService,
  ) {}

  ngOnInit() {
    this.initializeComponent();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  public getGeocodedData(): Observable<GooglePlacesGeocodedResult> {
    const lat = this.currentPosition?.lat();
    const lng = this.currentPosition?.lng();

    const geocodedResult = { lat, lng } as GooglePlacesGeocodedResult;

    return this.areasService.getAddressByCoordinates(lat, lng).pipe(
      takeUntil(this.unsubscribe$),
      map((searchedAddress) => {
        if (searchedAddress.area) {
          const currentCountry = this.settingsService.getCountry();

          if (searchedAddress.area.country === currentCountry.value) {
            geocodedResult.areaModel = searchedAddress.area;

            if (this.settingsService.isUseAddressGeoCodeResultsFeatureEnabled()) {
              geocodedResult.block = searchedAddress.address?.block ?? null;
              geocodedResult.building = searchedAddress.address?.buildingNumber ?? null;
              geocodedResult.street = searchedAddress.address?.street ?? null;
            }

            return geocodedResult;
          } else {
            this.showAreaFromDifferentCountryErrorMessage(currentCountry);
            return null;
          }
        } else {
          // We couldn't get the area from the API so we just return the geocoded result
          return geocodedResult;
        }
      }),
      catchError((error: unknown) => {
        this.loggerService.info({ component: 'GoogleMapsComponent', message: "couldn't get areas by coordinates", details: { error } });
        return of(geocodedResult);
      }),
    );
  }

  public onMapReset(): void {
    if (this.map) {
      this.map.setCenter(this.startPosition);
    }
  }

  public onOpenAutocomplete(): void {
    void this.navigationService.navigateTo('/google-maps-autocomplete', false, {
      animation: mdTransitionAnimation,
    });
  }

  private initializeComponent(): void {
    const currentCountry = this.settingsService.getCountry();

    this.defaulLocation = {
      lat: currentCountry.geography.latitude,
      lng: currentCountry.geography.longitude,
    };

    const updateLocation = (location: LatLng) => {
      this.ngZone.run(() => {
        if (location) {
          this.currentPosition = new google.maps.LatLng(location.lat, location.lng);
          this.emitCurrentLocation();

          if (this.map) {
            this.map.setCenter(this.currentPosition);
          }
        } else {
          this.overlayService.showToast({
            type: 'error',
            message: this.translateService.instant('ERROR_MESSAGE.DEFAULT_MESSAGE') as string,
            showCloseButton: true,
          });
        }
      });
    };

    this.appEventsService
      .onEvent('GoogleMapUpdateLocation')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((location: LatLng) => updateLocation(location));

    this.geolocationService
      .initializeGooglePlacesSDK()
      .then(() => {
        this.initializeLocations()
          .then(() => this.initMap())
          .catch((error: unknown) => {
            this.loggerService.info({ component: 'GoogleMapsComponent', message: "couldn't initialize locations", details: { error } });

            this.userPosition = null;

            // Use the default location if get some errors when trying to get the real locations
            this.startPosition = new google.maps.LatLng(this.defaulLocation.lat, this.defaulLocation.lng);

            // Initialize the current position just in case if the
            // user doesn't move the map and selects the address
            // as it is when loading the page
            this.currentPosition = this.startPosition;
            this.emitCurrentLocation();

            this.initMap();
          });
      })
      .catch((error: unknown) => {
        this.loggerService.info({ component: 'GoogleMapsComponent', message: "couldn't initialize Google Places SDK", details: { error } });
        this.mapError.next();
      });
  }

  private initMap(): void {
    const options = {
      center: this.startPosition,
      zoom: 15,
      scrollwheel: true,
      mapTypeControl: this.showMapTypeControl,
      streetViewControl: false,
      fullscreenControl: false,
      zoomControl: false,
      clickableIcons: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement as HTMLElement, options);

    const markerImage = {
      url: 'https://cdn.bilbayt.com/assets/gps-636734596734917517.png',
      size: null,
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(25, 25),
    };

    if (this.userPosition) {
      new google.maps.Marker({
        position: this.userPosition,
        icon: markerImage,
        map: this.map,
      });
    }

    if (this.resetMapElement?.nativeElement) {
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.resetMapElement.nativeElement);
    }

    google.maps.event.addListener(this.map, 'dragend', () => {
      this.currentPosition = this.map.getCenter();
      this.emitCurrentLocation();
    });
  }

  // Method that gets the initial location of the marker and the
  // current location of the user and shows them in the map
  private initializeLocations(): Promise<void> {
    const maybeGetUserLocation = this.showUserLocationInMap
      ? this.geolocationService.getCurrentPosition().then((result) => {
          if (!result.coordinates && !this.platformService.isBrowser) {
            this.overlayService.showToast({ message: result.errorMessage, type: 'error', showCloseButton: true });
          }

          return result.coordinates;
        })
      : Promise.resolve(null);

    return maybeGetUserLocation.then((userPositionResult: LatLng) =>
      this.getInitialPosition(userPositionResult).then((initialPositionResult) => {
        this.userPosition = userPositionResult ? new google.maps.LatLng(userPositionResult.lat, userPositionResult.lng) : null;

        this.startPosition = new google.maps.LatLng(initialPositionResult.lat, initialPositionResult.lng);

        // Initialize the current position just in case if the
        // user doesn't move the map and selects the address
        // as it is when loading the page
        this.currentPosition = this.startPosition;
        this.emitCurrentLocation();
      }),
    );
  }

  // Method that returns the initial position to be used for the marker.
  // That position can be the coordinates of the saved address, the coordinates
  // of the area, or jsut the default location of the selected country
  private getInitialPosition(userPositionResult?: LatLng): Promise<LatLng> {
    // Maybe the component didn't get the area but if the
    // user already selected an area we can use it instead
    const area = this.area || this.cartService.getArea();

    if (this.lat && this.lng) {
      // We already have the coordinates so that should be
      // the initial position
      return Promise.resolve({
        lat: this.lat,
        lng: this.lng,
      });
    } else if (area) {
      // We don't have the coordinates but we have the area so we
      // can try to find the coordinates of the area

      const searchParams = {
        address: this.getAreaAndCountryString(area),
      };

      return this.geolocationService
        .geocode(searchParams)
        .then((result) => ({
          lat: result.lat,
          lng: result.lng,
        }))
        .catch((error: unknown) => {
          this.loggerService.info({ component: 'GoogleMapsComponent', message: "couldn't geocode address", details: { error } });
          return userPositionResult ? userPositionResult : this.defaulLocation;
        });
    } else {
      // We don't have any information about the coordinates or
      // the area so we can just use the user location if available
      // or the default location of the selected country
      return Promise.resolve(userPositionResult ? userPositionResult : this.defaulLocation);
    }
  }

  // Method that returns a string with the name of the area and the name of the country
  private getAreaAndCountryString(area: Area): string {
    const country = this.settingsService.getAvailableCountries().find((c) => c.value === area.country);
    const countryName = this.translateService.instant(country.name) as string;

    return `${area.fullName}, ${countryName}`;
  }

  // Method that shows an error toast message because the user selected a
  // location on the map that is outside of the current country
  private showAreaFromDifferentCountryErrorMessage(currentCountry: Country): void {
    const message = this.translateService.instant('MAPS.AREA_FROM_DIFFERENT_COUNTRY', {
      countryName: this.translateService.instant(currentCountry.name) as string,
    }) as string;

    this.overlayService.showToast({ type: 'error', message });
  }

  private emitCurrentLocation(): void {
    const latitude = this.currentPosition?.lat();
    const longitude = this.currentPosition?.lng();

    if (latitude && longitude) {
      this.locationUpdated.next({ latitude, longitude });
    }
  }
}
