<div class="rating-container">
  <span class="empty-stars-container">
    <ng-container *ngFor="let emptyStarIndex of [1, 2, 3, 4, 5]">
      <ion-icon
        tappable
        class="star visible"
        src="/assets/custom-icons/rating-star-empty.svg"
        (click)="handleStarTap(emptyStarIndex)"
      ></ion-icon>
    </ng-container>
  </span>
  <span class="full-stars-container">
    <ng-container *ngFor="let fullStarIndex of [1, 2, 3, 4, 5]">
      <ion-icon
        tappable
        class="star"
        [class.visible]="rating >= fullStarIndex"
        src="/assets/custom-icons/rating-star-full.svg"
        (click)="handleStarTap(fullStarIndex)"
      ></ion-icon>
    </ng-container>
  </span>
</div>
