import { animate, AnimationAnimateMetadata, AnimationStaggerMetadata, AnimationStyleMetadata, stagger, style } from '@angular/animations';

import { getAnimationString } from './helpers';

import { AnimationDetails, ScalableAnimationDetails, StaggerableAnimationDetails, TranslatableAnimationDetails } from './models';

declare type AnimationMetaData = AnimationStyleMetadata | AnimationAnimateMetadata | AnimationStaggerMetadata;

export function applyFadeInAnimation(animationDetails: AnimationDetails): Array<AnimationMetaData> {
  return [style({ opacity: 0 }), animate(getAnimationString(animationDetails), style({ opacity: 1 }))];
}

export function applyFadeOutAnimation(animationDetails: AnimationDetails): Array<AnimationMetaData> {
  return [animate(getAnimationString(animationDetails), style({ opacity: 0 }))];
}

export function applyFadeInTranslateAnimation(animationDetails: AnimationDetails & TranslatableAnimationDetails): Array<AnimationMetaData> {
  return [
    style({
      opacity: 0,
      transform: `translate${animationDetails.axis || 'Y'}(${animationDetails.distance})`,
    }),
    animate(getAnimationString(animationDetails), style({ opacity: 1, transform: 'none' })),
  ];
}

export function applyFadeOutTranslateAnimation(
  animationDetails: AnimationDetails & TranslatableAnimationDetails,
): Array<AnimationMetaData> {
  return [
    animate(
      getAnimationString(animationDetails),
      style({
        opacity: 0,
        transform: `translate${animationDetails.axis || 'Y'}(${animationDetails.distance})`,
      }),
    ),
  ];
}

export function applyFadeInTranslateStaggerAnimation(
  animationDetails: AnimationDetails & TranslatableAnimationDetails & StaggerableAnimationDetails,
): Array<AnimationMetaData> {
  return [
    style({ opacity: 0, transform: `translate${animationDetails.axis || 'Y'}(${animationDetails.distance})` }),
    stagger(animationDetails.stagger, [animate(getAnimationString(animationDetails), style({ opacity: 1, transform: 'none' }))]),
  ];
}

export function applyFadeInExpandHeightAnimation(animationDetails: AnimationDetails): Array<AnimationMetaData> {
  return [
    style({
      opacity: 0,
      height: 0,
      'padding-top': 0,
      'padding-bottom': 0,
      'margin-top': 0,
      'margin-bottom': 0,
    }),
    animate(getAnimationString(animationDetails)),
  ];
}

export function applyFadeOutCollapseHeightAnimation(animationDetails: AnimationDetails): Array<AnimationMetaData> {
  return [
    style({
      opacity: 1,
      height: '*',
      'padding-top': '*',
      'padding-bottom': '*',
      'margin-top': '*',
      'margin-bottom': '*',
    }),
    animate(
      getAnimationString(animationDetails),
      style({
        opacity: 0,
        height: 0,
        'padding-top': 0,
        'padding-bottom': 0,
        'margin-top': 0,
        'margin-bottom': 0,
      }),
    ),
  ];
}

export function applyFadeInExpandWidthAnimation(animationDetails: AnimationDetails): Array<AnimationMetaData> {
  return [
    style({
      opacity: 0,
      width: 0,
      'padding-left': 0,
      'padding-right': 0,
      'margin-left': 0,
      'margin-right': 0,
    }),
    animate(getAnimationString(animationDetails)),
  ];
}

export function applyFadeOutCollapseWidthAnimation(animationDetails: AnimationDetails): Array<AnimationMetaData> {
  return [
    style({
      opacity: 1,
      width: '*',
      'padding-left': '*',
      'padding-right': '*',
      'margin-left': '*',
      'margin-right': '*',
    }),
    animate(
      getAnimationString(animationDetails),
      style({
        opacity: 0,
        width: 0,
        'padding-left': 0,
        'padding-right': 0,
        'margin-left': 0,
        'margin-right': 0,
      }),
    ),
  ];
}

export function applyFadeInExpandHeightPaddingMarginAnimation(animationDetails: AnimationDetails): Array<AnimationMetaData> {
  return [style({ opacity: 0, height: 0, margin: 0, padding: 0 }), animate(getAnimationString(animationDetails))];
}

export function applyFadeOutCollapseHeightPaddingMarginAnimation(animationDetails: AnimationDetails): Array<AnimationMetaData> {
  return [
    style({ opacity: 1, height: '*', margin: '*', padding: '*' }),
    animate(getAnimationString(animationDetails), style({ opacity: 0, height: 0, padding: 0, margin: 0 })),
  ];
}

export function applyFadeInScaleAnimation(animationDetails: AnimationDetails & ScalableAnimationDetails): Array<AnimationMetaData> {
  return [
    style({
      opacity: 0,
      transform: `scale${animationDetails.axis || ''}(${animationDetails.startingScale || 0})`,
    }),
    animate(
      getAnimationString(animationDetails),
      style({ opacity: 1, transform: `scale${animationDetails.axis || ''}(${animationDetails.endingScale || 1})` }),
    ),
  ];
}

export function applyFadeOutScaleAnimation(animationDetails: AnimationDetails & ScalableAnimationDetails): Array<AnimationMetaData> {
  return [
    animate(
      getAnimationString(animationDetails),
      style({
        opacity: 0,
        transform: `scale${animationDetails.axis || ''}(${animationDetails.endingScale || 0})`,
      }),
    ),
  ];
}

export function applyScaleInAnimation(animationDetails: AnimationDetails & ScalableAnimationDetails): Array<AnimationMetaData> {
  return [
    style({ transform: `scale${animationDetails.axis || ''}(${animationDetails.startingScale || 0})` }),
    animate(getAnimationString(animationDetails), style({ transform: `scaleY(${animationDetails.endingScale || 1})` })),
  ];
}

export function applyScaleOutAnimation(animationDetails: AnimationDetails & ScalableAnimationDetails): Array<AnimationMetaData> {
  return [
    animate(
      getAnimationString(animationDetails),
      style({ transform: `scale${animationDetails.axis || ''}(${animationDetails.endingScale || 0})` }),
    ),
  ];
}
