<ng-template #socialLoginModalContent>
  <div class="social-login-title" [class.with-message]="!!message">{{ "SOCIAL_LOGIN.TITLE" | translate }}</div>
  <div *ngIf="message" class="social-login-message" data-cy="social-login-message">{{ message }}</div>

  <ion-button expand="block" class="social-login-button" data-cy="email-sign-in-button" color="white" (click)="onEmailSignIn()">
    <ion-icon class="social-login-button-icon email-icon" src="/assets/custom-icons/social-login/email-sign-in.svg"></ion-icon>
    <div class="social-login-button-text">{{ "SOCIAL_LOGIN.CONTINUE_WITH_EMAIL" | translate }}</div>
  </ion-button>

  <ion-button
    *ngIf="isIos"
    color="white"
    expand="block"
    class="social-login-button"
    data-cy="apple-sign-in-button"
    (click)="onSocialSignIn('apple')"
  >
    <ion-icon class="social-login-button-icon apple-icon" src="/assets/custom-icons/social-login/apple-sign-in.svg"></ion-icon>
    <div class="social-login-button-text">{{ "SOCIAL_LOGIN.CONTINUE_WITH_APPLE" | translate }}</div>
  </ion-button>

  <ion-button color="white" expand="block" class="social-login-button" data-cy="google-sign-in-button" (click)="onSocialSignIn('google')">
    <ion-icon class="social-login-button-icon google-icon" src="/assets/custom-icons/social-login/google-sign-in.svg"></ion-icon>
    <div class="social-login-button-text">{{ "SOCIAL_LOGIN.CONTINUE_WITH_GOOGLE" | translate }}</div>
  </ion-button>
</ng-template>

<div *ngIf="isBrowser" class="inner-content">
  <ng-template [ngTemplateOutlet]="socialLoginModalContent"></ng-template>
</div>

<ion-content *ngIf="!isBrowser">
  <ng-template [ngTemplateOutlet]="socialLoginModalContent"></ng-template>
</ion-content>
