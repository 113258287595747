import { Market } from '@awesome-cordova-plugins/market/ngx';

import { PlatformService } from '../ssr/platform.service';

class BrowserMarketProvider extends Market {
  public open(_appId: string): Promise<unknown> {
    return Promise.resolve(true);
  }
}

class MobileMarketProvider extends Market {}

function marketFactory(platformService: PlatformService): Market {
  return platformService.areCordovaPluginsAvailable ? new MobileMarketProvider() : new BrowserMarketProvider();
}

export const marketProvider = { provide: Market, useFactory: marketFactory, deps: [PlatformService] };
