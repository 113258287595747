import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { CartService } from './cart.service';
import { DateTimeService } from './date-time.service';

@Injectable({ providedIn: 'root' })
export class NoticePeriodService {
  constructor(private dateTimeService: DateTimeService, private cartService: CartService, private translateService: TranslateService) {}

  public getNoticePeriodWarningMessage({ noticePeriod, dateTime: dateTime }: { noticePeriod: number; dateTime: string }): string {
    const canShowNoticePeriod = this.canShowNoticePeriod({ noticePeriod, dateTime });

    if (!canShowNoticePeriod) {
      return null;
    }

    return noticePeriod >= 2
      ? (this.translateService.instant('NOTICE_PERIOD_HOURS', { noticePeriod }) as string)
      : (this.translateService.instant('NOTICE_PERIOD_MINUTES', { noticePeriod: noticePeriod * 60 }) as string);
  }

  public getNoticePeriodWarningMessageForGroceryItem({ noticePeriod, dateTime }: { noticePeriod: number; dateTime: string }): string {
    const canShowNoticePeriod = this.canShowNoticePeriod({ noticePeriod, dateTime });

    if (!canShowNoticePeriod) {
      return null;
    }

    return noticePeriod === 1
      ? (this.translateService.instant('NOTICE_PERIOD_HOUR', { noticePeriod }) as string)
      : (this.translateService.instant('NOTICE_PERIOD_HOURS', { noticePeriod }) as string);
  }

  public getNoticePeriodWarningForCart({ noticePeriod, dateTime }: { dateTime: string; noticePeriod: number }): string {
    const canShowNoticePeriod = this.canShowNoticePeriod({ noticePeriod, dateTime });

    if (!canShowNoticePeriod) {
      return null;
    }

    return this.translateService.instant('NOTICE_PERIOD_H', { noticePeriod }) as string;
  }

  private canShowNoticePeriod({ dateTime, noticePeriod }: { dateTime: string; noticePeriod: number }): boolean {
    if (!dateTime || noticePeriod === null || noticePeriod === undefined) {
      return false;
    }

    const bufferMinutes = noticePeriod * 60 + this.cartService.getAddToCartBufferTimeInMinutes();
    const isSelectedDateTimeValid = this.dateTimeService.isLocalTimeWithBufferSameOrBefore({ dateTime, bufferMinutes });

    return !isSelectedDateTimeValid;
  }
}
