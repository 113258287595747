import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { EditWishlistModalPage } from './edit-wishlist-modal.page';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, IonicModule, TranslateModule.forChild()],
  declarations: [EditWishlistModalPage],
})
export class EditWishlistModalPageModule {}
