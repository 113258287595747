import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

type AppEvent =
  | 'AppPaused'
  | 'AppResumed'
  | 'UserLoginStatusChanged'
  | 'AuthTokensAreNotValid'
  | 'GoogleMapUpdateLocation'
  | 'ServiceUnavailable'
  | 'RedirectToErrorPage'
  | 'OrderPlaced'
  | 'OrderPaid'
  | 'EnteredToHomePage'
  | 'ActiveOrdersChanged';

interface EventDetails {
  type: AppEvent;
  payload?: unknown;
}

@Injectable({ providedIn: 'root' })
export class AppEventsService {
  private eventsDispatcher$ = new Subject<EventDetails>();

  public dispatch(type: AppEvent, payload?: unknown): void {
    this.eventsDispatcher$.next({ type, payload });
  }

  public onEvent(type: AppEvent): Observable<unknown> {
    return this.eventsDispatcher$.pipe(
      filter((eventDetails) => eventDetails.type === type),
      map((eventDetails) => eventDetails.payload),
    );
  }
}
