import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { shouldSkipHttpInterceptors } from '../config/app.config';

@Injectable()
export class SearchOptionsInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (shouldSkipHttpInterceptors(request.url)) {
      return next.handle(request);
    }

    const values = { skipServiceTimeCheckForBilbaytNow: true };

    request = request.clone({ headers: request.headers.set('X-Search-Options', JSON.stringify(values)) });

    return next.handle(request);
  }
}
