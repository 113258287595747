import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { GoogleMapsOrderTrackingComponent } from './google-maps-order-tracking.component';

@NgModule({
  declarations: [GoogleMapsOrderTrackingComponent],
  imports: [CommonModule, IonicModule, TranslateModule.forChild()],
  exports: [GoogleMapsOrderTrackingComponent],
})
export class GoogleMapsOrderTrackingComponentModule {}
