import { Injectable } from '@angular/core';

import { BehaviorSubject, interval } from 'rxjs';

import { DateTimeService } from './date-time.service';
import { PlatformService } from './ssr/platform.service';

const LOCAL_DATE_TIME_POLLING_INTERVAL = 5 * 60 * 1000; // 5 minutes

@Injectable({ providedIn: 'root' })
export class DeviceLocalTimeService {
  public onLocalTimeChange$: BehaviorSubject<string>;

  constructor(private platformService: PlatformService, private dateTimeService: DateTimeService) {}

  public initializeLocalTimeIntervalUpdates(intervalInMs: number = LOCAL_DATE_TIME_POLLING_INTERVAL): void {
    this.onLocalTimeChange$ = new BehaviorSubject<string>(this.dateTimeService.getLocalDateTimeIsoString());

    // Interval and timer operators doesn't work in SSR
    // https://stackoverflow.com/a/57456495/3915438
    if (!this.platformService.isServer) {
      interval(intervalInMs).subscribe(() => this.onLocalTimeChange$.next(this.dateTimeService.getLocalDateTimeIsoString()));
    }

    this.platformService.resume.subscribe(() => {
      this.onLocalTimeChange$.next(this.dateTimeService.getLocalDateTimeIsoString());
    });
  }
}
