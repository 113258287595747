import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';

import { AnalyticsService } from '../services/analytics.service';
import { AppService } from '../services/app.service';
import { CartService } from '../services/cart.service';
import { SettingsService } from '../services/settings.service';

import { TOKEN_ANALYTICS_CONFIG } from '../config/analytics.config';
import { shouldSkipHttpInterceptors } from '../config/app.config';

@Injectable()
export class AnalyticsInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (shouldSkipHttpInterceptors(request.url)) {
      return next.handle(request);
    }

    const appService = this.injector.get(AppService);
    const cartService = this.injector.get(CartService);
    const settingsService = this.injector.get(SettingsService);
    const analyticsService = this.injector.get(AnalyticsService);
    const analyticsServiceConfig = this.injector.get(TOKEN_ANALYTICS_CONFIG);

    const source = analyticsServiceConfig.source;
    const appVersion = appService.getAppVersion();
    const distinctId = analyticsService.getCurrentDistinctId();

    request = request.clone({ headers: request.headers.set('X-Source', source) });
    request = request.clone({ headers: request.headers.set('X-Version', appVersion) });
    request = request.clone({ headers: request.headers.set('X-Distinct-Id', distinctId) });

    const testGroups = settingsService.getTestGroups();

    if (testGroups?.length) {
      testGroups.forEach((testGroup) => {
        const name = this.getHeaderNameByTestGroupId(testGroup.id);
        const value = testGroup.value;

        request = request.clone({ headers: request.headers.set(name, value) });
      });
    }

    const { searchId, sorterTypeText } = cartService.getCurrentOrderSearchMetadata();

    if (searchId) {
      request = request.clone({ headers: request.headers.set('X-Search-Id', searchId) });
    }

    // Currently there's an a/b test experiment named "vendorSorter" which adds the
    // X-Vendor-Sorter header. But if later that experiment is finished/removed, we
    // may still want to send the X-Vendor-Sorter header in every HTTP request just
    // like we do with the searchId
    if (sorterTypeText) {
      request = request.clone({ headers: request.headers.set('X-Vendor-Sorter', sorterTypeText) });
    }

    return next.handle(request);
  }

  private getHeaderNameByTestGroupId(testGroupId: string): string {
    const headerName = `X${testGroupId.charAt(0).toUpperCase()}${testGroupId.slice(1)}`.split(/(?=[A-Z])/).join('-');

    return headerName;
  }
}
