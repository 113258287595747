import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { PickerComponent } from './picker.component';

import { PickerColumnComponentModule } from './picker-column/picker-column.module';

@NgModule({
  declarations: [PickerComponent],
  imports: [CommonModule, IonicModule, PickerColumnComponentModule],
  exports: [PickerComponent],
})
export class PickerComponentModule {}
