import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { PickerColumnComponent } from './picker-column.component';

@NgModule({
  declarations: [PickerColumnComponent],
  imports: [CommonModule, IonicModule],
  exports: [PickerColumnComponent],
})
export class PickerColumnComponentModule {}
