import { APP_INITIALIZER, Injectable, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';

import { Subscription } from 'rxjs';

import { AppState } from '../models/app-state.model';

import { WindowService } from '../services/ssr/window.service';
import { INITIAL_STATE, StateService } from '../services/state.service';

import { Helpers } from '../helpers';

import { environment } from 'src/environments/environment';

export const DEVTOOLS_OPTIONS = new InjectionToken<DevtoolsOptions>('DevtoolsOptions');

let currentActionId = 0;

const DEVTOOLS_PROP_NAME = '__REDUX_DEVTOOLS_EXTENSION__';

declare global {
  interface Window {
    [DEVTOOLS_PROP_NAME]: {
      connect: (params: object) => {
        init: (state: AppState) => void;
        send: (action: { type: string; payload: unknown }, state: AppState) => void;
        subscribe: (event: unknown) => Subscription;
      };
    };
  }
}

/**
 * For more details please visit:
 * https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
 */
export type DevtoolsOptions = {
  name: string;
  latency: number;
  maxAge: number;
  actionsBlacklist: string[];
  actionsWhitelist: string[];
  predicate: (state: unknown, action: unknown) => boolean;
};

// Array to keep the subscriptions related to the devtools so that
// we can clean everything up when needed
const subs: Array<Subscription> = [];

/**
 * Handles the communication between the current state of the app and the
 * devtools extension plugin.
 * @param ngZone Angular NgZone instance
 * @param options Devtools options
 */
export function devtoolsConnector(stateService: StateService, windowService: WindowService): void {
  if (!windowService.isWindowDefined) {
    return;
  }

  if (!windowService.window[DEVTOOLS_PROP_NAME]) {
    return;
  }

  if (subs.length) {
    subs.forEach((s) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
  }

  const devTools = windowService.window[DEVTOOLS_PROP_NAME].connect({
    name: 'Store',
    maxAge: 20,
    pause: false, // start/pause recording of dispatched actions
    lock: false, // lock/unlock dispatching actions and side effects
    persist: false, // persist states on page reloading
    export: true, // export history of actions in a file
    import: 'custom', // import history of actions from a file
    jump: true, // jump back and forth (time travelling)
    skip: false, // skip (cancel) actions
    reorder: false, // drag and drop actions in the history list
    dispatch: false, // dispatch custom actions or action creators
    test: false, // generate tests for the selected actions
  });

  subs.push(
    stateService.stateChangesLog$.subscribe(({ payload, nextState }) => {
      if (Helpers.areEqual(nextState, INITIAL_STATE)) {
        devTools.init(nextState);
        return;
      }

      devTools.send({ type: `Change #${++currentActionId}`, payload }, nextState);
    }),
  );

  subs.push(
    devTools.subscribe((message: { type: string; payload: { type: unknown }; state: string }) => {
      if (message.type === 'DISPATCH') {
        const payloadType = message.payload.type;
        // const rootState = message.state ? (JSON.parse(message.state) as AppState) : {};

        if (payloadType === 'COMMIT') {
          console.warn('COMMIT is not implemented yet.');
          return;
        }

        if (payloadType === 'JUMP_TO_ACTION' || payloadType === 'JUMP_TO_STATE') {
          console.warn('JUMP_TO_ACTION is not implemented yet.');
          return;
        }

        if (payloadType === 'TOGGLE_ACTION') {
          console.warn('TOGGLE_ACTION is not implemented yet.');
          return;
        }
      }
    }),
  );
}

@Injectable({ providedIn: 'root' })
export class Devtools {
  constructor(private stateService: StateService, private windowService: WindowService) {}

  public initialize(): void {
    devtoolsConnector(this.stateService, this.windowService);
  }
}

export function initApp(devtools: Devtools) {
  return (): void => {
    if (!environment.production) {
      devtools.initialize();
    }
  };
}

@NgModule({})
export class DevtoolsModule {
  public static forRoot(): ModuleWithProviders<DevtoolsModule> {
    return {
      ngModule: DevtoolsModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: initApp,
          deps: [Devtools],
          multi: true,
        },
      ],
    };
  }
}
