import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';

import { COUNTRY, CountryCode } from '../models/country.model';
import { LANGUAGE, LanguageCode } from '../models/language.model';

import { SettingsService } from '../services/settings.service';

import { shouldSkipHttpInterceptors } from '../config/app.config';

const DEFAULT_LANG: LanguageCode = LANGUAGE.english.code;
const DEFAULT_COUNTRY: CountryCode = COUNTRY.kuwait.code;

@Injectable()
export class LangInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (shouldSkipHttpInterceptors(request.url)) {
      return next.handle(request);
    }

    const settingsService = this.injector.get(SettingsService);
    const language = settingsService.getLanguage();
    const country = settingsService.getCountry();

    // We use DEFAULT_LANG and DEFAULT_COUNTRY because as of CA-728 we need to get the
    // default country based on the user IP address so we need to make at least one HTTP
    // call BEFORE actually knowing/initializing the default country and language.
    request = request.clone({
      headers: request.headers.set(
        'Accept-Language',
        `${language ? language.value : DEFAULT_LANG}-${country ? country.code : DEFAULT_COUNTRY}`,
      ),
    });

    return next.handle(request);
  }
}
