export enum AnimationState {
  Void = 'void',
  Any = '*',
  Hidden = 'hidden',
  Visible = 'visible',
  Collapsed = 'collapsed',
  Expanded = 'expanded',
}

export const enum AnimationTransition {
  Enter = ':enter',
  Leave = ':leave',
  AnyToAny = '* => *',
  VoidToAny = 'void => *',
  AnyToVoid = '* => void',
  HiddenToVisible = 'hidden => visible',
  VisibleToHidden = 'visible => hidden',
  CollapsedToExpanded = 'collapsed => expanded',
  ExpandedToCollapsed = 'expanded => collapsed',
}

export const enum AnimationTimingFunction {
  Linear = 'linear',
  Ease = 'ease',
  EaseIn = 'ease-in',
  EaseOut = 'ease-out',
  EaseInOut = 'ease-in-out',
  CubicBezier = 'cubic-bezier(0.36,0.66,0.04,1)',
}

export interface AnimationDetails {
  duration: number;
  delay?: number;
  timingFunction?: AnimationTimingFunction;
}

export interface TranslatableAnimationDetails extends AnimationDetails {
  distance: string;
  axis?: 'X' | 'Y';
}

export interface ScalableAnimationDetails extends AnimationDetails {
  startingScale?: string;
  endingScale?: string;
  axis?: 'X' | 'Y';
}

export interface StaggerableAnimationDetails extends AnimationDetails {
  stagger: number;
}
