import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { NewOrderAddress } from '../models/address-details.model';
import { Address } from '../models/address.model';
import { Area } from '../models/area.model';
import { Country, CountryCode } from '../models/country.model';
import { Geography } from '../models/geography.model';
import { GooglePlacesGeocodedResult } from '../models/google-places-geocoded-result.model';
import { OrderCustomerDetails } from '../models/order-customer-details.model';

import { Helpers } from '../helpers';

@Injectable({ providedIn: 'root' })
export class AddressService {
  constructor(private translateService: TranslateService) {}

  public isNew(address: Address): boolean {
    return !address?.savedAddressId;
  }

  public hasLocationCoordinates(address: Address): boolean {
    return !!address?.geography?.latitude && !!address?.geography?.longitude;
  }

  public hasLocationChanged(currentAddress: Address, newAddress: Address): boolean {
    return this.hasAreaChanged(currentAddress, newAddress) || this.hasAddressChanged(currentAddress, newAddress);
  }

  public hasAreaChanged(currentAddress: Address, newAddress: Address): boolean {
    if (!currentAddress && !newAddress) {
      return false;
    }

    return !!(
      (!currentAddress?.area && newAddress?.area) ||
      (currentAddress?.area && !newAddress?.area) ||
      currentAddress?.area.areaId !== newAddress?.area.areaId
    );
  }

  public createNewAddressFromArea(area: Area): Address {
    return this.updateAreaFromAddress(this.createNewEmptyAddress(), area);
  }

  public createNewAddressFromOrderCustomerDetailsAddress(address: OrderCustomerDetails): Address {
    return {
      name: '',
      area: {
        name: address.area,
      },
      address: {
        block: address.block,
        avenue: address.avenue,
        street: address.street,
        building: address.building,
        floor: address.floor,
        apartment: address.apartment,
        neighborhood: address.neighborhood,
        lane: address.lane,
      },
      phoneNumber: {
        countryCode: '',
        localNumber: address.phoneNumber,
      },
    } as Address;
  }

  public createNewAddressFromCoordinates({
    area,
    address,
    latitude,
    longitude,
  }: {
    address?: Address;
    area?: Area;
    latitude: number;
    longitude: number;
  }): Address {
    const newAddress = address ? Helpers.clone(address) : this.createNewAddressFromArea(area);

    if (!latitude || !longitude) {
      return newAddress;
    }

    return {
      ...newAddress,
      geography: { latitude, longitude },
      shouldBeSaved: true,
    };
  }

  public updateAreaFromAddress(address: Address, area: Area): Address {
    const newAddress = Helpers.clone(address);

    if (area) {
      newAddress.area = area;
      newAddress.areaId = area.areaId;
    }

    return newAddress;
  }

  public removeGeocodedDataFromAddress(address: Address): Address {
    return {
      ...address,
      geography: {} as Geography,
    };
  }

  public addGeocodedDataToAddress(address: Address, geocodedData: GooglePlacesGeocodedResult): Address {
    let newAddress = Helpers.clone(address);

    if (geocodedData?.lat && geocodedData?.lng) {
      newAddress = {
        ...newAddress,
        geography: {
          latitude: geocodedData.lat,
          longitude: geocodedData.lng,
        },
      };
    }

    if (geocodedData?.areaModel) {
      newAddress = this.updateAreaFromAddress(newAddress, geocodedData.areaModel);
    }

    const shouldAddBlock = !newAddress.address?.block && geocodedData?.block;
    const shouldAddStreet = !newAddress.address?.street && geocodedData?.street;
    const shouldAddBuilding = !newAddress.address?.building && geocodedData?.building;

    if (shouldAddBlock || shouldAddStreet || shouldAddBuilding) {
      // Initialize the address object if the saved address was created
      // using an older version of the app that was setting it to null
      // https://bilbayt.atlassian.net/browse/CA-1926
      if (!newAddress.address) {
        newAddress.address = {};
      }

      if (shouldAddBlock) {
        newAddress.address.block = geocodedData.block;
      }

      if (shouldAddStreet) {
        newAddress.address.street = geocodedData.street;
      }

      if (shouldAddBuilding) {
        newAddress.address.building = geocodedData.building;
      }
    }

    return newAddress;
  }

  public createdFromArea(newAddress: Address): boolean {
    const hasPhoneNumber = newAddress?.phoneNumber?.countryCode && newAddress?.phoneNumber?.localNumber;

    const hasAddressDetails =
      newAddress?.address?.country ||
      newAddress?.address?.block ||
      newAddress?.address?.street ||
      newAddress?.address?.avenue ||
      newAddress?.address?.building ||
      newAddress?.address?.apartment ||
      newAddress?.address?.floor ||
      newAddress?.address?.neighborhood ||
      newAddress?.address?.lane;

    return this.isNew(newAddress) && !hasPhoneNumber && !hasAddressDetails;
  }

  public getNewOrderAddressFromSavedAddressModel(savedAddress: Address, country: Country): NewOrderAddress {
    const newOrderAddressModel = {} as NewOrderAddress;

    if (!savedAddress) {
      return newOrderAddressModel;
    }

    return {
      ...newOrderAddressModel,
      area: savedAddress.area.name,
      country: country.code.toUpperCase() as Uppercase<CountryCode>,
      block: savedAddress.address?.block ?? null,
      avenue: savedAddress.address?.avenue ?? null,
      street: savedAddress.address?.street ?? null,
      building: savedAddress.address?.building ?? null,
      apartment: savedAddress.address?.apartment ?? null,
      floor: savedAddress.address?.floor ?? null,
      neighborhood: savedAddress.address?.neighborhood ?? null,
      lane: savedAddress.address?.lane ?? null,
    };
  }

  public getAddressDescriptionText(selectedAddress: Address): {
    oneLiner: string;
    addressLines: [string, string, string];
  } {
    if (!selectedAddress) {
      return { oneLiner: '', addressLines: ['', '', ''] };
    }

    const addressLine1 = [];
    const addressLine2 = [];
    const addressLine3 = [];

    const areaName = selectedAddress.area.name;
    const block = selectedAddress.address?.block;
    const avenue = selectedAddress.address?.avenue;
    const street = selectedAddress.address?.street;
    const building = selectedAddress.address?.building;
    const floor = selectedAddress.address?.floor;
    const apartment = selectedAddress.address?.apartment;
    const neighborhood = selectedAddress.address?.neighborhood;
    const lane = selectedAddress.address?.lane;

    if (areaName) {
      addressLine1.push(areaName);
    }

    if (block) {
      addressLine1.push(`${this.translateService.instant('FIELDS.BLOCK')} ${block}`);
    }

    if (neighborhood) {
      addressLine2.push(`${this.translateService.instant('FIELDS.NEIGHBORHOOD')} ${neighborhood}`);
    }

    if (avenue) {
      addressLine3.push(`${this.translateService.instant('FIELDS.AVENUE')} ${avenue}`);
    }

    if (street) {
      const streetLabel = this.translateService.instant('FIELDS.STREET') as string;
      const sanitizedStreet = street.toLowerCase().indexOf(streetLabel.toLowerCase()) >= 0 ? street : `${streetLabel} ${street}`;

      addressLine3.push(sanitizedStreet);
    }

    if (building) {
      addressLine3.push(`${this.translateService.instant('FIELDS.BUILDING')} ${building}`);
    }

    if (floor) {
      addressLine3.push(`${this.translateService.instant('FIELDS.FLOOR')} ${floor}`);
    }

    if (apartment) {
      addressLine3.push(`${this.translateService.instant('FIELDS.APARTMENT_NO')} ${apartment}`);
    }

    if (lane) {
      addressLine3.push(`${this.translateService.instant('FIELDS.LANE')} ${lane}`);
    }

    const addressLine1Text = addressLine1.join(' ').trim();
    const addressLine2Text = addressLine2.join(' ').trim();
    const addressLine3Text = addressLine3.join(' ').trim();

    return {
      addressLines: [addressLine1Text, addressLine2Text, addressLine3Text],
      oneLiner: [addressLine1Text, addressLine2Text, addressLine3Text].filter((lineDetails) => !!lineDetails.length).join(', '),
    };
  }

  private createNewEmptyAddress(): Address {
    return { shouldBeSaved: true, address: {} } as Address;
  }

  private hasAddressChanged(currentAddress: Address, newAddress: Address): boolean {
    if (!currentAddress && !newAddress) {
      return false;
    }

    if ((!currentAddress && newAddress) || (currentAddress && !newAddress)) {
      return true;
    }

    return !!(
      currentAddress?.savedAddressId !== newAddress?.savedAddressId ||
      currentAddress?.name !== newAddress?.name ||
      currentAddress?.areaId !== newAddress?.areaId ||
      currentAddress?.geography?.latitude !== newAddress?.geography?.latitude ||
      currentAddress?.geography?.longitude !== newAddress?.geography?.longitude ||
      currentAddress?.address?.country !== newAddress?.address?.country ||
      currentAddress?.address?.block !== newAddress?.address?.block ||
      currentAddress?.address?.street !== newAddress?.address?.street ||
      currentAddress?.address?.avenue !== newAddress?.address?.avenue ||
      currentAddress?.address?.building !== newAddress?.address?.building ||
      currentAddress?.address?.apartment !== newAddress?.address?.apartment ||
      currentAddress?.address?.floor !== newAddress?.address?.floor ||
      currentAddress?.address?.neighborhood !== newAddress?.address?.neighborhood ||
      currentAddress?.address?.lane !== newAddress?.address?.lane ||
      currentAddress?.phoneNumber?.countryCode !== newAddress?.phoneNumber?.countryCode ||
      currentAddress?.phoneNumber?.localNumber !== newAddress?.phoneNumber?.localNumber
    );
  }
}
