import { PreloadableRouteData, PreloadableRoutes } from './app-routing.module';

import { AppInitializedGuard } from './core/guards/app-initialized.guard';
import { CountryLanguageGuard } from './core/guards/country-language.guard';

import { vendorDetailsBackwardCompatUrls } from './app-routes';

export const SERVER_ROUTES: PreloadableRoutes = [
  {
    path: 'ssr/:id',
    data: { title: 'SSR Test Page' },
    loadChildren: () => import('./pages/ssr-test/ssr-test.module').then((m) => m.SstTestPageModule),
  },
  {
    path: ':country/:language',
    canActivate: [CountryLanguageGuard],
    children: [
      {
        path: '',
        canActivate: [AppInitializedGuard],
        children: [
          ...vendorDetailsBackwardCompatUrls,
          {
            path: 'vendors',
            pathMatch: 'full',
            redirectTo: 'vendors/search',
          },
          {
            path: 'vendors/search',
            loadChildren: () => import('./pages/empty-ssr/empty-ssr.module').then((m) => m.EmptySsrPageModule),
          },
          {
            path: 'vendors/id/:vendorId/search',
            loadChildren: () => import('./pages/empty-ssr/empty-ssr.module').then((m) => m.EmptySsrPageModule),
          },
          {
            path: 'vendors/:vendorSlug/search',
            loadChildren: () => import('./pages/empty-ssr/empty-ssr.module').then((m) => m.EmptySsrPageModule),
          },
          {
            path: 'vendors/id/:vendorId',
            data: { dynamicTitle: true },
            loadChildren: () => import('./pages/vendor-details-ssr/vendor-details-ssr.module').then((m) => m.VendorDetailsSsrPageModule),
          },
          {
            path: 'vendors/id/:vendorId/:itemId',
            data: { dynamicTitle: true },
            loadChildren: () => import('./pages/vendor-details-ssr/vendor-details-ssr.module').then((m) => m.VendorDetailsSsrPageModule),
          },
          {
            path: 'vendors/id/:vendorId/categories/:categoryId',
            data: { dynamicTitle: true },
            loadChildren: () => import('./pages/vendor-details-ssr/vendor-details-ssr.module').then((m) => m.VendorDetailsSsrPageModule),
          },
          {
            path: 'vendors/id/:vendorId/categories/:categoryId/items',
            data: { dynamicTitle: true },
            loadChildren: () => import('./pages/vendor-details-ssr/vendor-details-ssr.module').then((m) => m.VendorDetailsSsrPageModule),
          },
          {
            path: 'vendors/:vendorSlug',
            data: { dynamicTitle: true } as PreloadableRouteData,
            loadChildren: () => import('./pages/vendor-details-ssr/vendor-details-ssr.module').then((m) => m.VendorDetailsSsrPageModule),
          },
          {
            path: 'vendors/:vendorSlug/:itemId',
            data: { dynamicTitle: true } as PreloadableRouteData,
            loadChildren: () => import('./pages/vendor-details-ssr/vendor-details-ssr.module').then((m) => m.VendorDetailsSsrPageModule),
          },
          {
            path: 'vendors/:vendorSlug/:itemId/:itemSlug',
            data: { dynamicTitle: true } as PreloadableRouteData,
            loadChildren: () => import('./pages/vendor-details-ssr/vendor-details-ssr.module').then((m) => m.VendorDetailsSsrPageModule),
          },
          {
            path: 'vendors/:vendorSlug/categories/:categoryId',
            data: { dynamicTitle: true } as PreloadableRouteData,
            loadChildren: () => import('./pages/vendor-details-ssr/vendor-details-ssr.module').then((m) => m.VendorDetailsSsrPageModule),
          },
          {
            path: 'vendors/:vendorSlug/categories/:categoryId/items',
            data: { dynamicTitle: true } as PreloadableRouteData,
            loadChildren: () => import('./pages/vendor-details-ssr/vendor-details-ssr.module').then((m) => m.VendorDetailsSsrPageModule),
          },
          {
            path: 'collections/marketing/:collectionId',
            data: { dynamicTitle: true },
            loadChildren: () =>
              import('./pages/marketing-collection-details-ssr/marketing-collection-details-ssr.module').then(
                (m) => m.MarketingCollectionDetailsSsrPageModule,
              ),
          },
          {
            path: '',
            loadChildren: () => import('./pages/empty-ssr/empty-ssr.module').then((m) => m.EmptySsrPageModule),
          },
        ],
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./pages/empty-ssr/empty-ssr.module').then((m) => m.EmptySsrPageModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
