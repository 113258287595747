import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { OrderReviewModalPage } from './order-review-modal.page';

import { EditableRatingComponentModule } from '../../shared/components/editable-rating/editable-rating.module';

import { SwiperModule } from 'swiper/angular';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule.forChild(), EditableRatingComponentModule, SwiperModule],
  declarations: [OrderReviewModalPage],
})
export class OrderReviewModalPageModule {}
