import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { ViewDidEnter } from '@ionic/angular';

import { Subject, takeUntil } from 'rxjs';

import { LoyaltyProgramNextRewardDetails } from '../../core/models/loyalty-program-next-reward-details.model';

import { AnalyticsService } from '../../core/services/analytics.service';
import { CartService } from '../../core/services/cart.service';
import { LoyaltyProgramService } from '../../core/services/loyalty-program.service';
import { ModalService } from '../../core/services/modal.service';
import { NavigationService } from '../../core/services/navigation.service';
import { SettingsService } from '../../core/services/settings.service';

import { AnalyticsConfig, TOKEN_ANALYTICS_CONFIG } from '../../core/config/analytics.config';

import { CartDetailsComponent } from '../../shared/components/cart-details/cart-details.component';

export const BrowserCartModalPageIdentifier = 'cart-modal';

@Component({
  selector: 'app-browser-cart-modal',
  templateUrl: './browser-cart-modal.page.html',
  styleUrls: ['./browser-cart-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BrowserCartModalPage implements OnInit, ViewDidEnter, OnDestroy {
  @ViewChild(CartDetailsComponent)
  public cartDetailsComponent: CartDetailsComponent;

  public inEditMode: boolean;
  public isCartEmpty: boolean;
  public loyaltyProgramNextRewardDetails: LoyaltyProgramNextRewardDetails;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private cartService: CartService,
    private modalService: ModalService,
    private settingsService: SettingsService,
    private analyticsService: AnalyticsService,
    private navigationService: NavigationService,
    private loyaltyProgramService: LoyaltyProgramService,
    @Inject(TOKEN_ANALYTICS_CONFIG) private analyticsConfig: AnalyticsConfig,
  ) {}

  ngOnInit(): void {
    this.isCartEmpty = this.cartService.isCartEmpty();
    this.cartService.cart$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => (this.isCartEmpty = this.cartService.isCartEmpty()));
  }

  ionViewDidEnter() {
    this.trackPageView();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  public onDismiss(): void {
    void this.modalService.dismissModal({ id: BrowserCartModalPageIdentifier });
  }

  public onCheckout(): void {
    this.cartDetailsComponent?.startCheckout();
  }

  public onClearCart(): void {
    this.cartDetailsComponent?.clearCart();
  }

  public onEditModeChanged(editModeEnabled: boolean): void {
    if (this.inEditMode !== editModeEnabled) {
      this.inEditMode = editModeEnabled;
    }
  }

  public onCheckoutStarted(): void {
    this.trackCheckoutEvent();
    this.trackCheckoutStartedCleverTapEvent();
    this.redirectToCheckoutPage();
  }

  public onCartTotalUpdated(): void {
    this.loyaltyProgramNextRewardDetails = this.loyaltyProgramService.getNextRewardDetailsForCart();
  }

  private redirectToCheckoutPage(): void {
    void this.modalService.dismissModal({ id: BrowserCartModalPageIdentifier }).then(() => {
      void this.navigationService.navigateTo('/checkout');
    });
  }

  private trackPageView(): void {
    const amount = this.cartService.getItemsTotalPrice();

    void this.analyticsService.trackView({
      name: this.analyticsConfig.pageName.cartPage,
      data: {
        [this.analyticsConfig.propertyName.amount]: amount,
        [this.analyticsConfig.propertyName.currency]: this.settingsService.currencySymbolEN,
      },
      includeAreaDateTime: true,
    });
  }

  private trackCheckoutEvent(): void {
    const amount = this.cartService.getItemsTotalPrice();

    void this.analyticsService.trackEvent({
      name: this.analyticsConfig.eventName.cartPageCheckout,
      data: {
        [this.analyticsConfig.propertyName.amount]: amount,
        [this.analyticsConfig.propertyName.currency]: this.settingsService.currencySymbolEN,
      },
      includeAreaDateTime: true,
    });
  }

  private trackCheckoutStartedCleverTapEvent(): void {
    void this.analyticsService.trackCheckoutStartedCleverTapEvent({
      amount: this.cartService.getItemsTotalPrice(),
      currency: this.settingsService.currencySymbolEN,
    });
  }
}
