import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CountryCode } from '../../models/country.model';
import { MultiLanguageValue } from '../../models/multi-language-value.model';
import { VendorDetailsSsr } from '../../models/vendor-details-ssr.model';

import { SettingsService } from '../settings.service';

import { Helpers } from '../../helpers';

import { LocalVendorDetailsLoader } from './local-vendor-details-loader';

import * as fs from 'fs';
import { join } from 'path';

@Injectable({ providedIn: 'root' })
export class LocalVendorDetailsServerLoader implements LocalVendorDetailsLoader {
  constructor(private settingsService: SettingsService) {}

  public getVendorDetails(vendorSlug: string): Observable<VendorDetailsSsr> {
    return new Observable((observer) => {
      try {
        const selectedCountry = this.settingsService.getCountry().code;
        const vendorDetailsFile = join(process.cwd(), 'vendor-details.json');
        const vendorDetailsFileContent = fs.readFileSync(vendorDetailsFile, 'utf8');
        const allVendorsMap = JSON.parse(vendorDetailsFileContent) as Record<CountryCode, Record<string, VendorDetailsSsr>>;
        const vendorDetails = allVendorsMap[selectedCountry][vendorSlug];

        Helpers.logWithTimeStamp(`Found vendor ${vendorSlug} in static file: ${!!vendorDetails}`);

        if (!vendorDetails) {
          observer.next(undefined);
          observer.complete();
          return;
        }

        observer.next({
          ...vendorDetails,
          name: this.localizeProperty(vendorDetails.name),
          description: this.localizeProperty(vendorDetails.description),
          items: vendorDetails.items
            ? vendorDetails.items.map((item) => ({
                ...item,
                name: this.localizeProperty(item.name),
                description: this.localizeProperty(item.description),
              }))
            : [],
        });
        observer.complete();
      } catch (error) {
        Helpers.logWithTimeStamp(`Error while getting vendor ${vendorSlug} from static file`);
        Helpers.logWithTimeStamp(error);
        observer.next(undefined);
        observer.complete();
      }
    });
  }

  private localizeProperty(prop: string): string {
    try {
      const selectedLanguage = this.settingsService.getLanguage().value;
      return Helpers.getLocalizedMultiLanguageValue(JSON.parse(prop) as MultiLanguageValue, selectedLanguage);
    } catch (e) {
      return '';
    }
  }
}
