import { Injectable } from '@angular/core';

import { WindowRef, WindowService } from './window.service';

@Injectable()
export class WindowServerService extends WindowService {
  public get isWindowDefined(): boolean {
    return false;
  }

  public get window(): WindowRef {
    return {} as unknown as WindowRef;
  }
}
