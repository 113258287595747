import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { HealthCheckStatus } from '../models/health-check-status.model';

import { AppEventsService } from '../services/app-events.service';
import { SettingsService } from '../services/settings.service';

import { isHealthCheckUrl, shouldSkipHttpInterceptors } from '../config/app.config';

@Injectable()
export class HealthCheckInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (shouldSkipHttpInterceptors(request.url) || isHealthCheckUrl(request.url)) {
      return next.handle(request);
    }

    return next
      .handle(request)
      .pipe(
        catchError((error) =>
          error instanceof HttpErrorResponse && error.status >= 500 ? this.handle500Errors(request, next) : throwError(error),
        ),
      );
  }

  private handle500Errors(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const settingsService = this.injector.get(SettingsService);
    const appEventsService = this.injector.get(AppEventsService);

    return settingsService.getHealthCheckStatus().pipe(
      catchError(() => {
        appEventsService.dispatch('ServiceUnavailable');
        return of<HttpEvent<unknown>>();
      }),
      switchMap((healthCheckStatus: HealthCheckStatus) => {
        if (healthCheckStatus && healthCheckStatus.status === 'Healthy') {
          return next.handle(req);
        } else {
          appEventsService.dispatch('ServiceUnavailable');
          return of<HttpEvent<unknown>>();
        }
      }),
    );
  }
}
