import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { GoogleMapsComponent } from './google-maps.component';

@NgModule({
  declarations: [GoogleMapsComponent],
  imports: [CommonModule, IonicModule, TranslateModule.forChild()],
  exports: [GoogleMapsComponent],
})
export class GoogleMapsComponentModule {}
