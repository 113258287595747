import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SelectedTimeSlot } from '../../core/models/selected-time-slots-per-vendor.model';
import { TimeSlot } from '../../core/models/time-slot.model';
import { TimeSlotsByDate } from '../../core/models/time-slots-by-date.model';

import { LoggerService } from '../../core/services/logger.service';
import { ModalService } from '../../core/services/modal.service';
import { OverlayService } from '../../core/services/overlay.service';
import { PlatformService } from '../../core/services/ssr/platform.service';
import { ValidationService } from '../../core/services/validation.service';
import { VendorsService } from '../../core/services/vendors.service';

export const TimeSlotSelectionModalPageIdentifier = 'time-slot-selection-modal';

@Component({
  selector: 'app-time-slot-selection-modal',
  templateUrl: './time-slot-selection-modal.page.html',
  styleUrls: ['./time-slot-selection-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TimeSlotSelectionModalPage implements OnInit, OnDestroy {
  @Input()
  public selectedVendorId: number;

  @Input()
  public selectedTimeSlot: TimeSlot;

  @Input()
  public timeSlotsByDate: Array<TimeSlotsByDate>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private modalService: ModalService,
    private overlayService: OverlayService,
    private validationService: ValidationService,
    private loggerService: LoggerService,
    private vendorsService: VendorsService,
    private platformService: PlatformService,
  ) {}

  ngOnInit() {
    if (!this.timeSlotsByDate) {
      this.loadTimeSlots();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  public onDismiss(params?: SelectedTimeSlot): Promise<boolean> {
    return this.modalService.dismissModal({ data: params, id: TimeSlotSelectionModalPageIdentifier });
  }

  public onSelectTimeSlotAndDismissPage(params: SelectedTimeSlot): void {
    if (!params) {
      return;
    }

    const { dateText, timeSlot } = params;

    this.selectedTimeSlot = timeSlot;

    void this.platformService.wait(300).then(() => this.onDismiss({ dateText, timeSlot }));
  }

  private loadTimeSlots(): void {
    this.vendorsService
      .getAvailableTimeSlotsByDate(this.selectedVendorId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (timeSlotsByDate) => {
          this.timeSlotsByDate = timeSlotsByDate;
        },
        error: (error: HttpErrorResponse) => {
          const errorMessage = this.validationService.getErrorMessage(error);

          this.loggerService.info({
            component: 'TimeSlotSelectionPage',
            message: "couldn't initialize page",
            details: { error, messageShownToUser: errorMessage },
          });

          void this.onDismiss();
          this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });
        },
      });
  }
}
