import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { ViewDidLeave } from '@ionic/angular';

import { Observable, scan, Subscription, takeWhile, timer } from 'rxjs';

import { ModalService } from '../../core/services/modal.service';

export const TimeoutRetryModalPageIdentifier = 'timeout-retry-modal';

export type TimeoutRetryModalDismissReason = 'manualRetry' | 'cancel';

@Component({
  selector: 'app-timeout-retry-modal',
  templateUrl: './timeout-retry-modal.page.html',
  styleUrls: ['./timeout-retry-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeoutRetryModalPage implements OnInit, ViewDidLeave {
  @Input()
  public waitDurationInSeconds: number;

  public countdown$: Observable<number>;
  public autoDismiss$: Subscription;

  public title: string;
  public message: string;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    if (this.waitDurationInSeconds > 0) {
      this.countdown$ = timer(0, 1000).pipe(
        scan((acc) => --acc, this.waitDurationInSeconds),
        takeWhile((x) => x > 0),
      );

      this.autoDismiss$ = this.countdown$.subscribe({
        complete: () => this.onDismiss(),
      });
    }
  }

  ionViewDidLeave(): void {
    this.autoDismiss$?.unsubscribe();
  }

  public onDismiss(reason?: TimeoutRetryModalDismissReason): void {
    void this.modalService.dismissModal<TimeoutRetryModalDismissReason>({ id: TimeoutRetryModalPageIdentifier, data: reason });
  }
}
