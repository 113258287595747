import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { isPlatform, Platform } from '@ionic/angular';
import { BackButtonEmitter } from '@ionic/angular/providers/platform';

import { Observable } from 'rxjs';

import { ApplicationPlatformName } from '../../enums/application-platform-name.enum';
import { ApplicationPlatform } from '../../enums/application-platform.enum';

import { isSimulatingCordovaPlatform } from '../environment.service';

const TABLET_MIN_WIDTH_IN_PX = 768;
const DESKTOP_MIN_WIDTH_IN_PX = 1200;

@Injectable()
export class PlatformService {
  constructor(private platform: Platform, @Inject(PLATFORM_ID) private platformId: Record<string, unknown>) {}

  public get isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  public get isIos(): boolean {
    return isPlatform('ios');
  }

  public get isAndroid(): boolean {
    return isPlatform('android');
  }

  public get areCordovaPluginsAvailable(): boolean {
    return isPlatform('cordova');
  }

  public get isBrowser(): boolean {
    return !this.isCordova;
  }

  public get isBrowserMobile(): boolean {
    return this.isBrowser && isPlatform('mobile');
  }

  public get isCordova(): boolean {
    return isPlatform('cordova') || isSimulatingCordovaPlatform();
  }

  public get isCordovaMobile(): boolean {
    return this.isCordova && isPlatform('mobile');
  }

  public get isCordovaTablet(): boolean {
    return this.isCordova && (isPlatform('ipad') || isPlatform('tablet'));
  }

  public get applicationPlatformId(): ApplicationPlatform {
    return this.isBrowser
      ? ApplicationPlatform.Web
      : this.isIos
      ? ApplicationPlatform.Ios
      : this.isAndroid
      ? ApplicationPlatform.Android
      : ApplicationPlatform.None;
  }

  public get applicationPlatformName(): ApplicationPlatformName {
    return this.isBrowser
      ? ApplicationPlatformName.Web
      : this.isIos
      ? ApplicationPlatformName.Ios
      : this.isAndroid
      ? ApplicationPlatformName.Android
      : ApplicationPlatformName.None;
  }

  public get tabletMinScreenWidth(): number {
    return TABLET_MIN_WIDTH_IN_PX;
  }

  public get desktopMinScreenWidth(): number {
    return DESKTOP_MIN_WIDTH_IN_PX;
  }

  public get pause(): Observable<void> {
    return this.platform.pause.asObservable();
  }

  public get resume(): Observable<void> {
    return this.platform.resume.asObservable();
  }

  public get backButton(): BackButtonEmitter {
    return this.platform.backButton;
  }

  public wait(duration: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), duration));
  }

  public preloadImage(imageUrl: string, duration: number = 300): Promise<{ loaded: boolean; width?: number; height?: number }> {
    return new Promise((resolve) => {
      const image = new Image();

      image.onload = () => {
        // Give some time to the image to be
        // actually loaded by the browser
        void this.wait(duration).then(() =>
          resolve({
            loaded: true,
            width: image.width ?? null,
            height: image.height ?? null,
          }),
        );
      };

      image.onerror = () => {
        resolve({ loaded: false });
      };

      // Start loading the image in the background
      image.src = imageUrl;
    });
  }

  public ready(): Promise<string> {
    return this.platform.ready();
  }

  public height(): number {
    return this.platform.height();
  }

  public width(): number {
    return this.platform.width();
  }
}
