import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { AddressFormComponent } from './address-form.component';

@NgModule({
  declarations: [AddressFormComponent],
  imports: [CommonModule, IonicModule, ReactiveFormsModule, FormsModule, TranslateModule.forChild()],
  exports: [AddressFormComponent],
})
export class AddressFormComponentModule {}
