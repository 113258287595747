import { transition, trigger } from '@angular/animations';

import { AnimationTransition, applyFadeInExpandWidthAnimation, applyFadeOutCollapseWidthAnimation } from '../../core/animations';

export const BrowserDateTimePickerModalPageAnimations = [
  trigger('animatedTimePicker', [
    transition(AnimationTransition.VoidToAny, applyFadeInExpandWidthAnimation({ duration: 300 })),
    transition(AnimationTransition.AnyToVoid, applyFadeOutCollapseWidthAnimation({ duration: 300 })),
  ]),
];
