import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { ContactModalPage } from './contact-modal.page';

import { PipesModule } from '../../shared/pipes/pipes.module';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, IonicModule, TranslateModule.forChild(), PipesModule],
  declarations: [ContactModalPage],
})
export class ContactModalPageModule {}
