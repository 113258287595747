/* istanbul ignore file */

import { Injectable } from '@angular/core';

import { Config, DomController, IonContent } from '@ionic/angular';

import { SettingsService } from './settings.service';
import { PlatformService } from './ssr/platform.service';
import { WindowService } from './ssr/window.service';

// Model used to send information related to the page where
// the user should be redirected to.
class ScrollData {
  public scrollTo: number;
  public scrollDuration: number;
  public scrollAmount: number;
}

// Constants
const SCROLL_ASSIST_SPEED = 0.3;
const SCROLLING_DURATION = 400;
const HORIZONTAL_SCROLLING_DURATION = 400;

@Injectable({ providedIn: 'root' })
export class ScrollService {
  constructor(
    private config: Config,
    private domCtrl: DomController,
    private windowService: WindowService,
    private settingsService: SettingsService,
    private platformService: PlatformService,
  ) {}

  // Method that scrolls horizontally the tabs container in order to show the tab that
  // has been selected if it was not visible ENTIRELY when it was selected
  public scrollHorizontallyToSelectedTab(tabElement: HTMLElement, duration?: number): Promise<void> {
    return new Promise((resolve) => {
      this.domCtrl.write(() => {
        if (!tabElement || !tabElement.parentElement) {
          resolve();
          return;
        }

        const tabsContainer = tabElement.parentElement;
        const tabsContainerWidth = tabElement.parentElement.getBoundingClientRect().width;
        const tabsContainerScrollWidth = tabsContainer.scrollWidth;
        const tabsContainerScrollPosition = tabsContainer.scrollLeft;

        const selectedTabWidth = tabElement.getBoundingClientRect().width;
        const selectedTabPosition = tabElement.getBoundingClientRect().x;
        const selectedTabDesiredPosition = (tabsContainerWidth - selectedTabWidth) / 2;

        const isCentered = selectedTabPosition === selectedTabDesiredPosition;

        if (isCentered) {
          resolve();
          return;
        }

        const delta = selectedTabPosition - selectedTabDesiredPosition;

        // We need to know how much is the max or min amount that
        // the tabs can be scrolled since it doesn't make sense that
        // the endPosition is higher than the total width of the scroll
        // or lower than 0
        let scrollStartMinValue: number;
        let scrollEndMaxValue: number;

        const shouldInvertStartAndEnd = this.settingsService.getLanguage().rtl;

        if (!shouldInvertStartAndEnd) {
          scrollStartMinValue = 0;
          scrollEndMaxValue = tabsContainerScrollWidth - tabsContainerWidth;
        } else {
          scrollStartMinValue = -1 * (tabsContainerScrollWidth - tabsContainerWidth);
          scrollEndMaxValue = 0;
        }

        const startingScrollLeft = tabsContainerScrollPosition;
        const endingScrollLeft =
          delta > 0
            ? Math.min(scrollEndMaxValue, startingScrollLeft + Math.abs(delta))
            : Math.max(scrollStartMinValue, startingScrollLeft - Math.abs(delta));

        // If we don't need to do anything, still wait for the scrolling time
        // because otherwise changing some tabs would not require the same time
        // as changing some other tabs
        if (startingScrollLeft === endingScrollLeft) {
          void this.platformService.wait(HORIZONTAL_SCROLLING_DURATION).then(() => resolve());
          return;
        }

        if (duration === 0) {
          tabsContainer.scrollLeft = endingScrollLeft;
          resolve();
          return;
        }

        this.animateHorizontalScroll(tabsContainer, startingScrollLeft, endingScrollLeft, HORIZONTAL_SCROLLING_DURATION, resolve);
      });
    });
  }

  // Code stolen from Ionic source code to mimic the speed of Ionic's scrolling methods
  // https://github.com/ionic-team/ionic-v3/blob/master/src/components/input/input.ts#L805
  public getScrollingDuration(distance: number): number {
    return Math.min(SCROLLING_DURATION, Math.max(150, distance / SCROLL_ASSIST_SPEED));
  }

  public scrollToFormField(content: IonContent, inputElement: HTMLElement): Promise<void> {
    return content.getScrollElement().then((scroller) => {
      const { scrollTop, offsetTop } = scroller;

      // Get the information about how mcuch should we scroll to
      // make the field to be visible
      const scrollData = this.getScrollData(
        inputElement.offsetTop,
        inputElement.offsetHeight,
        { scrollTop, contentTop: offsetTop },
        this.config.getNumber('keyboardHeight', 150),
        this.platformService.height(),
      );

      if (Math.abs(scrollData.scrollAmount) > 4) {
        // We need to wait a few ms just in case if the keyboard was
        // shown because otherwise the bottom padding may not be added yet
        return this.platformService.wait(300).then(() => content.scrollByPoint(0, scrollData.scrollTo, scrollData.scrollDuration));
      }
    });
  }

  private getScrollData(
    inputOffsetTop: number,
    inputOffsetHeight: number,
    scrollViewDimensions: { scrollTop: number; contentTop: number },
    keyboardHeight: number,
    plaformHeight: number,
  ): ScrollData {
    // Compute input's Y values relative to the body
    const inputTop = inputOffsetTop + scrollViewDimensions.contentTop - scrollViewDimensions.scrollTop;
    const inputBottom = inputTop + inputOffsetHeight;

    // Compute the safe area which is the viewable content area when the soft keyboard is up
    const safeAreaTop = scrollViewDimensions.contentTop;
    const safeAreaHeight = (plaformHeight - keyboardHeight - safeAreaTop) / 2;
    const safeAreaBottom = safeAreaTop + safeAreaHeight;

    // Figure out if each edge of teh input is within the safe area
    const inputTopWithinSafeArea = inputTop >= safeAreaTop && inputTop <= safeAreaBottom;
    const inputTopAboveSafeArea = inputTop < safeAreaTop;
    const inputTopBelowSafeArea = inputTop > safeAreaBottom;
    const inputBottomWithinSafeArea = inputBottom >= safeAreaTop && inputBottom <= safeAreaBottom;
    const inputBottomBelowSafeArea = inputBottom > safeAreaBottom;

    // Text Input Scroll To Scenarios
    // ---------------------------------------
    // 1) Input top within safe area, bottom within safe area
    // 2) Input top within safe area, bottom below safe area, room to scroll
    // 3) Input top above safe area, bottom within safe area, room to scroll
    // 4) Input top below safe area, no room to scroll, input smaller than safe area
    // 5) Input top within safe area, bottom below safe area, no room to scroll, input smaller than safe area
    // 6) Input top within safe area, bottom below safe area, no room to scroll, input larger than safe area
    // 7) Input top below safe area, no room to scroll, input larger than safe area

    const scrollData: ScrollData = {
      scrollTo: 0,
      scrollDuration: 0,
      scrollAmount: 0,
    };

    if (inputTopWithinSafeArea && inputBottomWithinSafeArea) {
      // Input top within safe area, bottom within safe area
      // no need to scroll to a position, it's good as-is
      return scrollData;
    }

    // Looks like we'll have to do some auto-scrolling
    if (inputTopBelowSafeArea || inputBottomBelowSafeArea || inputTopAboveSafeArea) {
      // Input top or bottom below safe area
      // auto scroll the input up so at least the top of it shows
      scrollData.scrollAmount =
        safeAreaHeight > inputOffsetHeight
          ? // Safe area height is taller than the input height, so we
            // can bring up the input just enough to show the input bottom
            (scrollData.scrollAmount = Math.round(safeAreaBottom - inputBottom))
          : // Safe area height is smaller than the input height, so we can
            // only scroll it up so the input top is at the top of the safe area
            // however the input bottom will be below the safe area
            (scrollData.scrollAmount = Math.round(safeAreaTop - inputTop));
    }

    // Figure out where it should scroll to for the best position to the input
    scrollData.scrollTo = scrollViewDimensions.scrollTop - scrollData.scrollAmount;

    // Calculate animation duration
    const distance = Math.abs(scrollData.scrollAmount);
    const duration = distance / 0.3;

    scrollData.scrollDuration = Math.min(400, Math.max(150, duration));

    return scrollData;
  }

  private animateHorizontalScroll(
    element: { scrollLeft: number },
    start: number,
    end: number,
    duration: number,
    resolveFn: () => void,
  ): void {
    const delta = end - start;

    let startTime: number;
    if (this.windowService.isWindowDefined && this.windowService.window.performance?.now) {
      startTime = this.windowService.window.performance.now();
    } else if (Date.now) {
      startTime = Date.now();
    } else {
      startTime = new Date().getTime();
    }

    const easing = (t: number, b: number, c: number, d: number) => c * ((t = t / d - 1) * t * t + 1) + b;

    const loop = (time?: number) => {
      const t = !time ? 0 : time - startTime;
      const factor = easing(t, 0, 1, duration);

      element.scrollLeft = start + delta * factor;

      if (t < duration && element.scrollLeft !== end) {
        requestAnimationFrame(loop);
      } else {
        resolveFn();
      }
    };

    loop();
  }
}
