import { Inject, Injectable } from '@angular/core';

import { BranchIo, BranchIoAnalytics, BranchIoProperties } from '@awesome-cordova-plugins/branch-io/ngx';

import { TargetPage } from '../../enums/target-page.enum';

import { UniversalLink } from '../../models/universal-link.model';

import { LoggerService } from '../logger.service';
import { PlatformService } from '../ssr/platform.service';

import { AppConfig, TOKEN_CONFIG } from '../../config/app.config';
import { TOKEN_UNIVERSAL_LINKS_CONFIG, UniversalLinksConfig } from '../../config/universal-links.config';

import { Helpers } from '../../helpers';

const INIT_MAX_DELAY_IN_MS = 5_000;
const GET_DATA_MAX_DELAY_IN_MS = 3_000;
const NON_BRANCH_LINK_PROP_NAME = '+non_branch_link';

@Injectable({ providedIn: 'root' })
export class BranchPlugin {
  constructor(
    private branch: BranchIo,
    private loggerService: LoggerService,
    private platformService: PlatformService,
    @Inject(TOKEN_CONFIG) private config: AppConfig,
    @Inject(TOKEN_UNIVERSAL_LINKS_CONFIG) private universalLinksConfig: UniversalLinksConfig,
  ) {}

  public initSession(): Promise<void> {
    return Promise.race([
      this.platformService.wait(INIT_MAX_DELAY_IN_MS),
      this.branch.initSession().catch((error: unknown) => {
        this.loggerService.info({
          component: 'BranchPlugin',
          message: "couldn't init branch session",
          details: { error },
          fromPlugin: true,
        });
      }),
    ]).then(() => {});
  }

  public getLatestDeepLinkData(): Promise<UniversalLink> {
    return Promise.race([
      this.platformService.wait(GET_DATA_MAX_DELAY_IN_MS).then(() => ({} as UniversalLink)),
      this.branch
        .getLatestReferringParams()
        .then((linkData: Record<string, string>) => this.getParamsFromDeepLinkData(linkData))
        .catch((error: unknown) => {
          this.loggerService.info({
            component: 'BranchPlugin',
            message: "couldn't get latest branch params",
            details: { error },
            fromPlugin: true,
          });
          return {} as UniversalLink;
        }),
    ]);
  }

  public setIdentity(userId: string): Promise<void> {
    return this.branch
      .setIdentity(userId)
      .then(() => {})
      .catch((error: unknown) => {
        this.loggerService.info({ component: 'BranchPlugin', message: "couldn't set identity", details: { error }, fromPlugin: true });
      });
  }

  public logout(): Promise<void> {
    return this.branch
      .logout()
      .then(() => {})
      .catch((error: unknown) => {
        this.loggerService.info({ component: 'BranchPlugin', message: "couldn't logout user", details: { error }, fromPlugin: true });
      });
  }

  public logEvent(event: string, metaData: unknown): Promise<void> {
    return this.branch
      .sendBranchEvent(event, metaData)
      .then(() => {})
      .catch((error: unknown) => {
        this.loggerService.info({ component: 'BranchPlugin', message: "couldn't send branch event", details: { error }, fromPlugin: true });
      });
  }

  public createLinkUrl({
    title,
    description,
    imageUrl,
    websiteUrl,
    params,
  }: {
    title: string;
    description: string;
    imageUrl?: string;
    websiteUrl: string;
    params: Record<string, unknown>;
  }): Promise<string> {
    return this.branch
      .createBranchUniversalObject({ canonicalIdentifier: websiteUrl })
      .then((branchUniversalObj) => {
        if (!branchUniversalObj) {
          return websiteUrl;
        }

        const branchAnalytics: BranchIoAnalytics = {};
        const branchProperties: BranchIoProperties = {
          $desktop_url: websiteUrl,
          $android_url: this.config.appStoreUrlAndroid,
          $ios_url: this.config.appStoreUrlIos,
          $og_title: title,
          $og_description: description,
          $og_image_url: imageUrl,
          ...params,
        };

        return branchUniversalObj
          .generateShortUrl(branchAnalytics, branchProperties)
          .then((link: { url: string }) => (link ? link.url : websiteUrl));
      })
      .catch((error: unknown) => {
        this.loggerService.info({ component: 'BranchPlugin', message: "couldn't create link", details: { error }, fromPlugin: true });
        return websiteUrl;
      });
  }

  private getParamsFromDeepLinkData(branchData: Record<string, string>): UniversalLink {
    if (!branchData) {
      return {} as UniversalLink;
    }

    let targetPage: TargetPage = null;
    let campaignId: number = null;
    let campaignSource: string = null;
    let catererId: number = null;
    let menuItemId: number = null;
    let marketingCollectionId: number = null;
    let wishlistId: number = null;
    let serviceTypes: number = null;
    let orderId: number = null;
    let affiliateUserId: string = null;
    let couponCode: string = null;

    const targetPageParamName = this.universalLinksConfig.paramName.targetPage;
    const campaignIdParamName = this.universalLinksConfig.paramName.campaignId;
    const campaignSourceParamName = this.universalLinksConfig.paramName.campaignSource;
    const catererIdParamName = this.universalLinksConfig.paramName.catererId;
    const menuItemIdParamName = this.universalLinksConfig.paramName.menuItemId;
    const marketingCollectionIdParamName = this.universalLinksConfig.paramName.marketingCollectionId;
    const wishlistIdParamName = this.universalLinksConfig.paramName.wishlistId;
    const serviceTypesParamName = this.universalLinksConfig.paramName.serviceTypes;
    const orderIdParamName = this.universalLinksConfig.paramName.orderId;
    const affiliateUserIdParamName = this.universalLinksConfig.paramName.affiliateUserId;
    const couponCodeParamName = this.universalLinksConfig.paramName.couponCode;

    if (this.areThereTopLevelPropertiesInBranchData(branchData)) {
      targetPage = +branchData[targetPageParamName];
      orderId = +branchData[orderIdParamName];
      catererId = +branchData[catererIdParamName];
      menuItemId = +branchData[menuItemIdParamName];
      marketingCollectionId = +branchData[marketingCollectionIdParamName];
      wishlistId = +branchData[wishlistIdParamName];
      serviceTypes = +branchData[serviceTypesParamName];
      campaignId = +branchData[campaignIdParamName];
      campaignSource = branchData[campaignSourceParamName];
      affiliateUserId = branchData[affiliateUserIdParamName];
      couponCode = branchData[couponCodeParamName];
    } else if (branchData[NON_BRANCH_LINK_PROP_NAME]) {
      const nonBranchLink = branchData[NON_BRANCH_LINK_PROP_NAME];
      targetPage = +Helpers.getNumberParamFromLink(targetPageParamName, nonBranchLink, true);
      orderId = +Helpers.getNumberParamFromLink(orderIdParamName, nonBranchLink, true);
      catererId = +Helpers.getNumberParamFromLink(catererIdParamName, nonBranchLink, true);
      menuItemId = +Helpers.getNumberParamFromLink(menuItemIdParamName, nonBranchLink, true);
      marketingCollectionId = +Helpers.getNumberParamFromLink(marketingCollectionIdParamName, nonBranchLink, true);
      wishlistId = +Helpers.getNumberParamFromLink(wishlistIdParamName, nonBranchLink, true);
      serviceTypes = +Helpers.getNumberParamFromLink(serviceTypesParamName, nonBranchLink, true);
      campaignId = +Helpers.getNumberParamFromLink(campaignIdParamName, nonBranchLink, true);
      campaignSource = Helpers.getStringParamFromLink(campaignSourceParamName, nonBranchLink, true);
      affiliateUserId = Helpers.getStringParamFromLink(affiliateUserIdParamName, nonBranchLink, true);
      couponCode = Helpers.getStringParamFromLink(couponCodeParamName, nonBranchLink, true);
    }

    if (!targetPage) {
      if (marketingCollectionId) {
        targetPage = TargetPage.MarketingCollectionPage;
      } else if (wishlistId) {
        targetPage = TargetPage.WishlistDetails;
      } else if (catererId) {
        // The universal link contains the catererId so
        // it's related to a caterer or a menu item
        targetPage = menuItemId ? TargetPage.MenuItemDetails : TargetPage.CatererDetails;
      } else if (orderId) {
        // If the universal link is related to an order
        // but it doesn't contain the type, we assume it
        // should redirect to the OrderDetailsPage
        targetPage = TargetPage.OrderDetails;
      }
    }

    const universalLink: UniversalLink = {
      targetPage,
      orderId,
      catererId,
      menuItemId,
      marketingCollectionId,
      serviceTypes,
      campaignId,
      campaignSource,
      affiliateUserId,
      wishlistId,
      couponCode,
    };

    return universalLink;
  }

  private areThereTopLevelPropertiesInBranchData(branchData: Record<string, unknown>): boolean {
    return (branchData[this.universalLinksConfig.paramName.campaignId] ||
      branchData[this.universalLinksConfig.paramName.campaignSource] ||
      branchData[this.universalLinksConfig.paramName.orderId] ||
      branchData[this.universalLinksConfig.paramName.targetPage] ||
      branchData[this.universalLinksConfig.paramName.catererId] ||
      branchData[this.universalLinksConfig.paramName.menuItemId] ||
      branchData[this.universalLinksConfig.paramName.marketingCollectionId] ||
      branchData[this.universalLinksConfig.paramName.wishlistId] ||
      branchData[this.universalLinksConfig.paramName.serviceTypes] ||
      branchData[this.universalLinksConfig.paramName.affiliateUserId] ||
      branchData[this.universalLinksConfig.paramName.couponCode]) as boolean;
  }
}
