import { Injectable } from '@angular/core';

import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { PlatformService } from '../ssr/platform.service';

export const enum StatusBarColor {
  LightText,
  DarkText,
}

@Injectable({ providedIn: 'root' })
export class StatusBarPlugin {
  private statusBarColor: StatusBarColor;

  constructor(private statusBar: StatusBar, private platformService: PlatformService) {}

  public initializeStatusBar(): void {
    this.statusBar.styleDefault();
    this.statusBarColor = StatusBarColor.DarkText;

    if (this.platformService.isIos) {
      this.statusBar.overlaysWebView(true);
    }

    if (this.platformService.isAndroid) {
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#000000');
    }
  }

  public getStatusBarTextColor(): StatusBarColor {
    return this.statusBarColor;
  }

  public setStatusBarTextColor(color: StatusBarColor): void {
    color === StatusBarColor.DarkText ? this.useDarkTextColor() : this.useLightTextColor();
  }

  public useLightTextColor(): void {
    // For now we only want to update the color
    // when using the app on iOS
    // https://bilbayt.atlassian.net/browse/CA-1705
    if (this.platformService.isIos) {
      this.statusBar.styleLightContent();
      this.statusBarColor = StatusBarColor.LightText;
    }
  }

  public useDarkTextColor(): void {
    // For now we only want to update the color
    // when using the app on iOS
    // https://bilbayt.atlassian.net/browse/CA-1705
    if (this.platformService.isIos) {
      this.statusBar.styleDefault();
      this.statusBarColor = StatusBarColor.DarkText;
    }
  }
}
