import { Injectable } from '@angular/core';

import { AccountService } from './account.service';
import { SettingsService } from './settings.service';
import { PlatformService } from './ssr/platform.service';
import { StateService } from './state.service';

@Injectable({ providedIn: 'root' })
export class SessionService {
  public alreadyVerifiedSelectedAddress = false;

  constructor(
    private stateService: StateService,
    private accountService: AccountService,
    private platformService: PlatformService,
    private settingsService: SettingsService,
  ) {}

  public shouldShowRateBilbaytPrompt(): boolean {
    if (this.platformService.isBrowser) {
      return false;
    }

    // Show it to any user that has 3 or more sessions
    // on the app and we didn't show it before
    return !this.stateService.state?.rateBilaytPromptShown && this.stateService.state?.sessionsCount >= 3;
  }

  public markRateBilbaytAsAlreadyShown(): void {
    this.stateService.update({ rateBilaytPromptShown: true });
  }

  public shouldVerifySelectedAddress(): boolean {
    return (
      this.settingsService.isVerifyAddressBasedOnCoordinatesFeatureEnabled() &&
      !this.alreadyVerifiedSelectedAddress &&
      this.accountService.isLoggedIn() &&
      this.platformService.isCordova
    );
  }

  public markSelectedAddressAsVerified(): void {
    this.alreadyVerifiedSelectedAddress = true;
  }

  public isFirstSession(): boolean {
    return !!this.stateService.state?.isFirstSession;
  }
}
