<div class="inner-content">
  <ng-container *ngIf="isDatePickerLoading">
    <div class="loading-spinner">
      <ion-spinner duration="1000" name="dots"></ion-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="!isDatePickerLoading && calendar">
    <div class="date-time-picker">
      <div class="calendar">
        <div class="calendar-header">
          <div tappable (click)="onShowPrevMonth()" class="calendar-header-prev" [class.disabled]="!calendar.isPrevAvailable">
            <ion-icon class="calendar-header-icon" src="/assets/custom-icons/vendors-page/chevron.svg"></ion-icon>
          </div>
          <p class="calendar-header-title">{{ calendar.monthName }} {{ calendar.year }}</p>
          <div tappable (click)="onShowNextMonth()" class="calendar-header-next" [class.disabled]="!calendar.isNextAvailable">
            <ion-icon class="calendar-header-icon" src="/assets/custom-icons/vendors-page/chevron.svg"></ion-icon>
          </div>
        </div>

        <div class="calendar-body">
          <p *ngFor="let weekDayName of calendar.weekDayNames" class="calendar-body-cell week-name">{{ weekDayName }}</p>
          <div *ngFor="let emptyCell of calendar.daysBeforeStartDay" class="calendar-body-cell"></div>
          <div
            *ngFor="let day of calendar.days; let i = index"
            class="calendar-body-cell"
            [class.today]="day.isToday"
            [class.disabled]="day.isBeforeToday"
            [class.selected]="i === dateSelectedIndex"
          >
            <p tappable class="calendar-body-cell-content" (click)="onUpdateSelectedDate(i)">{{ day.value }}</p>
          </div>
        </div>
      </div>

      <div class="date-time-picker-footer">
        <div
          class="time-picker"
          @animatedTimePicker
          [@.disabled]="!enableFooterAnimations"
          *ngIf="dateSelectedIndex !== null && dateSelectedIndex !== undefined"
        >
          <select
            tappable
            class="time-picker-select"
            *ngIf="timeOptions && timeOptions.length"
            [ngModel]="timeSelectedIndex"
            (ngModelChange)="onUpdateSelectedTime($event)"
          >
            <ng-container *ngFor="let timeOption of timeOptions; let i = index; trackBy: identifyTimeOption">
              <option [value]="i">{{ timeOption.text }}</option>
            </ng-container>
          </select>
          <ion-icon class="time-picker-icon" src="/assets/custom-icons/address-page/chevron.svg"></ion-icon>
        </div>

        <ion-button color="green" expand="block" class="select-button custom-button" (click)="onHandleSubmitClick()">
          <span *ngIf="newIsOrderForNowSelected || newSelectedDateTime">
            {{ "SELECT" | translate }}

            <span class="selected-date-time">
              {{
                newIsOrderForNowSelected
                  ? ("ORDER_FOR_NOW" | translate)
                  : (newSelectedDateTime | formatDateTime : { format: "MMM D h:mm A", useRelativeDate: false })
              }}
            </span>
          </span>

          <span *ngIf="!newIsOrderForNowSelected && !newSelectedDateTime">
            {{ "AREA_DATE_TIME_PICKER.ERROR_EMPTY_DATE_TIME" | translate }}
          </span>
        </ion-button>
      </div>
    </div>
  </ng-container>
</div>
