import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { AreasByProvinceComponent } from './areas-by-province.component';

@NgModule({
  declarations: [AreasByProvinceComponent],
  imports: [CommonModule, IonicModule, TranslateModule.forChild()],
  exports: [AreasByProvinceComponent],
})
export class AreasByProvinceComponentModule {}
