import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { BrowserDateTimePickerModalPage } from './browser-date-time-picker-modal.page';

import { PipesModule } from '../../shared/pipes/pipes.module';

@NgModule({
  declarations: [BrowserDateTimePickerModalPage],
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule.forChild(), PipesModule],
  exports: [BrowserDateTimePickerModalPage],
})
export class BrowserDateTimePickerModalPageModule {}
