<ion-content>
  <div class="modal-container">
    <div class="modal-content">
      <div class="form-container">
        <form class="custom-form" #form="ngForm" #formElement (ngSubmit)="onSubmit()" [formGroup]="wishlistForm">
          <ion-list class="custom-form-wrapper">
            <ion-item
              class="custom-form-item"
              [class.with-error-background]="
                !!wishlistForm.controls.name.errors?.required && (wishlistForm.controls.name.touched || submitAttempt)
              "
            >
              <ion-input
                #nameInputElement
                type="text"
                formControlName="name"
                class="custom-form-item-input"
                [placeholder]="'EDIT_WISHLIST_MODAL_PAGE.LIST_NAME_PLACEHOLDER' | translate"
              ></ion-input>
            </ion-item>
          </ion-list>

          <!--
            Hidden button to make the GO button submit the form,
            since the button is in the footer (outside the form tag)
          -->
          <button
            type="submit"
            style="visibility: hidden; width: 1px; height: 1px; padding: 0; margin: 0; position: absolute; top: 0"
          ></button>
        </form>
      </div>

      <div class="footer">
        <ion-row>
          <ion-col class="ion-no-padding">
            <ion-button expand="block" (click)="onDismiss()" color="grey" class="custom-button">{{ "CANCEL" | translate }}</ion-button>
          </ion-col>
          <ion-col class="ion-no-padding">
            <ion-button
              color="green"
              expand="block"
              class="custom-button"
              (mousedown)="$event.preventDefault(); form.ngSubmit.emit($event)"
            >
              <ng-container *ngIf="currentName">{{ "SAVE" | translate }}</ng-container>
              <ng-container *ngIf="!currentName">{{ "CREATE" | translate }}</ng-container>
            </ion-button>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </div>
</ion-content>
