import { Injectable } from '@angular/core';

import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx';

import { BehaviorSubject, filter, Observable, Subject } from 'rxjs';

import { InAppMessagePermission } from '../../enums/in-app-message-permission.enum';

import { InAppMessage } from '../../models/in-app-message.model';
import { PushNotification } from '../../models/push-notification.model';

import { EnvironmentService } from '../environment.service';
import { LoggerService } from '../logger.service';
import { WindowService } from '../ssr/window.service';

// IMPORTANT: according to the plugin wrapper most of the methods return a
// promise but the inner implementation doesn't accept any callbacks so we
// should treat them as sync methods

interface CleverTapPushNotification {
  customExtras: {
    campaignId?: number;
    type?: number;
    serviceTypes?: number;
    catererId?: number;
    menuItemId?: number;
    orderId?: number;
    marketingCollectionId?: number;
    wishlistId?: number;

    // This is a custom property that could include
    // a branch link
    deeplink?: string;

    // This can be a stringified object containing the
    // push notification extra data coming from the backend
    data?: string;
  };
}

interface CleverTapInAppMessage {
  customExtras: {
    deeplink?: string;
    permission?: InAppMessagePermission;
  };
}

@Injectable({ providedIn: 'root' })
export class CleverTapPlugin {
  private onNotificationReceived$ = new Subject<PushNotification>();
  private onInAppMessageReceived$ = new Subject<InAppMessage>();

  private onCleverTapPushNotificationTappedWithCustomExtras$ = new BehaviorSubject<Event>(undefined);

  constructor(
    private cleverTap: CleverTap,
    private windowService: WindowService,
    private loggerService: LoggerService,
    private environmentService: EnvironmentService,
  ) {
    // Start listening events related to push notifications from CleverTap
    // as soon as possible to avoid missing events emitted too soon
    // https://bilbayt.atlassian.net/browse/CA-2727
    if (this.windowService.isWindowDefined) {
      this.windowService.window.document.addEventListener('onCleverTapPushNotificationTappedWithCustomExtras', (event: Event) => {
        // The payload could be included in the details property from the event or
        // directly in the event depending on the platform
        if ('detail' in event) {
          const eventDetails = (event as unknown as { detail: Record<string, unknown> }).detail;
          event = { ...event, ...eventDetails };
        }

        this.onCleverTapPushNotificationTappedWithCustomExtras$.next(event);
      });
    }
  }

  public initialize(): void {
    try {
      void this.cleverTap.setDebugLevel(this.environmentService.environmentName === 'Production' ? 0 : 3);
      void this.cleverTap.notifyDeviceReady();
      void this.cleverTap.enablePersonalization();
    } catch (error: unknown) {
      this.loggerService.info({
        component: 'ClevertapPlugin',
        message: "couldn't initialize push notification",
        details: { error },
        fromPlugin: true,
      });
    }
  }

  public recordEventWithName(name: string): void {
    try {
      void this.cleverTap.recordEventWithName(name);
    } catch (error: unknown) {
      this.loggerService.info({
        component: 'ClevertapPlugin',
        message: `couldn't record event with name: ${name}`,
        details: { error },
        fromPlugin: true,
      });
    }
  }

  public recordEventWithNameAndProps(name: string, props: Record<string, string | number | boolean>): void {
    try {
      void this.cleverTap.recordEventWithNameAndProps(name, props);
    } catch (error: unknown) {
      this.loggerService.info({
        component: 'ClevertapPlugin',
        message: `couldn't record event with name and props: ${name}`,
        details: { error },
        fromPlugin: true,
      });
    }
  }

  public identifyUser(userDetails: Record<string, unknown>): void {
    try {
      void this.cleverTap.onUserLogin(userDetails);
    } catch (error: unknown) {
      this.loggerService.info({ component: 'ClevertapPlugin', message: "couldn't identify user", details: { error }, fromPlugin: true });
    }
  }

  public setProfileProperties(userDetails: Record<string, unknown>): void {
    try {
      void this.cleverTap.profileSet(userDetails);
    } catch (error: unknown) {
      this.loggerService.info({ component: 'ClevertapPlugin', message: "couldn't identify user", details: { error }, fromPlugin: true });
    }
  }

  public enablePushNotifications(): void {
    try {
      void this.cleverTap.registerPush();
    } catch (error: unknown) {
      this.loggerService.info({
        component: 'ClevertapPlugin',
        message: "couldn't enable push notifications",
        details: { error },
        fromPlugin: true,
      });
    }
  }

  public subscribeToPushNotificationEvents(): void {
    this.onCleverTapPushNotificationTappedWithCustomExtras$.pipe(filter((event) => !!event)).subscribe((event: Event) => {
      this.onNotificationReceived$.next(this.mapCleverTapNotificationToPushNotification(event as unknown as CleverTapPushNotification));
    });

    try {
      void this.cleverTap.createNotificationChannel('default', 'Default Channel', 'Default channel for push notifications', 5, true);
    } catch (error: unknown) {
      this.loggerService.info({
        component: 'ClevertapPlugin',
        message: "couldn't register for push notifications",
        details: { error },
        fromPlugin: true,
      });
    }
  }

  public subscribeToInAppEvents(): void {
    if (this.windowService.isWindowDefined) {
      this.windowService.window.document.addEventListener('onCleverTapInAppButtonClick', (event: Event) => {
        this.onInAppMessageReceived$.next(this.mapCleverTapInAppMessageToInAppMessage(event as unknown as CleverTapInAppMessage));
      });
    }
  }

  public getDeviceId(): Promise<string> {
    return this.cleverTap.getCleverTapID() as Promise<string>;
  }

  public onMessageReceived(): Observable<PushNotification> {
    return this.onNotificationReceived$.asObservable();
  }

  public onInAppMessageReceived(): Observable<InAppMessage> {
    return this.onInAppMessageReceived$.asObservable();
  }

  private mapCleverTapNotificationToPushNotification(notification: CleverTapPushNotification): PushNotification {
    if (!notification || !notification.customExtras) {
      return null;
    }

    let notificationData = notification.customExtras;

    if (notificationData.data) {
      try {
        // The notification data property is stringified
        // in Android but it's an object on iOS
        if (typeof notificationData.data === 'string') {
          notificationData = { ...notificationData, ...(JSON.parse(notificationData.data) as Record<string, string>) };
        } else if (typeof notificationData.data === 'object') {
          notificationData = { ...notificationData, ...(notificationData.data as Record<string, string>) };
        }
      } catch (error) {}
    }

    return {
      type: notificationData.type ? +notificationData?.type : null,
      campaignId: notificationData.campaignId ? +notificationData.campaignId : null,
      serviceTypes: notificationData.serviceTypes ? +notificationData.serviceTypes : null,
      catererId: notificationData.catererId ? +notificationData.catererId : null,
      menuItemId: notificationData.menuItemId ? +notificationData.menuItemId : null,
      orderId: notificationData.orderId ? +notificationData.orderId : null,
      marketingCollectionId: notificationData.marketingCollectionId ? +notificationData.marketingCollectionId : null,
      wishlistId: notificationData.wishlistId ? +notificationData.wishlistId : null,
      deeplink: notificationData.deeplink ? notificationData.deeplink : null,
    };
  }

  private mapCleverTapInAppMessageToInAppMessage(inAppMessage: CleverTapInAppMessage): InAppMessage {
    if (!inAppMessage || !inAppMessage.customExtras) {
      return null;
    }

    const inAppMessageData = inAppMessage.customExtras;

    return {
      deeplink: inAppMessageData.deeplink ? inAppMessageData.deeplink : null,
      permission: inAppMessageData.permission ? inAppMessageData.permission : null,
    };
  }
}
