import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { Address } from '../../../core/models/address.model';

import { Helpers } from '../../../core/helpers';

import { AnimationState } from '../../../core/animations';
import { SavedAddressesComponentAnimations } from './saved-addresses.animations';

@Component({
  selector: 'app-saved-addresses',
  templateUrl: './saved-addresses.component.html',
  styleUrls: ['./saved-addresses.component.scss'],
  animations: [...SavedAddressesComponentAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SavedAddressesComponent implements OnInit {
  @Input()
  public selected: Address;

  @Output()
  public selectAddress = new EventEmitter<Address>();

  public AnimationState: typeof AnimationState = AnimationState;
  public savedAddressesToShow: Array<Address>;
  public currentAnimationState: string = AnimationState.Expanded;

  private enteredText = '';
  private allSavedAddresses: Array<Address> = [];

  @Input()
  public set query(query: string) {
    this.enteredText = query ? query.toLowerCase() : '';
    this.updateVisibleAddresses();
  }

  @Input()
  public set savedAddresses(savedAddresses: Array<Address>) {
    this.allSavedAddresses = savedAddresses;
    this.updateVisibleAddresses();
  }

  ngOnInit() {
    this.currentAnimationState = AnimationState.Expanded;
  }

  public onToggleContent(): void {
    this.currentAnimationState =
      this.currentAnimationState === AnimationState.Collapsed ? AnimationState.Expanded : AnimationState.Collapsed;
  }

  public onSelect(savedAddress: Address): void {
    this.selectAddress.emit(savedAddress);
  }

  private updateVisibleAddresses(): void {
    if (this.enteredText && this.allSavedAddresses && this.allSavedAddresses.length) {
      this.savedAddressesToShow = this.allSavedAddresses.filter(
        (savedAddress) => savedAddress.area.name.toLowerCase().indexOf(this.enteredText) > -1,
      );
    } else {
      this.savedAddressesToShow = this.allSavedAddresses
        ? this.allSavedAddresses.map((address) => Helpers.clone(address))
        : new Array<Address>();
    }
  }
}
