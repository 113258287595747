import { OperatorFunction, pipe } from 'rxjs';

import { AppState } from '../models/app-state.model';

import { filterEmptyArray } from './filter-empty-array';
import { select, SelectorConfig } from './select';

export function selectNotEmptyArray<T extends AppState, R>(mapFn: (state: T) => R, config?: SelectorConfig<R>): OperatorFunction<T, R> {
  return pipe(select(mapFn, config ? config : undefined), filterEmptyArray());
}
