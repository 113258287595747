import { EnvironmentName } from '../app/core/enums/environment-name.enum';

export const environment = {
  production: true,
  name: EnvironmentName.Production,
  apiUrl: 'https://api.bilbayt.com',
  websiteUrl: 'https://bilbayt.com',
  authApiUrl: 'https://auth.bilbayt.com',
  apiClientId: 'aGLnIwiv',
  apiClientSecret: 'vbRzuxn6DjPjfFsx5Y1C',
  mixpanelAnalyticsTrackerId: 'f103f05ad7f53f41e57a5f26e3394a59',
  googleMapsApiKey: 'AIzaSyAIrBEuOvO2E4oOdksukRhbQcanM__XtIA',
  sentryProjectName: 'app-customer',
  sentryDSN: 'https://5b66167fe4e4df89509ac2a22fa0a938@o4507570772705280.ingest.de.sentry.io/4507570810781776',
  translationsUrl: 'https://cdn.bilbayt.com/i18n/CustomerApp',
  initialSettingsUrl: 'https://cdn.bilbayt.com/settings/CustomerApp',
  vendorsSlugsUrl: 'https://cdn.bilbayt.com/vendor-files/vendor-slugs.json',
  googleAuthClientId: '605888069527-a4jq0o0icmr11tl769jot573fvfp45v8.apps.googleusercontent.com',
  applePayMerchantId: 'merchant.com.bilbayt.bilbayt-prod',
  branchKey: 'key_live_bktWv08gw7z6urJ1xZwZYgbmBviajhSJ',
  cleverTapAccountId: 'RZ7-766-576Z',
  isCypressTestEnv: false,
  isSimulatingCordovaPlatform: false,
};
