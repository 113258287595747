import { Pipe, PipeTransform } from '@angular/core';

import { PricingMethod } from '../../core/enums/pricing-method.enum';
import { SelectionInput } from '../../core/enums/selection-input.enum';

@Pipe({ name: 'adjustBasedOnQuantity' })
export class AdjustBasedOnQuantityPipe implements PipeTransform {
  public transform(value: number, menuItemQuantity: number, pricingMethod: PricingMethod, type?: SelectionInput): number {
    return pricingMethod === PricingMethod.Quantity && (!type || type === SelectionInput.PlusMinusSelector)
      ? menuItemQuantity * value
      : value;
  }
}
