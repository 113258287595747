import { Injectable } from '@angular/core';

export type WindowRef = Window;

@Injectable()
export class WindowService {
  public get isWindowDefined(): boolean {
    return !!window;
  }

  public get window(): WindowRef {
    return window as unknown as WindowRef;
  }
}
