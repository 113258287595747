import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  ViewRef,
} from '@angular/core';

// TODO: we should update this component so that the placeholderMargin and the
// borderRadious are inputs so that we don't need to apply a fix on every page
// like the following:
// Fix for border radius issue in Safari
// .animated-image-content {
//   overflow: hidden;
//   border-radius: 8px;
// }

@Component({
  selector: 'app-animated-image',
  templateUrl: './animated-image.component.html',
  styleUrls: ['./animated-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnimatedImageComponent implements OnChanges {
  @ViewChild('image', { read: ElementRef, static: true })
  public image: ElementRef<HTMLElement>;

  @Input()
  public imageUrl: string;

  @Input()
  public showForeground: boolean;

  @Input()
  public showTopForeground: boolean;

  constructor(private renderer: Renderer2, private changeDetectorRef: ChangeDetectorRef) {
    this.changeDetectorRef.detach();
  }

  public get imageHtml(): HTMLElement {
    return this.image ? this.image.nativeElement : null;
  }

  ngOnChanges(changes: SimpleChanges) {
    const imageUrlChange = changes.imageUrl;

    if (imageUrlChange?.firstChange) {
      // If we need to show the foreground we need to run
      // a new change detection cycle
      if (this.showForeground || this.showTopForeground) {
        this.changeDetectorRef.detectChanges();
      }
    }

    if (imageUrlChange.currentValue) {
      // Load the menu item image in the background
      // to avoid showing "parts" of that image when
      // loading the menu item
      this.preloadImage();
    }
  }

  private preloadImage(): void {
    const image = new Image();

    image.onload = () => {
      try {
        const notDestroyed = this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed;

        if (notDestroyed) {
          this.renderer.removeClass(this.image.nativeElement, 'animated-image-content-hidden');
          this.changeDetectorRef.detectChanges();
        } else {
          // eslint-disable-next-line no-console
          console.info(`Tried to modify image already destroyed ${this.imageUrl}`);
        }
      } catch (error) {}
    };

    // Do nothing in case of errors, since the
    // default image is already shown
    image.onerror = () => {};

    // Start loading the image in the background
    image.src = this.imageUrl;
  }
}
