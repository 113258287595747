import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { PermissionAuthorizationStatus } from './native-plugins/diagnostic.plugin';
import { FirebasePlugin } from './native-plugins/firebase.plugin';

import { from, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Address } from '../models/address.model';
import { HttpQueryStringParams } from '../models/http-query-string-param.model';
import { LanguageCode } from '../models/language.model';
import { LatLng } from '../models/latlng.model';
import { LogIn } from '../models/login.model';
import { Register } from '../models/register.model';
import { SocialLoginUser } from '../models/social-login-user.model';
import { IncomingTokenDetails, TokenDetails } from '../models/token-details.model';
import { UserRewards } from '../models/user-rewards.model';
import { User } from '../models/user.model';
import { Wallet } from '../models/wallet.model';
import { WishlistDetails } from '../models/wishlist-details.model';
import { WishlistItem } from '../models/wishlist-item.model';
import { Wishlist } from '../models/wishlist.model';

import { AddressService } from './address.service';
import { AppEventsService } from './app-events.service';
import { CartService } from './cart.service';
import { DateTimeService } from './date-time.service';
import { EnvironmentService } from './environment.service';
import { GeolocationService } from './geolocation.service';
import { SettingsService } from './settings.service';
import { StateService } from './state.service';

import { AppConfig, TOKEN_CONFIG } from '../config/app.config';

import { Helpers } from '../helpers';

import { HttpParameterEncoder } from '../http-parameter-encoder';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private savedAddresses: Map<string, Array<Address>>;

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private cartService: CartService,
    private environmentService: EnvironmentService,
    private appEventsService: AppEventsService,
    private addressService: AddressService,
    private dateTimeService: DateTimeService,
    private stateService: StateService,
    private firebasePlugin: FirebasePlugin,
    private geolocationService: GeolocationService,
    @Inject(TOKEN_CONFIG) private config: AppConfig,
  ) {
    this.savedAddresses = new Map<string, Array<Address>>();

    this.settingsService.countryLanguage$
      .pipe(
        filter(({ changedLanguage }) => !!changedLanguage),
        switchMap(() => this.saveUserPreferredLanguage(this.settingsService.getLanguage().value)),
        catchError(() => of(true)),
      )
      .subscribe();
  }

  public get userDetails(): User {
    return Helpers.clone(this.stateService.state?.user);
  }

  public get userTokenDetails(): TokenDetails {
    return Helpers.clone(this.stateService.state?.token);
  }

  public isLoggedIn(): boolean {
    const token = this.stateService.state?.token;
    return !!this.stateService.state?.user && !!token && !!token?.accessToken && !!token?.refreshToken;
  }

  public signInUserWithApple(): Promise<string> {
    return this.firebasePlugin.getFirebaseTokenAfterAuthenticatingWithApple();
  }

  public signInUserWithGoogle(): Promise<string> {
    return this.firebasePlugin.getFirebaseTokenAfterAuthenticatingWithGoogle();
  }

  public logIn(model: LogIn): Observable<User> {
    return this.logInAndGetUserDetails(this.getEmailPasswordLoginBody(model));
  }

  public logInWithFirebaseToken(firebaseToken: string): Observable<SocialLoginUser> {
    return this.logInAndGetUserDetails(this.getFirebaseLoginBody(firebaseToken));
  }

  public getUserDetails(): Observable<User> {
    const getUserUrl = this.config.routes.getUser.url;
    return this.http.get<User>(getUserUrl).pipe(
      map((user) => {
        if (!user) {
          return {} as User;
        }

        // Backwards compat for old users
        if (user.phoneNumber && !user.phone) {
          user.phone = {
            countryCode: user.phoneNumber.split(' ')[0],
            localNumber: user.phoneNumber.split(' ')[1],
          };
        }

        return user;
      }),
    );
  }

  public deleteUserAccount(): Observable<void> {
    const deleteUserUrl = this.config.routes.putDeleteUser.url;
    return this.http.put<void>(deleteUserUrl, {}).pipe(map(() => this.logOut()));
  }

  public getUnsavedAddresses(): Array<Address> {
    const selectedAddress = this.stateService.state?.address;
    return this.addressService.isNew(selectedAddress) && !this.addressService.createdFromArea(selectedAddress) ? [selectedAddress] : [];
  }

  public getUserSavedAddresses(includeUnsavedAddresses: boolean = true): Observable<Array<Address>> {
    const country = this.settingsService.getCountry().code;
    const language = this.settingsService.getLanguage().value;

    if (!this.isLoggedIn()) {
      return of([...this.getUnsavedAddresses()]);
    }

    const cachedSavedAddresses = this.savedAddresses.get(`${language}-${country}`);
    const unsavedAddresses = includeUnsavedAddresses ? this.getUnsavedAddresses() : [];

    if (cachedSavedAddresses?.length) {
      const cachedAddresses = cachedSavedAddresses ? cachedSavedAddresses.map((address) => Helpers.clone(address)) : new Array<Address>();
      const addresses = [...unsavedAddresses, ...cachedAddresses];

      return of(addresses);
    } else {
      const url = this.config.routes.getUserSavedAddresses.url;

      return this.http.get<Array<Address>>(url).pipe(
        map((savedAddresses) => (savedAddresses ? savedAddresses.map((address) => Helpers.clone(address)) : new Array<Address>())),
        // Keep a local copy of the addresses so next time we
        // don't need to get them from the server
        tap((savedAddresses) => this.savedAddresses.set(`${language}-${country}`, savedAddresses)),
        map((addresses: Address[]) => [...unsavedAddresses, ...addresses]),
      );
    }
  }

  public getUserSavedAddressesBasedOnCoordinates(): Observable<{ coordinates: LatLng; addresses: Array<Address> }> {
    const emptyResponse: { coordinates: LatLng; addresses: Array<Address> } = { coordinates: { lat: null, lng: null }, addresses: [] };

    return from(this.geolocationService.getLocationServicesAuthorizationStatus()).pipe(
      switchMap((status) => {
        if (status !== PermissionAuthorizationStatus.RequestedAndAccepted) {
          return of(emptyResponse);
        }

        return from(this.geolocationService.getCurrentPosition()).pipe(
          switchMap((result) => {
            if (!result.coordinates?.lat || !result.coordinates?.lng) {
              return of(emptyResponse);
            }

            const url = this.config.routes.getUserSavedAddresses.url;
            const params: HttpQueryStringParams = {
              latitude: result.coordinates.lat.toString(),
              longitude: result.coordinates.lng.toString(),
            };

            return this.http.get<Array<Address>>(url, { params }).pipe(
              map((addresses) => ({
                addresses,
                coordinates: {
                  lat: result.coordinates.lat,
                  lng: result.coordinates.lng,
                },
              })),
              catchError(() => of(emptyResponse)),
            );
          }),
        );
      }),
    );
  }

  public deleteUserSavedAddresses(savedAddressId: number): Observable<void> {
    const url = this.config.routes.deleteUserSavedAddresses.url.replace('#savedAddressId#', savedAddressId.toString());
    return this.http.delete<void>(url).pipe(
      // Reset the local copy so next time we get the
      // updated data from the server
      tap(() => this.resetSavedAddresses()),
    );
  }

  public createUserSavedAddress(address: Address): Observable<Address> {
    const url = this.config.routes.postUserSavedAddresses.url;
    return this.http.post<Address>(url, address).pipe(
      // Reset the local copy so next time we get the
      // updated data from the server
      tap(() => this.resetSavedAddresses()),
    );
  }

  public editUserSavedAddress(addressId: number, address: Address): Observable<Address> {
    const url = this.config.routes.putUserSavedAddresses.url.replace('#savedAddressId#', addressId.toString());
    return this.http.put<Address>(url, address).pipe(
      tap(() => {
        // We already updated the address in the API but we also need
        // to update the local copy if it's the selected address
        this.cartService.maybeUpdateEditedAddress(address);

        // Reset the local copy so next time we get the
        // updated data from the server
        this.resetSavedAddresses();
      }),
    );
  }

  public register(model: Register): Observable<User> {
    const signUpUrl = this.config.routes.putUserSignUp.url;
    return this.http.put(signUpUrl, model).pipe(switchMap(() => this.logIn({ email: model.email, password: model.password })));
  }

  public refreshToken(): Observable<TokenDetails> {
    const getTokenUrl = this.config.routes.getToken.url;
    const body = this.getRefreshTokenBody();
    const headers = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    };

    return this.http.post(getTokenUrl, body, headers).pipe(
      map((response: IncomingTokenDetails) => {
        const token = this.initializeTokenDetails(response);
        void this.saveTokenInformation(token);
        return token;
      }),
    );
  }

  public getAccessToken(): string {
    return this.stateService.state?.token?.accessToken || null;
  }

  public getRefreshToken(): string {
    return this.stateService.state?.token?.refreshToken || null;
  }

  public logOut(): void {
    this.saveUserInformation(null);
    this.saveTokenInformation(null);
    this.cartService.clearAccountRelatedData();
    this.appEventsService.dispatch('UserLoginStatusChanged', false);
  }

  public resetPassword(email: string): Observable<void> {
    const url = this.config.routes.putUserResetPassword.url;

    // We need to set the following header to avoid Angular to try to parse
    // the response since the backend won't return anything in the body
    // GITHUB ISSUE: https://github.com/angular/angular/issues/18586
    return this.http.put<void>(url, { email }, { responseType: 'text' as 'json' });
  }

  public validateEmail(email: string): Observable<boolean> {
    const url = this.config.routes.getEmailAvailability.url;

    // There's an open issue since 2016 related to some characters
    // being encoded improperly https://github.com/angular/angular/issues/11058
    // so as a workaround we need to use our own encoder class when sending
    // emails as query string parameters
    // https://github.com/angular/angular/issues/18261#issuecomment-338354119
    const params = new HttpParams({ encoder: new HttpParameterEncoder() }).set('email', email);

    return this.http.get<{ available: boolean }>(url, { params }).pipe(map((response) => !!response.available));
  }

  public getUserWallet(): Observable<Wallet> {
    const user = this.stateService.state?.user;

    const emptyWallet: Wallet = {
      balance: 0,
      walletTransactions: [],
      country: this.settingsService.getCountry().value,
      email: user?.email ?? '',
      userId: user?.userId ?? '',
      phone: user?.phone ?? null,
      name: user ? `${user.firstName} ${user.lastName}` : '',
    };

    if (!this.isLoggedIn()) {
      return of(emptyWallet);
    }

    const url = this.config.routes.getUserWallet.url;

    return this.http.get<Wallet>(url).pipe(catchError(() => of(emptyWallet)));
  }

  public getUserWishlists(): Observable<Array<Wishlist>> {
    const url = this.config.routes.getUserWishlists.url;
    return this.http.get<Array<Wishlist>>(url);
  }

  public getUserWishlistsLastModifiedUtc(wishlistId?: number): Observable<{ lastModifiedUtc: string }> {
    const url = this.config.routes.getUserWishlistsLastModified.url;

    return wishlistId
      ? this.http.get<{ lastModifiedUtc: string }>(url, { params: { wishListId: wishlistId } })
      : this.http.get<{ lastModifiedUtc: string }>(url);
  }

  public joinWishlist(wishlistId: number): Observable<void> {
    const url = this.config.routes.putJoinWishlist.url.replace('#wishlistId#', wishlistId.toString());
    return this.http.put<void>(url, {});
  }

  public leaveWishlist(wishlistId: number): Observable<void> {
    const url = this.config.routes.putLeaveWishlist.url.replace('#wishlistId#', wishlistId.toString());
    return this.http.put<void>(url, {});
  }

  public addItemToWishlist(wishlistId: number, itemDetails: WishlistItem): Observable<void> {
    const url = this.config.routes.putAddItemToWishlist.url.replace('#wishlistId#', wishlistId.toString());
    return this.http.put<void>(url, itemDetails);
  }

  public createWishlist(name: string, itemDetails?: WishlistItem): Observable<void> {
    const url = this.config.routes.postNewWishlist.url;
    return this.http.post<void>(url, { title: name, wishListItems: itemDetails ? [itemDetails] : undefined });
  }

  public getWishlistDetails(wishlistId: number): Observable<WishlistDetails> {
    const url = this.config.routes.getUserWishlistDetails.url.replace('#wishlistId#', wishlistId.toString());
    return this.http.get<WishlistDetails>(url);
  }

  public updateWishlistItems(wishlist: WishlistDetails): Observable<WishlistDetails> {
    const url = this.config.routes.putUpdateWishlist.url.replace('#wishlistId#', wishlist.wishListId.toString());

    const items: Array<WishlistItem> = [];

    wishlist.details.caterers.forEach((vendor) => {
      vendor.items.forEach((item) => {
        items.push({
          itemId: item.menuItemId,
          quantity: item.quantity,
          specialRequests: item.specialRequests,
          femaleService: item.femaleServers,
          options: item.options.map((option) => ({ optionId: option.menuItemOptionId, quantity: option.quantity })),
          addons: item.addOns.map((addOn) => ({ addonId: addOn.menuItemAddOnId, quantity: addOn.quantity })),
        });
      });
    });

    return this.http.put<void>(url, { items }).pipe(switchMap(() => this.getWishlistDetails(wishlist.wishListId)));
  }

  public renameWishlist(wishlistId: number, newName: string): Observable<void> {
    const url = this.config.routes.putRenameWishlist.url.replace('#wishlistId#', wishlistId.toString());
    return this.http.put<void>(url, { title: newName });
  }

  public deleteWishlist(wishlistId: number): Observable<void> {
    const url = this.config.routes.deleteUserWishlist.url.replace('#wishlistId#', wishlistId.toString());
    return this.http.delete<void>(url);
  }

  public getUserRewards(): Observable<UserRewards> {
    const url = this.config.routes.getUserRewards.url;
    return this.http.get<UserRewards>(url);
  }

  public resetSavedAddresses(): void {
    this.savedAddresses = new Map<string, Array<Address>>();
  }

  public getUserSavedAddressById(savedAddressId: number): Observable<Address> {
    return this.getUserSavedAddresses().pipe(
      map((savedAddresses) => {
        let result: Address = null;

        for (const address of savedAddresses) {
          if (address.savedAddressId === savedAddressId) {
            result = address;
            break;
          }
        }

        return result;
      }),
    );
  }

  public saveUserPreferredLanguage(language: LanguageCode): Observable<void> {
    if (!this.isLoggedIn()) {
      return of(null);
    }

    const url = this.config.routes.putUserPreferredLanguage.url;
    return this.http.put<void>(url, { language });
  }

  public checkMissingPersonalInfo(): { hasValidFirstName: boolean; hasValidLastName: boolean; hasValidPhoneNumber: boolean } {
    const user = this.stateService.state?.user;

    if (!user) {
      return { hasValidFirstName: false, hasValidLastName: false, hasValidPhoneNumber: false };
    }

    const hasFirstName = user.firstName && user.firstName !== this.userDetails.email;
    const hasLastName = user.lastName && user.lastName.replace(/-/g, '') !== '';
    const hasPhoneNumber =
      user.phone && user.phone.countryCode && user.phone.localNumber && user.phone.localNumber.replace(/0/g, '') !== '';

    return { hasValidFirstName: !!hasFirstName, hasValidLastName: !!hasLastName, hasValidPhoneNumber: !!hasPhoneNumber };
  }

  public refreshUserDetails(): Observable<void> {
    if (!this.isLoggedIn()) {
      return of(null);
    }

    return this.getUserDetails().pipe(
      map((userDetails) => {
        this.saveUserInformation(userDetails);
      }),
    );
  }

  private saveUserInformation(userDetails: User): void {
    this.stateService.update({ user: userDetails });
  }

  private saveTokenInformation(tokenDetails: TokenDetails): void {
    this.stateService.update({ token: tokenDetails });
  }

  private getRefreshTokenBody(): string {
    const grantType = 'grant_type=refresh_token';
    const tokenData = `refresh_token=${this.stateService.state?.token?.refreshToken}`;
    const clientId = `client_id=${this.environmentService.apiClientId}`;
    const clientSecret = `client_secret=${this.environmentService.apiClientSecret}`;
    const scope = 'scope=openid profile offline_access api';
    return `${grantType}&${tokenData}&${clientId}&${clientSecret}&${scope}`;
  }

  private getEmailPasswordLoginBody(model: LogIn): string {
    const username = encodeURIComponent(model.email);
    const password = encodeURIComponent(model.password);
    const grantType = 'grant_type=password';
    const userData = `username=${username}&password=${password}`;
    const clientId = `client_id=${this.environmentService.apiClientId}`;
    const clientSecret = `client_secret=${this.environmentService.apiClientSecret}`;
    const scope = 'scope=openid profile offline_access api';
    return `${grantType}&${userData}&${clientId}&${clientSecret}&${scope}`;
  }

  private getFirebaseLoginBody(firebaseToken: string): string {
    const username = 'firebase';
    const password = '';
    const userData = `username=${username}&password=${password}&firebase_token=${firebaseToken}`;
    const grantType = 'grant_type=password';
    const clientId = `client_id=${this.environmentService.apiClientId}`;
    const clientSecret = `client_secret=${this.environmentService.apiClientSecret}`;
    const scope = 'scope=openid profile offline_access api';
    return `${grantType}&${userData}&${clientId}&${clientSecret}&${scope}`;
  }

  private logInAndGetUserDetails(body: string): Observable<SocialLoginUser> {
    const getTokenUrl = this.config.routes.getToken.url;
    const headers = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    };

    return this.http.post<IncomingTokenDetails>(getTokenUrl, body, headers).pipe(
      mergeMap((apiTokenDetails) =>
        this.saveTokenDetailsAndGetUserDetails(apiTokenDetails).pipe(
          map((userDetails) => ({
            ...userDetails,
            isNewUser: !!apiTokenDetails?.new_user,
          })),
        ),
      ),
    );
  }

  private initializeTokenDetails(object: IncomingTokenDetails): TokenDetails {
    const tokenDetails = {} as TokenDetails;

    if (!object) {
      return tokenDetails;
    }

    return {
      ...tokenDetails,
      accessToken: object.access_token,
      refreshToken: object.refresh_token,
      expiresIn: object.expires_in,
      timestamp: this.dateTimeService.now.toDate().getTime(),
    };
  }

  private saveTokenDetailsAndGetUserDetails(apiTokenDetails: IncomingTokenDetails): Observable<User> {
    const tokenDetails = this.initializeTokenDetails(apiTokenDetails);

    void this.saveTokenInformation(tokenDetails);

    return this.getUserDetails().pipe(
      map((userDetails) => {
        this.saveUserInformation(userDetails);

        // Publish an event to update side menu and any other required component
        this.appEventsService.dispatch('UserLoginStatusChanged', true);

        return userDetails;
      }),
    );
  }
}
