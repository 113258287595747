/// <reference types="@types/google.maps" />

import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { DiagnosticPlugin, PermissionAuthorizationStatus } from './native-plugins/diagnostic.plugin';
import { GeolocationPlugin } from './native-plugins/geolocation.plugin';
import { LocationAccuracyPlugin } from './native-plugins/location-accuracy.plugin';
import { NetworkPlugin } from './native-plugins/network.plugin';

import { GooglePlacesGeocodedResult } from '../models/google-places-geocoded-result.model';
import { GooglePlacesResult } from '../models/google-places-result.model';
import { LatLng } from '../models/latlng.model';

import { EnvironmentService } from './environment.service';
import { SettingsService } from './settings.service';
import { PlatformService } from './ssr/platform.service';
import { WindowService } from './ssr/window.service';

export const GOOGLE_MAPS_SCRIPT_ID = 'googleMaps';
export const GOOGLE_MAPS_CALLBACK_NAME = 'placesInit';

export interface LocationResult {
  coordinates: LatLng | null;
  errorMessage?: string;
}

@Injectable({ providedIn: 'root' })
export class GeolocationService {
  private renderer: Renderer2;
  private geocoder: google.maps.Geocoder;
  private googlePlaces: google.maps.places.AutocompleteService;

  constructor(
    private rendererFactory: RendererFactory2,
    private diagnosticPlugin: DiagnosticPlugin,
    private geolocationPlugin: GeolocationPlugin,
    private locationAccuracyPlugin: LocationAccuracyPlugin,
    private networkPlugin: NetworkPlugin,
    private windowService: WindowService,
    private settingsService: SettingsService,
    private platformService: PlatformService,
    private translateService: TranslateService,
    private environmentService: EnvironmentService,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public getLocationServicesAuthorizationStatus(): Promise<PermissionAuthorizationStatus> {
    return this.diagnosticPlugin.getLocationAuthorizationStatus();
  }

  public requestLocationServicesAuthorization(): Promise<PermissionAuthorizationStatus> {
    return this.diagnosticPlugin.requestLocationAuthorization().then(() => this.getLocationServicesAuthorizationStatus());
  }

  public getCurrentPosition(): Promise<{ coordinates?: LatLng; errorMessage?: string }> {
    return this.diagnosticPlugin
      .getLocationAuthorizationStatus()
      .then((status) => this.handleLocationAuthorizationStatus(status))
      .then(({ coordinates, errorMessage }) => ({
        coordinates: coordinates ?? null,
        errorMessage: coordinates
          ? null
          : errorMessage ?? (this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_UNKNOWN_ERROR') as string),
      }));
  }

  public initializeGooglePlacesSDK(): Promise<boolean> {
    if (this.googlePlaces) {
      return Promise.resolve(true);
    } else if (!this.networkPlugin.isOnline()) {
      return Promise.reject(new Error('No internet connection'));
    } else {
      return this.injectGooglePlacesSDK();
    }
  }

  public getGooglePlacesSuggestions(query: string): Promise<Array<GooglePlacesResult>> {
    const params = {
      input: query,
      componentRestrictions: {
        // Get results only from the current country
        country: [this.settingsService.getCountry().code],
      },
    };

    return new Promise((resolve, reject) => {
      void this.googlePlaces.getPlacePredictions(params, (predictions, status) => {
        switch (status) {
          case google.maps.places.PlacesServiceStatus.OK:
            if (!predictions) {
              predictions = [];
            }

            const results = predictions.map((prediction) => ({
              placeId: prediction.place_id,
              mainText: prediction.structured_formatting.main_text,
              secondaryText: prediction.structured_formatting.secondary_text,
            }));

            resolve(results);
            break;
          case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
            resolve([]);
            break;
          case google.maps.places.PlacesServiceStatus.INVALID_REQUEST:
            reject(this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_INVALID_REQUEST'));
            break;
          case google.maps.places.PlacesServiceStatus.NOT_FOUND:
            reject(this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_NOT_FOUND'));
            break;
          case google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT:
            reject(this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_OVER_QUERY_LIMIT'));
            break;
          case google.maps.places.PlacesServiceStatus.REQUEST_DENIED:
            reject(this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_REQUEST_DENIED'));
            break;
          default:
            reject(this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_PLACES_UNKNOWN_ERROR'));
            break;
        }
      });
    });
  }

  public geocode(params: unknown): Promise<GooglePlacesGeocodedResult> {
    return new Promise((resolve, reject) => {
      void this.geocoder.geocode(params, (results, status) => {
        switch (status) {
          case google.maps.GeocoderStatus.OK:
            const geocodedAddress = this.fromGeocodedResult(results[0]);
            resolve(geocodedAddress);
            break;
          case google.maps.GeocoderStatus.INVALID_REQUEST:
            reject(this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_INVALID_REQUEST'));
            break;
          case google.maps.GeocoderStatus.OVER_QUERY_LIMIT:
            reject(this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_OVER_QUERY_LIMIT'));
            break;
          case google.maps.GeocoderStatus.REQUEST_DENIED:
            reject(this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_REQUEST_DENIED'));
            break;
          case google.maps.GeocoderStatus.ZERO_RESULTS:
            reject(this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_ZERO_RESULTS'));
            break;
          default:
            reject(this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_GEOCODE_UNKNOWN_ERROR'));
            break;
        }
      });
    });
  }

  /* istanbul ignore next */
  public getStaticMapBaseUrl(): string {
    const key = this.environmentService.googleMapsApiKey;
    const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap';

    const markerIconUrl = 'https://cdn.bilbayt.com/assets/map-marker-636748429577111505.png';
    const markerIconUrlCenter = '32,56';

    return `${baseUrl}?center=#lat#,#lng#&scale=2&zoom=#zoom#&size=#width#x#height#&markers=scale:2|anchor:${markerIconUrlCenter}|icon:${markerIconUrl}|#lat#,#lng#&key=${key}`;
  }

  public fromGeocodedResult(data: google.maps.GeocoderResult): GooglePlacesGeocodedResult {
    const result = {} as GooglePlacesGeocodedResult;

    if (!data || !data.address_components) {
      return result;
    }

    let locality: string;

    data.address_components.forEach((dataComponent) => {
      dataComponent.types.forEach((type) => {
        switch (type) {
          case 'street_number':
            result.building = dataComponent.long_name;
            break;
          case 'route':
            result.street = dataComponent.long_name;
            break;
          case 'sublocality':
          case 'sublocality_level_1':
            result.area = dataComponent.long_name;
            break;
          case 'locality':
            locality = dataComponent.long_name;
            break;
        }
      });
    });

    if (!result.area && locality) {
      // If we don't get the area, let's try to use the
      // locality because in some cases what google considers
      // the locality is actually the name of the area
      result.area = locality;
    }

    if (data.geometry?.location) {
      result.lat = data.geometry.location.lat();
      result.lng = data.geometry.location.lng();
    }

    return result;
  }

  private handleLocationAuthorizationStatus(status: PermissionAuthorizationStatus): Promise<LocationResult> {
    switch (status) {
      // The app didn't request access to the location feature yet, so the app can request the user
      // for authorization and then this method will be called again with the response of that request
      case PermissionAuthorizationStatus.CanRequest:
        return this.diagnosticPlugin.requestLocationAuthorization().then((newStatus) => this.handleLocationAuthorizationStatus(newStatus));

      // The user already granted access to the location feature, so:
      // - on iOS there's nothing else to be done, the app can request the user location now
      // - on Android the app should ask for location accuracy to get the best possible results. If the
      //   user doesn't grant permission to the app, the getLocationCoordinatesWithAccuracy() method would
      //   ask the user if he/she wants to be redirected to the Settings page.
      case PermissionAuthorizationStatus.RequestedAndAccepted:
        return this.platformService.isIos ? this.geolocationPlugin.getCurrentPosition() : this.getLocationCoordinatesWithAccuracy();

      // The app asked the user for authorization to access the location feature but he/she didn't allow it.
      // This could also mean that Location Services is OFF on iOS.
      // - on iOS, the last thing we can do is to use the location accuracy plugin to show a native alert to
      //   the user asking if he/she wants to be redirected to the Settings page to enable the location feature
      // - on Android there's nothing else to be done, so the app will show an error message
      case PermissionAuthorizationStatus.RequestedAndRejected:
        return this.platformService.isIos
          ? this.askToRedirectToIosSettings().then(() => ({ coordinates: null }))
          : Promise.resolve({
              coordinates: null,
              errorMessage: this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_AUTHORIZATION_DENIED_ALWAYS') as string,
            });
    }
  }

  private getLocationCoordinatesWithAccuracy(): Promise<LocationResult> {
    return this.locationAccuracyPlugin.canRequest().then((canRequest) => {
      if (!canRequest) {
        // On iOS, this will occur if Location Services is currently on OR a request is currently in progress.
        // On Android, this will occur if the app doesn't have authorization to use location.
        return { coordinates: null };
      }

      return this.locationAccuracyPlugin.request().then((requestSuccess) => {
        if (!requestSuccess) {
          return { coordinates: null };
        }

        return this.geolocationPlugin.getCurrentPosition();
      });
    });
  }

  private askToRedirectToIosSettings(): Promise<boolean> {
    return this.locationAccuracyPlugin.canRequest().then((canRequest) => (canRequest ? this.locationAccuracyPlugin.request() : false));
  }

  private injectGooglePlacesSDK(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.windowService.isWindowDefined) {
        reject();
        return;
      }

      const initalizeGoogleServices = () => {
        try {
          this.geocoder = new google.maps.Geocoder();
          this.googlePlaces = new google.maps.places.AutocompleteService();
          resolve(true);
        } catch (error) {
          reject(error);
        }
      };

      const alreadyLoaded = this.windowService.window.document.getElementById(GOOGLE_MAPS_SCRIPT_ID);

      // It may happen that the app is reloaded or partially reloaded but
      // the script is already loaded which causes a javascript error
      // https://bilbayt.atlassian.net/browse/CA-2213
      if (alreadyLoaded) {
        initalizeGoogleServices();
        return;
      }

      // This callback will be called when the script
      // is injected and loaded properly
      this.windowService.window[GOOGLE_MAPS_CALLBACK_NAME] = () => {
        initalizeGoogleServices();
      };

      try {
        const script = this.renderer.createElement('script') as HTMLScriptElement;

        script.id = GOOGLE_MAPS_SCRIPT_ID;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.environmentService.googleMapsApiKey}&libraries=places&callback=${GOOGLE_MAPS_CALLBACK_NAME}`;

        this.renderer.appendChild(this.windowService.window.document.body, script);
      } catch (error) {
        reject(error);
      }
    });
  }
}
