import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { filter, map } from 'rxjs/operators';

import { AccountService } from '../services/account.service';
import { NavigationService } from '../services/navigation.service';
import { OverlayService } from '../services/overlay.service';

import { AppInitializedGuard } from './app-initialized.guard';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private appInitializedGuard: AppInitializedGuard,
    private overlayService: OverlayService,
    private accountService: AccountService,
    private translateService: TranslateService,
    private navigationService: NavigationService,
  ) {}
  canActivate() {
    return this.appInitializedGuard.canActivate().pipe(
      filter((canContinue) => !!canContinue),
      map(() => this.checkUserLoggedIn()),
    );
  }

  private checkUserLoggedIn(): boolean {
    if (!this.accountService.isLoggedIn()) {
      void this.navigationService.navigateBackToHomePage().then(() =>
        this.overlayService.showToast({
          type: 'error',
          message: this.translateService.instant('ERROR_MESSAGE.USER_NOT_SIGNED_IN') as string,
          showCloseButton: true,
        }),
      );
      return false;
    }

    return true;
  }
}
