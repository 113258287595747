import { Animation, createAnimation, NavOptions } from '@ionic/core';

interface TransitionOptions extends NavOptions {
  progressCallback?: (ani: Animation | undefined) => void;
  baseEl: unknown;
  enteringEl: HTMLElement;
  leavingEl: HTMLElement | undefined;
}

export const getIonPageElement = (element: HTMLElement): Element => {
  if (element.classList.contains('ion-page')) {
    return element;
  }

  const ionPage = element.querySelector(':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs');

  if (ionPage) {
    return ionPage;
  }

  return element;
};

export const browserAnimatedTransitionAnimation = (_: HTMLElement, opts: TransitionOptions): Animation => {
  const backDirection = opts.direction === 'back';
  const enteringEl = opts.enteringEl;
  const leavingEl = opts.leavingEl;

  const ionPageElement = getIonPageElement(enteringEl);
  const rootTransition = createAnimation();

  rootTransition.addElement(ionPageElement).fill('both').beforeRemoveClass('ion-page-invisible');

  if (backDirection) {
    rootTransition.duration(200).easing('cubic-bezier(0.47,0,0.745,0.715)');
  } else {
    rootTransition.duration(280).easing('cubic-bezier(0.36,0.66,0.04,1)').fromTo('opacity', 0.01, 1);
  }

  if (leavingEl && backDirection) {
    const leavingPage = createAnimation();

    rootTransition.duration(200).easing('cubic-bezier(0.47,0,0.745,0.715)');

    leavingPage
      .addElement(getIonPageElement(leavingEl))
      .onFinish((currentStep) => {
        if (currentStep === 1 && leavingPage.elements.length > 0) {
          leavingPage.elements[0].style.setProperty('display', 'none');
        }
      })
      .fromTo('opacity', 1, 0);

    rootTransition.addAnimation(leavingPage);
  }

  return rootTransition;
};

export const browserEmptyTransitionAnimation = (_: HTMLElement, _opts: TransitionOptions): Animation => createAnimation();
