<ng-container *ngFor="let timeSlotsByDate of allTimeSlotsByDate">
  <ng-container *ngIf="timeSlotsByDate.timeSlots?.length > 0">
    <div tappable class="header" (click)="onToggleDate(timeSlotsByDate)">
      <h3 class="header-title">{{ timeSlotsByDate.dateText }}</h3>
      <ion-icon
        src="/assets/custom-icons/b-angle-right.svg"
        class="header-icon"
        [class.header-icon-rotate]="timeSlotsByDate.animationState === AnimationState.Expanded"
      ></ion-icon>
    </div>

    <div [@animatedContent]="timeSlotsByDate.animationState" style="overflow: hidden">
      <div
        tappable
        (click)="onSelectTimeSlot(timeSlotsByDate.dateText, timeSlot)"
        class="time-slot"
        [class.time-slot-selected]="
          selectedTimeSlot &&
          selectedTimeSlot.date === timeSlot.date &&
          selectedTimeSlot.startTime?.hour === timeSlot.startTime?.hour &&
          selectedTimeSlot.startTime?.minute === timeSlot.startTime?.minute &&
          selectedTimeSlot.endTime?.hour === timeSlot.endTime?.hour &&
          selectedTimeSlot.endTime?.minute === timeSlot.endTime?.minute
        "
        data-cy="time-slot"
        *ngFor="let timeSlot of timeSlotsByDate.timeSlots"
      >
        <ion-icon src="/assets/custom-icons/b-check-circle-solid.svg" class="time-slot-icon"></ion-icon>
        <p class="time-slot-name">{{ timeSlot.timeSlotText }}</p>
      </div>
    </div>
  </ng-container>
</ng-container>
