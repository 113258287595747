import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

import { TokenDetails } from '../models/token-details.model';

import { AccountService } from '../services/account.service';
import { AppEventsService } from '../services/app-events.service';
import { PlatformService } from '../services/ssr/platform.service';

import { shouldNotIncludeAccessToken, shouldSkipHttpInterceptors } from '../config/app.config';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // These two properties will allow us to stop further requests
  // to be sent to the server if we are waiting for a new token
  public isRefreshingToken = false;
  public tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private injector: Injector) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (shouldSkipHttpInterceptors(request.url) || shouldNotIncludeAccessToken(request.url)) {
      return next.handle(request);
    }

    const authService = this.injector.get(AccountService);

    return next.handle(this.addToken(request, authService.getAccessToken())).pipe(
      catchError((errorResponse) => {
        if (errorResponse instanceof HttpErrorResponse) {
          switch (errorResponse.status) {
            case 400:
              const errorMessage = (errorResponse?.error as Record<string, unknown>)?.error;

              if (errorMessage === 'invalid_grant') {
                // If we get a 400 and the error message is
                // 'invalid_grant', the token is no longer valid
                return this.handleInvalidAccessToken(request, next);
              } else {
                return throwError(errorResponse);
              }

            case 401:
              return this.handleInvalidAccessToken(request, next);
          }
        }

        return throwError(errorResponse);
      }),
    );
  }

  private addToken(req: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return token ? req.clone({ setHeaders: { Authorization: 'Bearer ' + token } }) : req;
  }

  // Method that tries to obtain a new access token and stops any other request
  // to be sent to the server until the new token is ready or we get an error
  private handleInvalidAccessToken(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      const accountService = this.injector.get(AccountService);

      console.log(`[Auth Interceptor] Old access token: ${accountService.getAccessToken()}`);
      console.log(`[Auth Interceptor] Old refresh token: ${accountService.getRefreshToken()}`);

      return accountService.refreshToken().pipe(
        catchError(() => of(null)),
        switchMap((tokenDetails: TokenDetails) => {
          if (tokenDetails) {
            console.log(`[Auth Interceptor] New access token: ${tokenDetails.accessToken}`);
            console.log(`[Auth Interceptor] New refresh token: ${tokenDetails.refreshToken}`);

            this.tokenSubject.next(tokenDetails.accessToken);
            return next.handle(this.addToken(req, tokenDetails.accessToken));
          }

          // If we didn't get a new token, we are in trouble so logout.
          return this.logoutUser();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }),
      );
    } else {
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => next.handle(this.addToken(req, token))),
      );
    }
  }

  private logoutUser(): Observable<never> {
    const platformsService = this.injector.get(PlatformService);
    const appEventsService = this.injector.get(AppEventsService);

    // Publish an event so we can handle this scenario somewhere
    // else where we have access to the navigation stack
    void platformsService.wait(300).then(() => appEventsService.dispatch('AuthTokensAreNotValid'));

    // Let the caller know that there was an error
    return throwError(() => new Error('Invalid token details'));
  }
}
