import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { ModalAdPage } from './modal-ad.page';

import { AnimatedImageComponentModule } from '../../shared/components/animated-image/animated-image.module';

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule.forChild(), AnimatedImageComponentModule],
  declarations: [ModalAdPage],
})
export class ModalAdPageModule {}
