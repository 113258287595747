import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Geolocation, GeolocationOptions } from '@awesome-cordova-plugins/geolocation/ngx';

import { PositionErrorCode } from '../../enums/position-error-code.enum';

import { LocationResult } from '../geolocation.service';
import { LoggerService } from '../logger.service';

const GEOLOCATION_OPTIONS: GeolocationOptions = {
  // The maximum length of time (milliseconds) that is allowed to pass
  // until the corresponding geolocationSuccess callback executes
  timeout: 30 * 1000,

  // Provides a hint that the application needs the best possible results.
  // By default, the device attempts to retrieve a Position using network-based
  // methods. Setting this property to true tells the framework to use more
  // accurate methods, such as satellite positioning
  enableHighAccuracy: true,

  // Accept a cached position whose age is no greater than the specified time in milliseconds
  maximumAge: 5 * 60 * 1000,
};

@Injectable({ providedIn: 'root' })
export class GeolocationPlugin {
  constructor(private geolocation: Geolocation, private translateService: TranslateService, private loggerService: LoggerService) {}

  public getCurrentPosition(): Promise<LocationResult> {
    return this.geolocation
      .getCurrentPosition(GEOLOCATION_OPTIONS)
      .then((position) => {
        if (!position || !position.coords) {
          return {
            coordinates: null,
            errorMessage: this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_UNKNOWN_ERROR') as string,
          };
        }

        return {
          coordinates: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        };
      })
      .catch((error: unknown) => {
        this.loggerService.info({
          component: 'GeolocationPlugin',
          message: "couldn't get current position",
          fromPlugin: true,
          details: { error },
        });

        let errorMessage: string;

        switch ((error as { code: PositionErrorCode })?.code) {
          case PositionErrorCode.TIMEOUT:
            errorMessage = this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_POSITION_TIMEOUT') as string;
            break;

          case PositionErrorCode.PERMISSION_DENIED:
            errorMessage = this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_POSITION_PERMISSION_DENIED') as string;
            break;

          case PositionErrorCode.POSITION_UNAVAILABLE:
            errorMessage = this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_POSITION_UNAVAILABLE') as string;
            break;
        }

        return {
          coordinates: null,
          errorMessage: errorMessage ?? (this.translateService.instant('ERROR_MESSAGE.GEOLOCATION_UNKNOWN_ERROR') as string),
        };
      });
  }
}
