import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { ImagesPreviewModalPage } from './images-preview-modal.page';

import { AnimatedImageComponentModule } from '../../shared/components/animated-image/animated-image.module';

import { SwiperModule } from 'swiper/angular';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, AnimatedImageComponentModule, TranslateModule.forChild(), SwiperModule],
  declarations: [ImagesPreviewModalPage],
})
export class ImagesPreviewModalPageModule {}
