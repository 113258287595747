import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';

import { EnvironmentService } from '../services/environment.service';

import { GET_ACCESS_TOKEN_URL, shouldSkipHttpInterceptors } from '../config/app.config';

@Injectable()
export class FullUrlInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (shouldSkipHttpInterceptors(request.url)) {
      return next.handle(request);
    }

    const environmentService = this.injector.get(EnvironmentService);

    const fullUrl =
      request.url === GET_ACCESS_TOKEN_URL
        ? `${environmentService.authApiUrl}/${request.url}`
        : `${environmentService.apiUrl}/${request.url}`;

    request = request.clone({ url: fullUrl });

    return next.handle(request);
  }
}
