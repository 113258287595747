import { InjectionToken } from '@angular/core';

import { COUNTRY, Country, COUNTRY_CODES, CountryId } from '../models/country.model';
import { CurrencyConfiguration } from '../models/currency-configuration.model';
import { LANGUAGE, Language, LANGUAGE_CODES } from '../models/language.model';
import { RouteConfig } from '../models/route-config.model';

import { environment } from '../../../environments/environment';

export const RESPONSE_TIMING_URL =
  'https://bilbayt-intra-functions.azurewebsites.net/api/ReceiveApiTimingData?code=xfUTowSwiR338UKrT0IMU695zpKqdcKvwBooA73jpaO8Ut0uvJAaNA==';

const ASSETS_PARTIAL_URL = 'assets/';
const BRANCH_PARTIAL_URL = 'api.branch.io/';
const DATADOG_PARTIAL_URL = 'logs.datadog';

const REGISTER_NEW_USER_URL = 'user/account';
const RESET_USER_PASSWORD_URL = 'user/account/password/reset';
const CHECK_EMAIL_AVAILABILITY_URL = 'user/account/email-availability';
const CART_VALIDATE_URL = 'public/cart/validate';
const GET_DEFAULT_DDRESS_URL = 'public/locations/default-address';
const GET_FEATURE_TOGGLES_URL = 'public/settings/features';

const TRANSLATIONS_PARTIAL_URL = 'i18n/CustomerApp';
const INITIAL_SETTINGS_PARTIAL_URL = 'settings/CustomerApp';
const VENDOR_STATIC_FILES_PARTIAL_URL = '/vendor-files/';

export const COUNTRY_LANGUAGE_CODE = {
  KwEn: `${COUNTRY.kuwait.code}-${LANGUAGE.english.code}`,
  KwAr: `${COUNTRY.kuwait.code}-${LANGUAGE.arabic.code}`,
  AeEn: `${COUNTRY.uae.code}-${LANGUAGE.english.code}`,
  AeAr: `${COUNTRY.uae.code}-${LANGUAGE.arabic.code}`,
  SaEn: `${COUNTRY.saudiArabia.code}-${LANGUAGE.english.code}`,
  SaAr: `${COUNTRY.saudiArabia.code}-${LANGUAGE.arabic.code}`,
} as const;

export const TOKEN_CONFIG = new InjectionToken<AppConfig>('app.config');

export const GET_ACCESS_TOKEN_URL = 'connect/token';

export const SETTINGS_CDN_REQUEST_TIMEOUT_IN_MILLISECONDS = 5_000;
export const TRANSLATION_CDN_REQUEST_TIMEOUT_IN_MILLISECONDS = 5_000;

// Having a different APP_NAME by environment allows us to switch between PROD and STG
// without the need of logging in again
export const APP_NAME = environment.production ? 'BILBAYT_CUSTOMER_APP' : 'BILBAYT_CUSTOMER_APP_STG';

export const COUNTRY_LANGUAGE_REGEX = new RegExp(`(${COUNTRY_CODES.join('|')})/(${LANGUAGE_CODES.join('|')})`, 'gi');

export const shouldSkipHttpInterceptors = (url: string): boolean =>
  url.includes(TRANSLATIONS_PARTIAL_URL) ||
  url.includes(INITIAL_SETTINGS_PARTIAL_URL) ||
  url.includes(VENDOR_STATIC_FILES_PARTIAL_URL) ||
  url.includes(ASSETS_PARTIAL_URL) ||
  url.includes(BRANCH_PARTIAL_URL) ||
  url.includes(DATADOG_PARTIAL_URL) ||
  url.includes(RESPONSE_TIMING_URL);

export const shouldNotIncludeAccessToken = (url: string): boolean =>
  url === GET_ACCESS_TOKEN_URL || url === REGISTER_NEW_USER_URL || url === RESET_USER_PASSWORD_URL || url === CHECK_EMAIL_AVAILABILITY_URL;

export const isHealthCheckUrl = (url: string): boolean => url.endsWith('/hc');

// Ionic internally uses priority 100 for overlays so in order to make our handlers
// not to be ignored we need to set a higher priority
// https://ionicframework.com/docs/developing/hardware-back-button#internal-framework-handlers
export const BACK_BUTTON_HANDLER_PRIORITY = 150;

interface AnimationDuration {
  animation: string;
  duration: number;
}

interface AnimationDurationDirection extends AnimationDuration {
  direction: string;
}

interface EnterLeaveAnimation {
  enterAnimation: string;
  leaveAnimation: string;
}

export interface AppConfig {
  appName: string;

  termsAndConditionsUrl: string;

  appStoreUrlAndroid: string;
  appStoreUrlIos: string;

  appIdAndroid: string;
  appIdIos: string;

  apiVersionNumber: number;

  contactEmail: string;

  defaultLanguage: string;
  availableLanguages: Array<Language>;

  defaultCountry: CountryId;
  availableCountries: Array<Country>;

  currencySymbol: Array<CurrencyConfiguration>;

  defaultErrorIconName: string;

  maxCacheTime: number;

  // Angular view refresh issue
  // https://github.com/angular/angular/issues/19712
  tabsChangeMinTime: number;

  sharedElementsAnimationIn: AnimationDuration;
  sharedElementsAnimationOut: AnimationDuration;
  sharedSubElementsAnimationIn: AnimationDuration;
  sharedSubElementsAnimationOut: AnimationDuration;
  fromBottomToTopIn: AnimationDurationDirection;
  fromBottomToTopOut: AnimationDurationDirection;

  modalPageAnimation: EnterLeaveAnimation;
  overlayPageAnimation: EnterLeaveAnimation;
  bottomModalPageAnimation: EnterLeaveAnimation;

  bilbaytNowEtaRoundingMinutes: number;
  bilbaytNowAdditionalHours: number;
  bilbaytNowRoundingMinutes: number;

  nameMaxLength: number;
  passwordMinLength: number;
  addressMaxLength: number;

  routes: {
    getHealthCheck: RouteConfig;
    getUser: RouteConfig;
    getToken: RouteConfig;
    getAreas: RouteConfig;
    getOrders: RouteConfig;
    getReOrderDetails: RouteConfig;
    getUserWallet: RouteConfig;
    getUserWishlists: RouteConfig;
    getUserWishlistsLastModified: RouteConfig;
    getUserWishlistDetails: RouteConfig;
    getUserLoyaltyProgramDetails: RouteConfig;
    getOrderDetails: RouteConfig;
    getOrderPaymentOptions: RouteConfig;
    getMenuItemDetails: RouteConfig;
    getMenuItemAvailableTimes: RouteConfig;
    getCaterersReviews: RouteConfig;
    getUserSavedAddresses: RouteConfig;
    getShortcutsForUser: RouteConfig;
    getVendorsHomePageSearchResults: RouteConfig;
    getVendorsItemsHomePageSearchResults: RouteConfig;
    getVendorDetailsById: RouteConfig;
    getVendorDetailsBySlug: RouteConfig;
    getModalAds: RouteConfig;
    getFreeCancellationMessage: RouteConfig;
    getCurrentCountry: RouteConfig;
    getUserRewards: RouteConfig;
    getLastUnreviewedOrder: RouteConfig;
    getFeatureToggles: RouteConfig;
    getUserCards: RouteConfig;
    getVendorAvailableTimeSlots: RouteConfig;
    getGroceryVendorDetails: RouteConfig;
    getGroceryVendorCategories: RouteConfig;
    getGroceryVendorCategoryItemsPreview: RouteConfig;
    getGroceryCategoryMenuItemsDetails: RouteConfig;
    getGroceryMenuItemsDetails: RouteConfig;
    getVendorSearchResults: RouteConfig;
    getEmailAvailability: RouteConfig;
    getGlobalSearchResults: RouteConfig;
    getDefaultArea: RouteConfig;
    getDefaultAddress: RouteConfig;
    getUserActiveOrders: RouteConfig;
    getOrderTrackDetails: RouteConfig;
    getMarketingCollectionDetails: RouteConfig;
    getUniversalLinkDetails: RouteConfig;

    putOrderPay: RouteConfig;
    putNewOrder: RouteConfig;
    putUserSignUp: RouteConfig;
    putOrderReview: RouteConfig;
    putCartValidate: RouteConfig;
    putOrderCancellation: RouteConfig;
    putUserResetPassword: RouteConfig;
    putOrderSpecialRequest: RouteConfig;
    putUserSavedAddresses: RouteConfig;
    putUserPushNotificationStatus: RouteConfig;
    putUserPreferredLanguage: RouteConfig;
    putDeleteUser: RouteConfig;
    putAddItemToWishlist: RouteConfig;
    putUpdateWishlist: RouteConfig;
    putRenameWishlist: RouteConfig;
    putJoinWishlist: RouteConfig;
    putLeaveWishlist: RouteConfig;

    postUserCard: RouteConfig;
    postContact: RouteConfig;
    postSearchAddress: RouteConfig;
    postDeepLinkClick: RouteConfig;
    postNewWishlist: RouteConfig;
    postReferralProgramLinkClick: RouteConfig;
    postPromotionValidate: RouteConfig;
    postUserSavedAddresses: RouteConfig;
    postUserPromotions: RouteConfig;
    postBankPromotion: RouteConfig;
    postVendorSignup: RouteConfig;
    postBusinessRegister: RouteConfig;
    postVendorAvailableTimes: RouteConfig;

    deleteUserSavedAddresses: RouteConfig;
    deleteUserCard: RouteConfig;
    deleteUserWishlist: RouteConfig;
  };
}

export const APP_CONFIG: AppConfig = {
  appName: APP_NAME,

  termsAndConditionsUrl: 'https://bilbayt.com/#country#/#language#/terms',

  appStoreUrlAndroid: 'https://play.google.com/store/apps/details?id=com.bilbayt.bilbayt',
  appStoreUrlIos: 'https://itunes.apple.com/app/id1052726070',

  appIdAndroid: 'com.bilbayt.bilbayt',
  appIdIos: 'id1052726070',

  apiVersionNumber: 123,

  contactEmail: 'info@bilbayt.com',

  defaultLanguage: LANGUAGE.english.code,
  availableLanguages: [
    { value: LANGUAGE.english.code, rtl: false },
    { value: LANGUAGE.arabic.code, rtl: true },
  ],

  defaultCountry: COUNTRY.kuwait.id,
  availableCountries: [
    {
      value: COUNTRY.kuwait.id,
      code: COUNTRY.kuwait.code,
      name: 'COUNTRIES.KUWAIT',
      flagUrl: 'assets/img/flags/kw.svg',
      contactPhoneNumber: '+96522053828',
      phoneNumberValidationPattern: /^\+\d{1,3}\s?(?!0+$)\d{8}$/,
      phoneLocalNumberValidationPattern: /^(?!0+$)\d{8}$/,
      phoneCountryCodeValidationPattern: /^\+\d{1,3}$/,
      geography: {
        latitude: 29.3759,
        longitude: 47.9774,
      },
    },
    {
      value: COUNTRY.uae.id,
      code: COUNTRY.uae.code,
      name: 'COUNTRIES.UAE',
      flagUrl: 'assets/img/flags/ae.svg',
      contactPhoneNumber: '+971507078428',
      phoneNumberValidationPattern: /^\+\d{1,3}\s?(?!0+$)0?\d{9}$/,
      phoneLocalNumberValidationPattern: /^(?!0+$)0?\d{9}$/,
      phoneCountryCodeValidationPattern: /^\+\d{1,3}$/,
      geography: {
        latitude: 25.2048,
        longitude: 55.2708,
      },
    },
    {
      value: COUNTRY.saudiArabia.id,
      code: COUNTRY.saudiArabia.code,
      name: 'COUNTRIES.SAUDI_ARABIA',
      flagUrl: 'assets/img/flags/sa.svg',
      contactPhoneNumber: '+966506533880',
      phoneNumberValidationPattern: /^\+\d{1,3}\s?(?!0+$)0?\d{9}$/,
      phoneLocalNumberValidationPattern: /^(?!0+$)0?\d{9}$/,
      phoneCountryCodeValidationPattern: /^\+\d{1,3}$/,
      geography: {
        latitude: 24.7136,
        longitude: 46.6753,
      },
    },
  ],

  currencySymbol: [
    {
      key: COUNTRY_LANGUAGE_CODE.KwEn,
      isoCode: 'KWD',
      currencySymbol: 'KD',
      minIntegerDigits: 1,
      minDecimalDigits: 3,
      decimalSeparatorSymbol: '.',
      thousandsSeparatorSymbol: ',',
      branchCurrencyCodeId: 80,
    },
    {
      key: COUNTRY_LANGUAGE_CODE.KwAr,
      isoCode: 'KWD',
      currencySymbol: 'د.ك',
      minIntegerDigits: 1,
      minDecimalDigits: 3,
      decimalSeparatorSymbol: '.',
      thousandsSeparatorSymbol: ',',
      branchCurrencyCodeId: 80,
    },
    {
      key: COUNTRY_LANGUAGE_CODE.AeEn,
      isoCode: 'AED',
      currencySymbol: 'AED',
      minIntegerDigits: 1,
      minDecimalDigits: 2,
      decimalSeparatorSymbol: '.',
      thousandsSeparatorSymbol: ',',
      branchCurrencyCodeId: 0,
    },
    {
      key: COUNTRY_LANGUAGE_CODE.AeAr,
      isoCode: 'AED',
      currencySymbol: 'د.إ',
      minIntegerDigits: 1,
      minDecimalDigits: 2,
      decimalSeparatorSymbol: '.',
      thousandsSeparatorSymbol: ',',
      branchCurrencyCodeId: 0,
    },
    {
      key: COUNTRY_LANGUAGE_CODE.SaEn,
      isoCode: 'SAR',
      currencySymbol: 'SAR',
      minIntegerDigits: 1,
      minDecimalDigits: 2,
      decimalSeparatorSymbol: '.',
      thousandsSeparatorSymbol: ',',
      branchCurrencyCodeId: 123,
    },
    {
      key: COUNTRY_LANGUAGE_CODE.SaAr,
      isoCode: 'SAR',
      currencySymbol: 'ر.س',
      minIntegerDigits: 1,
      minDecimalDigits: 2,
      decimalSeparatorSymbol: '.',
      thousandsSeparatorSymbol: ',',
      branchCurrencyCodeId: 123,
    },
  ],

  defaultErrorIconName: 'b_hospital',

  maxCacheTime: 7 * 24 * 60 * 60 * 1000, // 1 Week

  // Angular view refresh issue
  // https://github.com/angular/angular/issues/19712
  tabsChangeMinTime: 1000, // 1 second

  sharedElementsAnimationIn: { animation: 'shared-elements', duration: 380 },
  sharedElementsAnimationOut: { animation: 'shared-elements', duration: 340 },
  sharedSubElementsAnimationIn: { animation: 'shared-sub-elements', duration: 380 },
  sharedSubElementsAnimationOut: { animation: 'shared-sub-elements', duration: 340 },
  fromBottomToTopIn: { animation: 'from-bottom-to-top', duration: null, direction: 'forward' },
  fromBottomToTopOut: { animation: 'from-bottom-to-top', duration: null, direction: 'back' },

  modalPageAnimation: {
    enterAnimation: 'modal-alert-pop-in',
    leaveAnimation: 'modal-alert-pop-out',
  },
  overlayPageAnimation: { enterAnimation: 'overlay-pop-in', leaveAnimation: 'overlay-pop-out' },
  bottomModalPageAnimation: {
    enterAnimation: 'bottom-modal-pop-in',
    leaveAnimation: 'bottom-modal-pop-out',
  },

  bilbaytNowEtaRoundingMinutes: 5,
  bilbaytNowAdditionalHours: 3,
  bilbaytNowRoundingMinutes: 30, // Half an hour

  nameMaxLength: 30,
  passwordMinLength: 8,
  addressMaxLength: 50,

  routes: {
    // Api Endpoints that are not public
    // but still don't require the access token
    getToken: { url: GET_ACCESS_TOKEN_URL },
    putUserSignUp: { url: REGISTER_NEW_USER_URL },
    putUserResetPassword: { url: RESET_USER_PASSWORD_URL },

    // Api Endpoints that should include the access token
    getUser: { url: 'user/account/identity' },
    getOrders: { url: 'user/orders/paginated' },
    getReOrderDetails: { url: 'user/orders/#orderId#/vendors/#vendorId#/order-again' },
    getUserWallet: { url: 'user/wallet' },
    getUserWishlists: { url: 'user/wishlists' },
    getUserWishlistsLastModified: { url: 'user/wishlists/last-modified' },
    getUserWishlistDetails: { url: 'user/wishlists/#wishlistId#' },
    getUserLoyaltyProgramDetails: { url: 'user/loyalty-program' },
    getOrderDetails: { url: 'user/orders/#orderId#' },
    getOrderPaymentOptions: { url: 'user/orders/#orderId#/payment-options' },
    getUserSavedAddresses: { url: 'user/addresses' },
    getUserRewards: { url: 'user/rewards' },
    getUserActiveOrders: { url: 'user/orders/active-orders' },
    getOrderTrackDetails: { url: 'user/orders/#orderId#/vendors/#vendorId#/order-track-details' },
    getLastUnreviewedOrder: { url: 'user/orders/newest-reviewable-order' },
    getFeatureToggles: { url: GET_FEATURE_TOGGLES_URL },
    getUserCards: { url: 'user/payments/cards' },

    putOrderPay: { url: 'user/orders/#orderId#/pay' },
    putNewOrder: { url: 'user/orders' },
    putOrderReview: { url: 'user/orders/#orderId#/review' },
    putOrderCancellation: { url: 'user/orders/#orderId#/cancel' },
    putOrderSpecialRequest: { url: 'user/orders/#orderId#/special-request' },
    putUserSavedAddresses: { url: 'user/addresses/#savedAddressId#' },
    putUserPushNotificationStatus: { url: 'user/account/push-notifications' },
    putUserPreferredLanguage: { url: 'user/account/language' },
    putDeleteUser: { url: 'user/account/delete' },
    putAddItemToWishlist: { url: 'user/wishlists/#wishlistId#/add-item' },
    putUpdateWishlist: { url: 'user/wishlists/#wishlistId#/update-items' },
    putRenameWishlist: { url: 'user/wishlists/#wishlistId#/rename' },
    putJoinWishlist: { url: 'user/wishlists/#wishlistId#/join' },
    putLeaveWishlist: { url: 'user/wishlists/#wishlistId#/leave' },

    postUserCard: { url: 'user/payments/cards' },
    postNewWishlist: { url: 'user/wishlists/add' },
    postDeepLinkClick: { url: 'user/tracking/deep-link-click' },
    postReferralProgramLinkClick: { url: 'user/tracking/affiliate-link-click' },
    postUserSavedAddresses: { url: 'user/addresses/' },
    postUserPromotions: { url: 'user/promotions' },
    postBankPromotion: { url: 'user/promotions/bank-promotion' },
    postVendorSignup: { url: 'public/vendors/signup' },
    postBusinessRegister: { url: 'public/business/register' },

    deleteUserWishlist: { url: '/user/wishlists/#wishlistId#/delete' },
    deleteUserSavedAddresses: { url: 'user/addresses/#savedAddressId#' },
    deleteUserCard: { url: 'user/payments/cards/#cardId#' },
    getDefaultArea: { url: 'public/locations/default-area' },
    getDefaultAddress: { url: GET_DEFAULT_DDRESS_URL },

    // Api Endpoints that don't require the access token
    getHealthCheck: { url: 'hc' },
    getEmailAvailability: { url: CHECK_EMAIL_AVAILABILITY_URL },
    getAreas: { url: 'public/locations/#country#/provinces' },
    getCaterersReviews: { url: 'public/vendors/#catererId#/reviews' },
    getMenuItemDetails: { url: 'public/vendors/#catererId#/menu-items/#menuItemId#' },
    getMenuItemAvailableTimes: { url: 'public/vendors/#vendorId#/menu-items/#menuItemId#/available-times' },
    getVendorDetailsById: { url: 'public/vendors/#vendorId#/menu-v3' },
    getVendorDetailsBySlug: { url: 'public/vendors/#vendorSlug#' },
    getShortcutsForUser: { url: 'user/marketing/for-customer' },
    getVendorsHomePageSearchResults: { url: 'public/vendors/search-v2' },
    getVendorsItemsHomePageSearchResults: { url: 'public/vendors/all-products-v2' },
    getModalAds: { url: 'public/ads/modal-ads' },
    getFreeCancellationMessage: {
      url: 'public/settings/orders/cancellation-message',
    },
    getCurrentCountry: { url: 'public/locations/current/country' },
    getGroceryVendorDetails: { url: 'public/vendors/#vendorId#' },
    getGroceryVendorCategories: { url: 'public/vendors/#vendorId#/categories' },
    getGroceryVendorCategoryItemsPreview: { url: 'public/vendors/#vendorId#/products' },
    getGroceryCategoryMenuItemsDetails: {
      url: 'public/vendors/#vendorId#/categories/#menuGroupId#/products',
    },
    getGroceryMenuItemsDetails: { url: 'public/products/#menuItemId#' },
    getVendorSearchResults: { url: 'public/vendors/#catererId#/search' },
    getGlobalSearchResults: { url: 'public/vendors/universal-search' },
    getMarketingCollectionDetails: { url: 'public/marketing/marketing-collections/#collectionId#' },
    getUniversalLinkDetails: { url: 'public/universal-links/#linkId#' },

    putCartValidate: { url: CART_VALIDATE_URL },

    postContact: { url: 'public/contacts' },
    postSearchAddress: { url: 'public/locations/address/search' },
    postPromotionValidate: { url: 'public/promotions/#promotionCode#/validity' },
    getVendorAvailableTimeSlots: { url: 'public/vendors/#vendorId#/time-slots' },
    postVendorAvailableTimes: { url: 'public/vendors/#vendorId#/available-times' },
  },
};
