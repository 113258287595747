import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ModalService } from '../../core/services/modal.service';
import { SettingsService } from '../../core/services/settings.service';

export const ReferralProgramModalPageIdentifier = 'referral-program-modal';

@Component({
  selector: 'app-referral-program-modal',
  templateUrl: './referral-program-modal.page.html',
  styleUrls: ['./referral-program-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReferralProgramModalPage implements OnInit {
  public credits: number;

  constructor(private modalService: ModalService, private settingsService: SettingsService) {}

  ngOnInit() {
    this.credits = this.settingsService.getReferralProgramCredits();
  }

  public onClose(): void {
    void this.dismiss(false);
  }

  public onShareLink(): void {
    void this.dismiss(true);
  }

  private dismiss(result: boolean): Promise<boolean> {
    return this.modalService.dismissModal({ data: result, id: ReferralProgramModalPageIdentifier });
  }
}
