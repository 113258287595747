import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

import { Observable, of, Subject } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

import { DeliveryProviders } from '../enums/delivery-providers.enum';

import { Country, CountryCode, CountryId } from '../models/country.model';
import { CurrencyConfiguration } from '../models/currency-configuration.model';
import { HealthCheckStatus } from '../models/health-check-status.model';
import { FeatureTogglesFromApi, InitialSettings, TestGroups } from '../models/initial-settings.model';
import { Language, LANGUAGE, LanguageCode } from '../models/language.model';
import { ServiceTypeDetails } from '../models/service-type-details.model';
import { ServiceTypeOption } from '../models/service-type-option.model';
import { TestGroup } from '../models/test-group.model';

import { EnvironmentService } from './environment.service';
import { LoggerService } from './logger.service';
import { PlatformService } from './ssr/platform.service';
import { WindowService } from './ssr/window.service';
import { StateService } from './state.service';

import { AppConfig, TOKEN_CONFIG } from '../config/app.config';

import { Helpers } from '../helpers';

import { SETTINGS_LOADER, SettingsLoader } from './ssr/settings-loader';

export type LanguageDirection = 'ltr' | 'rtl';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  public countryLanguage$: Observable<{ changedCountry: boolean; changedLanguage: boolean }>;

  private initialized = false;

  private defaultLanguage: Language;
  private selectedLanguage: Language;
  private availableLanguages: Array<Language>;

  private defaultCountry: Country;
  private selectedCountry: Country;
  private availableCountries: Array<Country>;

  private initialSettings: InitialSettings;
  private testGroups = new Map<string, string>();
  private featureTogglesFromApi: FeatureTogglesFromApi;

  private countryLanguageSource$ = new Subject<{ changedCountry: boolean; changedLanguage: boolean }>();

  constructor(
    private meta: Meta,
    private http: HttpClient,
    private windowService: WindowService,
    private platformService: PlatformService,
    private translateService: TranslateService,
    private stateService: StateService,
    private loggerService: LoggerService,
    private environmentService: EnvironmentService,
    @Inject(TOKEN_CONFIG) private config: AppConfig,
    @Inject(SETTINGS_LOADER) private settingsLoader: SettingsLoader,
  ) {
    this.availableCountries = this.config.availableCountries;
    this.availableLanguages = this.config.availableLanguages;

    // In some cases we may want to redirect to some pages BEFORE the app has been
    // initialized so it's better to initialize the default country/language here
    // https://bilbayt.atlassian.net/browse/CA-2013
    this.defaultCountry = this.availableCountries.find((country) => country.value === this.config.defaultCountry);
    this.defaultLanguage = this.availableLanguages.find((language) => language.value === this.config.defaultLanguage);

    this.countryLanguage$ = this.countryLanguageSource$.pipe(shareReplay(1));
  }

  public get currencySymbolEN(): string {
    const country = this.getCountry().code;
    const currencyConfiguration = this.config.currencySymbol.find(
      (currencySymbol) => currencySymbol.key === `${country}-${LANGUAGE.english.code}`,
    );
    return currencyConfiguration.currencySymbol.toLowerCase();
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  public getCurrentCountryCodeFromApi(): Observable<CountryId> {
    return this.http.get<CountryId>(this.config.routes.getCurrentCountry.url);
  }

  public getInitialSettings({ loggedIn }: { loggedIn: boolean }): Observable<void> {
    return this.settingsLoader.getSettings().pipe(
      map((initialSettings) => {
        this.initialSettings = initialSettings;
        this.updateTestGroups(this.initialSettings.testGroups);
      }),
      // Some features toggles are based on the current user
      // so we need to get them from the API
      // https://bilbayt.atlassian.net/browse/CA-2486
      switchMap(() =>
        this.getFeatureTogglesFromApi({ loggedIn }).pipe(
          map((featureTogglesFromApi) => (this.featureTogglesFromApi = featureTogglesFromApi)),
        ),
      ),
      map(() => {
        this.initialized = true;
      }),
    );
  }

  public initializeCountryLanguage(): void {
    const selectedLanguage = this.stateService.state?.language;
    const selectedCountry = this.stateService.state?.country;

    if (!selectedLanguage && !selectedCountry) {
      this.updateTranslateServiceAndDirection(this.defaultLanguage);
      return;
    }

    // Since we added the geography as one of the app update but most of the users already
    // have the country and then language in the storage, we need to update them
    this.selectedLanguage = this.availableLanguages.find((language) => language.value === selectedLanguage.value);
    this.selectedCountry = this.availableCountries.find((country) => country.value === selectedCountry.value);

    this.updateTranslateServiceAndDirection(this.selectedLanguage);
    this.countryLanguageSource$.next({ changedCountry: true, changedLanguage: true });
  }

  // Method that returns true if the language and the country have
  // been initialized already. This method is usefull to check if
  // when the user opens the app, if that is a new install or just
  // an app open event
  public alreadySelectedCountryLanguage(): boolean {
    return !!this.stateService.state?.language || !!this.stateService.state?.country;
  }

  public getLanguage(): Language {
    return this.selectedLanguage || this.defaultLanguage;
  }

  public getLanguageDirection(): LanguageDirection {
    const currentLanguage = this.getLanguage();
    return currentLanguage.rtl ? 'rtl' : 'ltr';
  }

  public getAvailableLanguages(): Array<Language> {
    return this.availableLanguages;
  }

  public getCountry(): Country {
    return this.selectedCountry || this.defaultCountry;
  }

  public getAvailableCountries(): Array<Country> {
    return this.availableCountries;
  }

  public changeCountryAndLanguage(country: Country, language: Language): { changedCountry: boolean; changedLanguage: boolean } {
    const changedCountry = this.changeCountry(country);
    const changedLanguage = this.changeLanguage(language);

    this.countryLanguageSource$.next({ changedCountry, changedLanguage });
    return { changedCountry, changedLanguage };
  }

  public getCurrencyConfiguration(): CurrencyConfiguration {
    const language = this.getLanguage().value;
    const country = this.getCountry().code;

    return this.config.currencySymbol.find((currencySymbol) => currencySymbol.key === `${country}-${language}`);
  }

  // https://en.wikipedia.org/wiki/ISO_4217
  public getCurrentIsoCurrencyCode(): string {
    const currencyConfiguration = this.config.currencySymbol.find(
      (currencySymbol) => currencySymbol.key === `${this.getCountry().code}-${LANGUAGE.english.code}`,
    );
    return currencyConfiguration.isoCode.toUpperCase();
  }

  public getCurrencySymbol(): string {
    return this.getCurrencyConfiguration().currencySymbol;
  }

  // Method that compares the api version number from the local config against
  // the api version number returned from the api to see if the user must
  // update the app before before continuing
  public shouldUpdateApp(): boolean {
    return +this.config.apiVersionNumber < +this.initialSettings.version.number;
  }

  public getPhoneNumberCountryCode(): string {
    const phoneCodesForCurrentCountry = this.getCountryBasedSetting(this.initialSettings.phoneCodes);
    return phoneCodesForCurrentCountry.countryCode;
  }

  public getServiceTypeDetails(): Array<ServiceTypeDetails> {
    return this.getServiceTypeDetailsFromSettings();
  }

  public getServiceTypesNames(serviceTypes: number): string {
    const serviceTypesNames = this.getAllServiceTypesIncludedInServiceTypeCombination(serviceTypes).map((serviceType) =>
      this.getServiceTypeName(serviceType),
    );

    return Helpers.joinStringArray(serviceTypesNames, {
      joinSeparator: ', ',
      joinSeparatorLastString: ` ${this.translateService.instant('AND')} `,
    });
  }

  public getServiceTypeName(serviceType: number): string {
    return this.getSelectedServiceTypeOptionDetails(serviceType)?.name;
  }

  public getServiceTypeNameForEveryLanguage(serviceType: number): { [K in LanguageCode]: string } {
    const serviceTypeEn = this.getServiceTypeDetailsFromSettings(LANGUAGE.english.code).find((st) => st.serviceType === serviceType);
    const serviceTypeAr = this.getServiceTypeDetailsFromSettings(LANGUAGE.arabic.code).find((st) => st.serviceType === serviceType);

    return {
      en: serviceTypeEn?.name ?? '',
      ar: serviceTypeAr?.name ?? '',
    };
  }

  public getServiceTypes(): Array<number> {
    return this.getServiceTypeDetails().map((serviceTypeDetails) => serviceTypeDetails.serviceType);
  }

  // Method that returns the name of a given service type but removing all the empty spaces
  // so that the name can be used to track events
  public getServiceTypeNameKey(serviceType: number): string {
    const serviceTypeDetail = this.getServiceTypeDetailsFromSettings(LANGUAGE.english.code).find(
      (serviceTypeDetails) => serviceTypeDetails.serviceType === serviceType,
    );
    return serviceTypeDetail ? serviceTypeDetail.name.replace(/\s/g, '') : '';
  }

  public getServiceTypeOptions({ includeHidden } = { includeHidden: false }): Array<ServiceTypeOption> {
    return this.getServiceTypeOptionsFromSettings().filter((serviceTypeOption) => includeHidden || !serviceTypeOption.hidden);
  }

  public getServiceTypesAvailableForNow(): Array<number> {
    return this.getServiceTypeDetails()
      .filter((serviceTypeDetail) => serviceTypeDetail.supportsOrderForNow)
      .map((serviceTypeDetail) => serviceTypeDetail.serviceType);
  }

  public isServiceTypeAvailableForNow(incomingServiceType: number): boolean {
    return this.getServiceTypesAvailableForNow().some((serviceType) => serviceType === incomingServiceType);
  }

  public isServiceTypeDeliveredByBilbayt(serviceType: number): boolean {
    return (
      (this.getServiceTypeDetails().find((serviceTypeDetails) => serviceTypeDetails.serviceType === serviceType)?.deliveryProviders &
        DeliveryProviders.Bilbayt) ===
      DeliveryProviders.Bilbayt
    );
  }

  public getReferralProgramCredits(): number {
    return this.getCountryBasedSetting(this.initialSettings.affiliateProgram)?.credits;
  }

  public getReferralProgramMinOrderValue(): number {
    return this.getCountryBasedSetting(this.initialSettings.affiliateProgram)?.minOrderValue;
  }

  public isServiceTypeBasedOnCategories(serviceType: number): boolean {
    return this.getServiceTypeDetails().some(
      (serviceTypeDetails) => serviceTypeDetails.serviceType === serviceType && serviceTypeDetails.hasCategories,
    );
  }

  public getServiceTypeBasedOnCategories(): number {
    return this.getServiceTypeDetails().find((serviceTypeDetails) => serviceTypeDetails.hasCategories)?.serviceType;
  }

  public getServiceTypesBasedOnTimeSlots(): Array<number> {
    return this.getServiceTypeDetails()
      .filter((serviceTypeDetails) => serviceTypeDetails.basedOnTimeSlots)
      .map((serviceTypeDetails) => serviceTypeDetails.serviceType);
  }

  public getServiceTypesBasedOnDateTime(): Array<number> {
    return this.getServiceTypeDetails()
      .filter((serviceTypeDetails) => serviceTypeDetails.basedOnDateTime)
      .map((serviceTypeDetails) => serviceTypeDetails.serviceType);
  }

  public isOrderForNowAvailableInServiceType(selectedServiceType: number): boolean {
    return this.getSelectedServiceTypeOptionDetails(selectedServiceType)?.supportsOrderForNow;
  }

  public isServiceTypeBasedOnTimeSlots(selectedServiceType: number): boolean {
    return this.getSelectedServiceTypeOptionDetails(selectedServiceType)?.basedOnTimeSlots;
  }

  public isServiceTypeBasedOnDateTime(selectedServiceType: number): boolean {
    return this.getSelectedServiceTypeOptionDetails(selectedServiceType)?.basedOnDateTime;
  }

  public supportsVendorsItemsOnHomePage(selectedServiceType: number): boolean {
    return (
      this.getSelectedServiceTypeOptionDetails(selectedServiceType)?.supportsVendorsItemsOnHomePage &&
      this.getTestGroupValueById('vendorsItemsOnHomePage') === 'Enabled'
    );
  }

  public isAdditionalCardSupported(serviceTypes: number): boolean {
    return this.getServiceTypeDetails().some(
      (serviceTypeDetails) => serviceTypeDetails.serviceType === serviceTypes && serviceTypeDetails.supportsAdditionalCard,
    );
  }

  public isQuickAddToCartSupported(serviceTypes: number): boolean {
    return this.getServiceTypeDetails().some(
      (serviceTypeDetails) => serviceTypeDetails.serviceType === serviceTypes && serviceTypeDetails.supportsQuickAddToCart,
    );
  }

  public isWhatsAppChatEnabled(): boolean {
    const whatsAppChatSettings = this.getCountryBasedSetting(this.getPlatformBasedSetting(this.initialSettings.whatsAppChat));
    return whatsAppChatSettings?.enabled ?? false;
  }

  public getWhatsAppChatLink(): string {
    const whatsAppChatSettings = this.getCountryBasedSetting(this.getPlatformBasedSetting(this.initialSettings.whatsAppChat));
    return whatsAppChatSettings?.liveChatLink ?? null;
  }

  public getTapSettingsPublicKey(): string {
    return this.getCountryBasedSetting(this.getEnvironmentBasedSetting(this.initialSettings.tapSettings))?.publicKey;
  }

  public getTapSettingsMerchantId(): string {
    return this.getCountryBasedSetting(this.getEnvironmentBasedSetting(this.initialSettings.tapSettings))?.merchantId;
  }

  public getHealthCheckStatus(): Observable<HealthCheckStatus> {
    const url = this.config.routes.getHealthCheck.url;
    return this.http.get<HealthCheckStatus>(url);
  }

  public getAllServiceTypesIncludedInServiceTypeCombination(serviceTypes: number): Array<number> {
    return (
      this.getServiceTypeDetails()
        .filter((serviceTypeDetails) => (serviceTypeDetails.serviceType & serviceTypes) === serviceTypeDetails.serviceType)
        .map((serviceTypeDetails) => serviceTypeDetails.serviceType) ?? []
    );
  }

  public getAllServiceTypes(): number {
    return this.getServiceTypes().reduce((total, serviceType) => total + serviceType, 0);
  }

  public getIntersectingServiceTypes(serviceTypes1: number, serviceTypes2: number): Array<number> {
    return this.getAllServiceTypesIncludedInServiceTypeCombination(serviceTypes1).filter((serviceType1) =>
      this.getAllServiceTypesIncludedInServiceTypeCombination(serviceTypes2).some((serviceType2) => serviceType1 === serviceType2),
    );
  }

  public isServiceTypeEmptyOrValid(serviceTypes: number): boolean {
    return !serviceTypes || this.isServiceTypeValid(serviceTypes);
  }

  public isServiceTypeSingle(serviceTypes: number): boolean {
    return this.getServiceTypes().some((s) => s === serviceTypes);
  }

  public isServiceTypeOptionValid(serviceType: number): boolean {
    return this.getServiceTypeOptions({ includeHidden: true }).some((serviceTypeOption) => serviceTypeOption.serviceTypes === serviceType);
  }

  // Important: we check the service type options and not the service types because we
  // need to make sure the service type is available for the selected country
  public isServiceTypeValid(serviceTypes: number): boolean {
    return this.getAllServiceTypesIncludedInServiceTypeCombination(serviceTypes).some((serviceType) =>
      this.getServiceTypeOptions({ includeHidden: true }).some((serviceTypeOption) => serviceTypeOption.serviceTypes === serviceType),
    );
  }

  public isOptionalMapInCheckoutFeatureEnabled(): boolean {
    return this.initialSettings?.featureToggles?.optionalMapInCheckoutFeature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.optionalMapInCheckoutFeature) ?? false
      : false;
  }

  public isReferralProgramFeatureEnabled(): boolean {
    return this.initialSettings?.featureToggles?.enableAffiliateProgramFeature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.enableAffiliateProgramFeature) ?? false
      : false;
  }

  public isTapV3FeatureEnabled(): boolean {
    return this.initialSettings?.featureToggles?.tapV3Feature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.tapV3Feature) ?? false
      : false;
  }

  public isUseAddressGeoCodeResultsFeatureEnabled(): boolean {
    return this.initialSettings?.featureToggles?.useAddressGeoCodeResultsFeature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.useAddressGeoCodeResultsFeature) ?? false
      : false;
  }

  public shouldHideSkipLocationOptionInMap(): boolean {
    return this.initialSettings?.featureToggles?.hideSkipLocationOptionInMapFeature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.hideSkipLocationOptionInMapFeature) ?? false
      : false;
  }

  public shouldShowMapFirstInAreaSelectionModalPage(): boolean {
    return this.initialSettings?.featureToggles?.showMapFirstInAreaSelectionModalPageFeature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.showMapFirstInAreaSelectionModalPageFeature) ?? false
      : false;
  }

  public shouldSendApiTimingData(): boolean {
    return this.initialSettings?.featureToggles?.sendApiTimingDataFeature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.sendApiTimingDataFeature) ?? false
      : false;
  }

  public isFirebaseSocialLoginFeatureEnabled(): boolean {
    return this.initialSettings?.featureToggles?.firebaseSocialLoginFeature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.firebaseSocialLoginFeature) ?? false
      : false;
  }

  public isVerifyAddressBasedOnCoordinatesFeatureEnabled(): boolean {
    return this.initialSettings?.featureToggles?.verifyAddressBasedOnCoordinatesFeature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.verifyAddressBasedOnCoordinatesFeature) ?? false
      : false;
  }

  public shouldEnableFoodServiceTypeBasedOnApiEndpointFeature(): boolean {
    return this.initialSettings?.featureToggles?.enableFoodServiceTypeBasedOnApiEndpointFeature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.enableFoodServiceTypeBasedOnApiEndpointFeature) ?? false
      : false;
  }

  public isQuickAddToCartFeatureEnabled(): boolean {
    return this.initialSettings?.featureToggles?.enableQuickAddToCartFeature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.enableQuickAddToCartFeature) ?? false
      : false;
  }

  public shouldShowLoyaltyProgramNextRewardItemFeatureEnabled(): boolean {
    return this.initialSettings?.featureToggles?.showLoyaltyProgramNextRewardItemFeature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.showLoyaltyProgramNextRewardItemFeature) ?? false
      : false;
  }

  public shouldShowLoyaltyProgramNextRewardCartFeatureEnabled(): boolean {
    return this.initialSettings?.featureToggles?.showLoyaltyProgramNextRewardCartFeature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.showLoyaltyProgramNextRewardCartFeature) ?? false
      : false;
  }

  public shouldShowLoyaltyProgramNextRewardCheckoutFeatureEnabled(): boolean {
    return this.initialSettings?.featureToggles?.showLoyaltyProgramNextRewardCheckoutFeature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.showLoyaltyProgramNextRewardCheckoutFeature) ?? false
      : false;
  }

  public isApplePayNativeExperienceFeatureEnabled(): boolean {
    return this.platformService.areCordovaPluginsAvailable &&
      this.platformService.isIos &&
      this.initialSettings?.featureToggles?.enableApplePayNativeExperienceFeature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.enableApplePayNativeExperienceFeature) ?? false
      : false;
  }

  public isFoodServiceTypeEnabled(): boolean {
    return this.shouldEnableFoodServiceTypeBasedOnApiEndpointFeature()
      ? this.featureTogglesFromApi?.foodServiceTypeFeature || false
      : this.initialSettings?.featureToggles?.enableFoodServiceTypeFeature
      ? this.getCountryBasedSetting(this.initialSettings.featureToggles.enableFoodServiceTypeFeature)
      : false;
  }

  public loadTestGroupsFromStorage(): void {
    const initializedTestGroups = new Map<string, string>();

    if (this.stateService.state?.testGroups?.length) {
      for (const testGroup of this.stateService.state.testGroups) {
        initializedTestGroups.set(testGroup.id, testGroup.value);
      }
    }

    this.testGroups = initializedTestGroups;
  }

  public getTestGroupValueById(testGroupId: string): string {
    return this.testGroups.get(testGroupId);
  }

  public getTestGroups(): Array<TestGroup> {
    const testGroups: Array<TestGroup> = [];

    this.testGroups.forEach((value, id) => {
      testGroups.push({ id, value });
    });

    return testGroups;
  }

  public getFoodServiceType(): number {
    return 64;
  }

  public getWishlistsLongPollingIntervalForCurrentCountry(): number {
    return this.getCountryBasedSetting(this.initialSettings.wishlistsLongPollingInterval);
  }

  public getBannersAutoplayConfigForCurrentCountry(): { enabled: boolean; delay?: number } {
    return this.getCountryBasedSetting(this.initialSettings.bannersAutoplayConfig) || { enabled: false };
  }

  private changeLanguage(language: Language): boolean {
    if (this.selectedLanguage && language.value === this.selectedLanguage.value) {
      return false;
    }

    this.stateService.update({ language });
    this.selectedLanguage = language;
    this.updateTranslateServiceAndDirection(this.selectedLanguage);
    return true;
  }

  private changeCountry(country: Country): boolean {
    if (this.selectedCountry && country.value === this.selectedCountry.value) {
      return false;
    }

    this.stateService.update({ country });
    this.selectedCountry = country;
    return true;
  }

  private getFeatureTogglesFromApi({ loggedIn }: { loggedIn: boolean }): Observable<FeatureTogglesFromApi> {
    const defaultFeatureTogglesFromApi: FeatureTogglesFromApi = { foodServiceTypeFeature: false };

    return loggedIn && this.shouldEnableFoodServiceTypeBasedOnApiEndpointFeature()
      ? this.http.get<FeatureTogglesFromApi>(this.config.routes.getFeatureToggles.url).pipe(
          catchError((error: Error) => {
            this.loggerService.info({
              component: 'SettingsService',
              message: "Couldn't load feature toggles from API",
              details: { error },
            });
            return of(defaultFeatureTogglesFromApi);
          }),
        )
      : of(defaultFeatureTogglesFromApi);
  }

  private updateTestGroups(testGroupsFromInitialSettings: TestGroups): void {
    if (testGroupsFromInitialSettings) {
      const testGroupsValues: { [key: string]: string } = {};

      for (const testExperimentName of Object.keys(testGroupsFromInitialSettings)) {
        if (testGroupsFromInitialSettings[testExperimentName].length === 1) {
          // The test experiment is over and there's only one group
          // the user can be put into
          testGroupsValues[testExperimentName] = testGroupsFromInitialSettings[testExperimentName][0].groupValue;
        } else {
          // The user was already assigned to a group before so we must
          // keep the user in the same group
          if (
            this.testGroups.has(testExperimentName) &&
            testGroupsFromInitialSettings[testExperimentName].find((group) => group.groupValue === this.testGroups.get(testExperimentName))
          ) {
            testGroupsValues[testExperimentName] = this.testGroups.get(testExperimentName);
          } else {
            // We need to get a random number and calculate the test experiment value
            // based on the probabilities of each test group

            let currentMin = 0;
            let selectedTestGroupValue: string;

            const selectedRandomNumber = Math.random();

            for (const testExperimentGroup of testGroupsFromInitialSettings[testExperimentName]) {
              const min = currentMin;
              const max = Helpers.sumWithPrecision(currentMin + testExperimentGroup.groupValueProb);

              if (selectedRandomNumber >= min && selectedRandomNumber <= max) {
                selectedTestGroupValue = testExperimentGroup.groupValue;
                break;
              }
              currentMin = max;
            }

            testGroupsValues[testExperimentName] = selectedTestGroupValue;
          }
        }
      }

      this.updateLocalTestGroups(testGroupsValues);
    }
  }

  private updateLocalTestGroups(groupsFromInitialSettings: { [key: string]: string }): void {
    if (groupsFromInitialSettings) {
      const testGroups: Array<TestGroup> = [];

      const keys = Object.keys(groupsFromInitialSettings);

      if (keys?.length) {
        keys.forEach((key) => {
          testGroups.push({ id: key, value: groupsFromInitialSettings[key] });
        });
      }

      const allTestGroups = new Map<string, string>();

      if (testGroups?.length) {
        testGroups.forEach((testGroup) => {
          allTestGroups.set(testGroup.id, testGroup.value);
        });
      }

      this.testGroups = allTestGroups;

      this.stateService.update({ testGroups });
    }
  }

  private getLanguageBasedSetting<T>(object: { [key: string]: T }, languageCode?: LanguageCode): T {
    return this.getValue(object, languageCode ?? this.getLanguage().value);
  }

  private getCountryBasedSetting<T>(object: { [key: string]: T }, countryCode?: CountryCode): T {
    return this.getValue(object, countryCode ?? this.getCountry().code);
  }

  private getPlatformBasedSetting<T>(object: { [key: string]: T }): T {
    return this.getValue(object, this.platformService.isIos ? 'ios' : 'android');
  }

  private getEnvironmentBasedSetting<T>(object: { [key: string]: T }): T {
    return this.getValue(object, this.environmentService.environmentName === 'Production' ? 'prod' : 'dev');
  }

  private getValue<T>(object: { [key: string]: T }, key: string): T {
    const lowerCaseKey = key.toLowerCase();
    const upperCaseKey = key.toUpperCase();
    const objectOrDefault = object || {};

    return objectOrDefault[lowerCaseKey] || objectOrDefault[upperCaseKey];
  }

  private getSelectedServiceTypeOptionDetails(selectedServiceType: number): ServiceTypeDetails {
    return this.getServiceTypeDetails().find((serviceTypeDetails) => serviceTypeDetails.serviceType === selectedServiceType);
  }

  private updateTranslateServiceAndDirection(language: Language): void {
    this.translateService.use(language.value);
    this.translateService.setDefaultLang(language.value);
    this.updateMetaTags(language.value);

    if (this.windowService.isWindowDefined) {
      this.windowService.window.document.dir = language.rtl ? 'rtl' : 'ltr';
    }
  }

  private updateMetaTags(language: LanguageCode): void {
    if (this.platformService.isBrowser) {
      const appName = language === 'en' ? 'Bilbayt' : 'بالبيت';
      const appDescription = language === 'en' ? 'The food ordering app for groups and gatherings.' : 'افضل طريقة لطلب الأكل للجمعات.';
      const openGraphTitle =
        language === 'en'
          ? 'Order Catering Online In Kuwait, The UAE & KSA | Bilbayt'
          : 'Order Catering Online In Kuwait, The UAE & KSA | بالبيت';
      const openGraphDescription =
        language === 'en' ? 'The food ordering app for groups and gatherings.' : 'افضل طريقة لطلب الأكل للجمعات.';

      this.meta.updateTag({ name: 'application-name', content: appName }, "name='application-name'");
      this.meta.updateTag({ name: 'description', content: appDescription }, "name='description'");
      this.meta.updateTag({ property: 'og:title', content: openGraphTitle }, "property='og:title'");
      this.meta.updateTag({ property: 'og:description', content: openGraphDescription }, "property='og:description'");
    }
  }

  private getServiceTypeDetailsFromSettings(languageCode?: LanguageCode): Array<ServiceTypeDetails> {
    const isFoodServiceTypeEnabled = this.isFoodServiceTypeEnabled();
    const serviceTypesForCurrentLanguage = this.getLanguageBasedSetting(this.initialSettings.serviceTypes, languageCode ?? null);

    return isFoodServiceTypeEnabled
      ? serviceTypesForCurrentLanguage
      : serviceTypesForCurrentLanguage.filter((serviceTypeDetails) => serviceTypeDetails.serviceType !== this.getFoodServiceType());
  }

  private getServiceTypeOptionsFromSettings(): Array<ServiceTypeOption> {
    const isFoodServiceTypeEnabled = this.isFoodServiceTypeEnabled();
    const serviceTypesOptionsForCurrentLanguage = this.getCountryBasedSetting(
      this.getLanguageBasedSetting(this.initialSettings.serviceTypeOptions),
    );

    return isFoodServiceTypeEnabled
      ? serviceTypesOptionsForCurrentLanguage
      : serviceTypesOptionsForCurrentLanguage.filter((serviceTypeOption) => serviceTypeOption.serviceTypes !== this.getFoodServiceType());
  }
}
