<div
  fixed
  *ngIf="useFullWidthToastAnimation && toast?.message"
  @animatedFullWidthToast
  class="toast"
  [class]="toast.type"
  [class.actionable]="toast.showCloseButton"
  [style.top]="headerHeight"
  data-cy="toast"
>
  <span class="message animated-message" data-cy="toast-message">{{ toast.message }}</span>
  <span class="action animated-action" *ngIf="toast.showCloseButton" tappable (click)="onClose()" data-cy="toast-button">
    {{ toast.closeButtonText || ("OK" | translate) }}
  </span>
</div>

<div
  fixed
  *ngIf="!useFullWidthToastAnimation && toast?.message"
  @animatedToast
  class="toast"
  [class]="toast.type"
  [class.actionable]="toast.showCloseButton"
  [style.top]="headerHeight"
  data-cy="toast"
>
  <span class="message animated-message" data-cy="toast-message">{{ toast.message }}</span>
  <span class="action animated-action" *ngIf="toast.showCloseButton" tappable (click)="onClose()" data-cy="toast-button">
    {{ toast.closeButtonText || ("OK" | translate) }}
  </span>
</div>
