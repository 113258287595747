import { Inject, Injectable } from '@angular/core';

import { BranchPlugin } from './native-plugins/branch.plugin';
import { CleverTapPlugin } from './native-plugins/clever-tap.plugin';
import { MixpanelPlugin } from './native-plugins/mixpanel.plugin';

import { of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';

import { AnalyticsEvent } from '../models/analytics-event.model';
import { CountryCode } from '../models/country.model';
import { DeepLinkClick } from '../models/deep-link-click.model';
import { LanguageCode } from '../models/language.model';
import { MultiLanguageValue } from '../models/multi-language-value.model';
import { PushNotification } from '../models/push-notification.model';
import { SelectedTimeSlot } from '../models/selected-time-slots-per-vendor.model';
import { UniversalLink } from '../models/universal-link.model';

import { AccountService } from './account.service';
import { AreasService } from './areas.service';
import { CartService } from './cart.service';
import { DateTimeService } from './date-time.service';
import { SettingsService } from './settings.service';
import { PlatformService } from './ssr/platform.service';
import { StateService } from './state.service';

import { AnalyticsConfig, getBranchEventName, TOKEN_ANALYTICS_CONFIG } from '../config/analytics.config';

import { Helpers } from '../helpers';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private isReady: boolean;
  private currentDistinctId: string;

  // We keep the last vendor viewed details so that we can include the vendor id and name
  // later when tracking events like the item added event because those components don't
  // have the vendor details
  private lastVendorViewedEventDetails: { id: number; nameEn: string; nameAr: string };

  constructor(
    private branchPlugin: BranchPlugin,
    private mixpanelPlugin: MixpanelPlugin,
    private cleverTapPlugin: CleverTapPlugin,
    private accountService: AccountService,
    private platformService: PlatformService,
    private settingsService: SettingsService,
    private stateService: StateService,
    private cartService: CartService,
    private areasService: AreasService,
    private dateTimeService: DateTimeService,
    @Inject(TOKEN_ANALYTICS_CONFIG) private analyticsConfig: AnalyticsConfig,
  ) {}

  private get userData(): Record<string, string> {
    const userDetails = this.accountService.userDetails;

    // We initialize all the properties so they can be present in the
    // object as '' if we don't have information for each property
    const userData = {
      [this.analyticsConfig.propertyName.userId]: '',
      [this.analyticsConfig.propertyName.userEmail]: '',
      [this.analyticsConfig.propertyName.userFirstName]: '',
      [this.analyticsConfig.propertyName.userLastName]: '',
      [this.analyticsConfig.propertyName.userPhoneNumber]: '',
    };

    if (userDetails) {
      userData[this.analyticsConfig.propertyName.userId] = userDetails.userId;
      userData[this.analyticsConfig.propertyName.userEmail] = userDetails.email;
      userData[this.analyticsConfig.propertyName.userFirstName] = userDetails.firstName;
      userData[this.analyticsConfig.propertyName.userLastName] = userDetails.lastName;
      userData[this.analyticsConfig.propertyName.userPhoneNumber] = userDetails.phoneNumber;
    }

    return userData;
  }

  private get commonData(): Record<string, string> {
    const currentLanguage = this.settingsService.getLanguage();
    const currentCountry = this.settingsService.getCountry();

    // We initialize all the properties so they can be present in the
    // object as '' if we don't have information for each property
    const commonData = {
      [this.analyticsConfig.propertyName.country]: '',
      [this.analyticsConfig.propertyName.language]: '',
      [this.analyticsConfig.propertyName.platform]: this.platformService.applicationPlatformName,
    };

    if (currentLanguage) {
      commonData[this.analyticsConfig.propertyName.language] = currentLanguage.value;
    }

    if (currentCountry) {
      commonData[this.analyticsConfig.propertyName.country] = currentCountry.code;
    }

    return commonData;
  }

  private get cleverTapCommonData(): Record<string, string | number> {
    const currentLanguage = this.settingsService.getLanguage();
    const currentCountry = this.settingsService.getCountry();
    const currentArea = this.cartService.getArea();

    const commonData: Record<string, string | number> = {
      [this.analyticsConfig.cleverTapPropertyName.source]: this.analyticsConfig.cleverTapSource,
      [this.analyticsConfig.cleverTapPropertyName.platform]: this.platformService.applicationPlatformName,
    };

    if (currentLanguage?.value) {
      commonData[this.analyticsConfig.cleverTapPropertyName.language] = currentLanguage.value.toLowerCase();
    }

    if (currentCountry?.code) {
      commonData[this.analyticsConfig.cleverTapPropertyName.country] = currentCountry.code.toLowerCase();
    }

    if (currentArea?.areaId) {
      commonData[this.analyticsConfig.cleverTapPropertyName.areaId] = currentArea.areaId;
    }

    return commonData;
  }

  private get testGroupsData(): Record<string, string> {
    const testGroups = this.settingsService.getTestGroups() || [];
    return testGroups.reduce((acc, g) => ({ ...acc, [`b_${g.id}`]: g.value }), {});
  }

  public initializeAnalytics(): Promise<void> {
    this.cleverTapPlugin.initialize();

    return Promise.all([this.mixpanelPlugin.init(), this.branchPlugin.initSession()]).then(() =>
      this.identifyExistingUser().then(() => {
        this.isReady = true;
      }),
    );
  }

  public initializeUserPropertiesUpdaters(): void {
    this.initializeCleverTapAreaProfilePropertyUpdater();
    this.initializeCleverTapCartEmptyProfilePropertyUpdater();
    this.initializeCleverTapCountryLanguageProfilePropertyUpdater();
    this.initializeMixpanelCountryLanguageProfilePropertyUpdater();
  }

  public trackView(event: AnalyticsEvent): Promise<void> {
    return this.trackEventUsingAllAnalyticSdks(event);
  }

  public trackEvent(event: AnalyticsEvent): Promise<void> {
    return this.trackEventUsingAllAnalyticSdks(event);
  }

  public identifyExistingUser(): Promise<void> {
    return Promise.all([this.identifyExistingMixpanelUser(), this.identifyBranchUser(), this.identifyCleverTapUser()]).then(() => {});
  }

  public identifyNewUser(): Promise<void> {
    return Promise.all([this.identifyNewMixpanelUser(), this.identifyBranchUser(), this.identifyCleverTapUser()]).then(() => {});
  }

  public resetCurrentUser(): Promise<void> {
    return Promise.all([this.resetMixpanelUser(), this.resetBranchUser()]).then(() => {});
  }

  public setUserProperties(peopleProperties: Record<string, unknown>): Promise<unknown> {
    return this.mixpanelPlugin.set(peopleProperties);
  }

  public setUserPropertiesOnce(peopleProperties: Record<string, unknown>): Promise<unknown> {
    return this.mixpanelPlugin.setOnce(peopleProperties);
  }

  public unionPeopleProperties(peopleProperties: Record<string, unknown>): Promise<unknown> {
    return this.mixpanelPlugin.union(peopleProperties);
  }

  public getCurrentDistinctId(): string {
    return this.currentDistinctId;
  }

  public trackServiceTypeSelectedCleverTapEvent(serviceType: number): void {
    const serviceTypeNames = this.settingsService.getServiceTypeNameForEveryLanguage(serviceType);

    this.cleverTapPlugin.recordEventWithNameAndProps(this.analyticsConfig.cleverTapEventName.serviceTypeSelected, {
      ...this.cleverTapCommonData,
      [this.analyticsConfig.cleverTapPropertyName.serviceTypeId]: serviceType,
      [this.analyticsConfig.cleverTapPropertyName.serviceTypeNameEn]: serviceTypeNames.en,
      [this.analyticsConfig.cleverTapPropertyName.serviceTypeNameAr]: serviceTypeNames.ar,
    });
  }

  public trackSearchPerformedCleverTapEvent(
    searchTerm: string,
    results: {
      vendorsCount: number;
      serviceTypesCount: number;
      topVendors: Array<{ id: number; nameEn: string; nameAr: string }>;
      topServiceTypes: Array<{ id: number; nameEn: string; nameAr: string }>;
    },
  ): void {
    const properties: Record<string, string | number> = {
      ...this.cleverTapCommonData,
      [this.analyticsConfig.cleverTapPropertyName.searchTerm]: searchTerm,
      [this.analyticsConfig.cleverTapPropertyName.searchVendorsCount]: results.vendorsCount,
      [this.analyticsConfig.cleverTapPropertyName.searchServiceTypesCount]: results.serviceTypesCount,
    };

    if (results.topServiceTypes?.length) {
      if (results.topServiceTypes.length > 0) {
        properties[this.analyticsConfig.cleverTapPropertyName.searchFirstServiceTypeId] = results.topServiceTypes[0].id;
        properties[this.analyticsConfig.cleverTapPropertyName.searchFirstServiceTypeNameEn] = results.topServiceTypes[0].nameEn;
        properties[this.analyticsConfig.cleverTapPropertyName.searchFirstServiceTypeNameAr] = results.topServiceTypes[0].nameAr;
      }

      if (results.topServiceTypes.length > 1) {
        properties[this.analyticsConfig.cleverTapPropertyName.searchSecondServiceTypeId] = results.topServiceTypes[1].id;
        properties[this.analyticsConfig.cleverTapPropertyName.searchSecondServiceTypeNameEn] = results.topServiceTypes[1].nameEn;
        properties[this.analyticsConfig.cleverTapPropertyName.searchSecondServiceTypeNameAr] = results.topServiceTypes[1].nameAr;
      }

      if (results.topServiceTypes.length > 2) {
        properties[this.analyticsConfig.cleverTapPropertyName.searchThirdServiceTypeId] = results.topServiceTypes[2].id;
        properties[this.analyticsConfig.cleverTapPropertyName.searchThirdServiceTypeNameEn] = results.topServiceTypes[2].nameEn;
        properties[this.analyticsConfig.cleverTapPropertyName.searchThirdServiceTypeNameAr] = results.topServiceTypes[2].nameAr;
      }
    }

    if (results.topVendors?.length) {
      if (results.topVendors.length > 0) {
        properties[this.analyticsConfig.cleverTapPropertyName.searchFirstVendorId] = results.topVendors[0].id;
        properties[this.analyticsConfig.cleverTapPropertyName.searchFirstVendorNameEn] = results.topVendors[0].nameEn;
        properties[this.analyticsConfig.cleverTapPropertyName.searchFirstVendorNameAr] = results.topVendors[0].nameAr;
      }

      if (results.topVendors.length > 1) {
        properties[this.analyticsConfig.cleverTapPropertyName.searchSecondVendorId] = results.topVendors[1].id;
        properties[this.analyticsConfig.cleverTapPropertyName.searchSecondVendorNameEn] = results.topVendors[1].nameEn;
        properties[this.analyticsConfig.cleverTapPropertyName.searchSecondVendorNameAr] = results.topVendors[1].nameAr;
      }

      if (results.topVendors.length > 2) {
        properties[this.analyticsConfig.cleverTapPropertyName.searchThirdVendorId] = results.topVendors[2].id;
        properties[this.analyticsConfig.cleverTapPropertyName.searchThirdVendorNameEn] = results.topVendors[2].nameEn;
        properties[this.analyticsConfig.cleverTapPropertyName.searchThirdVendorNameAr] = results.topVendors[2].nameAr;
      }
    }

    this.cleverTapPlugin.recordEventWithNameAndProps(this.analyticsConfig.cleverTapEventName.searchPerformed, properties);
  }

  public trackVendorViewedCleverTapEvent(details: { id: number; nameEn: string; nameAr: string }): void {
    this.lastVendorViewedEventDetails = details;

    this.cleverTapPlugin.recordEventWithNameAndProps(this.analyticsConfig.cleverTapEventName.vendorViewed, {
      ...this.cleverTapCommonData,
      [this.analyticsConfig.cleverTapPropertyName.vendorId]: details.id,
      [this.analyticsConfig.cleverTapPropertyName.vendorNameEn]: details.nameEn,
      [this.analyticsConfig.cleverTapPropertyName.vendorNameAr]: details.nameAr,
    });
  }

  public trackItemViewedCleverTapEvent(details: {
    id: number;
    nameEn: string;
    nameAr: string;
    vendorId: number;
    serviceType: number;
  }): void {
    const serviceTypeNames = this.settingsService.getServiceTypeNameForEveryLanguage(details.serviceType);

    this.cleverTapPlugin.recordEventWithNameAndProps(this.analyticsConfig.cleverTapEventName.itemViewed, {
      ...this.cleverTapCommonData,
      [this.analyticsConfig.cleverTapPropertyName.itemId]: details.id,
      [this.analyticsConfig.cleverTapPropertyName.itemNameEn]: details.nameEn,
      [this.analyticsConfig.cleverTapPropertyName.itemNameAr]: details.nameAr,
      [this.analyticsConfig.cleverTapPropertyName.vendorId]: details.vendorId,
      [this.analyticsConfig.cleverTapPropertyName.vendorNameEn]:
        this.lastVendorViewedEventDetails?.id === details.vendorId ? this.lastVendorViewedEventDetails.nameEn : undefined,
      [this.analyticsConfig.cleverTapPropertyName.vendorNameAr]:
        this.lastVendorViewedEventDetails?.id === details.vendorId ? this.lastVendorViewedEventDetails.nameAr : undefined,
      [this.analyticsConfig.cleverTapPropertyName.serviceTypeId]: details.serviceType,
      [this.analyticsConfig.cleverTapPropertyName.serviceTypeNameEn]: serviceTypeNames ? serviceTypeNames.en : undefined,
      [this.analyticsConfig.cleverTapPropertyName.serviceTypeNameAr]: serviceTypeNames ? serviceTypeNames.ar : undefined,
    });
  }

  public trackItemAddedCleverTapEvent(details: {
    id: number;
    nameEn: string;
    nameAr: string;
    amount: number;
    currency: string;
    vendorId: number;
    serviceType: number;
  }): void {
    const serviceTypeNames = this.settingsService.getServiceTypeNameForEveryLanguage(details.serviceType);

    this.cleverTapPlugin.recordEventWithNameAndProps(this.analyticsConfig.cleverTapEventName.itemAdded, {
      ...this.cleverTapCommonData,
      [this.analyticsConfig.cleverTapPropertyName.itemId]: details.id,
      [this.analyticsConfig.cleverTapPropertyName.itemNameEn]: details.nameEn,
      [this.analyticsConfig.cleverTapPropertyName.itemNameAr]: details.nameAr,
      [this.analyticsConfig.cleverTapPropertyName.amount]: details.amount,
      [this.analyticsConfig.cleverTapPropertyName.currency]: details.currency,
      [this.analyticsConfig.cleverTapPropertyName.vendorId]: details.vendorId,
      [this.analyticsConfig.cleverTapPropertyName.vendorNameEn]:
        this.lastVendorViewedEventDetails?.id === details.vendorId ? this.lastVendorViewedEventDetails.nameEn : undefined,
      [this.analyticsConfig.cleverTapPropertyName.vendorNameAr]:
        this.lastVendorViewedEventDetails?.id === details.vendorId ? this.lastVendorViewedEventDetails.nameAr : undefined,
      [this.analyticsConfig.cleverTapPropertyName.serviceTypeId]: details.serviceType,
      [this.analyticsConfig.cleverTapPropertyName.serviceTypeNameEn]: serviceTypeNames ? serviceTypeNames.en : undefined,
      [this.analyticsConfig.cleverTapPropertyName.serviceTypeNameAr]: serviceTypeNames ? serviceTypeNames.ar : undefined,
    });
  }

  public trackCheckoutStartedCleverTapEvent(details: { amount: number; currency: string }): void {
    this.cleverTapPlugin.recordEventWithNameAndProps(this.analyticsConfig.cleverTapEventName.checkoutStarted, {
      ...this.cleverTapCommonData,
      [this.analyticsConfig.cleverTapPropertyName.amount]: details.amount,
      [this.analyticsConfig.cleverTapPropertyName.currency]: details.currency,
    });
  }

  public setCleverTapCountryAndLanguageProperties(selectedCountryCode: CountryCode, selectedLanguageCode: LanguageCode): void {
    this.cleverTapPlugin.setProfileProperties({
      [this.analyticsConfig.cleverTapUserPropertyName.country]: selectedCountryCode,
      [this.analyticsConfig.cleverTapUserPropertyName.language]: selectedLanguageCode,
    });
  }

  public trackOrganicAppOpenEvent(): void {
    void this.trackEvent({
      name: this.analyticsConfig.eventName.appOpen,
      data: { [this.analyticsConfig.propertyName.type]: this.analyticsConfig.attributionType.organic },
    });

    void this.setUserPropertiesOnce({
      [this.analyticsConfig.mixpanelUserPropertyName.lastAppOpenDate]: this.dateTimeService.now.toDate(),
    });
  }

  public trackOrganicAppOpenFromPushNotificationEvent(notification: PushNotification): void {
    void this.trackEvent({
      name: this.analyticsConfig.eventName.pushNotificationOpen,
      data: {
        [this.analyticsConfig.propertyName.type]: notification.type ? notification.type : '',
        [this.analyticsConfig.propertyName.orderId]: notification.orderId ? notification.orderId : '',
        [this.analyticsConfig.propertyName.catererId]: notification.catererId ? notification.catererId : '',
        [this.analyticsConfig.propertyName.menuItemId]: notification.menuItemId ? notification.menuItemId : '',
        [this.analyticsConfig.propertyName.serviceType]: notification.serviceTypes ? notification.serviceTypes : '',
        [this.analyticsConfig.propertyName.collectionId]: notification.marketingCollectionId ? notification.marketingCollectionId : '',
        [this.analyticsConfig.propertyName.wishlistId]: notification.wishlistId ? notification.wishlistId : '',
      },
    });
  }

  public trackOrganicAppInstallEvent(): void {
    void this.trackEvent({
      name: this.analyticsConfig.eventName.appInstall,
      data: { [this.analyticsConfig.propertyName.type]: this.analyticsConfig.attributionType.organic },
    });

    void this.setUserPropertiesOnce({
      [this.analyticsConfig.mixpanelUserPropertyName.installDate]: this.dateTimeService.now.toDate(),
    });
  }

  public trackAttributedAppOpenFromUniversalLinkEvent(linkData: UniversalLink): void {
    void this.trackEvent({
      name: this.analyticsConfig.eventName.appOpen,
      data: {
        [this.analyticsConfig.propertyName.type]: this.analyticsConfig.attributionType.attributed,
        [this.analyticsConfig.propertyName.orderId]: linkData.orderId ? linkData.orderId : '',
        [this.analyticsConfig.propertyName.catererId]: linkData.catererId ? linkData.catererId : '',
        [this.analyticsConfig.propertyName.menuItemId]: linkData.menuItemId ? linkData.menuItemId : '',
        [this.analyticsConfig.propertyName.serviceType]: linkData.serviceTypes ? linkData.serviceTypes : '',
        [this.analyticsConfig.propertyName.collectionId]: linkData.marketingCollectionId ? linkData.marketingCollectionId : '',
        [this.analyticsConfig.propertyName.wishlistId]: linkData.wishlistId ? linkData.wishlistId : '',
        [this.analyticsConfig.propertyName.campaignId]: linkData.campaignId ? linkData.campaignId : '',
        [this.analyticsConfig.propertyName.source]: linkData.campaignSource ? linkData.campaignSource : '',
        [this.analyticsConfig.propertyName.couponCode]: linkData.couponCode ? linkData.couponCode : '',
      },
    });

    void this.setUserProperties({
      [this.analyticsConfig.mixpanelUserPropertyName.lastAppOpenDate]: this.dateTimeService.now.toDate(),
    });
  }

  public trackAttributedAppOpenFromPushNotificationEvent(notificationData: PushNotification): void {
    void this.trackEvent({
      name: this.analyticsConfig.eventName.appOpen,
      data: {
        [this.analyticsConfig.propertyName.type]: this.analyticsConfig.attributionType.attributed,
        [this.analyticsConfig.propertyName.orderId]: notificationData.orderId ? notificationData.orderId : '',
        [this.analyticsConfig.propertyName.catererId]: notificationData.catererId ? notificationData.catererId : '',
        [this.analyticsConfig.propertyName.menuItemId]: notificationData.menuItemId ? notificationData.menuItemId : '',
        [this.analyticsConfig.propertyName.serviceType]: notificationData.serviceTypes ? notificationData.serviceTypes : '',
        [this.analyticsConfig.propertyName.collectionId]: notificationData.marketingCollectionId
          ? notificationData.marketingCollectionId
          : '',
        [this.analyticsConfig.propertyName.wishlistId]: notificationData.wishlistId ? notificationData.wishlistId : '',
        [this.analyticsConfig.propertyName.campaignId]: notificationData.campaignId ? notificationData.campaignId : '',
        [this.analyticsConfig.propertyName.source]: this.analyticsConfig.sourceName.pushNotification,
      },
    });

    void this.setUserProperties({
      [this.analyticsConfig.mixpanelUserPropertyName.lastAppOpenDate]: this.dateTimeService.now.toDate(),
    });
  }

  public trackAttributedAppInstallEvent(linkData: UniversalLink): void {
    void this.trackEvent({
      name: this.analyticsConfig.eventName.appInstall,
      data: {
        [this.analyticsConfig.propertyName.type]: this.analyticsConfig.attributionType.attributed,
        [this.analyticsConfig.propertyName.orderId]: linkData.orderId ? linkData.orderId : '',
        [this.analyticsConfig.propertyName.catererId]: linkData.catererId ? linkData.catererId : '',
        [this.analyticsConfig.propertyName.menuItemId]: linkData.menuItemId ? linkData.menuItemId : '',
        [this.analyticsConfig.propertyName.serviceType]: linkData.serviceTypes ? linkData.serviceTypes : '',
        [this.analyticsConfig.propertyName.collectionId]: linkData.marketingCollectionId ? linkData.marketingCollectionId : '',
        [this.analyticsConfig.propertyName.wishlistId]: linkData.wishlistId ? linkData.wishlistId : '',
        [this.analyticsConfig.propertyName.campaignId]: linkData.campaignId ? linkData.campaignId : '',
        [this.analyticsConfig.propertyName.source]: linkData.campaignSource ? linkData.campaignSource : '',
        [this.analyticsConfig.propertyName.couponCode]: linkData.couponCode ? linkData.couponCode : '',
      },
    });

    void this.setUserPropertiesOnce({
      [this.analyticsConfig.mixpanelUserPropertyName.installDate]: this.dateTimeService.now.toDate(),
    });
  }

  public trackAttributedReferralProgramAppOpenEvent(linkData: UniversalLink): void {
    void this.trackEvent({
      name: this.analyticsConfig.eventName.appOpen,
      data: {
        [this.analyticsConfig.propertyName.type]: this.analyticsConfig.attributionType.attributed,
        [this.analyticsConfig.propertyName.orderId]: linkData.orderId ? linkData.orderId : '',
        [this.analyticsConfig.propertyName.catererId]: linkData.catererId ? linkData.catererId : '',
        [this.analyticsConfig.propertyName.menuItemId]: linkData.menuItemId ? linkData.menuItemId : '',
        [this.analyticsConfig.propertyName.serviceType]: linkData.serviceTypes ? linkData.serviceTypes : '',
        [this.analyticsConfig.propertyName.collectionId]: linkData.marketingCollectionId ? linkData.marketingCollectionId : '',
        [this.analyticsConfig.propertyName.wishlistId]: linkData.wishlistId ? linkData.wishlistId : '',
        [this.analyticsConfig.propertyName.referralUserId]: linkData.affiliateUserId ? linkData.affiliateUserId : '',
        [this.analyticsConfig.propertyName.source]: this.analyticsConfig.sourceName.referralProgram,
        [this.analyticsConfig.propertyName.couponCode]: linkData.couponCode ? linkData.couponCode : '',
      },
    });

    void this.setUserProperties({
      [this.analyticsConfig.mixpanelUserPropertyName.lastAppOpenDate]: this.dateTimeService.now.toDate(),
    });
  }

  public trackAttributedReferralProgramAppInstallEvent(linkData: UniversalLink): void {
    void this.trackEvent({
      name: this.analyticsConfig.eventName.appInstall,
      data: {
        [this.analyticsConfig.propertyName.type]: this.analyticsConfig.attributionType.attributed,
        [this.analyticsConfig.propertyName.orderId]: linkData.orderId ? linkData.orderId : '',
        [this.analyticsConfig.propertyName.catererId]: linkData.catererId ? linkData.catererId : '',
        [this.analyticsConfig.propertyName.menuItemId]: linkData.menuItemId ? linkData.menuItemId : '',
        [this.analyticsConfig.propertyName.serviceType]: linkData.serviceTypes ? linkData.serviceTypes : '',
        [this.analyticsConfig.propertyName.collectionId]: linkData.marketingCollectionId ? linkData.marketingCollectionId : '',
        [this.analyticsConfig.propertyName.wishlistId]: linkData.wishlistId ? linkData.wishlistId : '',
        [this.analyticsConfig.propertyName.referralUserId]: linkData.affiliateUserId ? linkData.affiliateUserId : '',
        [this.analyticsConfig.propertyName.source]: this.analyticsConfig.sourceName.referralProgram,
        [this.analyticsConfig.propertyName.couponCode]: linkData.couponCode ? linkData.couponCode : '',
      },
    });

    void this.setUserPropertiesOnce({
      [this.analyticsConfig.mixpanelUserPropertyName.installDate]: this.dateTimeService.now.toDate(),
    });
  }

  public trackModalAdOpenEvent(campaignId: number, source: string): void {
    void this.trackEvent({
      name: this.analyticsConfig.eventName.modalAdOpen,
      data: {
        [this.analyticsConfig.propertyName.campaignId]: campaignId ? campaignId : '',
        [this.analyticsConfig.propertyName.source]: source ? source : '',
      },
    });
  }

  public trackBannerAdOpenEvent(campaignId: number, source: string): void {
    void this.trackEvent({
      name: this.analyticsConfig.eventName.bannerAdOpen,
      data: {
        [this.analyticsConfig.propertyName.campaignId]: campaignId ? campaignId : '',
        [this.analyticsConfig.propertyName.source]: source ? source : '',
      },
    });
  }

  public trackDeepLinkClicksPeopleProperties(linkDataArray: Array<DeepLinkClick>): Promise<unknown> {
    if (!linkDataArray || !linkDataArray.length) {
      return Promise.resolve();
    }

    const valuesToTrack: Record<string, Array<string | number>> = {};

    linkDataArray.forEach((linkData) => {
      const campaignIdPropertyName = linkData.isNewInstall
        ? this.analyticsConfig.mixpanelUserPropertyName.installCampaignId
        : this.analyticsConfig.mixpanelUserPropertyName.appOpenCampaignId;

      const campaignSourcePropertyName = linkData.isNewInstall
        ? this.analyticsConfig.mixpanelUserPropertyName.installSource
        : this.analyticsConfig.mixpanelUserPropertyName.appOpenSource;

      if (!valuesToTrack[campaignIdPropertyName]) {
        valuesToTrack[campaignIdPropertyName] = [];
      }

      if (!valuesToTrack[campaignSourcePropertyName]) {
        valuesToTrack[campaignSourcePropertyName] = [];
      }

      valuesToTrack[campaignIdPropertyName].push(linkData.campaignId);
      valuesToTrack[campaignSourcePropertyName].push(linkData.source);
    });

    return this.unionPeopleProperties(valuesToTrack);
  }

  public trackMixpanelAddToCartAttempt(details: {
    vendorId: number;
    itemId: number;
    amount: number;
    serviceType: number;
    description?: string;
    isWizardUiEnabled?: boolean;
    isWizardUiSupported?: boolean;
  }): void {
    const eventDetails = {
      name: this.analyticsConfig.eventName.addToCartAttempt,
      data: {
        [this.analyticsConfig.propertyName.itemId]: details.itemId,
        [this.analyticsConfig.propertyName.vendorId]: details.vendorId,
        [this.analyticsConfig.propertyName.amount]: details.amount,
        [this.analyticsConfig.propertyName.serviceType]: details.serviceType,
        [this.analyticsConfig.propertyName.currency]: this.settingsService.currencySymbolEN,
        [this.analyticsConfig.propertyName.description]: details.description,
        [this.analyticsConfig.propertyName.isWizardUiEnabled]: !!details.isWizardUiEnabled,
        [this.analyticsConfig.propertyName.isWizardUiSupported]: !!details.isWizardUiSupported,
      },
    };

    void this.logMixpanelEvent(eventDetails);
  }

  public trackMixpanelAddToCartSuccess(details: {
    vendorId: number;
    itemId: number;
    amount: number;
    serviceType: number;
    description?: string;
    isWizardUiEnabled?: boolean;
    isWizardUiSupported?: boolean;
  }): void {
    const eventDetails = {
      name: this.analyticsConfig.eventName.addToCartSuccess,
      data: {
        [this.analyticsConfig.propertyName.itemId]: details.itemId,
        [this.analyticsConfig.propertyName.vendorId]: details.vendorId,
        [this.analyticsConfig.propertyName.amount]: details.amount,
        [this.analyticsConfig.propertyName.serviceType]: details.serviceType,
        [this.analyticsConfig.propertyName.currency]: this.settingsService.currencySymbolEN,
        [this.analyticsConfig.propertyName.description]: details.description,
        [this.analyticsConfig.propertyName.isWizardUiEnabled]: !!details.isWizardUiEnabled,
        [this.analyticsConfig.propertyName.isWizardUiSupported]: !!details.isWizardUiSupported,
      },
    };

    void this.logMixpanelEvent(eventDetails);
  }

  public trackMixpanelAddToCartFailure(details: {
    vendorId: number;
    itemId: number;
    amount: number;
    serviceType: number;
    description: string;
    isWizardUiEnabled?: boolean;
    isWizardUiSupported?: boolean;
    maxStepName?: string;
    maxStepNumber?: number;
    totalStepsNumber?: number;
  }): void {
    const eventDetails = {
      name: this.analyticsConfig.eventName.addToCartFailure,
      data: {
        [this.analyticsConfig.propertyName.itemId]: details.itemId,
        [this.analyticsConfig.propertyName.vendorId]: details.vendorId,
        [this.analyticsConfig.propertyName.amount]: details.amount,
        [this.analyticsConfig.propertyName.serviceType]: details.serviceType,
        [this.analyticsConfig.propertyName.currency]: this.settingsService.currencySymbolEN,
        [this.analyticsConfig.propertyName.description]: details.description,
        [this.analyticsConfig.propertyName.maxStepName]: details.maxStepName || undefined,
        [this.analyticsConfig.propertyName.maxStepNumber]: details.maxStepNumber || undefined,
        [this.analyticsConfig.propertyName.totalStepsNumber]: details.totalStepsNumber || undefined,
        [this.analyticsConfig.propertyName.isWizardUiEnabled]: !!details.isWizardUiEnabled,
        [this.analyticsConfig.propertyName.isWizardUiSupported]: !!details.isWizardUiSupported,
      },
    };

    void this.logMixpanelEvent(eventDetails);
  }

  private trackEventUsingAllAnalyticSdks(event: AnalyticsEvent): Promise<void> {
    if (!this.isReady || !event.name) {
      return Promise.resolve();
    }

    if (!event.data) {
      event.data = {};
    }

    if (event.includeAreaDateTime) {
      event.data[this.analyticsConfig.propertyName.areaId] = this.cartService.getArea()?.areaId ?? null;

      const dateTimePerVendor = this.cartService.getSelectedDateTimes();
      const timeSlotsPerVendor = this.cartService.getSelectedTimeSlots();

      event.data[this.analyticsConfig.propertyName.dateTimesPerVendor] =
        Object.keys(dateTimePerVendor)
          .map((vendorId) => `${vendorId}: ${dateTimePerVendor[vendorId]}`)
          .join(', ') ?? null;

      event.data[this.analyticsConfig.propertyName.timeSlotsPerVendor] =
        Object.keys(timeSlotsPerVendor)
          .map((vendorId) => {
            const timeSlotDetails = timeSlotsPerVendor[vendorId] as SelectedTimeSlot;
            return `${vendorId}: ${timeSlotDetails.timeSlot.date} ${timeSlotDetails.timeSlot.startTime.hour}:${timeSlotDetails.timeSlot.startTime.minute}-${timeSlotDetails.timeSlot.endTime.hour}:${timeSlotDetails.timeSlot.endTime.minute}`;
          })
          .join(', ') ?? null;
    }

    return Promise.all([this.logMixpanelEvent(event), this.logBranchEvent(event)]).then(() => {});
  }

  private logMixpanelEvent(event: AnalyticsEvent): Promise<unknown> {
    const data = this.replaceEmptyStringsByNull({
      ...event.data,
      ...this.commonData,
      ...this.userData,
      ...this.testGroupsData,
      [this.analyticsConfig.propertyName.baseSource]: this.analyticsConfig.baseSource,
    });

    return this.mixpanelPlugin.logEvent(event.name, data);
  }

  private logBranchEvent(event: AnalyticsEvent): Promise<void> {
    const branchEventName = getBranchEventName(event.name);

    if (!branchEventName) {
      return Promise.resolve();
    }

    const branchEventData: Record<string, unknown> = {};

    if (event.revenue) {
      branchEventData.revenue = event.revenue;
      branchEventData.currency = this.settingsService.getCurrentIsoCurrencyCode();
    }

    const orderId = event.data[this.analyticsConfig.propertyName.orderId] as number;

    if (orderId) {
      branchEventData.transactionID = orderId.toString();
    }

    const customData = this.formatPropertiesToBeOnlyString({
      ...event.data,
      ...this.commonData,
      ...this.userData,
      [this.analyticsConfig.propertyName.baseSource]: this.analyticsConfig.baseSource,
    });

    return this.branchPlugin.logEvent(branchEventName, { ...branchEventData, customData });
  }

  private formatPropertiesToBeOnlyString(data: Record<string, unknown>): Record<string, string> {
    const plainData: Record<string, string> = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] === null || data[key] === undefined) {
          // Convert null and undefined to empty strings
          plainData[key] = '';
        } else if (data[key] instanceof Array) {
          // Convert arrays to strings
          plainData[key] = (data[key] as []).join(',');
        } else if (typeof data[key] === 'boolean') {
          // Convert booleans to strings
          plainData[key] = data[key].toString();
        } else {
          // Convert it as string
          plainData[key] = `${data[key]}`;
        }
      }
    }

    return plainData;
  }

  private replaceEmptyStringsByNull(data: Record<string, unknown>): Record<string, string | null> {
    const updatedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        updatedData[key] = data[key] === '' ? null : data[key];
      }
    }

    return updatedData;
  }

  private updateLocalDistinctId(): Promise<void> {
    return this.mixpanelPlugin.getDistinctId().then((distinctId) => {
      this.currentDistinctId = distinctId;
    });
  }

  private identifyExistingMixpanelUser(): Promise<unknown> {
    const userDetails = this.accountService.userDetails;

    if (!userDetails || !userDetails.userId) {
      // The user is an anonymous user so we just need
      // to update the local copy of the distinctId
      return this.updateLocalDistinctId();
    }

    return this.mixpanelPlugin.identifyExistingUser(userDetails.userId).then((distinctId) => {
      this.currentDistinctId = distinctId;

      return this.setUserProperties({
        [this.analyticsConfig.mixpanelUserPropertyName.userId]: userDetails.userId,
        [this.analyticsConfig.mixpanelDefaultPeoplePropertyName.email]: userDetails.email,
        [this.analyticsConfig.mixpanelDefaultPeoplePropertyName.firstName]: userDetails.firstName,
        [this.analyticsConfig.mixpanelDefaultPeoplePropertyName.lastName]: userDetails.lastName,
        [this.analyticsConfig.mixpanelDefaultPeoplePropertyName.phone]: userDetails.phoneNumber,
        [this.analyticsConfig.mixpanelUserPropertyName.oneSignalUserId]: userDetails.userId,
      });
    });
  }

  private identifyNewMixpanelUser(): Promise<unknown> {
    const userDetails = this.accountService.userDetails;

    if (!userDetails || !userDetails.userId) {
      // The user is an anonymous user so we just need
      // to update the local copy of the distinctId
      return this.updateLocalDistinctId();
    }

    return this.mixpanelPlugin.registerUserAlias(userDetails.userId).then((distinctId) => {
      this.currentDistinctId = distinctId;

      return this.setUserProperties({
        [this.analyticsConfig.mixpanelUserPropertyName.userId]: userDetails.userId,
        [this.analyticsConfig.mixpanelDefaultPeoplePropertyName.email]: userDetails.email,
        [this.analyticsConfig.mixpanelDefaultPeoplePropertyName.firstName]: userDetails.firstName,
        [this.analyticsConfig.mixpanelDefaultPeoplePropertyName.lastName]: userDetails.lastName,
        [this.analyticsConfig.mixpanelDefaultPeoplePropertyName.phone]: userDetails.phoneNumber,
        [this.analyticsConfig.mixpanelUserPropertyName.oneSignalUserId]: userDetails.userId,
      });
    });
  }

  private identifyBranchUser(): Promise<unknown> {
    const userDetails = this.accountService.userDetails;

    if (!userDetails || !userDetails.userId) {
      return Promise.resolve();
    }

    return this.branchPlugin.setIdentity(userDetails.userId);
  }

  private identifyCleverTapUser(): Promise<unknown> {
    const userDetails = this.accountService.userDetails;

    if (!userDetails || !userDetails.userId) {
      return Promise.resolve();
    }

    this.cleverTapPlugin.identifyUser({
      [this.analyticsConfig.cleverTapDefaultPeoplePropertyName.identity]: userDetails.userId,
      [this.analyticsConfig.cleverTapDefaultPeoplePropertyName.email]: userDetails.email,
      [this.analyticsConfig.cleverTapDefaultPeoplePropertyName.phone]: userDetails.phoneNumber
        ? userDetails.phoneNumber.replace(/\s/g, '')
        : '',
      [this.analyticsConfig.cleverTapDefaultPeoplePropertyName.name]: `${userDetails.firstName} ${userDetails.lastName}`,
      [this.analyticsConfig.cleverTapUserPropertyName.userId]: userDetails.userId,
      [this.analyticsConfig.cleverTapUserPropertyName.firstName]: userDetails.firstName,
      [this.analyticsConfig.cleverTapUserPropertyName.lastName]: userDetails.lastName,
    });

    return Promise.resolve();
  }

  private initializeCleverTapAreaProfilePropertyUpdater(): void {
    // The selected area may come from the storage and it won't probably include the nameJson
    // property that was added recently so we need to get the list of areas from the API
    // https://bilbayt.atlassian.net/browse/WEB-6314

    this.stateService.state$
      .pipe(
        startWith(this.stateService.state),
        map((state) => state?.address?.area),
        distinctUntilChanged((previous, current) => previous?.areaId === current?.areaId),
        filter((selectedArea) => !!selectedArea),
        switchMap((selectedArea) =>
          // We need to check if the nameJson property is actually a localized property because
          // there was a bug in the API https://bilbayt.atlassian.net/browse/WEB-6714
          Helpers.isPropertyLocalized(selectedArea.nameJson) ? of(selectedArea) : this.areasService.getAreaById(selectedArea.areaId),
        ),
        tap((selectedAreaDetails) => {
          const areaNames = Helpers.isPropertyLocalized(selectedAreaDetails.nameJson)
            ? (JSON.parse(selectedAreaDetails.nameJson) as MultiLanguageValue)
            : null;

          this.cleverTapPlugin.setProfileProperties({
            [this.analyticsConfig.cleverTapUserPropertyName.areaId]: selectedAreaDetails.areaId,
            [this.analyticsConfig.cleverTapUserPropertyName.areaNameEn]: areaNames
              ? Helpers.getLocalizedMultiLanguageValue(areaNames, 'en')
              : null,
            [this.analyticsConfig.cleverTapUserPropertyName.areaNameAr]: areaNames
              ? Helpers.getLocalizedMultiLanguageValue(areaNames, 'ar')
              : null,
          });
        }),
        catchError(() => of(null)),
      )
      .subscribe();
  }

  private initializeCleverTapCartEmptyProfilePropertyUpdater(): void {
    this.cartService.cart$
      .pipe(
        map(() => this.cartService.isCartEmpty()),
        startWith(this.cartService.isCartEmpty()),
        distinctUntilChanged(),
        tap((isCartEmpty) => {
          this.cleverTapPlugin.setProfileProperties({ [this.analyticsConfig.cleverTapUserPropertyName.cartEmpty]: isCartEmpty });
        }),
      )
      .subscribe();
  }

  private initializeCleverTapCountryLanguageProfilePropertyUpdater(): void {
    this.settingsService.countryLanguage$
      .pipe(
        tap(() => {
          this.cleverTapPlugin.setProfileProperties({
            [this.analyticsConfig.cleverTapUserPropertyName.country]: this.settingsService.getCountry().code,
            [this.analyticsConfig.cleverTapUserPropertyName.language]: this.settingsService.getLanguage().value,
          });
        }),
      )
      .subscribe();
  }

  private initializeMixpanelCountryLanguageProfilePropertyUpdater(): void {
    this.settingsService.countryLanguage$
      .pipe(
        tap(() => {
          void this.setUserProperties({
            [this.analyticsConfig.mixpanelUserPropertyName.country]: this.settingsService.getCountry().code,
            [this.analyticsConfig.mixpanelUserPropertyName.language]: this.settingsService.getLanguage().value,
          });
        }),
      )
      .subscribe();
  }

  private resetMixpanelUser(): Promise<string | void> {
    return this.mixpanelPlugin.resetDistinctId().then(() => this.updateLocalDistinctId());
  }

  private resetBranchUser(): Promise<void> {
    return this.branchPlugin.logout();
  }
}
