import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { NavigationService } from '../services/navigation.service';
import { SettingsService } from '../services/settings.service';
import { PlatformService } from '../services/ssr/platform.service';
import { VendorsService } from '../services/vendors.service';

import { SettingsPageParams } from '../../pages/settings/settings-routing.module';

import { AppReadyGuard } from './app-ready.guard';

@Injectable({
  providedIn: 'root',
})
export class AppInitializedGuard implements CanActivate {
  constructor(
    private appReadyGuard: AppReadyGuard,
    private vendorsService: VendorsService,
    private settingsService: SettingsService,
    private platformService: PlatformService,
    private navigationService: NavigationService,
  ) {}

  canActivate() {
    return this.appReadyGuard.canActivate().pipe(
      map(() => this.checkAppUpdate()),
      filter((canContinue) => !!canContinue),
      map(() => this.checkCountryLanguageSettings()),
      filter((canContinue) => !!canContinue),
      switchMap(() => this.checkLocationSelected()),
      filter((canContinue) => !!canContinue),
    );
  }

  private checkAppUpdate(): boolean {
    if (this.settingsService.shouldUpdateApp()) {
      void this.navigationService.navigateTo('/update', true);

      return false;
    }

    return true;
  }

  private checkCountryLanguageSettings(): boolean {
    if (this.platformService.isBrowser) {
      return true;
    }

    const alreadySelectedCountryLanguage = this.settingsService.alreadySelectedCountryLanguage();

    if (alreadySelectedCountryLanguage) {
      return true;
    }

    const pageParams: SettingsPageParams = { shouldInitializeAndRedirectHome: true };

    void this.navigationService.navigateTo('/settings', true, { state: pageParams });

    return false;
  }

  private checkLocationSelected(): Observable<boolean> {
    if (this.platformService.isBrowser) {
      return of(true);
    }

    const selectedAddress = this.vendorsService.getAddressSearchFilters();

    if (!selectedAddress) {
      void this.navigationService.navigateTo('/location-identification', true);
      return of(false);
    }

    return of(true);
  }
}
