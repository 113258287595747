import { debounceTime, distinctUntilChanged, map, OperatorFunction, pipe } from 'rxjs';

import { AppState } from '../models/app-state.model';

import { Helpers } from '../helpers';

export const DEFAULT_DEBOUNCE_TIME_MS = 300;

export interface SelectorConfig<T> {
  debounceTimeMs?: number;
  compareFn?: (oldState: T, newState: T) => boolean;
}

export function select<T extends AppState, R>(mapFn: (state: T) => R, config?: SelectorConfig<R>): OperatorFunction<T, R> {
  const debounceTimeMs = config && config.debounceTimeMs ? config.debounceTimeMs : DEFAULT_DEBOUNCE_TIME_MS;
  const compareFn = config && config.compareFn && typeof config.compareFn === 'function' ? config.compareFn : Helpers.areEqual;

  return pipe(map(mapFn), debounceTime(debounceTimeMs), distinctUntilChanged(compareFn));
}
