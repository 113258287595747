import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { EnableLocationServicesModalPage } from './enable-location-services-modal.page';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule.forChild()],
  declarations: [EnableLocationServicesModalPage],
})
export class EnableLocationServicesModalPageModule {}
