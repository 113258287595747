import { Injectable } from '@angular/core';

import { Network } from '@awesome-cordova-plugins/network/ngx';

import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

type ConnectionStatus = 'online' | 'offline';

@Injectable({ providedIn: 'root' })
export class NetworkPlugin {
  public onConnectionChange$: Observable<ConnectionStatus>;

  private isDisconnected: boolean;
  private onConnectionChangeSource$: Subject<ConnectionStatus>;

  constructor(private network: Network) {
    this.onConnectionChangeSource$ = new Subject<ConnectionStatus>();
    this.onConnectionChange$ = this.onConnectionChangeSource$.pipe(distinctUntilChanged());
  }

  public isOnline(): boolean {
    return this.network.type.toLowerCase() !== 'none';
  }

  public isOffline(): boolean {
    return this.network.type.toLowerCase() === 'none';
  }

  public initializeNetworkEvents(): void {
    if (this.network.onDisconnect) {
      this.network.onDisconnect().subscribe(() => {
        this.isDisconnected = true;
        this.onConnectionChangeSource$.next('offline');
      });
    }

    if (this.network.onConnect) {
      this.network.onConnect().subscribe(() => {
        if (this.isDisconnected) {
          this.isDisconnected = false;
          this.onConnectionChangeSource$.next('online');
        }
      });
    }
  }
}
