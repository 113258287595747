import { InjectionToken } from '@angular/core';

// Token used to inject the configuration object
export const TOKEN_ANALYTICS_CONFIG = new InjectionToken<AnalyticsConfig>('analytics.config');

// Method that maps our custom event name to Branch's event name based on
// https://docs.branch.io/apps/v2event/#available-events
// This is important because Branch will send events to other analytics tools
// like Facebook and Snapchat only if the names are the ones included in this list
// +---------------------------------+-----------------------+
// | Custom event name               | Branch event name     |
// +---------------------------------+-----------------------+
// | AppInstall                      | INSTALL               |
// | AppOpen                         | OPEN                  |
// | MenuItemsDetailsPage_AddToCart  | ADD_TO_CART           |
// | CartPage                        | VIEW_CART             |
// | CheckoutPage                    | INITIATE_PURCHASE     |
// | CheckoutPage_Pay                | ADD_PAYMENT_INFO      |
// | OrderConfirmationPage           | PURCHASE              |
// | SearchResultsPage               | SEARCH                |
// | MenuItemDetailsPage             | VIEW_ITEM             |
// | CatererDetailsPage              | VIEW_ITEMS            |
// | SignUp                          | COMPLETE_REGISTRATION |
// | LogIn                           | LOGIN                 |
// +---------------------------------+-----------------------+
export const getBranchEventName = (eventName: string): string => {
  switch (eventName) {
    // INSTALL and OPEN events are tracked automatically by Branch SDK
    case ANALYTICS_CONFIG.eventName.appInstall:
    case ANALYTICS_CONFIG.eventName.appOpen:
      return null;

    case ANALYTICS_CONFIG.eventName.menuItemDetailsPageAddToCart:
      return 'ADD_TO_CART';
    case ANALYTICS_CONFIG.pageName.cartPage:
      return 'VIEW_CART';
    case ANALYTICS_CONFIG.pageName.checkoutPage:
      return 'INITIATE_PURCHASE';
    case ANALYTICS_CONFIG.eventName.checkoutPagePay:
      return 'ADD_PAYMENT_INFO';
    case ANALYTICS_CONFIG.pageName.orderConfirmationPage:
      return 'PURCHASE';
    case ANALYTICS_CONFIG.pageName.searchResultsPage:
      return 'SEARCH';
    case ANALYTICS_CONFIG.pageName.menuItemDetailsPage:
      return 'VIEW_ITEM';
    case ANALYTICS_CONFIG.pageName.catererDetailsPage:
      return 'VIEW_ITEMS';
    case ANALYTICS_CONFIG.eventName.signUp:
      return 'COMPLETE_REGISTRATION';
    case ANALYTICS_CONFIG.eventName.logIn:
      return 'LOGIN';

    default:
      return eventName;
  }
};

export interface AnalyticsConfig {
  pageName: {
    searchResultsPage: string;
    catererDetailsPage: string;
    menuItemDetailsPage: string;
    marketingCollectionPage: string;
    cartPage: string;
    checkoutPage: string;
    checkoutCustomerDetailsPage: string;
    contactPage: string;
    tagsFilterPage: string;
    logInPage: string;
    signUpPage: string;
    errorPage: string;
    incidentPage: string;
    vendorReviewsPage: string;
    orderReviewModalPage: string;
    orderConfirmationPage: string;
    orderUpdatePage: string;
    areaSelectionPage: string;
    dateSelectionPage: string;
    timeSelectionPage: string;
    addressPage: string;
    savedAddressDetailsPage: string;
    googleMapsAutocompletePage: string;
    homePage: string;
    groceryVendorsPage: string;
    groceryVendorDetailsPage: string;
    groceryCategoriesPage: string;
    groceryCategoryItemsPage: string;
    groceryItemDetailsPage: string;
    vendorSearchPage: string;
    activeOrderDetailsModalPage: string;
  };

  eventName: {
    tagsFilterPageApply: string;
    tagsFilterPageClose: string;
    tagsFilterPageClearAll: string;
    globalSearchResultsPageSearch: string;
    catererDetailsPageShare: string;
    catererDetailsPageOpenReviews: string;
    catererDetailsPageSelectTab: string;
    catererDetailsPageTapViewOrder: string;
    catererDetailsPageOpenETA: string;
    catererDetailsPageOpenDeliveryInfo: string;
    catererDetailsPageOpenMinOrderInfo: string;
    catererDetailsPageOpenPolicyInfo: string;
    catererDetailsPageViewAllItems: string;
    menuItemDetailsPageAddToCart: string;
    menuItemDetailsPageAddToCartClick: string;
    menuItemDetailsPageShare: string;
    checkoutPageApplyCouponCode: string;
    checkoutPageCouponCodeAccepted: string;
    checkoutPageCouponCodeRejected: string;
    checkoutPagePay: string;
    checkoutPageForgotPassword: string;
    checkoutCustomerDetailsPageContinue: string;
    orderConfirmationPageShare: string;
    contactPageSubmit: string;
    orderDetailsPageCancelOrder: string;
    orderDetailsPageShare: string;
    orderReviewModalPageSubmit: string;
    orderUpdatePageSubmit: string;
    cartPageCheckout: string;
    cartPageChangeDateTime: string;
    cartPageChangeTimeSlot: string;
    pushNotificationOpen: string;
    signUp: string;
    logIn: string;
    logInPageClose: string;
    share: string;
    enjoysBilbaytYes: string;
    enjoysBilbaytNo: string;
    appInstall: string;
    appOpen: string;
    modalAdOpen: string;
    bannerAdOpen: string;
    savedAddressMapPageSkip: string;
    savedAddressMapPageConfirm: string;
    walletOpen: string;
    orderForNowSelected: string;
    orderForLaterSelected: string;
    serviceTypeOptionSelected: string;
    justBrowseSelected: string;
    referralProgramPageShare: string;
    areaDateTimePickerSelect: string;
    areaDateTimePickerSkip: string;
    areaDateTimePickerChange: string;
    trackingTransparencyModalPageDenied: string;
    trackingTransparencyModalPageGranted: string;
    vendorsPagePopularTagSelected: string;
    vendorsPagePopularTagRemoved: string;
    addToCartAttempt: string;
    addToCartSuccess: string;
    addToCartFailure: string;
    vendorDetailsPageChatRequested: string;
    vendorDetailsPageChatLinkClicked: string;
  };

  propertyName: {
    userId: string;
    userEmail: string;
    userFirstName: string;
    userLastName: string;
    userPhoneNumber: string;
    country: string;
    language: string;
    platform: string;
    catererId: string;
    vendorId: string;
    areaId: string;
    dateTime: string;
    dateTimesPerVendor: string;
    timeSlotsPerVendor: string;
    itemId: string;
    menuItemId: string;
    amount: string;
    currency: string;
    orderId: string;
    errorTitle: string;
    errorMessage: string;
    selectedTagId: string;
    selectedTagsId: string;
    selectedSorterId: string;
    couponCode: string;
    couponCodeError: string;
    paymentMethodId: string;
    name: string;
    mobile: string;
    email: string;
    comments: string;
    type: string;
    source: string;
    campaignId: string;
    serviceType: string;
    serviceTypeId: string;
    bilbaytNow: string;
    baseSource: string;
    fromFeaturedMenuItems: string;
    fromFeaturedVendors: string;
    countOfFeaturedMenuItems: string;
    saveAddres: string;
    fromUserDetailsPage: string;
    walletCredit: string;
    walletCreditAmount: string;
    description: string;
    cause: string;
    searchTerms: string;
    chatEmail: string;
    rating: string;
    fromDateSelectionPage: string;
    discount: string;
    discountAmount: string;
    amountPaid: string;
    collectionId: string;
    referralUserId: string;
    referralProgram: string;
    categoryName: string;
    orderTrackingStatusId: string;
    fromServiceTypeOptionPage: string;
    fromActiveOrderDetailsModalPage: string;
    updatedDateTimeAutomatically: string;
    soonestDeliveryDateTime: string;
    isGroceryItem: string;
    wishlistId: string;
    chatId: string;
    chatRequestedByUserId: string;
    chatRequestedByUserEmail: string;
    maxStepName: string;
    maxStepNumber: string;
    totalStepsNumber: string;
    isWizardUiEnabled: string;
    isWizardUiSupported: string;
  };

  mixpanelUserPropertyName: {
    country: string;
    language: string;
    installSource: string;
    installCampaignId: string;
    appOpenSource: string;
    appOpenCampaignId: string;
    lastAppOpenDate: string;
    installDate: string;
    signUpDate: string;
    socialLoginProvider: string;
    userId: string;
    oneSignalUserId: string;
  };

  mixpanelDefaultPeoplePropertyName: {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
  };

  cleverTapUserPropertyName: {
    country: string;
    language: string;
    userId: string;
    firstName: string;
    lastName: string;
    areaId: string;
    areaNameEn: string;
    areaNameAr: string;
    cartEmpty: string;
  };

  cleverTapDefaultPeoplePropertyName: {
    identity: string;
    name: string;
    email: string;
    phone: string;
  };

  cleverTapEventName: {
    serviceTypeSelected: string;
    searchPerformed: string;
    vendorViewed: string;
    itemViewed: string;
    itemAdded: string;
    checkoutStarted: string;
  };

  cleverTapPropertyName: {
    source: string;
    country: string;
    language: string;
    platform: string;
    serviceTypeId: string;
    serviceTypeNameEn: string;
    serviceTypeNameAr: string;
    searchTerm: string;
    searchVendorsCount: string;
    searchServiceTypesCount: string;
    searchFirstServiceTypeId: string;
    searchFirstServiceTypeNameEn: string;
    searchFirstServiceTypeNameAr: string;
    searchSecondServiceTypeId: string;
    searchSecondServiceTypeNameEn: string;
    searchSecondServiceTypeNameAr: string;
    searchThirdServiceTypeId: string;
    searchThirdServiceTypeNameEn: string;
    searchThirdServiceTypeNameAr: string;
    searchFirstVendorId: string;
    searchFirstVendorNameEn: string;
    searchFirstVendorNameAr: string;
    searchSecondVendorId: string;
    searchSecondVendorNameEn: string;
    searchSecondVendorNameAr: string;
    searchThirdVendorId: string;
    searchThirdVendorNameEn: string;
    searchThirdVendorNameAr: string;
    areaId: string;
    vendorId: string;
    vendorNameEn: string;
    vendorNameAr: string;
    itemId: string;
    itemNameEn: string;
    itemNameAr: string;
    amount: string;
    currency: string;
  };

  sourceName: {
    pushNotification: string;
    referralProgram: string;
  };

  attributionType: {
    attributed: string;
    organic: string;
  };

  chatRating: {
    positive: string;
    negative: string;
  };

  source: string;
  baseSource: string;
  cleverTapSource: string;

  leavingMenuItemPageCause: {
    minOptionsNotAdded: string;
    minAddonsNotAdded: string;
    minMenuItemAmountNotMet: string;
    menuItemNotAvailable: string;
    hitBackButton: string;
  };
}

export const ANALYTICS_CONFIG: AnalyticsConfig = {
  pageName: {
    searchResultsPage: 'SearchResultsPage',
    catererDetailsPage: 'CatererDetailsPage',
    menuItemDetailsPage: 'MenuItemDetailsPage',
    marketingCollectionPage: 'MarketingCollectionPage',
    cartPage: 'CartPage',
    checkoutPage: 'CheckoutPage',
    checkoutCustomerDetailsPage: 'CheckoutCustomerDetailsPage',
    contactPage: 'ContactPage',
    tagsFilterPage: 'TagsFilterPage',
    logInPage: 'LoginPage',
    signUpPage: 'SignupPage',
    errorPage: 'ErrorPage',
    incidentPage: 'IncidentPage',
    vendorReviewsPage: 'VendorReviewsPage',
    orderReviewModalPage: 'OrderReviewPage',
    orderConfirmationPage: 'OrderConfirmationPage',
    orderUpdatePage: 'SpecialRequestsPage',
    areaSelectionPage: 'AreaSelectionModalPage',
    dateSelectionPage: 'DateSelectionModalPage',
    timeSelectionPage: 'TimeSelectionModalPage',
    addressPage: 'AddressPage',
    savedAddressDetailsPage: 'SavedAddressDetailsPage',
    googleMapsAutocompletePage: 'SavedAddressAutocompletePage',
    homePage: 'HomePage',
    groceryVendorsPage: 'GroceryVendorsPage',
    groceryVendorDetailsPage: 'GroceryVendorDetailsPage',
    groceryCategoriesPage: 'GroceryVendorCategoriesPage',
    groceryCategoryItemsPage: 'GroceryVendorCategoryItemsPage',
    groceryItemDetailsPage: 'GroceryItemDetailsPage',
    vendorSearchPage: 'VendorSearchPage',
    activeOrderDetailsModalPage: 'ActiveOrderDetailsModalPage',
  },

  eventName: {
    tagsFilterPageApply: 'TagsFilterPage_Apply',
    tagsFilterPageClose: 'TagsFilterPage_Close',
    tagsFilterPageClearAll: 'TagsFilterPage_ClearAll',
    globalSearchResultsPageSearch: 'GlobalSearchResultsPage_Search',
    catererDetailsPageShare: 'CatererDetailsPage_Share',
    catererDetailsPageOpenReviews: 'CatererDetailsPage_OpenReviews',
    catererDetailsPageSelectTab: 'CatererDetailsPage_Select#name#Tab',
    catererDetailsPageTapViewOrder: 'CatererDetailsPage_TapViewOrder',
    catererDetailsPageOpenETA: 'CatererDetailsPage_OpenETA',
    catererDetailsPageOpenDeliveryInfo: 'CatererDetailsPage_OpenDeliveryInfo',
    catererDetailsPageOpenMinOrderInfo: 'CatererDetailsPage_OpenMinOrderInfo',
    catererDetailsPageOpenPolicyInfo: 'CatererDetailsPage_OpenPolicyInfo',
    catererDetailsPageViewAllItems: 'CatererDetailsPage_ViewAllItems',
    menuItemDetailsPageAddToCart: 'MenuItemDetailsPage_AddToCart',
    menuItemDetailsPageAddToCartClick: 'MenuItemDetailsPage_AddToCartClick',
    menuItemDetailsPageShare: 'MenuItemDetailsPage_Share',
    checkoutPageApplyCouponCode: 'CheckoutPage_ApplyCouponCode',
    checkoutPageCouponCodeAccepted: 'CheckoutPage_CouponCodeAccepted',
    checkoutPageCouponCodeRejected: 'CheckoutPage_CouponCodeRejected',
    checkoutPagePay: 'CheckoutPage_Pay',
    checkoutPageForgotPassword: 'CheckoutPage_ForgotPassword',
    checkoutCustomerDetailsPageContinue: 'CheckoutCustomerDetailsPage_Continue',
    orderConfirmationPageShare: 'OrderConfirmationPage_Share',
    contactPageSubmit: 'ContactPage_Submit',
    orderDetailsPageCancelOrder: 'OrderDetailsPage_CancelOrder',
    orderDetailsPageShare: 'OrderDetailsPage_Share',
    orderReviewModalPageSubmit: 'OrderReviewPage_Submit',
    orderUpdatePageSubmit: 'SpecialRequestsPage_Submit',
    cartPageCheckout: 'CartPage_Checkout',
    cartPageChangeDateTime: 'CartPage_ChangeDateTime',
    cartPageChangeTimeSlot: 'CartPage_ChangeTimeSlot',
    pushNotificationOpen: 'PushNotification_Open',
    signUp: 'SignUp',
    logIn: 'LogIn',
    logInPageClose: 'LogInPage_Close',
    share: 'Share',
    enjoysBilbaytYes: 'EnjoysBilbayt_Yes',
    enjoysBilbaytNo: 'EnjoysBilbayt_No',
    appInstall: 'AppInstall',
    appOpen: 'AppOpen',
    modalAdOpen: 'ModalAdOpen',
    bannerAdOpen: 'BannerAdOpen',
    savedAddressMapPageSkip: 'SavedAddressMapPage_Skip',
    savedAddressMapPageConfirm: 'SavedAddressMapPage_Confirm',
    walletOpen: 'WalletOpen',
    orderForNowSelected: 'OrderForNowSelected',
    orderForLaterSelected: 'OrderForLaterSelected',
    serviceTypeOptionSelected: '#name#Selected',
    justBrowseSelected: 'JustBrowseSelected',
    referralProgramPageShare: 'ReferralProgramPage_Share',
    areaDateTimePickerSelect: 'AreaDateTimePicker_Select',
    areaDateTimePickerSkip: 'AreaDateTimePicker_Skip',
    areaDateTimePickerChange: 'AreaDateTimePicker_Change',
    trackingTransparencyModalPageGranted: 'TrackingTransparencyModalPage_Granted',
    trackingTransparencyModalPageDenied: 'TrackingTransparencyModalPage_Denied',
    vendorsPagePopularTagSelected: 'VendorsPage_PopularTagSelected',
    vendorsPagePopularTagRemoved: 'VendorsPage_PopularTagRemoved',
    addToCartAttempt: 'AddToCart_Attempt',
    addToCartSuccess: 'AddToCart_Success',
    addToCartFailure: 'AddToCart_Failure',
    vendorDetailsPageChatRequested: 'VendorDetailsPage_ChatRequested',
    vendorDetailsPageChatLinkClicked: 'VendorDetailsPage_ChatLinkClicked',
  },

  propertyName: {
    userId: 'b_userId',
    userEmail: 'b_userEmail',
    userFirstName: 'b_userFirstName',
    userLastName: 'b_userLastName',
    userPhoneNumber: 'b_userPhoneNumber',
    country: 'b_country',
    language: 'b_language',
    platform: 'b_platform',
    areaId: 'b_areaId',
    dateTime: 'b_dateTime',
    dateTimesPerVendor: 'b_dateTimesPerVendor',
    timeSlotsPerVendor: 'b_timeSlotsPerVendor',
    catererId: 'b_catererId',
    vendorId: 'b_vendorId',
    itemId: 'b_itemId',
    menuItemId: 'b_menuItemId',
    amount: 'b_amount',
    currency: 'b_currency',
    orderId: 'b_orderId',
    errorTitle: 'b_errorTitle',
    errorMessage: 'b_errorMessage',
    selectedTagId: 'b_selectedTagId',
    selectedTagsId: 'b_selectedTagsId',
    selectedSorterId: 'b_selectedSorterId',
    couponCode: 'b_couponCode',
    couponCodeError: 'b_couponCodeError',
    paymentMethodId: 'b_paymentMethodId',
    name: 'b_name',
    mobile: 'b_mobile',
    email: 'b_email',
    comments: 'b_comments',
    type: 'b_type',
    source: 'b_source',
    campaignId: 'b_campaignId',
    serviceType: 'b_serviceType',
    serviceTypeId: 'b_serviceTypeId',
    bilbaytNow: 'b_bilbaytNow',
    baseSource: 'b_baseSource',
    fromFeaturedMenuItems: 'b_fromFeaturedMenuItems',
    fromFeaturedVendors: 'b_fromFeaturedVendors',
    countOfFeaturedMenuItems: 'b_countOfFeaturedMenuItems',
    saveAddres: 'b_saveAddress',
    fromUserDetailsPage: 'b_fromUserDetailsPage',
    walletCredit: 'b_walletCredit',
    walletCreditAmount: 'b_walletCreditAmount',
    description: 'b_description',
    cause: 'b_cause',
    searchTerms: 'b_searchTerms',
    chatEmail: 'b_chatEmail',
    rating: 'b_rating',
    fromDateSelectionPage: 'b_fromDateSelectionPage',
    discount: 'b_discount',
    discountAmount: 'b_discountAmount',
    amountPaid: 'b_amountPaid',
    collectionId: 'b_collectionId',
    referralUserId: 'b_referralUserId',
    referralProgram: 'b_referralProgram',
    categoryName: 'b_categoryName',
    orderTrackingStatusId: 'b_orderTrackingStatusId',
    fromServiceTypeOptionPage: 'b_fromServiceTypeOptionPage',
    fromActiveOrderDetailsModalPage: 'b_fromActiveOrderDetailsModalPage',
    updatedDateTimeAutomatically: 'b_updatedDateTimeAutomatically',
    soonestDeliveryDateTime: 'b_soonestDeliveryDateTime',
    isGroceryItem: 'b_isGroceryItem',
    wishlistId: 'b_wishlistId',
    chatId: 'b_chatId',
    chatRequestedByUserId: 'b_chatRequestedByUserId',
    chatRequestedByUserEmail: 'b_chatRequestedByUserEmail',
    maxStepName: 'b_maxStepName',
    maxStepNumber: 'b_maxStepNumber',
    totalStepsNumber: 'b_totalStepNumber',
    isWizardUiEnabled: 'b_isWizardUiEnabled',
    isWizardUiSupported: 'b_isWizardUiSupported',
  },

  mixpanelUserPropertyName: {
    country: 'Country of User',
    language: 'Language of User',
    installSource: 'Install Source',
    installCampaignId: 'Install CampaignId',
    appOpenSource: 'AppOpen Source',
    appOpenCampaignId: 'AppOpen CampaignId',
    lastAppOpenDate: 'Last AppOpen Date',
    installDate: 'Install Date',
    signUpDate: 'Sign Up Date',
    socialLoginProvider: 'Social Login Provider',
    userId: 'UserId',
    oneSignalUserId: '$onesignal_user_id',
  },

  mixpanelDefaultPeoplePropertyName: {
    email: '$email',
    phone: '$phone',
    firstName: '$first_name',
    lastName: '$last_name',
  },

  cleverTapUserPropertyName: {
    country: 'Country',
    language: 'Language',
    firstName: 'First Name',
    lastName: 'Last Name',
    userId: 'User Id',
    areaId: 'Area ID',
    areaNameEn: 'Area Name EN',
    areaNameAr: 'Area Name AR',
    cartEmpty: 'Cart Empty',
  },

  cleverTapDefaultPeoplePropertyName: {
    identity: 'Identity',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
  },

  cleverTapEventName: {
    serviceTypeSelected: 'Service Type Selected',
    searchPerformed: 'Search Performed',
    vendorViewed: 'Vendor Viewed',
    itemViewed: 'Item Viewed',
    itemAdded: 'Add to Cart',
    checkoutStarted: 'Checkout Started',
  },

  cleverTapPropertyName: {
    source: 'Source',
    country: 'Country',
    language: 'Language',
    platform: 'Platform',
    serviceTypeId: 'Service Type ID',
    serviceTypeNameEn: 'Service Type Name EN',
    serviceTypeNameAr: 'Service Type Name AR',
    searchTerm: 'Search Term',
    searchVendorsCount: 'Vendors Count',
    searchServiceTypesCount: 'Service Types Count',
    searchFirstServiceTypeId: 'First Service Type ID',
    searchFirstServiceTypeNameEn: 'First Service Type Name EN',
    searchFirstServiceTypeNameAr: 'First Service Type Name AR',
    searchSecondServiceTypeId: 'Second Service Type ID',
    searchSecondServiceTypeNameEn: 'Second Service Type Name EN',
    searchSecondServiceTypeNameAr: 'Second Service Type Name AR',
    searchThirdServiceTypeId: 'Third Service Type ID',
    searchThirdServiceTypeNameEn: 'Third Service Type Name EN',
    searchThirdServiceTypeNameAr: 'Third Service Type Name AR',
    searchFirstVendorId: 'First Vendor ID',
    searchFirstVendorNameEn: 'First Vendor Name EN',
    searchFirstVendorNameAr: 'First Vendor Name AR',
    searchSecondVendorId: 'Second Vendor ID',
    searchSecondVendorNameEn: 'Second Vendor Name EN',
    searchSecondVendorNameAr: 'Second Vendor Name AR',
    searchThirdVendorId: 'Third Vendor ID',
    searchThirdVendorNameEn: 'Third Vendor Name EN',
    searchThirdVendorNameAr: 'Third Vendor Name AR',
    areaId: 'Area ID',
    vendorId: 'Vendor ID',
    vendorNameEn: 'Vendor Name EN',
    vendorNameAr: 'Vendor Name AR',
    itemId: 'Item ID',
    itemNameEn: 'Item Name EN',
    itemNameAr: 'Item Name AR',
    amount: 'Amount',
    currency: 'Currency',
  },

  sourceName: {
    pushNotification: 'PushNotification',
    referralProgram: 'AffiliateProgram',
  },

  attributionType: {
    attributed: 'attributed',
    organic: 'organic',
  },

  chatRating: {
    positive: 'positive',
    negative: 'negative',
  },

  source: 'customer-app',
  baseSource: 'MobileApp',
  cleverTapSource: 'MobileApp',

  leavingMenuItemPageCause: {
    minOptionsNotAdded: 'MinOptionsNotAdded',
    minAddonsNotAdded: 'MinAddonsNotAdded',
    minMenuItemAmountNotMet: 'MinMenuItemAmountNotMet',
    menuItemNotAvailable: 'MenuItemNotAvailable',
    hitBackButton: 'HitBackButton',
  },
};
