<ng-container *ngIf="!plainText">
  <div class="address-details-container">
    <p *ngIf="name && !hideName" class="name" data-cy="address-name">{{ name }}</p>
    <p *ngIf="firstLine" class="first-line" data-cy="address-first-line">{{ firstLine }}</p>
    <p *ngIf="secondLine" class="second-line">{{ secondLine }}</p>
    <p *ngIf="thirdLine" class="third-line">{{ thirdLine }}</p>
    <p *ngIf="!hidePhoneNumber && phoneNumber" class="fourth-line">{{ phoneNumber }}</p>
  </div>
</ng-container>

<ng-container *ngIf="plainText && !singleLine">
  <p *ngIf="name && !hideName" class="plain-text-name">{{ name }}</p>
  <p class="plain-text-details">{{ oneLineAddress }}</p>
  <p *ngIf="!hidePhoneNumber" class="plain-text-phone-number">{{ phoneNumber }}</p>
</ng-container>

<p class="plain-text-details" *ngIf="plainText && singleLine">
  {{ (name && !hideName ? name + " - " : "") + oneLineAddress + (!hidePhoneNumber ? " " + phoneNumber : "") }}
</p>
