import { Injectable, InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

import { VendorDetailsSsr } from '../../models/vendor-details-ssr.model';

export const LOCAL_VENDOR_DETAILS_LOADER = new InjectionToken<string>('LOCAL_VENDOR_DETAILS_LOADER');

export declare abstract class LocalVendorDetailsLoader {
  public abstract getVendorDetails(vendorSlug: string): Observable<VendorDetailsSsr>;
}

@Injectable({ providedIn: 'root' })
export class LocalVendorDetailsBrowserLoader implements LocalVendorDetailsLoader {
  public getVendorDetails(): Observable<VendorDetailsSsr> {
    return new Observable((observer) => {
      observer.next(undefined);
      observer.complete();
    });
  }
}
