<ng-container *ngFor="let province of provincesToShow">
  <ng-container *ngIf="province.areas?.length > 0">
    <div tappable class="header" data-cy="province" (click)="onToggleProvince(province)">
      <h3 data-cy="province-name">{{ province.name }}</h3>
      <ion-icon
        src="/assets/custom-icons/b-angle-right.svg"
        [class.icon-rotate]="province.animationState === AnimationState.Expanded"
      ></ion-icon>
    </div>

    <div [@animatedContent]="province.animationState" style="overflow: hidden">
      <div
        tappable
        (click)="onSelect(area)"
        class="area"
        [class.area-selected]="selectedArea?.areaId === area.areaId"
        *ngFor="let area of province.areas"
        data-cy="area"
      >
        <ion-icon src="/assets/custom-icons/b-check-circle-solid.svg"></ion-icon>
        <p data-cy="area-name">{{ area.name }}</p>
      </div>
    </div>
  </ng-container>
</ng-container>
