import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { OrderUpdateModalPage } from './order-update-modal.page';

import { PipesModule } from '../../shared/pipes/pipes.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule.forChild(), PipesModule],
  declarations: [OrderUpdateModalPage],
})
export class OrderUpdateModalPageModule {}
