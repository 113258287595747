import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AccountService } from '../services/account.service';
import { EnvironmentService } from '../services/environment.service';
import { ResponseTimingService } from '../services/response-timing.service';

import { shouldSkipHttpInterceptors } from '../config/app.config';

import dayjs from 'dayjs';

@Injectable()
export class ResponseTimingInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const environmentService = this.injector.get(EnvironmentService);

    // This interceptor should only be used in PROD!
    // https://bilbayt.atlassian.net/browse/CA-1990

    if (shouldSkipHttpInterceptors(request.url) || environmentService.environmentName !== 'Production') {
      return next.handle(request);
    }

    const accountService = this.injector.get(AccountService);
    const responseTimingService = this.injector.get(ResponseTimingService);

    const createdAt = dayjs.utc().toISOString();
    const startTime = dayjs();

    const userId = accountService.userDetails?.userId ?? null;
    const httpRequest = `${request.method} ${request.urlWithParams}`;

    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          responseTimingService.logResponseTimingData({
            userId,
            httpRequest,
            statusCode: event.status,
            responseTime: dayjs().diff(startTime),
            createdAt,
          });
        }
      }),
      catchError((event) => {
        if (event instanceof HttpErrorResponse) {
          responseTimingService.logResponseTimingData({
            userId,
            httpRequest,
            statusCode: event.status,
            responseTime: dayjs().diff(startTime),
            createdAt,
          });
        }

        return throwError(event);
      }),
    );
  }
}
