import { Injectable, OnDestroy } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { AnalyticsService } from '../services/analytics.service';
import { AppService } from '../services/app.service';
import { PushNotificationsService } from '../services/push-notifications.service';
import { StateService } from '../services/state.service';

import { selectNotNil } from '../operators/select-not-nil';
import { Effect } from './';

@Injectable({ providedIn: 'root' })
export class PushNotificationsEffects implements Effect, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private appService: AppService,
    private stateService: StateService,
    private analyticsService: AnalyticsService,
    private pushNotificationsService: PushNotificationsService,
  ) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  public initialize(): void {
    this.trackOpenEvent();
    this.handlePushNotificationRedirection();
  }

  private trackOpenEvent(): void {
    this.stateService.state$
      .pipe(
        selectNotNil((state) => state.pendingPushNotification),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((notification) => {
        !this.pushNotificationsService.shouldBeAttributed(notification)
          ? this.analyticsService.trackOrganicAppOpenFromPushNotificationEvent(notification)
          : this.analyticsService.trackAttributedAppOpenFromPushNotificationEvent(notification);
      });
  }

  private handlePushNotificationRedirection(): void {
    this.stateService.state$
      .pipe(
        selectNotNil((state) => state.pendingPushNotification),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((notification) => {
        if (!this.pushNotificationsService.shouldRedirect(notification)) {
          this.pushNotificationsService.clearPendingPushNotification();
          return;
        }

        if (this.appService.isHomePageLoaded()) {
          // Links need to be handled in the AppComponent because otherwise
          // we don't have access to the router
          this.pushNotificationsService.processPendingPushNotification$.next(notification);
        }
      });
  }
}
