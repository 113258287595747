import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { mdTransitionAnimation } from '@ionic/angular';

import { BrowserCartModalPage, BrowserCartModalPageIdentifier } from '../../modals/browser-cart-modal/browser-cart-modal.page';
import { OrderReviewModalPage, OrderReviewModalPageIdentifier } from '../../modals/order-review-modal/order-review-modal.page';

import { TargetPage } from '../enums/target-page.enum';

import { AccountService } from './account.service';
import { LoginService } from './login.service';
import { ModalService } from './modal.service';
import { NavigationService } from './navigation.service';
import { OverlayService } from './overlay.service';
import { SettingsService } from './settings.service';
import { PlatformService } from './ssr/platform.service';
import { WindowService } from './ssr/window.service';
import { StateService } from './state.service';
import { VendorsService } from './vendors.service';

import { GroceryCategoriesPageParams } from '../../pages/grocery-categories/grocery-categories-routing.module';
import { VendorSearchPageParams } from '../../pages/vendor-search/vendor-search-routing.module';

import {
  browserCartModalEnterAnimation,
  browserCartModalLeaveAnimation,
  ModalAnimationOptions,
} from '../transitions/browser-modal-transition';

@Injectable({ providedIn: 'root' })
export class DeepLinkService {
  constructor(
    private windowService: WindowService,
    private loginService: LoginService,
    private modalService: ModalService,
    private stateService: StateService,
    private navigationService: NavigationService,
    private overlayService: OverlayService,
    private accountService: AccountService,
    private translateService: TranslateService,
    private settingsService: SettingsService,
    private platformService: PlatformService,
    private vendorsService: VendorsService,
  ) {}

  public openVendorDetailsPage({ serviceTypes, vendorId, itemId }: { serviceTypes: number; vendorId: number; itemId?: number }): void {
    if (!vendorId || (serviceTypes && !this.settingsService.isServiceTypeValid(serviceTypes))) {
      return;
    }

    const urlBasedOnId = itemId ? `/vendors/id/${vendorId}/${itemId}` : `/vendors/id/${vendorId}`;

    if (!this.platformService.isBrowser) {
      void this.navigationService.navigateTo(urlBasedOnId, false, {
        animation: mdTransitionAnimation,
        fragment: serviceTypes ? serviceTypes.toString() : undefined,
      });
      return;
    }

    const { vendorSlug, vendorServiceTypes } = this.vendorsService.getVendorSlugAndServiceTypeById(vendorId);
    const urlBasedOnIdOrSlug = vendorSlug ? (itemId ? `/vendors/${vendorSlug}/${itemId}` : `/vendors/${vendorSlug}`) : urlBasedOnId;

    void this.navigationService.navigateTo(urlBasedOnIdOrSlug, false, {
      fragment: serviceTypes ? serviceTypes.toString() : vendorServiceTypes ? vendorServiceTypes.toString() : '',
    });
  }

  public openVendorCategoriesPage(vendorId: number, params: GroceryCategoriesPageParams): void {
    if (!vendorId || !params?.activeCategoryInfo) {
      return;
    }

    const urlBasedOnId = params.activeCategoryInfo.hasCategories
      ? `/vendors/id/${vendorId}/categories/${params.activeCategoryInfo.categoryId}`
      : `/vendors/id/${vendorId}/categories/${params.activeCategoryInfo.categoryId}/items`;

    if (!this.platformService.isBrowser) {
      void this.navigationService.navigateTo(urlBasedOnId, false, { state: params });
    } else {
      const { vendorSlug, vendorServiceTypes } = this.vendorsService.getVendorSlugAndServiceTypeById(vendorId);

      if (vendorServiceTypes && !params.selectedServiceType) {
        params = {
          ...params,
          selectedServiceType: params.selectedServiceType || vendorServiceTypes,
        };
      }

      void this.navigationService.navigateTo(
        vendorSlug
          ? params.activeCategoryInfo.hasCategories
            ? `/vendors/${vendorSlug}/categories/${params.activeCategoryInfo.categoryId}`
            : `/vendors/${vendorSlug}/categories/${params.activeCategoryInfo.categoryId}/items`
          : urlBasedOnId,
        false,
        { state: params },
      );
    }
  }

  public openMarketingCollectionDetailsPage(collectionId: number): void {
    if (!collectionId) {
      return;
    }

    void this.navigationService.navigatePreservingFragmentAndQueryParamsTo(`/collections/marketing/${collectionId}`, false, {
      animation: mdTransitionAnimation,
    });
  }

  public openWishlistDetailsPage(wishlistId: number): void {
    void this.navigationService.navigateTo(`/wishlists/${wishlistId}`, false, { animation: mdTransitionAnimation });
  }

  public openVendorSearch(vendorId: number, params: VendorSearchPageParams): void {
    let url = `/vendors/id/${vendorId}/search`;

    if (this.platformService.isBrowser) {
      const { vendorSlug } = this.vendorsService.getVendorSlugAndServiceTypeById(vendorId);

      if (vendorSlug) {
        url = `/vendors/${vendorSlug}/search`;
      }
    }

    void this.navigationService.navigatePreservingFragmentAndQueryParamsTo(url, false, {
      state: params,
      animation: mdTransitionAnimation,
    });
  }

  public openOrderRelatedPage(targetPage: TargetPage, orderId: number): void {
    const openTargetPageCallback = () => {
      if (targetPage === TargetPage.OrderDetails) {
        void this.navigationService.navigateTo('/user/orders', false, {
          queryParams: { orderId },
          animation: mdTransitionAnimation,
        });
      } else {
        void this.modalService.showModal({
          component: OrderReviewModalPage,
          id: OrderReviewModalPageIdentifier,
          componentProps: { orderId },
        });
      }
    };

    if (this.accountService.isLoggedIn()) {
      openTargetPageCallback();
    } else {
      const message =
        targetPage === TargetPage.OrderDetails
          ? (this.translateService.instant('ERROR_MESSAGE.ORDER_DETAILS_LOGIN_REQUEST') as string)
          : (this.translateService.instant('ERROR_MESSAGE.ORDER_REVIEW_LOGIN_REQUEST') as string);

      void this.loginService.showSocialLogin(message).then(() => {
        const isLoggedIn = this.accountService.isLoggedIn();

        if (isLoggedIn) {
          // The user is now logged in so we can
          // open the target page
          openTargetPageCallback();
        } else {
          // The user refuses to login so we just
          // show a toast with an error message
          const msg =
            targetPage === TargetPage.OrderDetails
              ? (this.translateService.instant('ERROR_MESSAGE.ORDER_DETAILS_LOGIN_REFUSED') as string)
              : (this.translateService.instant('ERROR_MESSAGE.ORDER_REVIEW_LOGIN_REFUSED') as string);

          this.overlayService.showToast({ type: 'error', message: msg });
        }
      });
    }
  }

  public openAppUpdateOptionalPage(): void {
    void this.navigationService.navigateTo('/update-optional', false, {
      animation: mdTransitionAnimation,
    });
  }

  public openRegisterPage(): void {
    void this.loginService.showSocialLogin();
  }

  public openCartPage(): void {
    if (this.platformService.isBrowser) {
      const windowRef = this.windowService.isWindowDefined ? this.windowService.window : undefined;

      void this.modalService.showModal({
        component: BrowserCartModalPage,
        id: BrowserCartModalPageIdentifier,
        enterAnimation: (baseEl: HTMLElement, opts: ModalAnimationOptions) => browserCartModalEnterAnimation(windowRef, baseEl, opts),
        leaveAnimation: (baseEl: HTMLElement, opts: ModalAnimationOptions) => browserCartModalLeaveAnimation(windowRef, baseEl, opts),
      });

      return;
    }

    void this.navigationService.navigateTo('/cart', false, { animation: mdTransitionAnimation });
  }

  public openReferralProgramPage(): void {
    if (this.accountService.isLoggedIn()) {
      void this.navigationService.navigateTo('/referral-program', false, {
        animation: mdTransitionAnimation,
      });
    }
  }

  public getTopSearchedServiceType(): number {
    return this.stateService.state?.topSearchedServiceType;
  }

  public getTopSearchedVendor(): number {
    return this.stateService.state?.topSearchedVendor;
  }
}
