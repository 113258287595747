import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { OrderPaymentModalPage } from './order-payment-modal.page';

import { PipesModule } from '../../shared/pipes/pipes.module';

import { PaymentComponentModule } from '../../shared/components/payment/payment.module';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, IonicModule, PaymentComponentModule, TranslateModule.forChild(), PipesModule],
  declarations: [OrderPaymentModalPage],
})
export class OrderPaymentModalPageModule {}
