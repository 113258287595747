import { UrlMatchResult, UrlSegment } from '@angular/router';

import { PreloadableRouteData, PreloadableRoutes } from './app-routing.module';
import { OrdersPageParams } from './pages/orders/orders-routing.module';
import { SavedAddressesPageParams } from './pages/saved-addresses/saved-addresses-routing.module';

import { AddressNotSelectedGuard } from './core/guards/address-not-selected.guard';
import { AppInitializedGuard } from './core/guards/app-initialized.guard';
import { AppNotUpdatedGuard } from './core/guards/app-not-updated.guard';
import { AppReadyGuard } from './core/guards/app-ready.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { BrowserCountrySelectionPageGuard } from './core/guards/browser-country-selection-page.guard';
import { CountryLanguageGuard } from './core/guards/country-language.guard';
import { InvalidCartGuard } from './core/guards/invalid-cart.guard';
import { NoEntryPageCordovaGuard } from './core/guards/no-entry-page-cordova.guard';
import { NoEntryPageGuard } from './core/guards/no-entry-page.guard';
import { OnlinePaymentErrorGuard } from './core/guards/online-payment-error.guard';
import { ReferralProgramRedirectionGuard } from './core/guards/referral-program-redirection.guard';
import { VendorDetailsPageBackwardsCompatUrlRedirectionGuard } from './core/guards/vendor-details-page-backwards-compat-url-redirection.guard';
import { VendorDetailsUrlParamsValidationGuard } from './core/guards/vendor-details-url-params-validation.guard';

export const vendorDetailsBackwardCompatUrls = [
  // Backwards compat routes used in the old website or other routes that are valid but discouraged
  // NOTE: when creating deep links we're using menuId (and not menuid) so we need to include both
  // routes here as route matching in Angular is case-sensitive
  { path: 'caterers/id/:vendorId', canActivate: [VendorDetailsPageBackwardsCompatUrlRedirectionGuard], children: [] },
  { path: 'caterers/id/:vendorId/menuid/:itemId', canActivate: [VendorDetailsPageBackwardsCompatUrlRedirectionGuard], children: [] },
  { path: 'caterers/id/:vendorId/menuId/:itemId', canActivate: [VendorDetailsPageBackwardsCompatUrlRedirectionGuard], children: [] },
  { path: 'caterers/slug/:vendorSlug', canActivate: [VendorDetailsPageBackwardsCompatUrlRedirectionGuard], children: [] },
  { path: 'vendors/id/:vendorId/menuid/:itemId', canActivate: [VendorDetailsPageBackwardsCompatUrlRedirectionGuard], children: [] },
  { path: 'vendors/id/:vendorId/menuId/:itemId', canActivate: [VendorDetailsPageBackwardsCompatUrlRedirectionGuard], children: [] },
  { path: 'vendors/slug/:vendorSlug', canActivate: [VendorDetailsPageBackwardsCompatUrlRedirectionGuard], children: [] },
];

const vendorDetailsRelatedUrls = [
  ...vendorDetailsBackwardCompatUrls,
  {
    path: 'vendors/id/:vendorId/search',
    canActivate: [NoEntryPageGuard],
    data: { dynamicTitle: true },
    loadChildren: () => import('./pages/vendor-search/vendor-search.module').then((m) => m.VendorSearchPageModule),
  },
  {
    path: 'vendors/id/:vendorId/categories/:categoryId',
    canActivate: [NoEntryPageGuard],
    data: { dynamicTitle: true },
    loadChildren: () => import('./pages/grocery-categories/grocery-categories.module').then((m) => m.GroceryCategoriesPageModule),
  },
  {
    path: 'vendors/id/:vendorId/categories/:categoryId/items',
    canActivate: [NoEntryPageGuard],
    data: { dynamicTitle: true },
    loadChildren: () =>
      import('./pages/grocery-category-items/grocery-category-items.module').then((m) => m.GroceryCategoryItemsPageModule),
  },
  {
    path: 'vendors/id/:vendorId',
    canActivate: [NoEntryPageCordovaGuard, VendorDetailsUrlParamsValidationGuard],
    data: { dynamicTitle: true },
    loadChildren: () => import('./pages/vendor-details/vendor-details.module').then((m) => m.VendorDetailsPageModule),
  },
  {
    path: 'vendors/id/:vendorId/:itemId',
    canActivate: [NoEntryPageCordovaGuard, VendorDetailsUrlParamsValidationGuard],
    data: { dynamicTitle: true },
    loadChildren: () => import('./pages/vendor-details/vendor-details.module').then((m) => m.VendorDetailsPageModule),
  },
  {
    path: 'vendors/id/:vendorId/:itemId/:itemSlug',
    canActivate: [NoEntryPageCordovaGuard, VendorDetailsUrlParamsValidationGuard],
    data: { dynamicTitle: true },
    loadChildren: () => import('./pages/vendor-details/vendor-details.module').then((m) => m.VendorDetailsPageModule),
  },
  {
    path: 'vendors/:vendorSlug/search',
    canActivate: [NoEntryPageGuard],
    data: { dynamicTitle: true },
    loadChildren: () => import('./pages/vendor-search/vendor-search.module').then((m) => m.VendorSearchPageModule),
  },
  {
    path: 'vendors/:vendorSlug/categories/:categoryId',
    canActivate: [NoEntryPageGuard],
    data: { dynamicTitle: true },
    loadChildren: () => import('./pages/grocery-categories/grocery-categories.module').then((m) => m.GroceryCategoriesPageModule),
  },
  {
    path: 'vendors/:vendorSlug/categories/:categoryId/items',
    canActivate: [NoEntryPageGuard],
    data: { dynamicTitle: true },
    loadChildren: () =>
      import('./pages/grocery-category-items/grocery-category-items.module').then((m) => m.GroceryCategoryItemsPageModule),
  },
  {
    path: 'vendors/:vendorSlug',
    canActivate: [NoEntryPageCordovaGuard, VendorDetailsUrlParamsValidationGuard],
    data: { dynamicTitle: true } as PreloadableRouteData,
    loadChildren: () => import('./pages/vendor-details/vendor-details.module').then((m) => m.VendorDetailsPageModule),
  },
  {
    path: 'vendors/:vendorSlug/:itemId',
    canActivate: [NoEntryPageCordovaGuard, VendorDetailsUrlParamsValidationGuard],
    data: { dynamicTitle: true } as PreloadableRouteData,
    loadChildren: () => import('./pages/vendor-details/vendor-details.module').then((m) => m.VendorDetailsPageModule),
  },
  {
    path: 'vendors/:vendorSlug/:itemId/:itemSlug',
    canActivate: [NoEntryPageCordovaGuard, VendorDetailsUrlParamsValidationGuard],
    data: { dynamicTitle: true } as PreloadableRouteData,
    loadChildren: () => import('./pages/vendor-details/vendor-details.module').then((m) => m.VendorDetailsPageModule),
  },
];

export const APP_ROUTES: PreloadableRoutes = [
  {
    path: 'error',
    data: { title: 'PAGES.ERROR' },
    loadChildren: () => import('./pages/error/error.module').then((m) => m.ErrorPageModule),
  },
  {
    path: 'update',
    data: { title: 'PAGES.APP_UPDATE' },
    canActivate: [AppNotUpdatedGuard],
    loadChildren: () => import('./pages/update/update.module').then((m) => m.UpdatePageModule),
  },
  {
    path: 'not-found',
    data: { title: 'PAGES.NOT_FOUND' },
    loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundPageModule),
  },
  {
    path: 'incident',
    data: { title: 'PAGES.INCIDENT' },
    loadChildren: () => import('./pages/incident/incident.module').then((m) => m.IncidentPageModule),
  },
  {
    // This route won't actually open the referral program page — it's here only to
    // handle referral links being opened in a desktop which is done by the guard
    // IMPORTANT: the service that builds these deep links was not appending the country
    // or language to the URL, so we should expect URLs to be like:
    // http://localhost:8100/affiliate/04da3256-9c92-402b-8852-084ac4b8d13f?redirectUrl=http://localhost:8100/
    // http://localhost:8100/affiliate/04da3256-9c92-402b-8852-084ac4b8d13f?redirectUrl=http://localhost:8100/kw/en/caterers/id/1111
    // http://localhost:8100/affiliate/04da3256-9c92-402b-8852-084ac4b8d13f?redirectUrl=http://localhost:8100/kw/en/caterers/id/1111/menuId/8494
    path: 'affiliate/:userId',
    canActivate: [ReferralProgramRedirectionGuard],
    loadChildren: () => import('./pages/referral-program/referral-program.module').then((m) => m.ReferralProgramPageModule),
  },
  {
    path: ':country/:language',
    canActivate: [CountryLanguageGuard],
    children: [
      {
        path: 'about',
        data: { title: 'PAGES.ABOUT_US' },
        canActivate: [NoEntryPageCordovaGuard],
        loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutPageModule),
      },
      {
        path: 'policy',
        data: { title: 'PAGES.PRIVACY_POLICY' },
        canActivate: [NoEntryPageCordovaGuard],
        loadChildren: () => import('./pages/policy/policy.module').then((m) => m.PolicyPageModule),
      },
      {
        path: 'terms',
        data: { title: 'PAGES.TERMS_AND_CONDITIONS' },
        canActivate: [NoEntryPageCordovaGuard],
        loadChildren: () => import('./pages/terms/terms.module').then((m) => m.TermsPageModule),
      },
      {
        path: 'press',
        data: { title: 'PAGES.PRESS' },
        canActivate: [NoEntryPageCordovaGuard],
        loadChildren: () => import('./pages/press/press.module').then((m) => m.PressPageModule),
      },
      {
        path: 'vendors/join',
        data: { title: 'PAGES.FOR_VENDORS' },
        canActivate: [NoEntryPageCordovaGuard],
        loadChildren: () => import('./pages/for-vendors/for-vendors.module').then((m) => m.ForVendorsPageModule),
      },
      {
        path: 'business',
        data: { title: 'PAGES.FOR_BUSINESS' },
        canActivate: [NoEntryPageCordovaGuard],
        loadChildren: () => import('./pages/for-business/for-business.module').then((m) => m.ForBusinessPageModule),
      },
      {
        path: 'thank-you',
        data: { title: 'PAGES.THANK_YOU' },
        canActivate: [NoEntryPageCordovaGuard],
        loadChildren: () => import('./pages/thank-you/thank-you.module').then((m) => m.ThankYouPageModule),
      },
      {
        path: 'update-optional',
        data: { title: 'PAGES.APP_UPDATE' },
        canActivate: [AppReadyGuard],
        loadChildren: () => import('./pages/update-optional/update-optional.module').then((m) => m.UpdateOptionalPageModule),
      },
      {
        path: 'location-identification',
        canActivate: [AppReadyGuard, AddressNotSelectedGuard],
        data: { title: 'PAGES.LOCATION_IDENTIFICATION' },
        loadChildren: () =>
          import('./pages/location-identification/location-identification.module').then((m) => m.LocationIdentificaPageModule),
      },
      {
        path: 'settings',
        canActivate: [AppReadyGuard],
        data: { title: 'PAGES.SETTINGS' },
        loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsPageModule),
      },
      {
        path: '',
        canActivate: [AppInitializedGuard],
        children: [
          // There's a guard that will take care of redirecting to /vendors/search if
          // we shouldn't take the user to /vendors/home
          { path: 'vendors', pathMatch: 'full', redirectTo: 'vendors/home' },

          // We need to intercept the /vendors/search URL and make it to be
          // handled by the TabsPageModule because:
          // - that module would make tabs to be visible on the mobile app
          // - /vendors/:vendorSlug would match with /vendors/search if not intercepted
          {
            matcher: (segments: Array<UrlSegment>): UrlMatchResult => {
              const isVendorsHomeUrl = segments && segments.length === 2 && segments[0].path === 'vendors' && segments[1].path === 'home';
              const isVendorsSearchUrl =
                segments && segments.length === 2 && segments[0].path === 'vendors' && segments[1].path === 'search';

              return isVendorsSearchUrl || isVendorsHomeUrl ? { consumed: [] } : null;
            },
            loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
          },

          ...vendorDetailsRelatedUrls,

          {
            path: 'referral-program',
            canActivate: [NoEntryPageCordovaGuard],
            data: { title: 'PAGES.REFERRAL_PROGRAM' },
            loadChildren: () => import('./pages/referral-program/referral-program.module').then((m) => m.ReferralProgramPageModule),
          },
          {
            path: 'wishlists',
            canActivate: [NoEntryPageCordovaGuard],
            data: { title: 'PAGES.WISHLISTS' },
            loadChildren: () => import('./pages/wishlists/wishlists.module').then((m) => m.WishlistsPageModule),
          },
          {
            path: 'wishlists/:wishlistId',
            canActivate: [NoEntryPageCordovaGuard],
            data: { dynamicTitle: true },
            loadChildren: () => import('./pages/wishlist-details/wishlist-details.module').then((m) => m.WishlistDetailsPageModule),
          },
          {
            path: 'cart',
            canActivate: [NoEntryPageGuard],
            data: { title: 'PAGES.CART' },
            loadChildren: () => import('./pages/cart/cart.module').then((m) => m.CartPageModule),
          },
          {
            path: 'collections/marketing/:collectionId',
            canActivate: [NoEntryPageCordovaGuard],
            loadChildren: () =>
              import('./pages/marketing-collection-details/marketing-collection-details.module').then(
                (m) => m.MarketingCollectionDetailsPageModule,
              ),
          },
          {
            path: 'checkout',
            canActivate: [NoEntryPageCordovaGuard, AuthGuard],
            children: [
              {
                path: '',
                canActivate: [InvalidCartGuard],
                data: { title: 'PAGES.CHECKOUT' },
                loadChildren: () => import('./pages/checkout/checkout.module').then((m) => m.CheckoutPageModule),
              },
              {
                path: 'address/select',
                canActivate: [NoEntryPageGuard],
                // TODO: fromCheckoutPAge is not part of SavedAddressesPageParams
                data: { fromCheckoutPage: true } as PreloadableRouteData & SavedAddressesPageParams,
                loadChildren: () => import('./pages/saved-addresses/saved-addresses.module').then((m) => m.SavedAddressesPageModule),
              },
              {
                path: ':orderId/success',
                canActivate: [NoEntryPageCordovaGuard],
                loadChildren: () =>
                  import('./pages/order-confirmation/order-confirmation.module').then((m) => m.OrderConfirmationPageModule),
              },
              {
                path: ':orderId/error',
                canActivate: [InvalidCartGuard, OnlinePaymentErrorGuard],
                loadChildren: () => import('./pages/checkout/checkout.module').then((m) => m.CheckoutPageModule),
              },
            ],
          },
          {
            path: 'search',
            canActivate: [NoEntryPageCordovaGuard],
            data: { title: 'PAGES.SEARCH' },
            loadChildren: () => import('./pages/search/search.module').then((m) => m.SearchPageModule),
          },
          {
            path: 'terms-conditions',
            canActivate: [NoEntryPageGuard],
            data: { title: 'PAGES.TERMS_AND_CONDITIONS' },
            loadChildren: () =>
              import('./pages/terms-and-conditions/terms-and-conditions.module').then((m) => m.TermsAndConditionsPageModule),
          },
          {
            path: 'developer-settings',
            canActivate: [NoEntryPageGuard],
            loadChildren: () => import('./pages/developer-settings/developer-settings.module').then((m) => m.DeveloperSettingsPageModule),
          },
          {
            path: 'user',
            canActivate: [AuthGuard],
            children: [
              {
                path: 'wallet',
                canActivate: [NoEntryPageCordovaGuard],
                data: { title: 'PAGES.WALLET' },
                loadChildren: () => import('./pages/wallet/wallet.module').then((m) => m.WalletPageModule),
              },
              {
                path: 'profile',
                canActivate: [NoEntryPageCordovaGuard],
                data: { title: 'PAGES.MY_PROFILE' },
                loadChildren: () => import('./pages/account-details/account-details.module').then((m) => m.AccountDetailsPageModule),
              },
              {
                path: 'addresses',
                canActivate: [NoEntryPageCordovaGuard],
                children: [
                  {
                    path: '',
                    data: { fromAccountDetailsPage: true, title: 'PAGES.MY_ADDRESSES' } as PreloadableRouteData & SavedAddressesPageParams,
                    loadChildren: () => import('./pages/saved-addresses/saved-addresses.module').then((m) => m.SavedAddressesPageModule),
                  },
                  {
                    path: 'add',
                    data: {
                      fromAccountDetailsPage: true,
                      shouldOpenNewAddressModal: true,
                      title: 'PAGES.ADD_ADDRESS',
                    } as PreloadableRouteData & SavedAddressesPageParams,
                    loadChildren: () => import('./pages/saved-addresses/saved-addresses.module').then((m) => m.SavedAddressesPageModule),
                  },
                  {
                    path: ':addressId/edit',
                    data: {
                      fromAccountDetailsPage: true,
                      shouldOpenEditAddressModal: true,
                      title: 'PAGES.EDIT_ADDRESS',
                    } as PreloadableRouteData & SavedAddressesPageParams,
                    loadChildren: () => import('./pages/saved-addresses/saved-addresses.module').then((m) => m.SavedAddressesPageModule),
                  },
                ],
              },
              {
                path: 'orders',
                canActivate: [NoEntryPageCordovaGuard],
                data: { title: 'PAGES.MY_ORDERS' },
                loadChildren: () => import('./pages/orders/orders.module').then((m) => m.OrdersPageModule),
              },
              {
                path: 'orders/:orderId',
                canActivate: [NoEntryPageCordovaGuard],
                loadChildren: () => import('./pages/orders/orders.module').then((m) => m.OrdersPageModule),
              },
              {
                path: 'orders/:orderId/review',
                canActivate: [NoEntryPageCordovaGuard],
                data: { shouldOpenOrderReviewModal: true } as PreloadableRouteData & OrdersPageParams,
                loadChildren: () => import('./pages/orders/orders.module').then((m) => m.OrdersPageModule),
              },
              {
                path: 'orders/:orderId/vendors/:vendorId',
                canActivate: [NoEntryPageCordovaGuard],
                loadChildren: () => import('./pages/orders/orders.module').then((m) => m.OrdersPageModule),
              },
              {
                path: 'orders/:orderId/vendors/:vendorId/tracking',
                canActivate: [NoEntryPageCordovaGuard],
                data: { shouldOpenOrderTrackingModal: true } as PreloadableRouteData & OrdersPageParams,
                loadChildren: () => import('./pages/orders/orders.module').then((m) => m.OrdersPageModule),
              },
              {
                path: '**',
                pathMatch: 'full',
                redirectTo: '/',
              },
            ],
          },
          {
            path: '',
            loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
          },
        ],
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    canActivate: [BrowserCountrySelectionPageGuard],
    loadChildren: () =>
      import('./pages/browser-country-selection/browser-country-selection.module').then((m) => m.BrowserCountrySelectionPageModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
