import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { RateBilbaytModalPage } from './rate-bilbayt-modal.page';

import { EditableRatingComponentModule } from '../../shared/components/editable-rating/editable-rating.module';

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule.forChild(), EditableRatingComponentModule],
  declarations: [RateBilbaytModalPage],
})
export class RateBilbaytModalPageModule {}
