import { Injectable } from '@angular/core';

import { NetworkPlugin } from './native-plugins/network.plugin';

import { filter, map, take } from 'rxjs';

import { AccountService } from './account.service';
import { AnalyticsService } from './analytics.service';
import { AppPerformanceService, PerformanceMark } from './app-performance.service';
import { AppService } from './app.service';
import { BackgroundService } from './background.service';
import { CartService } from './cart.service';
import { DeviceLocalTimeService } from './device-local-time.service';
import { InAppMessagesService } from './in-app-messages.service';
import { LoggerService } from './logger.service';
import { LoyaltyProgramService } from './loyalty-program.service';
import { PushNotificationsService } from './push-notifications.service';
import { SettingsService } from './settings.service';
import { PlatformService } from './ssr/platform.service';
import { StateService } from './state.service';
import { UniversalLinksService } from './universal-links.service';
import { VendorsService } from './vendors.service';

import { TRANSLATION_CDN_REQUEST_TIMEOUT_IN_MILLISECONDS } from '../config/app.config';

import { AppEffects } from '../effects';
import { toPromise } from '../operators/to-promise';

@Injectable({ providedIn: 'root' })
export class BootstrapService {
  constructor(
    private appEffects: AppEffects,
    private networkPlugin: NetworkPlugin,
    private stateService: StateService,
    private appService: AppService,
    private cartService: CartService,
    private loggerService: LoggerService,
    private accountService: AccountService,
    private settingsService: SettingsService,
    private vendorsService: VendorsService,
    private platformService: PlatformService,
    private analyticsService: AnalyticsService,
    private backgroundService: BackgroundService,
    private inAppMessagesService: InAppMessagesService,
    private loyaltyProgramService: LoyaltyProgramService,
    private universalLinksService: UniversalLinksService,
    private pushNotificationsService: PushNotificationsService,
    private appPerformanceService: AppPerformanceService,
    private deviceLocalTimeService: DeviceLocalTimeService,
  ) {}

  public async initializeApp(): Promise<boolean> {
    let appInitialized = false;

    try {
      this.appPerformanceService.markStart(PerformanceMark.InitializeApp);

      this.loggerService.initialize();
      await this.initializePlatform();
      await this.loadDataFromPreviousSessions();

      // Initialize analytics and A/B test experiments before sending any
      // request to the API so that we can include some custom headers in
      // every HTTP request
      await this.analyticsService.initializeAnalytics();

      await this.initializeSettings();
      await this.loadTranslationsFiles();

      if (this.networkPlugin.isOnline()) {
        this.universalLinksService.initializeUniversalLinks();
        this.inAppMessagesService.initializeInAppMessagesEvents();
        this.pushNotificationsService.initializePushNotifications();

        await Promise.all([this.initializeVendorsSlugByIdMapStaticFile(), this.refreshUserDetails(), this.loadLoyaltyProgramDetails()]);

        // Important: we need to wait for the settings to be initalized since some user
        // properties are related to the selected country/language
        this.analyticsService.initializeUserPropertiesUpdaters();

        this.networkPlugin.initializeNetworkEvents();
        this.backgroundService.initializeBackgroundHandlers();
        this.deviceLocalTimeService.initializeLocalTimeIntervalUpdates();

        appInitialized = true;
      }

      // Knowing when the app finished the initialization process
      // helps us to avoid issues in iOS when the WKWebView reloads
      // the app after being in the background for some time (CA-1312)
      this.appService.updateAppReadyStatus(appInitialized);

      this.appPerformanceService.markEnd(PerformanceMark.InitializeApp);
      this.appPerformanceService.sendPerformanceDataToMixpanel();

      return appInitialized;
    } catch (error: unknown) {
      this.appService.updateAppReadyStatus(appInitialized);
      this.loggerService.error({ component: 'AppInitializer', message: "couldn't initialize app", error });
      return appInitialized;
    }
  }

  private initializePlatform(): Promise<void> {
    this.appPerformanceService.markStart(PerformanceMark.PlatformReady);
    return this.platformService
      .ready()
      .then(() => {})
      .finally(() => {
        this.appPerformanceService.markEnd(PerformanceMark.PlatformReady);
      });
  }

  private async loadDataFromPreviousSessions(): Promise<void> {
    this.appEffects.initialize();
    this.stateService.initialize(await this.appEffects.getPreviousSessionState());

    this.cartService.initializeCart();
    this.settingsService.loadTestGroupsFromStorage();

    if (this.accountService.isLoggedIn()) {
      // Initialize the raygun object with the current user details
      void this.loggerService.identifyUser(this.accountService.userDetails);
    }
  }

  private refreshUserDetails(): Promise<void> {
    if (this.platformService.isServer || this.platformService.isCordova) {
      return Promise.resolve();
    }

    this.appPerformanceService.markStart(PerformanceMark.RefreshUserDetailsFromApi);
    return toPromise(this.accountService.refreshUserDetails())
      .then(() => this.appPerformanceService.markEnd(PerformanceMark.RefreshUserDetailsFromApi))
      .catch(() => {});
  }

  private loadLoyaltyProgramDetails(): Promise<void> {
    if (this.platformService.isServer || this.platformService.isCordova) {
      return Promise.resolve();
    }

    this.appPerformanceService.markStart(PerformanceMark.LoadLoyaltyProgramDetailsFromApi);
    return toPromise(this.loyaltyProgramService.loadLoyaltyProgramDetails())
      .then(() => this.appPerformanceService.markEnd(PerformanceMark.LoadLoyaltyProgramDetailsFromApi))
      .catch(() => {});
  }

  private initializeSettings(): Promise<void> {
    this.appPerformanceService.markStart(PerformanceMark.LoadInitialSettingsFromApi);

    return toPromise(this.settingsService.getInitialSettings({ loggedIn: this.accountService.isLoggedIn() })).then(() => {
      this.appPerformanceService.markEnd(PerformanceMark.LoadInitialSettingsFromApi);
      this.appPerformanceService.markStart(PerformanceMark.InitializeSettingsFromStorage);

      this.settingsService.initializeCountryLanguage();
      this.appPerformanceService.markEnd(PerformanceMark.InitializeSettingsFromStorage);
    });
  }

  private initializeVendorsSlugByIdMapStaticFile(): Promise<void> {
    if (this.platformService.isCordova || this.platformService.isServer) {
      return Promise.resolve();
    }

    this.appPerformanceService.markStart(PerformanceMark.LoadVendorsIdSLugMapFromCdn);
    return toPromise(this.vendorsService.loadVendorsSlugByIdMapStaticFile()).then(() => {
      this.appPerformanceService.markEnd(PerformanceMark.LoadVendorsIdSLugMapFromCdn);
    });
  }

  private loadTranslationsFiles(): Promise<boolean> {
    this.appPerformanceService.markStart(PerformanceMark.LoadTranslationsFromCdn);

    return Promise.race([
      toPromise(
        this.appService.loadTranslations$.pipe(
          filter((areLoaded) => areLoaded === true),
          take(1),
          map(() => true),
        ),
      ),
      this.platformService.wait(TRANSLATION_CDN_REQUEST_TIMEOUT_IN_MILLISECONDS * 2).then(() => false),
    ]).then((loaded) => {
      this.appPerformanceService.markEnd(PerformanceMark.LoadTranslationsFromCdn);
      return loaded;
    });
  }
}
