import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';

import { IonInput, ViewDidEnter } from '@ionic/angular';

import { GooglePlacesResult } from '../../core/models/google-places-result.model';

import { AnalyticsService } from '../../core/services/analytics.service';
import { AppEventsService } from '../../core/services/app-events.service';
import { GeolocationService } from '../../core/services/geolocation.service';
import { LoggerService } from '../../core/services/logger.service';
import { ModalService } from '../../core/services/modal.service';
import { OverlayService } from '../../core/services/overlay.service';
import { PlatformService } from '../../core/services/ssr/platform.service';

import { AnalyticsConfig, TOKEN_ANALYTICS_CONFIG } from '../../core/config/analytics.config';

import { GoogleMapsAutocompleteModalPageAnimations } from './google-maps-autocomplete-modal.animations';

export const GoogleMapsAutocompleteModalPageIdentifier = 'google-maps-autocomplete-modal';

@Component({
  selector: 'app-google-maps-autocomplete-modal',
  templateUrl: './google-maps-autocomplete-modal.page.html',
  styleUrls: ['./google-maps-autocomplete-modal.page.scss'],
  animations: [...GoogleMapsAutocompleteModalPageAnimations],
  encapsulation: ViewEncapsulation.None,
})
export class GoogleMapsAutocompleteModalPage implements ViewDidEnter {
  @ViewChild('input')
  public input: IonInput;

  public queryText: string;
  public searchResults: Array<GooglePlacesResult> = [];

  constructor(
    private modalService: ModalService,
    private overlayService: OverlayService,
    private geolocationService: GeolocationService,
    private loggerService: LoggerService,
    private analyticsService: AnalyticsService,
    private appEventsService: AppEventsService,
    private platformService: PlatformService,
    @Inject(TOKEN_ANALYTICS_CONFIG) private analyticsConfig: AnalyticsConfig,
  ) {}

  ionViewDidEnter() {
    void this.analyticsService.trackView({ name: this.analyticsConfig.pageName.googleMapsAutocompletePage });

    this.initializePage();
  }

  public onQueryChange(): void {
    if (this.queryText === '') {
      this.searchResults = [];
      return;
    }

    void this.geolocationService
      .getGooglePlacesSuggestions(this.queryText)
      .then((results) => (this.searchResults = results.filter((result) => result.mainText)))
      .catch((error: string) => {
        this.loggerService.info({
          component: 'GoogleMapsAutocompleteModalPage',
          message: "couldn't get Google Places suggestions",
          details: { error },
        });

        this.searchResults = [];
        this.overlayService.showToast({ message: error, showCloseButton: true, type: 'error' });
      });
  }

  public onSelectResult(item: GooglePlacesResult): void {
    const searchParams = { placeId: item.placeId };

    this.geolocationService
      .geocode(searchParams)
      .then((geocodedItem) => {
        this.appEventsService.dispatch('GoogleMapUpdateLocation', geocodedItem);
        this.onDismiss();
      })
      .catch((error: string) => {
        this.loggerService.info({
          component: 'GoogleMapsAutocompleteModalPage',
          message: "couldn't geocode selected place",
          details: { error },
        });
        this.overlayService.showToast({ message: error, showCloseButton: true, type: 'error' });
      });
  }

  public onDismiss(): void {
    void this.modalService.dismissModal({ id: GoogleMapsAutocompleteModalPageIdentifier });
  }

  private initializePage(): void {
    void this.overlayService.showLoading().then(() => {
      this.geolocationService
        .initializeGooglePlacesSDK()
        .then(() => this.overlayService.hideLoading().then(() => this.setfocus()))
        .catch((error: unknown) => {
          this.loggerService.info({
            component: 'GoogleMapsAutocompleteModalPage',
            message: "couldn't initialize Google Places SDK details:",
            details: { error },
          });
          this.onDismiss();
        });
    });
  }

  private setfocus(): void {
    void this.platformService.wait(300).then(() =>
      this.input.getInputElement().then((inputElement) => {
        if (inputElement) {
          inputElement.focus();
        }
      }),
    );
  }
}
