import { Mixpanel, MixpanelPeople } from '@awesome-cordova-plugins/mixpanel/ngx';

import { isCypressTestEnv } from '../environment.service';
import { PlatformService } from '../ssr/platform.service';
import { WindowService } from '../ssr/window.service';

interface MixpanelBrowser {
  init: (token: string, options?: { loaded?: () => void; track_pageview?: boolean; persistence?: 'localStorage' | 'cookie' }) => void;
  get_distinct_id: () => string;
  track: (name: string, properties?: unknown) => unknown;
  identify: (distinctId: string) => unknown;
  alias: (aliasId: string, originalId: string) => unknown;
  reset: () => unknown;
  people: {
    set: (peopleProperties: unknown) => unknown;
    set_once: (peopleProperties: unknown) => unknown;
    union: (peopleProperties: unknown) => unknown;
    increment: (peopleProperties: unknown) => unknown;
  };
}

let mixpanel: MixpanelBrowser;
let initialized: boolean;

class BrowserMixpanelProvider extends Mixpanel {
  constructor(private windowService: WindowService) {
    super();
  }

  public init(token: string): Promise<unknown> {
    if (!this.windowService.isWindowDefined) {
      return Promise.resolve();
    }

    if (isCypressTestEnv()) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      return import('mixpanel-browser').then((mixpanelLib: { default: MixpanelBrowser }) => {
        if (mixpanelLib && mixpanelLib.default) {
          mixpanel = mixpanelLib.default;

          mixpanel.init(token, {
            track_pageview: false,
            persistence: 'localStorage',
            loaded: () => {
              initialized = true;
              resolve(true);
            },
          });
        } else {
          resolve(false);
        }
      });
    });
  }

  public distinctId(): Promise<string> {
    return Promise.resolve(mixpanel && initialized ? mixpanel.get_distinct_id() : '');
  }

  public track(eventName: string, eventProperties?: unknown): Promise<unknown> {
    return Promise.resolve(mixpanel && initialized ? mixpanel.track(eventName, eventProperties) : true);
  }

  public identify(distinctId: string): Promise<unknown> {
    return Promise.resolve(mixpanel && initialized ? mixpanel.identify(distinctId) : true);
  }

  public alias(aliasId: string, originalId: string): Promise<unknown> {
    return Promise.resolve(mixpanel && initialized ? mixpanel.alias(aliasId, originalId) : true);
  }

  public reset(): Promise<unknown> {
    return Promise.resolve(mixpanel && initialized ? mixpanel.reset() : true);
  }
}

class BrowserMixpanelPeopleProvider extends MixpanelPeople {
  public increment(peopleProperties: unknown): Promise<unknown> {
    return Promise.resolve(mixpanel && initialized ? mixpanel.people.increment(peopleProperties) : true);
  }

  public set(peopleProperties: unknown): Promise<unknown> {
    return Promise.resolve(mixpanel && initialized ? mixpanel.people.set(peopleProperties) : true);
  }

  public setOnce(peopleProperties: unknown): Promise<unknown> {
    return Promise.resolve(mixpanel && initialized ? mixpanel.people.set_once(peopleProperties) : true);
  }

  public union(unionProperties: unknown): Promise<unknown> {
    return Promise.resolve(mixpanel && initialized ? mixpanel.people.union(unionProperties) : true);
  }
}

class MobileMixpanelProvider extends Mixpanel {}
class MobileMixpanelPeopleProvider extends MixpanelPeople {}

function mixpanelFactory(platformService: PlatformService, windowService: WindowService): Mixpanel {
  return platformService.areCordovaPluginsAvailable ? new MobileMixpanelProvider() : new BrowserMixpanelProvider(windowService);
}

function mixpanelPeopleFactory(platformService: PlatformService): MixpanelPeople {
  return platformService.areCordovaPluginsAvailable ? new MobileMixpanelPeopleProvider() : new BrowserMixpanelPeopleProvider();
}

export const mixpanelProvider = { provide: Mixpanel, useFactory: mixpanelFactory, deps: [PlatformService, WindowService] };
export const mixpanelPeopleProvider = { provide: MixpanelPeople, useFactory: mixpanelPeopleFactory, deps: [PlatformService] };
