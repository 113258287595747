import { Injectable } from '@angular/core';

import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';

import { LoggerService } from '../logger.service';

@Injectable({ providedIn: 'root' })
export class LocationAccuracyPlugin {
  constructor(private locationAccuracy: LocationAccuracy, private loggerService: LoggerService) {}

  public canRequest(): Promise<boolean> {
    return this.locationAccuracy.canRequest().catch((error: unknown) => {
      this.loggerService.info({
        component: 'LocationAccuracyPlugin',
        message: "couldn't check if can request",
        details: { error },
        fromPlugin: true,
      });
      return false;
    });
  }

  public request(): Promise<boolean> {
    return this.locationAccuracy
      .request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY)
      .then(() => true)
      .catch((error: unknown) => {
        this.loggerService.info({
          component: 'LocationAccuracyPlugin',
          message: "couldn't request high accuracy",
          details: { error },
          fromPlugin: true,
        });
        return false;
      });
  }
}
