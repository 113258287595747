import { transition, trigger } from '@angular/animations';

import {
  AnimationTransition,
  applyFadeInAnimation,
  applyFadeInTranslateAnimation,
  applyFadeOutAnimation,
  applyFadeOutTranslateAnimation,
} from '../../../core/animations';

const ENTER_ANIMATION_DURATION = 300;
const ENTER_ANIMATION_DELAY = 300;

export const ENTER_ANIMATION_TOTAL_DURATION = ENTER_ANIMATION_DELAY + ENTER_ANIMATION_DURATION;

export const AreaDateTimePickerAnimations = [
  trigger('animatedBackdrop', [
    transition(AnimationTransition.Enter, applyFadeInAnimation({ duration: 600 })),
    transition(AnimationTransition.Leave, applyFadeOutAnimation({ duration: 300, delay: 300 })),
  ]),
  trigger('animatedActionSheet', [
    transition(
      AnimationTransition.Enter,
      applyFadeInTranslateAnimation({
        duration: ENTER_ANIMATION_DURATION,
        delay: ENTER_ANIMATION_DELAY,
        distance: '100%',
      }),
    ),
    transition(AnimationTransition.Leave, applyFadeOutTranslateAnimation({ duration: 300, distance: '100%' })),
  ]),

  trigger('animatedLoadingIcon', [transition(AnimationTransition.Enter, applyFadeInAnimation({ duration: 300 }))]),
];
