import { Injectable } from '@angular/core';

import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';

import { LoggerService } from '../logger.service';

@Injectable({ providedIn: 'root' })
export class ClipboardPlugin {
  constructor(private clipboard: Clipboard, private loggerService: LoggerService) {}

  public copy(text: string): Promise<boolean> {
    return this.clipboard
      .copy(text)
      .then(() => true)
      .catch((error: unknown) => {
        this.loggerService.info({
          component: 'ClipboardPlugin',
          message: "couldn't copy to clipboard",
          details: { error },
          fromPlugin: true,
        });
        return false;
      });
  }
}
