import { EnvironmentInjector, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { catchError, from, isObservable, map, Observable, of, switchMap } from 'rxjs';

import { URL_DATE_FORMAT, URL_TIME_FORMAT } from '../models/date-time-format.model';

import { AreasService } from '../services/areas.service';
import { CartService } from '../services/cart.service';
import { DateTimeService } from '../services/date-time.service';
import { NavigationService } from '../services/navigation.service';
import { SettingsService } from '../services/settings.service';
import { VendorsService } from '../services/vendors.service';

import { getVendorIdFn } from '../resolvers/get-vendor-id.resolver';

@Injectable({
  providedIn: 'root',
})
export class VendorDetailsUrlParamsValidationGuard implements CanActivate {
  constructor(
    private router: Router,
    private cartService: CartService,
    private areasService: AreasService,
    private vendorsService: VendorsService,
    private dateTimeService: DateTimeService,
    private settingsService: SettingsService,
    private navigationService: NavigationService,
    private environmentInjector: EnvironmentInjector,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentFragment = route.fragment;
    const currentQueryParams = route.queryParams || {};
    const urlSegments = [this.navigationService.getCountryLanguageUrlSegment(), ...route.url.map((segment) => segment.path)];

    return this.getVendorId(route, state).pipe(
      switchMap((vendorId) => {
        // This guard is similar to the HomePageVendorsGuard but here we only add params if
        // the vendor is already in the cart since in some cases we want to keep them empty
        // (for example for deep links)
        // https://bilbayt.atlassian.net/browse/CA-2765

        const { shouldRemoveFromUrl: shouldRemoveServiceTypeFromUrl } = this.checkServiceType(route);

        if (shouldRemoveServiceTypeFromUrl) {
          return of(this.router.createUrlTree(urlSegments, { fragment: undefined, queryParams: currentQueryParams }));
        }

        const { shouldUpdateUrl: shouldUpdateDateTimeFromUrl, newDateValue, newTimeValue } = this.checkDateTime(route, vendorId);

        if (shouldUpdateDateTimeFromUrl) {
          return of(
            this.router.createUrlTree(urlSegments, {
              fragment: currentFragment,
              queryParams: {
                ...currentQueryParams,
                date: newDateValue ?? undefined,
                time: newTimeValue ?? undefined,
              },
            }),
          );
        }

        return this.checkAreaId(route, vendorId).pipe(
          map((areaCheckResult) => {
            const { shouldUpdateUrl: shouldUpdateAreaIdFromUrl, newValue: newAreaId } = areaCheckResult;

            if (shouldUpdateAreaIdFromUrl) {
              return this.router.createUrlTree(urlSegments, {
                fragment: currentFragment,
                queryParams: { ...currentQueryParams, areaId: newAreaId },
              });
            }

            return true;
          }),
        );
      }),
    );
  }

  private checkServiceType(route: ActivatedRouteSnapshot): { shouldRemoveFromUrl: boolean } {
    const serviceTypeFromUrl = this.navigationService.getServiceTypeFromUrl(route);
    return { shouldRemoveFromUrl: serviceTypeFromUrl && !this.settingsService.isServiceTypeOptionValid(serviceTypeFromUrl) };
  }

  private checkDateTime(
    route: ActivatedRouteSnapshot,
    vendorId: number,
  ): { shouldUpdateUrl: boolean; newDateValue?: string; newTimeValue?: string } {
    const dateFromUrl = this.navigationService.getDateFromUrl(route);
    const timeFromUrl = this.navigationService.getTimeFromUrl(route);

    if (!this.cartService.isCartEmpty() && this.cartService.isVendorInCart(vendorId)) {
      const orderForNowFromCart = this.cartService.isBilbaytNowCart();
      const dateTimeFromCart = this.cartService.getSelectedDateTimeForVendor(vendorId);
      const { date: dateFromCart, time: timeFromCart } = this.navigationService.getDateAndTimeQueryParamsFromIsoString(
        dateTimeFromCart,
        orderForNowFromCart,
      );

      if (dateFromCart && timeFromCart && (dateFromCart !== dateFromUrl || timeFromCart !== timeFromUrl)) {
        return { shouldUpdateUrl: true, newDateValue: dateFromCart, newTimeValue: timeFromCart };
      }
    }

    if (!dateFromUrl && !timeFromUrl) {
      return { shouldUpdateUrl: false };
    }

    if (!dateFromUrl || !timeFromUrl) {
      return { shouldUpdateUrl: true, newDateValue: undefined, newTimeValue: undefined };
    }

    const isDateFormatInvalid = !this.dateTimeService.isValid(dateFromUrl, URL_DATE_FORMAT);
    const isTimeFormatInvalid = timeFromUrl !== 'now' && !this.dateTimeService.isValid(timeFromUrl, URL_TIME_FORMAT);

    if (isDateFormatInvalid || isTimeFormatInvalid) {
      return { shouldUpdateUrl: true, newDateValue: undefined, newTimeValue: undefined };
    }

    if (timeFromUrl === 'now') {
      const dateIsToday = this.dateTimeService.isToday(this.dateTimeService.convertToDateTimeIsoString(dateFromUrl, URL_DATE_FORMAT));
      if (!dateIsToday) {
        return { shouldUpdateUrl: true, newDateValue: undefined, newTimeValue: undefined };
      }
    }

    if (timeFromUrl !== 'now') {
      const isDateTimeBeforeCurrentTime = this.dateTimeService.isBeforeCurrentLocalTime(
        this.dateTimeService.convertToDateTimeIsoString(`${dateFromUrl}${timeFromUrl}`, `${URL_DATE_FORMAT}${URL_TIME_FORMAT}`),
      );

      if (isDateTimeBeforeCurrentTime) {
        return { shouldUpdateUrl: true, newDateValue: undefined, newTimeValue: undefined };
      }
    }

    return { shouldUpdateUrl: false };
  }

  private checkAreaId(route: ActivatedRouteSnapshot, vendorId: number): Observable<{ shouldUpdateUrl: boolean; newValue?: number }> {
    const areaIdFromUrl = this.navigationService.getAreaIdFromUrl(route);
    const addressFromSearchFilters = this.vendorsService.getAddressSearchFilters();

    if (!this.cartService.isCartEmpty() && this.cartService.isVendorInCart(vendorId)) {
      const areaIdFromCart = this.cartService.getArea()?.areaId;

      if (areaIdFromCart && areaIdFromCart !== areaIdFromUrl) {
        return of({ shouldUpdateUrl: true, newValue: areaIdFromCart });
      }
    }

    if (!areaIdFromUrl) {
      return of({ shouldUpdateUrl: false });
    }

    // This check is kept only to avoid making an extra HTTP request
    // when the user goes from the home page to the vendor details page
    // without manually changing the area ID from the URL
    if (addressFromSearchFilters && addressFromSearchFilters.area.areaId === areaIdFromUrl) {
      return of({ shouldUpdateUrl: false });
    }

    return this.areasService.getAreaById(areaIdFromUrl).pipe(
      map((area) => ({ shouldUpdateUrl: !area, newValue: area ? area.areaId : undefined })),
      catchError(() => of({ shouldUpdateUrl: true, newValue: undefined })),
    );
  }

  private getVendorId(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number> {
    const vendorIdResult = getVendorIdFn(this.environmentInjector, route, state);
    return isObservable(vendorIdResult) ? vendorIdResult : from(Promise.resolve(vendorIdResult));
  }
}
