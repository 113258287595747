import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

import { getAnimationString } from './helpers';

import { applyFadeOutAnimation } from './animations-metadata';

import { AnimationDetails, AnimationTimingFunction, AnimationTransition } from './models';

const defaultValues = {
  fadeInFakeContent: 400,
  fadeOutFakeContent: 200,
  fadeInRealContent: 400,
  fadeOutRealContent: 400,
};

export function triggerSkeletonScreenFakeContentStatelessAnimation(
  name: string,
  animationDetails?: AnimationDetails,
): AnimationTriggerMetadata {
  return trigger(name, [
    transition(AnimationTransition.Enter, [
      style({ opacity: 0.6 }),
      animate(
        getAnimationString(
          animationDetails || {
            duration: defaultValues.fadeInFakeContent,
            timingFunction: AnimationTimingFunction.Ease,
          },
        ),
        style({ opacity: 1 }),
      ),
    ]),
    transition(
      AnimationTransition.Leave,
      applyFadeOutAnimation(
        animationDetails || {
          duration: defaultValues.fadeOutFakeContent,
          timingFunction: AnimationTimingFunction.Ease,
        },
      ),
    ),
  ]);
}

export function triggerSkeletonScreenRealContentStatelessAnimation(
  name: string,
  animationDetails?: AnimationDetails,
): AnimationTriggerMetadata {
  return trigger(name, [
    transition(AnimationTransition.Enter, [
      style({ opacity: 0.6 }),
      animate(
        getAnimationString(
          animationDetails || {
            duration: defaultValues.fadeInRealContent,
            timingFunction: AnimationTimingFunction.Ease,
          },
        ),
        style({ opacity: 1 }),
      ),
    ]),
    transition(
      AnimationTransition.Leave,
      applyFadeOutAnimation(
        animationDetails || {
          duration: defaultValues.fadeOutRealContent,
          timingFunction: AnimationTimingFunction.Ease,
        },
      ),
    ),
  ]);
}
