<ng-container *ngIf="isVisible">
  <div tappable (click)="onHandleBackdropClick()" @animatedBackdrop class="backdrop" data-cy="backdrop"></div>
  <div @animatedActionSheet class="container">
    <div class="body">
      <div class="fields">
        <div *ngIf="!hideAddressPicker" tappable class="field" (click)="onToggleDateTimePicker()">
          <ion-icon class="icon-start when" src="/assets/img/area-date-time-picker/clock.svg"></ion-icon>
          <p class="value" *ngIf="dateTimeText">{{ dateTimeText }}</p>
          <ion-icon class="icon-end" [class.rotated]="isDatePickerVisible" src="/assets/img/area-date-time-picker/chevron.svg"></ion-icon>
        </div>

        <div class="date-time-picker picker-ios" [class.hidden]="!isDatePickerVisible" [class.no-border]="hideAddressPicker">
          <div class="loading-spinner" @animatedLoadingIcon *ngIf="isDatePickerLoading">
            <ion-spinner duration="1000" name="dots"></ion-spinner>
          </div>

          <app-picker [columns]="dateTimePickerOptions?.columns" (appPickerChange)="onUpdateSelectedDateAndTime($event)"></app-picker>
        </div>

        <div *ngIf="!hideAddressPicker" tappable (click)="onOpenAreasModal()" class="field">
          <ion-icon class="icon-start where" src="/assets/img/area-date-time-picker/location.svg"></ion-icon>
          <p *ngIf="areaText" class="value">{{ areaText }}</p>
          <ion-icon class="icon-end" src="/assets/img/area-date-time-picker/chevron.svg"></ion-icon>
        </div>
      </div>
    </div>

    <div class="footer">
      <ion-button
        color="green"
        expand="block"
        class="custom-button"
        [class.disabled]="!isReady"
        data-cy="confirm-button"
        (click)="onHandleSubmitClick()"
      >
        <span>{{ buttonTranslationKey | translate }}</span>
      </ion-button>
    </div>
  </div>
</ng-container>
