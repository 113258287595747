<div class="picker-opts" #colEle [style.max-width]="col.optionsWidth">
  <button
    class="picker-opt"
    disable-activated
    *ngFor="let option of col.options; let i = index"
    data-cy="picker-button"
    (click)="onUpdateSelectedIndexFromEvent(i, $event)"
  >
    {{ option.text }}
  </button>
</div>
