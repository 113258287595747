import { EnvironmentInjector, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { catchError, from, isObservable, map, Observable, of } from 'rxjs';

import { NavigationService } from '../services/navigation.service';
import { VendorsService } from '../services/vendors.service';

// This function allows us to reuse the resolver from anywhere and not just an NgModule
export const getVendorIdFn = (
  environmentInjector: EnvironmentInjector,
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<number> => {
  const vendorIdResult = environmentInjector.runInContext(() => getVendorIdResolver(route, state));
  return isObservable(vendorIdResult) ? vendorIdResult : from(Promise.resolve(vendorIdResult));
};

export const getVendorIdResolver: ResolveFn<number> = (activatedRoute) => {
  const navigationService = inject(NavigationService);
  const vendorId = navigationService.getVendorIdFromUrl(activatedRoute);

  if (vendorId) {
    return vendorId;
  }

  const vendorSlug = navigationService.getVendorSlugFromUrl(activatedRoute);

  if (vendorSlug) {
    const vendorsService = inject(VendorsService);
    const { vendorId: vendorIdFromStaticFile } = vendorsService.getVendorIdAndServiceTypeBySlug(vendorSlug);

    if (vendorIdFromStaticFile) {
      return vendorIdFromStaticFile;
    }

    return vendorsService.getVendorDetailsBySlug(vendorSlug).pipe(
      map((vendorDetailsBySlug) => {
        // Save it for next time so we don't need to call the API again
        vendorsService.setVendorIdSlugAndServiceType(vendorDetailsBySlug.vendorId, vendorSlug, vendorDetailsBySlug.serviceTypes);
        return vendorDetailsBySlug.vendorId;
      }),
      catchError(() => {
        void navigationService.navigateTo('/not-found');
        return of(null);
      }),
    );
  }

  return undefined;
};
