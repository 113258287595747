import { Animation, createAnimation } from '@ionic/core';

export const alertModalIosLeaveAnimation = (baseEl: HTMLElement): Animation => {
  if (!baseEl) {
    return createAnimation();
  }

  const baseAnimation = createAnimation();
  const backdropAnimation = createAnimation();
  const modalContentAnimation = createAnimation();

  const backdropEl = baseEl.querySelector('ion-backdrop');
  if (backdropEl) {
    backdropAnimation.addElement(backdropEl).fromTo('opacity', 'var(--backdrop-opacity)', 0);
  }

  const contentEl = baseEl.querySelector('.modal-content');
  if (contentEl) {
    modalContentAnimation.addElement(contentEl).keyframes([
      { offset: 0, opacity: 0.99, transform: 'scale(1)' },
      { offset: 1, opacity: 0, transform: 'scale(0.9)' },
    ]);
  }

  return baseAnimation.addElement(baseEl).easing('ease-in-out').duration(200).addAnimation([backdropAnimation, modalContentAnimation]);
};
