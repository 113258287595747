<ion-header class="ion-no-border">
  <ion-toolbar>
    <div class="title">
      <h1>{{ "TIME_SLOT_SELECTION_MODAL_PAGE.TITLE" | translate }}</h1>
      <ion-icon data-cy="close-button" tappable src="/assets/custom-icons/b-times.svg" (click)="onDismiss()"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="loading-spinner animated fade-in" *ngIf="!timeSlotsByDate">
    <ion-spinner duration="1000" name="dots"></ion-spinner>
  </div>

  <div class="animated fade-in" *ngIf="timeSlotsByDate">
    <app-time-slots-by-date
      *ngIf="timeSlotsByDate.length"
      [timeSlots]="timeSlotsByDate"
      [selectedTimeSlot]="selectedTimeSlot"
      (timeSlotSelect)="onSelectTimeSlotAndDismissPage($event)"
    ></app-time-slots-by-date>

    <p *ngIf="!timeSlotsByDate.length" class="no-time-slots-message">
      {{ "TIME_SLOT_SELECTION_MODAL_PAGE.NO_RESULTS" | translate }}
    </p>
  </div>
</ion-content>
