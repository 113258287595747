import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { map } from 'rxjs';

import { UniversalLink } from '../models/universal-link.model';

import { NavigationService } from '../services/navigation.service';
import { PlatformService } from '../services/ssr/platform.service';
import { UniversalLinksAttributionService } from '../services/universal-links-attribution.service';

import { AppReadyGuard } from './app-ready.guard';

@Injectable({
  providedIn: 'root',
})
export class ReferralProgramRedirectionGuard implements CanActivate {
  constructor(
    private router: Router,
    private appReadyGuard: AppReadyGuard,
    private platformService: PlatformService,
    private navigationService: NavigationService,
    private universalLinksAttributionService: UniversalLinksAttributionService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.appReadyGuard.canActivate().pipe(map(() => this.checkAfiiliateLink(route)));
  }

  private checkAfiiliateLink(route: ActivatedRouteSnapshot): boolean | UrlTree {
    if (!this.platformService.isBrowser) {
      void this.navigationService.navigateBackToHomePage();
      return false;
    }

    const userId = route.paramMap.get('userId');
    const redirectUrl = route.queryParamMap.get('redirectUrl');

    if (!userId) {
      void this.navigationService.navigateBackToHomePage();
      return false;
    }

    this.universalLinksAttributionService.saveUniversalLinkClick({ affiliateUserId: userId } as unknown as UniversalLink);

    if (!redirectUrl) {
      void this.navigationService.navigateBackToHomePage();
      return false;
    }

    // IMPORTANT: If we include the query string params in the url sent to createUrlTree([url])
    // they will be encoded and the target page will get the encoded values since Angular won't
    // decode them automatically. So we need to parse the full URL and:
    // - use the pathname as the URL to make it relative
    // - get the query string params (if any) and use the queryParams property
    // - get the hash (if any) and use the fragment property

    const redirectUrlDetails = this.navigationService.getUrlDetails(redirectUrl);

    return this.router.createUrlTree([redirectUrlDetails.pathname], {
      queryParams: redirectUrlDetails.searchMap || undefined,
      fragment: redirectUrlDetails.hash || undefined,
    });
  }
}
