import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { NavigationService } from '../services/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class NoEntryPageGuard implements CanActivate {
  constructor(private navigationService: NavigationService) {}

  canActivate() {
    if (this.navigationService.isEntryPageNavigation()) {
      void this.navigationService.navigateBackToHomePage();
      return false;
    }

    return true;
  }
}
