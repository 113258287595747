import { LaunchReview } from '@awesome-cordova-plugins/launch-review/ngx';

import { Observable, of } from 'rxjs';

import { PlatformService } from '../ssr/platform.service';

class BrowserLaunchReviewProvider extends LaunchReview {
  public launch(_appId?: string): Promise<void> {
    return Promise.resolve();
  }

  public rating(): Observable<string> {
    return of('loremipsum');
  }

  public isRatingSupported(): boolean {
    return true;
  }
}

class MobileLaunchReviewProvider extends LaunchReview {}

function launchReviewFactory(platformService: PlatformService): LaunchReview {
  return platformService.areCordovaPluginsAvailable ? new MobileLaunchReviewProvider() : new BrowserLaunchReviewProvider();
}

export const launchReviewProvider = { provide: LaunchReview, useFactory: launchReviewFactory, deps: [PlatformService] };
