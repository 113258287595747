<div class="cart-container" [class.empty-cart-container]="showEmptyCartMessage">
  <div @animatedEmptyMessage [@.disabled]="isBrowser" *ngIf="showEmptyCartMessage" class="empty-cart-content">
    <ion-icon src="/assets/img/empty-cart.svg"></ion-icon>
    <div data-cy="empty-cart-title" class="empty-cart-title">{{ "CART_PAGE.EMPTY_CART_TITLE" | translate }}</div>
    <div data-cy="empty-cart-message" class="empty-cart-message">{{ "CART_PAGE.EMPTY_CART_MESSAGE" | translate }}</div>
  </div>

  <div @animatedCartContent [@.disabled]="isBrowser" class="cart-content" *ngIf="!isCartEmpty && !!cartDetails">
    <div *ngIf="cartDetails" class="cart-vendors animated-cart-vendors">
      <ng-container *ngFor="let cartVendor of cartDetails.caterers; trackBy: identifyVendor">
        <div
          @animatedCartVendor
          [@.disabled]="isBrowser"
          *ngIf="!showOnlyVendorId || showOnlyVendorId === cartVendor.catererId"
          class="cart-vendor"
          data-cy="cart-vendor"
        >
          <div class="cart-vendor-header">
            <div class="cart-vendor-name" data-cy="vendor-name">{{ cartVendor.name }}</div>
            <div tappable @animatedTrashIcon *ngIf="inEditMode" class="cart-vendor-delete-button" (click)="onDeleteVendor(cartVendor)">
              <ion-icon icon="/assets/custom-icons/shared/trash.svg"></ion-icon>
            </div>
          </div>

          <div class="cart-vendor-branch" *ngIf="cartVendor.branch">{{ cartVendor.branch }}</div>

          <div
            @animatedCartItem
            [@.disabled]="isBrowser"
            class="cart-item"
            data-cy="cart-item"
            *ngFor="let cartItem of cartVendor.items; let i = index; trackBy: identifyMenuItem"
          >
            <div tappable class="cart-item-details" (click)="onEditMenuItem({ vendorId: cartItem.catererId, itemId: cartItem.menuItemId })">
              <div class="cart-item-image">
                <app-animated-image [imageUrl]="cartItem.imageUrl"></app-animated-image>
                <div class="cart-item-notice" *ngIf="noticePeriodWarnings[cartItem.menuItemId]">
                  <ion-icon src="/assets/custom-icons/b-clock.svg"></ion-icon>
                  <span>{{ noticePeriodWarnings[cartItem.menuItemId] }}</span>
                </div>
              </div>

              <div class="cart-item-summary">
                <div class="cart-item-selection-summary">
                  <div class="cart-item-header">
                    <div class="cart-item-name" data-cy="item-name">{{ cartItem.name }}</div>
                    <div class="cart-item-total" data-cy="cart-item-total">{{ cartItem.total | localCurrency }}</div>
                  </div>

                  <div class="cart-item-selection">
                    <div class="cart-item-selection-detail" data-cy="cart-item-option" *ngFor="let cartItemOption of cartItem.options">
                      {{ cartItemOption.name }} ({{ cartItemOption.quantity }})
                    </div>

                    <div class="cart-item-selection-detail" data-cy="cart-item-addon" *ngFor="let cartItemAddon of cartItem.addOns">
                      {{ cartItemAddon.name }} ({{ cartItemAddon.quantity }})
                    </div>

                    <div class="cart-item-selection-detail" data-cy="female-service" *ngIf="cartItem.femaleServers">
                      {{ "CART_DETAILS_COMPONENT.FEMALE_SERVICE_REQUESTED" | translate }}
                    </div>
                  </div>
                </div>

                <div class="cart-item-quantity-details">
                  <ion-button
                    color="grey"
                    class="cart-item-quantity-button custom-button"
                    (click)="onDecreaseItemQuantity($event, cartItem)"
                  >
                    <ion-icon slot="icon-only" src="/assets/custom-icons/b-minus.svg"></ion-icon>
                  </ion-button>
                  <div class="cart-item-quantity" data-cy="cart-item-quantity">{{ cartItem.quantity }}</div>

                  <ion-button
                    color="grey"
                    class="cart-item-quantity-button custom-button"
                    [class.disabled]="cartItem.maximumQuantity && cartItem.maximumQuantity === cartItem.quantity"
                    (click)="onIncreaseItemQuantity($event, cartItem)"
                  >
                    <ion-icon slot="icon-only" src="/assets/custom-icons/b-plus.svg"></ion-icon>
                  </ion-button>
                </div>
              </div>
            </div>

            <div
              tappable
              class="cart-item-notes"
              (click)="onUpdateItemNotes(cartVendor.name, cartItem)"
              *ngIf="cartItem.serviceType !== groceryItemServiceType"
            >
              <div class="cart-item-notes-label">
                {{ (isAdditionalCardSupportedByServiceType[cartItem.serviceType] ? "CART_PAGE.GIFT_CARD" : "CART_PAGE.NOTES") | translate }}
              </div>
              <div class="cart-item-notes-placeholder" *ngIf="!cartItem.specialRequests">
                {{
                  (isAdditionalCardSupportedByServiceType[cartItem.serviceType]
                    ? "CART_PAGE.GIFT_CARD_PLACEHOLDER"
                    : "CART_PAGE.NOTES_PLACEHOLDER"
                  ) | translate
                }}
              </div>
              <div class="cart-item-notes-content" data-cy="special-requests" *ngIf="cartItem.specialRequests">
                {{ cartItem.specialRequests }}
              </div>
            </div>
          </div>

          <div tappable class="cart-vendor-delivery" (click)="onEditDeliveryTime(cartVendor.catererId)">
            <div class="cart-vendor-delivery-details">
              <div class="cart-vendor-delivery-provider">{{ cartVendor.deliveryProvider | deliveredBy }}</div>
              <div class="cart-vendor-delivery-fee" data-cy="delivery-charges">
                {{ cartVendor.delivery ? (cartVendor.delivery | localCurrency) : ("CART_DETAILS_COMPONENT.DELIVERY_FREE" | translate) }}
              </div>
            </div>
            <div class="cart-vendor-delivery-date" data-cy="delivery-time-text">
              <ng-container *ngIf="isTimeSlotBasedOrder">
                <ng-container
                  *ngIf="selectedTimeSlotsPerVendor[cartVendor.catererId] as selectedTimeSlot; else noTimeSlotForVendorTemplate"
                >
                  {{ selectedTimeSlot.dateText }} {{ selectedTimeSlot.timeSlot.timeSlotText }}
                </ng-container>

                <ng-template #noTimeSlotForVendorTemplate>
                  {{ "CART_DETAILS_COMPONENT.NO_AVAILABLE_TIME_SLOTS" | translate }}
                </ng-template>
              </ng-container>

              <ng-container *ngIf="isDateTimeBasedOrder">
                <ng-container *ngIf="isOrderForNowSelected">
                  <ng-container *ngIf="cartVendor.eta?.label || cartVendor.eta?.description; else noETAForVendorTemplate">
                    {{ cartVendor.eta?.label }} {{ cartVendor.eta?.description }}
                  </ng-container>

                  <ng-template #noETAForVendorTemplate>
                    {{ "CART_DETAILS_COMPONENT.NO_AVAILABLE_ETA" | translate }}
                  </ng-template>
                </ng-container>

                <ng-container *ngIf="!isOrderForNowSelected">
                  <ng-container
                    *ngIf="selectedDateTimesPerVendor[cartVendor.catererId] as selectedDateTime; else noDateTimeForVendorTemplate"
                  >
                    {{ selectedDateTime | formatDateTime : { format: "ddd MMM D, h:mm A" } }}

                    <div
                      class="cart-vendor-delivery-warning"
                      data-cy="delivery-time-text-warning"
                      *ngIf="listOfVendorIdsDateTimeUpdatedAutomatically[cartVendor.catererId]"
                    >
                      {{ "CART_DETAILS_COMPONENT.DELIVERY_TIME_ADJUSTED" | translate }}
                    </div>
                  </ng-container>

                  <ng-template #noDateTimeForVendorTemplate>
                    {{ "CART_DETAILS_COMPONENT.SELECT_DATE_TIME" | translate }}
                  </ng-template>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="!isTimeSlotBasedOrder && !isDateTimeBasedOrder">
                <ng-container *ngIf="cartVendor.eta?.label || cartVendor.eta?.description; else noETAForVendorTemplate">
                  {{ cartVendor.eta?.label }} {{ cartVendor.eta?.description }}
                </ng-container>

                <ng-template #noETAForVendorTemplate>
                  {{ "CART_DETAILS_COMPONENT.NO_AVAILABLE_ETA" | translate }}
                </ng-template>
              </ng-container>
            </div>
          </div>

          <div *ngIf="!hideTotals" class="cart-vendor-footer">
            <span>{{ cartVendor.name }}</span>
            <span class="cart-vendor-total">{{ cartVendor.total | localCurrency }}</span>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="!hideTotals && cartDetails && subtotal" class="cart-totals animated-cart-totals">
      <div *ngIf="subtotal !== total" class="cart-total-item">
        <div class="cart-total-item-label">{{ "CHECKOUT_PAGE.SUBTOTAL" | translate }}</div>
        <div class="cart-total-item-value" data-cy="cart-total-amount">{{ subtotal | localCurrency }}</div>
      </div>
      <div *ngIf="discount" class="cart-total-item">
        <div class="cart-total-item-label">{{ "CHECKOUT_PAGE.DISCOUNT" | translate }}</div>
        <div class="cart-total-item-value">-{{ discount | localCurrency }}</div>
      </div>
      <div *ngIf="walletCredits" class="cart-total-item">
        <div class="cart-total-item-label">{{ "CHECKOUT_PAGE.WALLET_CREDITS" | translate }}</div>
        <div class="cart-total-item-value">-{{ walletCredits | localCurrency }}</div>
      </div>
      <div class="cart-total-item total">
        <div class="cart-total-item-label">{{ "CHECKOUT_PAGE.TOTAL" | translate }}</div>
        <div class="cart-total-item-value">{{ total | localCurrency }}</div>
      </div>
    </div>
  </div>
</div>
