// These plugins don't need any specific factory
// since they work as well in the browser
import { File } from '@awesome-cordova-plugins/file/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx';

// These plugins do not work properly on the browser
// so we need to create factories for each plugin
import { branchProvider } from './branch.provider';
import { callNumberProvider } from './call-number.provider';
import { cleverTapProvider } from './clever-tap.provider';
import { clipboardProvider } from './clipboard.provider';
import { diagnosticProvider } from './diagnostic.provider';
import { emailComposerProvider } from './email-composer.provider';
import { firebaseProvider } from './firebase.provider';
import { geolocationProvider } from './geolocation.provider';
import { keyboardProvider } from './keyboard.provider';
import { launchReviewProvider } from './launch-review.provider';
import { locationAccuracyProvider } from './location-accuracy.provider';
import { marketProvider } from './market.provider';
import { mixpanelPeopleProvider, mixpanelProvider } from './mixpanel.provider';
import { networkProvider } from './network.provider';
import { socialSharingProvider } from './social-sharing.provider';
import { splashScreenProvider } from './splash-screen.provider';
import { statusBarProvider } from './status-bar.provider';

export default [
  File,
  SQLite,
  InAppBrowser,
  branchProvider,
  marketProvider,
  networkProvider,
  keyboardProvider,
  firebaseProvider,
  mixpanelProvider,
  statusBarProvider,
  callNumberProvider,
  diagnosticProvider,
  geolocationProvider,
  splashScreenProvider,
  launchReviewProvider,
  socialSharingProvider,
  emailComposerProvider,
  mixpanelPeopleProvider,
  locationAccuracyProvider,
  clipboardProvider,
  cleverTapProvider,
];
