import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { MarketPlugin } from '../services/native-plugins/market.plugin';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ModalService } from '../services/modal.service';
import { NavigationService } from '../services/navigation.service';
import { OverlayService } from '../services/overlay.service';

import { shouldSkipHttpInterceptors } from '../config/app.config';

@Injectable()
export class UpgradeInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (shouldSkipHttpInterceptors(request.url)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 410) {
          const marketPlugin = this.injector.get(MarketPlugin);
          const overlayService = this.injector.get(OverlayService);
          const modalService = this.injector.get(ModalService);
          const navigationService = this.injector.get(NavigationService);
          const translateService = this.injector.get(TranslateService);

          void overlayService.hideLoading();
          void navigationService.navigateTo('update-optional', false, { replaceUrl: true });

          void modalService.showAlert({
            title: translateService.instant('APP_UPDATE_OPTIONAL_PAGE.TITLE') as string,
            message: translateService.instant('APP_UPDATE_OPTIONAL_PAGE.MESSAGE') as string,
            buttons: [
              {
                isPrimary: true,
                text: translateService.instant('Update') as string,
                handler: () => marketPlugin.openAppStore(),
              },
              {
                text: translateService.instant('Cancel') as string,
              },
            ],
          });
        }

        return throwError(error);
      }),
    );
  }
}
