import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ViewDidEnter } from '@ionic/angular';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Address } from '../../core/models/address.model';

import { CalendarMonth, DateTimeService } from '../../core/services/date-time.service';
import { ModalService } from '../../core/services/modal.service';
import { OverlayService } from '../../core/services/overlay.service';

import {
  AreaDateTimePickerComponentParams,
  AreaDateTimePickerComponentResult,
} from '../../shared/components/area-date-time-picker/area-date-time-picker.component';

import { Helpers } from '../../core/helpers';

import { BrowserDateTimePickerModalPageAnimations } from './browser-date-time-picker-modal.animations';

export const BrowserDateTimePickerModalPageIdentifier = 'browser-date-time-picker-modal';

const CUTTOF_TIME_IN_MINUTES = 60;
const DEFAULT_SELECTED_HOUR = '12';
const DEFAULT_SELECTED_MINUTE = '00';

const DATE_TEXT_DEFAULT_FORMAT = 'ddd MMM D';

const TIME_VALUE_ORDER_FOR_NOW = 'NOW';
const DATE_VALUE_DEFAULT_FORMAT = 'DD/MM/YYYY';
const TIME_VALUE_DEFAULT_FORMAT = 'HH:mm';
const HOUR_VALUE_DEFAULT_FORMAT = 'HH';
const MINUTE_VALUE_DEFAULT_FORMAT = 'mm';

interface TimeValue {
  hour: number;
  minute: number;
}

interface DateTimeValues {
  date: string;
  times: Array<TimeValue>;
}

interface BrowserDateOption {
  text: string;
  value: string;
  enabled: boolean;
  isToday: boolean;
  isBeforeToday: boolean;
  isAfterToday: boolean;
  valueIsoString: string;
}

interface BrowserHourMinuteOption {
  text: string;
  hourValue: string;
  minuteValue: string;
  enabled: boolean;
}

@Component({
  selector: 'app-browser-date-time-picker-modal',
  templateUrl: 'browser-date-time-picker-modal.page.html',
  styleUrls: ['browser-date-time-picker-modal.page.scss'],
  animations: [...BrowserDateTimePickerModalPageAnimations],
  encapsulation: ViewEncapsulation.None,
})
export class BrowserDateTimePickerModalPage implements OnInit, ViewDidEnter, OnDestroy {
  @Input()
  public params?: AreaDateTimePickerComponentParams;

  public allHours: Array<number> = [];
  public allMinutes: Array<number> = [];

  public isReady: boolean;
  public isDatePickerLoading = false;
  public isDateTimePickerVisible = false;

  public calendar: CalendarMonth;

  public dateOptions: Array<BrowserDateOption>;
  public timeOptions: Array<BrowserHourMinuteOption>;

  public selectedAddress: Address;
  public selectedDateTime: string;
  public isOrderForNowSelected: boolean;
  public enableBackdropDismiss = true;
  public isOrderForNowAvailable = false;

  public dateSelectedIndex: number;
  public timeSelectedIndex: number;

  public newSelectedDateTime: string;
  public newIsOrderForNowSelected: boolean;

  public enableFooterAnimations = false;

  private availableDateTimeValuessFromAPI: Array<DateTimeValues>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private modalService: ModalService,
    private overlayService: OverlayService,
    private dateTimeService: DateTimeService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.isReady = false;

    for (let hour = 0; hour < 24; hour++) {
      this.allHours.push(hour);
    }

    for (let minute = 0; minute <= 30; minute += 30) {
      this.allMinutes.push(minute);
    }

    if (this.params) {
      this.selectedAddress = this.params.selectedAddress;
      this.selectedDateTime = this.params.selectedDateTime;
      this.isOrderForNowSelected = !!this.params.isOrderForNowSelected;
      this.isOrderForNowAvailable = !!this.params.isOrderForNowAvailable;
      this.enableBackdropDismiss = !!this.params.enableBackdropDismiss;
    }

    this.newSelectedDateTime = this.selectedDateTime ?? null;
    this.newIsOrderForNowSelected = this.isOrderForNowAvailable && this.isOrderForNowSelected;

    if (this.params?.availableDateTimesAsync) {
      this.isDatePickerLoading = true;

      this.params.availableDateTimesAsync.pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (availableDateTimeValuessFromAPI) => {
          this.availableDateTimeValuessFromAPI = availableDateTimeValuessFromAPI ?? [];
          this.isDatePickerLoading = false;
          this.initializePicker();
        },
        error: () => {
          this.availableDateTimeValuessFromAPI = [];
          this.isDatePickerLoading = false;
          this.initializePicker();
        },
      });
    } else {
      this.availableDateTimeValuessFromAPI = [];
      this.initializePicker();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  ionViewDidEnter() {
    this.enableFooterAnimations = true;
  }

  public onDismiss(results?: AreaDateTimePickerComponentParams): Promise<boolean> {
    return this.modalService.dismissModal({ id: BrowserDateTimePickerModalPageIdentifier, data: results });
  }

  public onShowNextMonth(): void {
    this.updateCalendar({ monthsToAdd: 1 });
  }

  public onShowPrevMonth(): void {
    this.updateCalendar({ monthsToAdd: -1 });
  }

  public onUpdateSelectedDate(newDateSelectedIndex: number): void {
    if (this.dateOptions[newDateSelectedIndex].isBeforeToday) {
      return;
    }

    // 1) is order for now selected
    //    a) selected today => keep order for now
    //    b) selected other day => select order for later and initialize time options
    // 2) is order for later selected => initialize time options for the new date

    this.dateSelectedIndex = newDateSelectedIndex;
    this.newIsOrderForNowSelected = this.isOrderForNowAvailable && this.dateOptions[newDateSelectedIndex].isToday;

    this.initializeTimeOptions();
    this.updateNewSelectedDateTime();
  }

  public onUpdateSelectedTime(newTimeSelectedIndex: number): void {
    if (!this.timeOptions[newTimeSelectedIndex].enabled) {
      return;
    }

    this.timeSelectedIndex = newTimeSelectedIndex;
    this.updateNewSelectedDateTime();
  }

  public onHandleSubmitClick(): void {
    const results = this.getSelectedData();

    if (this.areResultsValid(results)) {
      this.dismiss(results);
    }
  }

  public identifyTimeOption(_index: number, value: BrowserHourMinuteOption): string {
    return value.text;
  }

  private dismiss(results?: AreaDateTimePickerComponentResult): void {
    if (!this.isReady) {
      return;
    }

    void this.modalService.dismissModal({ id: BrowserDateTimePickerModalPageIdentifier, data: results ?? undefined });
  }

  private initializePicker(): void {
    this.calendar = this.dateTimeService.getCalendarForMonth(this.newSelectedDateTime ?? undefined);
    this.initializeDateOptions({ autoSelectDate: true });

    this.isReady = true;
    this.isDatePickerLoading = false;
  }

  private updateCalendar({ monthsToAdd }: { monthsToAdd: number }): void {
    this.calendar = this.dateTimeService.getCalendarForMonth(this.calendar.days[0].valueIsoString, monthsToAdd);

    this.clearSelectedDateTime();
    this.initializeDateOptions({ autoSelectDate: false });
  }

  private initializeDateOptions({ autoSelectDate }: { autoSelectDate: boolean }): void {
    this.dateOptions = this.getBrowserDateOptions(this.calendar);

    if (autoSelectDate) {
      this.dateSelectedIndex = this.dateOptions.findIndex((dateOption) => dateOption.enabled);

      // Scenarios to be handled:
      //  1) is date/time selected
      //    a) date/time is still available => select that date/time
      //    b) date/time is NOT available
      //      i) order for now is available => select order for now
      //      ii) order for now is NOT available => select first available date/time
      //  2) is order for now selected
      //    a) order for now is available => select order for now
      //    b) order for now is NOT available => select first available date/time

      if (this.newSelectedDateTime) {
        const dateSelectedIndex = this.dateOptions.findIndex(
          (dateOption) =>
            dateOption.enabled &&
            dateOption.value === this.dateTimeService.format({ dateTimeIso: this.newSelectedDateTime, format: DATE_VALUE_DEFAULT_FORMAT }),
        );

        if (dateSelectedIndex !== -1) {
          this.dateSelectedIndex = dateSelectedIndex;
        } else if (this.isOrderForNowAvailable) {
          // The selected date/time is not available anymore but order for now is
          // available so we should select order for now by default
          this.newIsOrderForNowSelected = true;
        } else {
          // The selected date/time is not available anymore and order for now is not
          // available either, so we just need to keep the first available day as
          // selected by default (done above when initializing this.dateSelectedIndex)
          this.newIsOrderForNowSelected = false;
        }
      } else {
        // Both if order for now was selected or not, since there's no selected
        // date/time we need to check if we can select order for now by default
        // or not based on if it's available or not for this service type
        this.newIsOrderForNowSelected = this.isOrderForNowAvailable;
      }

      this.initializeTimeOptions();
      this.updateNewSelectedDateTime();
    }
  }

  private initializeTimeOptions(): void {
    if (this.dateSelectedIndex >= 0) {
      this.timeOptions = this.getBrowserHourMinuteOptions({ selectedDateIndex: this.dateSelectedIndex });

      if (this.newIsOrderForNowSelected) {
        const orderForNowIndex = this.timeOptions.findIndex(
          (hourOption) =>
            hourOption.enabled && hourOption.hourValue === TIME_VALUE_ORDER_FOR_NOW && hourOption.minuteValue === TIME_VALUE_ORDER_FOR_NOW,
        );

        if (orderForNowIndex >= 0) {
          this.timeSelectedIndex = orderForNowIndex;
          return;
        }
      }

      if (this.newSelectedDateTime) {
        const selectedTimeIndex = this.timeOptions.findIndex(
          (hourOption) =>
            hourOption.enabled &&
            hourOption.hourValue ===
              this.dateTimeService.format({ dateTimeIso: this.newSelectedDateTime, format: HOUR_VALUE_DEFAULT_FORMAT }) &&
            hourOption.minuteValue ===
              this.dateTimeService.format({ dateTimeIso: this.newSelectedDateTime, format: MINUTE_VALUE_DEFAULT_FORMAT }),
        );

        if (selectedTimeIndex >= 0) {
          this.timeSelectedIndex = selectedTimeIndex;
          return;
        }
      }

      const defaultHourIndex = this.timeOptions.findIndex(
        (hourOption) =>
          hourOption.enabled && hourOption.hourValue === DEFAULT_SELECTED_HOUR && hourOption.minuteValue === DEFAULT_SELECTED_MINUTE,
      );

      this.timeSelectedIndex = defaultHourIndex >= 0 ? defaultHourIndex : this.timeOptions.findIndex((hourOption) => hourOption.enabled);
    }
  }

  private getBrowserDateOptions(calendar: CalendarMonth): Array<BrowserDateOption> {
    const dateOptions: Array<BrowserDateOption> = [];

    // In the mobile picker we only show the available dates but in the browser picker
    // we need to show all dates, disabling the ones that are not available

    const availableDatesFromApi = this.availableDateTimeValuessFromAPI?.length
      ? this.availableDateTimeValuessFromAPI.map((dateTimes) => dateTimes.date)
      : [];

    calendar.days.forEach((date) => {
      const dateValue = this.dateTimeService.format({ dateTimeIso: date.valueIsoString, format: DATE_VALUE_DEFAULT_FORMAT });
      const dateText = this.dateTimeService.format({
        dateTimeIso: date.valueIsoString,
        format: DATE_TEXT_DEFAULT_FORMAT,
        useRelativeDate: true,
      });

      // Things to check to see if the date is available:
      // - it should not be a date in the past
      // - if it's today, the current local time + cuttof should be <= today at 23:30
      // - if it's after today, it should be included in the available date/times from the API (if defined)

      const isAvailable =
        (date.isBeforeToday
          ? false
          : date.isToday
          ? this.dateTimeService.isTimeSameOrAfterCurrentLocalTime(23, 30, CUTTOF_TIME_IN_MINUTES)
          : true) &&
        (!availableDatesFromApi.length || !!availableDatesFromApi.find((dateFromApi) => date.valueIsoString === dateFromApi));

      dateOptions.push({
        text: dateText,
        value: dateValue,
        enabled: isAvailable,
        isToday: date.isToday,
        isAfterToday: date.isAfterToday,
        isBeforeToday: date.isBeforeToday,
        valueIsoString: date.valueIsoString,
      });
    });

    return dateOptions;
  }

  private getBrowserHourMinuteOptions({ selectedDateIndex }: { selectedDateIndex: number }): Array<BrowserHourMinuteOption> {
    const timeOptions: Array<BrowserHourMinuteOption> = [];

    const forToday = this.isToday({ dateValue: this.dateOptions[this.dateSelectedIndex].value });
    const forTomorrow = this.isTomorrow({ dateValue: this.dateOptions[this.dateSelectedIndex].value });

    const availableValues: Array<TimeValue> = this.getAllAvailableTimes();
    let availableValuesFromApi: Array<TimeValue>;

    if (selectedDateIndex != null && this.availableDateTimeValuessFromAPI?.length) {
      const selectedDateIsoString = this.dateTimeService.convertToDateTimeIsoString(
        this.dateOptions[selectedDateIndex].value,
        DATE_VALUE_DEFAULT_FORMAT,
      );
      const selectedDateFromApi = this.availableDateTimeValuessFromAPI.find((dateTime) => dateTime.date === selectedDateIsoString);

      if (selectedDateFromApi?.times?.length) {
        availableValuesFromApi = selectedDateFromApi.times;
      }
    }

    availableValues.forEach(({ hour, minute }) => {
      const isValidOrEmptyFromApi =
        availableValuesFromApi && availableValuesFromApi.length
          ? availableValuesFromApi.findIndex((timeValue) => timeValue.hour === hour && timeValue.minute === minute) !== -1
          : true;

      // We need to check the hour and minutes against the current time today
      // AND tomorrow to handle properly what happens when opening the picker
      // near midnight (before and after)
      const isValid =
        isValidOrEmptyFromApi &&
        (forToday || forTomorrow
          ? this.dateTimeService.isTimeSameOrAfterCurrentLocalTime(hour, minute, CUTTOF_TIME_IN_MINUTES, forTomorrow)
          : true);

      if (isValid) {
        timeOptions.push({
          text: `${Helpers.padNumber(hour)}:${Helpers.padNumber(minute)}`,
          hourValue: Helpers.padNumber(hour),
          minuteValue: Helpers.padNumber(minute),
          enabled: isValid,
        });
      }
    });

    if (this.isOrderForNowAvailable && forToday) {
      timeOptions.unshift({
        enabled: true,
        hourValue: TIME_VALUE_ORDER_FOR_NOW,
        minuteValue: TIME_VALUE_ORDER_FOR_NOW,
        text: this.translateService.instant('NOW') as string,
      });
    }

    return timeOptions;
  }

  private isToday({ dateValue, format }: { dateValue: string; format?: string }): boolean {
    return this.dateTimeService.isToday(this.dateTimeService.convertToDateTimeIsoString(dateValue, format ?? DATE_VALUE_DEFAULT_FORMAT));
  }

  private isTomorrow({ dateValue, format }: { dateValue: string; format?: string }): boolean {
    return this.dateTimeService.isTomorrow(this.dateTimeService.convertToDateTimeIsoString(dateValue, format ?? DATE_VALUE_DEFAULT_FORMAT));
  }

  private getAllAvailableTimes(): Array<TimeValue> {
    const timeValues: Array<TimeValue> = [];

    for (const hour of this.allHours) {
      for (const minute of this.allMinutes) {
        timeValues.push({ hour, minute });
      }
    }

    return timeValues;
  }

  private updateNewSelectedDateTime() {
    const hasSelectedDate = this.dateOptions?.length && this.dateSelectedIndex !== null && this.dateSelectedIndex !== undefined;
    const hasSelectedTime = this.timeOptions?.length && this.timeSelectedIndex !== null && this.timeSelectedIndex !== undefined;

    this.newIsOrderForNowSelected =
      hasSelectedDate && hasSelectedTime && this.timeOptions[this.timeSelectedIndex].hourValue === TIME_VALUE_ORDER_FOR_NOW;

    this.newSelectedDateTime =
      hasSelectedDate && hasSelectedTime && !this.newIsOrderForNowSelected
        ? this.dateTimeService.convertToDateTimeIsoString(
            `${this.dateOptions[this.dateSelectedIndex].value} ${this.timeOptions[this.timeSelectedIndex].hourValue}:${
              this.timeOptions[this.timeSelectedIndex].minuteValue
            }`,
            `${DATE_VALUE_DEFAULT_FORMAT} ${TIME_VALUE_DEFAULT_FORMAT}`,
          )
        : null;
  }

  private clearSelectedDateTime(): void {
    this.dateSelectedIndex = null;
    this.timeSelectedIndex = null;

    this.updateNewSelectedDateTime();
  }

  private getSelectedData(): AreaDateTimePickerComponentResult {
    return {
      hasChangedData: this.hasChangedData(),
      // We're not doing anything with the area in this picker, we just
      // return whatever area/address was sent in the inputs
      selectedAddress: this.selectedAddress,
      selectedDateTime: this.newIsOrderForNowSelected ? null : this.newSelectedDateTime,
      isOrderForNowSelected: this.newIsOrderForNowSelected,
    };
  }

  private hasChangedData(): boolean {
    return (
      this.dateTimeService.hasChangedDateOrTime(this.newSelectedDateTime, this.selectedDateTime) ||
      this.newIsOrderForNowSelected !== this.isOrderForNowSelected
    );
  }

  private areResultsValid(data: AreaDateTimePickerComponentResult): boolean {
    const dateTime = data.selectedDateTime;
    const isOrderForNowSelected = data.isOrderForNowSelected;

    const showToastMessage = (message: string) => this.overlayService.showToast({ message, showCloseButton: true, type: 'error' });

    if (!isOrderForNowSelected) {
      if (!dateTime) {
        const errorMessage = this.translateService.instant('AREA_DATE_TIME_PICKER.ERROR_EMPTY_DATE_TIME') as string;
        showToastMessage(errorMessage);
        return false;
      }

      if (this.dateTimeService.isBeforeCurrentLocalTime(dateTime)) {
        const errorMessage = this.translateService.instant('AREA_DATE_TIME_PICKER.ERROR_INVALID_DATE_TIME') as string;
        showToastMessage(errorMessage);
        return false;
      }
    }

    return true;
  }
}
