import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { DeliveryProviders } from '../enums/delivery-providers.enum';
import { LogicalOperator } from '../enums/logical-operator.enum';
import { VendorSorterType } from '../enums/vendor-sorter-type.enum';

import { Address } from '../models/address.model';
import { Area } from '../models/area.model';
import { Banner } from '../models/banner.model';
import { BusinessRegister } from '../models/business-register.model';
import { CountryCode } from '../models/country.model';
import { GroceryItemsWithCategories } from '../models/grocery-category-info.model';
import { GroceryCategory } from '../models/grocery-category.model';
import { GroceryMenuItem } from '../models/grocery-menu-item.model';
import { GrocerySearchResults } from '../models/grocery-search-results.model';
import { GroceryVendorDetails } from '../models/grocery-vendor-details.model';
import { HighlightedCollection } from '../models/highlighted-collection.model';
import { HttpQueryStringParams } from '../models/http-query-string-param.model';
import { InfoMessage } from '../models/info-message.model';
import { ItemAvailableDateTime } from '../models/item-available-date-time.model';
import { MenuItemDetails } from '../models/menu-item-details.model';
import { CatererReview } from '../models/review.model';
import { SearchMetadata } from '../models/search-metadata.model';
import { VendorsHomePageSearchResults, VendorsItemsHomePageSearchResults } from '../models/search-results.model';
import { Tag } from '../models/tag.model';
import { TimeSlotsByDate } from '../models/time-slots-by-date.model';
import { VendorAvailableDateTime } from '../models/vendor-available-date-time.model';
import { VendorChatEvent } from '../models/vendor-chat-event.model';
import { VendorDetails, VendorDetailsBySlug, VendorDetailsMenuGroup, VendorMenuItem } from '../models/vendor-details.model';
import { VendorItem } from '../models/vendor-item.model';
import { Vendor } from '../models/vendor.model';

import { CartService } from './cart.service';
import { DateTimeService } from './date-time.service';
import { EnvironmentService } from './environment.service';
import { LoggerService } from './logger.service';
import { NoticePeriodService } from './notice-period.service';
import { SettingsService } from './settings.service';
import { StateService } from './state.service';

import { AppConfig, TOKEN_CONFIG } from '../config/app.config';

import { Helpers } from '../helpers';

import { VendorSignup } from '../models/vendor-signup';

type VendorId = number;
type VendorSlug = string;
type ObjectWithTags = { tags: number[] };

export type VendorsSlugByIdMap = Record<CountryCode, Record<VendorId, { slug: string; serviceTypes: number }>>;
export type VendorsIdBySlugMap = Record<CountryCode, Record<VendorSlug, { id: number; serviceTypes: number }>>;

export interface SearchResultsBaseQueryParams {
  area?: Area;
  dateTime?: string;
  orderForNow?: boolean;
}

export interface SearchResultsQueryParams extends SearchResultsBaseQueryParams {
  serviceType: number;
}

export interface AddressDateTimeFilters {
  address: Address;
  dateTime: string;
  orderForNow: boolean;
}

interface VendorDetailsQueryParams {
  vendorId: number;
  serviceTypes?: number;
  areaId?: number;
  dateTime?: string;
  orderForNow?: boolean;
  itemId?: number;
}

export interface VendorAvailableDateTimesPayload {
  areaId: number;
  menuItems: Array<{ menuItemId: number }>;
}

export const MIN_TIME_TO_REFRESH_RESULTS_FOR_LATER_IN_MINUTES = 2880;
export const MIN_TIME_TO_REFRESH_RESULTS_FOR_NOW_IN_MINUTES = 30;
export const MAX_TIME_TO_REFRESH_RESULTS_FOR_NOW_IN_MINUTES = 180;

export enum LocalMenuGroup {
  RecentlyOrderedItems = -1,
  TrendingItems = -2,
}

@Injectable({ providedIn: 'root' })
export class VendorsService {
  // IMPORTANT: I didn't want to put this in the shared state because they're
  // maps with more than 1000 keys and it will only be used by this service.
  private vendorsSlugByIdMap: VendorsSlugByIdMap;
  private vendorsIdBySlugMap: VendorsIdBySlugMap;

  private collectionsSearchResults = new Map<number, Array<HighlightedCollection>>();
  private vendorsSearchResults = new Map<number, Array<Vendor>>();
  private vendorsItemsSearchResults = new Map<number, Array<VendorItem>>();
  private tagsSearchResults = new Map<number, Array<Tag>>();
  private popularTagsSearchResults = new Map<number, Array<Tag>>();
  private bannersSearchResults = new Map<number, Array<Banner>>();
  private infoMessagesSearchResults = new Map<number, Array<InfoMessage>>();

  private collectionsShortcutsResults: Array<HighlightedCollection>;
  private vendorsShortcutsResults: Array<Vendor>;
  private bannersShortcutsResults: Array<Banner>;

  private recommendedSorterType: VendorSorterType;
  private updateSearchResultsBasedOnDateTimeFromVendorId: number;

  constructor(
    private http: HttpClient,
    private stateService: StateService,
    private cartService: CartService,
    private loggerService: LoggerService,
    private dateTimeService: DateTimeService,
    private settingsService: SettingsService,
    private translateService: TranslateService,
    private environmentService: EnvironmentService,
    private noticePeriodService: NoticePeriodService,
    @Inject(TOKEN_CONFIG) private config: AppConfig,
  ) {}

  public loadShortcutsSearchResults({ area }: { area: Area }): Observable<void> {
    const params: HttpQueryStringParams = { areaId: area ? area.areaId.toString() : null };
    return this.http
      .get<{
        vendors: Array<Vendor>;
        banners: Array<Banner>;
        collections: Array<HighlightedCollection>;
      }>(this.config.routes.getShortcutsForUser.url, { params })
      .pipe(
        map((searchResults) => {
          this.collectionsShortcutsResults = searchResults.collections || [];
          this.vendorsShortcutsResults = searchResults.vendors || [];
          this.bannersShortcutsResults = searchResults.banners || [];
        }),
      );
  }

  public loadSearchResults({ serviceType, ...params }: SearchResultsQueryParams): Observable<void> {
    return this.settingsService.supportsVendorsItemsOnHomePage(serviceType)
      ? this.loadVendorsItemsSearchResults({ serviceType, ...params })
      : this.loadVendorsSearchResults({ serviceType, ...params });
  }

  public loadVendorsSlugByIdMapStaticFile(): Observable<void> {
    return this.http.get<VendorsSlugByIdMap>(this.environmentService.vendorsSlugsUrl).pipe(
      map((vendorsSlugByIdMap) => {
        try {
          if (vendorsSlugByIdMap) {
            const vendorsIdBySlugMap = {} as unknown as VendorsIdBySlugMap;

            Object.keys(vendorsSlugByIdMap).forEach((country: CountryCode) => {
              vendorsIdBySlugMap[country] = {};

              Object.keys(vendorsSlugByIdMap[country]).forEach((vendorId) => {
                const vendorSlug = vendorsSlugByIdMap[country][+vendorId].slug;
                const vendorServiceTypes = vendorsSlugByIdMap[country][+vendorId].serviceTypes;
                vendorsIdBySlugMap[country][vendorSlug] = {
                  id: +vendorId,
                  serviceTypes: vendorServiceTypes,
                };
              });
            });

            this.vendorsSlugByIdMap = vendorsSlugByIdMap;
            this.vendorsIdBySlugMap = vendorsIdBySlugMap;
          }
        } catch (error: unknown) {
          this.vendorsSlugByIdMap = undefined;
          this.vendorsIdBySlugMap = undefined;
          this.loggerService.info({
            component: 'VendorsService',
            message: "Couldn't create vendors SLUGS -> IDS map from static file",
            details: { error },
          });
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.vendorsSlugByIdMap = undefined;
        this.vendorsIdBySlugMap = undefined;
        this.loggerService.info({
          component: 'VendorsService',
          message: "Couldn't load vendors slug static file from CDN",
          details: { error },
        });
        return of(null);
      }),
    );
  }

  public getShortcutsVendors(): Array<Vendor> {
    return this.vendorsShortcutsResults || [];
  }

  public getShortcutsCollections(): Array<HighlightedCollection> {
    return this.collectionsShortcutsResults || [];
  }

  public getShortcutsBanners(): Array<Banner> {
    return this.bannersShortcutsResults || [];
  }

  public getShortcutsTags(): Array<Tag> {
    return [];
  }

  public getShortcutsPopularTags(): Array<Tag> {
    return [];
  }

  public getShortcutsInfoMessages(): Array<InfoMessage> {
    return [];
  }

  public getVendorsOfServiceType(serviceType: number): Array<Vendor> {
    return this.vendorsSearchResults && this.vendorsSearchResults.has(serviceType) ? this.vendorsSearchResults.get(serviceType) ?? [] : [];
  }

  public getVendorsItemsOfServiceType(serviceType: number): Array<VendorItem> {
    return this.vendorsItemsSearchResults && this.vendorsItemsSearchResults.has(serviceType)
      ? this.vendorsItemsSearchResults.get(serviceType) ?? []
      : [];
  }

  public getCollectionsOfServiceType(serviceType: number): Array<HighlightedCollection> {
    return this.collectionsSearchResults && this.collectionsSearchResults.has(serviceType)
      ? this.collectionsSearchResults.get(serviceType) ?? []
      : [];
  }

  public getInfoMessagesOfServiceType(serviceType: number): Array<InfoMessage> {
    return this.infoMessagesSearchResults && this.infoMessagesSearchResults.has(serviceType)
      ? this.infoMessagesSearchResults.get(serviceType) ?? []
      : [];
  }

  public getBannersOfServiceType(serviceType: number): Array<Banner> {
    return this.bannersSearchResults.has(serviceType) ? this.bannersSearchResults.get(serviceType) ?? [] : [];
  }

  public getRecommendedSorterType(): VendorSorterType {
    return this.recommendedSorterType ?? null;
  }

  public getTagsOfServiceTypes(serviceType: number): Array<Tag> {
    return this.tagsSearchResults.has(serviceType) ? this.tagsSearchResults.get(serviceType) ?? [] : [];
  }

  public getPopularTagsOfServiceTypes(serviceType: number): Array<Tag> {
    return this.popularTagsSearchResults.has(serviceType) ? this.popularTagsSearchResults.get(serviceType) ?? [] : [];
  }

  public getVendorSlugAndServiceTypeById(vendorId: number): { vendorSlug: string; vendorServiceTypes: number } {
    if (!this.vendorsSlugByIdMap) {
      return { vendorSlug: undefined, vendorServiceTypes: undefined };
    }

    const vendorDetails = this.vendorsSlugByIdMap[this.settingsService.getCountry().code][vendorId];
    return {
      vendorSlug: vendorDetails ? vendorDetails.slug : undefined,
      vendorServiceTypes: vendorDetails ? vendorDetails.serviceTypes : undefined,
    };
  }

  public getVendorIdAndServiceTypeBySlug(vendorSlug: string): { vendorId: number; vendorServiceTypes: number } {
    if (!this.vendorsIdBySlugMap) {
      return { vendorId: undefined, vendorServiceTypes: undefined };
    }

    const vendorDetails = this.vendorsIdBySlugMap[this.settingsService.getCountry().code][vendorSlug];
    return {
      vendorId: vendorDetails ? vendorDetails.id : undefined,
      vendorServiceTypes: vendorDetails ? vendorDetails.serviceTypes : undefined,
    };
  }

  public setVendorIdSlugAndServiceType(vendorId: number, vendorSlug: string, vendorServiceTypes: number): void {
    if (this.vendorsIdBySlugMap) {
      this.vendorsIdBySlugMap[this.settingsService.getCountry().code][vendorSlug] = { id: vendorId, serviceTypes: vendorServiceTypes };
    }

    if (this.vendorsSlugByIdMap) {
      this.vendorsSlugByIdMap[this.settingsService.getCountry().code][vendorId] = { slug: vendorSlug, serviceTypes: vendorServiceTypes };
    }
  }

  public getVendorDetailsBySlug(vendorSlug: string): Observable<VendorDetailsBySlug> {
    const url = this.config.routes.getVendorDetailsBySlug.url.replace('#vendorSlug#', vendorSlug);
    return this.http.get<VendorDetailsBySlug>(url);
  }

  public getVendorDetailsById({
    serviceTypes,
    vendorId,
    itemId,
    areaId,
    dateTime,
    orderForNow,
  }: VendorDetailsQueryParams): Observable<VendorDetails> {
    const url = this.config.routes.getVendorDetailsById.url.replace('#vendorId#', vendorId.toString());

    // If the user selects NOW for food, the app will also select it for Catering/Rentals/... but it
    // won't send it to the API — that way the user would see the notice requirements and the app will
    // adjust the selected date/time automatically when adding something to the cart
    // https://bilbayt.atlassian.net/browse/CA-2150

    let selectedDateTime: string;
    let isOrderForNowSelected: boolean;

    if (serviceTypes) {
      selectedDateTime = dateTime && this.settingsService.isServiceTypeBasedOnDateTime(serviceTypes) ? dateTime : null;
      isOrderForNowSelected = orderForNow && this.settingsService.isOrderForNowAvailableInServiceType(serviceTypes);
    } else {
      selectedDateTime = dateTime ?? null;
      isOrderForNowSelected = orderForNow ?? false;
    }

    const params: HttpQueryStringParams = {
      orderForNow: (!!isOrderForNowSelected).toString(),
    };

    const foodServiceType = this.settingsService.getFoodServiceType();

    // If the feature toggle is enabled and the user selected Food on
    // the home page, we should only show food items in the vendor details
    // https://bilbayt.atlassian.net/browse/CA-2497

    if (serviceTypes === foodServiceType) {
      params.serviceTypes = foodServiceType.toString();
    }

    if (areaId) {
      params.areaId = areaId.toString();
    }

    if (selectedDateTime) {
      params.dateTime = selectedDateTime;
    }

    // If we need the vendor details but in the context of a specific item (like when opening
    // an item from the cart) we should send the itemId to the API to optimize the response
    if (itemId) {
      params.itemId = itemId.toString();
    }

    return this.http.get<VendorDetails>(url, { params }).pipe(
      map((vendorDetails) => {
        this.initializeSoonestDeliveryTimeSlot(vendorDetails);
        this.initializeVendorLastOrderAcceptedTime(vendorDetails);
        return vendorDetails;
      }),
    );
  }

  public getAddressSearchFilters(): Address {
    return this.stateService.state?.searchFilters?.address || this.cartService.getAddress();
  }

  public getDatetimeSearchFilters(serviceType: number): { dateTime: string; orderForNow: boolean } {
    if (!serviceType) {
      return { dateTime: undefined, orderForNow: false };
    }

    const searchFilters = this.stateService.state?.searchFilters;
    const isServiceTypeBasedOnDateTime = this.settingsService.isServiceTypeBasedOnDateTime(serviceType);

    if (isServiceTypeBasedOnDateTime && searchFilters) {
      if (searchFilters.orderForNow) {
        return { dateTime: undefined, orderForNow: true };
      } else if (searchFilters.dateTime && !this.dateTimeService.isBeforeCurrentLocalTime(searchFilters.dateTime)) {
        return { dateTime: searchFilters.dateTime, orderForNow: false };
      }
    }

    return { dateTime: undefined, orderForNow: false };
  }

  public setAddressSearchFilters(address: Address): void {
    this.stateService.update({
      searchFilters: {
        address: address ?? undefined,
        dateTime: this.stateService.state?.searchFilters?.dateTime ?? undefined,
        orderForNow: this.stateService.state?.searchFilters?.orderForNow ?? undefined,
      },
    });
  }

  public setDateTimeSearchFilters(serviceType: number, { dateTime, orderForNow }: { dateTime: string; orderForNow: boolean }): void {
    // As of CA-2038 the idea is to reuse the date/time selected for other service
    // types so that the search filters are not empty when changing from one
    // service type to another
    // https://bilbayt.atlassian.net/browse/CA-2038

    // If the user selects NOW for food, the app will also select it for Catering/Rentals/... but it
    // won't send it to the API — that way the user would see the notice requirements and the app will
    // adjust the selected date/time automatically when adding something to the cart
    // https://bilbayt.atlassian.net/browse/CA-2150

    // We started getting the date/time from the URL recently, so there's an edge case where
    // we have a date/time already from prev sessions but the app is loaded with a service
    // type that is not based on date/time which would clear the values from the state. If that's
    // the case we should keep the existing date/time values — they will be returned or not
    // by the getSearchFilters() method based on the selected service type
    // https://bilbayt.atlassian.net/browse/WEB-6851

    if (!serviceType) {
      return;
    }

    if (this.settingsService.isServiceTypeBasedOnDateTime(serviceType)) {
      this.stateService.update({
        searchFilters: {
          address: this.stateService.state?.searchFilters?.address ?? undefined,
          dateTime,
          orderForNow,
        },
      });
    }
  }

  public clearSearchFilters(): void {
    this.stateService.update({ searchFilters: { address: undefined, dateTime: undefined, orderForNow: false } });
  }

  public setUpdateSearchResultsBasedOnDateTimeFromVendorId(vendorId: number): void {
    this.updateSearchResultsBasedOnDateTimeFromVendorId = vendorId;
  }

  public getUpdateSearchResultsBasedOnDateTimeFromVendorId(): number {
    return this.updateSearchResultsBasedOnDateTimeFromVendorId;
  }

  public clearUpdateSearchResultsBasedOnDateTimeFromVendorId(): void {
    this.updateSearchResultsBasedOnDateTimeFromVendorId = null;
  }

  public getMenuItemDetails(catererId: number, menuItemId: number): Observable<MenuItemDetails> {
    const url = this.config.routes.getMenuItemDetails.url
      .replace('#catererId#', catererId.toString())
      .replace('#menuItemId#', menuItemId.toString());

    return this.http.get<MenuItemDetails>(url);
  }

  public getMenuItemFromVendorDetails(vendorDetails: VendorDetails, menuItemId: number): VendorMenuItem {
    let menuItem: VendorMenuItem = null;

    top: for (const serviceTypeGroup of vendorDetails.menuGroups) {
      for (const menuGroup of serviceTypeGroup.groups) {
        for (const groupMenuItem of menuGroup.menuItems) {
          if (groupMenuItem.menuItemId === menuItemId) {
            menuItem = groupMenuItem;
            break top; // Break from both loops
          }
        }
      }
    }

    return menuItem;
  }

  public getReviews(catererId: number): Observable<Array<CatererReview>> {
    const url = this.config.routes.getCaterersReviews.url.replace('#catererId#', catererId.toString());

    return this.http.get<Array<CatererReview>>(url).pipe(
      map((reviews) => {
        if (reviews && reviews.length) {
          reviews.forEach((review) => {
            review.createdDateFormatted = this.dateTimeService.format({ dateTimeIso: review.createdDate, format: 'MMMM DD, YYYY' });
          });
        }
        return reviews;
      }),
    );
  }

  public getGroceryVendorDetails(vendorId: number, areaId?: number): Observable<GroceryVendorDetails> {
    const url = this.config.routes.getGroceryVendorDetails.url.replace('#vendorId#', vendorId.toString());
    const params: HttpQueryStringParams = {};

    if (areaId) {
      params.areaId = areaId.toString();
    }

    return this.http.get<GroceryVendorDetails>(url, { params }).pipe(
      map((vendorDetails) => {
        this.initializeSoonestDeliveryTimeSlot(vendorDetails);
        return vendorDetails;
      }),
    );
  }

  public getGroceryVendorSearchResults(
    serviceType: number,
    vendorId: number,
    searchTerm: string,
    categoryId?: number,
  ): Observable<GrocerySearchResults> {
    const url = this.config.routes.getVendorSearchResults.url.replace('#catererId#', vendorId.toString());

    const params = {
      search: searchTerm,
      serviceTypes: serviceType.toString(),
      ...(categoryId && { categoryId: categoryId.toString() }),
    };

    return this.http.get<GrocerySearchResults>(url, { params });
  }

  public getGroceryVendorCategories(vendorId: number): Observable<Array<GroceryCategory>> {
    const url = this.config.routes.getGroceryVendorCategories.url.replace('#vendorId#', vendorId.toString());

    return this.http.get<Array<GroceryCategory>>(url);
  }

  public getGroceryVendorCategoryItemsPreview(vendorId: number, categoryId: number = null): Observable<GroceryItemsWithCategories> {
    let url = this.config.routes.getGroceryVendorCategoryItemsPreview.url.replace('#vendorId#', vendorId.toString());

    if (categoryId) {
      url += `?categoryId=${categoryId}`;
    }

    return this.http.get<GroceryItemsWithCategories>(url);
  }

  public getGroceryCategoryMenuItems(vendorId: number, categoryId: number): Observable<Array<GroceryMenuItem>> {
    const url = this.config.routes.getGroceryCategoryMenuItemsDetails.url
      .replace('#vendorId#', vendorId.toString())
      .replace('#menuGroupId#', categoryId.toString());

    return this.http.get<Array<GroceryMenuItem>>(url);
  }

  public getGroceryMenuItemDetails(menuItemId: number): Observable<GroceryMenuItem> {
    const url = this.config.routes.getGroceryMenuItemsDetails.url.replace('#menuItemId#', menuItemId.toString());

    return this.http.get<GroceryMenuItem>(url);
  }

  public getAvailableTimeSlotsByDate(vendorId: number): Observable<Array<TimeSlotsByDate>> {
    const url = this.config.routes.getVendorAvailableTimeSlots.url.replace('#vendorId#', vendorId.toString());

    return this.http.get<Array<TimeSlotsByDate>>(url);
  }

  public getVendorAvailableDateTimes({
    areaId,
    vendorId,
    itemsIds,
  }: {
    areaId: number;
    vendorId: number;
    itemsIds: Array<number>;
  }): Observable<Array<VendorAvailableDateTime>> {
    const url = this.config.routes.postVendorAvailableTimes.url.replace('#vendorId#', vendorId.toString());

    const nonRepeatedItemsIds = itemsIds?.length ? Array.from(new Set(itemsIds)) : [];
    const payload: VendorAvailableDateTimesPayload = {
      areaId,
      menuItems: nonRepeatedItemsIds.map((itemId) => ({ menuItemId: itemId })),
    };

    return this.http.post<Array<VendorAvailableDateTime>>(url, payload);
  }

  public getVendorsAvailableDateTimesIntersection(vendorsDateTimes: Array<Array<VendorAvailableDateTime>>): Array<VendorAvailableDateTime> {
    if (vendorsDateTimes?.length === 1) {
      return vendorsDateTimes[0];
    }

    const results = vendorsDateTimes.reduce((prevDateTimes, nextDateTimes) => {
      const dateTimesIntersection: Array<VendorAvailableDateTime> = [];

      prevDateTimes.forEach((vendorDateTime) => {
        const nextDateTime = nextDateTimes.find((dateTime) => dateTime.date === vendorDateTime.date);

        if (nextDateTime) {
          const timesIntersection: Array<{ hour: number; minute: number }> = [];

          vendorDateTime.times.forEach((vendorTime) => {
            const existsInNextVendorTimes = nextDateTime.times.find(
              (time) => time.hour === vendorTime.hour && time.minute === vendorTime.minute,
            );

            if (existsInNextVendorTimes) {
              timesIntersection.push(vendorTime);
            }
          });

          if (timesIntersection?.length) {
            dateTimesIntersection.push({ date: vendorDateTime.date, times: timesIntersection });
          }
        }
      });

      return dateTimesIntersection;
    });

    return results;
  }

  public getLocallyCreatedMenuGroups(vendorDetails: VendorDetails): Array<VendorDetailsMenuGroup> {
    const menuGroups: Array<VendorDetailsMenuGroup> = [];

    if (!vendorDetails.menuGroups?.length) {
      return menuGroups;
    }

    const menuItems: Array<VendorMenuItem> = [];

    for (const menuGroup of vendorDetails.menuGroups) {
      for (const group of menuGroup.groups) {
        menuItems.push(...group.menuItems);
      }
    }

    if (vendorDetails.reOrderItems?.length) {
      const validReOrderItems = vendorDetails.reOrderItems
        .filter((orderItem) => menuItems.some((menuItem) => menuItem.menuItemId === orderItem.menuItemId))
        .map((item) => menuItems.find((m) => m.menuItemId === item.menuItemId));

      if (validReOrderItems.length) {
        menuGroups.push({
          serviceType: null,
          groups: [
            {
              menuGroupId: LocalMenuGroup.RecentlyOrderedItems,
              menuItems: validReOrderItems,
              name: this.translateService.instant('VENDOR_DETAILS_PAGE.RECENTLY_ORDERED') as string,
              serviceTypes: null,
            },
          ],
        });
      }
    }

    if (vendorDetails.trendingItems?.length) {
      const validTrendingItems = vendorDetails.trendingItems
        .filter((trendingItemId) => menuItems.some((menuItem) => menuItem.menuItemId === trendingItemId))
        .map((menuItemId) => menuItems.find((m) => m.menuItemId === menuItemId));

      if (validTrendingItems.length) {
        menuGroups.push({
          serviceType: null,
          groups: [
            {
              menuGroupId: LocalMenuGroup.TrendingItems,
              menuItems: validTrendingItems,
              name: this.translateService.instant('VENDOR_DETAILS_PAGE.TRENDING_ITEMS') as string,
              serviceTypes: null,
            },
          ],
        });
      }
    }

    return menuGroups;
  }

  public getVendorOpeningTime({
    deviceLocalTime,
    isOrderForNowSelected,
    serviceStartTime,
    serviceEndTime,
  }: {
    deviceLocalTime: string;
    isOrderForNowSelected: boolean;
    serviceStartTime: string;
    serviceEndTime: string;
  }): string {
    return deviceLocalTime && isOrderForNowSelected && serviceStartTime && serviceEndTime
      ? this.dateTimeService.isTimeOutsideTimePeriod(deviceLocalTime, serviceStartTime, serviceEndTime)
        ? this.dateTimeService.format({ dateTimeIso: serviceStartTime, format: 'h:mma' })
        : null
      : null;
  }

  public getVendorLastorderTime({
    deviceLocalTime,
    isOrderForNowSelected,
    lastOrderAcceptedTime,
  }: {
    deviceLocalTime: string;
    isOrderForNowSelected: boolean;
    lastOrderAcceptedTime: string;
  }): string {
    return deviceLocalTime && isOrderForNowSelected && lastOrderAcceptedTime
      ? this.dateTimeService.isNearEndTime(deviceLocalTime, lastOrderAcceptedTime, 30)
        ? this.dateTimeService.format({ dateTimeIso: lastOrderAcceptedTime, format: 'h:mma' })
        : null
      : null;
  }

  public isDeliveredByBilbayt(deliveryProviders: DeliveryProviders): boolean {
    if (deliveryProviders === null || deliveryProviders === undefined) {
      return false;
    }

    return (deliveryProviders & DeliveryProviders.Bilbayt) === DeliveryProviders.Bilbayt;
  }

  public signupVendor(vendorSignup: VendorSignup): Observable<void> {
    const url = this.config.routes.postVendorSignup.url;
    return this.http.post<void>(url, vendorSignup);
  }

  public registerBusiness(businessRegister: BusinessRegister): Observable<void> {
    const url = this.config.routes.postBusinessRegister.url;
    return this.http.post<void>(url, businessRegister);
  }

  public getLatestVendorChatEvent(): VendorChatEvent {
    return this.stateService.state.latestVendorChatEvent || undefined;
  }

  public updateLatestVendorChatEvent(chatEvent: VendorChatEvent): void {
    this.stateService.update({ latestVendorChatEvent: chatEvent });
  }

  public resetLatestVendorChatEvent(): void {
    this.stateService.update({ latestVendorChatEvent: undefined });
  }

  public getMenuItemAvailableTimes({
    areaId,
    dateTime,
    vendorId,
    menuItemId,
  }: {
    areaId: number;
    dateTime?: string;
    vendorId: number;
    menuItemId: number;
  }): Observable<ItemAvailableDateTime> {
    const url = this.config.routes.getMenuItemAvailableTimes.url
      .replace('#vendorId#', vendorId.toString())
      .replace('#menuItemId#', menuItemId.toString());

    const params: HttpQueryStringParams = {
      areaId: areaId.toString(),
    };

    if (dateTime) {
      params.dateTime = dateTime;
    }

    return this.http.get<ItemAvailableDateTime>(url, { params });
  }

  public filterBasedOnTags<T extends ObjectWithTags>(objects: Array<T>, selectedTags: Array<Tag>): Array<T> {
    if (!objects?.length) {
      return [];
    }

    let selectedObjects = objects;

    if (!selectedTags?.length) {
      return selectedObjects;
    }

    const andTagsIds = selectedTags.filter((tag) => tag.operator === LogicalOperator.And).map((tag) => tag.tagId);
    const orTagsIds = selectedTags.filter((tag) => tag.operator === LogicalOperator.Or || !tag.operator).map((tag) => tag.tagId);

    selectedObjects = andTagsIds?.length
      ? selectedObjects.filter((obj) => andTagsIds.every((tagId) => obj.tags.indexOf(tagId) >= 0))
      : selectedObjects;

    selectedObjects = orTagsIds?.length
      ? selectedObjects.filter((obj) => orTagsIds.some((tagId) => obj.tags.indexOf(tagId) >= 0))
      : selectedObjects;

    return selectedObjects;
  }

  public filterVendorMenuGroupsBasedOnSelectedTags(
    vendorMenuGroups: Array<VendorDetailsMenuGroup>,
    selectedTags: Array<Tag>,
  ): Array<VendorDetailsMenuGroup> {
    if (!vendorMenuGroups) {
      return [];
    }

    const filteredMenuGroups: Array<VendorDetailsMenuGroup> = [];

    if (!selectedTags) {
      return vendorMenuGroups;
    }

    for (const serviceTypeGroup of vendorMenuGroups) {
      for (const menuGroup of serviceTypeGroup.groups) {
        const filteredItems = this.filterBasedOnTags(menuGroup.menuItems, selectedTags);

        if (filteredItems?.length) {
          if (!filteredMenuGroups.find((group) => group.serviceType === serviceTypeGroup.serviceType)) {
            filteredMenuGroups.push({
              serviceType: serviceTypeGroup.serviceType,
              groups: [],
            });
          }

          const selectedServiceTypeGroup = filteredMenuGroups.find((group) => group.serviceType === serviceTypeGroup.serviceType);

          selectedServiceTypeGroup.groups.push({ ...menuGroup, menuItems: filteredItems });
        }
      }
    }

    return filteredMenuGroups;
  }

  public stringifyTags(tags: Array<Tag>, { andText, orText }: { andText: string; orText: string }): string {
    let result = '';

    if (!tags || !tags.length) {
      return result;
    }

    const selectedTags = tags.filter((tag) => !!tag.selected);
    const andTags = selectedTags.filter((tag) => tag.operator === LogicalOperator.And).map((tag) => tag.name);
    const orTags = selectedTags.filter((tag) => tag.operator === LogicalOperator.Or || !tag.operator).map((tag) => tag.name);

    if (andTags?.length) {
      result += Helpers.joinStringArray(andTags, { joinSeparator: andText });

      if (orTags?.length) {
        result += andText;
      }
    }

    if (orTags?.length) {
      result += Helpers.joinStringArray(orTags, { joinSeparator: orText });
    }

    return result;
  }

  private loadVendorsSearchResults({ serviceType, area, dateTime, orderForNow }: SearchResultsQueryParams): Observable<void> {
    // If the user selects NOW for food, the app will also select it for Catering/Rentals/... but it
    // won't send it to the API — that way the user would see the notice requirements and the app will
    // adjust the selected date/time automatically when adding something to the cart
    // https://bilbayt.atlassian.net/browse/CA-2150

    const selectedDateTime = dateTime && this.settingsService.isServiceTypeBasedOnDateTime(serviceType) ? dateTime : null;
    const isOrderForNowSelected = orderForNow && this.settingsService.isOrderForNowAvailableInServiceType(serviceType);

    const params: HttpQueryStringParams = {
      serviceTypes: serviceType.toString(),
      orderForNow: (!!isOrderForNowSelected).toString(),
    };

    if (area?.areaId) {
      params.areaId = area.areaId.toString();
    }

    if (selectedDateTime) {
      params.dateTime = selectedDateTime;
    }

    return this.http
      .get<VendorsHomePageSearchResults>(this.config.routes.getVendorsHomePageSearchResults.url, { params, observe: 'response' })
      .pipe(
        tap((response) => {
          const metadata = this.getSearchMetadataFromResponse(response);
          this.cartService.updateCurrentOrderSearchMetadata(metadata);
        }),
        map((response) => response.body),
        map((searchResults) => {
          if (searchResults.vendors?.length) {
            searchResults.vendors.forEach((vendorsResults) => {
              vendorsResults.vendors.forEach((vendor) => {
                this.initializeVendorLastOrderAcceptedTime(vendor);

                // If we get the notice period of the vendor and the user selected a date/time
                // we need to check if the selected date/time is valid based on the notice period
                // and show a warning if it's not because the API will ignore the notice period
                // when checking the availability in the search v2
                // https://bilbayt.atlassian.net/browse/CA-2126
                vendor.noticePeriodWarningMessage = this.noticePeriodService.getNoticePeriodWarningMessage({
                  noticePeriod: vendor.noticePeriod,
                  dateTime,
                });
              });

              vendorsResults.tags.forEach((tag) => {
                tag.resultsCount = tag.vendorCount;
              });

              this.vendorsSearchResults.set(vendorsResults.serviceType, vendorsResults.vendors);
              this.bannersSearchResults.set(vendorsResults.serviceType, vendorsResults.banners);
              this.tagsSearchResults.set(vendorsResults.serviceType, vendorsResults.tags);

              if (vendorsResults.popularTags?.length) {
                this.popularTagsSearchResults.set(
                  vendorsResults.serviceType,
                  vendorsResults.popularTags.map((tagId) => vendorsResults.tags.find((tag) => tag.tagId === tagId)),
                );
              }
            });
          }

          searchResults.collections.forEach((collectionsResults) =>
            this.collectionsSearchResults.set(collectionsResults.serviceType, collectionsResults.collections),
          );

          searchResults.infoMessages.forEach((infoMessagesResults) =>
            this.infoMessagesSearchResults.set(infoMessagesResults.serviceType, infoMessagesResults.infoMessages),
          );

          this.recommendedSorterType = searchResults.sorterMetadata.sorterType;
        }),
      );
  }

  private loadVendorsItemsSearchResults({ serviceType, area, dateTime, orderForNow }: SearchResultsQueryParams): Observable<void> {
    // If the user selects NOW for food, the app will also select it for Catering/Rentals/... but it
    // won't send it to the API — that way the user would see the notice requirements and the app will
    // adjust the selected date/time automatically when adding something to the cart
    // https://bilbayt.atlassian.net/browse/CA-2150

    const selectedDateTime = dateTime && this.settingsService.isServiceTypeBasedOnDateTime(serviceType) ? dateTime : null;
    const isOrderForNowSelected = orderForNow && this.settingsService.isOrderForNowAvailableInServiceType(serviceType);

    const params: HttpQueryStringParams = {
      serviceType: serviceType.toString(),
      orderForNow: (!!isOrderForNowSelected).toString(),
    };

    if (area?.areaId) {
      params.areaId = area.areaId.toString();
    }

    if (selectedDateTime) {
      params.dateTime = selectedDateTime;
    }

    return this.http
      .get<VendorsItemsHomePageSearchResults>(this.config.routes.getVendorsItemsHomePageSearchResults.url, { params, observe: 'response' })
      .pipe(
        tap((response) => {
          const metadata = this.getSearchMetadataFromResponse(response);
          this.cartService.updateCurrentOrderSearchMetadata(metadata);
        }),
        map((response) => response.body),
        map((searchResults) => {
          if (searchResults.menuItems?.length) {
            searchResults.menuItems.forEach((vendorItem) => {
              // If we get the notice period of the vendor and the user selected a date/time
              // we need to check if the selected date/time is valid based on the notice period
              // and show a warning if it's not because the API will ignore the notice period
              // when checking the availability in the search v2
              // https://bilbayt.atlassian.net/browse/CA-2126
              vendorItem.noticePeriodWarningMessage = this.noticePeriodService.getNoticePeriodWarningMessage({
                noticePeriod: vendorItem.noticePeriod,
                dateTime,
              });

              searchResults.tags.forEach((tag) => {
                tag.resultsCount = tag.vendorCount;
              });

              this.vendorsItemsSearchResults.set(serviceType, searchResults.menuItems);
            });
          }

          this.bannersSearchResults.set(serviceType, searchResults.banners);
          this.collectionsSearchResults.set(serviceType, searchResults.collections);
          this.infoMessagesSearchResults.set(serviceType, searchResults.infoMessages);
          this.tagsSearchResults.set(serviceType, searchResults.tags);
          this.popularTagsSearchResults.set(
            serviceType,
            searchResults.popularTags
              ? searchResults.popularTags.map((tagId) => searchResults.tags.find((tag) => tag.tagId === tagId))
              : [],
          );

          this.recommendedSorterType = searchResults.sorterMetadata.sorterType;
        }),
      );
  }

  private getSearchMetadataFromResponse(
    response: HttpResponse<VendorsHomePageSearchResults | VendorsItemsHomePageSearchResults>,
  ): SearchMetadata {
    let metadata: SearchMetadata = {
      searchId: null,
      sorterTypeText: null,
    };

    try {
      metadata = {
        searchId: response.headers.get('X-Search-Id'),
        sorterTypeText: response.body.sorterMetadata.sorterTypeText,
      };
    } catch (error) {}

    return metadata;
  }

  private initializeSoonestDeliveryTimeSlot(vendor: VendorDetails | GroceryVendorDetails): void {
    if (!vendor || !vendor.availableTimeSlots?.length) {
      return;
    }

    vendor.soonestAvailableDateTimeSlotIso = this.dateTimeService.getSoonestAvailableTimeSlotIsoFromAvailableTimeSlots(
      vendor.availableTimeSlots,
    );
  }

  private initializeVendorLastOrderAcceptedTime(vendor: Vendor | VendorDetails): void {
    try {
      if (!vendor.serviceStartTime || !vendor.serviceEndTime) {
        return;
      }

      // The start and end time have 1900-01-01 as the date so we need to take
      // the part of the ISO string related to the time and use it to create
      // a new date/time that uses today as the date

      vendor.serviceStartTime = this.dateTimeService.convertToDateTimeIsoString(
        this.dateTimeService.parseIso(vendor.serviceStartTime).format('HH:mm'),
        'HH:mm',
      );

      vendor.serviceEndTime = this.dateTimeService.convertToDateTimeIsoString(
        this.dateTimeService.parseIso(vendor.serviceEndTime).format('HH:mm'),
        'HH:mm',
      );

      if (vendor.serviceEndTime && vendor.noticePeriod !== null && vendor.noticePeriod !== undefined) {
        vendor.lastOrderAcceptedTime = this.dateTimeService.subtractTime(vendor.serviceEndTime, vendor.noticePeriod, 'hour');
      }
    } catch (error) {
      console.warn('Error when trying to initialize the last order accepted time', error);
    }
  }
}
