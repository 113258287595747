import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { BrowserCountryPickerModalPage } from './browser-country-picker-modal.page';

@NgModule({
  declarations: [BrowserCountryPickerModalPage],
  imports: [CommonModule, IonicModule, TranslateModule.forChild()],
  exports: [BrowserCountryPickerModalPage],
})
export class BrowserCountryPickerModalPageModule {}
