import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { VendorReviewsModalPage } from './vendor-reviews-modal.page';

import { RatingComponentModule } from '../../shared/components/rating/rating.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule.forChild(), RatingComponentModule],
  declarations: [VendorReviewsModalPage],
})
export class VendorReviewsModalPageModule {}
