import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { DATE_FORMAT_REGEX, ISO_FORMAT, LTR_TO_RTL_FORMAT, TIME_FORMAT_REGEX } from '../models/date-time-format.model';
import { LanguageCode } from '../models/language.model';
import { TimeSlot } from '../models/time-slot.model';
import { TimeSlotsByDate } from '../models/time-slots-by-date.model';

import { isCypressTestEnv } from './environment.service';
import { SettingsService } from './settings.service';
import { WindowService } from './ssr/window.service';

import dayjs, { Dayjs } from 'dayjs';
import locale_ar from 'dayjs/locale/ar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import localeData from 'dayjs/plugin/localeData';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(minMax);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);

const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
const CUSTOMIZED_LOCALE_AR = { ...locale_ar, weekStart: 0, meridiem: (hour) => (hour > 12 ? 'م' : 'ص') } as ILocale;

interface DateTimeFormatParams {
  dateTimeIso: string;
  format: string;
  isUtc?: boolean;
  useRelativeDate?: boolean;
}

interface DateTimeEqualityParams {
  dateTimeA: string;
  dateTimeB: string;
  compareDatesOnly?: boolean;
  compareTimesOnly?: boolean;
}

export interface CalendarMonth {
  year: number;
  month: number;
  monthName: string;
  weekDayNames: Array<string>;
  days: Array<{
    value: number;
    valueIsoString: string;
    isToday: boolean;
    isBeforeToday: boolean;
    isAfterToday: boolean;
  }>;
  daysBeforeStartDay: Array<void>;
  isPrevAvailable: boolean;
  isNextAvailable: boolean;
}

@Injectable({ providedIn: 'root' })
export class DateTimeService {
  private calendarDays: Array<string> = [];

  constructor(private windowService: WindowService, private translateService: TranslateService, private settingsService: SettingsService) {}

  public get now(): dayjs.Dayjs {
    if (isCypressTestEnv() && this.windowService.isWindowDefined) {
      const dateTime = this.windowService.window.localStorage.getItem('cypressTestEnvDateTimeIsoString');

      if (dateTime) {
        return dayjs(dateTime);
      }
    }

    return dayjs();
  }

  public get utcNow(): dayjs.Dayjs {
    if (isCypressTestEnv() && this.windowService.isWindowDefined) {
      const dateTime = this.windowService.window.localStorage.getItem('cypressTestEnvDateTimeIsoString');

      if (dateTime) {
        return dayjs.utc(dateTime);
      }
    }

    return dayjs.utc();
  }

  public areEqual({ dateTimeA, dateTimeB, compareDatesOnly, compareTimesOnly }: DateTimeEqualityParams): boolean {
    if (this.isNotDefined(dateTimeA) || this.isNotDefined(dateTimeB)) {
      console.warn('[DateTimeService] areEqual() was called with empty parameters');
      return null;
    }

    const dayjsA = this.parseIso(dateTimeA);
    const dayjsB = this.parseIso(dateTimeB);

    if (compareDatesOnly) {
      return dayjsA.isSame(dayjsB, 'date');
    } else if (compareTimesOnly) {
      return dayjsA.format('HHmm') === dayjsB.format('HHmm');
    } else {
      return dayjsA.isSame(dayjsB, 'minute');
    }
  }

  public format({ dateTimeIso, format, isUtc = false, useRelativeDate = false }: DateTimeFormatParams): string {
    if (this.isNotDefined(dateTimeIso) || this.isNotDefined(format)) {
      console.warn('[DateTimeService] format() was called with empty parameters');
      return null;
    }

    const dateTime = isUtc ? dayjs.utc(dateTimeIso).local() : this.parseIso(dateTimeIso);

    const language = this.settingsService.getLanguage();

    if (!language) {
      console.warn(`[DateTimeService] Tried to format date / time to ${format} before the language was initialized`);
      return dateTime.format(DEFAULT_DATE_FORMAT);
    }

    const displayFormat = language.rtl && LTR_TO_RTL_FORMAT[format] ? LTR_TO_RTL_FORMAT[format] : format;
    const localeConfig = language.value === 'ar' ? CUSTOMIZED_LOCALE_AR : null;

    if (useRelativeDate && DATE_FORMAT_REGEX.test(format) && (dateTime.isToday() || dateTime.isTomorrow())) {
      const relativeDate = (
        dateTime.isToday() ? this.translateService.instant('TODAY') : this.translateService.instant('TOMORROW')
      ) as string;

      const timeFormat = TIME_FORMAT_REGEX.exec(displayFormat);

      return timeFormat?.length ? `${relativeDate}, ${dateTime.locale(language.value, localeConfig).format(timeFormat[0])}` : relativeDate;
    }

    return dateTime.locale(language.value, localeConfig).format(displayFormat);
  }

  public isToday(dateTimeIso: string): boolean {
    if (this.isNotDefined(dateTimeIso)) {
      console.warn('[DateTimeService] isToday() was called with empty parameters');
      return null;
    }

    return this.parseIso(dateTimeIso).isToday();
  }

  public isTomorrow(dateTimeIso: string): boolean {
    if (this.isNotDefined(dateTimeIso)) {
      console.warn('[DateTimeService] isTomorrow() was called with empty parameters');
      return null;
    }

    return this.parseIso(dateTimeIso).isTomorrow();
  }

  public getLocalDateTimeIsoString(): string {
    return this.now.format(ISO_FORMAT);
  }

  public getUtcDateTimeIsoString(): string {
    return this.utcNow.format(ISO_FORMAT);
  }

  public getElapsedTimeFromCurrentUtcTime(timeStamp: string, unit: dayjs.ManipulateType): number {
    if (this.isNotDefined(timeStamp) || this.isNotDefined(unit)) {
      console.warn('[DateTimeService] getElapsedTimeFromCurrentUtcTime() was called with empty parameters');
      return null;
    }

    return this.utcNow.diff(dayjs.utc(timeStamp), unit);
  }

  public subtractTime(dateTimeIso: string, amountToSubtract: number, unit: dayjs.ManipulateType): string {
    if (this.isNotDefined(dateTimeIso) || this.isNotDefined(amountToSubtract) || this.isNotDefined(unit)) {
      console.warn('[DateTimeService] subtractTime() was called with empty parameters');
      return null;
    }

    const newTime = this.parseIso(dateTimeIso).subtract(amountToSubtract, unit);
    return newTime.format(ISO_FORMAT);
  }

  public isBeforeCurrentLocalTime(dateTime: string): boolean {
    if (this.isNotDefined(dateTime)) {
      console.warn('[DateTimeService] isBeforeCurrentLocalTime() was called with empty parameters');
      return null;
    }

    return this.parseIso(dateTime).isBefore(this.now);
  }

  public isTimeSameOrAfterCurrentLocalTime(hour: number, minute: number, cutoff: number = 0, compareToNextDay = false): boolean {
    if (this.isNotDefined(hour) || this.isNotDefined(minute)) {
      console.warn('[DateTimeService] isTimeSameOrAfterCurrentLocalTime() was called with empty parameters');
      return null;
    }

    return this.now
      .hour(hour)
      .minute(minute)
      .second(0)
      .add(compareToNextDay ? 1 : 0, 'day')
      .subtract(cutoff, 'minutes')
      .isSameOrAfter(this.now);
  }

  public isTimeOutsideTimePeriod(targetTime: string, startTime: string, endTime: string): boolean {
    if (this.isNotDefined(targetTime) || this.isNotDefined(startTime) || this.isNotDefined(endTime)) {
      console.warn('[DateTimeService] isTimeOutsideTimePeriod() was called with empty parameters');
      return true;
    }

    const targetTimeDateTime = this.parseIso(targetTime);
    const startTimeDateTime = this.parseIso(startTime);
    let endTimeDateTime = this.parseIso(endTime);

    // Avoid issues if for example the start time is 02:00 PM and the end time is 02:00 AM
    if (endTimeDateTime.isSameOrBefore(startTimeDateTime)) {
      endTimeDateTime = endTimeDateTime.add(1, 'day');
    }

    return targetTimeDateTime.isBefore(startTimeDateTime) || targetTimeDateTime.isAfter(endTimeDateTime);
  }

  public isNearEndTime(targetIso: string, endIso: string, timeLimitInMinutes: number): boolean {
    if (this.isNotDefined(targetIso) || this.isNotDefined(endIso) || this.isNotDefined(timeLimitInMinutes)) {
      console.warn('[DateTimeService] isNearEndTime() was called with empty parameters');
      return false;
    }

    const targetDateTime = this.parseIso(targetIso);

    let endDateTime = this.parseIso(endIso);
    let startDateTime = this.parseIso(endIso).subtract(timeLimitInMinutes, 'minute');

    // Avoid issues if for example the target time is 02:00 PM and the end time is 02:00 AM
    if (endDateTime.isSameOrBefore(targetDateTime)) {
      endDateTime = endDateTime.add(1, 'day');
      startDateTime = startDateTime.add(1, 'day');
    }

    return targetDateTime.isBetween(startDateTime, endDateTime, null, '[)');
  }

  public isLocalTimeWithBufferSameOrAfter({ dateTime, bufferMinutes }: { dateTime: string; bufferMinutes: number }): boolean {
    if (this.isNotDefined(dateTime) || this.isNotDefined(bufferMinutes)) {
      console.warn('[DateTimeService] isLocalTimeWithBufferSameOrAfter() was called with empty parameters');
      return null;
    }

    return this.now.add(bufferMinutes, 'm').isSameOrAfter(this.parseIso(dateTime));
  }

  public isLocalTimeWithBufferSameOrBefore({ dateTime, bufferMinutes }: { dateTime: string; bufferMinutes: number }): boolean {
    if (this.isNotDefined(dateTime) || this.isNotDefined(bufferMinutes)) {
      console.warn('[DateTimeService] isLocalTimeWithBufferSameOrBefore() was called with empty parameters');
      return null;
    }

    return this.now.add(bufferMinutes, 'm').isSameOrBefore(this.parseIso(dateTime));
  }

  public isBefore({ dateTime1, dateTime2 }: { dateTime1: string; dateTime2: string }): boolean {
    if (this.isNotDefined(dateTime1) || this.isNotDefined(dateTime2)) {
      console.warn('[DateTimeService] isBefore() was called with empty parameters');
      return null;
    }

    return this.parseIso(dateTime1).isBefore(this.parseIso(dateTime2));
  }

  public getEarliestDateTime(dateTimes: Array<string>): string | null {
    if (!dateTimes?.length) {
      return null;
    }

    if (dateTimes.length === 1) {
      return dateTimes[0];
    }

    let minDateTime = this.parseIso(dateTimes[0]);

    dateTimes.forEach((dateTime, index) => {
      const currentDateTime = this.parseIso(dateTime);

      if (index !== 0 && currentDateTime.isBefore(minDateTime)) {
        minDateTime = currentDateTime;
      }
    });

    return minDateTime.format(ISO_FORMAT);
  }

  public hasChangedDateOrTime(dateTimeA: string, dateTimeB: string): boolean {
    if ((!dateTimeA && dateTimeB) || (dateTimeA && !dateTimeB)) {
      return true;
    }

    return dateTimeA && dateTimeB && !this.areEqual({ dateTimeA, dateTimeB });
  }

  public isValid(dateTimeStr: string, format?: string): boolean {
    if (!dateTimeStr) {
      return false;
    }

    // Dayjs may return valid for some date/times that have a valid format
    // even if they aren't valid addresses (like 2023-13-45) so we must
    // set the 'strict' param to true
    // https://github.com/iamkun/dayjs/issues/320

    return dayjs(dateTimeStr, format ?? ISO_FORMAT, true).isValid();
  }

  public convertToDateTimeIsoString(dateTimeStr: string, format?: string): string {
    if (this.isNotDefined(dateTimeStr)) {
      console.warn('[DateTimeService] convertToDateTimeIsoString() was called with empty parameters');
      return null;
    }

    return dayjs(dateTimeStr, format).format(ISO_FORMAT);
  }

  public getSoonestAvailableTimeSlotIsoFromAvailableTimeSlots(availableTimeSlots: Array<TimeSlotsByDate>): string {
    let soonestAvailableDateTimeSlotIso: string = null;

    if (availableTimeSlots?.length && availableTimeSlots[0]?.timeSlots?.length) {
      soonestAvailableDateTimeSlotIso = this.getDateTimeIsoFromTimeSlot(availableTimeSlots[0].timeSlots[0]);
    }

    return soonestAvailableDateTimeSlotIso;
  }

  public getDateTimeIsoFromTimeSlot(timeSlot: TimeSlot): string {
    let dateTimeIso: string = null;

    if (timeSlot?.date) {
      dateTimeIso = this.parseIso(timeSlot.date).hour(timeSlot.endTime.hour).minute(timeSlot.endTime.minute).format(ISO_FORMAT);
    }

    return dateTimeIso;
  }

  public sort(dateTimeIsoA: string, dateTimeIsoB: string): number {
    return this.parseIso(dateTimeIsoA).isAfter(this.parseIso(dateTimeIsoB)) ? 1 : -1;
  }

  public parseIso(dateTimeIso: string): Dayjs {
    return dayjs(dateTimeIso, ISO_FORMAT);
  }

  public getCalendarDays(): Array<string> {
    const today = this.now.startOf('day');

    if (this.calendarDays.length && this.calendarDays[0] === today.format(ISO_FORMAT)) {
      return this.calendarDays;
    }

    const totalDays = today.daysInMonth() - today.date() + 1 + today.add(1, 'month').daysInMonth() + today.add(2, 'month').daysInMonth();

    for (let i = 0; i < totalDays; i++) {
      this.calendarDays.push(today.add(i, 'day').format(ISO_FORMAT));
    }

    return this.calendarDays;
  }

  public today(): Date {
    return dayjs().startOf('day').toDate();
  }

  public getCalendarForMonth(dateTimeIso?: string, addMonths: number = 0): CalendarMonth {
    const startDate = (dateTimeIso ? dayjs(dateTimeIso, ISO_FORMAT) : this.now).startOf('month').add(addMonths, 'months');

    const days: CalendarMonth['days'] = Array.from({ length: dayjs(startDate).daysInMonth() });

    days.forEach((_, index) => {
      const value = dayjs(startDate).date(index + 1);
      const valueIso = value.format(ISO_FORMAT);

      const today = this.isToday(valueIso);
      const beforeToday = this.isBefore({ dateTime1: valueIso, dateTime2: this.now.startOf('day').format(ISO_FORMAT) });

      days[index] = {
        value: +value.format('D'),
        valueIsoString: value.format(ISO_FORMAT),
        isToday: today,
        isBeforeToday: beforeToday,
        isAfterToday: !today && !beforeToday,
      };
    });

    return {
      days,
      daysBeforeStartDay: Array.from({ length: this.getDayOfWeek(days[0].valueIsoString) }),
      weekDayNames: this.getWeekdaysNames({ format: 'short' }),
      month: startDate.month() + 1,
      monthName: this.getMonthName(startDate.month() + 1),
      year: startDate.year(),
      isNextAvailable: startDate.diff(dayjs().startOf('month'), 'months') <= 1,
      isPrevAvailable: startDate.diff(dayjs().startOf('month'), 'months') > 0,
    };
  }

  public getMonthName(month: number): string {
    const { languageCode, locale } = this.getCurrentLanguageLocaleConfig();

    return dayjs()
      .locale(languageCode, locale)
      .startOf('day')
      .month(month - 1)
      .format('MMMM');
  }

  public getWeekdaysNames({ format }: { format: 'short' | 'long' }): Array<string> {
    const { languageCode, locale } = this.getCurrentLanguageLocaleConfig();

    return format === 'short'
      ? dayjs().locale(languageCode, locale).localeData().weekdaysShort()
      : dayjs().locale(languageCode, locale).localeData().weekdays();
  }

  public getMonthFromDateTimeIso(dateTimeIso: string): number {
    return dayjs(dateTimeIso).startOf('day').month() + 1;
  }

  public getDayOfWeek(dateTimeIso: string): number {
    return dayjs(dateTimeIso).day();
  }

  public toMinutesOrHours(timeInMinutes: number): string {
    const minuteTranslationKey = this.settingsService.getLanguage().rtl ? 'MINUTE' : 'MINUTES';

    if (timeInMinutes < 60) {
      return `${timeInMinutes} ${this.translateService.instant(minuteTranslationKey)}`;
    }

    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    const hoursResultStr = `${hours} ${hours > 1 ? this.translateService.instant('HOURS') : this.translateService.instant('HOUR')}`;
    const minutesResultStr = minutes ? `${minutes} ${this.translateService.instant('MINUTES')}` : '';

    return minutesResultStr ? `${hoursResultStr} ${minutesResultStr}` : hoursResultStr;
  }

  private isNotDefined(object: number | string): boolean {
    return object === undefined || object === null;
  }

  private getCurrentLanguageLocaleConfig(): { languageCode: LanguageCode; locale: ILocale } {
    const language = this.settingsService.getLanguage();
    return {
      languageCode: language.value,
      locale: language.value === 'ar' ? CUSTOMIZED_LOCALE_AR : null,
    };
  }

  public getTimeUntilDeadline(deadlineTimeIso: string): { days: number; hours: number; minutes: number; seconds: number } {
    if (this.isNotDefined(deadlineTimeIso)) {
      console.warn('[DateTimeService] getTimeUntilDeadline() was called with empty parameters');
      return null;
    }

    const diffInMilliseconds = dayjs(deadlineTimeIso).diff();
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const remainingHours = diffInHours % 24;
    const remainingMinutes = diffInMinutes % 60;
    const remainingSeconds = diffInSeconds % 60;

    return {
      days: diffInDays > 0 ? diffInDays : 0,
      hours: remainingHours > 0 ? remainingHours : 0,
      minutes: remainingMinutes > 0 ? remainingMinutes : 0,
      seconds: remainingSeconds > 0 ? remainingSeconds : 0,
    };
  }
}
