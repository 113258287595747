import { transition, trigger } from '@angular/animations';

import { AnimationTransition, applyFadeInAnimation, applyFadeInTranslateAnimation } from '../../core/animations';

export const ItemAvailabilityDateTimePickerModalPageAnimations = [
  trigger('animatedCalendarMessage', [transition(AnimationTransition.VoidToAny, applyFadeInAnimation({ duration: 440 }))]),
  trigger('animatedCalendarContent', [transition(AnimationTransition.VoidToAny, applyFadeInAnimation({ duration: 300 }))]),
  trigger('animatedCalendarFooter', [
    transition(AnimationTransition.VoidToAny, applyFadeInTranslateAnimation({ duration: 240, delay: 40, distance: '-12px' })),
  ]),
];
