import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { AreaDateTimePickerComponent } from './area-date-time-picker.component';

import { PickerComponentModule } from '../picker/picker.module';

@NgModule({
  declarations: [AreaDateTimePickerComponent],
  imports: [CommonModule, IonicModule, TranslateModule.forChild(), PickerComponentModule],
  exports: [AreaDateTimePickerComponent],
})
export class AreaDateTimePickerComponentModule {}
