import { group, query, transition, trigger } from '@angular/animations';

import {
  AnimationDetails,
  AnimationTransition,
  applyFadeInScaleAnimation,
  applyFadeInTranslateAnimation,
  applyFadeOutScaleAnimation,
  applyFadeOutTranslateAnimation,
  applyScaleInAnimation,
  applyScaleOutAnimation,
  ScalableAnimationDetails,
} from '../../../core/animations';

const toastEnterAnimationDetails: AnimationDetails & ScalableAnimationDetails = { duration: 350, delay: 300, axis: 'Y' };
const toastLeaveAnimationDetails: AnimationDetails & ScalableAnimationDetails = { duration: 350, delay: 0, axis: 'Y' };

export const TOAST_ENTER = toastEnterAnimationDetails.duration + toastEnterAnimationDetails.delay;
export const TOAST_LEAVE = toastLeaveAnimationDetails.duration + toastLeaveAnimationDetails.delay;

export const ToastComponentAnimations = [
  trigger('animatedFullWidthToast', [
    transition(AnimationTransition.VoidToAny, [
      group([
        query(':self', applyScaleInAnimation(toastEnterAnimationDetails)),
        query('.animated-message, .animated-action', applyFadeInScaleAnimation(toastEnterAnimationDetails), { optional: true }),
      ]),
    ]),
    transition(AnimationTransition.AnyToVoid, [
      group([
        query(':self', applyScaleOutAnimation(toastLeaveAnimationDetails)),
        query('.animated-message, .animated-action', applyFadeOutScaleAnimation(toastLeaveAnimationDetails), { optional: true }),
      ]),
    ]),
  ]),
  trigger('animatedToast', [
    transition(AnimationTransition.VoidToAny, applyFadeInTranslateAnimation({ duration: 350, distance: '50px' })),
    transition(AnimationTransition.AnyToVoid, applyFadeOutTranslateAnimation({ duration: 350, distance: '50px' })),
  ]),
];
