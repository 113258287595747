import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { EditableRatingComponent } from './editable-rating.component';

@NgModule({
  declarations: [EditableRatingComponent],
  imports: [CommonModule, IonicModule],
  exports: [EditableRatingComponent],
})
export class EditableRatingComponentModule {}
