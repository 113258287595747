<div tappable (click)="onToggleContent()" class="header">
  <h3 class="header-title">{{ "SAVED_ADDRESSES" | translate }}</h3>
  <ion-icon
    src="/assets/custom-icons/b-angle-right.svg"
    class="header-icon"
    [class.rotate]="currentAnimationState === AnimationState.Expanded"
  ></ion-icon>
</div>

<div [@animatedContent]="currentAnimationState" style="overflow: hidden">
  <div
    tappable
    (click)="onSelect(savedAddress)"
    class="address"
    [class.selected]="selected?.savedAddressId === savedAddress.savedAddressId"
    *ngFor="let savedAddress of savedAddressesToShow"
  >
    <ion-icon src="/assets/custom-icons/b-check-circle-solid.svg" class="address-icon"></ion-icon>

    <p class="address-details">
      <app-address-details [address]="savedAddress" [plainText]="true" data-cy="saved-address"></app-address-details>
    </p>
  </div>
</div>
