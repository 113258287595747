<div class="modal-container">
  <div class="modal-content">
    <div class="details-section">
      <div *ngIf="title" class="title" data-cy="alert-title">{{ title }}</div>
      <div *ngIf="message" class="message" data-cy="alert-message" [innerHTML]="message">{{ message }}</div>
    </div>

    <div class="footer-section" [class.inline-buttons]="inlineButtons">
      <ion-button
        *ngFor="let button of buttons"
        expand="block"
        class="alert-button custom-buttom"
        data-cy="alert-button"
        [color]="!button.isDestructive && !button.isPrimary ? 'grey' : null"
        [class.danger]="button.isDestructive"
        [class.success]="button.isPrimary"
        (click)="onActionClick(button)"
      >
        {{ button.text }}
      </ion-button>
    </div>
  </div>
</div>
