import { ErrorHandler, NgZone } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { LoggerService } from './services/logger.service';
import { OverlayService } from './services/overlay.service';

export class CustomErrorHandler implements ErrorHandler {
  constructor(
    private ngZone: NgZone,
    private loggerService: LoggerService,
    private overlayService: OverlayService,
    private translateService: TranslateService,
  ) {}

  public handleError(error: unknown): void {
    if (this.loggerService) {
      let errorMessage = 'unhandled error';

      if (error instanceof Error && error.message) {
        errorMessage = error.message as string;
      }

      console.error(error);
      this.loggerService.error({ component: 'CustomErrorHandler', message: errorMessage, error });

      this.ngZone.run(() => {
        this.overlayService.showToast({
          type: 'error',
          showCloseButton: true,
          message: this.translateService.instant('ERROR_MESSAGE.DEFAULT_MESSAGE') as string,
        });
      });
    }
  }
}

export function errorHandlerFactory(
  ngZone: NgZone,
  loggerService: LoggerService,
  overlayService: OverlayService,
  translateService: TranslateService,
): ErrorHandler {
  return new CustomErrorHandler(ngZone, loggerService, overlayService, translateService);
}

export const errorHandlerProvider = {
  provide: ErrorHandler,
  useFactory: errorHandlerFactory,
  deps: [NgZone, LoggerService, OverlayService, TranslateService],
};
