// https://github.com/ionic-team/ionic-framework/blob/68afc49e9ed27acffb0b765b7be6b03e8574850d/core/src/components/picker/picker.tsx

import { Component, EventEmitter, Input, Output, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';

import { PickerColumn } from '@ionic/core';

import { PickerColumnComponent } from './picker-column/picker-column.component';

export interface PickerComponentSelectedColumnsDetails {
  [columnName: string]: { index: number; value: string; changed: boolean };
}

@Component({
  selector: 'app-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PickerComponent {
  @Input()
  public columns: Array<PickerColumn>;

  @Output()
  public appPickerChange = new EventEmitter<PickerComponentSelectedColumnsDetails>();

  @ViewChildren(PickerColumnComponent)
  public pickerColumnComponents: QueryList<PickerColumnComponent>;

  public onHandleColumnChange(pickerColumn: PickerColumn): void {
    const selectedValues: PickerComponentSelectedColumnsDetails = {};

    this.columns.forEach((column) => {
      const index = column.selectedIndex || 0;
      const value = column.options[index].value as string;

      selectedValues[column.name] = {
        index,
        value,
        changed: pickerColumn.name === column.name,
      };
    });

    this.appPickerChange.emit(selectedValues);
  }

  public updateSelectedValue(columnName: string, valueIndex: number): void {
    if (!this.pickerColumnComponents?.length) {
      return;
    }

    const selectedColumnIndex = this.columns.findIndex((column) => column.name === columnName);

    if (selectedColumnIndex !== -1) {
      this.pickerColumnComponents.toArray()[selectedColumnIndex]?.updateSelectedIndexManually(valueIndex);
    }
  }
}
