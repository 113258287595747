import { Component, HostBinding, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { mdTransitionAnimation } from '@ionic/angular';

import { KeyboardPlugin } from './core/services/native-plugins/keyboard.plugin';
import { NetworkPlugin } from './core/services/native-plugins/network.plugin';
import { StatusBarPlugin } from './core/services/native-plugins/status-bar.plugin';

import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import {
  BrowserDateTimePickerModalPage,
  BrowserDateTimePickerModalPageIdentifier,
} from './modals/browser-date-time-picker-modal/browser-date-time-picker-modal.page';

import { PushNotification } from './core/models/push-notification.model';
import { UniversalLink } from './core/models/universal-link.model';
import { User } from './core/models/user.model';

import { AccountService } from './core/services/account.service';
import { AnalyticsService } from './core/services/analytics.service';
import { AppEventsService } from './core/services/app-events.service';
import { AppService } from './core/services/app.service';
import { ConsoleToggleService } from './core/services/console-toggle.service';
import { isCypressTestEnv, isProduction, isSimulatingCordovaPlatform } from './core/services/environment.service';
import { LoggerService } from './core/services/logger.service';
import { LoginService } from './core/services/login.service';
import { ModalService } from './core/services/modal.service';
import { NavigationService } from './core/services/navigation.service';
import { OrdersService } from './core/services/orders.service';
import { OverlayService } from './core/services/overlay.service';
import { PushNotificationsService } from './core/services/push-notifications.service';
import { SearchService } from './core/services/search.service';
import { SettingsService } from './core/services/settings.service';
import { PlatformService } from './core/services/ssr/platform.service';
import { WindowService } from './core/services/ssr/window.service';
import { UniversalLinksService } from './core/services/universal-links.service';

import {
  AreaDateTimePickerComponent,
  AreaDateTimePickerComponentParams,
  AreaDateTimePickerComponentResult,
} from './shared/components/area-date-time-picker/area-date-time-picker.component';

import { PreloadableRouteData } from './app-routing.module';
import { ErrorPageParams } from './pages/error/error-routing.module';

import { toPromise } from './core/operators/to-promise';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('@.disabled')
  public animationsDisabled = isCypressTestEnv();

  @ViewChild(AreaDateTimePickerComponent)
  public areaDateTimePicker: AreaDateTimePickerComponent;

  private isBrowser: boolean;

  private userDetails: User;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private platformService: PlatformService,
    private appService: AppService,
    private loginService: LoginService,
    private accountService: AccountService,
    private networkPlugin: NetworkPlugin,
    private settingsService: SettingsService,
    private translateService: TranslateService,
    private analyticsService: AnalyticsService,
    private pushNotificationsService: PushNotificationsService,
    private universalLinksService: UniversalLinksService,
    private loggerService: LoggerService,
    private statusBarPlugin: StatusBarPlugin,
    private renderer: Renderer2,
    private appEventsService: AppEventsService,
    private overlayService: OverlayService,
    private navigationService: NavigationService,
    private ordersService: OrdersService,
    private searchService: SearchService,
    private keyboardPlugin: KeyboardPlugin,
    private modalService: ModalService,
    private windowService: WindowService,
    private consoleToggleService: ConsoleToggleService,
  ) {
    this.isBrowser = this.platformService.isBrowser;
    this.consoleToggleService.disableConsoleInProduction();

    this.appService.appReady$.pipe(filter((isReady) => !!isReady)).subscribe(() => {
      this.userDetails = this.accountService.userDetails || null;

      this.initializePlatform();
      this.initializeGlobalComponents();

      // Add the gradient background while loading the app so that we don't
      // show a black background by default if there's an error when getting
      // the initial settings
      this.addBackgroundToRouterElement();

      this.statusBarPlugin.initializeStatusBar();
      this.keyboardPlugin.initializeKeyboard();

      this.initializeGlobalEvents();
      this.setViewPortHeightForMobileBrowsers();

      void this.searchService.maybeSendTrackingData();
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route = this.route.snapshot;

          while (route.firstChild) {
            route = route.firstChild;
          }

          return route.data || {};
        }),
      )
      .subscribe(({ title, dynamicTitle }: PreloadableRouteData) => {
        if (!!title) {
          const bilbaytSuffix = this.translateService.instant('PAGES.BILBAYT_SUFFIX') as string;
          const titleTranslated = this.translateService.instant(title) as string;
          const fullTitle = `${titleTranslated} | ${bilbaytSuffix}`;
          this.title.setTitle(fullTitle);
        } else if (!dynamicTitle) {
          const defaultTitleTranslated = this.translateService.instant('PAGES.HOME') as string;
          this.title.setTitle(defaultTitleTranslated);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  private initializePlatform(): void {
    if (
      !isProduction() &&
      this.windowService.isWindowDefined &&
      !this.platformService.areCordovaPluginsAvailable &&
      isSimulatingCordovaPlatform()
    ) {
      this.windowService.window.document.querySelector('html').className += ' plt-cordova';
    }
  }

  private initializeGlobalEvents(): void {
    this.appEventsService
      .onEvent('AuthTokensAreNotValid')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.accountService.logOut();
        void this.loginService.showSocialLogin(this.translateService.instant('SOCIAL_LOGIN.INVALID_AUTH_TOKENS_MESSAGE') as string);
      });

    this.appEventsService
      .onEvent('UserLoginStatusChanged')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.userDetails = this.accountService.userDetails;

          void this.loggerService.identifyUser(this.userDetails);

          void toPromise(this.ordersService.loadLastUnreviewedOrder());

          if (this.router.url.endsWith('/error')) {
            // If the user was taken to the error page after a 401 error, we should
            // redirect him/her back to the home page
            void this.navigationService.navigateBackToHomePage();
          }
        } else {
          // Ignore the event if userDetails is already null which
          // may happen if the user clicks the "log out" option more
          // than once very quickly
          // https://bilbayt.atlassian.net/browse/CA-1615
          if (!this.userDetails) {
            return;
          }

          this.userDetails = null;
          this.ordersService.resetLastUnreviewedOrder();
          void this.loggerService.resetUser();
          void this.analyticsService.resetCurrentUser();

          // Redirect to the initial page when logging out to
          // prevent the user to stay in a page that requires
          // to be logged in
          void this.navigationService.navigateBackToHomePage();
        }
      });

    this.networkPlugin.onConnectionChange$.pipe(takeUntil(this.unsubscribe$)).subscribe((status) => {
      if (status === 'offline') {
        this.overlayService.showToast({
          type: 'error',
          showCloseButton: true,
          message: this.translateService.instant('OFFLINE') as string,
        });
      } else if (status === 'online') {
        this.overlayService.showToast({
          type: 'info',
          showCloseButton: true,
          message: this.translateService.instant('ONLINE') as string,
        });
      }
    });

    // Remove the gradient background to avoid issues in page transitions
    // https://bilbayt.atlassian.net/browse/CA-1836
    this.appEventsService
      .onEvent('EnteredToHomePage')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.removeBackgroundFromRouterElement();
      });

    this.settingsService.countryLanguage$.pipe(takeUntil(this.unsubscribe$)).subscribe(({ changedCountry }) => {
      if (changedCountry) {
        this.addBackgroundToRouterElement();
      }
    });

    this.pushNotificationsService.processPendingPushNotification$.pipe(takeUntil(this.unsubscribe$)).subscribe((pushNotification) => {
      this.handlePushNotification(pushNotification);
    });

    this.universalLinksService.processPendingUniversalLink$.pipe(takeUntil(this.unsubscribe$)).subscribe((universalLink) => {
      this.handleUniversalLink(universalLink);
    });

    this.appEventsService
      .onEvent('ServiceUnavailable')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => void this.navigationService.navigateTo('/incident', false, { animation: mdTransitionAnimation }));

    this.appEventsService
      .onEvent('RedirectToErrorPage')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const errorPageParams: ErrorPageParams = { isInternetError: true };
        void this.navigationService.navigateTo('/error', true, { state: errorPageParams });
      });
  }

  private handlePushNotification(notification: PushNotification): void {
    const targetUrl = this.pushNotificationsService.getHomePageUrl(notification);

    if (!targetUrl) {
      return;
    }

    if (this.navigationService.getUrlWithoutParams().endsWith(targetUrl)) {
      // The user is already in the tab that
      // should handle the notification
      this.pushNotificationsService.handleNotificationOnTabPage(notification);
      return;
    }

    // We only need to redirect the user to the tab page because that
    // page will check if there're any pending deep links to be handled
    // once it's ready
    void this.navigationService.navigateBackToTab(targetUrl);
  }

  private handleUniversalLink(universalLink: UniversalLink): void {
    const targetUrl = this.universalLinksService.getHomePageUrl(universalLink);

    if (!targetUrl) {
      return;
    }

    // We should avoid redirecting back from /vendors/search to /vendors/home if the
    // user manually clicks on a banner or modal ad
    const shouldHandleInCurrenPage =
      this.navigationService.getUrlWithoutParams().endsWith(targetUrl) || universalLink.fromBannerAds || universalLink.fromModalAds;

    if (shouldHandleInCurrenPage) {
      // The user is already in the tab that
      // should handle the notification
      this.universalLinksService.handleUniversalLinkOnTabPage(universalLink);
      return;
    }

    // We only need to redirect the user to the tab page because that
    // page will check if there're any pending deep links to be handled
    // once it's ready
    void this.navigationService.navigateBackToTab(targetUrl);
  }

  private setViewPortHeightForMobileBrowsers(): void {
    // Fix for 100vh not working properly on mobile browsers
    // https://bilbayt.atlassian.net/browse/CA-2647
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    if (this.platformService.isBrowserMobile && this.windowService.isWindowDefined) {
      this.windowService.window.document.documentElement.style.setProperty('--vh', `${this.windowService.window.innerHeight}px`);

      this.windowService.window.addEventListener('resize', () => {
        this.windowService.window.document.documentElement.style.setProperty('--vh', `${this.windowService.window.innerHeight}px`);
      });
    }
  }

  private addBackgroundToRouterElement(): void {
    if (this.platformService.isBrowser || !this.windowService.isWindowDefined) {
      return;
    }

    void this.platformService.wait(500).then(() => {
      const mainElement = this.windowService.window.document.getElementById('main');

      if (!mainElement) {
        return;
      }

      this.renderer.addClass(mainElement, 'with-background');
    });
  }

  private removeBackgroundFromRouterElement(): void {
    if (this.platformService.isBrowser || !this.windowService.isWindowDefined) {
      return;
    }

    void this.platformService.wait(500).then(() => {
      const mainElement = this.windowService.window.document.getElementById('main');

      if (!mainElement) {
        return;
      }

      this.renderer.removeClass(mainElement, 'with-background');
    });
  }

  // We need to bind the methods from the services to the
  // local methods so that we can handle them from here instead
  private initializeGlobalComponents(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.overlayService.showAreaDateTimePicker = this.showAreaDateTimePicker.bind(this);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.overlayService.hideAreaDateTimePicker = this.hideAreaDateTimePicker.bind(this);
  }

  private showAreaDateTimePicker(params: AreaDateTimePickerComponentParams): Promise<AreaDateTimePickerComponentResult> {
    if (this.isBrowser) {
      return this.modalService.showSheet<AreaDateTimePickerComponentResult>({
        id: BrowserDateTimePickerModalPageIdentifier,
        component: BrowserDateTimePickerModalPage,
        breakpoints: undefined,
        initialBreakpoint: undefined,
        canDismiss: params?.enableBackdropDismiss ?? true,
        backdropDismiss: params?.enableBackdropDismiss ?? true,
        cssClass: 'auto-height',
        componentProps: { params },
      });
    }

    return this.areaDateTimePicker.show(params);
  }

  private hideAreaDateTimePicker(): void {
    if (this.isBrowser) {
      void this.modalService.dismissModal({ id: BrowserDateTimePickerModalPageIdentifier });
    } else {
      this.areaDateTimePicker.dismiss();
    }
  }
}
