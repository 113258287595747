import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { catchError, debounceTime, map, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { Address } from '../../core/models/address.model';
import { ISO_FORMAT } from '../../core/models/date-time-format.model';

import { CalendarMonth, DateTimeService } from '../../core/services/date-time.service';
import { LoggerService } from '../../core/services/logger.service';
import { ModalService } from '../../core/services/modal.service';
import { OverlayService } from '../../core/services/overlay.service';
import { PlatformService } from '../../core/services/ssr/platform.service';
import { VendorsService } from '../../core/services/vendors.service';

import { Helpers } from '../../core/helpers';

import { ItemAvailabilityDateTimePickerModalPageAnimations } from './item-availability-date-time-picker-modal.animations';

export const ItemAvailabilityDateTimePickerModalPageIdentifier = 'item-availability-date-time-picker-modal';

const DEBOUNCE_TIME_IN_MS = 750;
const FOOTER_HEIGHT_IN_PX = 45;
const FOOTER_MARGIN_TOP_IN_PX = 16;
const CALENDAR_MIN_HEIGHT_IN_PX = 150;
const CALENDAR_DAY_VIEW_ITEM_HEIGHT_IN_PX = 36;
const CALENDAR_MONTH_VIEW_ITEM_HEIGHT_IN_PX = 44;
const ERROR_MESSAGE_HEIGHT_IN_PX = 110;
const NO_AVAILABLE_DATES_BEFORE_X_MESSAGE_HEIGHT_IN_PX = 88;

interface HourMinuteValue {
  text: string;
  available: boolean;
  isOrderForNow: boolean;
  dateTimeValue: string;
}

export interface ItemAvailabilityDateTimePickerModalPageParams {
  vendorId: number;
  menuItemId: number;
  selectedAddress: Address;
  selectedDateTime: string;
  isOrderForNowSelected: boolean;
  isOrderForNowAvailable: boolean;
}

export interface ItemAvailabilityDateTimePickerModalPageResult {
  hasChangedData: boolean;
  selectedDateTime: string;
  isOrderForNowSelected: boolean;
}

@Component({
  selector: 'app-item-availability-date-time-picker-modal',
  templateUrl: 'item-availability-date-time-picker-modal.page.html',
  styleUrls: ['item-availability-date-time-picker-modal.page.scss'],
  animations: [...ItemAvailabilityDateTimePickerModalPageAnimations],
  encapsulation: ViewEncapsulation.None,
})
export class ItemAvailabilityDateTimePickerModalPage implements OnInit, OnDestroy {
  @Input()
  public params: ItemAvailabilityDateTimePickerModalPageParams;

  public isReady: boolean;
  public isLoading: boolean;
  public canCheckPrevDate: boolean;

  public calendar: CalendarMonth;
  public showCalendar: boolean;

  public shouldShowLoadingErrorMessage: boolean;
  public shouldShowItemNotAvailableSoonMessage: boolean;
  public shouldShowItemNotAvailableBeforeDateMessage: boolean;

  public requestedDate: string;
  public isOrderForNowAvailable: boolean;
  public nextAvailableDate: string;
  public nextAvailableTimes: Array<HourMinuteValue> = [];

  public newSelectedDate: string;
  public newSelectedDateTime: string;
  public newIsOrderForNowSelected: boolean;

  public footerHeight = FOOTER_HEIGHT_IN_PX;
  public footerMarginTop = FOOTER_MARGIN_TOP_IN_PX;
  public calendarItemHeight = CALENDAR_DAY_VIEW_ITEM_HEIGHT_IN_PX;
  public calendarContainerHeight = CALENDAR_MIN_HEIGHT_IN_PX;

  private areaId: number;
  private vendorId: number;
  private menuItemId: number;
  private selectedDateTime: string;
  private isOrderForNowSelected: boolean;

  private onSelectedDateChanged$ = new Subject<string>();
  private unsubscribe$ = new Subject<void>();

  constructor(
    private modalService: ModalService,
    private loggerService: LoggerService,
    private vendorsService: VendorsService,
    private overlayService: OverlayService,
    private dateTimeService: DateTimeService,
    private platformService: PlatformService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.isReady = false;

    this.areaId = this.params?.selectedAddress?.area.areaId;
    this.vendorId = this.params?.vendorId;
    this.menuItemId = this.params?.menuItemId;
    this.selectedDateTime = this.params?.selectedDateTime;
    this.isOrderForNowSelected = this.params?.isOrderForNowSelected;
    this.isOrderForNowAvailable = this.params?.isOrderForNowAvailable;

    if (!this.vendorId || !this.menuItemId || !this.areaId) {
      void this.platformService.wait(300).then(() => {
        const errorMessage = this.translateService.instant('ERROR_MESSAGE.DEFAULT_MESSAGE') as string;
        this.loggerService.info({ component: 'ItemAvailabilityDateTimePickerModal', message: 'some mandatory params are undefined' });
        this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' });
        void this.dismiss();
      });
      return;
    }

    this.onSelectedDateChanged$
      .pipe(
        tap((nextAvailableDate: string) => {
          this.isLoading = true;
          this.newSelectedDateTime = null;
          this.nextAvailableTimes = [];
          this.nextAvailableDate = nextAvailableDate;
          this.canCheckPrevDate = !this.dateTimeService.isToday(nextAvailableDate);
          this.shouldShowLoadingErrorMessage = false;
          this.shouldShowItemNotAvailableSoonMessage = false;
          this.shouldShowItemNotAvailableBeforeDateMessage = false;
        }),
        debounceTime(DEBOUNCE_TIME_IN_MS),
        switchMap(() => this.loadCalendarForDate(this.nextAvailableDate)),
        tap(() => {
          this.isLoading = false;
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    this.requestedDate = this.selectedDateTime
      ? this.dateTimeService.parseIso(this.selectedDateTime).startOf('day').format(ISO_FORMAT)
      : this.dateTimeService.now.startOf('day').format(ISO_FORMAT);

    if (this.selectedDateTime || this.isOrderForNowSelected) {
      this.onSelectedDateChanged$.next(this.requestedDate);
    } else {
      this.initializeCalendar({ includingDateTimeIso: this.requestedDate });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  public onShowPrevDate(): void {
    if (this.dateTimeService.isToday(this.nextAvailableDate)) {
      this.canCheckPrevDate = false;
      return;
    }

    this.onSelectedDateChanged$.next(
      this.dateTimeService.parseIso(this.nextAvailableDate).subtract(1, 'day').startOf('day').format(ISO_FORMAT),
    );
  }

  public onShowNextDate(): void {
    this.onSelectedDateChanged$.next(this.dateTimeService.parseIso(this.nextAvailableDate).add(1, 'day').startOf('day').format(ISO_FORMAT));
  }

  public onShowNextMonth(): void {
    this.initializeCalendar({
      includingDateTimeIso: this.dateTimeService
        .parseIso(this.calendar.days[this.calendar.days.length - 1].valueIsoString)
        .add(1, 'day')
        .format(ISO_FORMAT),
    });
  }

  public onShowPrevMonth(): void {
    this.initializeCalendar({
      includingDateTimeIso: this.dateTimeService.parseIso(this.calendar.days[0].valueIsoString).subtract(1, 'day').format(ISO_FORMAT),
    });
  }

  public onShowCalendarForSelectedDateTime(): void {
    this.initializeCalendar({ includingDateTimeIso: this.nextAvailableDate });
    this.newSelectedDate = this.dateTimeService.parseIso(this.nextAvailableDate).startOf('day').format(ISO_FORMAT);
  }

  public onUpdateSelectedDate(selectedDateIso: string): void {
    this.newSelectedDate = selectedDateIso;
  }

  public onUpdateSelectedDateTime(hourMinuteValue: HourMinuteValue): void {
    this.newSelectedDateTime = hourMinuteValue.isOrderForNow ? undefined : hourMinuteValue.dateTimeValue;
    this.newIsOrderForNowSelected = hourMinuteValue.isOrderForNow;
  }

  public onHandleSubmit(): void {
    if (this.showCalendar) {
      if (!this.newSelectedDate) {
        return;
      }

      this.requestedDate = this.newSelectedDate;
      this.onSelectedDateChanged$.next(this.requestedDate);
      this.showCalendar = false;
    } else {
      if (!this.newSelectedDateTime && !this.newIsOrderForNowSelected) {
        return;
      }

      void this.dismiss({
        hasChangedData:
          !!this.isOrderForNowSelected !== !!this.newIsOrderForNowSelected ||
          this.dateTimeService.hasChangedDateOrTime(this.selectedDateTime, this.newSelectedDateTime),
        selectedDateTime: this.newSelectedDateTime,
        isOrderForNowSelected: this.newIsOrderForNowSelected,
      });
    }
  }

  public onHandleTryAgain(): void {
    this.onSelectedDateChanged$.next(this.nextAvailableDate);
  }

  public onDismiss(results?: ItemAvailabilityDateTimePickerModalPageResult): void {
    void this.dismiss(results);
  }

  private initializeCalendar({ includingDateTimeIso }: { includingDateTimeIso: string }): void {
    this.showCalendar = true;
    this.calendar = this.dateTimeService.getCalendarForMonth(includingDateTimeIso);

    this.newSelectedDate = undefined;
    this.shouldShowItemNotAvailableSoonMessage = false;
    this.shouldShowItemNotAvailableBeforeDateMessage = false;

    // This is important to animate any changes in the height of the container
    this.calendarItemHeight = CALENDAR_MONTH_VIEW_ITEM_HEIGHT_IN_PX;
    this.calendarContainerHeight =
      Math.ceil((this.calendar.weekDayNames.length + this.calendar.daysBeforeStartDay.length + this.calendar.days.length) / 7) *
        this.calendarItemHeight +
      (FOOTER_HEIGHT_IN_PX + FOOTER_MARGIN_TOP_IN_PX);
  }

  private loadCalendarForDate(selectedDateIsoString: string): Observable<void> {
    return this.vendorsService
      .getMenuItemAvailableTimes({
        areaId: this.areaId,
        vendorId: this.vendorId,
        menuItemId: this.menuItemId,
        dateTime: selectedDateIsoString,
      })
      .pipe(
        map((availableTimes) => {
          this.nextAvailableDate = availableTimes.date;

          const nextAvailableTimes: Array<HourMinuteValue> = availableTimes.times.map((availableTime) => ({
            text: `${Helpers.padNumber(availableTime.hour)}:${Helpers.padNumber(availableTime.minute)}`,
            available: !!availableTime.available,
            dateTimeValue: this.dateTimeService
              .parseIso(this.nextAvailableDate)
              .startOf('day')
              .hour(availableTime.hour)
              .minute(availableTime.minute)
              .format(ISO_FORMAT),
            isOrderForNow: false,
          }));

          if (this.dateTimeService.isToday(this.nextAvailableDate) && this.isOrderForNowAvailable) {
            nextAvailableTimes.unshift({
              text: this.translateService.instant('NOW') as string,
              available: true,
              dateTimeValue: undefined,
              isOrderForNow: true,
            });
          }

          this.nextAvailableTimes = nextAvailableTimes;
          this.canCheckPrevDate = !this.dateTimeService.isToday(this.nextAvailableDate);
          this.shouldShowItemNotAvailableSoonMessage = selectedDateIsoString !== this.nextAvailableDate && !this.nextAvailableTimes?.length;
          this.shouldShowItemNotAvailableBeforeDateMessage =
            selectedDateIsoString !== this.nextAvailableDate && !!this.nextAvailableTimes?.length;

          // This is important to animate any changes in the height of the container
          this.calendarItemHeight = CALENDAR_DAY_VIEW_ITEM_HEIGHT_IN_PX;
          this.calendarContainerHeight =
            Math.ceil(this.nextAvailableTimes.length / 4) * this.calendarItemHeight +
            (FOOTER_HEIGHT_IN_PX + FOOTER_MARGIN_TOP_IN_PX) +
            (this.shouldShowItemNotAvailableSoonMessage ? ERROR_MESSAGE_HEIGHT_IN_PX : 0) +
            (this.shouldShowItemNotAvailableBeforeDateMessage ? NO_AVAILABLE_DATES_BEFORE_X_MESSAGE_HEIGHT_IN_PX : 0);
        }),
        catchError((error: HttpErrorResponse) => {
          this.loggerService.info({
            component: 'ItemAvailabilityDateTimePicker',
            message: "couldn't load item available date/times",
            details: { error },
          });

          this.shouldShowLoadingErrorMessage = true;
          this.calendarContainerHeight = ERROR_MESSAGE_HEIGHT_IN_PX + (FOOTER_HEIGHT_IN_PX + FOOTER_MARGIN_TOP_IN_PX);

          return of(null);
        }),
        takeUntil(this.unsubscribe$),
      );
  }

  private dismiss(results?: ItemAvailabilityDateTimePickerModalPageResult): Promise<boolean> {
    return this.modalService.dismissModal({ id: ItemAvailabilityDateTimePickerModalPageIdentifier, data: results });
  }
}
