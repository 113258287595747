import { Injectable } from '@angular/core';

import { EnvironmentName } from '../enums/environment-name.enum';

import { environment } from '../../../environments/environment';

export function getEnvironmentProps(): typeof environment {
  return environment;
}

export function isCypressTestEnv(): boolean {
  return environment.isCypressTestEnv;
}

// NOTE: this is a workaround to be able to test the way the app looks like
// in cordova but running the app in the browser (otherwise we need to build
// and run the app on an emulator which is very time consuming).
export function isSimulatingCordovaPlatform(): boolean {
  return environment.isSimulatingCordovaPlatform;
}

export function isProduction(): boolean {
  return environment.production;
}

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  constructor() {}

  public get environmentName(): EnvironmentName {
    return environment.name;
  }

  public get apiUrl(): string {
    return environment.apiUrl;
  }

  public get websiteUrl(): string {
    return environment.websiteUrl;
  }

  public get authApiUrl(): string {
    return environment.authApiUrl;
  }

  public get apiClientId(): string {
    return environment.apiClientId;
  }

  public get apiClientSecret(): string {
    return environment.apiClientSecret;
  }

  public get mixpanelAnalyticsTrackerId(): string {
    return environment.mixpanelAnalyticsTrackerId;
  }

  public get sentryDSN(): string {
    return environment.sentryDSN;
  }

  public get sentryProjectName(): string {
    return environment.sentryProjectName;
  }

  public get googleMapsApiKey(): string {
    return environment.googleMapsApiKey;
  }

  public get translationsUrl(): string {
    return environment.translationsUrl;
  }

  public get initialSettingsUrl(): string {
    return environment.initialSettingsUrl;
  }

  public get vendorsSlugsUrl(): string {
    return environment.vendorsSlugsUrl;
  }

  public get googleAuthClientId(): string {
    return environment.googleAuthClientId;
  }

  public get applePayMerchantId(): string {
    return environment.applePayMerchantId;
  }

  public get branchKey(): string {
    return environment.branchKey;
  }

  public get cleverTapAccountId(): string {
    return environment.cleverTapAccountId;
  }
}
