import { transition, trigger } from '@angular/animations';

import {
  AnimationTransition,
  applyFadeInAnimation,
  applyFadeInTranslateAnimation,
  applyFadeOutAnimation,
  applyFadeOutTranslateAnimation,
  triggerFadeInExpandCollapseStatefulAnimation,
} from '../../core/animations';

export const MenuItemDetailsModalComponentAnimations = [
  trigger('animatedContent', [transition(AnimationTransition.Enter, applyFadeInAnimation({ duration: 600 }))]),
  trigger('animatedWishlistButton', [
    transition(AnimationTransition.Enter, applyFadeInTranslateAnimation({ duration: 400, delay: 200, distance: '8px' })),
    transition(AnimationTransition.Leave, applyFadeOutTranslateAnimation({ duration: 400, distance: '-8px' })),
  ]),
  trigger('animatedBackButton', [
    transition(AnimationTransition.Enter, applyFadeInAnimation({ duration: 400 })),
    transition(AnimationTransition.Leave, applyFadeOutAnimation({ duration: 400 })),
  ]),
  trigger('animatedShareButton', [
    transition(AnimationTransition.Enter, applyFadeInTranslateAnimation({ duration: 400, delay: 300, distance: '8px' })),
    transition(AnimationTransition.Leave, applyFadeOutTranslateAnimation({ duration: 400, distance: '-8px' })),
  ]),
  trigger('animatedWishlistName', [
    transition(AnimationTransition.Enter, applyFadeInAnimation({ duration: 300 })),
    transition(AnimationTransition.Leave, applyFadeOutAnimation({ duration: 300 })),
  ]),
  trigger('animatedWishlistLoadingIcon', [
    transition(AnimationTransition.Enter, applyFadeInTranslateAnimation({ duration: 300, distance: '50px' })),
    transition(AnimationTransition.Leave, applyFadeOutTranslateAnimation({ duration: 300, distance: '-50px' })),
  ]),
  trigger('animatedWishlistSuccessIcon', [
    transition(AnimationTransition.Enter, applyFadeInTranslateAnimation({ duration: 300, distance: '50px' })),
    transition(AnimationTransition.Leave, applyFadeOutTranslateAnimation({ duration: 300, distance: '-50px' })),
  ]),
  triggerFadeInExpandCollapseStatefulAnimation('animatedOptionGroupContent'),
  triggerFadeInExpandCollapseStatefulAnimation('animatedAddonGroupContent'),
  trigger('animagedGroupBadgeIcon', [
    transition(AnimationTransition.Enter, applyFadeInTranslateAnimation({ duration: 400, distance: '8px' })),
    transition(AnimationTransition.Leave, applyFadeOutTranslateAnimation({ duration: 400, distance: '8px' })),
  ]),
];
