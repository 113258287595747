import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { AnimatedImageComponent } from './animated-image.component';

@NgModule({
  declarations: [AnimatedImageComponent],
  imports: [CommonModule, IonicModule, TranslateModule.forChild()],
  exports: [AnimatedImageComponent],
})
export class AnimatedImageComponentModule {}
