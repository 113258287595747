import { Network } from '@awesome-cordova-plugins/network/ngx';

import { PlatformService } from '../ssr/platform.service';
import { WindowService } from '../ssr/window.service';

class BrowserNetworkProvider extends Network {
  constructor(private windowService: WindowService) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public get type(): string {
    return this.isOnline() ? 'wify' : 'none';
  }

  private isOnline(): boolean {
    return this.windowService.isWindowDefined ? this.windowService.window.navigator.onLine : true;
  }
}

class MobileNetworkProvider extends Network {}

function networkFactory(windowService: WindowService, platformService: PlatformService): Network {
  return platformService.areCordovaPluginsAvailable ? new MobileNetworkProvider() : new BrowserNetworkProvider(windowService);
}

export const networkProvider = { provide: Network, useFactory: networkFactory, deps: [WindowService, PlatformService] };
