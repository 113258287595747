import { Injectable } from '@angular/core';

import { Mixpanel, MixpanelPeople } from '@awesome-cordova-plugins/mixpanel/ngx';

import { EnvironmentService } from '../environment.service';
import { LoggerService } from '../logger.service';

const FAKE_DISTINCT_ID = 'fake-mixpanel-distinct-id';

@Injectable({ providedIn: 'root' })
export class MixpanelPlugin {
  constructor(
    private mixpanel: Mixpanel,
    private mixpanelPeople: MixpanelPeople,
    private loggerService: LoggerService,
    private environmentService: EnvironmentService,
  ) {}

  public init(): Promise<void> {
    try {
      const mixPanelAnalyticsTrackerId = this.environmentService.mixpanelAnalyticsTrackerId;
      return this.mixpanel
        .init(mixPanelAnalyticsTrackerId)
        .then(() => {})
        .catch((error: unknown) => {
          this.loggerService.info({ component: 'MixpanelPlugin', message: "couldn't init", details: { error }, fromPlugin: true });
        });
    } catch (error: unknown) {
      this.loggerService.info({ component: 'MixpanelPlugin', message: "couldn't init", details: { error }, fromPlugin: true });
    }
  }

  public logEvent(eventName: string, eventProperties?: Record<string, unknown>): Promise<void> {
    try {
      return this.mixpanel
        .track(eventName, eventProperties)
        .then(() => {})
        .catch((error: unknown) => {
          this.loggerService.info({ component: 'MixpanelPlugin', message: "couldn't log event", details: { error }, fromPlugin: true });
        });
    } catch (error: unknown) {
      this.loggerService.info({ component: 'MixpanelPlugin', message: "couldn't log event", details: { error }, fromPlugin: true });
    }
  }

  public getDistinctId(): Promise<string> {
    try {
      return this.mixpanel
        .distinctId()
        .then((distinctId) => distinctId as string)
        .catch((error: unknown) => {
          this.loggerService.info({ component: 'MixpanelPlugin', message: "couldn't log event", details: { error }, fromPlugin: true });
          return FAKE_DISTINCT_ID;
        });
    } catch (error: unknown) {
      this.loggerService.info({ component: 'MixpanelPlugin', message: "couldn't log event", details: { error }, fromPlugin: true });
      return Promise.reject(FAKE_DISTINCT_ID);
    }
  }

  public identifyExistingUser(distinctId: string): Promise<string> {
    try {
      return this.mixpanel
        .identify(distinctId)
        .then(() => this.getDistinctId())
        .catch((error: unknown) => {
          this.loggerService.info({
            component: 'MixpanelPlugin',
            message: "couldn't identify the user",
            details: { error },
            fromPlugin: true,
          });
          return '';
        });
    } catch (error: unknown) {
      this.loggerService.info({ component: 'MixpanelPlugin', message: "couldn't identify the user", details: { error }, fromPlugin: true });
      return Promise.resolve('');
    }
  }

  // We need to use the mixpanel distinctId to identify the user because
  // we don't know if the event related to the alias was already sent to
  // Mixpanel due to the async nature of the native SDK
  public registerUserAlias(userId: string): Promise<string> {
    try {
      return this.getDistinctId()
        .then((distinctId) =>
          this.mixpanel
            .alias(userId, distinctId)
            .then(() => this.mixpanel.identify(distinctId))
            .then(() => distinctId),
        )
        .catch((error: unknown) => {
          this.loggerService.info({
            component: 'MixpanelPlugin',
            message: `couldn't create alias for user ${userId}`,
            details: { error },
            fromPlugin: true,
          });
          return '';
        });
    } catch (error: unknown) {
      this.loggerService.info({
        component: 'MixpanelPlugin',
        message: `couldn't create alias for user ${userId}`,
        details: { error },
        fromPlugin: true,
      });
      return Promise.resolve('');
    }
  }

  public resetDistinctId(): Promise<void> {
    try {
      return this.mixpanel
        .reset()
        .then(() => {})
        .catch((error: unknown) => {
          this.loggerService.info({
            component: 'MixpanelPlugin',
            message: "couldn't reset distinct id",
            details: { error },
            fromPlugin: true,
          });
        });
    } catch (error: unknown) {
      this.loggerService.info({ component: 'MixpanelPlugin', message: "couldn't reset distinct id", details: { error }, fromPlugin: true });
    }
  }

  public set(peopleProperties: Record<string, unknown>): Promise<void> {
    try {
      return this.mixpanelPeople
        .set(peopleProperties)
        .then(() => {})
        .catch((error: unknown) => {
          this.loggerService.info({
            component: 'MixpanelPlugin',
            message: "couldn't set people properties",
            details: { error },
            fromPlugin: true,
          });
        });
    } catch (error: unknown) {
      this.loggerService.info({
        component: 'MixpanelPlugin',
        message: "couldn't set people properties",
        details: { error },
        fromPlugin: true,
      });
    }
  }

  public setOnce(peopleProperties: Record<string, unknown>): Promise<void> {
    try {
      return this.mixpanelPeople
        .setOnce(peopleProperties)
        .then(() => {})
        .catch((error: unknown) => {
          this.loggerService.info({
            component: 'MixpanelPlugin',
            message: "couldn't set people properties once",
            details: { error },
            fromPlugin: true,
          });
        });
    } catch (error: unknown) {
      this.loggerService.info({
        component: 'MixpanelPlugin',
        message: "couldn't set people properties once",
        details: { error },
        fromPlugin: true,
      });
    }
  }

  public union(peopleProperties: Record<string, unknown>): Promise<void> {
    try {
      return this.mixpanelPeople
        .union(peopleProperties)
        .then(() => {})
        .catch((error: unknown) => {
          this.loggerService.info({
            component: 'MixpanelPlugin',
            message: "couldn't union people properties",
            details: { error },
            fromPlugin: true,
          });
        });
    } catch (error: unknown) {
      this.loggerService.info({
        component: 'MixpanelPlugin',
        message: "couldn't union people properties",
        details: { error },
        fromPlugin: true,
      });
    }
  }
}
