<ion-content>
  <div class="modal-content">
    <div class="modal-header">
      <div class="location-pin-icon"></div>
    </div>

    <div class="modal-body">
      <h1 class="title">{{ "PUSH_NOTIFICATIONS_DISABLED_MODAL_PAGE.TITLE" | translate }}</h1>
      <p class="message">{{ "PUSH_NOTIFICATIONS_DISABLED_MODAL_PAGE.MESSAGE" | translate }}</p>
    </div>

    <div class="modal-footer">
      <ion-button (click)="onOpenPushNotificationSettings()" expand="block" class="custom-button continue-button" color="green">
        {{ "PUSH_NOTIFICATIONS_DISABLED_MODAL_PAGE.OPEN_SETTINGS" | translate }}
      </ion-button>

      <ion-button (click)="onDismiss()" expand="block" class="custom-button skip-button" color="grey">
        {{ "SKIP" | translate }}
      </ion-button>
    </div>
  </div>
</ion-content>
