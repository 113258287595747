import { Injectable } from '@angular/core';

import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';

import { CountryCode } from '../../models/country.model';
import { LanguageCode } from '../../models/language.model';

import { EnvironmentService } from '../environment.service';
import { LoggerService } from '../logger.service';
import { PlatformService } from '../ssr/platform.service';
import { WindowService } from '../ssr/window.service';

declare global {
  interface Window {
    FirebasePlugin: {
      setCrashlyticsCustomKey: (key: string, value: string | number | boolean) => void;
      isCrashlyticsCollectionEnabled: (success: (isEnabled: boolean) => void) => void;
    };
  }
}

@Injectable({ providedIn: 'root' })
export class FirebasePlugin {
  constructor(
    private firebaseX: FirebaseX,
    private windowService: WindowService,
    private loggerService: LoggerService,
    private platformService: PlatformService,
    private environmentService: EnvironmentService,
  ) {}

  public unregisterFromPushNotifications(country: CountryCode, language: LanguageCode, userId?: string): void {
    // We don't want to receive notifications from Firebase
    // but we can't unregister the device because CleverTap
    // relies on the FCM plugin
    void Promise.all([
      this.firebaseX.unsubscribe('all'),
      this.firebaseX.unsubscribe(country),
      this.firebaseX.unsubscribe(language),
      this.firebaseX.unsubscribe(`${country}-${language}`),
      this.firebaseX.unsubscribe('logged-in'),
      this.firebaseX.unsubscribe('not-logged-in'),
      userId ? `user-${userId}` : Promise.resolve(),
    ])
      .then(() => {})
      .catch(() => {});
  }

  public logPageViewEvent(name: string, params?: Record<string, unknown>): Promise<void> {
    return Promise.all([this.firebaseX.setScreenName(name), this.firebaseX.logEvent(name, params)])
      .then(() => {})
      .catch((error: unknown) => {
        this.loggerService.info({
          component: 'FirebasePlugin',
          message: "couldn't log page view event",
          details: { error },
          fromPlugin: true,
        });
      });
  }

  public logEvent(name: string, params?: Record<string, unknown>): Promise<void> {
    return this.firebaseX
      .logEvent(name, params)
      .then(() => {})
      .catch((error: unknown) => {
        this.loggerService.info({ component: 'FirebasePlugin', message: "couldn't log event", details: { error }, fromPlugin: true });
      });
  }

  public getFirebaseTokenAfterAuthenticatingWithApple(): Promise<string> {
    return this.firebaseX
      .authenticateUserWithApple()
      .then((crendential: object) => {
        if (!crendential) {
          this.loggerService.info({
            component: 'FirebasePlugin',
            message: "couldn't get apple credential details: object",
            fromPlugin: true,
          });
          return '';
        }

        return this.firebaseX
          .signInWithCredential(crendential)
          .then(() => this.firebaseX.getCurrentUser())
          .then((user) => {
            if (typeof user === 'string') {
              return user;
            }

            if (!user?.idToken) {
              this.loggerService.info({ component: 'FirebasePlugin', message: "couldn't get apple id details: token", fromPlugin: true });
              return '';
            }

            return user.idToken;
          });
      })
      .catch((error: unknown) => {
        this.loggerService.error({
          component: 'FirebasePlugin',
          message: "couldn't authenticate with details: apple",
          fromPlugin: true,
          error,
        });
        return '';
      });
  }

  public getFirebaseTokenAfterAuthenticatingWithGoogle(): Promise<string> {
    const clientId = this.environmentService.googleAuthClientId;

    return this.firebaseX
      .authenticateUserWithGoogle(clientId)
      .then((credential: object) => {
        if (!credential) {
          this.loggerService.info({
            component: 'FirebasePlugin',
            message: "couldn't get google credential details: object",
            fromPlugin: true,
          });
          return '';
        }

        return this.firebaseX
          .signInWithCredential(credential)
          .then(() => this.firebaseX.getCurrentUser())
          .then((user) => {
            if (typeof user === 'string') {
              return user;
            }

            if (!user?.idToken) {
              this.loggerService.info({ component: 'FirebasePlugin', message: "couldn't get google id details: token", fromPlugin: true });
              return '';
            }

            return user.idToken;
          });
      })
      .catch((error: unknown) => {
        this.loggerService.error({
          component: 'FirebasePlugin',
          message: "couldn't authenticate with details: google",
          fromPlugin: true,
          error,
        });
        return '';
      });
  }

  public logMessage(message: string): Promise<unknown> {
    return this.firebaseX.logMessage(message);
  }

  public setCrashlyticsUserId(userId: string): Promise<unknown> {
    return this.firebaseX.setCrashlyticsUserId(userId);
  }

  public setCrashlyticsCustomKey(key: string, value: string): void {
    if (!this.windowService.isWindowDefined) {
      return;
    }

    const firebasePluginRef = this.windowService.window.FirebasePlugin;
    if (!firebasePluginRef || !firebasePluginRef.setCrashlyticsCustomKey) {
      return;
    }

    firebasePluginRef.setCrashlyticsCustomKey(key, value);
  }

  public isCrashlyticsCollectionEnabled(): boolean {
    if (!this.windowService.isWindowDefined) {
      return;
    }

    const firebasePluginRef = this.windowService.window.FirebasePlugin;
    if (!firebasePluginRef || !firebasePluginRef.isCrashlyticsCollectionEnabled) {
      return false;
    }

    let isEnabled: boolean;
    firebasePluginRef.isCrashlyticsCollectionEnabled((enabled) => (isEnabled = enabled));

    return isEnabled;
  }

  public hasRemoteNotificationsPermission(): Promise<boolean> {
    return this.firebaseX.hasPermission().catch((error: unknown) => {
      this.loggerService.info({
        component: 'FirebasePlugin',
        message: "couldn't check if has permission",
        details: { error },
        fromPlugin: true,
      });
      return false;
    });
  }

  public grantPermission(): Promise<boolean> {
    return this.firebaseX
      .hasPermission()
      .then((hasPermission) => {
        if (hasPermission) {
          return true;
        }

        return this.firebaseX.grantPermission().then(() => this.firebaseX.hasPermission());
      })
      .catch((error: unknown) => {
        this.loggerService.info({
          component: 'FirebasePlugin',
          message: "couldn't grant permission",
          fromPlugin: true,
          details: { error },
        });
        return false;
      });
  }

  public getToken(): Promise<string> {
    return this.platformService.isAndroid ? this.firebaseX.getToken() : this.firebaseX.getAPNSToken();
  }
}
