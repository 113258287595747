import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';

import { LogInModalPage, LogInModalPageIdentifier } from '../log-in-modal/log-in-modal.page';

import { AccountService } from '../../core/services/account.service';
import { AnalyticsService } from '../../core/services/analytics.service';
import { DateTimeService } from '../../core/services/date-time.service';
import { LoggerService } from '../../core/services/logger.service';
import { ModalService } from '../../core/services/modal.service';
import { OverlayService } from '../../core/services/overlay.service';
import { PlatformService } from '../../core/services/ssr/platform.service';
import { ValidationService } from '../../core/services/validation.service';

import { AnalyticsConfig, TOKEN_ANALYTICS_CONFIG } from '../../core/config/analytics.config';

type SocialLoginProvider = 'apple' | 'google';

export const SocialLoginModalPageIdentifier = 'social-login-modal';

@Component({
  selector: 'app-social-login-modal',
  templateUrl: 'social-login-modal.page.html',
  styleUrls: ['social-login-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SocialLoginModalPage implements OnInit {
  @Input()
  public message?: string;

  public isIos: boolean;
  public isBrowser: boolean;

  private dismissed$ = new Subject<void>();

  constructor(
    private modalService: ModalService,
    private loggerService: LoggerService,
    private accountService: AccountService,
    private overlayService: OverlayService,
    private dateTimeService: DateTimeService,
    private platformService: PlatformService,
    private analyticsService: AnalyticsService,
    private validationService: ValidationService,
    @Inject(TOKEN_ANALYTICS_CONFIG) private analyticsConfig: AnalyticsConfig,
  ) {}

  ngOnInit(): void {
    this.isIos = this.platformService.isIos;
    this.isBrowser = this.platformService.isBrowser;
  }

  public onEmailSignIn(): void {
    void this.dismiss()
      .then(() => this.modalService.showModal<void>({ component: LogInModalPage, id: LogInModalPageIdentifier }))
      .then(() => this.emitDismissed());
  }

  public onSocialSignIn(provider: SocialLoginProvider): void {
    void this.overlayService.showLoading().then(() => {
      const getFirebaseToken =
        provider === 'apple' ? this.accountService.signInUserWithApple() : this.accountService.signInUserWithGoogle();

      void getFirebaseToken.then((firebaseToken) => {
        if (!firebaseToken) {
          void this.dismiss()
            .then(() =>
              this.overlayService.hideLoading().then(() => {
                this.overlayService.showToast({
                  message: this.validationService.getErrorMessage(),
                  showCloseButton: true,
                  type: 'error',
                });
              }),
            )
            .then(() => this.emitDismissed());

          return;
        }

        this.accountService.logInWithFirebaseToken(firebaseToken).subscribe({
          next: (userDetails) => {
            const { isNewUser } = userDetails;

            const identifyUserInMixpanel = isNewUser
              ? this.analyticsService.identifyNewUser().catch((error: unknown) =>
                  this.loggerService.info({
                    component: 'SocialLoginModalPage',
                    message: "couldn't create alias for user",
                    details: { error },
                  }),
                )
              : this.analyticsService
                  .identifyExistingUser()
                  .catch((error: unknown) =>
                    this.loggerService.info({ component: 'SocialLoginModalPage', message: "couldn't identify user", details: { error } }),
                  );

            void identifyUserInMixpanel.then(() => {
              isNewUser ? this.trackSignupEvent(provider) : this.trackLoginEvent(provider);

              void this.dismiss()
                .then(() => this.overlayService.hideLoading())
                .then(() => this.emitDismissed());
            });
          },
          error: (error: HttpErrorResponse) => {
            const errorMessage = this.validationService.getErrorMessage(error);
            this.loggerService.info({
              component: 'SocialLoginModalPage',
              message: `couldn't sign in with ${provider}`,
              details: { error },
            });

            void this.dismiss()
              .then(() => this.overlayService.hideLoading())
              .then(() => this.overlayService.showToast({ message: errorMessage, showCloseButton: true, type: 'error' }))
              .then(() => this.emitDismissed());
          },
        });
      });
    });
  }

  private dismiss(): Promise<boolean> {
    return this.modalService.dismissModal({ id: SocialLoginModalPageIdentifier, data: this.dismissed$.asObservable() });
  }

  private emitDismissed(): void {
    this.dismissed$.next();
    this.dismissed$.complete();
  }

  private trackSignupEvent(provider: SocialLoginProvider): void {
    void this.analyticsService.trackEvent({ name: this.analyticsConfig.eventName.signUp });
    void this.analyticsService.setUserProperties({
      [this.analyticsConfig.mixpanelUserPropertyName.socialLoginProvider]: provider,
      [this.analyticsConfig.mixpanelUserPropertyName.signUpDate]: this.dateTimeService.now.toDate(),
    });
  }

  private trackLoginEvent(provider: SocialLoginProvider): void {
    void this.analyticsService.trackEvent({ name: this.analyticsConfig.eventName.logIn });
    void this.analyticsService.setUserProperties({
      [this.analyticsConfig.mixpanelUserPropertyName.socialLoginProvider]: provider,
    });
  }
}
