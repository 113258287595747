import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx';

import { EnvironmentService, isCypressTestEnv } from '../environment.service';
import { PlatformService } from '../ssr/platform.service';
import { WindowService } from '../ssr/window.service';

interface CleverTapWebSdk {
  spa: boolean;
  enablePersonalization: boolean;
  init: (accountId: string) => void;
  setLogLevel: (level: number) => void;
  getCleverTapID: () => string;
  event: { push: (name: string, payload?: unknown) => void };
  privacy: { push: (params: { optOut?: boolean; useIP?: boolean }) => void };
  profile: { push: (params: { Site: Record<string, unknown> }) => void };
  onUserLogin: { push: (params: { Site: Record<string, unknown> }) => void };
}

let cleverTap: CleverTapWebSdk;

class BrowserCleverTapProvider extends CleverTap {
  constructor(private windowService: WindowService, private environmentService: EnvironmentService) {
    super();

    if (!this.windowService.isWindowDefined) {
      return;
    }

    if (isCypressTestEnv()) {
      return;
    }

    import('clevertap-web-sdk')
      .then((clevertapLib: { default: CleverTapWebSdk }) => {
        if (clevertapLib && clevertapLib.default) {
          cleverTap = clevertapLib.default;

          cleverTap.spa = true;
          cleverTap.privacy.push({ useIP: false });
          cleverTap.privacy.push({ optOut: false });
          cleverTap.init(this.environmentService.cleverTapAccountId);
        }
      })
      .catch(() => {});
  }

  public notifyDeviceReady(): Promise<unknown> {
    return Promise.resolve();
  }

  public getCleverTapID(): Promise<string> {
    return Promise.resolve(cleverTap ? cleverTap.getCleverTapID() : 'some-fake-clever-tap-id');
  }

  public setDebugLevel(level: number): Promise<unknown> {
    if (cleverTap) {
      cleverTap.setLogLevel(level);
    }

    return Promise.resolve();
  }

  public enablePersonalization(): Promise<unknown> {
    if (cleverTap) {
      cleverTap.enablePersonalization = true;
    }

    return Promise.resolve();
  }

  public registerPush(): Promise<unknown> {
    return Promise.resolve();
  }

  public setPushToken(_token: string): Promise<unknown> {
    return Promise.resolve();
  }

  public recordEventWithName(eventName: string): Promise<unknown> {
    if (cleverTap) {
      cleverTap.event.push(eventName);
    }

    return Promise.resolve();
  }

  public recordEventWithNameAndProps(eventName: string, eventProps: unknown): Promise<unknown> {
    if (cleverTap) {
      cleverTap.event.push(eventName, eventProps);
    }

    return Promise.resolve();
  }

  public onUserLogin(profile: Record<string, unknown>): Promise<unknown> {
    if (cleverTap) {
      cleverTap.onUserLogin.push({ Site: profile });
    }

    return Promise.resolve();
  }

  public profileSet(profile: Record<string, unknown>): Promise<unknown> {
    if (cleverTap) {
      cleverTap.profile.push({ Site: profile });
    }

    return Promise.resolve();
  }

  public createNotificationChannel(
    _channelID: string,
    _channelName: string,
    _channelDescription: string,
    _importance: number,
    _showBadge: boolean,
  ): Promise<unknown> {
    if (isCypressTestEnv() && this.windowService.isWindowDefined) {
      const notification = this.windowService.window.localStorage.getItem('cy-test-notification');
      const notificationDelay = this.windowService.window.localStorage.getItem('cy-test-notification-delay') ?? 0;

      if (notification) {
        setTimeout(() => {
          this.windowService.window.document.dispatchEvent(
            new CustomEvent('onCleverTapPushNotificationTappedWithCustomExtras', {
              detail: { customExtras: { ...(JSON.parse(notification) as Record<string, string>) } },
            }),
          );
        }, +notificationDelay);
      }
    }

    return Promise.resolve();
  }
}

class MobileCleverTapProvider extends CleverTap {}

function cleverTapFactory(
  windowService: WindowService,
  platformService: PlatformService,
  environmentService: EnvironmentService,
): CleverTap {
  return platformService.areCordovaPluginsAvailable
    ? new MobileCleverTapProvider()
    : new BrowserCleverTapProvider(windowService, environmentService);
}

export const cleverTapProvider = {
  provide: CleverTap,
  useFactory: cleverTapFactory,
  deps: [WindowService, PlatformService, EnvironmentService],
};
