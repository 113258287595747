import { NgModule } from '@angular/core';
import { Route, RouterModule, ROUTES } from '@angular/router';

import { PlatformService } from './core/services/ssr/platform.service';

import { AppPreloadingStrategy } from './app-preloading-strategy';
import { APP_ROUTES } from './app-routes';
import { SERVER_ROUTES } from './app-routes.server';

export declare type PreloadableRouteData = { preload?: boolean; delay?: number; title?: string; dynamicTitle?: boolean };
export declare type PreloadableRoute = Route & { data?: PreloadableRouteData } & { children?: Array<PreloadableRoute> };
export declare type PreloadableRoutes = PreloadableRoute[];

@NgModule({
  imports: [
    RouterModule.forRoot([], {
      preloadingStrategy: AppPreloadingStrategy,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
  providers: [
    AppPreloadingStrategy,
    {
      provide: ROUTES,
      useFactory: (platformService: PlatformService) => (platformService.isServer ? SERVER_ROUTES : APP_ROUTES),
      deps: [PlatformService],
      multi: true,
    },
  ],
})
export class AppRoutingModule {}
