/* istanbul ignore file */

import { Injectable } from '@angular/core';

import { WindowService } from './ssr/window.service';

@Injectable({ providedIn: 'root' })
export class SafeAreaInsetsService {
  constructor(private windowService: WindowService) {}

  public getSafeAreaInsetTop(): number {
    return this.convertSafeAreaInsetStringToNumber('--js-safe-area-inset-top');
  }

  public getSafeAreaInsetBottom(): number {
    return this.convertSafeAreaInsetStringToNumber('--js-safe-area-inset-bottom');
  }

  public getSafeAreaInsetLeft(): number {
    return this.convertSafeAreaInsetStringToNumber('--js-safe-area-inset-left');
  }

  public getSafeAreaInsetRight(): number {
    return this.convertSafeAreaInsetStringToNumber('--js-safe-area-inset-right');
  }

  private convertSafeAreaInsetStringToNumber(safeAreaInsetName: string): number {
    if (!this.windowService.isWindowDefined) {
      return 0;
    }

    let safeAreaInset = getComputedStyle(this.windowService.window.document.documentElement).getPropertyValue(safeAreaInsetName);

    if (!safeAreaInset) {
      return 0;
    }

    safeAreaInset = safeAreaInset.trim();

    if (safeAreaInset.includes('px')) {
      safeAreaInset = safeAreaInset.replace('px', '');
    }

    return +safeAreaInset;
  }
}
