import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { from, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { Address } from '../models/address.model';
import { COUNTRY_CODES } from '../models/country.model';
import { LANGUAGE_CODES } from '../models/language.model';

import { AccountService } from '../services/account.service';
import { AddressService } from '../services/address.service';
import { AreasService } from '../services/areas.service';
import { CartService } from '../services/cart.service';
import { OverlayService } from '../services/overlay.service';
import { SettingsService } from '../services/settings.service';
import { VendorsService } from '../services/vendors.service';

import { AppReadyGuard } from './app-ready.guard';

import { toPromise } from '../operators/to-promise';

interface UpdatedCountryLanguageDetails {
  newCountryCode: string;
  newLanguageCode: string;
  changedCountry: boolean;
  changedLanguage: boolean;
}

@Injectable({ providedIn: 'root' })
export class CountryLanguageGuard implements CanActivate {
  constructor(
    private router: Router,
    private appReadyGuard: AppReadyGuard,
    private cartService: CartService,
    private areasService: AreasService,
    private vendorsService: VendorsService,
    private addressService: AddressService,
    private accountService: AccountService,
    private overlayService: OverlayService,
    private settingsService: SettingsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.appReadyGuard.canActivate().pipe(
      filter((canContinue) => !!canContinue),
      switchMap(() => this.parseAndValidateCountryLanguageCodes(state.url, route)),
    );
  }

  private parseAndValidateCountryLanguageCodes(url: string, route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const currentOrDefaultCountryCode = this.settingsService.getCountry().code;
    const currentOrDefaultLanguageCode = this.settingsService.getLanguage().value;

    if (url === '/') {
      return of(this.router.createUrlTree([`/${currentOrDefaultCountryCode}/${currentOrDefaultLanguageCode}`]));
    }

    let newCountryCode = route.paramMap.get('country');
    let newLanguageCode = route.paramMap.get('language');

    // If the country/language entered is invalid, we should not only select a
    // default value for that country/language but also update the URL
    const countryOrLanguageWasInvalid = !this.validateCountryLanguage(newCountryCode, newLanguageCode);

    if (countryOrLanguageWasInvalid) {
      newCountryCode = currentOrDefaultCountryCode;
      newLanguageCode = currentOrDefaultLanguageCode;
    }

    const changedCountry = newCountryCode !== currentOrDefaultCountryCode;
    const changedLanguage = newLanguageCode !== currentOrDefaultLanguageCode;

    if (changedCountry || changedLanguage) {
      return from(this.applyCountryLanguageSettings({ newCountryCode, newLanguageCode, changedCountry, changedLanguage })).pipe(
        map(() =>
          countryOrLanguageWasInvalid
            ? this.router.createUrlTree([`/${currentOrDefaultCountryCode}/${currentOrDefaultLanguageCode}`])
            : true,
        ),
      );
    }

    return of(
      countryOrLanguageWasInvalid ? this.router.createUrlTree([`/${currentOrDefaultCountryCode}/${currentOrDefaultLanguageCode}`]) : true,
    );
  }

  private applyCountryLanguageSettings({
    newCountryCode,
    newLanguageCode,
    changedCountry,
    changedLanguage,
  }: UpdatedCountryLanguageDetails): Promise<void> {
    const newCountry = this.settingsService.getAvailableCountries().find((country) => country.code === newCountryCode);
    const newLanguage = this.settingsService.getAvailableLanguages().find((language) => language.value === newLanguageCode);

    return this.overlayService.showLoading().then(() => {
      const prevSelectedAddressFromCart = this.cartService.getAddress();
      const prevSelectedAddressFromSearch = this.vendorsService.getAddressSearchFilters();

      // Changing the country would reinitialize the home screen
      // since we need to get the new list of areas/provinces
      const shouldUpdateSelectedLocationsLanguage = !changedCountry && changedLanguage;

      this.settingsService.changeCountryAndLanguage(newCountry, newLanguage);

      const getSelectedLocationsInNewLanguage = shouldUpdateSelectedLocationsLanguage
        ? Promise.all([
            prevSelectedAddressFromSearch
              ? this.getSelectedLocationInNewLanguage(prevSelectedAddressFromSearch)
              : Promise.resolve(null as Address),
            prevSelectedAddressFromCart
              ? this.getSelectedLocationInNewLanguage(prevSelectedAddressFromCart)
              : Promise.resolve(null as Address),
          ])
        : Promise.resolve([null as Address, null as Address]);

      return getSelectedLocationsInNewLanguage.then(([addressFromSearch, addressFromCart]) => {
        this.vendorsService.clearSearchFilters();
        this.cartService.clearCountryLanguageRelatedData();

        if (addressFromSearch) {
          this.vendorsService.setAddressSearchFilters(addressFromSearch);
        }

        if (addressFromCart) {
          this.cartService.updateOrderAddress(addressFromCart);
        }

        return this.overlayService.hideLoading().then(() => {});
      });
    });
  }

  private validateCountryLanguage(countryCode: string, languageCode: string): boolean {
    return (
      countryCode &&
      languageCode &&
      COUNTRY_CODES.some((code) => code === countryCode) &&
      LANGUAGE_CODES.some((code) => code === languageCode)
    );
  }

  private getSelectedLocationInNewLanguage(prevSelectedAddress: Address): Promise<Address> {
    return this.addressService.isNew(prevSelectedAddress)
      ? toPromise(this.areasService.getAreaById(prevSelectedAddress.areaId))
          .then((areaInNewLanguage) => this.addressService.updateAreaFromAddress(prevSelectedAddress, areaInNewLanguage))
          .catch(() => prevSelectedAddress)
      : toPromise(this.accountService.getUserSavedAddressById(prevSelectedAddress.savedAddressId))
          .then((addressInNewLanguage) => addressInNewLanguage)
          .catch(() => prevSelectedAddress);
  }
}
