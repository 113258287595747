import { Injectable } from '@angular/core';

import { EnvironmentName } from '../../enums/environment-name.enum';

import { User } from '../../models/user.model';

import { AppService } from '../app.service';
import { EnvironmentService } from '../environment.service';
import { PlatformService } from '../ssr/platform.service';

import * as Sentry from 'sentry-cordova';

@Injectable({ providedIn: 'root' })
export class SentryPlugin {
  constructor(private appService: AppService, private platformService: PlatformService, private environmentService: EnvironmentService) {}

  public initialize(): void {
    const appVersion = this.appService.getAppVersion();
    const platform = this.platformService.applicationPlatformName;

    Sentry.init({
      dsn: this.environmentService.sentryDSN,
      debug: this.environmentService.environmentName !== EnvironmentName.Production,
      enabled: this.environmentService.environmentName !== EnvironmentName.Development,
      environment: this.environmentService.environmentName,
      // Releases are global per organization; prefix them with something project-specific for easy differentiation.
      // https://docs.sentry.io/platforms/javascript/configuration/releases/
      release: `${this.environmentService.sentryProjectName}@${appVersion}`,
      tracesSampleRate: 1,
      attachStacktrace: true,
      // Enabling native crashes breaks the app so let's keep using Crashlitycs for that
      enableNative: false,
    });

    Sentry.setTags({ platform });
  }

  public info(message: string, details: Record<string, unknown>): void {
    try {
      Sentry.captureEvent({ message, level: 'info', extra: { ...(details || {}) } });
    } catch (e) {}
  }

  public error(error: Error, details: Record<string, unknown>): void {
    try {
      Sentry.captureException(error, { level: 'error', extra: { ...(details || {}) } });
    } catch (e) {}
  }

  public identifyUser(user: User): void {
    Sentry.setUser({ id: user.userId, email: user.email });
  }

  public resetUser(): void {
    Sentry.setUser(null);
  }
}
