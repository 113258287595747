import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

import { LoggerService } from '../logger.service';
import { OverlayService } from '../overlay.service';
import { PlatformService } from '../ssr/platform.service';

import { ClipboardPlugin } from './clipboard.plugin';

@Injectable({ providedIn: 'root' })
export class SocialSharingPlugin {
  constructor(
    private socialSharing: SocialSharing,
    private clipboardPlugin: ClipboardPlugin,
    private platformService: PlatformService,
    private overlayService: OverlayService,
    private translateService: TranslateService,
    private loggerService: LoggerService,
  ) {}

  public canShareViaEmail(): Promise<boolean> {
    return this.socialSharing
      .canShareViaEmail()
      .then(() => true)
      .catch((error: unknown) => {
        this.loggerService.info({
          component: 'SocialSharingPlugin',
          message: "couldn't share via email",
          details: { error },
          fromPlugin: true,
        });
        return false;
      });
  }

  public share(message: string): Promise<void> {
    const share = this.platformService.isBrowser
      ? this.clipboardPlugin.copy(message).then((ok) =>
          this.overlayService.showToast({
            showCloseButton: true,
            type: ok ? 'success' : 'error',
            message: this.translateService.instant(ok ? 'CLIPBOARD_COPY_SUCCESS' : 'CLIPBOARD_COPY_ERROR') as string,
          }),
        )
      : this.socialSharing.share(message);

    return share
      .then(() => {})
      .catch((error: unknown) => {
        this.loggerService.info({
          component: 'SocialSharingPlugin',
          message: "couldn't share message",
          details: { error },
          fromPlugin: true,
        });
      });
  }
}
