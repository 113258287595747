import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

import { PlatformService } from '../ssr/platform.service';

class BrowserSocialSharingProvider extends SocialSharing {
  public canShareViaEmail(): Promise<void> {
    return Promise.resolve();
  }

  public share(_message: string, _image?: string, _url?: string): Promise<boolean> {
    return Promise.resolve(true);
  }
}

class MobileSocialSharingProvider extends SocialSharing {}

function socialSharingFactory(platformService: PlatformService): SocialSharing {
  return platformService.areCordovaPluginsAvailable ? new MobileSocialSharingProvider() : new BrowserSocialSharingProvider();
}

export const socialSharingProvider = { provide: SocialSharing, useFactory: socialSharingFactory, deps: [PlatformService] };
