import { group, query, transition, trigger } from '@angular/animations';

import {
  AnimationTimingFunction,
  AnimationTransition,
  applyFadeInAnimation,
  applyFadeInTranslateAnimation,
  applyFadeOutCollapseHeightAnimation,
  applyFadeOutTranslateAnimation,
} from '../../../core/animations';

export const CartDetailsComponentAnimations = [
  trigger('animatedEmptyMessage', [
    transition(AnimationTransition.VoidToAny, applyFadeInTranslateAnimation({ duration: 350, distance: '50px' })),
  ]),
  trigger('animatedCartContent', [
    transition(AnimationTransition.VoidToAny, [
      group([
        query('.animated-cart-vendors', applyFadeInAnimation({ duration: 350 }), { optional: true }),
        query('.animated-cart-totals', applyFadeInTranslateAnimation({ duration: 350, distance: '50px' }), { optional: true }),
      ]),
    ]),
    transition(AnimationTransition.AnyToVoid, [
      group([
        query('.animated-cart-vendors', applyFadeOutTranslateAnimation({ duration: 350, distance: '-50px' }), { optional: true }),
        query('.animated-cart-totals', applyFadeOutTranslateAnimation({ duration: 350, distance: '50px' }), { optional: true }),
      ]),
    ]),
  ]),
  trigger('animatedCartVendor', [
    transition(
      AnimationTransition.AnyToVoid,
      applyFadeOutCollapseHeightAnimation({ duration: 350, timingFunction: AnimationTimingFunction.Ease }),
    ),
  ]),
  trigger('animatedCartItem', [
    transition(
      AnimationTransition.AnyToVoid,
      applyFadeOutCollapseHeightAnimation({ duration: 350, timingFunction: AnimationTimingFunction.Ease }),
    ),
  ]),
  trigger('animatedTrashIcon', [
    transition(AnimationTransition.VoidToAny, applyFadeInTranslateAnimation({ duration: 350, distance: '35px', axis: 'X' })),
    transition(AnimationTransition.AnyToVoid, applyFadeOutTranslateAnimation({ duration: 350, distance: '35px', axis: 'X' })),
  ]),
];
