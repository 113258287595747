import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { SavedAddressesComponent } from './saved-addresses.component';

import { AddressDetailsComponentModule } from '../address-details/address-details.module';

@NgModule({
  declarations: [SavedAddressesComponent],
  imports: [CommonModule, IonicModule, AddressDetailsComponentModule, TranslateModule.forChild()],
  exports: [SavedAddressesComponent],
})
export class SavedAddressesComponentModule {}
