import { query, transition, trigger } from '@angular/animations';

import { AnimationTransition, applyFadeInTranslateStaggerAnimation } from '../../core/animations';

export const GoogleMapsAutocompleteModalPageAnimations = [
  trigger('animatedResults', [
    transition(AnimationTransition.AnyToAny, [
      query(AnimationTransition.Enter, applyFadeInTranslateStaggerAnimation({ duration: 300, stagger: 100, distance: '10px' }), {
        optional: true,
      }),
    ]),
  ]),
];
