import { PipesModule } from './pipes/pipes.module';

import { AreaDateTimePickerComponentModule } from './components/area-date-time-picker/area-date-time-picker.module';
import { ToastComponentModule } from './components/toast/toast.module';

const pipes = [PipesModule];

const components = [ToastComponentModule, AreaDateTimePickerComponentModule];

export default [...pipes, ...components];
