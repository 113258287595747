import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';

import { Observable, of } from 'rxjs';

import { PlatformService } from '../ssr/platform.service';

class BrowserKeyboardProvider extends Keyboard {
  public hideFormAccessoryBar(_hide: boolean): void {}

  public show(): void {}

  public close(): void {}

  public disableScroll(_disable: boolean): void {}

  public onKeyboardShow(): Observable<boolean> {
    return of(true);
  }

  public onKeyboardHide(): Observable<boolean> {
    return of(true);
  }
}

class MobileKeyboardProvider extends Keyboard {}

function keyboardFactory(platformService: PlatformService): Keyboard {
  return platformService.areCordovaPluginsAvailable ? new MobileKeyboardProvider() : new BrowserKeyboardProvider();
}

export const keyboardProvider = { provide: Keyboard, useFactory: keyboardFactory, deps: [PlatformService] };
