import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { DimensionRequirementsModalPage } from './dimension-requirements-modal.page';

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule.forChild()],
  declarations: [DimensionRequirementsModalPage],
})
export class DimensionRequirementsModalPageModule {}
