import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

import { getAnimationString } from './helpers';

import { AnimationDetails, AnimationState, AnimationTransition } from './models';

interface FadeInExpandCollapseAnimationParams {
  expandAnimationDetails?: AnimationDetails;
  collapseAnimationDetails?: AnimationDetails;
}

interface FadeInAnimationParams {
  visibleToHiddenAnimationDetails?: AnimationDetails;
  hiddenToVisibleAnimationDetails?: AnimationDetails;
}

interface FadeInTranslateAnimationParams extends FadeInAnimationParams {
  distance: string;
}

const defaultValues = {
  expandAnimationDuration: 600,
  collapseAnimationDuration: 400,
  fadeInTranslateYAnimationDuration: 600,
  fadeOutTranslateYAnimationDuration: 400,
  fadeInAnimationDuration: 600,
  fadeOutAnimationDuration: 400,
};

export function triggerFadeInExpandCollapseStatefulAnimation(
  name: string,
  params?: FadeInExpandCollapseAnimationParams,
): AnimationTriggerMetadata {
  return trigger(name, [
    state(AnimationState.Expanded, style({ height: '*', opacity: 1 })),
    state(AnimationState.Collapsed, style({ height: '0', opacity: 0 })),
    transition(AnimationTransition.CollapsedToExpanded, [
      animate(
        getAnimationString(
          params?.expandAnimationDetails || {
            duration: defaultValues.expandAnimationDuration,
          },
        ),
      ),
    ]),
    transition(AnimationTransition.ExpandedToCollapsed, [
      animate(
        getAnimationString(
          params?.collapseAnimationDetails || {
            duration: defaultValues.collapseAnimationDuration,
          },
        ),
      ),
    ]),
  ]);
}

export function triggerFadeInTranslateYStatefulAnimation(name: string, params: FadeInTranslateAnimationParams): AnimationTriggerMetadata {
  return trigger(name, [
    state(AnimationState.Hidden, style({ opacity: 0, transform: `translateY(${params.distance})` })),
    state(AnimationState.Visible, style({ opacity: 1, transform: 'translateY(0)' })),
    transition(AnimationTransition.HiddenToVisible, [
      animate(
        getAnimationString(
          params.hiddenToVisibleAnimationDetails || {
            duration: defaultValues.fadeOutTranslateYAnimationDuration,
          },
        ),
      ),
    ]),
    transition(AnimationTransition.VisibleToHidden, [
      animate(
        getAnimationString(
          params.visibleToHiddenAnimationDetails || {
            duration: defaultValues.fadeInTranslateYAnimationDuration,
          },
        ),
      ),
    ]),
  ]);
}

export function triggerFadeInStatefulAnimation(name: string, params: FadeInAnimationParams): AnimationTriggerMetadata {
  return trigger(name, [
    state(AnimationState.Hidden, style({ opacity: 0 })),
    state(AnimationState.Visible, style({ opacity: 1 })),
    transition(AnimationTransition.HiddenToVisible, [
      animate(
        getAnimationString(
          params?.hiddenToVisibleAnimationDetails || {
            duration: defaultValues.fadeOutAnimationDuration,
          },
        ),
      ),
    ]),
    transition(AnimationTransition.VisibleToHidden, [
      animate(
        getAnimationString(
          params?.visibleToHiddenAnimationDetails || {
            duration: defaultValues.fadeInAnimationDuration,
          },
        ),
      ),
    ]),
  ]);
}
