import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';

import { IonInput, ViewDidEnter, ViewWillLeave } from '@ionic/angular';

import { Subject, Subscription } from 'rxjs';

import { ModalService } from '../../core/services/modal.service';
import { PlatformService } from '../../core/services/ssr/platform.service';

import { BACK_BUTTON_HANDLER_PRIORITY } from '../../core/config/app.config';

export const EditWishlistModalPageIdentifier = 'edit-wishlist-modal';

@Component({
  selector: 'app-edit-wishlist-modal',
  templateUrl: './edit-wishlist-modal.page.html',
  styleUrls: ['./edit-wishlist-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditWishlistModalPage implements OnInit, ViewDidEnter, ViewWillLeave, OnDestroy {
  @ViewChild('nameInputElement')
  public nameInputElement: IonInput;

  @Input()
  public currentName: string;

  public wishlistForm: FormGroup<{ name: FormControl<string> }>;
  public submitAttempt: boolean;

  private unregisterBackButtonSubscription: Subscription;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: NonNullableFormBuilder, private modalService: ModalService, private platformService: PlatformService) {}

  ngOnInit() {
    this.initializeForm();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  ionViewDidEnter() {
    this.focusNameField();
    this.unregisterBackButtonSubscription = this.platformService.backButton.subscribeWithPriority(BACK_BUTTON_HANDLER_PRIORITY, () =>
      this.dismiss(),
    );
  }

  ionViewWillLeave() {
    this.unregisterBackButtonSubscription?.unsubscribe();
  }

  public onDismiss(): void {
    this.dismiss();
  }

  public onSubmit(): void {
    this.submitAttempt = true;

    if (this.wishlistForm.valid) {
      this.dismiss(this.wishlistForm.getRawValue().name);
    }
  }

  private initializeForm(): void {
    this.submitAttempt = false;
    this.wishlistForm = this.formBuilder.group({ name: [this.currentName ?? '', [Validators.required]] });
  }

  private focusNameField(): void {
    void this.platformService.wait(50).then(() => this.nameInputElement?.setFocus());
  }

  private dismiss(newName?: string): void {
    void this.modalService.dismissModal({ id: EditWishlistModalPageIdentifier, data: newName });
  }
}
