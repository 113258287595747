import { Animation, createAnimation } from '@ionic/core';

import { WindowRef } from '../services/ssr/window.service';

export interface ModalAnimationOptions {
  presentingEl?: HTMLElement;
  currentBreakpoint?: number;
  backdropBreakpoint?: number;
}

export const getElementRoot = (el: HTMLElement, fallback: HTMLElement = el): HTMLElement =>
  (el.shadowRoot || fallback) as unknown as HTMLElement;

export const browserModalEnterAnimation = (baseEl: HTMLElement, _opts: ModalAnimationOptions): Animation => {
  const root = getElementRoot(baseEl);

  const backdropAnimation = createAnimation()
    .fromTo('opacity', 0.01, 'var(--backdrop-opacity)')
    .beforeStyles({ 'pointer-events': 'none' })
    .afterClearStyles(['pointer-events']);

  const wrapperAnimation = createAnimation().keyframes([
    { offset: 0, opacity: 0.01, transform: 'translateY(40px)' },
    { offset: 1, opacity: 1, transform: 'translateY(0px)' },
  ]);

  backdropAnimation.addElement(root.querySelector('ion-backdrop') ?? undefined);
  wrapperAnimation.addElement(root.querySelector('.modal-wrapper') ?? undefined);

  return createAnimation()
    .addElement(baseEl)
    .easing('cubic-bezier(0.36,0.66,0.04,1)')
    .duration(380)
    .addAnimation([backdropAnimation, wrapperAnimation]);
};

export const browserModalLeaveAnimation = (baseEl: HTMLElement, _opts: ModalAnimationOptions): Animation => {
  const root = getElementRoot(baseEl);
  const backdropAnimation = createAnimation().fromTo('opacity', 'var(--backdrop-opacity)', 0);

  const wrapperAnimation = createAnimation().keyframes([
    { offset: 0, opacity: 0.99, transform: 'translateY(0px)' },
    { offset: 1, opacity: 0, transform: 'translateY(40px)' },
  ]);

  backdropAnimation.addElement(root.querySelector('ion-backdrop') ?? undefined);
  wrapperAnimation.addElement(root.querySelector('.modal-wrapper') ?? undefined);

  return createAnimation().easing('cubic-bezier(0.47,0,0.745,0.715)').duration(200).addAnimation([backdropAnimation, wrapperAnimation]);
};

export const browserAlertModalEnterAnimation = (baseEl: HTMLElement): Animation => {
  if (!baseEl) {
    return createAnimation();
  }

  const backdropAnimation = createAnimation();
  const wrapperAnimation = createAnimation();
  const modalContentAnimation = createAnimation();

  let backdropEl = baseEl.querySelector('ion-backdrop');
  const wrapperEl = baseEl.querySelectorAll('.modal-wrapper, .modal-show');
  const contentEl = baseEl.querySelector('.modal-content');

  if (!backdropEl) {
    const root = getElementRoot(baseEl);
    backdropEl = root?.querySelector('ion-backdrop') ?? undefined;
  }

  if (backdropEl) {
    backdropAnimation
      .addElement(backdropEl)
      .fromTo('opacity', 0.01, 'var(--backdrop-opacity)')
      .beforeStyles({ 'pointer-events': 'none' })
      .afterClearStyles(['pointer-events']);
  }

  if (wrapperEl) {
    wrapperAnimation.addElement(wrapperEl).beforeStyles({
      opacity: 1,
      transform: 'translateY(0vh)',
    });
  }

  if (contentEl) {
    modalContentAnimation.addElement(contentEl).keyframes([
      { offset: 0, opacity: 0.01, transform: 'translateY(40px)' },
      { offset: 1, opacity: 1, transform: 'translateY(0px)' },
    ]);
  }

  const baseAnimation = createAnimation()
    .addElement(baseEl)
    .easing('cubic-bezier(0.36,0.66,0.04,1)')
    .duration(380)
    .addAnimation([backdropAnimation, wrapperAnimation, modalContentAnimation]);

  return baseAnimation;
};

export const browserAlertModalLeaveAnimation = (baseEl: HTMLElement): Animation => {
  if (!baseEl) {
    return createAnimation();
  }

  const baseAnimation = createAnimation();
  const backdropAnimation = createAnimation();
  const modalContentAnimation = createAnimation();

  let backdropEl = baseEl.querySelector('ion-backdrop');
  const contentEl = baseEl.querySelector('.modal-content');

  if (!backdropEl) {
    const root = getElementRoot(baseEl);
    backdropEl = root?.querySelector('ion-backdrop') ?? undefined;
  }

  if (backdropEl) {
    backdropAnimation.addElement(backdropEl).fromTo('opacity', 'var(--backdrop-opacity)', 0);
  }

  if (contentEl) {
    modalContentAnimation.addElement(contentEl).keyframes([
      { offset: 0, opacity: 0.99, transform: 'translateY(0px)' },
      { offset: 1, opacity: 0, transform: 'translateY(40px)' },
    ]);
  }

  return baseAnimation
    .addElement(baseEl)
    .easing('cubic-bezier(0.47,0,0.745,0.715)')
    .duration(200)
    .addAnimation([backdropAnimation, modalContentAnimation]);
};

export const browserCartModalEnterAnimation = (windowRef: WindowRef, baseEl: HTMLElement, _opts: ModalAnimationOptions): Animation => {
  const root = getElementRoot(baseEl);

  const backdropAnimation = createAnimation()
    .fromTo('opacity', 0.01, 'var(--backdrop-opacity)')
    .beforeStyles({ 'pointer-events': 'none' })
    .afterClearStyles(['pointer-events']);

  const isLtr = windowRef.document ? windowRef.document.dir !== 'rtl' : true;

  const wrapperAnimation = isLtr
    ? createAnimation().keyframes([
        { offset: 0, opacity: 0.01, transform: 'translateX(100%)' },
        { offset: 1, opacity: 1, transform: 'translateX(0px)' },
      ])
    : createAnimation().keyframes([
        { offset: 0, opacity: 0.01, transform: 'translateX(-100%)' },
        { offset: 1, opacity: 1, transform: 'translateY(0px)' },
      ]);

  backdropAnimation.addElement(root.querySelector('ion-backdrop') ?? undefined);
  wrapperAnimation.addElement(root.querySelector('.modal-wrapper') ?? undefined);

  return createAnimation()
    .addElement(baseEl)
    .easing('cubic-bezier(0.36,0.66,0.04,1)')
    .duration(440)
    .addAnimation([backdropAnimation, wrapperAnimation]);
};

export const browserCartModalLeaveAnimation = (windowRef: WindowRef, baseEl: HTMLElement, _opts: ModalAnimationOptions): Animation => {
  const root = getElementRoot(baseEl);
  const backdropAnimation = createAnimation().fromTo('opacity', 'var(--backdrop-opacity)', 0);

  const isLtr = windowRef.document ? windowRef.document.dir !== 'rtl' : true;

  const wrapperAnimation = isLtr
    ? createAnimation().keyframes([
        { offset: 0, opacity: 0.99, transform: 'translateX(0px)' },
        { offset: 1, opacity: 0, transform: 'translateX(100%)' },
      ])
    : createAnimation().keyframes([
        { offset: 0, opacity: 0.99, transform: 'translateX(0px)' },
        { offset: 1, opacity: 0, transform: 'translateX(-100%)' },
      ]);

  backdropAnimation.addElement(root.querySelector('ion-backdrop') ?? undefined);
  wrapperAnimation.addElement(root.querySelector('.modal-wrapper') ?? undefined);

  return createAnimation().easing('cubic-bezier(0.47,0,0.745,0.715)').duration(340).addAnimation([backdropAnimation, wrapperAnimation]);
};
