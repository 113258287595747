import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { ModalService } from '../../core/services/modal.service';

import { Helpers } from '../../core/helpers';

export const DimensionRequirementsModalPageIdentifier = 'dimension-requirements-modal';

export interface DimensionRequirementsModalPageParams {
  width?: number;
  depth?: number;
  height?: number;
  dimensionsDescription: string;
}

@Component({
  selector: 'app-dimension-requirements-modal',
  templateUrl: './dimension-requirements-modal.page.html',
  styleUrls: ['./dimension-requirements-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DimensionRequirementsModalPage implements OnInit {
  @Input()
  public params: DimensionRequirementsModalPageParams;

  public width?: string;
  public depth?: string;
  public height?: string;
  public imageUrl: string;
  public description: string;

  public shouldRotateImage: boolean;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    if (this.params) {
      this.width = this.params.width ? Helpers.formatDecimalNumberWithPrecision(this.params.width, 1) : null;
      this.depth = this.params.depth ? Helpers.formatDecimalNumberWithPrecision(this.params.depth, 1) : null;
      this.height = this.params.height ? Helpers.formatDecimalNumberWithPrecision(this.params.height, 1) : null;
      this.description = this.params.dimensionsDescription;
      this.shouldRotateImage = this.params.width && this.params.depth && this.params.depth > this.params.width;
      this.imageUrl = this.getImageUrlBasedOnAvailableMeasures();
    }
  }

  public onDismiss(): Promise<boolean> {
    return this.modalService.dismissModal({ id: DimensionRequirementsModalPageIdentifier });
  }

  private getImageUrlBasedOnAvailableMeasures(): string {
    switch (true) {
      case !!this.width && !!this.height && !!this.depth && !this.shouldRotateImage:
        return 'dimensions-wdh.svg';
      case !!this.width && !!this.height && !!this.depth && !!this.shouldRotateImage:
        return 'dimensions-wdh-rotated.svg';
      case !!this.width && !!this.depth && !this.shouldRotateImage:
        return 'dimensions-wd.svg';
      case !!this.width && !!this.depth && !!this.shouldRotateImage:
        return 'dimensions-wd-rotated.svg';
      case !!this.width && !!this.height:
        return 'dimensions-wh.svg';
      case !!this.height && !!this.depth:
        return 'dimensions-dh.svg';
      case !!this.width:
        return 'dimensions-w.svg';
      case !!this.height:
        return 'dimensions-h.svg';
      case !!this.depth:
        return 'dimensions-d.svg';
    }
  }
}
