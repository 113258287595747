import { OperatorFunction, pipe } from 'rxjs';

import { AppState } from '../models/app-state.model';

import { filterFalsy } from './filter-falsy';
import { select, SelectorConfig } from './select';

export function selectTruthy<T extends AppState, R>(mapFn: (state: T) => R, config?: SelectorConfig<R>): OperatorFunction<T, R> {
  return pipe(select(mapFn, config ? config : undefined), filterFalsy());
}
