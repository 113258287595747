import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { PipesModule } from '../../pipes/pipes.module';

import { CartDetailsComponent } from './cart-details.component';

import { AnimatedImageComponentModule } from '../animated-image/animated-image.module';

@NgModule({
  declarations: [CartDetailsComponent],
  imports: [CommonModule, IonicModule, TranslateModule.forChild(), PipesModule, AnimatedImageComponentModule],
  exports: [CartDetailsComponent],
})
export class CartDetailsComponentModule {}
