import { APP_INITIALIZER } from '@angular/core';

import { BootstrapService } from './services/bootstrap.service';

function appInitializerFactory(bootstrapService: BootstrapService): () => Promise<boolean> {
  return () => bootstrapService.initializeApp();
}

export const appInitializerProvider = {
  provide: APP_INITIALIZER,
  useFactory: appInitializerFactory,
  deps: [BootstrapService],
  multi: true,
};
