import { Component, ViewEncapsulation } from '@angular/core';

import { DiagnosticPlugin } from '../../core/services/native-plugins/diagnostic.plugin';

import { ModalService } from '../../core/services/modal.service';

export const PushNotificationsDisabledModalPageIdentifier = 'push-notifications-disabled-modal';

@Component({
  selector: 'app-push-notifications-disabled-modal',
  templateUrl: './push-notifications-disabled-modal.page.html',
  styleUrls: ['./push-notifications-disabled-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PushNotificationsDisabledModalPage {
  constructor(private diagnosticPlugin: DiagnosticPlugin, private modalService: ModalService) {}

  public onOpenPushNotificationSettings(): void {
    void this.diagnosticPlugin.switchToSettings().then(() => this.onDismiss());
  }

  public onDismiss(): void {
    void this.modalService.dismissModal({ id: PushNotificationsDisabledModalPageIdentifier });
  }
}
