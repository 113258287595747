<div class="modal-container">
  <div class="modal-content">
    <div class="header-section">
      <div class="illustration-container">
        <span tappable (click)="onClose()" class="close-button">
          <ion-icon slot="icon-only" src="/assets/custom-icons/b-times.svg"></ion-icon>
        </span>
        <div class="illustration"></div>
      </div>
    </div>

    <div class="details-section">
      <h1 class="title">{{ "AFFILIATE_PROGRAM.TITLE" | translate }}</h1>
      <p class="description" [innerHTML]="'AFFILIATE_PROGRAM.DESCRIPTION' | translate: { value: credits | localCurrency }"></p>
    </div>

    <div class="footer-section">
      <ion-button expand="full" (click)="onShareLink()" color="green">{{ "AFFILIATE_PROGRAM.BUTTON_TEXT" | translate }}</ion-button>
    </div>
  </div>
</div>
