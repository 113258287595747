import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';

import { PlatformService } from '../ssr/platform.service';

class BrowserLocationAccuracyProvider extends LocationAccuracy {
  public canRequest(): Promise<boolean> {
    return Promise.resolve(true);
  }

  public request(_accuracy: number): Promise<unknown> {
    return Promise.resolve(true);
  }
}

class MobileLocationAccuracyProvider extends LocationAccuracy {}

function locationAccuracyFactory(platformService: PlatformService): LocationAccuracy {
  return platformService.areCordovaPluginsAvailable ? new MobileLocationAccuracyProvider() : new BrowserLocationAccuracyProvider();
}

export const locationAccuracyProvider = { provide: LocationAccuracy, useFactory: locationAccuracyFactory, deps: [PlatformService] };
