import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AnalyticsInterceptor } from './analytics-interceptor';
import { AuthInterceptor } from './auth-interceptor';
import { FullUrlInterceptor } from './full-url-interceptor';
import { HealthCheckInterceptor } from './health-check-interceptor';
import { LangInterceptor } from './lang-interceptor';
import { PlatformTypeInterceptor } from './platform-type-interceptor';
import { ResponseTimingInterceptor } from './response-timing.interceptor';
import { SearchOptionsInterceptor } from './search-options-interceptor';
import { TimeoutInterceptor } from './timeout-interceptor';
import { UpgradeInterceptor } from './upgrade-interceptor';

export default [
  { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: PlatformTypeInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: FullUrlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AnalyticsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: SearchOptionsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HealthCheckInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: UpgradeInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ResponseTimingInterceptor, multi: true },
];
