import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { ItemAvailabilityDateTimePickerModalPage } from './item-availability-date-time-picker-modal.page';

import { PipesModule } from '../../shared/pipes/pipes.module';

@NgModule({
  declarations: [ItemAvailabilityDateTimePickerModalPage],
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule.forChild(), PipesModule],
  exports: [ItemAvailabilityDateTimePickerModalPage],
})
export class ItemAvailabilityDateTimePickerModalPageModule {}
