<ion-header class="ion-no-border white-header">
  <ion-toolbar fixed>
    <ion-buttons slot="start">
      <ion-button (click)="onDismiss()">
        <ion-icon src="/assets/custom-icons/b-times.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ng-container *ngIf="vendorRatings?.length">
        {{ "ORDER_REVIEW_MODAL_PAGE.REVIEW_FOR" | translate }} {{ vendorRatings[sliderActiveIndex]?.vendorName }}
      </ng-container>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <swiper #swiper class="rating-slider" [dir]="sliderScrollingDirection" [allowTouchMove]="false">
    <ng-template swiperSlide class="vendor-rating" *ngFor="let vendorRating of vendorRatings">
      <div class="rating">
        <span class="rating-name" [class.with-error]="vendorRating.submitted && !vendorRating.service">
          {{ "RATINGS.SERVICE" | translate }}
        </span>
        <app-editable-rating class="rating-stars" (changeRating)="vendorRating.service = $event"></app-editable-rating>
      </div>

      <div class="rating">
        <span class="rating-name" [class.with-error]="vendorRating.submitted && !vendorRating.products">
          {{ "RATINGS.PRODUCTS" | translate }}
        </span>
        <app-editable-rating class="rating-stars" (changeRating)="vendorRating.products = $event"></app-editable-rating>
      </div>

      <div class="rating">
        <span class="rating-name" [class.with-error]="vendorRating.submitted && !vendorRating.timeliness">
          {{ "RATINGS.TIMELINESS" | translate }}
        </span>
        <app-editable-rating class="rating-stars" (changeRating)="vendorRating.timeliness = $event"></app-editable-rating>
      </div>

      <div class="rating">
        <span class="rating-name" [class.with-error]="vendorRating.submitted && !vendorRating.service">
          {{ "RATINGS.PRESENTATION" | translate }}
        </span>
        <app-editable-rating class="rating-stars" (changeRating)="vendorRating.presentation = $event"></app-editable-rating>
      </div>

      <div class="rating">
        <span class="rating-name" [class.with-error]="vendorRating.submitted && !vendorRating.overall">
          {{ "RATINGS.OVERALL" | translate }}
        </span>
        <app-editable-rating class="rating-stars" (changeRating)="vendorRating.overall = $event"></app-editable-rating>
      </div>

      <textarea
        rows="5"
        class="review"
        [(ngModel)]="vendorRating.review"
        [placeholder]="'ORDER_REVIEW_MODAL_PAGE.REVIEW_PLACEHOLDER' | translate"
      ></textarea>
    </ng-template>
  </swiper>
</ion-content>
<ion-footer *ngIf="vendorRatings?.length">
  <ion-toolbar fixed>
    <div class="footer-inline-buttons">
      <ion-button *ngIf="vendorRatings.length > 1" (click)="onGoBack()" expand="block" class="custom-button go-back-button" color="grey">
        {{ "BACK" | translate }}
      </ion-button>

      <ion-button (click)="onContinue()" expand="block" class="custom-button continue-button" color="green">
        <ng-container *ngIf="isLastVendor">
          {{ "SUBMIT" | translate }}
        </ng-container>
        <ng-container *ngIf="!isLastVendor">
          {{ "ORDER_REVIEW_MODAL_PAGE.SAVE_AND_CONTINUE" | translate }}
        </ng-container>
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>
