import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';

import { Toast } from '../../../core/models/toast.model';

import { OverlayService } from '../../../core/services/overlay.service';
import { PlatformService } from '../../../core/services/ssr/platform.service';
import { WindowService } from '../../../core/services/ssr/window.service';

import { TOAST_ENTER, TOAST_LEAVE, ToastComponentAnimations } from './toast.animations';

const TOAST_DEFAULT_DURATION = 5_000;
const DEFAULT_HEADER_HEIGHT = 44;

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [...ToastComponentAnimations],
  encapsulation: ViewEncapsulation.None,
})
export class ToastComponent implements OnInit {
  public headerHeight: string;
  public toast: Toast;

  public useFullWidthToastAnimation: boolean;

  private isInTransition: boolean;
  private toastTimerId: number;

  constructor(
    private elementRef: ElementRef,
    private overlayService: OverlayService,
    private windowService: WindowService,
    private platformService: PlatformService,
  ) {}

  ngOnInit() {
    this.useFullWidthToastAnimation = this.platformService.isCordova && !this.platformService.isCordovaTablet;
    this.overlayService.getToast().subscribe((toast) => {
      if (!toast) {
        this.dismiss();
        return;
      }

      if (this.isInTransition) {
        console.warn('Tried to show another toast when still transitioning the previous one');
        return;
      }

      if (this.toast?.message === toast.message) {
        console.warn(`Tried to show another toast with the same message: ${toast.message}`);
        return;
      }

      this.toast ? this.showNext(toast) : this.show(toast);
    });
  }

  public onClose(): void {
    if (this.toast.closeButtonCallback) {
      this.toast.closeButtonCallback();
    }

    this.dismiss();
  }

  private show(toast: Toast): void {
    if (!this.windowService.isWindowDefined) {
      return;
    }

    this.headerHeight = `${
      (this.elementRef.nativeElement as HTMLElement)?.parentElement
        ?.querySelector('ion-router-outlet > :not(.ion-page-hidden) > ion-header')
        ?.getBoundingClientRect()?.height || DEFAULT_HEADER_HEIGHT
    }px`;

    this.toast = toast;
    this.isInTransition = true;
    void this.platformService.wait(TOAST_ENTER).then(() => (this.isInTransition = false));
    this.toastTimerId = this.windowService.window.setTimeout(() => this.dismiss(), this.toast.duration ?? TOAST_DEFAULT_DURATION);
  }

  private showNext(toast: Toast): void {
    this.dismiss();
    void this.platformService.wait(TOAST_LEAVE).then(() => this.show(toast));
  }

  private dismiss(): void {
    if (!this.toast) {
      return;
    }

    if (this.toastTimerId) {
      clearTimeout(this.toastTimerId);
    }

    if (this.toast?.dismissCallback) {
      this.toast.dismissCallback();
    }

    this.toast = null;
    this.isInTransition = true;
    void this.platformService.wait(TOAST_LEAVE).then(() => (this.isInTransition = false));
  }
}
