import { Injectable } from '@angular/core';

import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';

@Injectable({ providedIn: 'root' })
export class SplashScreenPlugin {
  constructor(private splashScreen: SplashScreen) {}

  public hide(): void {
    this.splashScreen.hide();
  }

  public show(): void {
    this.splashScreen.show();
  }
}
