import { Component, Input, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { ViewDidEnter } from '@ionic/angular';

import { ModalService } from '../../core/services/modal.service';
import { SettingsService } from '../../core/services/settings.service';

import { ImagesPreviewModalPageAnimations } from './images-preview-modal.animations';

import SwiperCore, { Zoom } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Zoom]);

export const ImagesPreviewModalPageIdentifier = 'images-preview-modal';

@Component({
  selector: 'app-images-preview-modal',
  templateUrl: './images-preview-modal.page.html',
  styleUrls: ['./images-preview-modal.page.scss'],
  animations: [...ImagesPreviewModalPageAnimations],
  encapsulation: ViewEncapsulation.None,
})
export class ImagesPreviewModalPage implements OnInit, ViewDidEnter {
  @ViewChild('swiper')
  public swiper?: SwiperComponent;

  @Input()
  public imageUrls: Array<string>;

  @Input()
  public selectedImageIndex = 0;

  public currentIndex: number;
  public sliderScrollingDirection: string;

  constructor(private modalService: ModalService, private settingsService: SettingsService, private ngZone: NgZone) {}

  ngOnInit() {
    this.currentIndex = this.selectedImageIndex;
  }

  ionViewDidEnter(): void {
    this.sliderScrollingDirection = this.settingsService.getLanguageDirection();
    this.swiper.swiperRef.update();
    this.swiper.swiperRef.slideTo(this.currentIndex);
  }

  public onActiveIndexChange(): void {
    this.ngZone.run(() => (this.currentIndex = this.swiper.swiperRef.activeIndex));
  }

  public onClosePage(): Promise<boolean> {
    return this.modalService.dismissModal({ data: this.currentIndex, id: ImagesPreviewModalPageIdentifier });
  }
}
