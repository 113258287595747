import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';

import { TranslateLoader } from '@ngx-translate/core';

import { FilePlugin } from '../../services/native-plugins/file.plugin';

import { firstValueFrom, from, Observable } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';

import { AppService } from '../../services/app.service';
import { EnvironmentService, isCypressTestEnv } from '../../services/environment.service';
import { SettingsService } from '../../services/settings.service';
import { PlatformService } from '../../services/ssr/platform.service';

import { TRANSLATION_CDN_REQUEST_TIMEOUT_IN_MILLISECONDS } from '../../config/app.config';

export class TranslateBrowserLoader implements TranslateLoader {
  constructor(private injector: Injector) {}

  public getTranslation(lang: string): Observable<Record<string, string>> {
    const appService = this.injector.get(AppService);
    const environmentService = this.injector.get(EnvironmentService);
    const platformService = this.injector.get(PlatformService);
    const http = this.injector.get(HttpClient);
    const filePlugin = this.injector.get(FilePlugin);
    const countryCode = this.injector.get(SettingsService).getCountry().code;

    return platformService.areCordovaPluginsAvailable || isCypressTestEnv()
      ? loadRemoteTranslationsFile(appService, environmentService, platformService, http, filePlugin, countryCode, lang)
      : loadLocalTranslationsFile(appService, platformService, http, filePlugin, countryCode, lang);
  }
}

const loadLocalTranslationsFileForIos = (filePlugin: FilePlugin, countryCode: string, lang: string): Promise<Record<string, string>> => {
  const readFilePromise = filePlugin.readAsText(filePlugin.applicationDirectory, `www/assets/i18n/${countryCode}/${lang}.json`);
  return readFilePromise.then((data: string) => JSON.parse(data) as Record<string, string>);
};

const loadLocalTranslationsFileForBrowserOrAndroid = (
  http: HttpClient,
  countryCode: string,
  lang: string,
): Promise<Record<string, string>> => firstValueFrom(http.get<Record<string, string>>(`./assets/i18n/${countryCode}/${lang}.json`));

const loadLocalTranslationsFile = (
  appService: AppService,
  platformService: PlatformService,
  http: HttpClient,
  filePlugin: FilePlugin,
  countryCode: string,
  lang: string,
): Observable<Record<string, string>> | Observable<null> =>
  from(
    platformService
      .ready()
      .then(() =>
        platformService.areCordovaPluginsAvailable && platformService.isIos
          ? loadLocalTranslationsFileForIos(filePlugin, countryCode, lang)
          : loadLocalTranslationsFileForBrowserOrAndroid(http, countryCode, lang),
      ),
  ).pipe(
    tap(() => {
      appService.updateTranslationsLoadedStatus(true);
    }),
  );

const loadRemoteTranslationsFile = (
  appService: AppService,
  environmentService: EnvironmentService,
  platformService: PlatformService,
  http: HttpClient,
  filePlugin: FilePlugin,
  countryCode: string,
  lang: string,
): Observable<Record<string, string>> => {
  const appVersion = appService.getAppVersion();
  const translationsUrl = environmentService.translationsUrl;
  const translationsUrlForCurrentVersionAndCountry = `${translationsUrl}/${appVersion}/${countryCode}/${lang}.json`;

  return http.get<Record<string, string>>(translationsUrlForCurrentVersionAndCountry).pipe(
    timeout(TRANSLATION_CDN_REQUEST_TIMEOUT_IN_MILLISECONDS),
    tap(() => {
      appService.updateTranslationsLoadedStatus(true);
    }),
    catchError(() => loadLocalTranslationsFile(appService, platformService, http, filePlugin, countryCode, lang)),
  );
};
