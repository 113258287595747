import { Component, Input, ViewEncapsulation } from '@angular/core';

import { ModalService } from '../../core/services/modal.service';

import { AlertModalButton } from '../../core/models/modal-options';

export const AlertModalPageIdentifier = 'alert-modal';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.page.html',
  styleUrls: ['./alert-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertModalPage {
  @Input()
  public title: string;

  @Input()
  public message: string;

  @Input()
  public buttons: Array<AlertModalButton>;

  @Input()
  public inlineButtons?: boolean;

  constructor(private modalService: ModalService) {}

  public onActionClick(action: AlertModalButton): void {
    if (action.handler) {
      action.handler();
    }

    void this.modalService.dismissModal({ id: AlertModalPageIdentifier });
  }
}
