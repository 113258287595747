import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlCreationOptions } from '@angular/router';

import { NavigationService } from '../services/navigation.service';
import { VendorsService } from '../services/vendors.service';

@Injectable({ providedIn: 'root' })
export class VendorDetailsPageBackwardsCompatUrlRedirectionGuard implements CanActivate {
  constructor(private router: Router, private vendorsService: VendorsService, private navigationService: NavigationService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const vendorIdFromUrl = this.navigationService.getVendorIdFromUrl(route);
    const vendorSlugFromUrl = this.navigationService.getVendorSlugFromUrl(route);
    const countryLanguageUrl = this.navigationService.getCountryLanguageUrlSegment();

    if (!vendorIdFromUrl && !vendorSlugFromUrl) {
      return this.router.createUrlTree([`${countryLanguageUrl}/vendors`]);
    }

    let vendorId: number;
    let vendorSlug: string;
    let vendorServiceTypes: number;

    if (vendorIdFromUrl) {
      vendorId = vendorIdFromUrl;
      ({ vendorSlug, vendorServiceTypes } = this.vendorsService.getVendorSlugAndServiceTypeById(vendorIdFromUrl));
    } else if (vendorSlugFromUrl) {
      vendorSlug = vendorSlugFromUrl;
      ({ vendorId, vendorServiceTypes } = this.vendorsService.getVendorIdAndServiceTypeBySlug(vendorSlugFromUrl));
    }

    const itemIdFromUrl = this.navigationService.getItemIdFromUrl(route);
    const serviceTypesFromUrl = this.navigationService.getServiceTypeFromUrl(route);

    const params: UrlCreationOptions = {
      queryParams: route.queryParams || {},
      fragment: serviceTypesFromUrl ? serviceTypesFromUrl.toString() : vendorServiceTypes ? vendorServiceTypes.toString() : undefined,
    };

    if (vendorSlug) {
      const url = !itemIdFromUrl
        ? `${countryLanguageUrl}/vendors/${vendorSlug}`
        : `${countryLanguageUrl}/vendors/${vendorSlug}/${itemIdFromUrl}`;
      return this.router.createUrlTree([url], params);
    }

    const url = !itemIdFromUrl
      ? `${countryLanguageUrl}/vendors/id/${vendorId}`
      : `${countryLanguageUrl}/vendors/id/${vendorId}/${itemIdFromUrl}`;
    return this.router.createUrlTree([url], params);
  }
}
