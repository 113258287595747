import { AnimationDetails, AnimationTimingFunction } from './models';

/**
 * Returns a formatted string based on the animation details so that it can be
 * used as the first parameter of the Angular animate() function
 */
export function getAnimationString(animationDetails: AnimationDetails): string {
  return `${animationDetails.duration || 0}ms ${animationDetails.delay || 0}ms ${
    animationDetails.timingFunction || AnimationTimingFunction.CubicBezier
  }`;
}
