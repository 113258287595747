import { FirebaseUser, FirebaseX, MessagePayload } from '@awesome-cordova-plugins/firebase-x/ngx';

import { Observable, of } from 'rxjs';

import { PlatformService } from '../ssr/platform.service';

const firebaseToken =
  'eyJhbGciOiJSUzI1NiIsImtpZCI6ImIwNmExMTkxNThlOGIyODIxNzE0MThhNjdkZWE4Mzc0MGI1ZWU3N2UiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vYmlsYmF5dC12Mi1zdGFnaW5nLWViMDUwIiwiYXVkIjoiYmlsYmF5dC12Mi1zdGFnaW5nLWViMDUwIiwiYXV0aF90aW1lIjoxNjQ3OTUzOTc2LCJ1c2VyX2lkIjoiQ05GTnVOMGFCdVdKWTdFeGZKd2lXWkdkYVQzMiIsInN1YiI6IkNORk51TjBhQnVXSlk3RXhmSndpV1pHZGFUMzIiLCJpYXQiOjE2NDc5NTM5NzYsImV4cCI6MTY0Nzk1NzU3NiwiZW1haWwiOiJzZWJhc3RpYW4uZmVycmVyYXMuYXBwbGUuaWRAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiYXBwbGUuY29tIjpbIjAwMTIyMy5lZDUyMmUwZTMyMDU0NWE3YjFmYjY3MjNmYTE2MjhiZC4xMzI4Il0sImVtYWlsIjpbInNlYmFzdGlhbi5mZXJyZXJhcy5hcHBsZS5pZEBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJhcHBsZS5jb20ifX0.aNT2O1i5UZDHCYdlboGX5s4WHckg91oWzZIoKL0lPTx0CkC9iexD5qBblRGFkXSjpxzFsTx2e2xfuVknyF2LihvgaKYZoOFO3vW-tB8uuTUGc5pTNFQygzhFD82HuhcjoM92iFMoi6ErvsmC6gB6B137vhDllMiTJrYQBisTerFTnzQQXu82u5PjvYF6gJc36zAWMeEh03SCfnrGUq8_zJnvO9bfWsXpO_ZtJA3au1AmOnuy1D3Krt0uQSvMZrHWIdCBUF90qU6OhhkkfsduLQHu-HtZq0izvP5GbmK_3wOTgS1q_z4pbFTAg2jFn5zXcrl5OLrpi4WKaay-ZCzyGg';

class BrowserFirebaseProvider extends FirebaseX {
  public authenticateUserWithApple(_locale?: string): Promise<unknown> {
    return Promise.resolve({});
  }

  public authenticateUserWithGoogle(_clientId: string): Promise<unknown> {
    return Promise.resolve({});
  }

  public signInWithCredential(_credential: unknown): Promise<unknown> {
    return Promise.resolve();
  }

  public getCurrentUser(): Promise<FirebaseUser | string> {
    return Promise.resolve({ idToken: firebaseToken } as FirebaseUser);
  }

  public getToken(): Promise<string | null> {
    return Promise.resolve('fake-demo-firebase-token-99999');
  }

  public getAPNSToken(): Promise<string | null> {
    return Promise.resolve('fake-apns-demo-firebase-token-99999');
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public onMessageReceived(): Observable<MessagePayload> {
    return of(null);
  }

  public grantPermission(): Promise<unknown> {
    return Promise.resolve();
  }

  public hasPermission(): Promise<boolean> {
    return Promise.resolve(true);
  }

  public subscribe(_topic: string): Promise<unknown> {
    return Promise.resolve();
  }

  public unsubscribe(_topic: string): Promise<unknown> {
    return Promise.resolve();
  }

  public logEvent(_type: string, _data: unknown): Promise<unknown> {
    return Promise.resolve();
  }

  public setScreenName(_name: string): Promise<unknown> {
    return Promise.resolve();
  }

  public unregister(): Promise<unknown> {
    return Promise.resolve();
  }

  public logMessage(_message: string): Promise<unknown> {
    return Promise.resolve();
  }

  public setCrashlyticsUserId(_userId: string): Promise<unknown> {
    return Promise.resolve();
  }
}

class MobileFirebaseProvider extends FirebaseX {}

function firebaseFactory(platformService: PlatformService): FirebaseX {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return platformService.areCordovaPluginsAvailable ? new MobileFirebaseProvider() : new BrowserFirebaseProvider();
}

export const firebaseProvider = { provide: FirebaseX, useFactory: firebaseFactory, deps: [PlatformService] };
