import { Inject, Pipe, PipeTransform } from '@angular/core';

import { CurrencyConfiguration } from '../../core/models/currency-configuration.model';

import { SettingsService } from '../../core/services/settings.service';

import { AppConfig, TOKEN_CONFIG } from '../../core/config/app.config';

import { Helpers } from '../../core/helpers';

@Pipe({ name: 'localCurrency' })
export class LocalCurrencyPipe implements PipeTransform {
  constructor(private settingsService: SettingsService, @Inject(TOKEN_CONFIG) private config: AppConfig) {}

  public transform(value: number | string): string {
    if (value === null || value === undefined) {
      return;
    }

    // Convert the string to number if needed
    value = +value;

    // Format the decimals of the value
    value = Helpers.sumWithPrecision(value + 0);

    const language = this.settingsService.getLanguage().value;
    const country = this.settingsService.getCountry().code;

    // Get the confiduration for the selected country/language
    const currencyConfiguration: CurrencyConfiguration = this.config.currencySymbol.find(
      (currencySymbol) => currencySymbol.key === `${country}-${language}`,
    );

    if (!currencyConfiguration) {
      return value.toString();
    }

    const valueStr = value.toString();

    let valueIntegerPartStr = valueStr.split('.')[0];
    let valueDecimalPartStr = valueStr.split('.')[1] || '';

    // First we need to check if the
    // integer part should be transformed

    // Add 0 at the start if needed
    while (valueIntegerPartStr.length < currencyConfiguration.minIntegerDigits) {
      valueIntegerPartStr = '0' + valueIntegerPartStr;
    }

    // Add the thousandsSeparatorSymbol to the intergerPart if needed
    valueIntegerPartStr = valueIntegerPartStr.replace(/\B(?=(\d{3})+(?!\d))/g, currencyConfiguration.thousandsSeparatorSymbol);

    // Now we can check if we need to transform the decimals
    if (currencyConfiguration.minDecimalDigits) {
      // Add 0 at the end if needed
      while (valueDecimalPartStr.length < currencyConfiguration.minDecimalDigits) {
        valueDecimalPartStr = valueDecimalPartStr + '0';
      }
    }

    // Create a string with the interger part and the decimal part,
    // using the decimalSeparatorSymbol from the config
    const valueWithDecimals = valueDecimalPartStr?.length
      ? `${valueIntegerPartStr}${currencyConfiguration.decimalSeparatorSymbol}${valueDecimalPartStr}`
      : `${valueIntegerPartStr}`;

    // Add the currencySymbol at the end
    return `${valueWithDecimals} ${currencyConfiguration.currencySymbol}`;
  }
}
