import { Pipe, PipeTransform } from '@angular/core';

import { DateTimeService } from '../../core/services/date-time.service';

@Pipe({ name: 'formatDateTime' })
export class FormatDateTimePipe implements PipeTransform {
  constructor(private dateTimeService: DateTimeService) {}

  public transform(
    dateTimeIso: string,
    { format, isUtc = false, useRelativeDate = true }: { format: string; isUtc?: boolean; useRelativeDate?: boolean },
  ): string {
    if (!dateTimeIso || !format) {
      return;
    }

    return this.dateTimeService.format({ dateTimeIso, format, isUtc, useRelativeDate });
  }
}
