import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Area } from '../models/area.model';
import { HttpQueryStringParams } from '../models/http-query-string-param.model';
import { Province } from '../models/province.model';

import { SettingsService } from './settings.service';

import { AppConfig, TOKEN_CONFIG } from '../config/app.config';

@Injectable({ providedIn: 'root' })
export class AreasService {
  private areas: Map<string, Array<Province>>;

  constructor(private http: HttpClient, private settingsService: SettingsService, @Inject(TOKEN_CONFIG) private config: AppConfig) {
    this.areas = new Map<string, Array<Province>>();
  }

  public getAreas(): Observable<Array<Province>> {
    const country = this.settingsService.getCountry().code;
    const language = this.settingsService.getLanguage().value;

    const cachedAreas = this.areas.get(`${language}-${country}`);

    if (cachedAreas?.length) {
      // Return the local copy of the areas
      return of(JSON.parse(JSON.stringify(cachedAreas)) as Province[]);
    } else {
      const url = this.config.routes.getAreas.url.replace('#country#', country);

      return this.http.get<Array<Province>>(url).pipe(
        tap((areas) => {
          // Keep a local copy of the areas so next time we don't
          // need to get them from the server
          this.areas.set(`${language}-${country}`, areas);
        }),
      );
    }
  }

  public getAddressByCoordinates(
    lat: number,
    lng: number,
  ): Observable<{ area: Area; address: { buildingNumber: string; street: string; block: string } }> {
    const url = this.config.routes.postSearchAddress.url;
    const body: { latitude: number; longitude: number } = {
      latitude: lat,
      longitude: lng,
    };

    return this.http.post<{ area: Area; address: { buildingNumber: string; street: string; block: string } }>(url, body);
  }

  public getDefaultAreasBasedOnCurrentLocation(lat: number, lng: number): Observable<Array<Area>> {
    const url = this.config.routes.getDefaultArea.url;
    const params: HttpQueryStringParams = { latitude: lat.toString(), longitude: lng.toString() };

    return this.http.get<Array<Area>>(url, { params });
  }

  public getProvinceIndexByArea(area: Area): number {
    let provinceIndex: number;

    const country = this.settingsService.getCountry().code;
    const language = this.settingsService.getLanguage().value;
    const provinces = this.areas.get(`${language}-${country}`);

    loopProvinces: for (let i = 0; i < provinces.length; i++) {
      for (const provinceArea of provinces[i].areas) {
        if (provinceArea.areaId === area.areaId) {
          provinceIndex = i;
          break loopProvinces;
        }
      }
    }

    return provinceIndex;
  }

  public getAreaById(areaId: number): Observable<Area> {
    return this.getAreas().pipe(
      map((provinces) => {
        let result: Area = null;

        loopProvinces: for (const province of provinces) {
          for (const area of province.areas) {
            if (area.areaId === areaId) {
              result = area;
              break loopProvinces;
            }
          }
        }

        return result;
      }),
    );
  }
}
