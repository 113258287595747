import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';

import { PlatformService } from '../services/ssr/platform.service';

import { shouldSkipHttpInterceptors } from '../config/app.config';

@Injectable()
export class PlatformTypeInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (shouldSkipHttpInterceptors(request.url)) {
      return next.handle(request);
    }

    request = request.clone({ headers: request.headers.set('X-Platform', `${this.injector.get(PlatformService).applicationPlatformId}`) });

    return next.handle(request);
  }
}
