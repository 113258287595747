import { Injectable, OnDestroy } from '@angular/core';

import { concatMap, filter, map, Subject, takeUntil, timer, withLatestFrom } from 'rxjs';

import { AccountService } from '../services/account.service';
import { AnalyticsService } from '../services/analytics.service';
import { AppService } from '../services/app.service';
import { PushNotificationsService } from '../services/push-notifications.service';
import { PlatformService } from '../services/ssr/platform.service';
import { StateService } from '../services/state.service';
import { UniversalLinksAttributionService } from '../services/universal-links-attribution.service';
import { UniversalLinksService } from '../services/universal-links.service';

import { filterEmptyArray } from '../operators/filter-empty-array';
import { select } from '../operators/select';
import { selectNotEmptyArray } from '../operators/select-not-empty-array';
import { selectNotNil } from '../operators/select-not-nil';
import { Effect } from './';

@Injectable({ providedIn: 'root' })
export class UniversalLinksAttributionEffects implements Effect, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private appService: AppService,
    private stateService: StateService,
    private accountService: AccountService,
    private platformService: PlatformService,
    private analyticsService: AnalyticsService,
    private universalLinksService: UniversalLinksService,
    private pushNotificationsService: PushNotificationsService,
    private universalLinksAttributionService: UniversalLinksAttributionService,
  ) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  public initialize(): void {
    this.saveUniversalLinkClicks();
    this.savePushNotificationsClicks();
    this.sendDeepLinkClicksToApi();
    this.sendReferralProgramLinkClicksToApi();
    this.trackDeepLinkClicksUserProperties();
  }

  private saveUniversalLinkClicks(): void {
    this.stateService.state$
      .pipe(
        selectNotNil((state) => state.pendingUniversalLink),
        filter((link) => this.universalLinksService.shouldBeAttributed(link)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((link) => {
        this.universalLinksAttributionService.saveUniversalLinkClick(link);
      });
  }

  private savePushNotificationsClicks(): void {
    this.stateService.state$
      .pipe(
        selectNotNil((state) => state.pendingPushNotification),
        filter((notification) => this.pushNotificationsService.shouldBeAttributed(notification)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((notification) => {
        this.universalLinksAttributionService.savePushNotificationClick(notification);
      });
  }

  private sendDeepLinkClicksToApi(): void {
    // Interval and timer operators doesn't work in SSR
    // https://stackoverflow.com/a/57456495/3915438
    if (this.platformService.isServer) {
      return;
    }

    timer(0, this.universalLinksAttributionService.sendClicksToApiIntervalInMs)
      .pipe(
        filter(() => (this.platformService.isBrowser || this.appService.isHomePageLoaded()) && this.accountService.isLoggedIn()),
        withLatestFrom(
          this.stateService.state$.pipe(select((state) => state.pendingDeepLinkClicks)),
          this.stateService.state$.pipe(select((state) => state.sendingDeepLinkClicksToApi)),
        ),
        filter(([_, linkClicks, sendingLinkClicksToApi]) => !sendingLinkClicksToApi && !!linkClicks && !!linkClicks.length),
        concatMap(() => this.universalLinksAttributionService.sendDeepLinkClicksToApi()),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  private sendReferralProgramLinkClicksToApi(): void {
    // Interval and timer operators doesn't work in SSR
    // https://stackoverflow.com/a/57456495/3915438
    if (this.platformService.isServer) {
      return;
    }

    timer(0, this.universalLinksAttributionService.sendClicksToApiIntervalInMs)
      .pipe(
        filter(() => (this.platformService.isBrowser || this.appService.isHomePageLoaded()) && this.accountService.isLoggedIn()),
        withLatestFrom(
          this.stateService.state$.pipe(select((state) => state.pendingReferralProgramLinkClicks)),
          this.stateService.state$.pipe(select((state) => state.sendingReferralProgramLinkClicksToApi)),
        ),
        filter(([_timer, linkClicks, sendingLinkClicksToApi]) => !sendingLinkClicksToApi && !!linkClicks && !!linkClicks.length),
        concatMap(() => this.universalLinksAttributionService.sendReferralProgramLinkClicksToApi()),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  private trackDeepLinkClicksUserProperties(): void {
    this.stateService.state$
      .pipe(
        selectNotEmptyArray((state) => state.pendingDeepLinkClicks),
        map((links) => links.filter((link) => this.universalLinksAttributionService.shouldBeAttributed(link))),
        filterEmptyArray(),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((deepLinkClicks) => {
        void this.analyticsService.trackDeepLinkClicksPeopleProperties(deepLinkClicks);
      });
  }
}
