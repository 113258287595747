import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AlertModalPage } from './alert-modal.page';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule],
  declarations: [AlertModalPage],
})
export class AlertModalPageModule {}
