import { Injectable } from '@angular/core';

import { CleverTapPlugin } from './native-plugins/clever-tap.plugin';

import { InAppMessagePermission } from '../enums/in-app-message-permission.enum';

import { PushNotificationsService } from './push-notifications.service';
import { UniversalLinksService } from './universal-links.service';

@Injectable({ providedIn: 'root' })
export class InAppMessagesService {
  constructor(
    private cleverTapPlugin: CleverTapPlugin,
    private universalLinksService: UniversalLinksService,
    private pushNotificationsService: PushNotificationsService,
  ) {}

  public initializeInAppMessagesEvents(): void {
    this.cleverTapPlugin.onInAppMessageReceived().subscribe((inAppMessage) => {
      if (!inAppMessage) {
        return;
      }

      if (inAppMessage.permission && inAppMessage.permission === InAppMessagePermission.PushNotifications) {
        this.pushNotificationsService.askForPushNotificationsPermissionEvenIfRejected();
        return;
      }

      if (inAppMessage.deeplink) {
        this.universalLinksService.handleDeepLinkUrl(inAppMessage.deeplink);
        return;
      }
    });

    this.cleverTapPlugin.subscribeToInAppEvents();
  }
}
