import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ClipboardPlugin } from './native-plugins/clipboard.plugin';

import {
  ReferralProgramModalPage,
  ReferralProgramModalPageIdentifier,
} from '../../modals/referral-program-modal/referral-program-modal.page';

import { AccountService } from './account.service';
import { ModalService } from './modal.service';
import { OverlayService } from './overlay.service';
import { SettingsService } from './settings.service';
import { StateService } from './state.service';

@Injectable({ providedIn: 'root' })
export class ReferralProgramService {
  constructor(
    private accountService: AccountService,
    private settingsService: SettingsService,
    private stateService: StateService,
    private translateService: TranslateService,
    private overlayService: OverlayService,
    private modalService: ModalService,
    private clipboardPlugin: ClipboardPlugin,
  ) {}

  // Method that shows the referral program modal if the feature toggle
  // is turned on and then returns a boolean indicating if the content
  // should be shared or not
  public maybeShowReferralProgramModal(): Promise<boolean> {
    const isLoggedIn = this.accountService.isLoggedIn();
    const isReferralProgramEnabled = this.settingsService.isReferralProgramFeatureEnabled();

    if (this.stateService.state?.referralProgramModalShown || !isLoggedIn || !isReferralProgramEnabled) {
      // Do not show the modal but return true so that the caller
      // tries to share the link using the native sharing picker
      return Promise.resolve(true);
    }

    // Do not show it again
    this.stateService.update({ referralProgramModalShown: true });

    return this.modalService
      .showAlert<boolean>({
        component: ReferralProgramModalPage,
        id: ReferralProgramModalPageIdentifier,
        backdropDismiss: false,
      })
      .then((hasSharedLink) => !!hasSharedLink);
  }

  public copyReferralLink(referralLink: string): void {
    void this.clipboardPlugin.copy(referralLink).then((wasCopied) => {
      this.overlayService.showToast({
        message: wasCopied
          ? (this.translateService.instant('REFERRAL_PROGRAM_PAGE.CLIPBOARD_COPY_SUCCESS') as string)
          : (this.translateService.instant('REFERRAL_PROGRAM_PAGE.CLIPBOARD_COPY_ERROR') as string),
        type: wasCopied ? 'info' : 'error',
        showCloseButton: true,
      });
    });
  }
}
